import axios, { AxiosRequestConfig } from "axios";
import { apiUrlBase } from "../../const/const";
import * as authService from "../../services/auth.service";

export const login = async (
  username: string,
  password: string
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/login",
    data: {
      username: username,
      password: password,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export type RegisterModel = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const register = async (model: RegisterModel): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/register",
    data: model,
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export type ConfirmEmailModel = {
  code: string;
  userId: string;
};

export const confirmEmail = async (model: ConfirmEmailModel): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/confirmEmail",
    data: model,
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const forgotPassword = async (email: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/forgotPassword",
    data: {
      email: email,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const resetPassword = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/resetPassword",
    data: {
      password: model.password,
      confirm_password: model.confirmPassword,
      code: model.code,
      user_id: model.userId,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};


export const refreshToken = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/refreshToken",
    data: {
      accessToken: model.accessToken,
      refreshToken: model.refreshToken,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const changePassword = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/account/changePassword",
    data: {
      new_password: model.newPassword,
      current_password: model.currentPassword,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};