import React, { Fragment, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import * as summaryProvider from "../../../../../providers/student/summary.provider";
import { RewardType } from "../../../../../enums/reward-type.enum";
import { Chart } from "chart.js";
import { Counter } from "./components";
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    borderBottom: "2px solid lightgray",
  },
  rewardCountiner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reward: {
    width: 200,
    height: 200,
    objectFit: "contain",
  },
  chart: {
    height: 300,
  },
  counter: {
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: "#fff",
    height: 45,
    width: 45,
    top: 38,
    left: 77,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
    fontWeight: 500,
  },
}));

type ChartPros = {
  monthSummary: any
}

function BarChart(props: ChartPros) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<any>();
  const { monthSummary } = props;
  useEffect(() => {
    if (monthSummary) {
      const setupData = (monthSummary: any) => {
        const { days } = monthSummary;

        const canvasElement = canvasRef.current;

        if (canvasElement) {
          if (chartRef.current) {
            chartRef.current.destroy();
          }
          const goldDataset = {
            label: "Gold",
            data: days.map((d: any) => d.gold),
            backgroundColor: "gold",
          };
          const silverDataset = {
            label: "Silver",
            data: days.map((d: any) => d.silver),
            backgroundColor: "silver",
          };

          const bronzeDataset = {
            label: "Bronze",
            data: days.map((d: any) => d.bronze),
            backgroundColor: "#CD7F32",
          };

          const chart = new Chart(canvasElement, {
            type: "bar",
            data: {
              labels: days.map((d: any, i: number) => i + 1),
              datasets: [goldDataset, silverDataset, bronzeDataset],
            },
            options: {
              maintainAspectRatio: false,
            },
          });
          chartRef.current = chart;
        }
      };
      setupData(monthSummary);
    }
  }, [monthSummary]);

  return <canvas ref={canvasRef} />;
}

export default function Summary() {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [rewards, setRewards] = useState<any>();

  const [selectedDate, handleDateChange] = useState<any>(new Date());

  const [monthSummary, setMonthSummary] = useState<any>();
  const [monthLoading, setMonthLoading] = useState<boolean>(true);

  const [rankData, setRankData] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onSelectDate = async () => {
    setMonthLoading(true);
    const result = await summaryProvider.getByMonth(selectedDate);
    setMonthSummary(result);
    setMonthLoading(false);
  };

  useEffect(() => {
    const getSummary = async () => {
      setLoading(true);
      try {
        const rewards = await summaryProvider.get();
        setRewards(rewards);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getSummary();

    const getRanks = async () => {
      const rank = await summaryProvider.getRank();
      setRankData(rank);
    };
    getRanks();
  }, []);

  useEffect(() => {
    onSelectDate();
  }, [selectedDate]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={3}>
            <Grid container item xs={12} className={classes.row}>
              <Grid item xs={2}>
                <Typography variant="h5">Overall</Typography>
              </Grid>
              <Grid container item xs={8}>
                <Grid item xs={4} className={classes.rewardCountiner}>
                  <Counter
                    rewardType={RewardType.Gold}
                    value={rewards[RewardType.Gold]}
                  />
                </Grid>
                <Grid item xs={4} className={classes.rewardCountiner}>
                  <Counter
                    rewardType={RewardType.Silver}
                    value={rewards[RewardType.Silver]}
                  />
                </Grid>
                <Grid item xs={4} className={classes.rewardCountiner}>
                  <Counter
                    rewardType={RewardType.Bronze}
                    value={rewards[RewardType.Bronze]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid container item xs={12} className={classes.row}>
              <Grid item xs={2}>
                <Typography variant="h5">Overall Rank</Typography>
              </Grid>
              <Grid item xs={4} className={classes.rewardCountiner}>
                {rankData && (
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 25,
                      height: 45,
                      width: 45,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rankData.rank ? rankData.rank : "N/A"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4} className={classes.rewardCountiner}>
                {rankData && (
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {rankData.rank && rankData.percentage !== undefined ? (
                      <Fragment>
                        <Typography>Better than</Typography>
                        &nbsp;
                        <Typography
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            color: "red",
                          }}
                        >
                          {rankData.percentage}%
                        </Typography>
                        &nbsp;
                        <Typography>of users</Typography>
                      </Fragment>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>

            <Grid container item xs={12} className={classes.row}>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">
                  Rewards for {format(selectedDate, "MMMM")}
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsOpen(true)}
                  style={{ marginBottom: 15 }}
                >
                  Select Month
                </Button>
                <DatePicker
                  open={isOpen}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                  openTo="year"
                  minDate={new Date("2022-01-01")}
                  maxDate={new Date()}
                  views={["year", "month"]}
                  label="Yearn and Month"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Grid>
              <Grid container item xs={8}>
                {monthLoading ? (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <Counter
                      rewardType={RewardType.Gold}
                      value={monthSummary.total.gold}
                    />
                  </Grid>
                )}
                {monthLoading ? (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <Counter
                      rewardType={RewardType.Silver}
                      value={monthSummary.total.silver}
                    />
                  </Grid>
                )}
                {monthLoading ? (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid item xs={4} className={classes.rewardCountiner}>
                    <Counter
                      rewardType={RewardType.Bronze}
                      value={monthSummary.total.bronze}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h5">
                Rewards daily for {format(selectedDate, "MMMM")}
              </Typography>
            </Grid>

            <Grid className={classes.chart} xs={12}>
              <BarChart monthSummary={monthSummary} />
            </Grid>
            {/* <Grid className={classes.chart} xs={12}>
              <canvas ref={canvasRef} />
            </Grid> */}
          </Grid>
        )}
      </div>
    </div>
  );
}
