import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
  },
  root: {
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    animation: "$pulse 1s infinite",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  counter: {
    color: "white",
    fontSize: 95,
  },
}));

type Props = {
  duration: number;
  currentTime: number;
};

export default function CenterCountdown(props: Props) {
  const classes = useStyles();
  const { currentTime, duration } = props;
  const countdownTime = Math.round(duration - currentTime);
  return (
    <div className={classes.root}>
      <span className={classes.counter}>
        {countdownTime === 0 ? "Go !" : countdownTime}
      </span>
    </div>
  );
}
