import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import BreadcrumbsContainer from '../../../components/BreadcrumbsContainer';
import { Divider } from '@material-ui/core';
import * as adminFeedbackProvider from '../../../providers/admin/feedback.provider';
import { Feedback } from '../../../providers/admin/feedback.provider';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  
});

function Row(props: { row: Feedback }) {
  const { row } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {`${row.first_name} ${row.last_name}`}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.username}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.feedback}
        </TableCell>
        <TableCell component="th" scope="row">{row.creation_date}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    padding: theme.spacing(3),
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    height: '100%'
    
  },
  paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
      },
      height: '100%'
  },
}));


type Props = {
  title: string,
  breadcrumbs: any[]
}

export default function CollapsibleTable(props: Props) {
  const classes = useStyles()
  const [feedback, setFeedback] = useState<Feedback[]>();
  useEffect(() => {
    const getFeedback = async () => {
      const feedback = await adminFeedbackProvider.getAll();
      setFeedback(feedback);
    }
    getFeedback();
  }, []);
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
          {props.title}
      </Typography>
      <BreadcrumbsContainer breadcrumbs={props.breadcrumbs} title={props.title} />
      <Divider/>
      {
       feedback && <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Feedback</TableCell>
              <TableCell>Creation Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </main>
  );
}