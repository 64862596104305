import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import validate from "validate.js";
import * as authProvider from "../../../../../providers/common/auth.provider";
import { passwordComplexity } from "../../../../../validators";

const useStyles = makeStyles(() => ({
  root: {},
  error: {
    color: "red",
  },
  success: {
    color: "lightgreen",
  },
}));

validate.validators.passwordComplexity = passwordComplexity;

const schema = {
  currentPassword: {
    presence: { allowEmpty: false, message: "is required" },
  },
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
      maximum: 128,
    },
    passwordComplexity: {}
  },
};

type Props = {};

export default function ResetPassword(props: Props) {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  const [displaySuccess, setDisplaySuccess] = useState<boolean>();

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const handleCurrentPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as string;
    setCurrentPassword(value);
    setSubmitted({
      ...submitted,
      currentPassword: false,
    });
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as string;
    setNewPassword(value);
    setSubmitted({
      ...submitted,
      newPassword: false,
    });
  };

  const handleChangePassword = async (event: any) => {
    event.preventDefault();
    const values = {
      currentPassword,
      newPassword,
    };
    const errors = validate(values, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        currentPassword: true,
        newPassword: true,
      });
      return;
    }

    setLoading(true);
    try {
      const result = await authProvider.changePassword(values);

      const errorKeys = Object.keys(result.errors);
      if (errorKeys.length === 0) {
        setCurrentPassword("");
        setNewPassword("");

        setDisplaySuccess(true);
        setTimeout(() => {
          setDisplaySuccess(false);
        }, 5000);

        setLoading(false);
      } else {
        const capitalizeFirstLetter = (string: string) => {
          return string.charAt(0).toLowerCase() + string.slice(1);
        };
        const pageErrors = { ...errors };
        const pageSubmitted = { ...submitted };

        if (errorKeys.indexOf("Form") === -1) {
          for (let index = 0; index < errorKeys.length; index++) {
            const key = errorKeys[index];
            const error = result.errors[key];
            const keyLower = capitalizeFirstLetter(key);

            pageErrors[keyLower] = [error];
            pageSubmitted[keyLower] = true;
          }

          setErrors(pageErrors);
          setSubmitted(pageSubmitted);

          setErrorMessage("");
        } else {
          setErrors({});
          setSubmitted({});

          setErrorMessage(result.errors["Form"]);
        }
        setLoading(false);
      }
    } catch (ex) {
      console.warn(ex);

      setErrorMessage("An error occurred while processing your request");

      setErrors({});
      setSubmitted({});

      setLoading(false);
    }
  };

  return (
    <Card className={classes.root}>
      <form autoComplete="off" noValidate>
        <CardHeader title="Change password" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={hasError("currentPassword")}
                fullWidth
                helperText={
                  hasError("currentPassword") ? errors.currentPassword[0] : ""
                }
                label="Current Password"
                name="currentPassword"
                onChange={handleCurrentPasswordChange}
                type="password"
                value={currentPassword || ""}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasError("newPassword")}
                fullWidth
                helperText={
                  hasError("newPassword")
                    ? errors.newPassword[0]
                    : "8 or more characters, 1 uppercase, 1 lowercase, 1 number"
                }
                label="New Password"
                name="newPassword"
                onChange={handleNewPasswordChange}
                type="password"
                value={newPassword || ""}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <p className={classes.success}>
                {displaySuccess ? "Password changed" : ""}
              </p>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.error}>{errorMessage}</p>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={handleChangePassword}
          >
            Change password
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}
