import React, { Component } from "react";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";

import { ExerciseToolbar, ExerciseCard } from "./components";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ChannelExerciseListItem } from "../../../providers/instructor/channel-exercise.provider";
import * as channelExerciseProvider from "../../../providers/instructor/channel-exercise.provider";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  exercises: Array<ChannelExerciseListItem>;
  loading: boolean;
  confirmationData: any;
};

type Props = {
  classes: any;
  channelId: string;
};

class ChannelExerciseList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      exercises: [],
      loading: true,
      confirmationData: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      const posesOrSkills = await channelExerciseProvider.getAll(
        this.props.channelId
      );
      this.setState({
        exercises: posesOrSkills,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  onRemove = async (id: string) => {
    const { channelId } = this.props;
    this.setState({
      confirmationData: {
        channelId: channelId,
        id: id,
      },
    });
  };

  onRemoveClickHandler = async (data: any) => {
    await channelExerciseProvider.remove(data.id, data.channelId);
    this.setState({
      exercises:
        this.state.exercises &&
        this.state.exercises.filter((i) => i.id !== data.id),
    });
  };

  render = () => {
    const { exercises, confirmationData, loading } = this.state;

    const { classes, channelId } = this.props;

    return (
      <div className={classes.root}>
        {channelId && <ExerciseToolbar channelId={channelId} />}
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {exercises.map((poseOrSkill) => (
                <Grid item key={poseOrSkill.id} lg={3} md={6} xs={12}>
                  <ExerciseCard
                    onRemove={this.onRemove}
                    poseOrSkill={poseOrSkill}
                    channelId={channelId}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        <ConfirmationDialog
          handleConfirm={this.onRemoveClickHandler}
          dialogTitle={"Confirm exercise removal"}
          data={confirmationData}
        />
      </div>
    );
  };
}

export default withStyles(styles)(ChannelExerciseList);
