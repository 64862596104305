import React, { FC, useEffect, useState } from "react";
import * as THREE from "three";
import { CourseAnalysisTimespan } from "../../../../../types/class/class-analysis-timespan";

type Props = {
  backgroundObject: THREE.Mesh;
  timespanProgressPercentage: number;
  timespan?: CourseAnalysisTimespan;
  repsCounter: number;
   //HACK: handles timespan that ends with the video end
  isInstructorVideoEnded: boolean;
};

const radius = 0.2;
const tube = 0.05;
const radialSegments = 2;
const tubularSegments = 50;

const x = -1.89;
const y = 0.945;
const z = 0.05;

const greenColor = 0x00ff00;
const blackColor = 0x000000;

const TimespanCircularProgressBar: FC<Props> = (props) => {
  const {
    backgroundObject,
    timespan,
    timespanProgressPercentage,
    repsCounter,
    isInstructorVideoEnded,
  } = props;

  const [timespanProgressBars, setTimespanProgressBars] = useState<
    Array<THREE.Mesh>
  >([]);
  const [
    timespanProgressBarMeshBackgrounds,
    setTimespanProgressBarMeshBackgrounds,
  ] = useState<Array<THREE.Mesh>>([]);

  const getProgressBar = () => {
    const geometry = new THREE.TorusGeometry(
      radius,
      tube,
      radialSegments,
      tubularSegments,
      0
    );
    const material = new THREE.MeshBasicMaterial({ color: greenColor });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.set(x, y, z + 0.01);
    mesh.rotation.z = Math.PI / 2;
    return mesh;
  };

  const getProgressBarBackground = () => {
    const geometry = new THREE.TorusGeometry(
      radius,
      tube,
      radialSegments,
      tubularSegments,
      2 * Math.PI
    );
    const material = new THREE.MeshBasicMaterial({
      color: blackColor,
      transparent: true,
      opacity: 0.1,
    });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.set(x, y, z);
    mesh.rotation.z = Math.PI / 2;
    return mesh;
  };

  const updateProgressBar = (
    progressBar: THREE.Mesh,
    percentage: number | undefined
  ) => {
    if (percentage) {
      if(percentage > 100) {
        percentage = 100
      }
      progressBar.geometry.dispose();
      const percentValue = (2 * Math.PI) / 100;
      const angle = percentage * percentValue;
      const geometry = new THREE.TorusGeometry(
        radius,
        tube,
        radialSegments,
        tubularSegments,
        -angle
      );
      progressBar.geometry = geometry;
    }
  };

  const removeProgressBars = () => {
    timespanProgressBars.map((m) => {
      m.geometry.dispose();
      (m.material as THREE.Material).dispose();
      backgroundObject.remove(m);
    });
    timespanProgressBarMeshBackgrounds.map((m) => {
      m.geometry.dispose();
      (m.material as THREE.Material).dispose();
      backgroundObject.remove(m);
    });
  };

  useEffect(() => {
    if (timespan) {
      if (timespan.pose) {
        const mesh = getProgressBar();
        backgroundObject.add(mesh);
        setTimespanProgressBars([mesh]);
        const backgroundMesh = getProgressBarBackground();
        backgroundObject.add(backgroundMesh);
        setTimespanProgressBarMeshBackgrounds([backgroundMesh]);
      }
      if (timespan.exercise) {
        const repRadians = (2 * Math.PI) / timespan.exercise.repetitions;
        const percentageRads = repRadians / 100;
        const coloredRadians = percentageRads * 85;
        const progressBars = [];
        const getProgressBarNode = (
          radians: number,
          initialRotation: number
        ) => {
          const geometry = new THREE.TorusGeometry(
            radius,
            tube,
            radialSegments,
            tubularSegments,
            -radians
          );
          const material = new THREE.MeshBasicMaterial({
            color: blackColor,
            transparent: true,
            opacity: 0.1,
          });
          const mesh = new THREE.Mesh(geometry, material);

          mesh.position.set(x, y, z);
          mesh.rotation.z = Math.PI / 2 - initialRotation;
          return mesh;
        };
        for (let index = 0; index < timespan.exercise.repetitions; index++) {
          const initialRotation = repRadians * index;
          const node = getProgressBarNode(coloredRadians, initialRotation);
          progressBars.push(node);
          backgroundObject.add(node);
        }
        setTimespanProgressBars(progressBars);
      }
    } else {
      removeProgressBars();
      setTimespanProgressBars([]);
      setTimespanProgressBarMeshBackgrounds([]);
    }
  }, [timespan]);

  useEffect(() => {
    const onChange = async (
      percentage: number | undefined,
      timespan: CourseAnalysisTimespan | undefined
    ) => {
      if (timespan) {
        if (timespan.pose && timespanProgressBars.length === 1) {
          const progressBar = timespanProgressBars[0];
          updateProgressBar(progressBar, percentage);
        }
      }
    };

    onChange(timespanProgressPercentage, timespan);
  }, [timespanProgressPercentage]);

  useEffect(() => {
    const onChange = async (
      repsCounter: number | undefined,
      timespan: CourseAnalysisTimespan | undefined
    ) => {
      if (timespan) {
        if (timespan.exercise && repsCounter) {
          const node = timespanProgressBars[repsCounter - 1];
          if (node) {
            const material = node.material as THREE.MeshBasicMaterial;
            material.color = new THREE.Color(greenColor);
            material.opacity = 1;
            material.transparent = false;
          }
        }
      }
    };

    onChange(repsCounter, timespan);
  }, [repsCounter]);

  //HACK: handles timespan that ends with the video end
  useEffect(() => {
    if (isInstructorVideoEnded) {
      removeProgressBars();
    }
  }, [isInstructorVideoEnded]);

  return <div></div>;
};

export default TimespanCircularProgressBar;
