import React from "react";
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

type Props = {
  title: string;
};

const HelpAndFAQ = (props: Props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Card className={classes.root}>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="The first AI-powered fitness coaching platform, WizHero, with its revolutionary technology, has now evolved to a newer platform. Our latest website is highly interactive and functional, meticulously designed to cater to your fitness needs with absolute accuracy." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Let’s have a quick walkthrough of the new Wizhero website and user accounts. After logging in, you are redirected to an easy-to-use, personalized account, with a multitude of classes and activities to pick from. The videos are in high-definition quality with in-detail descriptions, helping you customize your fitness regime. The website has been categorized to aid both instructors and students in navigating it with precision." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Once you pick a class, you are transported to a virtual classroom, with an avatar of yourself positioned in the center of the room. On the room’s right wall is the instructor video accompanied by an audio play-by-play and calming music. And on the left are the slides of the final poses to be achieved. A timer on the top left corner keeps track of the time or the number of reps you have completed, depending on your exercise." />
            </ListItem>
            <ListItem>
              <ListItemText primary="To keep you motivated, gold and silver stars are awarded at the completion of your exercise, according to the accuracy, compared with the desired output. All your rewards and progress are recorded in the ‘My Activities’ section. A detailed recording of your daily exercise is also saved here in avatar form." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Your activity history is carefully analyzed and a personalized training plan is prepared to help you improve easily and ensure efficiency. Common mistakes and the root causes for these are identified and remedial training plans produced automatically. These training plans also take into account your bio-metrics as well as instructor recommendations." />
            </ListItem>
            <ListItem>
              <ListItemText primary="In a nutshell, WizHero is the perfect platform for you to attain that fit body you have always wanted, with the best of instructors and custom-made training plans, all in the comfort of your home. Visit our latest website and sign up today for a great fitness experience!" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default HelpAndFAQ;
