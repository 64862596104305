import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  card: {
    background: "black",
    position: "relative",
    height: 200,
    borderRadius: 0,
    width: "100%",
  },
  cover: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  durationContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    fontWeight: 500,
    paddingLeft: 4,
    paddingRight: 4,
  },
  title: {
    color: "black",
    marginTop: 5,
    marginLeft: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
  },
  description: {
    fontSize: 14,
    marginLeft: 10,

    whiteSpace: "nowrap",
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    color: "#797878",
  },
  instructorProfile: {
    marginTop: 5,
  },
  instructorName: {
    fontSize: 14,
    marginLeft: 10,
    marginRight: 15,
    color: "#797878",
  },
}));

export default function ChannelClassCard(props) {
  const { course, onUnpublish, ...rest } = props;
  const classes = useStyles();

  const optionsAnchorEl = useRef(null);
  const [isOptionsAnchor, setIsOptionsAnchor] = useState(false);
  const history = useHistory();

  const onEdit = () => {
    history.push(`/admin/class-practice/${course.id}/edit/`);
  };

  return (
    <Box {...rest}>
      <Card className={classes.card}>
        <CardActionArea>
          <img
            alt="card thumbnail"
            className={classes.cover}
            src={course.thumbnail_path}
            title={course.name}
          />
        </CardActionArea>
      </Card>
      <Box
        display="flex"
        style={{ marginTop: "10px", justifyContent: "space-between" }}
      >
        <Grid item xs={10} style={{ display: "flex", flexDirection: "row" }}>
          <Avatar
            className={classes.instructorProfile}
            alt="instructor avatar"
            src={course.avatar}
          />
          <Box display="block" className={classes.container}>
            <Typography className={classes.title}>{course.name}</Typography>
            <Box display="block" style={{ marginTop: "5px" }}>
              <Typography className={classes.instructorName}>
                {course.first_name} {course.last_name}
              </Typography>
              <Typography className={classes.description}>
                {course.description}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <MoreVertIcon
            ref={optionsAnchorEl}
            className={classes.moreOptions}
            onClick={() => setIsOptionsAnchor(!isOptionsAnchor)}
          />
          <Menu
            id="simple-menu"
            anchorEl={optionsAnchorEl.current}
            keepMounted
            open={isOptionsAnchor}
            onClose={() => setIsOptionsAnchor(!isOptionsAnchor)}
          >
            <MenuItem key="course test xbot" onClick={onEdit}>
              Edit
            </MenuItem>
            <MenuItem
              key="course toggle"
              onClick={() => onUnpublish(course.id)}
            >
              {course.is_published ? "Unpublish" : "Publish"}
            </MenuItem>
          </Menu>
        </Grid>
      </Box>
    </Box>
  );
}
