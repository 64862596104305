import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import SelectedPoint from "../SelectedPoint";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { getFlippedDisplayKeypoints } from "../../../../../services/keypoint.helper";
import { StudentPracticeActivityBlockPoseRecord } from "../../../../../types/practice-activities/student-practice-activity-block-pose-record";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import HumanRegionsAccuracy from "../../../../../components/HumanRegionsAccuracy";
import Summary from "../Summary";
import { StudentPracticeActivityBlockPoseDetails } from "../../../../../types/practice-activities/student-practice-activity-block-details";

Chart.register(...registerables);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
  },
  chart: {
    height: 300,
  },
  video: {
    width: "50%",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
}));

type Props = {
  records: StudentPracticeActivityBlockPoseRecord[];
  pose: StudentPracticeActivityBlockPoseDetails;
  name: string;
  score: number;
  duration: number;
  effectiveness: number;
};

export default function AccuracyChart(props: Props) {
  const { records, score, duration, pose, name, effectiveness } = props;
  const classes = useStyles();
  const chartRef = useRef<any>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [selectedRecord, setSelectedRecord] =
    useState<StudentPracticeActivityBlockPoseRecord>();
  const [recordIndex, setRecordIndex] = useState<number>();
  const playbackTimeoutRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    const setupData = () => {
      const canvasElement = canvasRef.current;
      if (canvasElement) {
        const chart = new Chart(canvasElement, {
          type: "line",
          data: {
            labels: records.map((d) => d.blockTime.toFixed(2)),
            datasets: [
              {
                label: "accuracy (%)",
                data: records.map((d) => Math.round(d.accuracy)),
                borderColor: "rgb(255, 99, 132, 0.6)",
                backgroundColor: "rgb(255, 99, 132)",
                fill: false,
                tension: 0.4,
                pointStyle: "circle",
                pointRadius: 5,
                pointBorderColor: "rgb(255, 99, 132)",
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        });
        chartRef.current = chart;

        const getMaxAccuracyKeypoints = (
          records: StudentPracticeActivityBlockPoseRecord[]
        ) => {
          let max = records[0].accuracy;
          let maxIndex = 0;
          for (let index = 1; index < records.length; index++) {
            const record = records[index];
            const accuracy = record.accuracy;
            if (max < accuracy) {
              max = accuracy;
              maxIndex = index;
            }
          }

          return records[maxIndex];
        };

        const record = getMaxAccuracyKeypoints(records);
        setSelectedRecord(record);
        canvasElement.onclick = (event: Event) => {
          const activePoints = chart.getElementsAtEventForMode(
            event,
            "point",
            { axis: "x", intersect: true },
            true
          );
          if (activePoints.length !== 0) {
            const activePoint = activePoints[0];
            const selectedRecordIndex = activePoint.index;
            const selectedRecord = records[selectedRecordIndex];
            setSelectedRecord(selectedRecord);
          }
        };
      }
    };

    setupData();
  }, []);

  const [isPLaying, setIsPlaying] = useState<boolean>();

  const onTogglePlay = () => {
    setIsPlaying(!isPLaying);
    if (!isPLaying) {
      const playbackTimeout = playbackTimeoutRef.current;
      if (playbackTimeout) {
        clearTimeout(playbackTimeout);
      }
      const recordIndex = 0;
      setRecordIndex(recordIndex);
    } else {
      const playbackTimeout = playbackTimeoutRef.current;
      if (playbackTimeout) {
        clearTimeout(playbackTimeout);
      }
    }
  };

  useEffect(() => {
    if (recordIndex !== undefined) {
      const record = records[recordIndex];
      setSelectedRecord(record);

      const nextRecordIndex = recordIndex + 1;
      if (records.length - 1 >= nextRecordIndex) {
        const nextRecord = records[nextRecordIndex];
        const timeoutDuration = nextRecord.blockTime - record.blockTime;
        playbackTimeoutRef.current = setTimeout(() => {
          setRecordIndex(nextRecordIndex);
        }, timeoutDuration * 1000);
      } else {
        setIsPlaying(false);
      }
    }
  }, [recordIndex]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">{name}</Typography>
      </Grid>
      <Grid className={classes.chart} item xs={12}>
        <canvas ref={canvasRef} />
      </Grid>
      <Grid className={classes.chart} item sm={6} xs={12}>
        {selectedRecord && (
          <SelectedPoint
            studentKeypoints={getFlippedDisplayKeypoints(
              selectedRecord.keypoints
            )}
            instructorKeypoints={getFlippedDisplayKeypoints(pose.keypoints)}
          />
        )}
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {selectedRecord && (
          <div style={{ height: 48, display: "flex", alignItems: "center" }}>
            <span>Regions used in calculations</span>
          </div>
        )}
        {selectedRecord && (
          <HumanRegionsAccuracy accuracyData={selectedRecord.jointsAccuracy} />
        )}
        {selectedRecord && (
          <div
            style={{
              height: 48,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#57c9d5",
                  margin: 5,
                }}
              ></div>
              <span>not used</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#ff0000",
                  margin: 5,
                }}
              ></div>{" "}
              <span>to</span>{" "}
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#00ff00",
                  margin: 5,
                }}
              ></div>
              <span>scale represents accuracy </span>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <IconButton
          aria-label={isPLaying ? "Stop" : "Play"}
          onClick={onTogglePlay}
          className={classes.button}
        >
          {!isPLaying ? (
            <PlayArrowIcon fontSize="inherit" />
          ) : (
            <StopIcon fontSize="inherit" />
          )}
        </IconButton>
      </Grid>
      <Summary
        duration={duration}
        exercise={undefined}
        score={score}
        effectiveness={effectiveness}
      />
    </Grid>
  );
}
