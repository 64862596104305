import React from "react";

import { Route, Redirect } from "react-router-dom";
import { UserType } from "../../enums/user-type.enum";
import * as authService from "../../services/auth.service";

const DefaultRoute = (props) => {
  const { component: Component, layout: Layout, ...rest } = props;

  const authState = authService.getAuthState();
  const userType = authState.userType;

  return (
    <Route
      {...rest}
      render={() =>
        userType === UserType.Instructor ? (
          <Redirect
            to={{
              pathname: "/sports-instructor/skills-video-classes",
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/sports-student/skills-video-classes" //skills-video-class-sequences", //
            }}
          />
        )
      }
    />
  );
};

export default DefaultRoute;
