import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { apiUrlBase } from "../../const/const";

export type ChannelExerciseStep = {
  id: string;
  keypoints: Keypoint3D[];
  image_path: string;
  step_order: number;
  regions: { [key: string]: boolean };
};

export type ChannelExercise = {
  id: string;
  name: string;
  message: string;
  steps: ChannelExerciseStep[];
};

export type ChannelExerciseListItem = {
  id: string;
  name: string;
  message: string;
  creation_date: string;
  thumbnail_path: string;
};

export const get = async (itemId: string): Promise<ChannelExercise> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/get",
    data: {
      id: itemId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelExerciseListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/getAll",
    data: {
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

type ExerciseCreate = {
  channelId: string;
  name: string;
  message?: string;
  keypoints: Keypoint3D[][];
  imageBlobs: File[] | Blob[];
  regions: any[]
};

export const create = async (item: ExerciseCreate): Promise<any> => {
  const formData = new FormData();
  formData.append("channel_id", item.channelId);
  formData.append("name", item.name);
  formData.append("message", item.message || "");
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("keypoints", JSON.stringify(item.keypoints));
  formData.append("regions", JSON.stringify(item.regions));

  for (let index = 0; index < item.imageBlobs.length; index++) {
    formData.append(`image[${index}]`, item.imageBlobs[index]);
  }

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/edit",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const remove = async (id: string, channelId: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/remove",
    data: {
      id: id,
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const skeleton = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/exercise/skeleton",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
