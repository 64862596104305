import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useRef } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ChannelType } from "../../../../../types/channel/channel.type";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  filterContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },

  filterPara: {
    display: "inline",
    paddingRight: "20px",
  },

  dropdownText: {
    textTransform: "none",
  },

  clearText: {
    
    textTransform: "none",
  },
}));

export default function FilterChannels(props: any) {
  const classes = useStyles();
  const channelTypeAnchorEl = useRef<any>(null);
  const instructorAnchorEl = useRef<any>(null);

  const [uniqInstructors, setUniqInstructors] = useState<Array<any>>([]);
  const [uniqChannelTypes, setUniqChannelTypes] = useState<Array<any>>([]);
  const [instructor, setInstructor] = useState<any>(null);
  const [channelType, setChannelType] = useState<any>(null);
  const [isInstructorFilter, setIsInstructorFilter] = useState<boolean>(false);
  const [isChannelType, setIsChannelType] = useState<boolean>(false);

  /* Grab the unique channel types and instructor names to add to the filter dropdowns */
  useEffect(() => {
    const { channels } = props;
    const uniqInstr = _.uniq(
      _.map(channels, function (o: ChannelType) {
        return [o.first_name, o.last_name].join();
      })
    );
    const uniqTypes = _.uniq(_.map(channels, "type"));
    setUniqInstructors(uniqInstr);
    setUniqChannelTypes(uniqTypes);
  }, []);

  // TODO: Merge filters operations into one function
  /* Switch for Dropdown boxes */
  const setInstructorFilter = () => {
    setIsInstructorFilter(!isInstructorFilter);
  };
  const setChannelFilter = () => {
    setIsChannelType(!isChannelType);
  };

  /* Filters by selected category and keeps already selected filters */

  const onFilterChannelType = (channelType: string) => {
    setChannelType(channelType);
    const channels = props.channels;
    const filtered = _.filter(
      channels,
      (o: ChannelType) => o.type == channelType
    );
    if (instructor) {
      const instructorFilter = _.filter(
        filtered,
        (o: ChannelType) => [o.first_name, o.last_name].join() === instructor
      );
      props.filter(instructorFilter);
    } else {
      props.filter(filtered);
    }
    setChannelFilter();
  };
  const onFilterInstructor = (instrName: string) => {
    setInstructor(instrName);
    const channels = props.channels;
    const filtered = _.filter(
      channels,
      (o: ChannelType) => [o.first_name, o.last_name].join() == instrName
    );
    if (channelType) {
      const classFilter = _.filter(
        filtered,
        (o: ChannelType) => o.type === channelType
      );
      props.filter(classFilter);
    } else {
      props.filter(filtered);
    }
    setInstructorFilter();
  };
  /* Clears individual filters but keeps remaining filters selected */
  const onClearChannelTypeFilter = () => {
    setChannelType(null);
    const channels = props.channels;
    if (instructor) {
      const filtered = _.filter(
        channels,
        (o: ChannelType) => [o.first_name, o.last_name].join() === instructor
      );
      props.clearFilters(filtered);
    } else {
      onClearFilters();
    }
  };
  const onClearInstructorFilter = () => {
    setInstructor(null);
    const channels = props.channels;
    if (channelType) {
      const filtered = _.filter(
        channels,
        (o: ChannelType) => o.type == channelType
      );
      props.clearFilters(filtered);
    } else {
      onClearFilters();
    }
  };

  /* Clears all filters by replacing the filtered list with the original list*/
  const onClearFilters = () => {
    setChannelType(null);
    setInstructor(null);
    const channels = props.channels;
    const filteredClasses = props.filteredChannels;
    if (filteredClasses) {
      props.clearFilters(channels);
    }
  };
  /* Calculates number of pages*/
  return (
    <Box component="div" style={{ marginTop: "20px" }}>
      <Box
        component="div"
        display="flex"
        style={{ marginTop: "15px", alignItems: "center" }}
      >
        <Typography className={classes.filterPara} variant="h5">
          Filter By
        </Typography>
        {/* Instructor Name Filter */}
        <Box component="div">
          <Button
            className={classes.dropdownText}
            variant="contained"
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={instructorAnchorEl}
            style={{ height: "35px" }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setInstructorFilter()}
          >
            Instructor
          </Button>
          {instructor ? (
            <Chip
              label={instructor}
              color="primary"
              style={{ marginLeft: "10px" }}
              onDelete={() => onClearInstructorFilter()}
            />
          ) : null}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={instructorAnchorEl.current}
          keepMounted
          open={isInstructorFilter}
          onClose={() => setInstructorFilter()}
        >
          {uniqInstructors.map((instrName: string) => (
            <MenuItem
              key={instrName}
              onClick={() => onFilterInstructor(instrName)}
            >
              {instrName}
            </MenuItem>
          ))}
        </Menu>
        {/* Channel Type Filter */}
        <Box component="div">
          <Button
            className={classes.dropdownText}
            variant="contained"
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={channelTypeAnchorEl}
            style={{ marginLeft: "15px", height: "35px" }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setChannelFilter()}
          >
            Channel Type
          </Button>
          {channelType ? (
            <Chip
              label={channelType}
              color="primary"
              onDelete={() => onClearChannelTypeFilter()}
              style={{ marginLeft: "10px" }}
            />
          ) : null}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={channelTypeAnchorEl.current}
          keepMounted
          open={isChannelType}
          onClose={() => setChannelFilter()}
        >
          {uniqChannelTypes.map((channelType: string) => (
            <MenuItem
              key={channelType}
              onClick={() => onFilterChannelType(channelType)}
            >
              {channelType}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
}
