import React, { useState, useEffect } from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import BreadcrumbsContainer from "../../../../components/BreadcrumbsContainer";
import { ChannelVideoItem } from "../../../../providers/instructor/channel-video.provider";
import { ChannelPoseOrSkillListItem } from "../../../../providers/instructor/channel-pose.provider";
import { ChannelExerciseListItem } from "../../../../providers/instructor/channel-exercise.provider";
import {
  AlternativeTextPreview,
  ExerciseSelection,
  PoseSelection,
  VideoSelection,
} from "./components";
import * as channelClassBlockProvider from "../../../../providers/instructor/channel-class-block.provider";
import * as channelVideoProvider from "../../../../providers/instructor/channel-video.provider";
import * as channelPoseOrSkillProvider from "../../../../providers/instructor/channel-pose.provider";
import * as channelExerciseProvider from "../../../../providers/instructor/channel-exercise.provider";
import validate from "validate.js";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
}));

type Props = RouteComponentProps<any> & {
  breadcrumbs: any[];
  title: string;
};

type Params = {
  classId: string;
  channelId: string;
};

validate.validators.customOptional = (
  value: any,
  options: any,
  key: any,
  attributes: any
) => {
  if (key === "pose" && options.radioValue === "Pose" && !value) {
    return "is required";
  }
  if (key === "exercise" && options.radioValue === "Exercise" && !value) {
    return "is required";
  }
  return undefined;
};

export default function CreateItemView(props: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [alternativeText, setAlternativeText] = useState<string>();
  const [videos, setVideos] = useState<ChannelVideoItem[]>([]);
  const [explanationVideo, setExplanationVideo] = useState<ChannelVideoItem>();
  const [practiceVideo, setPracticeVideo] = useState<ChannelVideoItem>();
  const [poses, setPoses] = useState<ChannelPoseOrSkillListItem[]>();
  const [pose, setPose] = useState<ChannelPoseOrSkillListItem>();
  const [exercises, setExercises] = useState<ChannelExerciseListItem[]>();
  const [exercise, setExercise] = useState<ChannelExerciseListItem>();
  const [radioValue, setRadioValue] = useState<string>("Pose");
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});
  const history = useHistory();

  const { classId, channelId } = useParams<Params>();

  useEffect(() => {
    const loadData = async (channelId: string) => {
      try {
        const results = await Promise.all([
          channelPoseOrSkillProvider.getAll(channelId),
          channelVideoProvider.getByChannel(channelId),
          channelExerciseProvider.getAll(channelId),
        ]);
        const poses = results[0];
        setPoses(poses);
        const videos = results[1];
        setVideos(videos);
        const exercises = results[2];
        setExercises(exercises);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    loadData(channelId);
  }, []);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
    setSubmitted({
      ...submitted,
      name: false,
    });
  };

  const handleAlternativeTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //TODO: cut off last row
    //TODO: add preview of text on the wall
    setAlternativeText(event.target.value as string);
  };

  const handlePoseChange = (pose: ChannelPoseOrSkillListItem) => {
    setPose(pose);
    setSubmitted({
      ...submitted,
      pose: false,
    });
  };

  const handleExerciseChange = (exercise: ChannelExerciseListItem) => {
    setExercise(exercise);
    setSubmitted({
      ...submitted,
      exercise: false,
    });
  };

  const handleExplanationVideoChange = (id: string) => {
    const video = videos.find((x) => x.id === id);
    if (video) {
      setExplanationVideo(video);
    }
    setSubmitted({
      ...submitted,
      explanationVideo: false,
    });
  };

  const handlePracticeVideoIdChange = (id: string) => {
    const video = videos.find((x) => x.id === id);
    if (video) {
      setPracticeVideo(video);
    }
    setSubmitted({
      ...submitted,
      practiceVideo: false,
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setRadioValue(value);
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      explanationVideo: {
        presence: { allowEmpty: false, message: "is required" },
      },
      practiceVideo: {
        presence: { allowEmpty: false, message: "is required" },
      },

      exercise: {
        customOptional: { radioValue: radioValue },
      },
      pose: {
        customOptional: { radioValue: radioValue },
      },
    };

    const formData = {
      name: name,
      explanationVideo: explanationVideo ? explanationVideo.id : undefined,
      practiceVideo: practiceVideo ? practiceVideo.id : undefined,
      exercise: exercise ? exercise.id : undefined,
      pose: pose ? pose.id : undefined,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true,
        explanationVideo: true,
        practiceVideo: true,
        exercise: true,
        pose: true,
      });
      return;
    }

    setLoading(true);
    if (explanationVideo && practiceVideo && (exercise || pose)) {
      const item = {
        channel_id: channelId,
        name: formData.name,
        explanation_video_id: formData.explanationVideo,
        alternative_text: alternativeText,
        practice_video_id: formData.practiceVideo,
        exercise_id: formData.exercise,
        pose_id: formData.pose,
      };
      try {
        await channelClassBlockProvider.create(item);
        history.goBack();
      } catch (err) {
        alert("An error occurred while processing your request");
      }
    }
    setLoading(false);
  };

  const data = channelId ? { channelId: channelId } : undefined;
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      {loading && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <VideoSelection
            videos={videos}
            video={explanationVideo}
            inputLabel={"Explanation Video"}
            onVideoIdChange={handleExplanationVideoChange}
            errorMessage={
              hasError("explanationVideo")
                ? errors["explanationVideo"][0]
                : null
            }
            disabled={loading}
          />
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Alternative Text"
              value={alternativeText}
              onChange={handleAlternativeTextChange}
              multiline
              rows="4"
            />
          </Grid>
          {alternativeText !== undefined && (
            <Grid item xs={12}>
              <AlternativeTextPreview text={alternativeText} />
            </Grid>
          )}
          <VideoSelection
            videos={videos}
            video={practiceVideo}
            inputLabel={"Practice Video"}
            onVideoIdChange={handlePracticeVideoIdChange}
            errorMessage={
              hasError("practiceVideo") ? errors["practiceVideo"][0] : null
            }
            disabled={loading}
          />
          <Grid item xs={12}>
            <RadioGroup
              row
              aria-label="timespan-radio"
              name="timespan-radio"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="Pose"
                control={<Radio />}
                label="Select Pose"
              />
              <FormControlLabel
                value="Exercise"
                control={<Radio />}
                label="Select Exercise Set"
              />
            </RadioGroup>
          </Grid>
          {poses && radioValue === "Pose" && (
            <PoseSelection
              poses={poses}
              pose={pose}
              onPoseSelected={handlePoseChange}
              errorMessage={hasError("pose") ? errors["pose"][0] : null}
              disabled={loading}
            />
          )}
          {exercises && radioValue === "Exercise" && (
            <ExerciseSelection
              exercises={exercises}
              exercise={exercise}
              onExerciseSelected={handleExerciseChange}
              errorMessage={hasError("exercise") ? errors["exercise"][0] : null}
              disabled={loading}
            />
          )}
          <Grid className={classes.buttonsContainer} item xs={12}>
            <Button
              disabled={loading}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={loading}
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}
