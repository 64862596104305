import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Slider,
  IconButton,
  ListItemText,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import {
  mediapipeService,
  mediapipeKeypoint,
} from "../../../services/mediapipe";
import Pose3dEditor from "../../../components/editor/pose3d-editor";

import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import {
  drawVideoOnCanvas,
  getBlobFromCanvas,
  getImageFromCanvas,
} from "../../../services/canvas.service";
import { secondsToTimeString } from "../../../utils/conversion.functions";
import { ChannelVideoItem } from "../../../providers/instructor/channel-video.provider";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { drawOriginal } from "../../../services/mediapipe/mediapipe.keypoint";
import HumanRegions from "../../../components/HumanRegions";
import validate from "validate.js";
import InfoIcon from "@material-ui/icons/Info";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    listContainer: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    video: {
      height: "100%",
      width: "100%",
    },
    timespanSlectionControls: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    flippedButton: {
      transform: "rotateY(180deg)",
    },
    error: {
      color: "#e53935",
      marginLeft: 14,
      marginRight: 14,
      fontSize: 11,
      marginTop: 3,
      textAlign: "left",
      
      fontWeight: 400,
      lineheight: 13,
      letterSpacing: 0.33,
    },
    info: {
      display: "flex",
      alignItems: "center",
      padding: "2px 12px !important",
    },
  });

type State = {
  isUiDisabled: boolean;
  name: string;
  keypoints3d: Keypoint3D[];
  sliderValue: number;
  duration?: number;
  step?: number;
  imageBlob?: Blob;
  selectedChannelVideo?: ChannelVideoItem;
  videos: ChannelVideoItem[];
  regions: any;
  errors: any;
  submitted: any;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class CreateItemView extends Component<Props, State> {
  private readonly canvasRef = React.createRef<HTMLCanvasElement>();
  private poseRef = React.createRef<any>();
  private videoRef = React.createRef<HTMLVideoElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isUiDisabled: true,
      name: "",
      keypoints3d: [],
      sliderValue: 0,
      duration: undefined,
      step: undefined,
      videos: [],
      regions: {},
      submitted: {},
      errors: {},
    };
  }

  componentDidMount = async () => {
    try {
      const channelId = this.props.match.params.channelId;
      if (channelId) {
        const startMediapipe = async () => {
          const onLandmarks = async (results: any) => {
            const { poseLandmarks, image } = results;

            if (poseLandmarks) {
              const downscale = (height: number, width: number) => {
                const getScaleMultiplier = (
                  height: number,
                  width: number
                ): number => {
                  const maxValue = 550;
                  if (height >= width) {
                    return maxValue / height;
                  }
                  return maxValue / width;
                };

                const scaleMultiplier = getScaleMultiplier(height, width);
                return {
                  scaledWidth: width * scaleMultiplier,
                  scaledHeight: height * scaleMultiplier,
                };
              };

              const imageHeight = image.height;
              const imageWidth = image.width;

              const canvasElement = this.canvasRef.current;
              if (canvasElement) {
                const { scaledHeight, scaledWidth } = downscale(
                  imageHeight,
                  imageWidth
                );
                canvasElement.width = scaledWidth;
                canvasElement.height = scaledHeight;
                drawOriginal(poseLandmarks, image, canvasElement);
              }

              const mapped = mediapipeKeypoint.mapForAnalysis(
                mediapipeKeypoint.denormalize(
                  poseLandmarks,
                  imageWidth,
                  imageHeight
                )
              );
              this.setState((prevState) => ({
                keypoints3d: mapped,
                isUiDisabled: false,
                submitted: { ...prevState.submitted, avatarData: false },
              }));
            }
          };

          const pose = await mediapipeService.getStaticModel();
          pose.onResults(onLandmarks);
          const poseRef = this.poseRef as React.MutableRefObject<any>;
          poseRef.current = pose;
        };

        await startMediapipe();

        const loadVideos = async () => {
          const videos = await channelVideoProvider.getByChannel(channelId);
          this.setState({
            videos: videos,
          });
        };

        await loadVideos();

        this.setState({
          isUiDisabled: false,
        });
      } else {
        console.warn("channelId not provided");
        this.setState({
          isUiDisabled: false,
        });
      }
    } catch (err) {
      //TODO: handle initialization error
      console.log("componentDidMount:err");
      console.log(err);
      this.setState({
        isUiDisabled: false,
      });
    }
  };

  onSave = async () => {
    const {
      imageBlob,
      name,
      keypoints3d,
      regions,
      selectedChannelVideo,
    } = this.state;

    const formData = {
      name: name,
      video: selectedChannelVideo ? selectedChannelVideo.id : undefined,
      avatarData: keypoints3d.length !== 0 ? keypoints3d : undefined,
    };

    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      video: {
        presence: { allowEmpty: false, message: "is required" },
      },
      avatarData: {
        presence: { allowEmpty: false, message: "is not generated" },
      },
    };

    const errors = validate(formData, schema);
    if (errors) {
      this.setState({
        errors: errors,
        submitted: {
          name: true,
          video: true,
          avatarData: true,
        },
      });
      return;
    }

    this.setState({
      isUiDisabled: true,
    });

    const images = [imageBlob];
    const channelId = this.props.match.params.channelId;
    const model = {
      channelId: channelId,
      images: images,
      name: name,
      keypoints3d: keypoints3d.map((kp) => [kp.x, kp.y, kp.z]),
      regions: Object.keys(regions).reduce((acc: any, curr: string) => {
        if (regions[curr] === true) {
          acc[curr] = true;
        }
        return acc;
      }, {}),
    };
    try {
      await channelPoseOrSkillProvider.create(model);
      this.props.history.goBack();
    } catch (ex) {
      //TODO: switch to toaster
      alert("Unable to process image, please select different one.");
      this.setState({
        isUiDisabled: false,
      });
    }
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      name: event.target.value as string,
      submitted: { ...prevState.submitted, name: false },
    }));
  };

  onLoadedMetadata = () => {
    const video = this.videoRef.current;
    if (video) {
      const duration = video.duration;
      const step = duration / 100;
      this.setState({
        duration,
        step,
        isUiDisabled: false,
      });
    }
  };

  handleSliderChange = (event: any, newValue: number | number[]) => {
    const sliderValue = newValue as number;
    this.setState({
      sliderValue,
    });
    const video = this.videoRef.current;
    if (video) {
      video.currentTime = sliderValue;
    }
  };

  onGenerate = async () => {
    this.setState({
      isUiDisabled: true,
    });
    const video = this.videoRef.current;
    const pose = this.poseRef.current;
    if (video && pose) {
      try {
        const canvas = drawVideoOnCanvas(video);
        const blob = await getBlobFromCanvas(canvas);
        const image = await getImageFromCanvas(canvas);
        this.setState({
          imageBlob: blob,
        });
        await pose.send({ image: image });
      } catch (err) {
        this.setState({
          isUiDisabled: false,
        });
        console.log(err);
      }
    } else {
      this.setState({
        isUiDisabled: false,
      });
    }
  };

  handleChannelVideoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedVideoId = event.target.value as string;
    const selectedChannelVideo = this.state.videos.find(
      (x) => x.id === selectedVideoId
    );

    this.setState((prevState) => ({
      selectedChannelVideo: selectedChannelVideo,
      submitted: {
        ...prevState.submitted,
        video: false,
        avatarData: !prevState.selectedChannelVideo
          ? false
          : prevState.submitted.avatarData,
      },
    }));
  };

  onMoveBackFast = () => {
    let currentTime = this.state.sliderValue - 0.4;
    if (currentTime < 0) {
      currentTime = 0;
    }
    this.setState({
      sliderValue: currentTime,
    });
    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.currentTime = currentTime;
    }
  };

  onMoveBack = () => {
    let currentTime = this.state.sliderValue - 0.02;
    if (currentTime < 0) {
      currentTime = 0;
    }
    this.setState({
      sliderValue: currentTime,
    });
    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.currentTime = currentTime;
    }
  };

  onMoveForward = () => {
    let currentTime = this.state.sliderValue + 0.02;
    if (this.videoRef && this.videoRef.current) {
      if (currentTime > this.videoRef.current.duration) {
        currentTime = this.videoRef.current.duration;
      }

      this.setState({
        sliderValue: currentTime,
      });

      this.videoRef.current.currentTime = currentTime;
    }
  };

  onMoveForwardFast = () => {
    let currentTime = this.state.sliderValue + 0.4;
    if (this.videoRef && this.videoRef.current) {
      if (currentTime > this.videoRef.current.duration) {
        currentTime = this.videoRef.current.duration;
      }

      this.setState({
        sliderValue: currentTime,
      });

      this.videoRef.current.currentTime = currentTime;
    }
  };

  onToggleRegion = (key: string) => {
    const { regions } = this.state;

    if (regions[key]) {
      regions[key] = false;
    } else {
      regions[key] = true;
    }

    this.setState({
      regions: { ...regions },
    });
  };

  hasError = (field: string) => {
    const { submitted, errors } = this.state;
    return submitted[field] && errors[field] ? true : false;
  };

  render = () => {
    const {
      isUiDisabled,
      name,
      keypoints3d,
      sliderValue,
      step,
      duration,
      selectedChannelVideo,
      videos,
      regions,
      errors,
    } = this.state;

    const { classes, title } = this.props;
    const channelId = this.props.match.params.channelId;
    const data = channelId ? { channelId: channelId } : undefined;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        {isUiDisabled && <LinearProgress />}
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                
                label="Name"
                value={name}
                onChange={this.handleNameChange}
                variant="outlined"
                disabled={isUiDisabled}
                helperText={this.hasError("name") ? errors["name"][0] : null}
                error={this.hasError("name")}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                error={this.hasError("video")}
              >
                <InputLabel shrink id="align-by-select-placeholder-label-label">
                  Channel Video
                </InputLabel>
                <Select
                  labelId="align-by-select-placeholder-label-label"
                  value={selectedChannelVideo ? selectedChannelVideo.id : ""}
                  onChange={this.handleChannelVideoChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  disabled={videos.length === 0 || isUiDisabled}
                >
                  {videos.map(({ id, name, thumbnail_path }) => (
                    <MenuItem value={id} key={id}>
                      <Avatar
                        alt={name}
                        src={thumbnail_path}
                        style={{ margin: "0px 10px 0px 0px" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {this.hasError("video") ? errors["video"][0] : null}
                </FormHelperText>
              </FormControl>
            </Grid>
            {selectedChannelVideo && (
              <Grid item xs={12}>
                <video
                  className={classes.video}
                  ref={this.videoRef}
                  playsInline
                  src={selectedChannelVideo.file_path}
                  crossOrigin="anonymous"
                  onLoadedMetadata={this.onLoadedMetadata}
                ></video>
              </Grid>
            )}
            {duration && sliderValue !== undefined && (
              <Grid item xs={12}>
                <Grid item xs={12} className={classes.timespanSlectionControls}>
                  <IconButton
                    aria-label={"Move fast forward"}
                    onClick={this.onMoveBackFast}
                    className={`${classes.button} ${classes.flippedButton}`}
                    disabled={isUiDisabled}
                  >
                    <FastForwardIcon />
                  </IconButton>
                  <IconButton
                    aria-label={"Move back"}
                    onClick={this.onMoveBack}
                    className={classes.button}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <Typography className={classes.timeLabel}>
                    {secondsToTimeString(sliderValue)}
                  </Typography>
                  <IconButton
                    aria-label={"Move forward"}
                    onClick={this.onMoveForward}
                    className={classes.button}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                  <IconButton
                    aria-label={"Move fast forward"}
                    onClick={this.onMoveForwardFast}
                    className={classes.button}
                    disabled={isUiDisabled}
                  >
                    <FastForwardIcon />
                  </IconButton>
                </Grid>
                <Slider
                  track={false}
                  value={sliderValue}
                  aria-labelledby="time-slider"
                  valueLabelDisplay="off"
                  step={step}
                  min={0}
                  max={duration}
                  onChange={this.handleSliderChange}
                  disabled={isUiDisabled}
                />
              </Grid>
            )}
            {duration && sliderValue !== undefined && (
              <Grid item xs={12}>
                <Button
                  disabled={isUiDisabled}
                  onClick={this.onGenerate}
                  variant="contained"
                  color="primary"
                >
                  Generate avatar data
                </Button>
              </Grid>
            )}
            {keypoints3d && keypoints3d.length > 0 && (
              <Grid item xs={12}>
                <Pose3dEditor
                  height={550}
                  width={550}
                  keypoints={keypoints3d}
                  avatarType={AvatarType.Female}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <p className={classes.error}>
                {!this.hasError("video") && this.hasError("avatarData")
                  ? errors["avatarData"][0]
                  : null}
              </p>
            </Grid>

            <Grid item xs={12}>
              {/* TODO: hide when no data or on image update*/}
              <canvas ref={this.canvasRef}></canvas>
            </Grid>
            {keypoints3d && keypoints3d.length > 0 && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 16 }}>
                    Regions of interest
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.info}>
                  <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
                  <Typography>If no regions will be selected, all regions will be used for calculations.</Typography>
                </Grid>
                <HumanRegions
                  selectedRegions={regions}
                  onRegionSelection={this.onToggleRegion}
                />
              </Grid>
            )}
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={isUiDisabled}
              onClick={this.props.history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={isUiDisabled}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(CreateItemView);
export default withRouter(styledComponent as any);
