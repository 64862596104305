import * as THREE from "three";
import logo from "../assets/images/logo_alpha_small.png";
import * as keypoints3DService from "./keypoints3D.service";
import { Keypoint3D } from "../types/analyze/keypoint3d.type";
import { ClassView } from "../types/threejs/class-view.type";
import { BlockVideoType } from "../types/analyze/block-video-type.enum";

const green = new THREE.Color(0x07f107);
const red = new THREE.Color(0xff0000);
const blue = new THREE.Color(0x0000ff);

const matchGreen = new THREE.Color(0xaaf405);
const matchRed = new THREE.Color(0xf26bd8);
const matchBlue = new THREE.Color(0x05eef4);

const matchGray = new THREE.Color(0x707070);
const orange = new THREE.Color(0xffa500);

export const disposeMaterials = (object: any): void => {
  if (object) {
    const keys = Object.keys(object);
    keys.forEach((key) => {
      const material = object[key];
      cleanupMaterial(material);
    });
  }
};

export const disposeThreeDictionary = (object: any): void => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    cleanupObject(object[key]);
  });
};

export const getMatchColorByKey = () => {
  const colorByKey: any = {
    R: matchGreen,
    L: matchRed,
    M: matchBlue,
  };
  return colorByKey;
};

export const getMatchFlippedColorByKey = () => {
  const colorByKey: any = {
    R: matchRed,
    L: matchGreen,
    M: matchBlue,
  };
  return colorByKey;
};

export const getMatchShadowColorByKey = () => {
  const colorByKey: any = {
    R: matchGray,
    L: matchGray,
    M: matchGray,
  };
  return colorByKey;
};

export const getInstructorLowerColorByKey = () => {
  const colorByKey: any = {
    R: orange,
    L: orange,
    M: orange,
  };
  return colorByKey;
};

export const getFlippedColorByKey = () => {
  const colorByKey: any = {
    R: red,
    L: green,
    M: blue,
  };
  return colorByKey;
};

export const getColorByKey = () => {
  const colorByKey: any = {
    R: green,
    L: red,
    M: blue,
  };
  return colorByKey;
};

export const getMaterialsForLines = (colorByKey: any): any => {
  const keys = Object.keys(colorByKey);
  const lineMaterials: any = {};
  keys.forEach((key) => {
    const color = colorByKey[key];
    const lineMaterialOptions: THREE.MeshPhongMaterialParameters = {
      color: color,
      shininess: 50,
    };
    const material = new THREE.MeshPhongMaterial(lineMaterialOptions);
    lineMaterials[key] = material;
  });

  return lineMaterials;
};

export const getMatchMaterialsForLines = (colorByKey: any): any => {
  const keys = Object.keys(colorByKey);
  const lineMaterials: any = {};
  keys.forEach((key) => {
    const color = colorByKey[key];
    const lineMaterialOptions: THREE.MeshPhongMaterialParameters = {
      color: color,
      shininess: 1,
      opacity: 1,
      transparent: true,
    };
    const material = new THREE.MeshPhongMaterial(lineMaterialOptions);
    lineMaterials[key] = material;
  });

  return lineMaterials;
};

export const getLineMaterial = (
  partA: string,
  partB: string,
  materials: any
): THREE.MeshPhongMaterial => {
  if (
    (partA.indexOf("R") === 0 && partB.indexOf("R") === 0) ||
    (partA.indexOf("r") === 0 && partB.indexOf("r") === 0)
  ) {
    return materials["R"];
  }
  if (
    (partA.indexOf("L") === 0 && partB.indexOf("L") === 0) ||
    (partA.indexOf("l") === 0 && partB.indexOf("l") === 0)
  ) {
    return materials["L"];
  }
  return materials["M"];
};

export const addPoint = (
  scene: THREE.Scene,
  keypoint: Keypoint3D,
  pointByPart: any,
  colorByKey: any
): void => {
  const color = getPointColor(keypoint.part, colorByKey);
  const point = getPoint(keypoint, color);
  scene.add(point);
  pointByPart[keypoint.part] = point;
};

export const addLine = (
  scene: THREE.Scene,
  keypoint: Keypoint3D,
  connectedKeypoint: Keypoint3D,
  key: string,
  lineMaterials: any,
  lineByParts: any
): void => {
  const material = getLineMaterial(
    keypoint.part,
    connectedKeypoint.part,
    lineMaterials
  );
  const line = getLine(keypoint, connectedKeypoint, material);
  line.name = key;
  scene.add(line);
  lineByParts[key] = line;
};

export const hidePoint = (point: any): void => {
  if (point) {
    (point.material as THREE.Material).visible = false;
  }
};

export const hideLine = (line: any): void => {
  if (line) {
    (line.material as THREE.Material).visible = false;
    //HACK: because of keypoints naming difference in 2d and 3d to simplify logic we hide points like this
    //TODO: manage properly
    line.position.x = -1000;
    line.position.y = -1000;
    line.position.z = -1000;
  }
};

export const updatePoint = (point: any, keypoint: Keypoint3D): void => {
  const { x, y, z } = keypoint;
  point.position.x = x;
  point.position.y = y;
  point.position.z = z;
  const material = point.material as THREE.Material;
  material.dispose();

  const getColor = (visibility: number) => {
    if (visibility > 0.6) {
      return "green";
    }
    if (visibility > 0.3) {
      return "lightgreen";
    }
    if (visibility > 0.2) {
      return "yellow";
    }
    if (visibility > 0.1) {
      return "orange";
    }
    return "red";
  };

  const newMaterial = new THREE.MeshPhongMaterial({
    color: getColor(keypoint.visibility || 0),
    shininess: 100,
  });

  newMaterial.transparent = true;
  newMaterial.opacity = 0.8;
  point.material = newMaterial;
};

export const updateLine = (
  line: THREE.Mesh,
  keypointA: Keypoint3D,
  keypointB: Keypoint3D,
  isMatchSkeletonLine: boolean = false
): void => {
  const newGeoumetry = getLineGeometry(
    keypointA,
    keypointB,
    isMatchSkeletonLine
  );
  line.geometry.dispose();
  line.geometry = newGeoumetry.clone();
  line.position.x = (keypointA.x + keypointB.x) / 2;
  line.position.y = (keypointA.y + keypointB.y) / 2;
  line.position.z = (keypointA.z + keypointB.z) / 2;
  line.lookAt(keypointB.x, keypointB.y, keypointB.z);

  (line.material as THREE.Material).visible = true;
};

// Standard scene setup in Three.js. Check "Creating a scene" manual for more information
// https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
export const sceneSetup = (): THREE.Scene => {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);
  const grid = getGrid();
  scene.add(grid);

  const lights = getLights();
  lights.forEach((light) => scene.add(light));

  const logoMesh = getLogo();
  scene.add(logoMesh);

  const floor = getFloor();
  scene.add(floor);

  return scene;
};

export const sceneSetup2 = (): THREE.Scene => {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff); 
  scene.add(new THREE.AmbientLight(0x404040, 1));
  return scene;
};

export const getFloor = (): THREE.Mesh => {
  const geometry = new THREE.PlaneGeometry(50, 50, 30, 30);
  const material = new THREE.ShadowMaterial({ opacity: 0.15 });

  const floor = new THREE.Mesh(geometry, material);
  floor.position.y = 0;
  floor.receiveShadow = true;
  floor.rotateX(-Math.PI / 2);

  return floor;
};

//TODO: dispose logo texture, memory leak
const getLogo = (): THREE.Mesh => {
  const image = logo;
  const geometry = new THREE.PlaneBufferGeometry(0.8, 0.4);
  const loader = new THREE.TextureLoader();
  const texture = loader.load(image);
  const planeMaterial = new THREE.MeshPhongMaterial({
    map: texture,
    transparent: true,
  });
  const ground = new THREE.Mesh(geometry, planeMaterial);
  ground.position.set(1.25, 0.005, 0.25);
  ground.rotation.x = -Math.PI / 2;
  ground.rotation.z = Math.PI;
  ground.name = "WizheroLogo";
  return ground;
};

export const getLights = (): THREE.Light[] => {
  const lights = [];

  const lightA = new THREE.AmbientLight(0x404040, 1);
  lights.push(lightA);

  const dirLight = new THREE.DirectionalLight(0xffffff, 1);
  dirLight.position.set(15, 13, -15);
  dirLight.castShadow = true;
  dirLight.shadow.camera.far = 30;
  dirLight.shadow.mapSize.width = 512;
  dirLight.shadow.mapSize.height = 512;
  lights.push(dirLight);

  return lights;
};

export const getRenderer = (mount: HTMLElement): THREE.WebGLRenderer => {
  const width = mount.clientWidth;
  const height = mount.clientHeight;
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  mount.appendChild(renderer.domElement); // mount using React ref
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  return renderer;
};

export const getGrid = (): THREE.GridHelper => {
  const gridColor = new THREE.Color(0xd1d6de);
  const gridSize = 5;
  const gridDivisions = 10;
  const grid = new THREE.GridHelper(
    gridSize,
    gridDivisions,
    gridColor,
    gridColor
  );
  return grid;
};

export const getLine = (
  keypointA: Keypoint3D,
  keypointB: Keypoint3D,
  material: THREE.MeshPhongMaterial,
  isMatchSkeletonLine: boolean = false
): THREE.Mesh => {
  const geometry = getLineGeometry(keypointA, keypointB, isMatchSkeletonLine);
  const line = new THREE.Mesh(geometry, material);
  line.position.x = (keypointA.x + keypointB.x) / 2;
  line.position.y = (keypointA.y + keypointB.y) / 2;
  line.position.z = (keypointA.z + keypointB.z) / 2;
  line.lookAt(keypointB.x, keypointB.y, keypointB.z);

  if (!isMatchSkeletonLine) {
    line.castShadow = true;
  }

  return line;
};

const getLineGeometry = (
  keypointA: Keypoint3D,
  keypointB: Keypoint3D,
  isMatchSkeletonLine: boolean
): THREE.CylinderBufferGeometry => {
  const radiusBottom = 0.04;
  const distance = keypoints3DService.getDistance(keypointA, keypointB);
  const radiusTop = isMatchSkeletonLine
    ? radiusBottom / 1.3
    : radiusBottom / 2.4;
  const geometry = new THREE.CylinderBufferGeometry(
    radiusTop,
    radiusBottom /* radiusBottom*/,
    distance,
    18
  );
  geometry.rotateX(Math.PI / 2);
  return geometry;
};

const getPointSize = (keypoint: Keypoint3D): number => {
  const headPoints = ["REye", "LEye", "REar", "LEar", "Nose"];
  if (headPoints.indexOf(keypoint.part) > -1) {
    return 0.028;
  }

  return 0.08; // 0.04;
};

export const getPoint = (
  keypoint: Keypoint3D,
  color: THREE.Color,
  isMatchSkeletonPoint: boolean = false
): THREE.Mesh => {
  const { x, y, z } = keypoint;
  const size = getPointSize(keypoint);
  const geometry = new THREE.SphereBufferGeometry(size, 32, 32);
  const material = new THREE.MeshPhongMaterial({
    color: color,
    shininess: 100,
  });

  const sphere = new THREE.Mesh(geometry, material);
  if (!isMatchSkeletonPoint) {
    sphere.castShadow = true;
  }
  sphere.position.setX(x);
  sphere.position.setY(y);
  sphere.position.setZ(z);
  return sphere;
};

export const getLineKey = (partA: string, partB: string): string => {
  return `${partA}-${partB}`;
};

export const getPointColor = (part: string, colorByKey: any): THREE.Color => {
  if (part.indexOf("R") === 0 || part.indexOf("r") === 0) {
    return colorByKey["R"];
  }
  if (part.indexOf("L") === 0 || part.indexOf("l") === 0) {
    return colorByKey["L"];
  }
  return colorByKey["M"];
};
//CLASS START

export const renderCourseThree = (
  mount: HTMLDivElement,
  scene: THREE.Scene,
  renderer: THREE.WebGLRenderer,
  view: ClassView
): void => {
  const camera = view.camera;
  if (camera) {
    camera.aspect = mount.clientWidth / mount.clientHeight;
    camera.lookAt(0, 1, 0);
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
  }
};

export const renderCourseThree2 = (
  mount: HTMLDivElement,
  scene: THREE.Scene,
  renderer: THREE.WebGLRenderer,
  view: ClassView,
  type: BlockVideoType
): void => {
  const camera = view.camera;
  if (camera) {
    camera.aspect = mount.clientWidth / mount.clientHeight;
    if (
      (type === BlockVideoType.Explanation ||
        type === BlockVideoType.AlternativeExplanation) &&
      view.eye3dVideo
    ) {
      camera.position.fromArray(view.eye3dVideo);
      camera.lookAt(-2.5, 1.25, 0);
    } else {
      camera.position.fromArray(view.eye3d);
      camera.lookAt(0, 1, 0);
    }
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
  }
};

export const default3dEye = [0.8, 1.4, -3.4]//[3.4, 1.4, -3.4];

export const getCourseView = (mount: HTMLElement): ClassView => {
  const width = mount.clientWidth;
  const height = mount.clientHeight;
  const fov = 35; //34;//35; //65;
  const eye3d = default3dEye; // [3.4, 1.4, -3.4]; //[3.6, 1.6, -3.6]; // //
  const camera = new THREE.PerspectiveCamera(fov, width / height, 1, 50);
  camera.position.fromArray(eye3d);
  const view = {
    eye3d: eye3d,
    fov: fov,
    camera: camera,
    eye3dVideo: [1.75, 1.25, 0]
  };
  return view;
};

export const getCourseRenderer = (
  mount: HTMLDivElement
): THREE.WebGLRenderer => {
  const width = mount.clientWidth;
  const height = mount.clientHeight;
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  mount.appendChild(renderer.domElement); // mount using React ref
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  return renderer;
};
//CLASS END

export const mixamoriUpdate = (
  scene: THREE.Scene,
  keypoints: Keypoint3D[],
  pointByPart: any,
  colorByKeyRef: any,
  lineByParts: any,
  lineMaterials: any
) => {
  keypoints.forEach((keypoint) => {
    const { part } = keypoint;
    //KEYPOINTS
    const point = pointByPart[part];
    if (point) {
      updatePoint(point, keypoint);
    } else {
      addPoint(scene, keypoint, pointByPart, colorByKeyRef);
    }

    // const getLinkedKeypoints = (
    //   keypoints: Keypoint3D[],
    //   part: string
    // ) => {
    //   const linkedParts: any = {
    //     mixamorigHips: [
    //       "mixamorigSpine1",
    //       "mixamorigRightUpLeg",
    //       "mixamorigLeftUpLeg",
    //     ], //0
    //     mixamorigSpine1: ["mixamorigNeck"], //1
    //     mixamorigNeck: ["mixamorigRightArm", "mixamorigLeftArm"],
    //     mixamorigLeftArm: ["mixamorigLeftForeArm"], //2
    //     mixamorigLeftForeArm: ["mixamorigLeftHand"], //3
    //     mixamorigLeftHand: [], //4
    //     mixamorigRightArm: ["mixamorigRightForeArm"], //5
    //     mixamorigRightForeArm: ["mixamorigRightHand"], //6
    //     mixamorigRightHand: [], //7
    //     mixamorigRightUpLeg: ["mixamorigRightLeg"], //8
    //     mixamorigRightLeg: ["mixamorigRightFoot"], //9
    //     mixamorigRightFoot: [], //10
    //     mixamorigLeftUpLeg: ["mixamorigLeftLeg"], //11
    //     mixamorigLeftLeg: ["mixamorigLeftFoot"], //12
    //     mixamorigLeftFoot: [], //16
    //   };
    //   const linkedKeypointsParts = linkedParts[part];
    //   if (!linkedKeypointsParts) {
    //     return [];
    //   }
    //   return keypoints.filter(
    //     (p) => linkedKeypointsParts.indexOf(p.part) > -1
    //   );
    // };
    const getLinkedKeypoints = (keypoints: Keypoint3D[], part: string) => {
      const linkedParts: any = {
        mixamorigHips: [
          "mixamorigSpine1",
          "mixamorigRightUpLeg",
          "mixamorigLeftUpLeg",
          "mixamorigRightFoot",
          "mixamorigLeftFoot",
        ], //0
        mixamorigSpine2: ["mixamorigRightUpLeg", "mixamorigLeftUpLeg"],
        mixamorigSpine1: ["mixamorigNeck"], //1
        mixamorigNeck: ["mixamorigRightArm", "mixamorigLeftArm"],
        mixamorigLeftArm: ["mixamorigLeftForeArm"], //2
        mixamorigLeftForeArm: ["mixamorigLeftHand"], //3
        mixamorigLeftHand: [], //4
        mixamorigRightArm: ["mixamorigRightForeArm"], //5
        mixamorigRightForeArm: ["mixamorigRightHand"], //6
        mixamorigRightHand: [], //7
        mixamorigRightUpLeg: ["mixamorigRightLeg"], //8
        mixamorigRightLeg: ["mixamorigRightFoot"], //9
        mixamorigRightFoot: [], //10
        mixamorigLeftUpLeg: ["mixamorigLeftLeg", "mixamorigRightUpLeg"], //11
        mixamorigLeftLeg: ["mixamorigLeftFoot"], //12
        mixamorigLeftFoot: ["mixamorigRightFoot"], //16
      };
      const linkedKeypointsParts = linkedParts[part];
      if (!linkedKeypointsParts) {
        return [];
      }
      return keypoints.filter((p) => linkedKeypointsParts.indexOf(p.part) > -1);
    };

    //LINES
    const connectedKeypoints = getLinkedKeypoints(keypoints, part);
    connectedKeypoints.forEach((connectedKeypoint) => {
      const key = getLineKey(part, connectedKeypoint.part);
      const line = lineByParts[key];
      if (line) {
        updateLine(line, keypoint, connectedKeypoint);
      } else {
        addLine(
          scene,
          keypoint,
          connectedKeypoint,
          key,
          lineMaterials,
          lineByParts
        );
      }
    });
  });
};

export const getLineElements = (
  axis: Keypoint3D,
  b: Keypoint3D,
  c: Keypoint3D
) => {
  const getLine = (
    keypointA: THREE.Vector3,
    keypointB: THREE.Vector3
  ): THREE.Mesh => {
    const material = new THREE.MeshPhongMaterial({
      color: red,
      shininess: 1,
    });

    const getLineGeometry = (
      keypointA: THREE.Vector3,
      keypointB: THREE.Vector3
    ): THREE.CylinderBufferGeometry => {
      const radius = 0.02;
      const distance = keypointA.distanceTo(keypointB) / 1.9;
      const geometry = new THREE.CylinderBufferGeometry(
        radius / 5,
        radius,
        distance,
        18
      );
      geometry.rotateX(Math.PI / 2);
      return geometry;
    };

    const geometry = getLineGeometry(keypointA, keypointB);
    const line = new THREE.Mesh(geometry, material);
    line.position.x = (keypointA.x + keypointB.x) / 2;
    line.position.y = (keypointA.y + keypointB.y) / 2;
    line.position.z = (keypointA.z + keypointB.z) / 2;
    line.lookAt(keypointB.x, keypointB.y, keypointB.z);

    return line;
  };
  const vectorB = new THREE.Vector3(b.x, b.y, b.z);
  const vectorC = new THREE.Vector3(c.x, c.y, c.z);
  const getMid = (a: THREE.Vector3, b: THREE.Vector3) => {
    return new THREE.Vector3((a.x + b.x) / 2, (a.y + b.y) / 2, (a.z + b.z) / 2);
  };

  const mid = getMid(vectorB, vectorC);

  const axisVector = new THREE.Vector3(axis.x, axis.y, axis.z);

  const distance = vectorC.distanceTo(vectorB);
  const extensionDistance = distance / 5;
  const midStartDistance = mid.distanceTo(axisVector); //100%
  const percent = midStartDistance / 100; //1%

  const extensionPercentage = extensionDistance / percent; //%
  const distanceFactor = 1 + extensionPercentage / 100;
  const extension = new THREE.Vector3();
  extension.addVectors(
    axisVector,
    new THREE.Vector3(mid.x, mid.y, mid.z)
      .sub(axisVector)
      .multiplyScalar(distanceFactor)
  );

  const multiplier = 1.15;

  const midBE = getMid(vectorB, extension);
  const midBEExtension = new THREE.Vector3();
  midBEExtension.addVectors(mid, midBE.sub(mid).multiplyScalar(multiplier));

  const midCE = getMid(vectorC, extension);
  const midCEExtension = new THREE.Vector3();
  midCEExtension.addVectors(mid, midCE.sub(mid).multiplyScalar(multiplier));

  const lineA = getLine(vectorB, midBEExtension);
  const lineB = getLine(midBEExtension, extension);
  const lineC = getLine(extension, midCEExtension);
  const lineD = getLine(midCEExtension, vectorC);
  return [lineA, lineB, lineC, lineD];
};

export const cleanupObject = (object: any) => {
  const { geometry, material } = object;
  if (geometry && geometry.dispose && typeof geometry.dispose === "function") {
    geometry.dispose();
  }

  cleanupMaterial(material);
};

const cleanupMaterial = (material: THREE.Material | THREE.Material[]) => {
  if (Array.isArray(material)) {
    for (let index = 0; index < material.length; index++) {
      const element = material[index];
      if (element && element.dispose && typeof element.dispose === "function") {
        element.dispose();
      }
    }
  } else if (
    material &&
    material.dispose &&
    typeof material.dispose === "function"
  ) {
    material.dispose();
  }
};
