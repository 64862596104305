import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { getRewardColor, getRewardName } from "../../../../../services/reward/reward.service";

type Props = {
  duration: number;
  score: number;
  effectiveness: number;
};

export default function TimespanSummary(props: Props) {
  const { score, effectiveness,duration } = props;
  const getColorForPercentage = (percentage: number) => {
    const pct = percentage / 100;

    const percentColors = [
      { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
      { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
      { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
    ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  };
  return (
    <div
      style={{
        width: "100%",
        height: 570,
        backgroundColor: "rgba(27, 27, 27, 0.7)",
        padding: 30,
        borderRadius: 20,
        color: "white",
        fontWeight: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >     
      <div
        style={{
          width: "100%",
          fontSize: 40,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: 100,
            }}
          >
            {Math.round(duration)}
          </span>
          <span
            style={{
              fontSize: 50,
              fontWeight: 900,
            }}
          >
            Sec.
          </span>
        </div>
        <div>
          <div
            style={{
              width: 350,
              margin: "auto",
            }}
          >
          <CircularProgressbarWithChildren
              value={score}
              strokeWidth={4}
              styles={buildStyles({
                pathColor: getRewardColor(score),
                trailColor: "transparent",
              })}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: 80,
                  }}
                >
                  <strong>{Math.round(score)}</strong>
                </span>
                <span
                  style={{
                    fontSize: 30,
                  }}
                >
                  Score
                </span>
                <span
                  style={{
                    fontSize: 30,
                  }}
                >
                  {getRewardName(score)}
                </span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>      
      </div>
      <div
        style={{
          width: "100%",
          height: "25%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            height: 160,
          }}
        >
          <CircularProgressbar
            value={effectiveness / 2}
            strokeWidth={4}
            styles={buildStyles({
              rotation: -0.25,
              pathColor: getColorForPercentage(effectiveness),
              trailColor: "transparent",
            })}
          />
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontSize: 60 }}>{effectiveness}</span>
              <span style={{ fontSize: 30 }}>%</span>
            </p>
            <span style={{ fontSize: 25 }}>Effectiveness</span>
          </div>
        </div>
      </div>
    </div>
  );
}
