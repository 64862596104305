import React, { Component } from "react";
import {
  IconButton,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { VideoToolbar, VideoCard } from "./components";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelVideoProvider from "../../../providers/student/channel-video.provider";
import { ChannelVideoItem } from "../../../providers/student/channel-video.provider";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  videos: Array<ChannelVideoItem>;
  loading: boolean;
};

type Props = {
  classes: any;
  channelId: string;
};

class ChannelVideoList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      videos: [],
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      const videos = await channelVideoProvider.getAll(this.props.channelId);
      this.setState({
        videos: videos,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  render = () => {
    const { videos, loading } = this.state;

    const { classes, channelId } = this.props;

    return (
      <div className={classes.root}>
        <VideoToolbar channelId={channelId} />
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {videos.map((video) => (
                <Grid item key={video.id} lg={3} md={4} sm={6} xs={12}>
                  <VideoCard video={video} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {/* <div className={classes.pagination}>
          <Typography variant="caption">1-6 of 20</Typography>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton>
            <ChevronRightIcon />
          </IconButton>
        </div> */}
      </div>
    );
  };
}

export default withStyles(styles)(ChannelVideoList);
