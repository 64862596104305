import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { apiUrlBase } from "../../const/const";

export type ChannelPoseOrSkill = {
  id: string;
  name: string;
  keypoints: Array<Keypoint3D>;
  thumbnail_path: string;
  channel_id: string;
  regions: { [key: string]: boolean }
};

export type ChannelPoseOrSkillListItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  is_default: boolean;
  flipped_path: string;
};

export const get = async (itemId: string): Promise<ChannelPoseOrSkill> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/get",
    data: {
      id: itemId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelPoseOrSkillListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/getAll",
    data: {
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("channel_id", item.channelId);
  formData.append("name", item.name);
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("data", JSON.stringify(item.keypoints3d));
  formData.append("regions", JSON.stringify(item.regions));

  for (let index = 0; index < item.images.length; index++) {
    const file = item.images[index];
    formData.append(`files[${index}]`, file);
  }
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/edit",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const remove = async (id: string, channelId: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/remove",
    data: {
      id: id,
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const skeleton = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/pose/skeleton",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
