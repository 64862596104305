import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { getBadgeImage } from "../../../../../../../services/reward/reward.service";
import { RewardType } from "../../../../../../../enums/reward-type.enum";

const useStyles = makeStyles((theme: Theme) => ({
  reward: {
    width: 200,
    height: 200,
    objectFit: "contain",
  },
  counter: {
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: "#fff",
    height: 45,
    width: 45,
    top: 38,
    left: 77,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
    fontWeight: 500,
  },
}));

type Props = {
  rewardType: RewardType;
  value: any;
};

export default function ActivityCard(props: Props) {
  const { rewardType, value } = props;
  const classes = useStyles();
  return (
    <div style={{ position: "relative" }}>
      <img
        title={RewardType[rewardType]}
        alt={RewardType[rewardType]}
        src={getBadgeImage(rewardType)}
        className={classes.reward}
      />
      <div className={classes.counter}>{value}</div>
    </div>
  );
}
