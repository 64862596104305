import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Menu, MenuItem } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useRef } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { InstructorListItem } from "../../../../../../../providers/student/channel-class.provider";
import { Activity } from "../../../../../../../providers/common/activity.provider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  filterContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  filterPara: {
    display: "inline",
    paddingRight: "20px",
  },
  dropdownText: {
    
    textTransform: "none",
  },
  clearText: {
    
    textTransform: "none",
  },
}));

type Props = {
  onFilterChange: (data: { [key: string]: string | undefined }) => void;
  instructors: Array<InstructorListItem>;
  activities: Array<Activity>;
};

export default function FilterClasses(props: Props) {
  const { activities, instructors, onFilterChange } = props;
  const classes = useStyles();
  const classAnchorEl = useRef<any>(null);
  const instructorAnchorEl = useRef<any>(null);
  const [instructor, setInstructor] =
    useState<InstructorListItem>();
  const [activity, setActivity] = useState<Activity>();

  const [isInstructorMenuOpened, setIsInstructorMenuOpened] =
    useState<boolean>(false);
  const openInstructorMenu = () => {
    setIsInstructorMenuOpened((prevValue) => !prevValue);
  };
  const onInstructorSelected = (instructor: InstructorListItem) => {
    setInstructor(instructor);
    openInstructorMenu();
  };

  const [isActivityMenuOpened, setIsActivityMenuOpened] =
    useState<boolean>(false);
  const openActivityFilter = () => {
    setIsActivityMenuOpened((prevValue) => !prevValue);
  };
  const onActivitySelected = (activity: Activity) => {
    setActivity(activity);
    openActivityFilter();
  };

  /* Clears individual filters but keeps remaining filters selected */
  const onClearChannelFilter = () => {
    setActivity(undefined);
  };
  const onClearInstructorFilter = () => {
    setInstructor(undefined);
  };

  useEffect(() => {
    onFilterChange({
      instructorId: instructor ? instructor.id : undefined,
      activityId: activity ? activity.id : undefined,
    });
  }, [activity, instructor]);

  return (
    <Box component="div" style={{ marginTop: "20px" }}>
      <Box
        component="div"
        display="flex"
        style={{ marginTop: "15px", alignItems: "center" }}
      >
        {/* Instructor Name Filter */}
        <Box component="div">
          <Button
            className={classes.dropdownText}
            variant="contained"
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={instructorAnchorEl}
            style={{ height: "35px" }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={openInstructorMenu}
          >
            Instructor
          </Button>
          {instructor ? (
            <Chip
              label={instructor.name}
              color="primary"
              style={{ marginLeft: "10px" }}
              onDelete={() => onClearInstructorFilter()}
            />
          ) : null}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={instructorAnchorEl.current}
          keepMounted
          open={isInstructorMenuOpened}
          onClose={openInstructorMenu}
        >
          {instructors.map((instructor) => (
            <MenuItem
              onClick={() => onInstructorSelected(instructor)}
              key={instructor.id}
            >
              {instructor.name}
            </MenuItem>
          ))}
        </Menu>
        {/* Activity Filter */}
        <Box component="div">
          <Button
            className={classes.dropdownText}
            variant="contained"
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={classAnchorEl}
            style={{ marginLeft: "15px", height: "35px" }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => openActivityFilter()}
          >
            Class Type
          </Button>
          {activity ? (
            <Chip
              label={activity.name}
              color="primary"
              onDelete={() => onClearChannelFilter()}
              style={{ marginLeft: "10px" }}
            />
          ) : null}
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={classAnchorEl.current}
          keepMounted
          open={isActivityMenuOpened}
          onClose={openActivityFilter}
        >
          {activities.map((activity) => (
            <MenuItem
              onClick={() => onActivitySelected(activity)}
              key={activity.id}
            >
              {activity.name}
            </MenuItem>
          ))}
        </Menu>
        <Button
          className={classes.clearText}
          style={{ marginLeft: "20px" }}
          component="h5"
          variant="outlined"
          size="small"
          color="primary"
          onClick={openActivityFilter}
        >
          Clear Filters
        </Button>
      </Box>
    </Box>
  );
}
