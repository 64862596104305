import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  StudentCanvas,
  SnapshotSkeletonsPlaceholder,
  SoundControl,
  InstructorVideoPractice,
  InstructorSoundControl,
  Skip,
} from "../../../components/Analysis";
import { Button, CircularProgress, Grid, Modal } from "@material-ui/core";

import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";

import * as channelClassProvider from "../../../providers/student/channel-class-practice.provider";
import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";

import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import {
  checkIfLandmarkssWithinImage,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import { RewardType } from "../../../enums/reward-type.enum";
import * as audioService from "../../../services/audio.service";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { Landmark } from "@mediapipe/pose";
import * as speechHelper from "../../../services/speech.helper";
import { LiveModelCombinedPractice } from "../../../components/Analysis/LiveModelCombined";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import { BlockVideoType } from "../../../types/analyze/block-video-type.enum";
import { LiveClassPracticeModel } from "../../../types/analyze/live-class-practice.type";
import * as THREE from "three";

const selfieMode = true;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  container: {
    position: "relative",
    height: window.innerHeight - 64,
    width: "100%",
  },
  controls: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    bottom: 5,
    right: 5,
    zIndex: 100,
  },
  studentCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s ease-in",
    pointerEvents: "none",
  },
  startClassButton: {
    position: "absolute",
    top: "50%",
    left: "15%",
    pointerEvents: "all",
  },
  startClassButtonWithoutExplanation: {
    position: "absolute",
    top: "50%",
    right: "15%",
    pointerEvents: "all",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid red",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

type Params = {
  classId?: string;
  modelTypeString: string;
};

export default function AnalyzeCourse() {
  const pauseTimeoutRef = useRef<NodeJS.Timer>();
  const classes = useStyles();
  const [live, setLive] = useState<LiveClassPracticeModel>();

  const [skipExplanations, setSkipExplanations] = useState<boolean>(false);

  const { classId, modelTypeString } = useParams<Params>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [blocks, setBlocks] = useState<ClassPracticeAnalysisBlock[]>();
  const [currentBlock, setCurrentBlock] =
    useState<ClassPracticeAnalysisBlock>();

  const [currentBlockPracticeIteration, setCurrentBlockPracticeIteration] =
    useState<number>(0);

  const [currentBlockVideoType, setCurrentBlockVideoType] =
    useState<BlockVideoType>(BlockVideoType.None);
  const [avatarType, setAvatarType] = useState<AvatarType>(AvatarType.None);
  const [isBellEnabled, setIsBellEnabled] = useState<boolean>(true);
  const [
    displayOutOfFrameNotificationBorder,
    setDisplayOutOfFrameNotificationBorder,
  ] = useState<boolean>(false);

  const [stream, setStream] = useState<MediaStream>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [streamErrorMessage, setStreamErrorMessage] = useState<string>();

  const onTimeUpdate = async (currentTime: number) => {
    const instructorVideoElement = instructorVideoRef.current;
    if (
      currentBlock &&
      instructorVideoElement &&
      currentBlockVideoType === BlockVideoType.Practice
    ) {
      const practiceVideoDuration = instructorVideoElement.duration;
      const practiceTime =
        currentBlockPracticeIteration === 0
          ? currentTime
          : currentBlockPracticeIteration * practiceVideoDuration + currentTime;

      if (practiceTime >= currentBlock.practice_duration_seconds) {
        if (skipExplanations === false && blocks) {
          const nextBlockIndex = currentBlock.order + 1;
          const nextBlock =
            nextBlockIndex <= blocks.length - 1
              ? blocks[nextBlockIndex]
              : undefined;
          if (nextBlock) {
            setCurrentBlock(nextBlock);
            setCurrentBlockVideoType(BlockVideoType.Explanation);
            instructorVideoElement.src = nextBlock.explanation_file_path;
            instructorVideoElement.loop = false;
            instructorVideoElement.play();
          } else {
            setCurrentBlockVideoType(BlockVideoType.None);
            setCurrentBlock(undefined);
          }
        }

        if (skipExplanations === true && blocks) {
          const nextBlockIndex = currentBlock.order + 1;
          const nextBlock =
            nextBlockIndex <= blocks.length - 1
              ? blocks[nextBlockIndex]
              : undefined;
          if (nextBlock) {
            setCurrentBlock(nextBlock);
            instructorVideoElement.src = nextBlock.practice_file_path;
            if (nextBlock.alternative_text) {
              setCurrentBlockVideoType(BlockVideoType.AlternativeExplanation);
              setTimeout(async () => {
                await instructorVideoElement.play();
                setCurrentBlockVideoType(BlockVideoType.Practice);
              }, 5 * 1000);
            } else {
              await instructorVideoElement.play();
              setCurrentBlockVideoType(BlockVideoType.Practice);
            }
          } else {
            instructorVideoElement.pause();
            setCurrentBlockVideoType(BlockVideoType.None);
            setCurrentBlock(undefined);
          }
        }
        setCurrentBlockPracticeIteration(0);
      }
    }
  };

  const onInstructorVideoEnded = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (!instructorVideoElement) {
      return;
    }

    if (currentBlockVideoType === BlockVideoType.Explanation && currentBlock) {
      setCurrentBlockVideoType(BlockVideoType.Practice);
      instructorVideoElement.src = currentBlock.practice_file_path;
      instructorVideoElement.play();
    }
    if (currentBlockVideoType === BlockVideoType.Practice) {
      instructorVideoElement.play();
      setCurrentBlockPracticeIteration(currentBlockPracticeIteration + 1);
    }
  };

  const onPlayBeep = () => {
    if (isBellEnabled) {
      audioService.beep();
    }
  };

  const onToggleBell = () => {
    setIsBellEnabled(!isBellEnabled);
  };

  const instructorVideoRef = useRef<HTMLVideoElement>(null);

  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };

  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastBlockId, setLastBlockId] = useState<string>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [lastInstructorVideoPaused, setLastInstructorVideoPaused] = useState<
    boolean | undefined
  >(false);
  const [canStartClass, setCanStartClass] = useState<boolean>(false);
  const [isClassStarted, setIsClassStarted] = useState<boolean>(false);
  const fontRef = useRef<THREE.Font>();

  const onRepetition = (text: string) => {
    if (isBellEnabled) {
      speechHelper.speak(text);
    }
  };

  const onSaveReward = (
    reward: RewardType,
    blockId: string,
    blockOrder: number
  ) => {};

  const onExercisePeakReached = (
    accuracyData: { [key: number]: number },
    live: LiveClassPracticeModel,
    repetition: number
  ) => {};

  const onPoseAccuracyCalculated = (
    accuracy: number,
    live: LiveClassPracticeModel
  ) => {};

  useEffect(() => {
    const initVideoStream = async () => {
      const video = {
        width: 640,
        height: 480,
        //TODO: try to limit processing by fps
      };
      const constraints = {
        audio: false,
        video: video,
      };

      const getMedia = async (constraints: MediaStreamConstraints) => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          return stream;
        } catch (err) {
          const getErrorMessage = (errorName: string) => {
            if (errorName === "NotAllowedError") {
              return "In order to proceed - give permission to access camera";
            }

            if (errorName === "NotFoundError") {
              return "Camera device not found";
            }
            return "An error occurred while accessing camera";
          };

          const errorMessage = getErrorMessage(err.name);
          setStreamErrorMessage(errorMessage);
          handleOpen();
          //If the user denies permission, or matching media is not available, then the promise is rejected with NotAllowedError or NotFoundError respectively
        }
      };

      const stream = await getMedia(constraints);
      if (stream) {
        setStream(stream);
      }
    };

    initVideoStream();
  }, []);

  useEffect(() => {
    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();
      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const initVideoElement = (stream: MediaStream) => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.crossOrigin = "anonymous";
      const onCanPlayHandler = async () => {
        videoElement.play();
        const model = mediapipeModelRef.current;
        await model.send({ image: videoElement });
        videoElement.removeEventListener("canplay", onCanPlayHandler);
      };

      videoElement.addEventListener("canplay", onCanPlayHandler);
      videoElement.srcObject = stream;
      studentVideoElementRef.current = videoElement;
    };

    const parseAvatarType = (typeString: string) => {
      if (typeString === "1") {
        setAvatarType(AvatarType.Male);
      }
      if (typeString === "2") {
        setAvatarType(AvatarType.Female);
      }
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00035; //0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const instructorVideoElement = instructorVideoRef.current;
      if (!instructorVideoElement) {
        return;
      }

      const { blocks } = await channelClassProvider.getForAnalysis(classId);
      blocks.sort((a, b) => a.order - b.order);
      setBlocks(blocks);
    };

    const loadFont = async () => {
      const fontLoader = new THREE.FontLoader();
      const font = await fontLoader.loadAsync("/fonts/Roboto_Regular.json");
      fontRef.current = font;
    };

    const initialize = async (
      classId: string,
      modelTypeString: string,
      stream: MediaStream
    ) => {
      parseAvatarType(modelTypeString);
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      await loadFont();
      initVideoElement(stream);
      speechHelper.initSpeech();

      setCanStartClass(true);
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
          }
        }
      }
    };

    if (classId && stream) {
      initialize(classId, modelTypeString, stream);
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;

    if (videoElement) {
      const model = mediapipeModelRef.current;
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        //TODO: check if needed?
        const currentTime = instructorVideoElement.currentTime;
        setLastCurrentTime(currentTime);
        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        //TODO: check if needed?
        if (currentBlock) {
          setLastBlockId(currentBlock.id);
        } else {
          setLastBlockId(undefined);
        }

        const instructorVideo = instructorVideoRef.current;
        if (instructorVideo) {
          setLastInstructorVideoPaused(instructorVideo.paused);
        } else {
          setLastInstructorVideoPaused(undefined);
        }
        await model.send({ image: videoElement });
      }
    }
  };

  useEffect(() => {
    const onResults = async (
      results: any,
      blocks: ClassPracticeAnalysisBlock[]
    ) => {
      const { poseLandmarks, image } = results;

      if (poseLandmarks && blocks) {
        const processLandmarks = (
          poseLandmarks: Landmark[],
          blocks: ClassPracticeAnalysisBlock[]
        ) => {
          const filterKalmanUnity = (
            measurements: Measurement3D[],
            joints: Landmark[],
            customKalmanFilter: CustomKalmanFilter
          ) => {
            const filteredUnity = applyKalmanFilterUnity(
              measurements,
              joints,
              customKalmanFilter
            );
            if (filteredUnity) {
              setMeasurements(filteredUnity);
              const mappedUnity = filteredUnity.map((f, index: number) => {
                return {
                  x: f.pos3d.x,
                  y: f.pos3d.y,
                  z: f.pos3d.z,
                  visibility: joints[index].visibility,
                };
              });
              return mappedUnity;
            }
          };

          const canvasElement = canvasRef.current;
          if (!canvasElement) {
            return;
          }

          const block = blocks.find((x) => x.id === lastBlockId);

          const getFilter = (block: ClassPracticeAnalysisBlock | undefined) => {
            if (block && block.exercise) {
              return customKalmanFilterFastRef.current;
            }
            return customKalmanFilterSlowRef.current;
          };

          const filter = getFilter(block);
          if (!filter) {
            return;
          }

          const filtered = filterKalmanUnity(
            measurements,
            poseLandmarks,
            filter
          );
          if (!filtered) {
            return;
          }

          const { width, height } = canvasElement;
          const denormalized = mediapipeKeypoint.denormalize(
            filtered,
            width,
            height
          );
          const studentKeypointsScaledByZ = mapForAnalysis(denormalized);
          drawOriginal(filtered, image, canvasElement);
          const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
            denormalized,
            width,
            height
          );

          if (lastCurrentTime !== undefined && lastTimestamp) {
            const live: LiveClassPracticeModel = {
              studentKeypoints: studentKeypointsScaledByZ,
              blockTime: lastCurrentTime,
              timestamp: lastTimestamp,
              isLandmarksWithinImage: isLandmarksWithinImage,
              studentActivityBlockId: undefined,
              isInstructorVideoPaused: lastInstructorVideoPaused,
            };

            setLive(live);
          } else {
            setLive(undefined);
            const canvasElement = canvasRef.current;
            if (canvasElement) {
              drawOriginal([], image, canvasElement);
            }
          }
        };
        processLandmarks(poseLandmarks, blocks);
      } else {
        setLive(undefined);
        const canvasElement = canvasRef.current;
        if (canvasElement) {
          drawOriginal([], image, canvasElement);
        }
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results && blocks) {
      onResults(results, blocks);
    }
  }, [results]);

  const onCountdownEnded = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && instructorVideoElement.paused) {
      await instructorVideoElement.play();
    }
  };

  const onStartClass = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (blocks && blocks.length > 0 && instructorVideoElement) {
      const currentBlock = blocks[0];
      setCurrentBlock(currentBlock);
      setCurrentBlockVideoType(BlockVideoType.Explanation);
      instructorVideoElement.src = currentBlock.explanation_file_path;
      await instructorVideoElement.play();
    }
    setIsClassStarted(true);
  };

  const onStartClassWithoutExplanation = async () => {
    setSkipExplanations(true);
    const instructorVideoElement = instructorVideoRef.current;
    if (blocks && blocks.length > 0 && instructorVideoElement) {
      const currentBlock = blocks[0];
      if (currentBlock && currentBlock.alternative_text) {
        setCurrentBlockVideoType(BlockVideoType.AlternativeExplanation);
        setCurrentBlock(currentBlock);
        instructorVideoElement.src = currentBlock.practice_file_path;
        await instructorVideoElement.play();
        instructorVideoElement.pause();
        setTimeout(async () => {
          setCurrentBlockVideoType(BlockVideoType.Practice);
        }, 5 * 1000);
      } else {
        setCurrentBlockVideoType(BlockVideoType.Practice);
        setCurrentBlock(currentBlock);
        instructorVideoElement.src = currentBlock.practice_file_path;
        await instructorVideoElement.play();
        instructorVideoElement.pause();
      }
    }
    setIsClassStarted(true);
  };

  const skipExplanation = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (currentBlock && instructorVideoElement) {
      setCurrentBlockVideoType(BlockVideoType.Practice);
      instructorVideoElement.src = currentBlock.practice_file_path;
      await instructorVideoElement.play();
    }
  };

  useEffect(() => {
    const onChange = async (
      isClassStarted: boolean,
      live: LiveClassPracticeModel | undefined
    ) => {
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement && isClassStarted) {
        const isLandmarksWithinImage = live && live.isLandmarksWithinImage;
        if (
          !isLandmarksWithinImage &&
          !instructorVideoElement.paused &&
          !pauseTimeoutRef.current &&
          currentBlockVideoType === BlockVideoType.Practice
        ) {
          setDisplayOutOfFrameNotificationBorder(true);
          const timeoutDuration = 5000;
          pauseTimeoutRef.current = setTimeout(() => {
            setDisplayOutOfFrameNotificationBorder(false);
            instructorVideoElement.pause();
          }, timeoutDuration);
        }
        if (isLandmarksWithinImage && pauseTimeoutRef.current) {
          clearTimeout(pauseTimeoutRef.current);
          pauseTimeoutRef.current = undefined;
          setDisplayOutOfFrameNotificationBorder(false);
        }
      }
    };

    onChange(isClassStarted, live);
  }, [isClassStarted, live]);

  useEffect(() => {
    if (
      currentBlockVideoType !== BlockVideoType.Practice &&
      pauseTimeoutRef.current
    ) {
      clearTimeout(pauseTimeoutRef.current);
      pauseTimeoutRef.current = undefined;
    }
  }, [currentBlockVideoType]);

  const getInstructorVideoCurrentTime = (
    instructorVideoElement: HTMLVideoElement
  ) => {
    if (currentBlockVideoType === BlockVideoType.Practice) {
      const { duration, currentTime } = instructorVideoElement;
      return currentBlockPracticeIteration === 0
        ? currentTime
        : currentBlockPracticeIteration * duration + currentTime;
    } else {
      return instructorVideoElement.currentTime;
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2>Error</h2>
          <p>{streamErrorMessage}</p>
        </div>
      </Modal>
      {!isProcessingStarted && <CircularProgress />}
      <Grid
        container
        spacing={1}
        style={{ visibility: !isProcessingStarted ? "hidden" : "visible" }}
      >
        <Grid className={classes.container} item xs={12}>
          {studentVideoElementRef.current &&
            canvasRef.current &&
            instructorVideoRef.current &&
            canvasRef.current &&
            isClassStarted &&
            blocks &&
            fontRef.current && (
              <LiveModelCombinedPractice
                live={live}
                instructorVideo={instructorVideoRef.current}
                studentCanvas={canvasRef.current}
                avatarType={avatarType}
                selfieMode={selfieMode}
                currentTime={instructorVideoRef.current.currentTime}
                onPoseAccuracyCalculated={onPoseAccuracyCalculated}
                onExercisePeakReached={onExercisePeakReached}
                onSaveReward={onSaveReward}
                onRepetition={onRepetition}
                onPlayBeep={onPlayBeep}
                currentBlock={currentBlock}
                currentBlockVideoType={currentBlockVideoType}
                blocks={blocks}
                font={fontRef.current}
                displayOutOfFrameNotificationBorder={
                  displayOutOfFrameNotificationBorder
                }
                videoPartCurrentTime={getInstructorVideoCurrentTime(
                  instructorVideoRef.current
                )}
                skipExplanations={skipExplanations}
              />
            )}
          {(!canvasRef.current || !instructorVideoRef.current) && (
            <SnapshotSkeletonsPlaceholder />
          )}
          <Grid className={classes.controls} item>
            {currentBlockVideoType === BlockVideoType.Explanation && (
              <Skip clickHandler={skipExplanation} />
            )}
            {instructorVideoRef.current && (
              <InstructorSoundControl
                onToggleSound={onToggleInstructorVideoSound}
                isAudioEnabled={!instructorVideoRef.current.muted}
              />
            )}
            <SoundControl
              isAudioEnabled={isBellEnabled}
              onToggleSound={onToggleBell}
            />
          </Grid>
          <InstructorVideoPractice
            videoRef={instructorVideoRef}
            onEnded={onInstructorVideoEnded}
            onTimeUpdate={onTimeUpdate}
          />

          <Grid
            className={classes.studentCanvas}
            style={{
              opacity:
                instructorVideoRef.current &&
                instructorVideoRef.current.paused &&
                (!isClassStarted ||
                  currentBlockVideoType === BlockVideoType.Practice)
                  ? 1
                  : 0,
            }}
          >
            <StudentCanvas
              canvasRef={canvasRef}
              isLandmarksWithinImage={!!(live && live.isLandmarksWithinImage)}
              onCountdownEnded={onCountdownEnded}
              isClassStarted={isClassStarted}
              isProcessingStarted={isProcessingStarted}
            />

            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButton}
                onClick={onStartClass}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start with Explanation
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButtonWithoutExplanation}
                onClick={onStartClassWithoutExplanation}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start without Explanation
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
