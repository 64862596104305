//TODO: switch from base64 thumbnails to blobs
export const getBase64ImageFromVideo = (
  videoElement: HTMLVideoElement
): string => {
  const canvas = drawVideoOnCanvas(videoElement);
  const base64Image = canvas.toDataURL("image/jpeg", 1.0);
  return base64Image;
};

export const getImageFromVideo = (
  videoElement: HTMLVideoElement
): Promise<HTMLImageElement> => {
  const canvas = drawVideoOnCanvas(videoElement);
  //TODO: replace with blob?
  const dataURL = canvas.toDataURL("image/jpeg", 1.0);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (err: any) => {
      reject(err);
    };
    image.src = dataURL;
  });
};

export const getImageFromCanvas = (
  canvas: HTMLCanvasElement
): Promise<HTMLImageElement> => {
  const dataURL = canvas.toDataURL("image/jpeg", 1.0);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (err: any) => {
      reject(err);
    };
    image.src = dataURL;
  });
};

export const getBlobFromCanvas = (canvas: HTMLCanvasElement): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
        } else {
          resolve(blob);
        }
      },
      "image/jpeg",
      1.0
    );
  });
};

export const getBlobFromVideo = (
  videoElement: HTMLVideoElement
): Promise<Blob> => {
  const canvas = drawVideoOnCanvas(videoElement);
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
        } else {
          resolve(blob);
        }
      },
      "image/jpeg",
      1.0
    );
  });
};

export const drawVideoOnCanvas = (
  videoElement: HTMLVideoElement
): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  canvas.height = videoElement.videoHeight;
  canvas.width = videoElement.videoWidth;
  videoElement.height = videoElement.videoHeight;
  videoElement.width = videoElement.videoWidth;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(
      videoElement,
      0,
      0,
      videoElement.videoWidth,
      videoElement.videoHeight
    );
  }
  return canvas;
};

export const drawVideoOnCanvas2 = (
  videoElement: HTMLVideoElement,
  canvas: HTMLCanvasElement
) => {
  canvas.height = videoElement.height;
  canvas.width = videoElement.width;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(
      videoElement,
      0,
      0,
      videoElement.videoWidth,
      videoElement.videoHeight
    );
  }
};

//TODO: move to different service
export const waitForMetadata = (
  videoElement: HTMLVideoElement,
  video: File
): Promise<void> => {
  return new Promise((resolve) => {
    videoElement.src = URL.createObjectURL(video);
    const onLoadedMetaData = () => {
      videoElement.removeEventListener("loadedmetadata", onLoadedMetaData);
      resolve();
    };
    videoElement.addEventListener("loadedmetadata", onLoadedMetaData);
  });
};

export const fileToJpg = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillStyle = "#fff"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
      } else {
        reject();
      }

      const base64Image = canvas.toDataURL("image/jpeg", 1.0);
      resolve(base64Image);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = URL.createObjectURL(file);
  });
};

export const getImageFromSrc = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (err: any) => {
      reject(err);
    };
    image.src = src;
  });
};