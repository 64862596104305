import React from "react";
import PropTypes from "prop-types";

const VideoPlayer = (props) => {
  const { video } = props;
  return (
    <video
      crossOrigin="anonymous"
      controls
      src={video.file_path}
      poster={video.thumbnail_path}
    ></video>
  );
};

VideoPlayer.propTypes = {
  className: PropTypes.string,
  video: PropTypes.object.isRequired,
};

export default VideoPlayer;
