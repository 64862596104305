import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as studentActivityTimespanProvider from "../../../providers/student/activity-timespan.provider";
import * as studentActivityTimespanRecordProvider from "../../../providers/student/activity-timespan-record.provider";
import { ExerciseChart, PoseChart } from "./components";
import { StudentActivityTimespanPoseRecord } from "../../../types/activities/student-activity-timespan-pose-record";
import { StudentActivityTimespanExerciseRecord } from "../../../types/activities/student-activity-timespan-exercise-record";
import { StudentActivityTimespanDetails } from "../../../types/activities/student-activity-timespan-details";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  breadcrumbs: any[];
  title: string;
};

type Params = {
  timespanId: string;
};

export default function ActivityTimespanDetails(props: Props) {
  const classes = useStyles();
  const [poseGroups, setPoseGroups] = useState<
    StudentActivityTimespanPoseRecord[][]
  >([]);

  const [timespan, setTimespan] = useState<
    StudentActivityTimespanDetails | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { timespanId } = useParams<Params>();

  const [exerciseGroups, setExerciseGroups] = useState<
    StudentActivityTimespanExerciseRecord[][]
  >([]);

  useEffect(() => {
    const loadActivities = async (timespanId: string) => {
      try {
        const timespan = await studentActivityTimespanProvider.get(timespanId);
        setTimespan(timespan);

        if (timespan.poseDetails) {
          const records = await studentActivityTimespanRecordProvider.getPoses(
            timespanId
          );
          setLoading(false);
          const mapGroups = (records: StudentActivityTimespanPoseRecord[]) => {
            const groups: any[][] = [];
            let group: any[] = [];
            let lastClassTime = 0;
            for (let index = 0; index < records.length; index++) {
              const record = records[index];
              if (lastClassTime > record.classTime) {
                groups.push(group);
                group = [];
              }
              group.push(record);
              lastClassTime = record.classTime;

              if (index === records.length - 1) {
                groups.push(group);
              }
            }

            setPoseGroups(groups);
          };
          mapGroups(records);
        }
        if (timespan.exerciseDetails) {
          const records =
            await studentActivityTimespanRecordProvider.getExercises(
              timespanId
            );
          setLoading(false);
          const mapGroups = (
            records: StudentActivityTimespanExerciseRecord[]
          ) => {
            const groups: any[][] = [];
            let group: any[] = [];
            let lastClassTime = 0;
            for (let index = 0; index < records.length; index++) {
              const record = records[index];
              if (lastClassTime > record.classTime) {
                groups.push(group);
                group = [];
              }
              group.push(record);
              lastClassTime = record.classTime;

              if (index === records.length - 1) {
                groups.push(group);
              }
            }

            setExerciseGroups(groups);
          };
          mapGroups(records);
        }
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };

    loadActivities(timespanId);
  }, []);

  return (
    <div className={classes.root}>
      {loading && <CircularProgress />}
      {!loading &&
        timespan &&
        timespan.poseDetails &&
        poseGroups.length === 0 &&
        exerciseGroups.length === 0 && (
          <Typography>No records found</Typography>
        )}
      {!loading &&
        timespan &&
        timespan.poseDetails &&
        poseGroups.length !== 0 &&
        poseGroups.map((group, index) => (
          <PoseChart
            key={index}
            records={group}
            filePath={timespan.filePath} timespan={timespan} />
        ))}
      {!loading &&
        timespan &&
        timespan.exerciseDetails &&
        exerciseGroups.length !== 0 &&
        exerciseGroups.map(
          (group, index) =>
            group.length !== 0 &&
            timespan &&
            timespan.exerciseDetails && (
              <ExerciseChart
                key={index}
                records={group}
                timespan={timespan}
                filePath={timespan.filePath}
              />
            )
        )}
    </div>
  );
}
