import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { secondsToShortTimeRoundedString } from "../../../../../utils/conversion.functions";

const useStyles = makeStyles((theme: Theme) => ({
  accuracy: {
    fontSize: 52,
    fontWeight: 500,
    color: "white",
  },
  timeRoot: {
    margin: 10,
    minHeight: 170,
    minWidth: 170,
    position: "relative",
    backgroundColor: "rgba(27, 27, 27, 0.7)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "88%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type Props = {
  duration: number;
  currentTime: number;
};

export default function Accuracy(props: Props) {
  const classes = useStyles();
  const { duration, currentTime } = props;
  const progress = Math.floor(100 - ((currentTime / duration) * 100));
  return (
    <div className={classes.root}>
      <div className={classes.timeRoot}>
        <span className={classes.accuracy}>
          {secondsToShortTimeRoundedString(duration - currentTime)}
        </span>
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
          }}
        >
          <CircularProgress
            style={{ color: "white" }}
            variant="determinate"
            size={160}
            thickness={2}
            value={progress}
          />
        </div>
      </div>
    </div>
  );
}
