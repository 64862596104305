import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import * as videoProvider from "../../../providers/student/video.provider";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button, Divider } from "@material-ui/core";
import {
  getBase64ImageFromVideo,
  waitForMetadata,
} from "../../../services/canvas.service";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

const styles = (theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
  });

type State = {
  disabled: boolean;
  video?: File;
  thumbnail: string | undefined;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class VideoCreate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      thumbnail: undefined,
    };

    this.onChange = this.onChange.bind(this);
  }

  async onChange(acceptedFiles: Array<File>) {
    if (acceptedFiles.length === 1) {
      const video = acceptedFiles[0];
      const videoElement = document.createElement("video");
      if (videoElement) {
        await waitForMetadata(videoElement, video);
        videoElement.currentTime = 1;
        setTimeout(() => {
          const thumbnail = getBase64ImageFromVideo(videoElement);
          this.setState({
            video: video,
            thumbnail: thumbnail,
          });
        }, 1000);
      }
    }
  }

  onSave = async () => {
    this.setState({
      disabled: true,
    });
    const { video, thumbnail } = this.state;
    if (video) {
      const model = {
        video: video,
        thumbnail: thumbnail && thumbnail.split(",").pop(),
      };
      try {
        await videoProvider.create(model);
        this.props.history.goBack();
      } catch (err) {
        console.log(err);
        this.setState({
          disabled: false,
        });
        alert("An error occurred. Please try again.");
      }
    } else {
      this.setState({
        disabled: false,
      });
    }
  };

  render = () => {
    const { video, thumbnail, disabled } = this.state;
    const { classes, history } = this.props;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
        />
        <Divider />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Dropzone onDrop={this.onChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: disabled
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: "video/mp4",
                          disabled: disabled,
                        })}
                      />
                      <p>
                        Drag 'n' drop video(*.mp4) here, or click to select
                        video.
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
            <Grid item xs={12}>
              {thumbnail && (
                <img
                  className={classes.thumbnail}
                  src={thumbnail}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {video && (
                <Typography color="textSecondary" variant="h6" component="h2">
                  {video.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={disabled}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={disabled}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(VideoCreate);
export default withRouter(styledComponent as any);
