import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";


type ChannelClassPracticeDetails = {
  name: string;
  instructor_full_name: string;
};

export const getClassPracticeDetails = async (
  classId: string
): Promise<ChannelClassPracticeDetails> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: "api/analysis/class-practice/details",
    data: {
      user_id: authService.getAuthState().id,
      class_id: classId,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};


type ChannelClassDetails = {
  name: string;
  instructor_full_name: string;
};

export const getClassDetails = async (
  classId: string
): Promise<ChannelClassDetails> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: "api/analysis/class/details",
    data: {
      user_id: authService.getAuthState().id,
      class_id: classId,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

type ChannelChannelDetails = {
  name: string;
  instructor_full_name: string;
};

export const getChannelDetails = async (
  channelId: string
): Promise<ChannelChannelDetails> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: "api/analysis/channel/details",
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};