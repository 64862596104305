import { makeStyles, Theme } from "@material-ui/core";
import React, { RefObject, useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    display: "flex",
  
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  message: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    fontSize: 50,
    fontWeight: 600,
    paddingTop: 60,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    flexDirection: 'column',
    textAlign: "center",
    alignItems: "center",
    opacity: 0.8
  },
  initialMessage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    fontSize: 40,
    paddingTop: 60,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    flexDirection: 'column',
    textAlign: "center",
    alignItems: "center",
    opacity: 0.8
  },
  countdown: {
    position: "absolute",
    margiLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    top: "50%",
  },
  counterSpan: {
    fontSize: 200,
    fontWeight: 600,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  isLandmarksWithinImage: boolean;
  onCountdownEnded: () => void;
  isClassStarted: boolean;
  isProcessingStarted: boolean;
};
const headerHeight = 64;
const StudentCanvasLive = (props: Props) => {
  const classes = useStyles();
  const {
    canvasRef,
    isLandmarksWithinImage,
    isClassStarted,
    onCountdownEnded,
    isProcessingStarted,
  } = props;
  const height = window.innerHeight - headerHeight;
  const width = (height / 3) * 4;

  const [counter, setCounter] = useState<number>();
  const intervalRef = useRef<NodeJS.Timer>();
  const [canShowMessage, setCanShowMessage] = useState<boolean>(false);

  useEffect(() => {
    if (isClassStarted) {
      if (isLandmarksWithinImage) {
        setCounter(3);
        const decrement = () => {
          setCounter((prevValue) => prevValue && prevValue - 1);
        };
        intervalRef.current = setInterval(decrement, 800);
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        setCounter(0);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isLandmarksWithinImage]);

  useEffect(() => {
    const onClassStart = (isClassStarted: boolean) => {
      if (isClassStarted && isLandmarksWithinImage && !intervalRef.current) {
        setCounter(3);
        const decrement = () => {
          setCounter((prevValue) => prevValue && prevValue - 1);
        };
        intervalRef.current = setInterval(decrement, 800);
      }
    };
    if (isClassStarted === true) {
      onClassStart(isClassStarted);
    }
  }, [isClassStarted]);

  useEffect(() => {
    let timeout: any;
    if (intervalRef.current && counter === 0) {
      if (!canShowMessage) {
        timeout = setTimeout(() => {
          setCanShowMessage(true);
        }, 700);
      }

      onCountdownEnded();
      clearInterval(intervalRef.current);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [counter]);

  const nonStartedClassMEssage1 =
    "Adjust your camera up or down so the app can see your whole body - head to toe - when you step back at a distance of approx. 2.5m/8ft.";
  const nonStartedClassMEssage2  =  " Then press the button and your class will start when you step back again.";
  const countdownMessage =
    "Now we can see you, continue class after countdown.";
  const userNotVisibleMessage =
    "App cannot see all your body and joints. Please adjust your camera downwards or step back.";

  return (
    <div className={classes.root}>
      <canvas ref={canvasRef} height={height} width={width} />
      <div
        className={classes.countdown}
        style={{ visibility: !counter ? "hidden" : "visible" }}
      >
        <span className={classes.counterSpan}>{counter}</span>
      </div>
      <div
        className={classes.message}
        style={{ visibility: !canShowMessage ? "hidden" : "visible" }}
      >
        <span>{!counter ? userNotVisibleMessage : countdownMessage}</span>
      </div>
      <div
        className={classes.initialMessage}
        style={{
          visibility:
            !isClassStarted && isProcessingStarted ? "visible" : "hidden",
        }}
      >
        <p>{nonStartedClassMEssage1}</p>
        <p>{nonStartedClassMEssage2}</p>
      </div>
      <div
        className={classes.message}
        style={{
          visibility: !canShowMessage && isClassStarted ? "visible" : "hidden",
        }}
      >
        <span>{!counter ? userNotVisibleMessage : countdownMessage}</span>
      </div>
    </div>
  );
};

export default StudentCanvasLive;
