import React, { Component } from "react";
import { VideoPlayer } from "./components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import { ChannelVideoDetails } from "../../../providers/instructor/channel-video.provider";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import { Divider } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    channel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  });

type State = {
  video?: ChannelVideoDetails;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class VideoDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const videoId = this.props.match.params.videoId;
    if (videoId) {
      const video = await channelVideoProvider.get(videoId);
      this.setState({
        video: video,
      });
    }
  };

  render = () => {
    const { video } = this.state;

    const { classes } = this.props;
    const data = video ? { channelId: video.channel_id } : undefined;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        {video && (
          <Card className={classes.card}>
            <CardActionArea>
              {video && <VideoPlayer video={video} />}
              <CardContent>
                <Typography gutterBottom variant="h3" component="h2">
                  {video.name}
                </Typography>
                <Typography color="textSecondary" variant="h6" component="h2">
                  {new Date(video.creation_date).toLocaleString()}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </div>
    );
  };
}

const styledComponent = withStyles(styles)(VideoDetailsView);
export default withRouter(styledComponent as any);
