import React, { Component, createRef } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  Slider,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
} from "@material-ui/core";
import * as channelClassProvider from "../../../providers/instructor/channel-class.provider";

import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import * as channelExerciseProvider from "../../../providers/instructor/channel-exercise.provider";

import { ChannelPoseOrSkillListItem } from "../../../providers/instructor/channel-pose.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import { ChannelExerciseListItem } from "../../../providers/instructor/channel-exercise.provider";
import { secondsToTimeString } from "../../../utils/conversion.functions";
import { ExerciseSelection, PoseSelection, TimespanItem } from "./components";
import { ClassCreateTimespan } from "../../../types/class/class-create-timespan";
import { ClassCreateTimespanPose } from "../../../types/class/class-create-timespan-pose";
import { ClassCreateTimespanExercise } from "../../../types/class/class-create-timespan-exercise";
import { timeCrossingCheck } from "../../../services/class.helper";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FastForwardIcon from "@material-ui/icons/FastForward";
import validate from "validate.js";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    timespanLabel: {
      display: "flex",
      justifyContent: "space-between",
    },
    timespanDelete: {
      cursor: "pointer",
    },
    timespanSlectionControls: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    timeLabel: {
      fontSize: 17,
      fontWeight: 700,
    },
    flippedButton: {
      transform: "rotateY(180deg)",
    },
    error: {
      color: "#e53935",
      marginLeft: 14,
      marginRight: 14,
      fontSize: 14,
      marginTop: 3,
      textAlign: "left",

      fontWeight: 400,
      lineheight: 13,
      letterSpacing: 0.33,
    },
  });

type State = {
  disabled: boolean;
  name?: string;
  videoObjectUrl?: string;
  isVideoDataLoaded: boolean;
  exercises: ChannelExerciseListItem[];
  exercise?: ChannelExerciseListItem;
  poses: ChannelPoseOrSkillListItem[];
  poseOrSkill?: ChannelPoseOrSkillListItem;
  timeSpan: number[];
  duration?: number;
  step?: number;
  timespans: ClassCreateTimespan[];
  timespanRadioValue: string;
  errors: any;
  submitted: any;
  description: string;
  timespanIndexToRemove: any;
  timespanDescription?: string;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class ChannelClassCreate extends Component<Props, State> {
  private readonly videoRef = createRef<HTMLVideoElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      poses: [],
      exercises: [],
      timeSpan: [],
      timespans: [],
      isVideoDataLoaded: false,
      timespanRadioValue: "Pose",
      errors: {},
      submitted: {},
      description: "",
      timespanIndexToRemove: undefined,
      
    };
  }

  componentDidMount = async () => {
    const { channelId, classId } = this.props.match.params;
    if (channelId && classId) {
      const results = await Promise.all([
        channelPoseOrSkillProvider.getAll(channelId),
        channelClassProvider.get(classId),
        channelExerciseProvider.getAll(channelId),
      ]);
      const poses = results[0];
      const channelClass = results[1];
      const exercises = results[2];

      if (exercises.length !== 0) {
        const exercise = exercises[0];
        this.setState({
          exercise: exercise,
        });
      }

      if (poses.length !== 0) {
        const poseOrSkill = poses[0];
        this.setState({
          poseOrSkill: poseOrSkill,
        });
      }
      this.setState({
        name: channelClass.name,
        description: channelClass.description,
        videoObjectUrl: channelClass.file_path,
        timespans: channelClass.timespans.sort(
          (a, b) => a.startSeconds - b.startSeconds
        ),
        poses: poses,
        exercises: exercises,
      });
    }
  };

  onSave = async () => {
    const { name, timespans, description } = this.state;

    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      timespans: {
        presence: { allowEmpty: false, message: "are required" },
      },
    };

    const formData = {
      name: name,
      timespans: timespans.length > 0 ? true : undefined,
    };

    const errors = validate(formData, schema);
    if (errors) {
      this.setState({
        errors: errors,
        submitted: {
          name: true,
          timespans: true,
        },
      });
      return;
    }

    this.setState({
      disabled: true,
    });
    const mappedTimespans = timespans.map(
      ({ startSeconds, endSeconds, pose, exercise, description }) => {
        return {
          startSeconds,
          endSeconds,
          pose,
          exercise,
          description
        };
      }
    );
    const { classId } = this.props.match.params;
    const model = {
      classId: classId,
      name: name,
      description: description,
      timespans: mappedTimespans,
    };
    try {
      await channelClassProvider.edit(model);
      this.props.history.goBack();
    } catch (e) {
      console.warn(e);
      alert("Edition failed, please try again.");
      this.setState({
        disabled: false,
      });
    }
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      name: event.target.value as string,
      submitted: {
        ...prevState.submitted,
        name: false,
      },
    }));
  };

  handlePoseOrSkillChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { poses } = this.state;
    const poseOrSkillId = event.target.value as string;
    this.setState({
      poseOrSkill: poses.find((x) => x.id === poseOrSkillId),
    });
  };

  handleTimespanChange = (event: any, newValue: number | number[]) => {
    const newTimeSpan = newValue as number[];
    const { timeSpan } = this.state;

    if (this.videoRef.current) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      const newStart = newTimeSpan[0];
      const newEnd = newTimeSpan[1];
      if (start !== newStart) {
        this.videoRef.current.currentTime = newStart;
      } else if (end !== newEnd) {
        this.videoRef.current.currentTime = newEnd;
      }
    }
    this.setState({
      timeSpan: newTimeSpan,
    });
  };

  handleConfirmTimespanRemoval = (index: number) => {
    this.setState({ timespanIndexToRemove: { index: index } });
  };

  handleTimespanRemoval = (data: any) => {
    const { timespans } = this.state;
    this.setState({
      timespans: timespans
        .filter((x, i) => i !== data.index)
        .sort((a, b) => a.startSeconds - b.startSeconds),
    });
  };

  onLoadedMetadata = () => {
    this.setState({
      isVideoDataLoaded: true,
    });
    if (this.videoRef.current) {
      const timeSpan = [0, this.videoRef.current.duration];
      const duration = this.videoRef.current.duration;
      //TODO: make dynamic, change divider in case if duration fits specific limit
      const step = duration / 100;
      this.setState({
        timeSpan: timeSpan,
        duration: duration,
        step: step,
      });
    }
  };

  handleTimespanRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    this.setState({
      timespanRadioValue: value,
    });
  };

  onPoseSelected = (pose: ChannelPoseOrSkillListItem) => {
    this.setState({
      poseOrSkill: pose,
    });
  };

  onExerciseSelected = (exercise: ChannelExerciseListItem) => {
    this.setState({
      exercise: exercise,
    });
  };

  onAddTimespanFromPose = (isPoseOrSkillFlipped: boolean) => {
    const { timespans, timeSpan, poseOrSkill, timespanDescription } = this.state;
    if (!poseOrSkill) {
      return;
    }
    //TODO: validate crossing
    const startSeconds = timeSpan[0];
    const endSeconds = timeSpan[1];

    if (
      timespans.some((ts) =>
        timeCrossingCheck(
          ts.startSeconds,
          ts.endSeconds,
          startSeconds,
          endSeconds
        )
      )
    ) {
      alert(
        "The selected time interval overlaps with the already added time interval, select a different time."
      );
      return;
    }

    const pose: ClassCreateTimespanPose = {
      poseId: poseOrSkill.id,
      isFlipped: isPoseOrSkillFlipped,
    };

    const newTimeSpan: ClassCreateTimespan = {
      startSeconds: startSeconds,
      endSeconds: endSeconds,
      name: poseOrSkill.name,
      pose: pose,
      description: timespanDescription
    };
    timespans.push(newTimeSpan);
    timespans.sort((a, b) => a.startSeconds - b.startSeconds);
    this.setState((prevState) => ({
      timespanDescription: "",
      timespans: timespans,
      submitted: {
        ...prevState.submitted,
        timespans: false,
      },
    }));
  };

  onAddTimespanFromExercise = (repetitions: number) => {
    const { timespans, timeSpan, exercise, timespanDescription } = this.state;
    if (!exercise) {
      return;
    }
    const startSeconds = timeSpan[0];
    const endSeconds = timeSpan[1];

    if (
      timespans.some((ts) =>
        timeCrossingCheck(
          ts.startSeconds,
          ts.endSeconds,
          startSeconds,
          endSeconds
        )
      )
    ) {
      alert(
        "The selected time interval overlaps with the already added time interval, select a different time."
      );
      return;
    }

    const timespanExercise: ClassCreateTimespanExercise = {
      exerciseId: exercise.id,
      repetitions: repetitions,
    };

    const newTimeSpan: ClassCreateTimespan = {
      startSeconds: startSeconds,
      endSeconds: endSeconds,
      name: exercise.name,
      exercise: timespanExercise,
      description: timespanDescription
    };
    timespans.push(newTimeSpan);
    timespans.sort((a, b) => a.startSeconds - b.startSeconds);
    this.setState((prevState) => ({
      timespanDescription: "",
      timespans: timespans,
      submitted: {
        ...prevState.submitted,
        timespans: false,
      },
    }));
  };

  onMoveStartBackFast = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newStart = start - 0.4;
      if (this.videoRef && this.videoRef.current) {
        if (newStart < 0) {
          newStart = 0;
        }
        this.setState({
          timeSpan: [newStart, end],
        });
        this.videoRef.current.currentTime = newStart;
      }
    }
  };

  onMoveStartBack = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newStart = start - 0.02;
      if (this.videoRef && this.videoRef.current) {
        if (newStart < 0) {
          newStart = 0;
        }
        this.setState({
          timeSpan: [newStart, end],
        });
        this.videoRef.current.currentTime = newStart;
      }
    }
  };

  onMoveStartForward = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newStart = start + 0.02;
      if (this.videoRef && this.videoRef.current) {
        if (newStart > this.videoRef.current.duration) {
          newStart = this.videoRef.current.duration;
        }
        if (newStart > end) {
          this.setState({
            timeSpan: [end, newStart],
          });
        } else {
          this.setState({
            timeSpan: [newStart, end],
          });
        }
        this.videoRef.current.currentTime = newStart;
      }
    }
  };

  onMoveStartForwardFast = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newStart = start + 0.4;
      if (this.videoRef && this.videoRef.current) {
        if (newStart > this.videoRef.current.duration) {
          newStart = this.videoRef.current.duration;
        }
        if (newStart > end) {
          this.setState({
            timeSpan: [end, newStart],
          });
        } else {
          this.setState({
            timeSpan: [newStart, end],
          });
        }
        this.videoRef.current.currentTime = newStart;
      }
    }
  };

  onMoveEndBackFast = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newEnd = end - 0.4;
      if (this.videoRef && this.videoRef.current) {
        if (newEnd < 0) {
          newEnd = 0;
        }
        if (start > newEnd) {
          this.setState({
            timeSpan: [newEnd, start],
          });
        } else {
          this.setState({
            timeSpan: [start, newEnd],
          });
        }
        this.videoRef.current.currentTime = newEnd;
      }
    }
  };

  onMoveEndBack = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newEnd = end - 0.02;
      if (this.videoRef && this.videoRef.current) {
        if (newEnd < 0) {
          newEnd = 0;
        }
        if (start > newEnd) {
          this.setState({
            timeSpan: [newEnd, start],
          });
        } else {
          this.setState({
            timeSpan: [start, newEnd],
          });
        }
        this.videoRef.current.currentTime = newEnd;
      }
    }
  };

  onMoveEndForward = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newEnd = end + 0.02;
      if (this.videoRef && this.videoRef.current) {
        if (newEnd > this.videoRef.current.duration) {
          newEnd = this.videoRef.current.duration;
        }
        this.setState({
          timeSpan: [start, newEnd],
        });
        this.videoRef.current.currentTime = newEnd;
      }
    }
  };

  onMoveEndForwardFast = () => {
    const timeSpan = this.state.timeSpan;
    if (timeSpan.length === 2) {
      const start = timeSpan[0];
      const end = timeSpan[1];
      let newEnd = end + 0.4;
      if (this.videoRef && this.videoRef.current) {
        if (newEnd > this.videoRef.current.duration) {
          newEnd = this.videoRef.current.duration;
        }
        this.setState({
          timeSpan: [start, newEnd],
        });
        this.videoRef.current.currentTime = newEnd;
      }
    }
  };

  hasError = (field: string) => {
    const { submitted, errors } = this.state;
    return submitted[field] && errors[field] ? true : false;
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      description: event.target.value as string,
    });
  };

  handleTimespanDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      timespanDescription: event.target.value as string,
    });
  };

  render = () => {
    const {
      videoObjectUrl,
      isVideoDataLoaded,
      disabled,
      timeSpan,
      timespans,
      poses,
      poseOrSkill,
      duration,
      step,
      name,
      exercises,
      exercise,
      timespanRadioValue,
      errors,
      description,
      timespanDescription
    } = this.state;
    const { classes } = this.props;
    const isVideoAvailable =
      isVideoDataLoaded && this.videoRef && this.videoRef.current;

    const { channelId } = this.props.match.params;
    const data = channelId ? { channelId: channelId } : undefined;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <video
                crossOrigin="anonymous"
                className={classes.formControl}
                ref={this.videoRef}
                playsInline
                src={videoObjectUrl}
                onLoadedMetadata={this.onLoadedMetadata}
              ></video>
            </Grid>
            {isVideoAvailable && (
              <Grid item xs={12}>
                <TextField
                  className={classes.formControl}
                  label="Name"
                  value={name}
                  onChange={this.handleNameChange}
                  helperText={this.hasError("name") ? errors["name"][0] : null}
                  error={this.hasError("name")}
                />
              </Grid>
            )}
            {isVideoAvailable && (
              <Grid item xs={12}>
                <TextField
                  className={classes.formControl}
                  label="Description"
                  multiline
                  rows="4"
                  value={description}
                  onChange={this.handleDescriptionChange}
                />
              </Grid>
            )}
            {isVideoAvailable && (
              <Grid item xs={12}>
                <Grid item className={classes.timespanSlectionControls} xs={12}>
                  <div className={classes.timespanSlectionControls}>
                    <IconButton
                      aria-label={"Move fast forward"}
                      onClick={this.onMoveStartBackFast}
                      className={`${classes.button} ${classes.flippedButton}`}
                    >
                      <FastForwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label={"Move start back"}
                      onClick={this.onMoveStartBack}
                      className={classes.button}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography className={classes.timeLabel}>
                      {secondsToTimeString(timeSpan[0])}
                    </Typography>
                    <IconButton
                      aria-label={"Move start forward"}
                      onClick={this.onMoveStartForward}
                      className={classes.button}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton
                      aria-label={"Move fast forward"}
                      onClick={this.onMoveStartForwardFast}
                      className={classes.button}
                    >
                      <FastForwardIcon />
                    </IconButton>
                  </div>
                  <div className={classes.timespanSlectionControls}>
                    <IconButton
                      aria-label={"Move fast forward"}
                      onClick={this.onMoveEndBackFast}
                      className={`${classes.button} ${classes.flippedButton}`}
                    >
                      <FastForwardIcon />
                    </IconButton>
                    <IconButton
                      aria-label={"Move end back"}
                      onClick={this.onMoveEndBack}
                      className={classes.button}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography className={classes.timeLabel}>
                      {secondsToTimeString(timeSpan[1])}
                    </Typography>
                    <IconButton
                      aria-label={"Move end forward"}
                      onClick={this.onMoveEndForward}
                      className={classes.button}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton
                      aria-label={"Move fast forward"}
                      onClick={this.onMoveEndForwardFast}
                      className={classes.button}
                    >
                      <FastForwardIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Slider
                  value={timeSpan}
                  aria-labelledby="time-slider"
                  valueLabelDisplay="off"
                  step={step}
                  min={0}
                  max={duration}
                  onChange={this.handleTimespanChange}
                />
              </Grid>
            )}
             {isVideoAvailable && 
              <Grid item xs={12}>
               <TextField
                 className={classes.formControl}
                 label="Timespan Description"
                 multiline
                 rows="4"
                 value={timespanDescription}
                 onChange={this.handleTimespanDescriptionChange}
               />
             </Grid>
            }
            {isVideoAvailable && (
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="timespan-radio"
                  name="timespan-radio"
                  value={timespanRadioValue}
                  onChange={this.handleTimespanRadioChange}
                >
                  <FormControlLabel
                    value="Pose"
                    control={<Radio />}
                    label="Create Pose"
                  />
                  <FormControlLabel
                    value="Exercise"
                    control={<Radio />}
                    label="Create Exercise Set"
                  />
                </RadioGroup>
              </Grid>
            )}

            {isVideoAvailable &&
              poseOrSkill &&
              timespanRadioValue === "Pose" && (
                <PoseSelection
                  poses={poses}
                  pose={poseOrSkill}
                  onPoseSelected={this.onPoseSelected}
                  onCreateTimespan={this.onAddTimespanFromPose}
                />
              )}

            {isVideoAvailable &&
              exercise &&
              timespanRadioValue === "Exercise" && (
                <ExerciseSelection
                  exercises={exercises}
                  exercise={exercise}
                  onExerciseSelected={this.onExerciseSelected}
                  onCreateTimespan={this.onAddTimespanFromExercise}
                />
              )}
            {timespans.length > 0 && (
              <Typography style={{ padding: 15 }} component="h3">
                Timespans:
              </Typography>
            )}
            {duration &&
              timespans.map((timespan, index: number) => (
                <TimespanItem
                  index={index}
                  timespan={timespan}
                  duration={duration}
                  onRemove={this.handleConfirmTimespanRemoval}
                />
              ))}
            <Grid item xs={12}>
              <p className={classes.error}>
                {this.hasError("timespans") ? errors["timespans"][0] : ""}
              </p>
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={disabled}
              onClick={this.props.history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={disabled}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
          <ConfirmationDialog
            handleConfirm={this.handleTimespanRemoval}
            dialogTitle={"Confirm timespan removal"}
            data={this.state.timespanIndexToRemove}
          />
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelClassCreate);
export default withRouter(styledComponent as any);
