import React, { FC, useEffect, useRef } from "react";
import * as THREE from "three";
import { secondsToShortTimeRoundedString } from "../../../../../utils/conversion.functions";
import { getTextGeometry } from "../../ui.helper";
import * as pose3dService from "../../../../../services/pose3d.service";

type Props = {
  duration: number;
  classCurrentTime: number;
  scene: THREE.Scene;
  font: THREE.Font;
};

const videoTime = "VideoTime";
const videoTimeBackground = "VideoTimeBackground";

const TimespanCircularProgressBar: FC<Props> = (props) => {
  const { duration, classCurrentTime, scene, font } = props;

  const textMeshRef = useRef<THREE.Mesh>();
  const textBackgroundMeshRef = useRef<THREE.Mesh>();

  useEffect(() => {
    const getTextBackground = (box: THREE.Box3): THREE.Mesh => {
      const material = new THREE.MeshBasicMaterial({
        color: 0x5a5a5a,
        transparent: true,
        opacity: 0.35,
      });
      const width = box.max.x - box.min.x;
      const height = box.max.y - box.min.y;
      const center = new THREE.Vector3();
      box.getCenter(center);
      const geometry = new THREE.PlaneGeometry(width, height - 0.025);
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(center.x, center.y + 0.05, -0.01);
      mesh.name = videoTimeBackground;
      return mesh;
    };

    const init = () => {
      const videoMesh = scene.getObjectByName("InstructorVideo") as THREE.Mesh;
      if (videoMesh) {
        const timeText = ``;

        const shapes = font.generateShapes(timeText, 0.1);
        const geometry = getTextGeometry(shapes);
        const matDark = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
        });

        const text = new THREE.Mesh(geometry, matDark);
        text.position.set(1.8, -1.2, 0.01);
        text.name = videoTime;
        textMeshRef.current = text;
        //2. Generate new Background
        const box = text.geometry.boundingBox;
        if (box) {
          const background = getTextBackground(box);
          textBackgroundMeshRef.current = background;
          text.add(background);

          videoMesh.add(text);
        }
      }
    };
    init();

    return () => {
      const textMesh = textMeshRef.current;
      if (textMesh) {
        pose3dService.cleanupObject(textMesh);
      }
      const textBackgroundMesh = textBackgroundMeshRef.current;
      if (textBackgroundMesh) {
        pose3dService.cleanupObject(textBackgroundMesh);
      }
    };
  }, []);

  useEffect(() => {
    const getTextBackgroundGeometry = (
      box: THREE.Box3
    ): THREE.PlaneGeometry => {
      const width = box.max.x - box.min.x;
      const height = box.max.y - box.min.y;
      const center = new THREE.Vector3();
      box.getCenter(center);
      const geometry = new THREE.PlaneGeometry(width + 0.05, height + 0.05);
      return geometry;
    };
    const onChange = (duration: number, currentTime: number) => {
      const textMesh = textMeshRef.current;
      if (textMesh) {
        //1. Generate new Text
        const timeText = `${secondsToShortTimeRoundedString(
          currentTime
        )}/${secondsToShortTimeRoundedString(duration)}`;

        const shapes = font.generateShapes(timeText, 0.1);
        const geometry = getTextGeometry(shapes);
        textMesh.geometry.dispose();
        textMesh.geometry = geometry;
        //2. Generate new Background
        const box = geometry.boundingBox;
        const textBackgroundMes = textBackgroundMeshRef.current;
        if (box && textBackgroundMes) {
          const geometry = getTextBackgroundGeometry(box);
          textBackgroundMes.geometry.dispose();
          textBackgroundMes.geometry = geometry;
        }
      }
    };
    onChange(duration, classCurrentTime);
  }, [duration, classCurrentTime]);

  return <div></div>;
};

export default TimespanCircularProgressBar;
