import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelExerciseListItem = {
  id: string;
  name: string;
  message: string;
  creation_date: string;
  thumbnail_path: string;
};

export const getAll = async (
  classId: string
): Promise<Array<ChannelExerciseListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/exercise/getAll",
    data: {
      class_id: classId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};