import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { SearchInput } from '../../../../../components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const VideoToolbar = props => {
  const { className, channelId, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const onAddVideo = (channelId) => {
      history.push(`/sports-instructor/channel/create-video/${channelId}`)
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          onClick={() => { onAddVideo(channelId) }}
          color="primary"
          variant="contained"
        >
          Upload video
        </Button>
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
        />
      </div> */}
    </div>
  );
};

VideoToolbar.propTypes = {
  className: PropTypes.string,
  channelId: PropTypes.string.isRequired
};


export default VideoToolbar;
