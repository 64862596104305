import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@material-ui/core";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import * as dataHelper from "../../../services/data.helper";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

import {
  mediapipeService,
  mediapipeKeypoint,
} from "../../../services/mediapipe";
import Pose3dEditor from "../../../components/editor/pose3d-editor";

import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import { drawOriginal } from "../../../services/mediapipe/mediapipe.keypoint";
import HumanRegions from "../../../components/HumanRegions";
import validate from "validate.js";
import InfoIcon from "@material-ui/icons/Info";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      color: "#e53935",
      marginLeft: 14,
      marginRight: 14,
      fontSize: 11,
      marginTop: 3,
      textAlign: "left",

      fontWeight: 400,
      lineheight: 13,
      letterSpacing: 0.33,
    },
    info: {
      display: "flex",
      alignItems: "center",
      padding: "2px 12px !important",
    },
  });

type State = {
  channelId: string | null;
  isUiDisabled: boolean;
  name: string;
  images: Array<File>;
  image?: File;
  imageBase64Image?: string;
  keypoints3d: Keypoint3D[];
  regions: any;
  errors: any;
  submitted: any;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class CreateItemView extends Component<Props, State> {
  private readonly canvasRef = React.createRef<HTMLCanvasElement>();
  private poseRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isUiDisabled: true,
      images: [],
      channelId: null,
      name: "",
      keypoints3d: [],
      regions: {},
      submitted: {},
      errors: {},
    };
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  componentDidMount = async () => {
    try {
      const channelId = this.props.match.params.channelId;
      if (channelId) {
        const startMediapipe = async () => {
          const onLandmarks = async (results: any) => {
            const { poseLandmarks, image } = results;

            if (poseLandmarks) {
              const downscale = (height: number, width: number) => {
                const getScaleMultiplier = (
                  height: number,
                  width: number
                ): number => {
                  const maxValue = 550;
                  if (height >= width) {
                    return maxValue / height;
                  }
                  return maxValue / width;
                };

                const scaleMultiplier = getScaleMultiplier(height, width);
                return {
                  scaledWidth: width * scaleMultiplier,
                  scaledHeight: height * scaleMultiplier,
                };
              };

              const imageHeight = image.height;
              const imageWidth = image.width;

              const canvasElement = this.canvasRef.current;
              if (canvasElement) {
                const { scaledHeight, scaledWidth } = downscale(
                  imageHeight,
                  imageWidth
                );
                canvasElement.width = scaledWidth;
                canvasElement.height = scaledHeight;
                drawOriginal(poseLandmarks, image, canvasElement);
              }

              const mapped = mediapipeKeypoint.mapForAnalysis(
                mediapipeKeypoint.denormalize(
                  poseLandmarks,
                  imageWidth,
                  imageHeight
                )
              );
              this.setState((prevState) => ({
                keypoints3d: mapped,
                submitted: { ...prevState.submitted, avatarData: false },
              }));
            }
            this.setState({
              isUiDisabled: false,
            });
          };

          const pose = await mediapipeService.getStaticModel();
          pose.onResults(onLandmarks);
          const poseRef = this.poseRef as React.MutableRefObject<any>;
          poseRef.current = pose;
        };

        await startMediapipe();
        this.setState({
          channelId: channelId,
          isUiDisabled: false,
        });
      } else {
        console.warn("channelId not provided");
      }
    } catch (err) {
      //TODO: handle initialization error
      console.log("componentDidMount:err");
      console.log(err);
    }
  };

  onSave = async () => {
    const { channelId, image, name, keypoints3d, regions } = this.state;

    //TODO: update to handle empty keypoints
    const formData = {
      name: name,
      image: image,
      avatarData: keypoints3d.length !== 0 ? keypoints3d : undefined,
    };

    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      image: {
        presence: { allowEmpty: false, message: "is required" },
      },
      avatarData: {
        presence: { allowEmpty: false, message: "is not generated" },
      },
    };

    const errors = validate(formData, schema);
    if (errors) {
      this.setState({
        errors: errors,
        submitted: {
          name: true,
          image: true,
          avatarData: true,
        },
      });
      return;
    }

    this.setState({
      isUiDisabled: true,
    });
    const images = [image];

    const model = {
      channelId: channelId,
      images: images,
      name: name,
      keypoints3d: keypoints3d.map((kp) => [kp.x, kp.y, kp.z]),
      regions: Object.keys(regions).reduce((acc: any, curr: string) => {
        if (regions[curr] === true) {
          acc[curr] = true;
        }
        return acc;
      }, {}),
    };
    try {
      await channelPoseOrSkillProvider.create(model);
      this.props.history.goBack();
    } catch (ex) {
      //TODO: switch to toaster
      alert("Unable to process image, please select different one.");
      this.setState({
        isUiDisabled: false,
      });
    }
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      name: event.target.value as string,
      submitted: {
        ...prevState.submitted,
        name: false,
      },
    }));
  };

  handleImageChange = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const base64Image = await dataHelper.getBase64Image(file);
      const image = await dataHelper.getImage(base64Image);
      this.setState((prevState) => ({
        isUiDisabled: true,
        image: file,
        imageBase64Image: base64Image,
        submitted: {
          ...prevState.submitted,
          image: false,
          avatarData: !prevState.image ? false : prevState.submitted.avatarData,
        },
      }));

      const pose = this.poseRef.current;
      try {
        await pose.send({ image: image });
      } catch (err) {
        console.log(err);
        //TODO: handle error
        this.setState({
          isUiDisabled: false,
        });
      }
    }
  };

  onToggleRegion = (key: string) => {
    const { regions } = this.state;

    if (regions[key]) {
      regions[key] = false;
    } else {
      regions[key] = true;
    }

    this.setState({
      regions: { ...regions },
    });
  };

  hasError = (field: string) => {
    const { submitted, errors } = this.state;
    return submitted[field] && errors[field] ? true : false;
  };

  render = () => {
    const {
      isUiDisabled,
      imageBase64Image,
      name,
      channelId,
      keypoints3d,
      regions,
      errors,
    } = this.state;

    const { classes, title } = this.props;
    const data = channelId ? { channelId: channelId } : undefined;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        {isUiDisabled && <LinearProgress />}
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                disabled={isUiDisabled}
                label="Name"
                value={name}
                onChange={this.handleNameChange}
                helperText={this.hasError("name") ? errors["name"][0] : null}
                error={this.hasError("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropzone onDrop={this.handleImageChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: isUiDisabled
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: "image/jpeg",
                          disabled: isUiDisabled,
                        })}
                      />
                      <p>Drag 'n' drop image here, or click to select one.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <p className={classes.error}>
                {this.hasError("image") ? errors["image"][0] : null}
              </p>
            </Grid>
            {imageBase64Image && (
              <Grid item xs={12}>
                <img className={classes.thumbnail} src={imageBase64Image} />
              </Grid>
            )}
            {keypoints3d && keypoints3d.length > 0 && (
              <Grid item xs={12}>
                <Pose3dEditor
                  height={550}
                  width={550}
                  keypoints={keypoints3d}
                  avatarType={AvatarType.Female}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <p className={classes.error}>
                {!this.hasError("image") && this.hasError("avatarData")
                  ? errors["avatarData"][0]
                  : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              {/* TODO: hide when no data or on image update*/}
              <canvas ref={this.canvasRef}></canvas>
            </Grid>
            {keypoints3d && keypoints3d.length > 0 && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 16 }}>
                    Regions of interest
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.info}>
                  <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
                  <Typography>
                    If no regions will be selected, all regions will be used for
                    calculations.
                  </Typography>
                </Grid>
                <HumanRegions
                  selectedRegions={regions}
                  onRegionSelection={this.onToggleRegion}
                />
              </Grid>
            )}
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={isUiDisabled}
              onClick={this.props.history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={isUiDisabled}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(CreateItemView);
export default withRouter(styledComponent as any);
