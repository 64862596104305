import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../services/pose3d.service";
import { ClassView } from "../../../types/threejs/class-view.type";
import * as THREE from "three";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../services/avatar/avatar.service";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../services/keypoint.helper";
import { LiveClassModel } from "../../../types/analyze/live.class.type";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import { rotateAroundAxis } from "../../../services/mediapipe/mediapipe.keypoint";
import {
  defaultRegions,
  getJointsFromRegions,
} from "../../../services/avatar/regions.service";
import { halfWidth } from "../../../services/analysis-scene.helper";

const useStyles = makeStyles((theme: Theme) => ({
  canvas: {
    width: 1,
    height: 1,
    zIndex: -100,
    position: "absolute",
  },
  canvasTest: {
    width: "100%",
    height: "100%",
  },
}));

type Props = {
  timespan?: CourseAnalysisTimespan;
  selfieMode: boolean;
  onPoseAccuracy: (
    accuracy: number,
    live: LiveClassModel,
    timespan: CourseAnalysisTimespan,
    mergedAccuracy: any
  ) => void;
  live?: LiveClassModel;
  onExerciseAccuracy: (
    accuracyPercentages: { [key: number]: number },
    live: LiveClassModel,
    repetition: number,
    timespan: CourseAnalysisTimespan,
    nextExerciseStepIndex: number,
    jointsAccuracy: any,
  ) => void;
  onRepetition: (repetition: number, accuracy: number) => void;
  onPlayBeep: () => void;
  isTest?: boolean;
};

const studentRootName = "root-student";
const studentDisplayRootName = "root-student-display";
const instructorRootName = "root-instructor";
const instructorDisplayRootName = "root-instructor-display";

const LiveModelCombined2D: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    selfieMode,
    onPoseAccuracy,
    live,
    timespan,
    onExerciseAccuracy,
    onRepetition,
    onPlayBeep,
    isTest,
  } = props;

  const timespanId = timespan ? timespan.id : undefined;

  const mountRef = useRef<HTMLDivElement>(null);

  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();

  const sceneRef = useRef<THREE.Scene>();

  const exerciseAccuracyPeaksRef = useRef<any>({});

  const [nextExerciseStepIndex, setNextExerciseStepIndex] = useState<number>(0);

  const [repsCounter, setRepsCounter] = useState<number>(0);

  const [prevExerciseAccuracy, setPrevExerciseAccuracy] = useState<{
    [key: number]: number;
  }>({});

  const [renderTrigger, setRenderTrigger] = useState<number>();

  useEffect(() => {
    const initScene = async () => {
      sceneRef.current = pose3dService.sceneSetup();
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }
      const renderer = pose3dService.getCourseRenderer(mount);
      setRenderer(renderer);

      const view = pose3dService.getCourseView(mount);
      setView(view);

      const addStudentModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = studentRootName;

        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });

        scene.add(model);
        avatarService.init(model);
      };

      const addStudentDisplayModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = studentDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.shininess = 10;
            object.material.color = new THREE.Color(0x0072fa);
            object.material.visible = false;
            object.castShadow = true;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = instructorRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorDisplayModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = instructorDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.transparent = true;
            object.material.opacity = 0.5;
            object.material.color = new THREE.Color(0x00ff00);
            object.material.visible = false;
          }
        });
        model.position.z = halfWidth / 2;
        scene.add(model);
        avatarService.init(model);
      };

      await addStudentModel();
      await addStudentDisplayModel();
      await addInstructorModel();
      if (isTest) {
        await addInstructorDisplayModel();
      }
    };

    initScene();

    //TODO: enable only in test
    const videoUpdateInterval = setInterval(() => {
      setRenderTrigger(new Date().getTime());
    }, 120);

    const onWindowResize = () => {
      //TODO: update, replace hardcoded value
      const headerHeight = 64;
      if (view && renderer) {
        view.camera.aspect = window.innerWidth / window.innerHeight;
        view.camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight - headerHeight);
      }
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      const cleanupAvatar = (scene: THREE.Scene, name: string) => {
        const avatar = scene.getObjectByName(name);
        if (avatar) {
          avatar.traverse((child: any) => {
            if (child.isMesh) {
              pose3dService.cleanupObject(child);
            }
          });
          scene.remove(avatar);
        }
      };
      const scene = sceneRef.current;
      if (scene) {
        cleanupAvatar(scene, studentRootName);
        cleanupAvatar(scene, studentDisplayRootName);
        cleanupAvatar(scene, instructorRootName);
      }
      //TODO: DISPOSE EVERYTHING!!
      //https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      //something to think about https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
      clearInterval(videoUpdateInterval);
    };
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    if (mount && scene && renderer && view) {
      pose3dService.renderCourseThree(mount, scene, renderer, view);
    }
  }, [renderTrigger]);

  useEffect(() => {
    const setExerciseVariables = (timespan?: CourseAnalysisTimespan) => {
      if (timespan && timespan.exercise) {
        setNextExerciseStepIndex(0);
        setPrevExerciseAccuracy({});
        setRepsCounter(0);
      }
    };
    setExerciseVariables(timespan);
  }, [timespanId]);

  //HACK: handles timespan that ends with the video end

  useEffect(() => {
    const getCoordinates = (
      scene: THREE.Scene,
      keypoints: Keypoint3D[],
      name: string
    ) => {
      if (keypoints.length === 0) {
        return;
      }
      const root = scene.getObjectByName(name);
      if (!root) {
        return;
      }

      avatarService.poseUpdate(
        root,
        keypoints.map((kp) => {
          return {
            x: kp.x,
            y: kp.y,
            z: kp.z,
            part: kp.part,
            visibility: kp.visibility,
          };
        })
      );
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
      const coordinates = avatarFeedback.getCoordinates(root);
      return coordinates;
    };

    const processPoseTimespan = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      displayInstructorKeypoints: Keypoint3D[],
      live: LiveClassModel,
      regions: { [key: string]: boolean },
      timespan: CourseAnalysisTimespan
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorThresholdKeypoints = getCoordinates(
        scene,
        displayInstructorKeypoints,
        instructorRootName
      );

      if (instructorThresholdKeypoints && studentThresholdKeypoints) {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );      

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );      

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          getJointsFromRegions(regions)
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        onPoseAccuracy(
          accuracyPercentage,
          live,
          timespan,
          mergedAccuracy
        );
      }
    };

    const processExerciseTimespan = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      stepsKeypoints: Keypoint3D[][],
      nextExerciseStepIndex: number,
      prevExerciseAccuracy: { [key: number]: number },
      live: LiveClassModel,
      regions: { [key: string]: boolean }[],
      timespan: CourseAnalysisTimespan
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorStepThresholdKeypoints = stepsKeypoints.map((kp) => {
        return getCoordinates(scene, kp, instructorRootName);
      });

      const calculateAccuracy = (
        studentThresholdKeypoints: Keypoint3D[],
        instructorThresholdKeypoints: Keypoint3D[],
        accuracyJoints: string[]
      ) => {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          accuracyJoints
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        return {
          accuracyPercentage,
          mergedAccuracy
        };
      };

      if (
        studentThresholdKeypoints &&
        !instructorStepThresholdKeypoints.some((kp) => kp === undefined)
      ) {
        const accuracyData = instructorStepThresholdKeypoints.map(
          (kp, index) => {
            if (kp) {
              return calculateAccuracy(
                studentThresholdKeypoints,
                kp,
                getJointsFromRegions(regions[index])
              );
            }
          }
        );
        const accuracyPercentages = accuracyData.map((d) => {
          if (d === undefined) {
            return undefined;
          }
          return d.accuracyPercentage;
        });

        const accuracyObject = accuracyPercentages.reduce(
          (acc: any, curr: number | undefined, index: number) => {
            acc[index] = curr;
            return acc;
          },
          {}
        );

        const accuracyMergedObject = accuracyData.reduce(
          (acc: any, curr: any, index: number) => {
            acc[index] = curr.mergedAccuracy;
            return acc;
          },
          {}
        );


        const nextPoseAccuracyData = accuracyData[nextExerciseStepIndex];

        let currentRep = repsCounter;
        if (timespan && nextPoseAccuracyData) {
          const {
            accuracyPercentage,
          } = nextPoseAccuracyData;

          const isThresholdReached =
            prevExerciseAccuracy[nextExerciseStepIndex] >=
            exerciseSuccessThresholdPercentage;
          const isAccuracyDecreasing =
            prevExerciseAccuracy[nextExerciseStepIndex] > accuracyPercentage;
          const isAccuracyReached =
            accuracyPercentage === 100 ||
            (isThresholdReached && isAccuracyDecreasing);

          const isLastStepReached =
            nextExerciseStepIndex ===
              instructorStepThresholdKeypoints.length - 1 && isAccuracyReached;

          if (isAccuracyReached) {
            //TODO: record peak accuracy
            if (!exerciseAccuracyPeaksRef.current[timespan.id]) {
              exerciseAccuracyPeaksRef.current[timespan.id] = [];
            }
            const stepAccuracy = accuracyPercentage > prevExerciseAccuracy[nextExerciseStepIndex] ? accuracyPercentage : prevExerciseAccuracy[nextExerciseStepIndex];
            if(stepAccuracy){
              exerciseAccuracyPeaksRef.current[timespan.id].push(
                stepAccuracy
              );
            }
          }

          if (isLastStepReached) {
            const blockAccuracyRecords =
              exerciseAccuracyPeaksRef.current[timespan.id]; 
            const averageAccuracy =
              blockAccuracyRecords.reduce((acc: number, curr: number) => {
                if (!Number.isNaN(curr)) {
                  acc = acc + curr;
                }
                return acc;
              }, 0) / blockAccuracyRecords.length;
            currentRep = repsCounter + 1;
            setRepsCounter(currentRep);
            onRepetition(currentRep, averageAccuracy);
            setNextExerciseStepIndex(0);
            onPlayBeep();
          } else if (isAccuracyReached) {
            setNextExerciseStepIndex(nextExerciseStepIndex + 1);
            onPlayBeep();
          }
          setPrevExerciseAccuracy(accuracyObject);
          if(Object.keys(prevExerciseAccuracy).length > 0){
            onExerciseAccuracy(
              accuracyObject,
              live,
              currentRep,
              timespan,
              nextExerciseStepIndex,
              accuracyMergedObject
            );
          }
        }
      }

      if (
        instructorStepThresholdKeypoints.some((kp) => kp === undefined) ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processKeypoints = (
      live?: LiveClassModel,
      timespan?: CourseAnalysisTimespan
    ) => {
      const studentKeypoints = live ? live.studentKeypoints : [];
      let displayStudentKeypoints = !selfieMode
        ? mapKeypoints(studentKeypoints)
        : getFlippedDisplayKeypoints(mapKeypoints(studentKeypoints));
      displayStudentKeypoints = rotateAroundAxis(
        displayStudentKeypoints,
        new THREE.Vector3(0, 1, 0),
        -45
      ) as any[];

      const mount = mountRef.current;
      const scene = sceneRef.current;

      if (mount && scene && renderer && view) {
        //TODO: move to service

        if (
          live &&
          live.isInstructorVideoEnded !== undefined &&
          live.isInstructorVideoEnded !== true &&
          live.isInstructorVideoPaused !== undefined &&
          live.isInstructorVideoPaused !== true
        ) {
          if (timespan && timespan.pose) {
            const displayInstructorKeypoints = mapKeypoints(
              timespan.pose.keypoints
            );

            processPoseTimespan(
              scene,
              displayStudentKeypoints,
              displayInstructorKeypoints,
              live,
              //HACK: bacuse of empty db records
              //TODO: replace with full regions selection?
              !timespan.pose.regions ||
                Object.keys(timespan.pose.regions).length === 0
                ? defaultRegions
                : timespan.pose.regions,
              timespan
            );
          } else if (timespan && timespan.exercise) {
            const sortedSteps = timespan.exercise.steps.sort((x) => x.order);
            const stepsDisplayKeypoints = sortedSteps.map((x) =>
              mapKeypoints(x.keypoints)
            );

            const stepsRegions = sortedSteps.map((x) => {
              if (x.regions) {
                return x.regions;
              }
              //HACK: bacuse of empty db records
              //TODO: replace with full regions selection?
              return {};
            });

            processExerciseTimespan(
              scene,
              displayStudentKeypoints,
              stepsDisplayKeypoints,
              nextExerciseStepIndex,
              prevExerciseAccuracy,
              live,
              stepsRegions,
              timespan
            );
          }
        }
      }
    };

    processKeypoints(live, timespan);
  }, [live]);

  return (
    <div
      className={isTest ? classes.canvasTest : classes.canvas}
      ref={mountRef}
    ></div>
  );
};

export default LiveModelCombined2D;
