import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import * as authProvider from "../../../providers/common/auth.provider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: "center",
  },
}));

enum ConfirmationResult {
  None = 0,
  Success = 1,
  Fail = 2,
}

export default function EmailConfirmation() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmation, setConfirmation] = useState<ConfirmationResult>(
    ConfirmationResult.None
  );

  useEffect(() => {
    const confirm = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        const userId = searchParams.get("userId");
        if (code && userId) {
          await authProvider.confirmEmail({ code: code, userId: userId });
          setConfirmation(ConfirmationResult.Success);
        } else {
          setConfirmation(ConfirmationResult.Fail);
        }
      } catch (ex) {
        setConfirmation(ConfirmationResult.Fail);
      }

      setLoading(false);
    };

    confirm();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">Email confirmation</Typography>
            {loading && <CircularProgress />}
            {confirmation === ConfirmationResult.Success && (
              <Typography variant="subtitle2">
                Success, now you can log in.
              </Typography>
            )}
            {confirmation === ConfirmationResult.Fail && (
              <Typography variant="subtitle2">
                An error occure while processing your request.
              </Typography>
            )}
            {confirmation === ConfirmationResult.Success && (
              <Button
                onClick={() => history.push("/login")}
                variant="contained"
                color="primary"
                style={{ margin: 10 }}
              >
                Login
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
