import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important"
  },
}));

type Props = {
  isAudioEnabled: boolean;
  onToggleSound: () => void;
};

export default function InstructorSoundControl(props: Props) {
  let { isAudioEnabled, onToggleSound } = props;
  const classes = useStyles();
  return (
    <IconButton
      aria-label={isAudioEnabled ? "Mute" : "Unmute"}
      onClick={onToggleSound}
      className={classes.button}
    >
      {!isAudioEnabled ? (
        <VolumeOffIcon fontSize="inherit" />
      ) : (
        <VolumeUpIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}
