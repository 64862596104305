import { makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BlockVideoType } from "../../../types/analyze/block-video-type.enum";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import {
  BlockSummary,
  ButtonContainer,
  CenterCountdown,
  ClassProgressBar,
  ComingNext,
  CornerCountdown,
  Feedback
} from "./Components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  leftSide: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "50%",
    backgroundColor: "rgba(27, 27, 27, 0.7)",
  },
  rightSide: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "50%",
    backgroundColor: "rgb(27, 27, 27)",
  },
  countdown: {
    position: "absolute",
    margiLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    top: "50%",
  },
}));

type Props = {
  isLandmarksWithinImage: boolean;
  isClassStarted: boolean;
  onCanStartPlayback: () => void;
  onExit: () => void;
  isFullScreenEnabled: boolean;
  toggleFullScreen: () => void;
  isInstructorVideoMuted: boolean;
  toggleMute: () => void;
  isInstructorVideoPlaying: boolean;
  togglePlay: () => void;
  blockVideoType: BlockVideoType;
  blockVideoCurrentTime: number;
  block?: ClassPracticeAnalysisBlock;
  blocks?: ClassPracticeAnalysisBlock[];
  studentRepsCounter: number;
  reward: any;
  classData: any;
  effectiveness: number;
  feedback?: string;
};

const AnalysisOverlay = (props: Props) => {
  const classes = useStyles();
  const {
    isLandmarksWithinImage,
    isClassStarted,
    onCanStartPlayback,
    blockVideoType,
    block,
    blocks,
    blockVideoCurrentTime,
    studentRepsCounter,
    reward,
    classData,
    onExit,
    isInstructorVideoMuted,
    toggleMute,
    isInstructorVideoPlaying,
    togglePlay,
    isFullScreenEnabled,
    toggleFullScreen,
    effectiveness,
    feedback,
  } = props;


  const [displayIntroMessage, setDisplayIntroMessage] =
    useState<boolean>(false);
  const [displayInFrameMessage, setDisplayInFrameMessage] =
    useState<boolean>(false);

  useEffect(() => {
    const onChange = (
      isClassStarted: boolean,
      isLandmarksWithinImage: boolean
    ) => {
      if (isClassStarted === true) {
        if (isLandmarksWithinImage) {
          setDisplayInFrameMessage(true);
        } else {
          setDisplayIntroMessage(true);
        }
      }
    };
    onChange(isClassStarted, isLandmarksWithinImage);
  }, [isClassStarted]);

  useEffect(() => {
    const onChange = (
      isLandmarksWithinImage: boolean,
      displayIntroMessage: boolean
    ) => {
      if (displayIntroMessage === true && isLandmarksWithinImage === true) {
        setDisplayIntroMessage(false);
        setDisplayInFrameMessage(true);
      }
    };
    onChange(isLandmarksWithinImage, displayIntroMessage);
  }, [isLandmarksWithinImage]);

  useEffect(() => {
    const onChange = (displayInFrameMessage: boolean) => {
      if (displayInFrameMessage === true) {
        const delay = 3 * 1000;
        setTimeout(() => {
          onCanStartPlayback();
          setDisplayInFrameMessage(false);
        }, delay);
      }
    };
    onChange(displayInFrameMessage);
  }, [displayInFrameMessage]);


  const introMessage =
    "Take a few steps back until you see your full body in the screen";
  const inoFrameMessage = "Thats the spot! \nNow lets start!";

  const getCurrentClassTime = (
    block: ClassPracticeAnalysisBlock | undefined,
    blocks: ClassPracticeAnalysisBlock[] | undefined
  ) => {
    if (block && blocks) {
      const index = blocks.findIndex((x) => x.id === block.id);
      const blocksBefore = blocks.filter((x, i) => i < index);
      const blocksBeforeDuration = blocksBefore.reduce((acc, curr) => {
        acc =
          acc +
          curr.explanation_duration_seconds +
          curr.practice_duration_seconds;
        return acc;
      }, 0);
      return blocksBeforeDuration;
    }
    return 0;
  };

  return (
    <div className={classes.root}>
      {!displayInFrameMessage &&
        !displayIntroMessage &&
        !isLandmarksWithinImage &&
        isClassStarted && (
          <ButtonContainer
            isFullScreenEnabled={isFullScreenEnabled}
            toggleFullScreen={toggleFullScreen}
            isInstructorVideoMuted={isInstructorVideoMuted}
            toggleMute={toggleMute}
            isInstructorVideoPlaying={isInstructorVideoPlaying}
            togglePlay={togglePlay}
            onExit={onExit}
          />
        )}
      {!displayInFrameMessage &&
        !displayIntroMessage &&
        block &&
        blockVideoType === BlockVideoType.Explanation &&
        blockVideoCurrentTime > 0 &&
        blockVideoCurrentTime <= 7 && <ComingNext block={block} />}
      {!displayInFrameMessage &&
        !displayIntroMessage &&
        block &&
        blockVideoType === BlockVideoType.Explanation &&
        blockVideoCurrentTime > 7 && (
          <div className={classes.leftSide}>
            <div
              style={{
                color: "white",
                padding: 15,
                fontSize: 100,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {block.alternative_text}
            </div>
          </div>
        )}
      {!isClassStarted && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        ></div>
      )}
      {displayInFrameMessage && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        >
          <div
            style={{
              color: "white",
              padding: 15,
              fontSize: 100,
              fontWeight: 700,
            }}
          >
            {inoFrameMessage}
          </div>
        </div>
      )}
      {displayIntroMessage && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        >
          <div
            style={{
              color: "white",
              padding: 15,
              fontSize: 100,
              fontWeight: 700,
            }}
          >
            {introMessage}
          </div>
        </div>
      )}
      {blockVideoType === BlockVideoType.Explanation &&
        isClassStarted &&
        isInstructorVideoPlaying &&
        block &&
        Math.round(block.explanation_duration_seconds - blockVideoCurrentTime) >
          3 && (
          <div
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              width: 180,
              height: 180,
            }}
          >
            <CornerCountdown
              duration={block.explanation_duration_seconds}
              currentTime={blockVideoCurrentTime}
            />
          </div>
        )}
      {blockVideoType === BlockVideoType.Explanation &&
        isClassStarted &&
        isInstructorVideoPlaying &&
        block &&
        Math.round(block.explanation_duration_seconds - blockVideoCurrentTime) >
          -1 &&
        Math.round(block.explanation_duration_seconds - blockVideoCurrentTime) <
          4 && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 300,
              height: 300,
            }}
          >
            <CenterCountdown
              duration={block.explanation_duration_seconds}
              currentTime={blockVideoCurrentTime}
            />
          </div>
        )}
      {blockVideoType === BlockVideoType.Summary &&
        reward &&
        isClassStarted &&
        block &&
        !isInstructorVideoPlaying && (
          <BlockSummary
            classData={classData}
            block={block}
            score={reward.rewardProgressPercentage}
            studentRepsCounter={studentRepsCounter}
            effectiveness={effectiveness}
          />
        )}
      {isClassStarted &&
        !displayInFrameMessage &&
        !displayIntroMessage &&
        blockVideoType === BlockVideoType.Explanation && (
          <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
            <ClassProgressBar
              duration={classData.classDuration}
              currentTime={getCurrentClassTime(block, blocks)}
            />
          </div>
        )}
      {blockVideoType === BlockVideoType.Practice &&  (
        <Feedback feedback={feedback} />
      )}
    </div>
  );
};

export default AnalysisOverlay;
