import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { AppRoute } from "../../Routes/app-route.type";

type Props = {
  breadcrumbs?: AppRoute[];
  title: string;
  data?: any;
};

export default function BreadcrumbsContainer(props: Props) {
  const { breadcrumbs, title, data } = props;
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
    data: any
  ) => {
    if (path.indexOf(":") !== -1 && !data) {
      event.preventDefault();
    }
    const nonOptionalKeys = path
      .split("/")
      .filter((p) => p.startsWith(":") && !p.endsWith("?"))
      .map((p) => p.replace(":", ""));
    if (
      data &&
      nonOptionalKeys.length > 0 &&
      nonOptionalKeys.some((key) => data[key] === undefined)
    ) {
      event.preventDefault();
    }
  };

  const getPath = (path: string) => {
    if (!data) {
      return path;
    }
    const steps = path.split("/");
    const replacedSteps = steps.map((step) => {
      if (step.endsWith("?")) {
        return "";
      }
      if (step.startsWith(":")) {
        const key = step.replace(":", "");
        const value = data[key];
        if (value) {
          return value;
        }
        return "";
      }
      return step;
    });
    return replacedSteps.join("/");
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs &&
        breadcrumbs.map((r: any) => (
          <Link
            color="inherit"
            href={getPath(r.path)}
            key={r.path}
            onClick={(e: any) => handleClick(e, r.path, data)}
          >
            {r.title}
          </Link>
        ))}
      <Typography color="textPrimary">{title}</Typography>
    </Breadcrumbs>
  );
}
