import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Modal } from "@material-ui/core";
import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";
import * as channelClassProvider from "../../../providers/student/channel-class.provider";
import * as studentActivityProvider from "../../../providers/student/activity.provider";
import * as studentActivityTimespanProvider from "../../../providers/student/activity-timespan.provider";
import * as studentActivityTimespanRecordProvider from "../../../providers/student/activity-timespan-record.provider";
import { LiveClassModel } from "../../../types/analyze/live.class.type";
import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";
import {
  checkIfLandmarkssWithinImage,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import { CourseAnalysisDetails } from "../../../types/class/class-analysis-details";
import { RewardType } from "../../../enums/reward-type.enum";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";
import * as audioService from "../../../services/audio.service";
import * as rewardService from "../../../services/reward/reward.service";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Landmark } from "@mediapipe/pose";
import * as speechHelper from "../../../services/speech.helper";
import { AccuracyChart } from "./components";
import LiveModelCombined2D from "../../../components/Analysis/LiveModelCombined/LiveModelCombined2D";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import AccuracyProgressBar from "./components/AccuracyProgressBar";
import TimeCountdown from "./components/TimeCountdown";
import RepsProgressBar from "./components/RepsProgressBar";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import logo from "../../../assets/images/logo_only2.png";
import { TimespanClassType } from "../../../types/analyze/timespan-class-type.enum";
import AnalysisOverlayDefault from "../../../components/Analysis/AnalysisOverlayDefault";
import ClassSummary from "./components/ClassSummary";
import { mapJointsAccuracy, round } from "../../../utils/conversion.functions";

const selfieMode = true;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: window.innerHeight,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: window.innerHeight,
    width: "100%",
  },
  blockContainer: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  studentCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s ease-in",
  },
  fullScreenButton: {
    position: "absolute",
    top: "60%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  startClassButton: {
    position: "absolute",
    top: "70%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  exitClassButton: {
    position: "absolute",
    top: "80%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid red",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  bottomContainer: {
    height: "18%",
    width: "100%",
    position: "fixed",
    bottom: 0,
    "&::before": {
      position: "fixed",
      bottom: 0,
      content: '""',
      height: "35vh",
      width: "100%",
      backgroundImage: "linear-gradient(180deg, transparent 25%, #1b1b1b 50%)",
      zIndex: -1,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
}));

type Params = {
  classId?: string;
};

const usePrevious = <T extends {}>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function AnalyzeCourse() {
  const history = useHistory();
  const classes = useStyles();

  const [feedback, setFeedback] = useState<string>();
  //used for effectivenes calculation
  const accuracyRef = useRef<any>({});
  const effectivenesRef = useRef<any>({});
  const [repsCounter, setRepsCounter] = useState<number>(0);

  const [live, setLive] = useState<LiveClassModel>();
  const { classId } = useParams<Params>();

  const canvas2Ref = useRef<HTMLCanvasElement>(null);

  const [course, setCourse] = useState<CourseAnalysisDetails>();
  const [accuracy, setAccuracy] = useState<number>(0);
  const [accuracyTimestamp, setAccuracyTimestamp] = useState<number>();

  const [accuracyRecords, setAccuracyRecords] = useState<
    {
      accuracy: number;
      timestamp: number;
      timespan: CourseAnalysisTimespan;
    }[]
  >([]);

  const [isBellEnabled, setIsBellEnabled] = useState<boolean>(true);

  const onPlayBeep = () => {
    if (isBellEnabled) {
      audioService.beep();
    }
  };

  const [isClassEnded, setIsClassEnded] = useState<boolean>(false);

  //TODO: add missing button
  // const onToggleBell = () => {
  //   setIsBellEnabled(!isBellEnabled);
  // };

  const timespanIdMappingRef = useRef<any>(undefined);

  const instructorVideoRef = useRef<HTMLVideoElement>(null);

  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };

  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastTimeSpanId, setLastTimeSpanId] = useState<string>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [lastInstructorVideoPaused, setLastInstructorVideoPaused] = useState<
    boolean | undefined
  >(false);
  const [lastInstructorVideoEnded, setLastInstructorVideoEnded] = useState<
    boolean | undefined
  >(false);

  const [canStartClass, setCanStartClass] = useState<boolean>(false);

  const [isClassStarted, setIsClassStarted] = useState<boolean>(false);

  const [timespanAccuracy, setTimespanAccuracy] = useState<
    { percentage: number; currentTime: number }[]
  >([]);

  const [timespanProgress, setTimespanProgress] = useState<number>(0);
  const rewardsRef = useRef<any>({});

  const [timespanClassType, setTimespanClassType] = useState<TimespanClassType>(
    TimespanClassType.None
  );
  const [currentTimespan, setCurrentTimespan] = useState<
    CourseAnalysisTimespan | undefined
  >();

  const [stream, setStream] = useState<MediaStream>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [streamErrorMessage, setStreamErrorMessage] = useState<string>();

  const onTimeUpdate = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      const { currentTime } = instructorVideoElement;
      if (
        timespanClassType === TimespanClassType.Video &&
        !currentTimespan &&
        course
      ) {
        const timespan = course.timespans.find(
          (x) => x.start_seconds <= currentTime && x.end_seconds >= currentTime
        );
        if (
          timespan &&
          Math.floor(timespan.end_seconds) !== Math.floor(currentTime)
        ) {
          setCurrentTimespan(timespan);
          setTimespanClassType(TimespanClassType.Timespan);
        }
      } else if (
        timespanClassType === TimespanClassType.Timespan &&
        currentTimespan &&
        Math.floor(currentTimespan.end_seconds) === Math.floor(currentTime)
      ) {
        const calculateEffectiveness = (accuracy: any) => {
          const keys = Object.keys(accuracy);
          if (keys.length === 0) {
            return 0;
          }
          const sum = keys.reduce((acc, curr) => {
            return acc + accuracy[curr];
          }, 0);
          return Math.round(sum / keys.length);
        };
        const effectiveness = calculateEffectiveness(accuracyRef.current);
        effectivenesRef.current[currentTimespan.id] = effectiveness;
        accuracyRef.current = {};
        repetitionAccuracyRef.current = {};
        if (prevTimespan && course) {
          const { timespans } = course;

          const prevIndex = timespans.findIndex(
            (t) => t.id === prevTimespan.id
          );
          if (prevIndex >= 0) {
            const timespan = timespans[prevIndex];
            const reward = rewardService.getReward(timespanProgress);

            if (!rewardsRef.current[timespan.id]) {
              rewardsRef.current[timespan.id] = {
                rewardProgressPercentage:
                  timespanProgress > 100 ? 100 : timespanProgress,
              };
              onSaveReward(
                reward,
                prevTimespan.id,
                timespanProgress > 100 ? 100 : timespanProgress,
                effectiveness
              );
            }
          }
        }

        setCurrentTimespan(undefined);
        setTimespanClassType(TimespanClassType.Video);
      }
    }
  };
  const prevTimespan = usePrevious<any>(currentTimespan);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const onTogglePlay = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      if (instructorVideoElement.paused) {
        await instructorVideoElement.play();
      } else {
        instructorVideoElement.pause();
      }
    }
  };

  const onSaveReward = (
    reward: RewardType,
    timespanId: string,
    progress: number,
    effectiveness: number
  ) => {
    try {
      const model = {
        reward: reward,
        timespanId: timespanIdMappingRef.current[timespanId],
        score: Math.round(progress),
        effectiveness: effectiveness
      };
      studentActivityTimespanProvider.saveReward(model);
    } catch (err) {
      //TODO: handle with retries
      console.log(err);
    }
  };

  const feedbaclClearingTimeoutRef = useRef<NodeJS.Timeout>();
  const [allowFeedbackUpdate, setAllowFeedbackUpdate] = useState<boolean>(true);

  const onExerciseAccuracyCalculated = (
    accuracyData: { [key: number]: number },
    live: LiveClassModel,
    currentRep: number,
    timespan: CourseAnalysisTimespan,
    nextExerciseStepIndex: number,
    mergedAccuracyData: any
  ) => {
    const accuracy = accuracyData[nextExerciseStepIndex];
    if (accuracy !== undefined) {
      setAccuracy(accuracy);
      //TODO: wait for 5 seconds from timespan start
      const canShowFeedback = live.classTime - timespan.start_seconds >= 5;
      if (canShowFeedback && allowFeedbackUpdate) {
        const newFeedback = avatarFeedback.getFeedback(
          accuracy,
          mergedAccuracyData[nextExerciseStepIndex]
        );
        if (feedback === undefined && newFeedback !== undefined) {
          setAllowFeedbackUpdate(false);
          setFeedback(newFeedback);
          feedbaclClearingTimeoutRef.current = setTimeout(() => {
            setFeedback(undefined);
            setTimeout(() => {
              //TODO: wait for 3 seconds from prev feedback
              setAllowFeedbackUpdate(true);
            }, 3000);
          }, 5000);
        }
      }
      if (timespan && timespan.exercise) {
        const percentage = (currentRep / timespan.exercise.repetitions) * 100;
        const percentageValue = percentage > 100 ? 100 : percentage;
        setTimespanProgress(percentageValue);
      }

      if (
        live.isLandmarksWithinImage === true &&
        live.studentActivityTimespanId
      ) {
        const accuracyPercentages = Object.keys(accuracyData)
          .sort()
          .map((key) => accuracyData[parseInt(key)]);

        const newRecord = {
          keypoints: live.studentKeypoints.map(({ x, y, z, part }) => {
            return { x: round(x), y: round(y), z: round(z), part: part };
          }),
          classTime: live.classTime,
          timestamp: live.timestamp,
          studentActivityTimespanId: live.studentActivityTimespanId,
          accuracyPercentages: accuracyPercentages,
          repetition: currentRep,
          jointsAccuracy: mapJointsAccuracy(
            mergedAccuracyData[nextExerciseStepIndex]
          ),
          nextExerciseStepIndex: nextExerciseStepIndex,
        };
        try {
          studentActivityTimespanRecordProvider.createExercise(newRecord);
        } catch (err) {
          //TODO: store record locally and send when possible??
          console.log("performance recording failed");
          console.log(err);
        }
      }
    }
  };

  const onPoseAccuracyCalculated = (
    accuracy: number,
    live: LiveClassModel,
    timespan: CourseAnalysisTimespan,
    //currentTime: number,
    mergedAccuracyData: any
  ) => {
    //TODO: wait for 5 seconds from timespan start
    const canShowFeedback = live.classTime - timespan.start_seconds >= 5;
    if (canShowFeedback && allowFeedbackUpdate) {
      const newFeedback = avatarFeedback.getFeedback(
        accuracy,
        mergedAccuracyData
      );
      if (feedback === undefined && newFeedback !== undefined) {
        setAllowFeedbackUpdate(false);
        setFeedback(newFeedback);
        feedbaclClearingTimeoutRef.current = setTimeout(() => {
          setFeedback(undefined);
          setTimeout(() => {
            //TODO: wait for 3 seconds from prev feedback
            setAllowFeedbackUpdate(true);
          }, 3000);
        }, 5000);
      }
    }

    accuracyRef.current[`${live.classTime}`] = accuracy;

    setAccuracy(accuracy);
    //TODO: shift timestamp to make it start at 0
    const accuracyTimestamp =
      Math.round((live.classTime - timespan.start_seconds) * 10) / 10;
    setAccuracyTimestamp(accuracyTimestamp);
    setAccuracyRecords([
      ...accuracyRecords,
      { accuracy: accuracy, timestamp: live.timestamp, timespan: timespan },
    ]);

    const newTimespanAccuracy = timespanAccuracy.map((i) => i);
    newTimespanAccuracy.push({
      percentage: accuracy,
      currentTime: live.classTime,
    });
    setTimespanAccuracy(newTimespanAccuracy);

    const calculateProgressValue = (timespanAccuracy: any[]) => {
      const thresholdPercentage = 85;
      const fullProgressBarSeconds = 15;
      const percentageValue = fullProgressBarSeconds / 100;
      const durationSeconds = timespanAccuracy.reduce((acc, curr, index) => {
        if (index > 0 && curr.percentage >= thresholdPercentage) {
          const prevRecord = timespanAccuracy[index - 1];
          const recordDurationSeconds =
            curr.currentTime - prevRecord.currentTime;
          acc = acc + recordDurationSeconds;
        }
        return acc;
      }, 0);
      return durationSeconds / percentageValue;
    };

    const progressValue = calculateProgressValue(newTimespanAccuracy);
    setTimespanProgress(progressValue);

    if (
      live.isLandmarksWithinImage === true &&
      live.studentActivityTimespanId
    ) {
      const newRecord = {
        keypoints: live.studentKeypoints.map(({ x, y, z, part }) => {
          return { x: round(x), y: round(y), z: round(z), part: part };
        }),
        classTime: live.classTime,
        timestamp: live.timestamp,
        studentActivityTimespanId: live.studentActivityTimespanId,
        accuracy: accuracy,
        jointsAccuracy: mapJointsAccuracy(mergedAccuracyData),
      };
      try {
        studentActivityTimespanRecordProvider.createPose(newRecord);
      } catch (err) {
        //TODO: store record locally and send when possible??
        console.log("performance recording failed");
        console.log(err);
      }
    }
  };
  const repetitionAccuracyRef = useRef<any>({});
  const onRepetition = (repetition: number, repetitionAccuracy: number) => {
    if (isBellEnabled) {
      speechHelper.speak(repetition.toString());
    }
    setRepsCounter(repetition);
    repetitionAccuracyRef.current[repetition] = repetitionAccuracy;
  };

  useEffect(() => {
    const initVideoStream = async () => {
      const video = {
        width: 640,
        height: 480,
        //TODO: try to limit processing by fps
      };
      const constraints = {
        audio: false,
        video: video,
      };

      const getMedia = async (constraints: MediaStreamConstraints) => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          return stream;
        } catch (err) {
          const getErrorMessage = (errorName: string) => {
            if (errorName === "NotAllowedError") {
              return "In order to proceed - give permission to access camera";
            }

            if (errorName === "NotFoundError") {
              return "Camera device not found";
            }
            return "An error occurred while accessing camera";
          };

          const errorMessage = getErrorMessage(err.name);
          setStreamErrorMessage(errorMessage);
          handleOpen();
          //If the user denies permission, or matching media is not available, then the promise is rejected with NotAllowedError or NotFoundError respectively
        }
      };

      const stream = await getMedia(constraints);
      if (stream) {
        setStream(stream);
      }
    };

    initVideoStream();
  }, []);

  useEffect(() => {
    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();
      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00035; //0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const course = await channelClassProvider.getForAnalysis(classId);
      setCourse(course);

      const model = {
        class_id: classId,
        threshold_accuracy: exerciseSuccessThresholdPercentage,
        timespans: course.timespans.map(
          ({ id, start_seconds, pose, exercise, end_seconds }) => {
            const model = {
              id: id,
              start_seconds,
              end_seconds,
              pose,
              //TODO: update exercise mapping to send only useful data
              exercise,
            };
            return model;
          }
        ),
      };
      const { timespan_id_mapping } = await studentActivityProvider.create(
        model
      );
      timespanIdMappingRef.current = timespan_id_mapping;
    };

    const initVideoElement = (stream: MediaStream) => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.crossOrigin = "anonymous";
      const onCanPlayHandler = async () => {
        videoElement.play();
        processFrame();
        videoElement.removeEventListener("canplay", onCanPlayHandler);
      };

      videoElement.addEventListener("canplay", onCanPlayHandler);
      videoElement.srcObject = stream;
      studentVideoElementRef.current = videoElement;
    };

    const initializeModel = async (classId: string, stream: MediaStream) => {
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      initVideoElement(stream);
      speechHelper.initSpeech();

      setCanStartClass(true);
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const flipCanvas = (canvas: HTMLCanvasElement | null) => {
          if (canvas) {
            const context = canvas.getContext("2d");
            if (context) {
              context.translate(canvas.width, 0);
              context.scale(-1, 1);
            }
          }
        };
        const canvas2 = canvas2Ref.current;
        flipCanvas(canvas2);
      }
    };

    if (classId && stream) {
      initializeModel(classId, stream);
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  useEffect(() => {
    const onResults = async (
      results: any,
      cource: CourseAnalysisDetails | undefined
    ) => {
      const { poseLandmarks, image } = results;

      if (poseLandmarks && cource) {
        const { timespans } = cource;
        const processLandmarks = (
          poseLandmarks: Landmark[],
          timespans: CourseAnalysisTimespan[]
        ) => {
          const filterKalmanUnity = (
            measurements: Measurement3D[],
            joints: Landmark[],
            customKalmanFilter: CustomKalmanFilter
          ) => {
            const filteredUnity = applyKalmanFilterUnity(
              measurements,
              joints,
              customKalmanFilter
            );
            if (filteredUnity) {
              setMeasurements(filteredUnity);
              const mappedUnity = filteredUnity.map((f, index: number) => {
                return {
                  x: f.pos3d.x,
                  y: f.pos3d.y,
                  z: f.pos3d.z,
                  visibility: joints[index].visibility,
                };
              });
              return mappedUnity;
            }
          };
          const canvas2Element = canvas2Ref.current;
          if (!canvas2Element) {
            return;
          }

          const timespan = timespans.find((x) => x.id === lastTimeSpanId);

          const getFilter = (timespan: CourseAnalysisTimespan | undefined) => {
            if (timespan && timespan.exercise) {
              return customKalmanFilterFastRef.current;
            }
            return customKalmanFilterSlowRef.current;
          };

          const filter = getFilter(timespan);
          if (!filter) {
            return;
          }

          const filtered = filterKalmanUnity(
            measurements,
            poseLandmarks,
            filter
          );
          if (!filtered) {
            return;
          }

          const { width, height } = canvas2Element;
          const denormalized = mediapipeKeypoint.denormalize(
            filtered,
            width,
            height
          );
          const studentKeypointsScaledByZ = mapForAnalysis(denormalized);

          drawOriginal(filtered, image, canvas2Element);
          const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
            denormalized,
            width,
            height
          );

          if (
            lastCurrentTime !== undefined &&
            lastTimestamp &&
            timespanIdMappingRef.current
          ) {
            const live: LiveClassModel = {
              studentKeypoints: studentKeypointsScaledByZ,
              classTime: lastCurrentTime,
              timestamp: lastTimestamp,
              isLandmarksWithinImage: isLandmarksWithinImage,
              studentActivityTimespanId: lastTimeSpanId
                ? timespanIdMappingRef.current[lastTimeSpanId]
                : undefined,
              isInstructorVideoEnded: lastInstructorVideoEnded,
              isInstructorVideoPaused: lastInstructorVideoPaused,
            };

            setLive(live);
          } else {
            setLive(undefined);
            const canvas2Element = canvas2Ref.current;
            drawOriginal([], image, canvas2Element);
          }
        };
        processLandmarks(poseLandmarks, timespans);
      } else {
        setLive(undefined);
        const canvas2Element = canvas2Ref.current;
        drawOriginal([], image, canvas2Element);
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results) {
      onResults(results, course);
    }
  }, [results]);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;

    if (videoElement) {
      const model = mediapipeModelRef.current;
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        const currentTime = instructorVideoElement.currentTime;
        setLastCurrentTime(currentTime);
        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        if (course) {
          const timeSpan = course.timespans.find(
            (x) =>
              x.start_seconds <= currentTime && x.end_seconds >= currentTime
          );
          if (timeSpan) {
            setLastTimeSpanId(timeSpan.id);
          } else {
            setLastTimeSpanId(undefined);
          }
        }

        const instructorVideo = instructorVideoRef.current;
        if (instructorVideo) {
          setLastInstructorVideoPaused(instructorVideo.paused);
          setLastInstructorVideoEnded(instructorVideo.ended);
        } else {
          setLastInstructorVideoPaused(undefined);
          setLastInstructorVideoEnded(undefined);
        }
        await model.send({ image: videoElement });
      }
    }
  };

  const [isInFrameCheckPassed, setIsInFrameCheckPassed] =
    useState<boolean>(false);

  const onStartVideo = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && instructorVideoElement.paused) {
      setTimespanClassType(TimespanClassType.Video);
      await instructorVideoElement.play();
    }
    setIsInFrameCheckPassed(true);
  };

  const onStartClass = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && course) {
      instructorVideoElement.src = course.file_path;
      instructorVideoElement.muted = true;
      await instructorVideoElement.play();
      instructorVideoElement.pause();
      instructorVideoElement.muted = false;
    }
    setIsClassStarted(true);
  };

  useEffect(() => {
    setTimespanAccuracy([]);
    setTimespanProgress(0);
    setAccuracyTimestamp(undefined);
    setRepsCounter(0);
  }, [currentTimespan]);

  const onReturnClickHandler = () => {
    history.push("/");
  };

  const onInstructorVideoEnded = () => {
    setIsClassEnded(true);
  };

  const calculateClassEfeectiveness = () => {
    const keys = Object.keys(effectivenesRef.current);
    if (keys.length === 0) {
      return 0;
    }
    const sum = keys.reduce((acc, curr) => {
      const effectiveness = effectivenesRef.current[curr];
      return acc + effectiveness;
    }, 0);
    return sum / keys.length;
  };

  const pauseTimeoutRef = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    const onChange = (live: any) => {
      const videoElement = instructorVideoRef.current;
      if (isInFrameCheckPassed && videoElement) {
        if (!pauseTimeoutRef.current && !live) {
          pauseTimeoutRef.current = setTimeout(() => {
            videoElement.pause();
            pauseTimeoutRef.current = undefined;
          }, 7000);
        }
        if (pauseTimeoutRef.current && live) {
          clearTimeout(pauseTimeoutRef.current);
          pauseTimeoutRef.current = undefined;
        }
      }
    };
    onChange(live);
  }, [live]);

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2>Error</h2>
          <p>{streamErrorMessage}</p>
        </div>
      </Modal>
      {!isProcessingStarted && (
        <LinearProgress
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: 7,
            zIndex: 10,
          }}
        />
      )}

      {!isClassEnded && (
        <Grid
          className={classes.container}
          style={{
            visibility: !isProcessingStarted ? "hidden" : "visible",
            width: "100%",
          }}
          container
        >
          <div
            style={{
              position: "absolute",
              top: window.innerHeight / 4,
              zIndex: 1,
              height: window.innerHeight / 3,
              transition: "opacity 0.6s",
              opacity:
                isClassStarted &&
                timespanClassType === TimespanClassType.Timespan
                  ? 1
                  : 0,
            }}
          >
            <AccuracyProgressBar accuracy={accuracy} />
          </div>
          {isClassStarted &&
            instructorVideoRef.current &&
            !instructorVideoRef.current.paused &&
            timespanClassType === TimespanClassType.Timespan &&
            currentTimespan && (
              <div
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  zIndex: 1,
                }}
              >
                <TimeCountdown
                  duration={
                    currentTimespan.end_seconds - currentTimespan.start_seconds
                  }
                  currentTime={
                    instructorVideoRef.current.currentTime -
                    currentTimespan.start_seconds
                  }
                />
              </div>
            )}
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isClassStarted ? "height 0.6s" : "",
              height:
                isClassStarted &&
                timespanClassType === TimespanClassType.Timespan
                  ? "83vh"
                  : "100vh",
              borderRight: "4px solid grey",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <canvas
                ref={canvas2Ref}
                width={640}
                height={480}
                style={{ height: "100%" }}
              />
            </div>
          </Grid>
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isClassStarted ? "height 0.6s" : "",
              opacity: isClassStarted ? 1 : 0,
              height:
                isClassStarted &&
                timespanClassType === TimespanClassType.Timespan
                  ? "83vh"
                  : "100vh",
              borderLeft: "4px solid grey",
            }}
          >
            <video
              style={{
                height: "100%",
                transform: "translateX(-50%)",
                marginLeft: "50%",
              }}
              crossOrigin="anonymous"
              onEnded={onInstructorVideoEnded}
              onTimeUpdate={onTimeUpdate}
              ref={instructorVideoRef}
            />
          </Grid>
          <Grid
            xs={12}
            item
            container
            className={classes.bottomContainer}
            style={{
              transition: "opacity 0.6s",
              opacity:
                isClassStarted &&
                timespanClassType === TimespanClassType.Timespan
                  ? 1
                  : 0,
            }}
          >
            {currentTimespan && currentTimespan.exercise && (
              <Grid
                xs={2}
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span style={{ fontSize: 40 }}>Repetitions</span>
                <div>
                  <span style={{ fontSize: 80, fontWeight: 500 }}>
                    {repsCounter}
                  </span>
                  <span style={{ fontSize: 60 }}>/</span>
                  <span style={{ fontSize: 60 }}>
                    {currentTimespan.exercise.repetitions}
                  </span>
                </div>
              </Grid>
            )}
            {currentTimespan &&
              currentTimespan.exercise &&
              timespanClassType === TimespanClassType.Timespan && (
                <Grid xs={10}>
                  <RepsProgressBar
                    requiredReps={currentTimespan.exercise.repetitions}
                    studentReps={repsCounter}
                    repsAccuracy={repetitionAccuracyRef.current}
                  />
                </Grid>
              )}
            {currentTimespan &&
              currentTimespan.pose &&
              timespanClassType === TimespanClassType.Timespan && (
                <AccuracyChart
                  duration={
                    currentTimespan.end_seconds - currentTimespan.start_seconds
                  }
                  accuracy={
                    accuracyTimestamp !== undefined
                      ? {
                          value: accuracy,
                          seconds: accuracyTimestamp,
                        }
                      : undefined
                  }
                />
              )}
          </Grid>
          {studentVideoElementRef.current &&
            canvas2Ref.current &&
            instructorVideoRef.current &&
            isClassStarted &&
            course && (
              <LiveModelCombined2D
                live={live}
                selfieMode={selfieMode}
                timespan={currentTimespan}
                onPoseAccuracy={onPoseAccuracyCalculated}
                onExerciseAccuracy={onExerciseAccuracyCalculated}
                onRepetition={onRepetition}
                onPlayBeep={onPlayBeep}
                isTest={false}
              />
            )}
          <Grid className={classes.studentCanvas}>
            {instructorVideoRef.current && course && (
              <AnalysisOverlayDefault
                isLandmarksWithinImage={!!(live && live.isLandmarksWithinImage)}
                isClassStarted={isClassStarted}
                onCanStartPlayback={onStartVideo}
                isInstructorVideoPlaying={!instructorVideoRef.current.paused}
                onExit={onReturnClickHandler}
                isFullScreenEnabled={!!document.fullscreenElement}
                toggleFullScreen={toggleFullScreen}
                isInstructorVideoMuted={instructorVideoRef.current.muted}
                toggleMute={onToggleInstructorVideoSound}
                togglePlay={onTogglePlay}
                timespanClassType={timespanClassType}
                feedback={feedback}
              />
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.fullScreenButton}
                onClick={toggleFullScreen}
                variant="contained"
                size="large"
                color="default"
                startIcon={
                  !document.fullscreenEnabled ? (
                    <FullscreenIcon />
                  ) : (
                    <FullscreenExitIcon />
                  )
                }
              >
                Fullscreen
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButton}
                onClick={onStartClass}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start Class
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                onClick={onReturnClickHandler}
                className={classes.exitClassButton}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<ExitToAppIcon />}
              >
                Exit
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {isClassEnded && course && (
        <ClassSummary
          instructorAvatar={course.avatar}
          instructorName={course.instructor}
          effectiveness={calculateClassEfeectiveness()}
          classThumbnail={course.thumbnail_path}
          channelType={course.type}
          rewards={rewardsRef.current}
          timespansCount={course.timespans.length}
          classDuration={course.duration}
          onReturnClickHandler={onReturnClickHandler}
        />
      )}
    </div>
  );
}
