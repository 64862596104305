import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ClassPracticeAnalysisBlock } from "../../../../../types/class-practice/class-practice-analysis-block";

const useStyles = makeStyles((theme: Theme) => ({
  leftSide: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "50%",
    backgroundColor: "rgba(27, 27, 27, 0.7)",
  },
}));

type Props = {
  block: ClassPracticeAnalysisBlock;
};

export default function ComingNext(props: Props) {
  const { block } = props;
  const classes = useStyles();
  return (
    <div className={classes.leftSide}>
      <div
        style={{
          height: "100%",
          color: "white",
          padding: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            fontSize: 60,
            fontWeight: 500,
          }}
        >
          Coming next
        </div>
        <div
          style={{
            fontSize: 80,
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          {block.name}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: 100,
              }}
            >
              {Math.round(block.practice_duration_seconds)}
            </span>
            <span
              style={{
                fontSize: 50,
                fontWeight: 900,
              }}
            >
              Sec.
            </span>
          </div>
          {block.exercise && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: 100,
                }}
              >
                {block.exercise.repetitions}
              </span>
              <span
                style={{
                  fontSize: 50,
                  fontWeight: 900,
                }}
              >
                Reps.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
