import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: "center",
  },
}));

export default function RegistrationSuccess() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h2">Password Changed Success</Typography>
            <Typography variant="subtitle2">
              Please login with your new password
            </Typography>
            <Button
              onClick={() => history.push("/login")}
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
            >
              Go to Login
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
