import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Input, Theme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "4px",
    alignItems: "center",
    padding: theme.spacing(1),
    display: "flex",
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "-0.05px",
  },
}));

type Props = {
  onChange: (value: string) => void;
};

export default function SearchInput(props: Props) {
  const { onChange } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <SearchIcon className={classes.icon} />
      <Input
        className={classes.input}
        disableUnderline
        onChange={(event) => onChange(event.target.value as string)}
      />
    </Paper>
  );
}
