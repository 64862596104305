import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelClassPracticeBlock = {
  id: number;
  block_id: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_duration_seconds?: number;
  exercise_repetitions?: number;
  name: string;
  thumbnail_path: string;
  order: number;
};

export type ChannelClassPracticeBlockCreate = {
  block_id: string;
  duration_seconds: number;
  pose_duration_seconds?: number;
  exercise_repetitions?: number;
  order: number;
};

export type ChannelClassPracticeBlockCreateListItem = {
  id: number;
  block_id: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_duration_seconds?: number;
  exercise_repetitions?: number;
  name: string;
  thumbnail_path: string;
};

export type ChannelClassPracticeOption = {
  id: string;
  name: string;
  description: string;
  creation_date: string;
  thumbnail_path: string;
  is_published: boolean;
  channel_id: string;
  duration_seconds: number;
  block_count: number;
};

export type ChannelClassPractice = {
  name: string;
  description: string;
  blocks: ChannelClassPracticeBlock[];
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelClassPracticeOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/create",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/edit",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClassPractice> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/remove",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      channel_id: model.channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelClassEditDetails = {
  name: string;
  file_path: string;
  thumbnail_path: string;
};

export const setPublished = async (
  classId: string,
  isPublished: boolean
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-practice/setPublished",
    data: {
      id: classId,
      is_published: isPublished,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
