import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import defaultThumbnail from "../../../../../assets/images/channel-thumbnail.png";
import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { AvatarType } from "../../../../../services/avatar/avatar-type.enum";
import * as authService from "../../../../../services/auth.service";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
    position: "relative",
  },
  card: {
    position: "relative",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  publishIcon: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  publishedIcon: {
    color: "green",
  },
  unpublishedIcon: {
    color: "red",
  },
}));

const CourseCard = (props) => {
  const { className, course, onRemove, onPublish, channelId, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const isAdmin = authService.isAdmin();

  const onEdit = (id) => {
    history.push(`/sports-instructor/channel/${channelId}/class/${id}/edit`);
  };

  const onAnalysis = () => {
    history.push(
      `/sports-instructor/take-class/${course.id}/${channelId}/${AvatarType.Male}`
    );
  };

  const onAnalysisTest = () => {
    history.push(
      `/sports-instructor/take-class-test/${course.id}/${channelId}/${AvatarType.Male}`
    );
  };

  const getDetailsText = () => {
    const detailTextParts = [];
    if (course.pose_count) {
      const suffix = course.pose_count === 1 ? "pose" : "poses";
      detailTextParts.push(`${course.pose_count} ${suffix}`);
    }
    if (course.exercise_count) {
      const suffix =
        course.exercise_count === 1 ? "exercise set" : "exercise sets";
      detailTextParts.push(`${course.exercise_count} ${suffix}`);
    }
    const detailsText = [];
    detailsText.push("Сonsists of ");
    for (let index = 0; index < detailTextParts.length; index++) {
      const text = detailTextParts[index];
      if (detailTextParts.length > 1 && index !== 0) {
        if (index === detailTextParts.length - 1) {
          detailsText.push(" and ");
        } else {
          detailsText.push(", ");
        }
      }
      detailsText.push(text);
    }

    return detailsText.join("");
  };

  return (
    <Card {...rest} className={classes.card}>
      <CardActionArea>
        <CardMedia
          style={{
            backgroundSize: "contain",
          }}
          className={classes.media}
          image={course.thumbnail_path || defaultThumbnail}
          title={course.name}
        >
          {course.is_published ? (
            <CheckBoxIcon
              title="Published"
              className={`${classes.publishIcon} ${classes.publishedIcon}`}
            />
          ) : (
            <IndeterminateCheckBoxIcon
              title="Not published"
              className={`${classes.publishIcon} ${classes.unpublishedIcon}`}
            />
          )}
        </CardMedia>
        <CardContent>
          <Typography
            gutterBottom
            className={classes.name}
            title={course.name}
            variant="h3"
            component="h2"
          >
            {course.name}
          </Typography>
          <Typography className={classes.description} variant="h6">
            {getDetailsText()}
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {new Date(course.creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => onEdit(course.id)}>
          Edit
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onPublish(course.id)}
        >
          {course.is_published ? "Unpublish" : "Publish"}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onRemove(course.id)}
        >
          Remove
        </Button>
        <Button size="small" color="secondary" onClick={onAnalysis}>
          Live
        </Button>
        {isAdmin && (
          <Button size="small" color="secondary" onClick={onAnalysisTest}>
            Test
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default CourseCard;
