import * as hands from './mediapipe.hands.service';
import * as holistic from './mediapipe.holistic.service';
import * as pose from './mediapipe.pose.service';

export const getModel = async () => {
  await hands.clear();
  await holistic.clear();
  return await pose.getModel();
};

export const getModel1 = async () => {
  await hands.clear();
  await holistic.clear();
  return await pose.getModel1();
};

export const getStaticModel = async () => {
  await hands.clear();
  await holistic.clear();
  return await pose.getStaticModel();
};

export const getStaticModel1 = async () => {
  await hands.clear();
  await holistic.clear();
  return await pose.getStaticModel1();
};

export const getHolisticModel = async () => {
  await hands.clear();
  await pose.clear();
  return await holistic.getModel();
};

export const getHandsModel = async () => {
  await holistic.clear();
  await pose.clear();
  return await hands.getModel();
};