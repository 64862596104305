import React, { Component } from 'react';
import { IconButton, Grid, Typography, CircularProgress, Divider } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ActivityCard } from './components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as activityProvider from '../../../providers/admin/activity.provider';
import { ActivityOption } from '../../../providers/admin/activity.provider';
import BreadcrumbsContainer from '../../../components/BreadcrumbsContainer';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
});

type State = {
  activities?: Array<ActivityOption>,
  loading: boolean
};

type Props = RouteComponentProps<any> & {
  classes: any,
  title: string,
  breadcrumbs: any[]
};

class ChannelList extends Component<Props, State> {
  constructor(props:Props){
    super(props);       
    this.state = {
      loading: true
    }; 
  }

  componentDidMount = async () => {
      try{
          const activities = await activityProvider.getAll();          
          this.setState({
              activities: activities,
              loading: false
          });
      }
      catch(err){
        alert('An error ocured, while processing your request.');
        this.setState({
          loading: false
        });
      }
  }

  onRemove = async (id: string) => {
    await activityProvider.remove(id);
    this.setState({
      activities: this.state.activities && this.state.activities.filter(i=> i.id !== id)
    });
  }

  render = () => {  
    const { 
        activities,
        loading
    } = this.state;

    const { classes, title } = this.props;

    return (
    <div className={classes.root}>
      {/* <Typography component="h1" variant="h4" gutterBottom>
          {title}
      </Typography> */}
      <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
      <BreadcrumbsContainer breadcrumbs={this.props.breadcrumbs} title={this.props.title} />
      <Divider/>
      <div className={classes.content}>
        {
          loading
            ? <CircularProgress />
            : <Grid
            container
            spacing={3}
          >
            { activities && activities.map(activity => (
              <Grid
                item
                key={activity.id}
                lg={4}
                md={6}
                xs={12}
              >
                <ActivityCard activity={activity} onRemove={this.onRemove} />
              </Grid>
            ))}
            </Grid>
        }
      </div>
      {/* <div className={classes.pagination}>
        <Typography variant="caption">1-6 of 20</Typography>
        <IconButton>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </div> */}
    </div>
  );
  };
};

const styledComponent = withStyles(styles)(ChannelList);
export default withRouter(styledComponent as any)
