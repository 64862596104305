import React, { Component } from "react";
import { Grid, CircularProgress } from "@material-ui/core";

import { ChannelClassCard } from "./components";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelClassProvider from "../../../../../providers/student/channel-class.provider";
import { ChannelCourseItem } from "../../../../../providers/student/channel-class.provider";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
  });

type State = {
  courses?: Array<ChannelCourseItem>;
  loading: boolean;
};

type Props = {
  classes: any;
  channelId: string;
};

class ChannelCourseList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      const courses = await channelClassProvider.getAllByChannel(
        this.props.channelId
      );
      this.setState({
        courses: courses,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  render = () => {
    const { courses, loading } = this.state;

    const { classes, channelId } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={4}>
              {courses &&
                courses.map((course) => (
                  <Grid item key={course.id} lg={3} md={6} xs={12}>
                    <ChannelClassCard course={course} channelId={channelId} />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(ChannelCourseList);
