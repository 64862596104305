import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import {
  ChannelClassSequenceToolbar,
  ChannelClassSequenceCard,
} from "./components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as channelClassSequenceProvider from "../../../../providers/instructor/channel-class-sequence.provider";
import { ChannelClassSequenceOption } from "../../../../providers/instructor/channel-class-sequence.provider";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type Props = {
  channelId: string;
};

export default function ChannelClassPracticeList(props: Props) {
  const { channelId } = props;
  const classes = useStyles();
  const [sequenceClasses, setPracticeClasses] =
    useState<ChannelClassSequenceOption[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const classes = await channelClassSequenceProvider.getAll(channelId);
        setPracticeClasses(
          classes.map((b) => {
            b.channel_id = channelId;
            return b;
          })
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const [confirmationData, setConfirmationData] = useState<any>();
  const onRemoveClickHandler = async (data: any) => {
    if (sequenceClasses) {
      await channelClassSequenceProvider.remove(data);
      setPracticeClasses([...sequenceClasses.filter((i) => i.id !== data.id)]);
    }
  };

  const onRemove = async (id: string) => {
    setConfirmationData({
      id: id,
      channelId: channelId,
    });
  };

  const onPublish = async (id: string) => {
    if (sequenceClasses) {
      const practiceClasse = sequenceClasses.find((i) => i.id === id);
      if (practiceClasse) {
        const newPublishStatus = !practiceClasse.is_published;
        try {
          await channelClassSequenceProvider.setPublished(
            practiceClasse.id,
            newPublishStatus
          );
          const newPracticeClasses = sequenceClasses.map((c) => {
            if (c.id === id) {
              c.is_published = newPublishStatus;
            }
            return c;
          });
          setPracticeClasses(newPracticeClasses);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <ChannelClassSequenceToolbar channelId={channelId} />
      <div className={classes.content}>
        {loading && <CircularProgress />}
        <Grid container spacing={3}>
          {sequenceClasses &&
            sequenceClasses.map((classSequence) => (
              <Grid item key={classSequence.id} lg={4} md={6} xs={12}>
                <ChannelClassSequenceCard
                  classSequence={classSequence}
                  channelId={channelId}
                  onRemove={onRemove}
                  onPublish={onPublish}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <ConfirmationDialog
        handleConfirm={onRemoveClickHandler}
        dialogTitle={"Confirm class removal"}
        data={confirmationData}
      />
    </div>
  );
}
