import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  name: {
    color: "white",
    padding: 3,
    width: "fit-content",
    fontSize: 18,
    fontWeight: 600,
  },
  description: {
    color: "white",
    padding: 3,
    width: "fit-content",
    fontSize: 14,
  },
  channel: {
    height: 100,
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    background: "linear-gradient(0.35turn, #000000, #ff0000 ,#ffffff)", //"linear-gradient(0.35turn, #000000,#ffffff, #ff0000)",
    position: "relative"
  },
  channelContent: {
    padding: theme.spacing(2),
  },
}));

export default function ChannelHeader(props) {
  const classes = useStyles();
  const { channel, children } = props;

  return (
    <Paper className={classes.channel}>
      <Grid item md={6} className={classes.channelContent}>
        <Typography className={classes.name} gutterBottom>
          {channel.name}
        </Typography>
        <Typography className={classes.description}>
          {channel.description}
        </Typography>
      </Grid>
      {children}
    </Paper>
  );
}
