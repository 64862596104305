import React, { RefObject } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    zIndex: -10,
    position: "absolute",
    top: 5,
    left: 5,
    width: "100%",
    opacity: 0,
  },
}));

type Props = {
  videoRef: RefObject<HTMLVideoElement>;
  onEnded: () => void;
  onTimeUpdate: (currentTime: number) => void;
};

export default function InstructorVideoPractice(props: Props) {
  const classes = useStyles();
  const { videoRef, onEnded, onTimeUpdate } = props;

  return (
    <video
      className={classes.video}
      ref={videoRef}
      onEnded={onEnded}
      onTimeUpdate={() => onTimeUpdate(videoRef.current ? videoRef.current.currentTime: 0)}
      crossOrigin="anonymous"
      playsInline
    />
  );
}
