import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Avatar,
  ListItemText,
} from "@material-ui/core";

import BreadcrumbsContainer from "../../../../components/BreadcrumbsContainer";
import * as channelClassBlockProvider from "../../../../providers/instructor/channel-class-block.provider";
import * as channelClassPracticeProvider from "../../../../providers/instructor/channel-class-practice.provider";
import { ChannelClassBlockOption } from "../../../../providers/instructor/channel-class-block.provider";
import { ChannelClassPracticeBlockCreateListItem } from "../../../../providers/instructor/channel-class-practice.provider";
import update from "immutability-helper";
import { Card, DeletionCard } from "./components/Card";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { secondsToTimeRoundedString } from "../../../../utils/conversion.functions";
import InfoIcon from "@material-ui/icons/Info";
import validate from "validate.js";

const cardWidth = 800;
const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(cardWidth + theme.spacing(2) * 2)]: {
      width: cardWidth,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(cardWidth + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
  thumbnailFlipped: {
    transform: "rotateY(180deg)",
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  blockListItem: {
    display: "flex",
    alignItems: "center",
  },
  info: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px !important",
  },
  error: {
    color: "#e53935",
    marginLeft: 14,
    marginRight: 14,
    fontSize: 14,
    marginTop: 3,
    textAlign: "left",
    fontWeight: 400,
    lineheight: 13,
    letterSpacing: 0.33,
  },
}));

type Props = RouteComponentProps<any> & {
  breadcrumbs: any[];
  title: string;
};

type Params = {
  classId: string;
  channelId: string;
};

export default function CreateItemView(props: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [blocks, setBlocks] = useState<ChannelClassBlockOption[]>([]);
  const [block, setBlock] = useState<ChannelClassBlockOption>();
  const [description, setDescription] = useState<string>("");
  const [selectedBlocks, setSelectedBlocks] = useState<
    ChannelClassPracticeBlockCreateListItem[]
  >([]);
  const [practiceDuration, setPracticeDuration] = useState<number>(0);
  const [repetitions, setRepetitions] = useState<number>(1);
  const [poseDuration, setPoseDuration] = useState<number>(0);
  const history = useHistory();
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});
  const { classId, channelId } = useParams<Params>();

  useEffect(() => {
    const loadData = async (channelId: string) => {
      try {
        const results = await Promise.all([
          channelClassPracticeProvider.get(classId),
          channelClassBlockProvider.getAll(channelId),
        ]);
        const classPractice = results[0];
        setName(classPractice.name);
        setDescription(classPractice.description);
        setSelectedBlocks(classPractice.blocks);
        const blocks = results[1];
        setBlocks(blocks);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    loadData(channelId);
  }, []);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
    setSubmitted({
      ...submitted,
      name: false,
    });
  };

  const handlePracticeDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = parseInt(event.target.value);
    if (value < 1) {
      value = 1;
    }
    setPracticeDuration(value);

    if (practiceDuration > value) {
      setPoseDuration(value);
    }
  };

  const handleRepetitionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = parseInt(event.target.value);
    if (value < 1) {
      value = 1;
    }
    setRepetitions(value);
  };

  const handlePoseDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = parseInt(event.target.value);
    if (block && value > practiceDuration) {
      value = practiceDuration;
    }
    setPoseDuration(value);
  };

  const handleBlockChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    const block = blocks.find((x) => x.id === id);
    if (block) {
      setBlock(block);
      setPracticeDuration(Math.round(block.practice_duration_seconds));
    }
  };

  const handleAddSelectedBlock = () => {
    if (block && practiceDuration) {
      const selectedBlock: ChannelClassPracticeBlockCreateListItem = {
        id: new Date().getTime(),
        block_id: block.id,
        explanation_duration_seconds: block.explanation_duration_seconds,
        practice_duration_seconds: practiceDuration,
        pose_duration_seconds: block.pose_id ? poseDuration : undefined,
        exercise_repetitions: block.exercise_id ? repetitions : undefined,
        thumbnail_path: block.thumbnail_path,
        name: block.name,
      };
      setSelectedBlocks([...selectedBlocks, selectedBlock]);
      setRepetitions(1);
      setPoseDuration(0);
      setPracticeDuration(0);
      setSubmitted({ ...submitted, blocks: false });
      setBlock(undefined);
    }
  };

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      blocks: {
        presence: { allowEmpty: false, message: "are required" },
      },
    };

    const formData = {
      name: name,
      blocks: selectedBlocks.length > 0 ? true : undefined,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true,
        blocks: true,
      });
      return;
    }

    setLoading(true);
    const item = {
      class_id: classId,
      name: name,
      description: description,
      blocks: selectedBlocks.map((block, index) => {
        const mapped = {
          block_id: block.block_id,
          practice_duration_seconds: block.practice_duration_seconds,
          pose_duration_seconds: block.pose_duration_seconds,
          exercise_repetitions: block.exercise_repetitions,
          order: index,
        };
        return mapped;
      }),
    };
    try {
      await channelClassPracticeProvider.edit(item);
      history.goBack();
    } catch (err) {}
    setLoading(false);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value as string);
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragBlock = selectedBlocks[dragIndex];
      setSelectedBlocks(
        update(selectedBlocks, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragBlock],
          ],
        })
      );
    },
    [selectedBlocks]
  );

  const onRemove = (id: number) => {
    setSelectedBlocks((prev) => selectedBlocks.filter((x) => x.id !== id));
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const data = classId ? { classId: classId, channelId: channelId } : undefined;
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      {loading && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Name"
              value={name}
              disabled={loading}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Description"
              multiline
              rows="4"
              value={description}
              disabled={loading}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="block-select-label">
                Block
              </InputLabel>
              <Select
                labelId="block-select-label"
                value={block ? block.id : ""}
                onChange={handleBlockChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                {blocks.map(({ id, name, thumbnail_path }) => (
                  <MenuItem value={id} key={id}>
                    <Avatar
                      alt={name}
                      src={thumbnail_path}
                      style={{ margin: "0px 10px 0px 0px" }}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {block && (
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Practice video duration seconds"
                value={practiceDuration}
                onChange={handlePracticeDurationChange}
                type="number"
              />
            </Grid>
          )}
          {block && (
            <Grid item xs={12} className={classes.info}>
              <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
              <Typography>
                Practice duration seconds used to set the duration for which
                practice video will be played. If duration set to larger value
                than the practice video duration, video will be replayed again.
              </Typography>
            </Grid>
          )}
          {block && block.exercise_id && (
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Repetitions"
                value={repetitions}
                onChange={handleRepetitionChange}
                name="repetitions"
                type="number"
              />
            </Grid>
          )}
          {block && block.exercise_id && (
            <Grid item xs={12} className={classes.info}>
              <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
              <Typography>
                Repetitions are used for medal score calculation. If user does
                66%-100% of repetitions amount - receives gold medal, 33%-66% -
                silver and 5%-33% - bronze.
              </Typography>
            </Grid>
          )}
          {block && block.pose_id && (
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Required pose duration seconds"
                value={poseDuration}
                onChange={handlePoseDurationChange}
                name="repetitions"
                type="number"
              />
            </Grid>
          )}
          {block && block.pose_id && (
            <Grid item xs={12} className={classes.info}>
              <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
              <Typography>
                Pose duration is used for medal score calculation. If user stays
                in pose for 66%-100% of duration - receives gold medal, 33%-66%
                - silver and 5%-33% - bronze.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              disabled={loading}
              onClick={handleAddSelectedBlock}
              variant="contained"
            >
              Add Block
            </Button>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.error}>
              {hasError("blocks") ? errors["blocks"][0] : ""}
            </p>
          </Grid>
          <Grid item xs={12} className={classes.info}>
            <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
            <Typography>You can drag and drop blocks to rearrange.</Typography>
          </Grid>
          <DndProvider backend={HTML5Backend}>
            {selectedBlocks.length !== 0 && (
              <DeletionCard onRemove={onRemove} />
            )}
            {selectedBlocks.map((block, index) => (
              <Card
                id={block.id}
                key={block.id}
                index={index}
                block={block}
                moveCard={moveCard}
              />
            ))}
          </DndProvider>
          {selectedBlocks.length !== 0 && (
            <Grid item xs={12}>
              <Typography>
                Total Practice Duration:{" "}
                {secondsToTimeRoundedString(
                  selectedBlocks.reduce((acc, curr) => {
                    acc = acc + curr.practice_duration_seconds;
                    return acc;
                  }, 0)
                )}
              </Typography>
            </Grid>
          )}
          {selectedBlocks.length !== 0 && (
            <Grid item xs={12}>
              <Typography>
                Total Class Duration:{" "}
                {secondsToTimeRoundedString(
                  selectedBlocks.reduce((acc, curr) => {
                    acc =
                      acc +
                      curr.practice_duration_seconds +
                      curr.explanation_duration_seconds;
                    return acc;
                  }, 0)
                )}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Button
              disabled={loading}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={loading}
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}
