import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes as userRoutes } from "./user.routes";
import { routes as instructorRoutes } from "./instructor.routes";
import { routes as commonRoutes } from "./common.routes";
import { routes as adminRoutes } from "./admin.routes";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { RouteAccessRequirement } from "./route-access-requirement.enum";
import UnprotectedRoute from "../components/UnprotectedRoute";
import {
  Empty as EmptyLayout,
  Main as MainLayout,
  Minimal as MinimalLayout,
  AnalysisClass as AnalysisClassLayout,
  AnalysisClassPractice as AnalysisClassPracticeLayout,
  AnalysisClassSequence as AnalysisClassSequenceLayout,
} from "../layouts";
import { AppRouteLayout } from "./app-route-layout.enum";
import { ProtectedAdminRoute } from "../components";
import NotFound from "../views/common/NotFound";

const Layout = (layout) => {
  if (layout === AppRouteLayout.Main) {
    return MainLayout;
  }
  if (layout === AppRouteLayout.Minimal) {
    return MinimalLayout;
  }
  if (layout === AppRouteLayout.Class) {
    return AnalysisClassLayout;
  }
  if (layout === AppRouteLayout.ClassPractice) {
    return AnalysisClassPracticeLayout;
  }
  if (layout === AppRouteLayout.ClassSequence) {
    return AnalysisClassSequenceLayout;
  }

  return EmptyLayout;
};

const Routes = (props) => {
  const routes = commonRoutes
    .concat(userRoutes)
    .concat(instructorRoutes)
    .concat(adminRoutes);
  const defaultRoute = "/default";
  return (
    <Switch>
      <Redirect exact from="/" to={defaultRoute} />
      {routes.map(
        (
          {
            path,
            component: Component,
            layout,
            accessRequirement,
            title,
            breadcrumbs,
          },
          index
        ) =>
          accessRequirement === RouteAccessRequirement.None ? (
            <UnprotectedRoute
              key={index}
              exact
              path={path}
              component={Component}
              layout={Layout(layout)}
              title={title}
              breadcrumbs={breadcrumbs}
            />
          ) : accessRequirement === RouteAccessRequirement.Login ? (
            <ProtectedRoute
              key={index}
              exact
              path={path}
              component={Component}
              layout={Layout(layout)}
              title={title}
              breadcrumbs={breadcrumbs}
            />
          ) : (
            <ProtectedAdminRoute
              key={index}
              exact
              path={path}
              component={Component}
              layout={Layout(layout)}
              title={title}
              breadcrumbs={breadcrumbs}
            />
          )
      )}
      <Route
        render={(props) => (
          <MainLayout>
            <NotFound />
          </MainLayout>
        )}
      />
    </Switch>
  );
};

export default Routes;
