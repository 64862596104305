import React, { Fragment, useEffect, useRef, useState } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { secondsToShortTimeRoundedString } from "../../../../../utils/conversion.functions";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import CombinedSlider from "../CombinedSlider";

function Video(props: any) {
  const { currentTIme, filePath } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = currentTIme;
    }
  }, [currentTIme]);

  return <video crossOrigin="anonymous" ref={videoRef} style={{ width: "100%" }} src={filePath} />;
}

type Props = {
  filePath: string;
  checkpoints: any[];
  onRemoveClickHandler: (index: number) => void;
  onMoveStartTimeBack: (index: number) => void;
  onMoveStartTimeForward: (index: number) => void;
  onMoveEndTimeBack: (index: number) => void;
  onMoveEndTimeForward: (index: number) => void;
  duration?: number;
  step?: number;
};

export default function Checkpoints(props: Props) {
  const {
    checkpoints,
    onRemoveClickHandler,
    onMoveStartTimeBack,
    onMoveStartTimeForward,
    onMoveEndTimeBack,
    onMoveEndTimeForward,
    filePath,
    duration,
    step,
  } = props;

  const [confirmData, setConfirmData] = useState<any>(undefined);
  const onConfirmClickHandler = (index: number) => {
    setConfirmData({ index: index });
  };

  return (
    <Grid container alignItems="center" item xs={12}>
      <Grid item xs={1}>
        #
      </Grid>
      <Grid item xs={2}>
        Name
      </Grid>
      <Grid item xs={3}>
        Start Time
      </Grid>
      <Grid item xs={2}>
        Time
      </Grid>
      <Grid item xs={3}>
        End time
      </Grid>
      <Grid item xs={1}></Grid>
      {checkpoints.map((cp, index) => (
        <Fragment key={`cp-${index}`}>
          <Grid item xs={6}>
            {cp.startTime !== undefined && (
              <Video filePath={filePath} currentTIme={cp.startTime} />
            )}
          </Grid>
          <Grid item xs={6}>
            {cp.endTime !== undefined && (
              <Video filePath={filePath} currentTIme={cp.endTime} />
            )}
          </Grid>
          <Grid item xs={1}>
            {index + 1}
          </Grid>
          <Grid item xs={2}>
            {cp.name}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label={"Move back"}
              onClick={() => onMoveStartTimeBack(index)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography>
              {secondsToShortTimeRoundedString(cp.startTime)}
            </Typography>
            <IconButton
              aria-label={"Move forward"}
              onClick={() => onMoveStartTimeForward(index)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            {secondsToShortTimeRoundedString(cp.timestamp)}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label={"Move back"}
              onClick={() => onMoveEndTimeBack(index)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography>
              {secondsToShortTimeRoundedString(cp.endTime)}
            </Typography>
            <IconButton
              aria-label={"Move forward"}
              onClick={() => onMoveEndTimeForward(index)}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => onConfirmClickHandler(index)}>
              <HighlightOffIcon fontSize="inherit" />
            </IconButton>
          </Grid>

          {duration && step && (
            <Grid
              item
              xs={12}
              style={{ borderBottom: "3px solid lightgrey", padding: 5, marginBottom: 10 }}
            >
              <CombinedSlider
                duration={duration}
                step={step}
                checkpoints={checkpoints}
                activeIndex={index}
              />
            </Grid>
          )}
        </Fragment>
      ))}
      <ConfirmationDialog
        handleConfirm={onRemoveClickHandler}
        dialogTitle={"Confirm checkpoint removal"}
        data={confirmData}
      />
    </Grid>
  );
}
