import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
    backgroundSize: "contain",
  },
}));

const PoseOrSkillCard = (props) => {
  const { className, poseOrSkill, onRemove, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  const onSkeleton = () => {
    history.push(
      `/sports-instructor/channel/pose/${poseOrSkill.id}/skeleton`
    );
  };
  const onEdit = () => {
    history.push(
      `/sports-instructor/channel/pose/${poseOrSkill.id}/edit`
    );
  };
  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <CardActionArea>
        <CardMedia
          style={{
            backgroundSize: "contain",
          }}
          className={clsx(classes.media, className)}
          image={poseOrSkill.thumbnail_path}
          title="thumbnail"
        />
        <CardContent>
          <Typography color="textSecondary" variant="h4" component="h2">
            {poseOrSkill.name}
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {new Date(poseOrSkill.creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => onEdit(poseOrSkill.id)}
        >
          Edit
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onSkeleton(poseOrSkill.id)}
        >
          Skeleton
        </Button>
        <Button
          size="small"
          disabled={poseOrSkill.is_default}
          color="secondary"
          onClick={() => onRemove(poseOrSkill.id)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

PoseOrSkillCard.propTypes = {
  className: PropTypes.string,
  poseOrSkill: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PoseOrSkillCard;
