import React, { useEffect, useState } from "react";
import { VideoPlayer } from "./components";
import { RouteComponentProps, useParams } from "react-router-dom";
import { Theme, makeStyles } from "@material-ui/core/styles";
import * as channelVideoProvider from "../../../providers/admin/channel-video.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import { Typography, Divider } from "@material-ui/core";
import { VideoDetails } from "../../../providers/student/video.provider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  videoId: string;
};

export default function VideoDetailsView(props: Props) {
  const { videoId } = useParams<Params>();
  const [video, setVideo] = useState<VideoDetails>();
  const classes = useStyles();

  useEffect(() => {
    const load = async () => {
      const video = await channelVideoProvider.get(videoId);
      setVideo(video);
    };

    load();
  }, []);
  
  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
      />
      <Divider />
      {video && <VideoPlayer video={video} />}
    </div>
  );
}
