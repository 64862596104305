import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useProgressStyles = makeStyles((theme: Theme) => ({
  progress: {
    position: "relative",
    width: 20,
    height: "90%",
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    marginRight: 7,
    overflow: "hidden",
    backgroundColor: "#f5f5f5",
    borderRadius: 15,
    boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)",
  },
  progressBar: {
    borderRadius: 15,
    width: "100%",
    bottom: 0,
    position: "absolute",
    background: "linear-gradient(lightgreen, yellow)",
    boxShadow: "inset 0 -1px 0 rgba(0, 0, 0, 0.15)",
  },
}));

type ProgressProps = {
  progress: number;
};

const VerticalProgress = (props: ProgressProps) => {
  const classes = useProgressStyles();
  const { progress } = props;
  return (
    <div className={classes.progress}>
      <div
        style={{ height: `${progress}%` }}
        className={classes.progressBar}
      ></div>
    </div>
  );
};

type Props = {
  requiredReps: number;
  studentReps: number;
  repsAccuracy: any;
};

export default function AccuracyProgressBar(props: Props) {
  const { requiredReps, repsAccuracy } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        width: "100%",
        height: "100%",
      }}
    >
      {new Array(requiredReps).fill(undefined).map((value, index) => (
        <VerticalProgress
          key={index}
          progress={repsAccuracy[index + 1] === undefined ? 0 : repsAccuracy[index + 1]}
        />
      ))}
    </div>
  );
}
