import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import defaultThumbnail from "../../../../../assets/images/channel-thumbnail.png";
import clsx from "clsx";
import * as authService from "../../../../../services/auth.service";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { Avatar, Box, Menu, MenuItem } from "@material-ui/core";
import yogaInstructor from "../../../../../assets/images/yoga_instructor.jpg";
import { Rating } from "@material-ui/lab";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  card: {
    position: "relative",
    height: 200,
    width: "100%",
    borderRadius: 0,
    background: "black",
  },
  cover: {
    height: 200,
    width: "100%",
    objectFit: "contain",
  },
  badge: {
    width: "fit-content",
    height: "fit-content",
    background: "#FFFFFF",
    boxShadow: "0 4px 4px rgba(0,0,0,0.25)",
    borderRadius: "4px",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10,
  },
  sportType: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 1,
  },

  testBtn: {
    position: "absolute",
    left: 5,
    bottom: 5,
    zIndex: 1,
  },  
  title: {
    color: "black",
    marginTop: 5,
    marginLeft: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
    
  },
  description: {
    fontSize: 14,
    marginLeft: 10,
    
    whiteSpace: "nowrap",
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    color: "#797878",
  },
  instructorName: {
    
    fontSize: 14,
    marginLeft: 10,
    marginRight: 15,
  }, 
  name: {
    overflow: "hidden",
  },
  instructor: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  content: {
    padding: "8px",
  },
  moreOptions: {
    color: "#797878",
    marginTop: 5,
    cursor: "pointer",
  },
  publishIcon: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  publishedIcon: {
    color: "green",
  },
  unpublishedIcon: {
    color: "red",
  },
  button: {
    
    textTransform: "none",
  },
}));

const ChannelCard = (props) => {
  const { channel, history, ...rest } = props;
  const classes = useStyles();
  const image = channel.thumbnail_path || defaultThumbnail;

  const optionsAnchorEl = useRef(null);
  const [isOptionsAnchor, setIsOptionsAnchor] = useState(false);

  const onChannelClick = (id) => {
    history.push(`/sports-student/channel/${id}`);
  };

  const onAnalyzeLive = (id) => {
    history.push(`/sports-student/live-feedback/${id}`);
  };

  const onAnalyzeVideo = (id) => {
    history.push(`/sports-student/videos`, {
      channelId: id,
    });
  };

  const onTest = (id) => {
    history.push(`/sports-student/live-feedback-test/${id}`);
  };

  return (
    <Box {...rest}>
      <Card className={classes.card} onClick={() => onChannelClick(channel.id)}>
        <Typography
          className={clsx(classes.sportType, classes.badge)}
          variant="h5"
        >
          {channel.type}
        </Typography> 
        <CardActionArea>
          <img
            alt="channel thumbnail"
            className={classes.cover}
            src={image}
            title={channel.name}
          />
        </CardActionArea>
      </Card>
      <Box display="flex" style={{ marginTop: "10px" }}>
        <Avatar
          className={classes.instructorProfile}
          alt="instructor avatar"
          src={yogaInstructor}
        />
        <Box display="block" className={classes.container}>
          <Typography className={classes.title}>{channel.name}</Typography>
          <Typography className={classes.description} variant="h6">
            This basic indoor {channel.type} class is suitable for all levels
            beginner to pro. It’s easily accessible and can be done in 15
            minutes in the morning.
          </Typography>
          <Box display="block" style={{ marginTop: "5px" }}>
            <Typography className={classes.instructorName}>
              {channel.first_name} {channel.last_name}
            </Typography>
            {/* <Rating
              name="read-only"
              style={{ marginLeft: "10px" }}
              value={4.5}
              size="small"
              readOnly
            /> */}
          </Box>
        </Box>
        {/* <MoreVertIcon
          ref={optionsAnchorEl}
          className={classes.moreOptions}
          onClick={() => setIsOptionsAnchor(!isOptionsAnchor)}
        /> */}
        {/* <Menu
          id="simple-menu"
          anchorEl={optionsAnchorEl.current}
          keepMounted
          open={isOptionsAnchor}
          onClose={() => setIsOptionsAnchor(!isOptionsAnchor)}
        >
          <MenuItem key="channel test" onClick={() => onTest(channel.id)}>
            Test
          </MenuItem>
          <MenuItem
            key="channel live"
            onClick={() => onAnalyzeLive(channel.id)}
          >
            Live feedback
          </MenuItem>
          <MenuItem
            key="channel live"
            onClick={() => onAnalyzeVideo(channel.id)}
          >
            Analyze video
          </MenuItem>
        </Menu> */}
      </Box>
    </Box>
  );
};

ChannelCard.propTypes = {
  channel: PropTypes.object.isRequired,
};

export default withRouter(ChannelCard);
