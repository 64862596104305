import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import * as pictureProvider from "../../../providers/student/picture.provider";
import * as dataHelper from "../../../services/data.helper";

const styles = (theme: Theme) =>
  createStyles({
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
  });

type State = {
  disabled: boolean;
  image?: File;
  imageBase64Image?: string;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  title: string;
};

class PictureCreate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  onSave = async () => {
    this.setState({
      disabled: true,
    });
    const { image } = this.state;

    const model = {
      image: image,
    };
    try {
      await pictureProvider.create(model);
      this.props.history.goBack();
    } catch (ex) {
      //TODO: switch to toaster
      alert("Unable to process image, please select different one.");
      this.setState({
        disabled: false,
      });
    }
  };

  handleImageChange = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const base64Image = await dataHelper.getBase64Image(file);
      this.setState({
        image: file,
        imageBase64Image: base64Image,
      });
    }
  };

  render = () => {
    const { disabled, imageBase64Image } = this.state;

    const { classes, title, history } = this.props;

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" gutterBottom>
            {title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Dropzone onDrop={this.handleImageChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: disabled
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: "image/jpeg",
                          disabled: disabled,
                        })}
                      />
                      <p>Drag 'n' drop image here, or click to select one.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
            <Grid item xs={12}>
              {imageBase64Image && (
                <img  className={classes.thumbnail} src={imageBase64Image} />
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={disabled}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={disabled}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(PictureCreate);
export default withRouter(styledComponent as any);
