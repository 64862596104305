import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps, useParams } from "react-router-dom";
import { ChannelsToolbar } from "../ChannelList/components";
import * as studentActivityProvider from "../../../providers/student/activity-sequence.provider";
import {
  StudentActivityRecord,
  StudentSequenceActivity,
} from "../../../providers/student/activity-sequence.provider";
import { secondsToShortTimeRoundedString } from "../../../utils/conversion.functions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ChannelClassSequenceCheckpoint } from "../../../types/class-sequence/class-sequence-checkpoint";
import { ActivityChart, CheckpointChart } from "./components";
import { RewardType } from "../../../enums/reward-type.enum";
import { getRewardImage } from "../../../services/reward/reward.service";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  reward: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  activityId: string;
};

export default function ActivitiesList(props: Props) {
  const { title } = props;
  const classes = useStyles();
  const [activity, setActivity] = useState<StudentSequenceActivity>();
  const [selectedCheckpoint, setSelectedKeypoint] =
    useState<ChannelClassSequenceCheckpoint>();

  const [selectedCheckpointRecords, setSelectedCheckpointRecords] =
    useState<StudentActivityRecord[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { activityId } = useParams<Params>();

  useEffect(() => {
    const loadActivities = async () => {
      try {
        const activity = await studentActivityProvider.get(activityId);
        setActivity(activity);
        setLoading(false);
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };

    loadActivities();
  }, []);

  const onPreviewCheckpoint = (
    chekpoint: ChannelClassSequenceCheckpoint,
    index: number,
    checkpoints: ChannelClassSequenceCheckpoint[]
  ) => {
    setSelectedKeypoint(chekpoint);
    if (activity) {
      const isLastCheckpoint = index === checkpoints.length - 1;
      if (isLastCheckpoint) {
        const checkpoinRecords = activity.records.filter(
          (x) => x.current_time >= chekpoint.startTime
        );
        setSelectedCheckpointRecords(checkpoinRecords);
      } else {
        const checkpoinRecords = activity.records.filter(
          (x) =>
            x.current_time >= chekpoint.startTime &&
            x.current_time <= chekpoint.endTime
        );
        setSelectedCheckpointRecords(checkpoinRecords);
      }
    }
  };

  const onPreviewActivity = () => {
    setSelectedKeypoint(undefined);
    setSelectedCheckpointRecords(undefined);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h1" variant="h1" gutterBottom>
          {title}
        </Typography>
        <IconButton
          style={{
            color: !selectedCheckpoint ? "red" : "",
          }}
          onClick={() => onPreviewActivity()}
        >
          <VisibilityIcon fontSize="inherit" />
        </IconButton>
      </div>
      <ChannelsToolbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.content}>
            <Grid container spacing={3}>
              {activity && (
                <Grid container alignItems="center" item xs={12}>
                  <Grid item xs={2}>
                    #
                  </Grid>
                  <Grid item xs={2}>
                    Time
                  </Grid>
                  <Grid item xs={2}>
                    Start Time
                  </Grid>
                  <Grid item xs={2}>
                    End Time
                  </Grid>
                  <Grid item xs={2}>
                    Name
                  </Grid>
                  <Grid item xs={1}>
                    Reward
                  </Grid>
                  <Grid item xs={1}></Grid>
                  {activity.checkpoints.map((cp, index) => (
                    <Fragment>
                      <Grid item xs={2}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={2}>
                        {secondsToShortTimeRoundedString(cp.timestamp)}
                      </Grid>
                      <Grid item xs={2}>
                        {secondsToShortTimeRoundedString(cp.startTime)}
                      </Grid>
                      <Grid item xs={2}>
                        {secondsToShortTimeRoundedString(cp.endTime)}
                      </Grid>
                      <Grid item xs={2}>
                        {cp.name}
                      </Grid>
                      <Grid item xs={1}>
                        {activity.rewards && activity.rewards[index] && (
                          <img
                            title={RewardType[activity.rewards[index].medal]}
                            alt={RewardType[activity.rewards[index].medal]}
                            src={getRewardImage(activity.rewards[index].medal)}
                            className={classes.reward}
                          />
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          style={{
                            color:
                              selectedCheckpoint &&
                              selectedCheckpoint.timestamp === cp.timestamp
                                ? "red"
                                : "",
                          }}
                          onClick={() =>
                            onPreviewCheckpoint(cp, index, activity.checkpoints)
                          }
                        >
                          <VisibilityIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              )}
              {activity && selectedCheckpoint && selectedCheckpointRecords && (
                <Grid xs={12}>
                  <CheckpointChart
                    filePath={activity.file_path}
                    records={selectedCheckpointRecords}
                    checkpoint={selectedCheckpoint}
                  />
                </Grid>
              )}
              {activity &&
                !selectedCheckpoint &&
                !selectedCheckpointRecords && (
                  <ActivityChart
                    checkpoints={activity.checkpoints}
                    records={activity.records}
                    filePath={activity.file_path}
                    duration_seconds={activity.duration_seconds}
                    effectiveness={activity.effectiveness}
                  />
                )}
            </Grid>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
