import React, { useEffect, useRef, useState } from "react";

type Props = {
  feedback?: string;
};

export default function CornerCountdown(props: Props) {
  const { feedback } = props;

  const [localFeedback, setLocalFeedback] = useState<string | undefined>();

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (localFeedback === undefined && feedback !== undefined) {
      setLocalFeedback(feedback);
      timeoutRef.current = setTimeout(() => {
        setLocalFeedback(undefined);
      }, 5000);
    }
  }, [feedback]);

  return (
    <div
      style={{
        position: "fixed",
        maxWidth: "50%",
        display: localFeedback !== undefined ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        bottom: "25%",
        right: "5%",
        border: "5px solid rgba(139, 0, 0, 0.7)",
        borderRadius: 20,
        backgroundColor: "rgba(27, 27, 27, 0.7)",
        fontSize: 80,
        color: "white",
        height: "15%",
        padding: 20,
      }}
    >
      <span>Adjust {localFeedback}</span>
    </div>
  );
}
