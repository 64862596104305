import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, LinearProgress, Modal } from "@material-ui/core";
import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";
import * as channelClassProvider from "../../../providers/student/channel-class-sequence.provider";
import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";
import {
  checkIfLandmarkssWithinImage,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Landmark } from "@mediapipe/pose";
import { ChannelClassSequenceCheckpoint } from "../../../types/class-sequence/class-sequence-checkpoint";
import { LiveClassSequenceModel } from "../../../types/analyze/live-class-sequence.type";
import { getReward } from "../../../services/reward/reward.service";
import { AccuracyChart } from "./components";
import * as activityProvider from "../../../providers/student/activity-sequence.provider";
import { mapJointsAccuracy, round } from "../../../utils/conversion.functions";
import AccuracySoundIndicatorService from "../../../services/accuracy-sound-indicator.service";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import AccuracyProgressBar from "./components/AccuracyProgressBar";
import { AnalysisOverlaySequence } from "../../../components/Analysis";
import logo from "../../../assets/images/logo_only2.png";
import LiveModelCombinedSequence2D from "../../../components/Analysis/LiveModelCombined/LiveModelCombinedSequence2D";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import ClassSummary from "./components/ClassSummary";

const selfieMode = true;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: window.innerHeight,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: window.innerHeight,
    width: "100%",
  },
  blockContainer: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  studentCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s ease-in",
  },
  fullScreenButton: {
    position: "absolute",
    top: "60%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  startClassButton: {
    position: "absolute",
    top: "70%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  exitClassButton: {
    position: "absolute",
    top: "80%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid red",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  bottomContainer: {
    height: "18%",
    width: "100%",
    position: "fixed",
    bottom: 0,
    "&::before": {
      position: "fixed",
      bottom: 0,
      content: '""',
      height: "35vh",
      width: "100%",
      backgroundImage: "linear-gradient(180deg, transparent 25%, #1b1b1b 50%)",
      zIndex: -1,
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
}));

type Params = {
  classId?: string;
};

export default function AnalyzeClassSequenceTest() {
  const soundIndicatorRef = useRef<AccuracySoundIndicatorService>();
  const classes = useStyles();
  const history = useHistory();

  const accuracyRef = useRef<any>({});
  const effectivenesRef = useRef<any>({});
  const [accuracyTimestamp, setAccuracyTimestamp] = useState<number>();
  const [accuracy, setAccuracy] = useState<number>(0);

  const [live, setLive] = useState<LiveClassSequenceModel>();
  const { classId } = useParams<Params>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [checkpoints, setCheckpoints] =
    useState<ChannelClassSequenceCheckpoint[]>();
  const [currentCheckpoint, setCurrentCheckpoint] = useState<
    ChannelClassSequenceCheckpoint | undefined
  >();
  const rewardsRef = useRef<any>({});

  const [isClassEnded, setIsClassEnded] = useState<boolean>(false);
  const [studentActivityId, setStudentActivityId] = useState<string>();
  const [classData, setClassData] = useState<any>();
  const [feedback, setFeedback] = useState<string>();
  const instructorVideoRef = useRef<HTMLVideoElement>(null);

  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [lastInstructorVideoPaused, setLastInstructorVideoPaused] = useState<
    boolean | undefined
  >(false);
  const [canStartClass, setCanStartClass] = useState<boolean>(false);
  const [isClassStarted, setIsClassStarted] = useState<boolean>(false);
  //TODO: merge with accurRef!
  const [accuracyRecords, setAccuracyRecords] = useState<
    {
      accuracy: number;
      timestamp: number;
      checkpoint: ChannelClassSequenceCheckpoint;
    }[]
  >([]);

  const [rewards, setRewards] = useState<any[]>([]);

  const [stream, setStream] = useState<MediaStream>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [streamErrorMessage, setStreamErrorMessage] = useState<string>();

  const onPoseAccuracy = (
    accuracy: number,
    live: LiveClassSequenceModel,
    checkpoint: ChannelClassSequenceCheckpoint,
    mergedAccuracyData: any
  ) => {
    if (soundIndicatorRef.current) {
      soundIndicatorRef.current.onAccuracy(accuracy);
    }
    setFeedback(avatarFeedback.getFeedback(accuracy, mergedAccuracyData));

    const accuracyTimestamp =
      Math.round((live.currentTime - checkpoint.startTime) * 10) / 10;
    setAccuracyTimestamp(accuracyTimestamp);

    setAccuracy(accuracy);
    accuracyRef.current[`${live.currentTime}`] = accuracy;

    setAccuracyRecords([
      ...accuracyRecords,
      { accuracy: accuracy, timestamp: live.timestamp, checkpoint: checkpoint },
    ]);
    //TODO: send activity data

    if (studentActivityId) {
      const activityData = {
        activity_id: studentActivityId,
        accuracy: Math.round((accuracy + Number.EPSILON) * 10) / 10,
        timestamp: live.timestamp,
        current_time: live.currentTime,
        keypoints: live.studentKeypoints.map((kp) => {
          return {
            x: round(kp.x),
            y: round(kp.y),
            z: round(kp.z),
            part: kp.part,
          };
        }),
        joints_accuracy: mapJointsAccuracy(mergedAccuracyData),
      };

      activityProvider.createRecords(activityData);
    }
  };

  useEffect(() => {
    const initVideoStream = async () => {
      const video = {
        width: 640,
        height: 480,
        //TODO: try to limit processing by fps
      };
      const constraints = {
        audio: false,
        video: video,
      };

      const getMedia = async (constraints: MediaStreamConstraints) => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          return stream;
        } catch (err) {
          const getErrorMessage = (errorName: string) => {
            if (errorName === "NotAllowedError") {
              return "In order to proceed - give permission to access camera";
            }

            if (errorName === "NotFoundError") {
              return "Camera device not found";
            }
            return "An error occurred while accessing camera";
          };

          const errorMessage = getErrorMessage(err.name);
          setStreamErrorMessage(errorMessage);
          handleOpen();
          //If the user denies permission, or matching media is not available, then the promise is rejected with NotAllowedError or NotFoundError respectively
        }
      };

      const stream = await getMedia(constraints);
      if (stream) {
        setStream(stream);
      }
    };

    initVideoStream();
  }, []);


  useEffect(() => {
    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();
      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const initVideoElement = (stream: MediaStream) => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.crossOrigin = "anonymous";
      const onCanPlayHandler = async () => {
        videoElement.play();
        processFrame();
        videoElement.removeEventListener("canplay", onCanPlayHandler);
      };

      videoElement.addEventListener("canplay", onCanPlayHandler);
      videoElement.srcObject = stream;
      studentVideoElementRef.current = videoElement;
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00035; //0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const {
        checkpoints,
        name,
        file_path,
        activity_id,
        instructor,
        duration,
        type,
        avatar,
        thumbnail_path,
      } = await channelClassProvider.getForAnalysis(classId);
      setCheckpoints(checkpoints);
      setClassData({
        name: name,
        file_path: file_path,
        instructor,
        duration,
        type,
        avatar,
        thumbnail_path,
      });
      setStudentActivityId(activity_id);
    };

    const initializeModel = async (classId: string, stream: MediaStream) => {
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      initVideoElement(stream);
      setCanStartClass(true);
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const flipCanvas = (canvas: HTMLCanvasElement | null) => {
          if (canvas) {
            const context = canvas.getContext("2d");
            if (context) {
              context.translate(canvas.width, 0);
              context.scale(-1, 1);
            }
          }
        };
        const canvas = canvasRef.current;
        flipCanvas(canvas);
      }
    };

    if (classId && stream) {
      initializeModel(classId, stream);
    }

    return () => {
      if (soundIndicatorRef.current) {
        soundIndicatorRef.current.stop();
      }

      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;

    if (!isClassEnded && videoElement) {
      const model = mediapipeModelRef.current;
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        setLastCurrentTime(instructorVideoElement.currentTime);

        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        const instructorVideo = instructorVideoRef.current;
        if (instructorVideo) {
          setLastInstructorVideoPaused(instructorVideo.paused);
        } else {
          setLastInstructorVideoPaused(undefined);
        }
        await model.send({ image: videoElement });
      }
    }
  };

  useEffect(() => {
    const onResults = async (results: any) => {
      const { poseLandmarks, image } = results;

      if (poseLandmarks) {
        const processLandmarks = (poseLandmarks: Landmark[]) => {
          const filterKalmanUnity = (
            measurements: Measurement3D[],
            joints: Landmark[],
            customKalmanFilter: CustomKalmanFilter
          ) => {
            const filteredUnity = applyKalmanFilterUnity(
              measurements,
              joints,
              customKalmanFilter
            );
            if (filteredUnity) {
              setMeasurements(filteredUnity);
              const mappedUnity = filteredUnity.map((f, index: number) => {
                return {
                  x: f.pos3d.x,
                  y: f.pos3d.y,
                  z: f.pos3d.z,
                  visibility: joints[index].visibility,
                };
              });
              return mappedUnity;
            }
          };

          const canvasElement = canvasRef.current;
          if (!canvasElement) {
            return;
          }

          const getFilter = () => {
            //return customKalmanFilterFastRef.current;
            return customKalmanFilterSlowRef.current;
          };

          const filter = getFilter();
          if (!filter) {
            return;
          }

          const filtered = filterKalmanUnity(
            measurements,
            poseLandmarks,
            filter
          );
          if (!filtered) {
            return;
          }

          const { width, height } = canvasElement;
          const denormalized = mediapipeKeypoint.denormalize(
            filtered,
            width,
            height
          );
          const studentKeypointsScaledByZ = mapForAnalysis(denormalized);
          drawOriginal(filtered, image, canvasElement);
          const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
            denormalized,
            width,
            height
          );

          if (lastCurrentTime !== undefined && lastTimestamp) {
            const live: LiveClassSequenceModel = {
              studentKeypoints: studentKeypointsScaledByZ,
              currentTime: lastCurrentTime,
              timestamp: lastTimestamp,
              isLandmarksWithinImage: isLandmarksWithinImage,
              isInstructorVideoPaused: lastInstructorVideoPaused,
            };

            setLive(live);
          } else {
            setLive(undefined);
            const canvasElement = canvasRef.current;
            drawOriginal([], image, canvasElement);
          }
        };
        processLandmarks(poseLandmarks);
      } else {
        setLive(undefined);
        const canvasElement = canvasRef.current;
        drawOriginal([], image, canvasElement);
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results) {
      onResults(results);
    }
  }, [results]);

  const [effectiveness, setEffectiveness] = useState<number>(0);
  const onInstructorVideoEnded = () => {
    //TODO: calculate effectivness and send to server
    const effectiveness = calculateClassEffectiveness();
    setEffectiveness(effectiveness);
    try {
      activityProvider.saveEffectiveness({
        effectiveness: effectiveness,
        activity_id: studentActivityId,
      });
    } catch (ex) {}
    setIsClassEnded(true);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const onTogglePlay = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      if (instructorVideoElement.paused) {
        await instructorVideoElement.play();
      } else {
        instructorVideoElement.pause();
      }
    }
  };

  const onStartClass = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.src = classData.file_path;
      instructorVideoElement.muted = true;
      await instructorVideoElement.play();
      instructorVideoElement.pause();
      instructorVideoElement.muted = false;
    }

    soundIndicatorRef.current = new AccuracySoundIndicatorService();
    setIsClassStarted(true);
  };

  const onTimeUpdate = () => {
    const videoElement = instructorVideoRef.current;
    if (videoElement) {
      const { currentTime } = videoElement;
      if (!checkpoints || checkpoints.length === 0) {
        return;
      }
      let activeCheckpoint = undefined;
      for (let index = 0; index < checkpoints.length; index++) {
        const checkpoint = checkpoints[index];

        if (
          checkpoint.startTime <= currentTime &&
          currentTime <= checkpoint.endTime
        ) {
          activeCheckpoint = checkpoint;
        }
      }

      setCurrentCheckpoint(activeCheckpoint);
      if (
        !currentCheckpoint ||
        !activeCheckpoint ||
        (activeCheckpoint &&
          currentCheckpoint &&
          activeCheckpoint.timestamp !== currentCheckpoint.timestamp)
      ) {
        setAccuracyTimestamp(0);
        setAccuracy(0);
      }
    }
  };

  useEffect(() => {
    const videoElement = instructorVideoRef.current;

    if (videoElement && checkpoints) {
      const sorted = checkpoints
        .filter((x) => x.endTime <= videoElement.currentTime)
        .sort((a, b) => a.startTime - b.startTime)
        .reverse();
      if (currentCheckpoint) {
        if (sorted.length > 0) {
          const prevCheckpoint = sorted[0];
          if (prevCheckpoint) {
            const calculateEffectiveness = (accuracy: any) => {
              const keys = Object.keys(accuracy);
              if (keys.length === 0) {
                return 0;
              }
              const sum = keys.reduce((acc, curr) => {
                return acc + accuracy[curr];
              }, 0);
              return Math.round(sum / keys.length);
            };
            const effectiveness = calculateEffectiveness(accuracyRef.current);
            effectivenesRef.current[prevCheckpoint.timestamp] = effectiveness;
          }
        }
      } else {
        if (sorted.length > 0) {
          const prevCheckpoint = sorted[0];
          if (prevCheckpoint) {
            const calculateEffectiveness = (accuracy: any) => {
              const keys = Object.keys(accuracy);
              if (keys.length === 0) {
                return 0;
              }
              const sum = keys.reduce((acc, curr) => {
                return acc + accuracy[curr];
              }, 0);
              return Math.round(sum / keys.length);
            };
            const effectiveness = calculateEffectiveness(accuracyRef.current);
            effectivenesRef.current[prevCheckpoint.timestamp] = effectiveness;
          }
          const prevIndex = checkpoints.findIndex(
            (x) => x.timestamp === prevCheckpoint.timestamp
          );

          const checkpointAccuracyRecords = accuracyRecords.filter(
            (x) => x.checkpoint.timestamp === prevCheckpoint.timestamp
          );
          const groupMaxAccuray = checkpointAccuracyRecords.reduce(
            (acc: number, curr: any) => {
              if (curr.accuracy > acc) {
                return curr.accuracy;
              }
              return acc;
            },
            0
          );

          const medal = getReward(groupMaxAccuray);
          setRewards([
            ...rewards,
            {
              name: prevCheckpoint.name,
              timestamp: prevCheckpoint.timestamp,
              reward: medal,
            },
          ]);
          if (!rewardsRef.current[prevCheckpoint.timestamp]) {
            const rewardProgressPercentage = groupMaxAccuray > 100 ? 100 : groupMaxAccuray;
            rewardsRef.current[prevCheckpoint.timestamp] = {
              rewardProgressPercentage:rewardProgressPercentage
                
            };
            if (studentActivityId) {
              activityProvider.saveReward({
                reward: medal,
                checkpoint_index: prevIndex,
                activity_id: studentActivityId,
                score: rewardProgressPercentage
              });
            }
          }
        }
      }
    }
  }, [currentCheckpoint]);

  const onReturnClickHandler = () => {
    history.push("/");
  };

  useEffect(() => {
    if (isClassEnded) {
      if (soundIndicatorRef.current) {
        soundIndicatorRef.current.stop();
      }
    }
  }, [isClassEnded]);

  const [isInFrameCheckPassed, setIsInFrameCheckPassed] = useState<boolean>(false);
  const onStartVideo = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && instructorVideoElement.paused) {
      await instructorVideoElement.play();      
    }
    setIsInFrameCheckPassed(true);
  };

  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };

  // const onToggleSignalingSound = () => {
  //   const signalingService = soundIndicatorRef.current;
  //   if (signalingService) {
  //     signalingService.toggle();
  //   }
  // };

  const calculateClassEffectiveness = () => {
    const keys = Object.keys(effectivenesRef.current);
    if (keys.length === 0) {
      return 0;
    }
    const sum = keys.reduce((acc, curr) => {
      const effectiveness = effectivenesRef.current[curr];
      return acc + effectiveness;
    }, 0);
    return sum / keys.length;
  };

  const pauseTimeoutRef = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(()=> {

    const onChange = (live: any) => {
      const videoElement = instructorVideoRef.current;
      if(isInFrameCheckPassed && videoElement){
        if(!pauseTimeoutRef.current && !live){
            pauseTimeoutRef.current = setTimeout(() => {
              videoElement.pause();
              pauseTimeoutRef.current = undefined;
            }, 7000);
        }
        if(pauseTimeoutRef.current && live){
            clearTimeout(pauseTimeoutRef.current);
            pauseTimeoutRef.current = undefined;
        }
      }
    }
    onChange(live);
  }, [live])

  return (
    <div className={classes.root}>
       <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2>Error</h2>
          <p>{streamErrorMessage}</p>
        </div>
      </Modal>
      {!isProcessingStarted && (
        <LinearProgress
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: 7,
            zIndex: 10,
          }}
        />
      )}

      {!isClassEnded && (
        <Grid
          className={classes.container}
          style={{
            visibility: !isProcessingStarted ? "hidden" : "visible",
            width: "100%",
          }}
          container
        >
          <div
            style={{
              position: "absolute",
              top: window.innerHeight / 4,
              zIndex: 1,
              height: window.innerHeight / 3,
              transition: "opacity 0.6s",
              opacity: isInFrameCheckPassed && currentCheckpoint ? 1 : 0,
            }}
          >
            <AccuracyProgressBar accuracy={accuracy} />
          </div>
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isInFrameCheckPassed ? "height 0.6s" : "",
              height: isInFrameCheckPassed ? "83vh" : "100vh",
              borderRight: "4px solid grey",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <canvas
                ref={canvasRef}
                width={640}
                height={480}
                style={{ height: "100%" }}
              />
            </div>
          </Grid>
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isInFrameCheckPassed ? "height 0.6s" : "",
              height: isInFrameCheckPassed ? "83vh" : "100vh",
              opacity: isClassStarted ? 1: 0,
              borderLeft: "4px solid grey",
            }}
          >
            <video
              style={{
                height: "100%",
                transform: "translateX(-50%)",
                marginLeft: "50%",
              }}
              crossOrigin="anonymous"
              onEnded={onInstructorVideoEnded}
              onTimeUpdate={onTimeUpdate}
              ref={instructorVideoRef}
            />
          </Grid>
          <Grid
            xs={12}
            item
            container
            className={classes.bottomContainer}
            style={{
              transition: "opacity 0.6s",
              opacity: isInFrameCheckPassed ? 1 : 0,
            }}
          >
            {currentCheckpoint && (
              <AccuracyChart
                currentCheckpoint={currentCheckpoint}
                duration={
                  currentCheckpoint.endTime - currentCheckpoint.startTime
                }
                accuracy={
                  accuracyTimestamp !== undefined
                    ? {
                        value: accuracy,
                        seconds: accuracyTimestamp,
                      }
                    : undefined
                }
              />
            )}
          </Grid>
          {studentVideoElementRef.current &&
            canvasRef.current &&
            instructorVideoRef.current &&
            isClassStarted &&
            checkpoints && (
              <LiveModelCombinedSequence2D
                live={live}
                selfieMode={selfieMode}
                checkpoint={currentCheckpoint}
                onPoseAccuracy={onPoseAccuracy}
              />
            )}

          <Grid className={classes.studentCanvas}>
            {instructorVideoRef.current && (
              <AnalysisOverlaySequence
                isLandmarksWithinImage={!!(live && live.isLandmarksWithinImage)}
                isClassStarted={isClassStarted}
                onCanStartPlayback={onStartVideo}
                isInstructorVideoPlaying={!instructorVideoRef.current.paused}
                onExit={onReturnClickHandler}
                isFullScreenEnabled={!!document.fullscreenElement}
                toggleFullScreen={toggleFullScreen}
                isInstructorVideoMuted={instructorVideoRef.current.muted}
                toggleMute={onToggleInstructorVideoSound}
                togglePlay={onTogglePlay}
                feedback={feedback}
              />
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.fullScreenButton}
                onClick={toggleFullScreen}
                variant="contained"
                size="large"
                color="default"
                startIcon={
                  !document.fullscreenEnabled ? (
                    <FullscreenIcon />
                  ) : (
                    <FullscreenExitIcon />
                  )
                }
              >
                Fullscreen
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButton}
                onClick={onStartClass}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start Class
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                onClick={onReturnClickHandler}
                className={classes.exitClassButton}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<ExitToAppIcon />}
              >
                Exit
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {isClassEnded && classData && (
        <ClassSummary
          instructorAvatar={classData.avatar}
          instructorName={classData.instructor}
          effectiveness={effectiveness}
          classThumbnail={classData.thumbnail_path}
          channelType={classData.type}
          rewards={rewardsRef.current}
          timespansCount={checkpoints ? checkpoints.length : 0}
          classDuration={classData.duration}
          onReturnClickHandler={onReturnClickHandler}
        />
      )}
    </div>
  );
}
