import * as THREE from "three";

export const wallHeight = 2.5;
export const halfWidth = 2.28;

export const getTextMesh = (text: string, font: THREE.Font, halfWidth: number, wallHeight: number, elementName: string) => {
  const getTextGeometry = (shapes: THREE.Shape[]): THREE.ShapeGeometry => {
    const geometry = new THREE.ShapeGeometry(shapes);
    geometry.computeBoundingBox();
    return geometry;
  };

  const shapes = font.generateShapes(text, 0.27);
  const geometry = getTextGeometry(shapes);

  const matDark = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
  });

  const mesh = new THREE.Mesh(geometry, matDark);
  mesh.name = elementName;

  if (geometry.boundingBox) {
    const { max, min } = geometry.boundingBox;
    const center = new THREE.Vector3();
    geometry.boundingBox.getCenter(center);
    const shiftX = 0 - center.x;
    const shiftY = wallHeight / 2 - center.y;
    mesh.position.set(-halfWidth + 0.1, shiftY, -shiftX);
    const width = max.x - min.x;
    const height = max.y - min.y;

    const wallWidth = 2 * halfWidth;
    if (height > wallHeight || width > wallWidth) {
      const heightPercentage = height / (wallWidth / 100);
      const widthPercentage = width / (wallHeight / 100);
      if (heightPercentage > widthPercentage) {
      }
    }
  }

  mesh.rotation.y = Math.PI / 2;

  return mesh;
};
