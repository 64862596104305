import ee from "event-emitter";

class EventEmmiter {}

ee(EventEmmiter.prototype);
const audioCtx = new AudioContext();

enum SoundIndicatorState {
  None = 0,
  Started = 1,
  Stopped = 2,
}

export default class AccuracySoundIndicatorService {
  private eventEmitter: any;
  private oscillator: OscillatorNode;

  private duration: number = 1000;
  private delay: number = 1000;

  private frequency: number = 400;
  private gainNode: GainNode;

  public isMuted: boolean = true;

  private soundIndicatorState: SoundIndicatorState = SoundIndicatorState.None;

  constructor() {
    // console.log("class create");
    this.eventEmitter = new EventEmmiter();
    this.oscillator = audioCtx.createOscillator();
    this.oscillator.type = "sine"; 
    this.gainNode = audioCtx.createGain();
    this.gainNode.gain.value = 0;//0.01;
    this.isMuted = this.gainNode.gain.value === 0;

    this.oscillator.connect(this.gainNode);

    this.eventEmitter.on("delayEnded", async () => {
      if (this.soundIndicatorState === SoundIndicatorState.Started) {
        this.gainNode.connect(audioCtx.destination);
        this.oscillator.frequency.value = this.frequency;
        await this.waitTimeout(this.duration);
        this.gainNode.disconnect(audioCtx.destination);
        await this.waitTimeout(this.delay);
        this.eventEmitter.emit("delayEnded");
      }
    });
  }

  private waitTimeout = (timeout: number) => {
    const localTimeout = timeout;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(undefined);
      }, localTimeout);
    });
  };

  public onAccuracy = async (accuracy: number) => {
    this.frequency = (1300 / 100) * accuracy;
    const totalDuration = 1100 - (1000 / 100) * accuracy;

    const beepDurationPercentage = 50;
    this.duration = (totalDuration / 100) * beepDurationPercentage;
    this.delay = totalDuration - this.duration;

    if (this.soundIndicatorState === SoundIndicatorState.None) {
      this.oscillator.start();
      this.gainNode.connect(audioCtx.destination);
      this.soundIndicatorState = SoundIndicatorState.Started;
      this.eventEmitter.emit("delayEnded");
    }
  };

  stop = () => {
    this.soundIndicatorState = SoundIndicatorState.Stopped;
  };

  toggle = () => {
    this.gainNode.gain.value = this.gainNode.gain.value === 0 ? 0.01: 0;
    this.isMuted = this.gainNode.gain.value === 0;
  }
}
