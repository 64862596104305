import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import * as channelClassProvider from "../../../../../providers/admin/channel-class.provider";
import { ChannelClassCard } from "./components";
import { SearchInput } from "../../../../../components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const pageSize = 8;

export default function ClassSequenceList() {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<
    Array<channelClassProvider.ChannelClassOption>
  >([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = useState<{ [key: string]: string | undefined }>(
    {}
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    const getClasses = async (
      page: number,
      filter: { [key: string]: string | undefined }
    ) => {
      setLoading(true);
      try {
        const pager = {
          pageSize: pageSize,
          pageNumber: page,
        };

        const { records, total } = await channelClassProvider.getAllSearch(
          pager,
          filter
        );
        setCourses(records);
        setTotalCount(total);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses(page, filter);
  }, [page, filter]);

  const onSearchChange = (search: string) => {
    setFilter({ ...filter, name: search });
  };

  const onUnpublish = async (id: string) => {
    if (courses) {
      const index = courses.findIndex((c) => c.id === id);
      if (index > -1) {     
        const isPublished = !courses[index].is_published;
        await channelClassProvider.toggleUnpublish(id, isPublished);
        courses[index].is_published = isPublished;
        setCourses([...courses]);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography component="h1" variant="h4" gutterBottom>
          Classes
        </Typography>
        {<SearchInput onChange={onSearchChange} />}
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid style={{ marginTop: "30px" }} container spacing={3}>
            {courses &&
              courses.map((course) => (
                <Grid item key={course.id} lg={3} md={6} xs={12}>
                  <ChannelClassCard course={course} onUnpublish={onUnpublish}/>
                </Grid>
              ))}
          </Grid>
        )}
        {totalCount !== undefined && totalCount !== 0 && (
          <Pagination
            disabled={loading}
            count={Math.ceil(totalCount / pageSize)}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
