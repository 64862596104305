import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Slider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import Pose3dEditor from "../../../../../components/editor/pose3d-editor";

import { Keypoint3D } from "../../../../../types/analyze/keypoint3d.type";
import { mediapipeKeypoint } from "../../../../../services/mediapipe";
import { AvatarType } from "../../../../../services/avatar/avatar-type.enum";
import { secondsToTimeString } from "../../../../../utils/conversion.functions";
import { drawVideoOnCanvas } from "../../../../../services/canvas.service";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { drawOriginal } from "../../../../../services/mediapipe/mediapipe.keypoint";
import { ChannelVideoItem } from "../../../../../providers/instructor/channel-video.provider";
import HumanRegions from "../../../../../components/HumanRegions";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  thumbnail: {
    maxWidth: "100%",
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  video: {
    height: "100%",
    width: "100%",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
  timespanSlectionControls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  timeLabel: {
    fontSize: 17,
    fontWeight: 700,
  },
  flippedButton: {
    transform: "rotateY(180deg)",
  },
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "#e53935",
    marginLeft: 14,
    marginRight: 14,
    fontSize: 11,
    marginTop: 3,
    textAlign: "left",
    
    fontWeight: 400,
    lineheight: 13,
    letterSpacing: 0.33,
  },
  info: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px !important",
  },
}));

type Props = {
  landmarks: any;
  image?: HTMLImageElement;
  onUpdate: (canvas: HTMLCanvasElement, stepIndex: number) => void;
  keypoints?: Keypoint3D[];
  isUiDisabled: boolean;
  stepIndex: number;
  videos: ChannelVideoItem[];
  onToggleRegion: (key: string, stepIndex: number) => void;
  regions: any;
  videoError?: string;
  avatarDataError?: string;
  onVideoIdChange: (id: string, stepIndex: number) => void;
  selectedChannelVideo?: ChannelVideoItem;
};

const dimension = 400;

export default function PoseCreate(props: Props) {
  const {
    onUpdate,
    image,
    landmarks,
    keypoints,
    isUiDisabled,
    stepIndex,
    videos,
    onToggleRegion,
    regions,
    videoError,
    avatarDataError,
    onVideoIdChange,
    selectedChannelVideo,
  } = props;

  const classes = useStyles();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState<number>();
  const [step, setStep] = useState<number>();
  const [sliderValue, setSliderValue] = useState<number>(0);

  const onLoadedMetadata = () => {
    const video = videoRef.current;
    if (video) {
      const duration = video.duration;
      setDuration(duration);
      const step = duration / 500;
      setStep(step);
    }
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const sliderValue = newValue as number;
    setSliderValue(sliderValue);
    const video = videoRef.current;
    if (video) {
      video.currentTime = sliderValue;
    }
  };

  const onPreview = async () => {
    const video = videoRef.current;
    if (video) {
      const canvas = drawVideoOnCanvas(video);
      onUpdate(canvas, stepIndex);
    }
  };

  useEffect(() => {
    const processLandmarks = (image: HTMLImageElement, landmarks: any) => {
      const imageWidth = image.width;
      const imageHeight = image.height;
      if (landmarks && imageWidth && imageHeight) {
        const downscale = (height: number, width: number) => {
          const getScaleMultiplier = (
            height: number,
            width: number
          ): number => {
            const maxValue = dimension;
            if (height >= width) {
              return maxValue / height;
            }
            return maxValue / width;
          };

          const scaleMultiplier = getScaleMultiplier(height, width);
          return {
            scaledWidth: width * scaleMultiplier,
            scaledHeight: height * scaleMultiplier,
          };
        };

        const canvasElement = canvasRef.current;
        if (canvasElement) {
          const { scaledHeight, scaledWidth } = downscale(
            imageHeight,
            imageWidth
          );
          canvasElement.width = scaledWidth;
          canvasElement.height = scaledHeight;
          drawOriginal(landmarks, image, canvasElement);
        }
      }
    };

    if (image && landmarks) {
      processLandmarks(image, landmarks);
    }
  }, [landmarks]);

  const onMoveBackFast = () => {
    let currentTime = sliderValue - 0.4;
    if (currentTime < 0) {
      currentTime = 0;
    }
    setSliderValue(currentTime);
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveBack = () => {
    let currentTime = sliderValue - 0.02;
    if (currentTime < 0) {
      currentTime = 0;
    }
    setSliderValue(currentTime);
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveForward = () => {
    let currentTime = sliderValue + 0.02;
    if (videoRef && videoRef.current) {
      if (currentTime > videoRef.current.duration) {
        currentTime = videoRef.current.duration;
      }
      setSliderValue(currentTime);

      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveForwardFast = () => {
    let currentTime = sliderValue + 0.4;
    if (videoRef && videoRef.current) {
      if (currentTime > videoRef.current.duration) {
        currentTime = videoRef.current.duration;
      }
      setSliderValue(currentTime);

      videoRef.current.currentTime = currentTime;
    }
  };

  const handleChannelVideoChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedVideoId = event.target.value as string;
    onVideoIdChange(selectedVideoId, stepIndex);
  };

  const onRegionSelection = (key: string) => {
    onToggleRegion(key, stepIndex);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>Step #{stepIndex + 1}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} error={!!videoError}>
          <InputLabel shrink id="align-by-select-placeholder-label-label">
            Channel Video
          </InputLabel>
          <Select
            labelId="align-by-select-placeholder-label-label"
            value={selectedChannelVideo ? selectedChannelVideo.id : ""}
            onChange={handleChannelVideoChange}
            displayEmpty
            className={classes.selectEmpty}
            disabled={videos.length === 0 || isUiDisabled}
          >
            {videos.map(({ id, name, thumbnail_path }) => (
              <MenuItem value={id} key={id}>
                <Avatar
                  alt={name}
                  src={thumbnail_path}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{videoError}</FormHelperText>
        </FormControl>
      </Grid>
      {selectedChannelVideo && (
        <Grid item xs={12}>
          <video
            className={classes.video}
            ref={videoRef}
            playsInline
            src={selectedChannelVideo.file_path}
            crossOrigin="anonymous"
            onLoadedMetadata={onLoadedMetadata}
          ></video>
        </Grid>
      )}
      {duration !== undefined && sliderValue !== undefined && (
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.timespanSlectionControls}>
            <IconButton
              aria-label={"Move fast forward"}
              onClick={onMoveBackFast}
              className={`${classes.button} ${classes.flippedButton}`}
              disabled={isUiDisabled}
            >
              <FastForwardIcon />
            </IconButton>
            <IconButton
              aria-label={"Move back"}
              onClick={onMoveBack}
              className={classes.button}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography className={classes.timeLabel}>
              {secondsToTimeString(sliderValue)}
            </Typography>
            <IconButton
              aria-label={"Move forward"}
              onClick={onMoveForward}
              className={classes.button}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton
              aria-label={"Move fast forward"}
              onClick={onMoveForwardFast}
              className={classes.button}
              disabled={isUiDisabled}
            >
              <FastForwardIcon />
            </IconButton>
          </Grid>
          <Slider
            track={false}
            value={sliderValue}
            aria-labelledby="time-slider"
            valueLabelDisplay="off"
            step={step}
            min={0}
            max={duration}
            onChange={handleSliderChange}
            disabled={isUiDisabled}
          />
        </Grid>
      )}
      {duration !== undefined && sliderValue !== undefined && (
        <Grid item xs={12}>
          <Button
            disabled={isUiDisabled}
            onClick={onPreview}
            variant="contained"
            color="primary"
          >
            Generate avatar data
          </Button>
        </Grid>
      )}
      {keypoints && keypoints.length > 0 && (
        <Grid item xs={6}>
          <canvas ref={canvasRef}></canvas>
        </Grid>
      )}
      {keypoints && keypoints.length > 0 && (
        <Grid item xs={6}>
          <Pose3dEditor
            height={dimension}
            width={dimension}
            keypoints={mediapipeKeypoint.mapParts(keypoints)}
            avatarType={AvatarType.Female}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <p className={classes.error}>{avatarDataError}</p>
      </Grid>
      {keypoints && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16 }}>
              Regions of interest
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.info}>
            <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
            <Typography>If no regions will be selected, all regions will be used for calculations.</Typography>
          </Grid>
          <HumanRegions
            selectedRegions={regions}
            onRegionSelection={onRegionSelection}
          />
        </Grid>
      )}
    </Grid>
  );
}
