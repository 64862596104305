import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import { ChannelPoseOrSkill } from "../../../providers/instructor/channel-pose.provider";
import { Editor } from "./components";
import {
  LinearProgress,
  Button,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
    },
  });

type State = {
  isContainerInitialized: boolean;
  item?: ChannelPoseOrSkill;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class ChannelPoseOrSkillEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isContainerInitialized: false,
    };
  }

  componentDidMount = async () => {
    try {
      const poseOrSkillId = this.props.match.params.poseOrSkillId;
      if (!poseOrSkillId) {
        //TODO: handle error
        return;
      }

      const item = await channelPoseOrSkillProvider.get(poseOrSkillId);
      this.setState({
        item,
        isContainerInitialized: true,
      });
    } catch (err) {
      //TODO: handle initialization error
      console.log("componentDidMount:err");
      console.log(err);
    }
  };

  onSave = async (entity: any): Promise<void> => {
    await channelPoseOrSkillProvider.skeleton(entity);
    this.props.history.goBack();
  };

  render = () => {
    const { isContainerInitialized, item } = this.state;

    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
        />
        <Divider />
        <Paper className={classes.paper}>
          {!isContainerInitialized ? (
            <LinearProgress />
          ) : !item ? (
            <div> Item not found. </div>
          ) : (
            <Editor {...item} onSave={this.onSave} />
          )}
          <Button onClick={this.props.history.goBack} variant="contained">
            Go Back
          </Button>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelPoseOrSkillEdit);
export default withRouter(styledComponent as any);
