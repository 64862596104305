import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { RouteComponentProps, useParams } from "react-router-dom";
import { ChannelsToolbar } from "../ChannelList/components";
import ActivityTimespanCard from "./components/ActivityTimespanCard/ActivityTimespanCard";
import * as studentActivityTimespanProvider from "../../../providers/student/activity-timespan.provider";
import { StudentActivityTimespanListItem } from "../../../types/activities/student-activity-timespan-list-item";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  activityId: string;
};

export default function ActivitiesList(props: Props) {
  const { title } = props;

  const classes = useStyles();
  const [timespans, setTimespans] = useState<StudentActivityTimespanListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { activityId } = useParams<Params>();

  useEffect(() => {
    const loadActivities = async () => {
      try {
        const timespans = await studentActivityTimespanProvider.getAll(
          activityId
        );
        setTimespans(timespans);
        setLoading(false);
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };

    loadActivities();
  }, []);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h1" gutterBottom>
        {title}
      </Typography>
      <ChannelsToolbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.content}>
            <Grid container spacing={3}>
              {timespans &&
                timespans.map((timespan) => (
                  <Grid item key={timespan.id} lg={4} md={4} sm={6} xs={12}>
                    <ActivityTimespanCard timespan={timespan} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
