import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import { RewardType } from "../../../../../../../enums/reward-type.enum";
import { getRewardImage } from "../../../../../../../services/reward/reward.service";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
  reward: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  rewardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

export default function ActivityCard(props) {
  const { studentActivity } = props;
  const { id, name, creationDate, rewards, thumbnailPath } = studentActivity;
  const history = useHistory();
  const classes = useStyles();
  const onActivityClicked = () => {
    history.push(`/sports-student/practice-activity/${id}`);
  };

  const { gold, silver, bronze } = rewards.reduce(
    (acc, curr) => {
      if (curr === RewardType.Gold) {
        const currentValue = acc["gold"];
        acc["gold"] = currentValue + 1;
      }
      if (curr === RewardType.Silver) {
        const currentValue = acc["silver"];
        acc["silver"] = currentValue + 1;
      }
      if (curr === RewardType.Bronze) {
        const currentValue = acc["bronze"];
        acc["bronze"] = currentValue + 1;
      }
      return acc;
    },
    {
      gold: 0,
      silver: 0,
      bronze: 0,
    }
  );

  return (
    <Card onClick={onActivityClicked}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={thumbnailPath}
          title="thumbnail"
        />
        <CardContent>
          <Typography gutterBottom variant="h3" component="h2">
            <strong>Class:</strong> {name}
          </Typography>
          <Typography color="textSecondary" variant="h5" component="h2">
            <strong>Recorded:</strong> {new Date(creationDate).toLocaleString()}
          </Typography>
          <Typography className={classes.rewardsContainer} color="textSecondary" variant="h5" component="h2">
            {gold !== 0 && (<div className={classes.rewardsContainer} ><img  title={"Gold"} alt={"Gold"} className={classes.reward} src={getRewardImage(RewardType.Gold)} /> <strong>{gold}</strong> </div> )}
            {silver !== 0 && (<div className={classes.rewardsContainer} ><img  title={"Silver"} alt={"Silver"} className={classes.reward} src={getRewardImage(RewardType.Silver)} /> <strong>{silver}</strong> </div> )}
            {bronze !== 0 && (<div className={classes.rewardsContainer} ><img  title={"Bronze"} alt={"Bronze"} className={classes.reward} src={getRewardImage(RewardType.Bronze)} /> <strong>{bronze}</strong> </div> )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
