import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { StudentActivity } from "../../../../../types/activities/student-activity";
import ActivityCard from "./components/ActivityCard/ActivityCard";
import * as studentActivityProvider from "../../../../../providers/student/activity.provider";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const pageSize = 8;

export default function ActivityList() {
  const classes = useStyles();
  const [activities, setActivities] = useState<StudentActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    const getClasses = async (page: number) => {
      setLoading(true);
      try {
        const pager = {
          pageSize: pageSize,
          pageNumber: page,
        };

        const { records, total } = await studentActivityProvider.getAll(pager);
        setActivities(records);
        setTotalCount(total);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses(page);
  }, [page]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <div className={classes.root}>
        <Typography component="h3" variant="h3" gutterBottom>
        Activities History
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={classes.content}>
          <Grid container spacing={3}>
            {activities && activities.length !== 0 ? (
              activities.map((activity) => (
                <Grid item key={activity.id} lg={3} md={3} sm={6} xs={12}>
                  <ActivityCard studentActivity={activity} />
                </Grid>
              ))
            ) : (
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Typography>No records found</Typography>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {totalCount !== undefined && totalCount !== 0 && (
        <Pagination
          disabled={loading}
          count={Math.ceil(totalCount / pageSize)}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
}
