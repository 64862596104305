import React, { FC, RefObject } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    zIndex: -10,
    position: "absolute",
    top: 5,
    left: 5,
    width: "100%",
    opacity: 0,
  },
}));

type Props = {
  filePath: string;
  videoRef: RefObject<HTMLVideoElement>;
  onDataLoaded: () => void;
};

const InstructorVideo: FC<Props> = (props) => {
  const classes = useStyles();
  const { filePath, videoRef, onDataLoaded } = props;
  
  return (
    <video
      className={classes.video}
      src={filePath}
      ref={videoRef}
      onLoadedData={onDataLoaded}
      crossOrigin="anonymous"
      playsInline
    />
  );
};

export default InstructorVideo;
