import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import defaultThumbnail from "../../../../../../../assets/images/channel-thumbnail.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  instructor: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const CourseCard = (props) => {
  const { course, channelId, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const onAnalysis = () => {
    history.push(`/sports-student/take-class/${course.id}/${channelId}`);
  };

  return (
    <Card {...rest} className={classes.card}>
      <CardActionArea>
        <img
          alt="channel thumbnail"
          className={classes.media}
          src={course.thumbnail_path || defaultThumbnail}
          title={course.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            className={classes.name}
            title={course.name}
            variant="h3"
            component="h2"
          >
            {course.name}
          </Typography>
          <Typography
            className={classes.instructor}
            title={`${course.first_name} ${course.last_name}`}
            variant="h6"
            color="textSecondary"
          >
            {course.first_name} {course.last_name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          onClick={() => onAnalysis(course.id)}
        >
          Take class
        </Button>
      </CardActions>
    </Card>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
};

export default CourseCard;
