import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: { position: "relative", height: "100%" },
  progress: {
    position: "relative",
    width: 20,
    height: "100%",
    display: "inline-block",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    marginRight: 7,
    overflow: "hidden",
    backgroundColor: "rgb(27, 27, 27)",
    borderRadius: 15,
    boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)",
  },
  progressBar: {
    borderRadius: 15,
    width: "80%",
    left: "10%",
    position: "absolute",
    bottom: 2,
    background: "linear-gradient(lightgreen, yellow)",
    boxShadow: "inset 0 -1px 0 rgba(0, 0, 0, 0.15)",
    transition: ".4s linear",
  },
  value: {
    position: "absolute",
    left: 40,
    transition: ".4s linear",
    backgroundColor: "rgba(27, 27, 27, 0.7)",
    color: "white",
    padding: 10,
    fontSize: 64,
    width: 100,
    height: 100,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {
  accuracy: number;
};

export default function AccuracyProgressBar(props: Props) {
  const classes = useStyles();
  const { accuracy } = props;
  const progressAccuracy = Number.isNaN(accuracy) ? 0 : accuracy;

  return (
    <div className={classes.container}>
      <div className={classes.progress}>
        <div
          style={{ height: `${progressAccuracy}%` }}
          className={classes.progressBar}
        ></div>
      </div>
      <div
        className={classes.value}
        style={{
          top: `${100 - progressAccuracy - 15}%`,
        }}
      >
        <span>{Number.isNaN(progressAccuracy) ? "" : Math.round(progressAccuracy)}</span>
      </div>
    </div>
  );
}
