import React, { Component } from "react";
import { VideoPlayer } from "./components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelVideoProvider from "../../../providers/student/channel-video.provider";
import { ChannelVideoDetails } from "../../../providers/student/channel-video.provider";
import * as channelProvider from "../../../providers/student/channel.provider";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Button, Divider } from "@material-ui/core";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    channel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  });

type State = {
  video?: ChannelVideoDetails;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  className: any;
  breadcrumbs: any[];
  title: string;
};

class VideoDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const videoId = this.props.match.params.videoId;

    if (videoId) {
      const video = await channelVideoProvider.get(videoId);
      this.setState({
        video: video,
      });
    }
  };

  onSubscribe = async () => {
    const { video } = this.state;
    if (video && video.subscribed) {
      await channelProvider.unsubscribe(video.channel_id);
    }

    if (video && !video.subscribed) {
      await channelProvider.subscribe(video.channel_id);
    }

    if (video) {
      video.subscribed = !video.subscribed;
    }

    this.setState({
      video: video,
    });
  };

  onChannelClick = (channelId: string) => {
    this.props.history.push(`/sports-student/channel/${channelId}`);
  };

  render = () => {
    const { video } = this.state;

    const { classes, className } = this.props;

    const data = video ? { channelId: video.channel_id } : undefined;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        {video && (
          <Card className={clsx(classes.card, className)}>
            <CardActionArea>
              {video && <VideoPlayer video={video} />}
              <CardContent>
                <div className={clsx(classes.channel, className)}>
                  <Typography
                    color="primary"
                    onClick={() => this.onChannelClick(video.channel_id)}
                  >
                    {video.channel_name}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={this.onSubscribe}
                    color={video.subscribed ? "secondary" : "primary"}
                  >
                    {video.subscribed ? "Unsubscribe" : "Subscribe"}
                  </Button>
                </div>

                <Typography gutterBottom variant="h3" component="h2">
                  {video.name}
                </Typography>
                <Typography color="textSecondary" variant="h6" component="h2">
                  {new Date(video.creation_date).toLocaleString()}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </div>
    );
  };
}

const styledComponent = withStyles(styles)(VideoDetailsView);
export default withRouter(styledComponent as any);
