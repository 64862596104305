import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  ActivityList,
  PracticeActivityList,
  SequenceActivityList,
  Summary,
} from "./components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export default function ChannelCourseList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Summary />
        </Grid>
        <Grid item xs={12}>
          <ActivityList />
        </Grid>
        <Grid item xs={12}>
          <SequenceActivityList />
        </Grid>
        <Grid item xs={12}>
          <PracticeActivityList />
        </Grid>
      </Grid>
    </div>
  );
}
