import { AppRoute } from "./app-route.type";
import { RouteAccessRequirement } from "./route-access-requirement.enum";
import { AppRouteLayout } from "./app-route-layout.enum";

import ChannelList from "../views/admin/ChannelList";
import ChannelVideoList from "../views/admin/ChannelVideoList";
import ActivityList from "../views/admin/ActivityList";

import SurroundSoundIcon from "@material-ui/icons/SurroundSound";
import ActivityEdit from "../views/admin/ActivityEdit";
import UserTable from "../views/admin/UserTable";
import FeedbackTable from "../views/admin/FeedbackTable";
import VideoDetails from "../views/admin/VideoDetails";
import AdminVideoTest from "../views/admin/AdminVideoTest";
import { ChannelClassEdit } from "../views/admin/ChannelClassEdit";
import ClassList from "../views/admin/ClassList";
import ChannelClassSequenceEdit from "../views/admin/ChannelClassSequenceEdit/ChannelClassSequenceEdit";
import { ChannelClassPracticeEdit } from "../views/admin/ChannelClassPracticeEdit";

const routePrefix = "/admin";

const getPath = (route: string): string => {
  return `${routePrefix}${route}`;
};

const videosRoute = {
  path: getPath("/channel-videos"),
  component: ChannelVideoList,
  title: "Admin Videos",
  accessRequirement: RouteAccessRequirement.Admin,
  layout: AppRouteLayout.Main,
  icon: SurroundSoundIcon,
};

export const sidebarRoutes: Array<AppRoute> = [
  {
    path: getPath("/classes"),
    component: ClassList,
    title: "Admin Classes",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
  {
    path: getPath("/channels"),
    component: ChannelList,
    title: "Admin Channels",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
  videosRoute,
  {
    path: getPath("/activities"),
    component: ActivityList,
    title: "Admin Activities",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
  {
    path: getPath("/users"),
    component: UserTable,
    title: "Admin Users",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
  {
    path: getPath("/feedback"),
    component: FeedbackTable,
    title: "Admin Feedback",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
  {
    path: getPath("/video-test"),
    component: AdminVideoTest,
    title: "Admin Video Test",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    icon: SurroundSoundIcon,
  },
];

export const otherRoutes: Array<AppRoute> = [
  {
    path: getPath("/activity/:activityId/edit"),
    component: ActivityEdit,
    title: "Admin Activity Edit",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
  }, 
  {
    path: getPath("/video/:videoId/details"),
    component: VideoDetails,
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
    title: "Video Details",
    breadcrumbs: [videosRoute],
  },
  {
    path: getPath("/class/:classId/edit"),
    component: ChannelClassEdit,
    title: "Admin Class Edit",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
  }, 
  {
    path: getPath("/class-sequence/:classId/edit"),
    component: ChannelClassSequenceEdit,
    title: "Admin Class Sequence Edit",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
  }, 
  {
    path: getPath("/class-practice/:classId/edit"),
    component: ChannelClassPracticeEdit,
    title: "Admin Class Practice Edit",
    accessRequirement: RouteAccessRequirement.Admin,
    layout: AppRouteLayout.Main,
  }, 

  
];

export const routes: Array<AppRoute> = sidebarRoutes.concat(otherRoutes);
