import React, { useState } from "react";
import { Paper, Checkbox } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as userInterestProvider from "../../providers/common/user-interest.provider";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

type Props = {
  featureName: string;
};

const NotImplementedComponent = (props: Props) => {
  const { featureName } = props;
  const classes = useStyles();
  const [hide, setHide] = useState<boolean>(false);
  //TODO: handle empty feature name,
  const handleCheck = async () => {
    await userInterestProvider.create({ featureName: featureName });
    setHide(true);
  };
  return (
    <div className={classes.layout}>
      <Paper className={classes.paper}>
        {hide ? (
          <p>Thank you for your interest!</p>
        ) : (
          <div>
            <span>"{featureName}" will be available soon.</span>
            <span> Tick this box</span>
            <Checkbox
              checked={false}
              onChange={handleCheck}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <span>
              {" "}
              to register your interest and we will let you know when it is
              ready to use.
            </span>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default NotImplementedComponent;
