import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { ChannelClassSequenceCheckpoint } from "../../types/class-sequence/class-sequence-checkpoint";
import { type } from "os";
import { RewardType } from "../../enums/reward-type.enum";

export type ClassSequenceActivityRecordsCreate = {
  activity_id: string;
  accuracy: number;
  timestamp: number;
  keypoints: Keypoint3D[];
  joints_accuracy: any;
};

export type StudentActivityRecord = {
  accuracy: number; //  'accuracy'
  timestamp: number; // 'timestamp'
  keypoints: Keypoint3D[]; //'keypoints',
  joints_accuracy: any; //'joints_accuracy'
  current_time: number; //'current_time'
};

export type CheckpointReward = {
  checkpoint_index: number;
  medal: RewardType;
}

export type StudentSequenceActivity = {
  id: string;
  creation_date: string;
  name: string;
  file_path: string;
  checkpoints: ChannelClassSequenceCheckpoint[];
  records: StudentActivityRecord[];
  duration_seconds: number;
  rewards: CheckpointReward[];
  effectiveness: number;
};

export type StudentSequenceActivityItem = {
  id: string;
  creation_date: string;
  name: string;
};

export type StudentSequencePagingList = {
  records: StudentSequenceActivityItem[];
  total: number;
};

export const getAll = async (pager: any): Promise<StudentSequencePagingList> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/sequence-activity/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<StudentSequenceActivity> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/sequence-activity/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const createRecords = async (
  model: ClassSequenceActivityRecordsCreate
): Promise<void> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/sequence-activity/createRecord",
    baseURL: apiUrlBase,
    data: {
      ...model,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

type ClassSequenceActivityRewardsCreate = {
  reward: RewardType,
  checkpoint_index: number,
  activity_id: string,
  score: number
}

export const saveReward = async (
  model: ClassSequenceActivityRewardsCreate
): Promise<void> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/sequence-activity/reward/save",
    baseURL: apiUrlBase,
    data: {
      ...model,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const saveEffectiveness = async (
  model: any
): Promise<void> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/sequence-activity/effectiveness/save",
    baseURL: apiUrlBase,
    data: {
      ...model,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
