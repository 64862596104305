import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelClassPracticeBlockCreateListItem = {
  id: number;
  block_id: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_duration_seconds?: number;
  exercise_repetitions?: number;
  name: string;
  thumbnail_path: string;
};

export type ChannelClassOption = {
  id: string;
  name: string;
  thumbnail_path: string;
  is_published: boolean;
};

export type ChannelClassPracticeBlock = {
  id: number;
  block_id: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_duration_seconds?: number;
  exercise_repetitions?: number;
  name: string;
  thumbnail_path: string;
  order: number;
};

export type ChannelClassPractice = {
  name: string;
  description: string;
  blocks: ChannelClassPracticeBlock[];
};

export const getAll = async (): Promise<Array<ChannelClassOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getAllSearch = async (pager: any, filter: any): Promise<{
  records: Array<ChannelClassOption>;
  total: number;
}> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/getAllSearch",
    baseURL: apiUrlBase,
    data: {
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        name: filter["name"],
      },
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/update",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClassPractice> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};


export type ChannelClassBlockOption = {
  id: string;
  name: string;
  thumbnail_path: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_id: string;
  exercise_id: string;
};

export const getAllBlocks = async (
  classId: string
): Promise<Array<ChannelClassBlockOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/getAllBlocks",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      class_id: classId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const toggleUnpublish = async (id: string, isPublished: boolean): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-practice/toggle_unpublish",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
      is_published: isPublished
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};