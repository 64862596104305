import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../services/pose3d.service";
import { ClassView } from "../../../types/threejs/class-view.type";
import { IconButton } from "@material-ui/core";
import * as THREE from "three";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../services/avatar/avatar.service";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../services/keypoint.helper";
import {
  getAccuracyLabel,
  getCurrentPoseLabel,
  getCurrentInstructorPoseMesh,
  getInstructorBackground,
  getInstructorVideoMesh,
  getStudentBackground,
  getStudentVideoMesh,
  getTextBackground,
  getTextGeometry,
  setSize,
  getInstructorVideoProgressBarNode,
  getInstructorVideoGeneralProgressBar,
  getInstructorVideoProgressBarNodeBackground,
} from "./ui.helper";
import { LiveClassModel } from "../../../types/analyze/live.class.type";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import * as rewardService from "../../../services/reward/reward.service";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import { RewardType } from "../../../enums/reward-type.enum";
import {
  NextTimespanCountdown,
  TimespanCircularProgressBar,
} from "./components";
import { rotateAroundAxis } from "../../../services/mediapipe/mediapipe.keypoint";
import {
  defaultRegions,
  getJointsFromRegions,
} from "../../../services/avatar/regions.service";
import { halfWidth, wallHeight } from "../../../services/analysis-scene.helper";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  canvas: {
    width: "100%",
    height: "100%",
  },
  iconButton: {
    fontSize: "3rem !important",
  },
  rotateLeft: {
    left: 5,
    bottom: "30%",
    position: "absolute",
  },
  rotateRight: {
    right: 5,
    bottom: "30%",
    position: "absolute",
  },
}));

type Props = {
  timespan?: CourseAnalysisTimespan;
  instructorVideo: HTMLVideoElement;
  studentCanvas: HTMLCanvasElement;
  avatarType: AvatarType;
  selfieMode: boolean;
  currentTime: number;
  onPoseAccuracyCalculated: (accuracy: number, live: LiveClassModel) => void;
  live?: LiveClassModel;
  timespans: CourseAnalysisTimespan[];
  onExercisePeakReached: (
    accuracyPercentages: { [key: number]: number },
    live: LiveClassModel,
    repetition: number
  ) => void;
  onSaveReward: (reward: RewardType, timespanId: string) => void;
  onRepetition: (text: string) => void;
  onPlayBeep: () => void;
  isTest?: boolean;
};

const progressBarHeight = wallHeight / 60;
const progressBarFullWidth = halfWidth * 2;
const studentRootName = "root-student";
const studentDisplayRootName = "root-student-display";
const instructorRootName = "root-instructor";
const instructorDisplayRootName = "root-instructor-display";

const usePrevious = <T extends {}>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const LiveSkeletons: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    instructorVideo,
    studentCanvas,
    avatarType,
    selfieMode,
    currentTime,
    onPoseAccuracyCalculated,
    live,
    timespans,
    onExercisePeakReached,
    onSaveReward,
    onRepetition,
    onPlayBeep,
    isTest
  } = props;
  const getCurrentTimespan = (
    timespans: CourseAnalysisTimespan[],
    currentTime: number
  ): CourseAnalysisTimespan | undefined => {
    return timespans.find(
      (x) => x.start_seconds <= currentTime && x.end_seconds >= currentTime
    );
  };

  const timespan = getCurrentTimespan(timespans, currentTime);
  const timespanId = timespan ? timespan.id : undefined;

  const getInstructorPoseName = (
    timespan: CourseAnalysisTimespan | undefined
  ) => {
    if (!timespan) {
      return undefined;
    }
    if (timespan.pose) {
      return timespan.pose.name;
    }
    if (timespan.exercise) {
      return `${timespan.exercise.name}(${timespan.exercise.repetitions})`;
    }
  };

  const prevTimespanId = usePrevious<any>(timespanId);

  const mountRef = useRef<HTMLDivElement>(null);

  const poseNameRef = useRef<THREE.Mesh>();
  const poseNameBackgroundRef = useRef<THREE.Mesh>();
  const poseNameFontRef = useRef<THREE.Font>();

  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();

  const instructorVideoTextureRef = useRef<THREE.VideoTexture>();

  const studentVideoTextureRef = useRef<THREE.CanvasTexture>();

  const sceneRef = useRef<THREE.Scene>();

  const instructorImageMeshRef = useRef<THREE.Mesh>();
  const instructorImageTextureRef = useRef<THREE.Texture>();
  const instructorImageMaterialRef = useRef<THREE.MeshBasicMaterial>();

  const instructorImageBackgroundRef = useRef<THREE.Mesh>();

  const accuracyLabelMeshRef = useRef<THREE.Mesh>();
  const accuracyBackgroundMeshRef = useRef<THREE.Mesh>();

  const [timespanAccuracy, setTimespanAccuracy] = useState<
    { percentage: number; currentTime: number }[]
  >([]);

  const [timespanProgressPercentage, setTimespanProgressPercentage] =
    useState<number>(0);

  const [nextExerciseStepIndex, setNextExerciseStepIndex] = useState<number>(0);

  const [repsCounter, setRepsCounter] = useState<number>(0);

  const instructorPoseName = getInstructorPoseName(timespan);

  const [prevExerciseAccuracy, setPrevExerciseAccuracy] = useState<{
    [key: number]: number;
  }>({});

  //HACK: for timespan that ends with video
  const [isInstructorVideoEnded, setIsInstructorVideoEnded] =
    useState<boolean>(false);

  const [renderTrigger, setRenderTrigger] = useState<number>();

  const getInstructorImageSrc = (
    timespan: CourseAnalysisTimespan | undefined
  ) => {
    if (!timespan) {
      return undefined;
    }
    if (timespan.pose) {
      return timespan.pose.image_path;
    }
    if (timespan.exercise) {
      if (nextExerciseStepIndex !== undefined) {
        return timespan.exercise.steps[nextExerciseStepIndex].image_path;
      }
      //TODO: rework
      return timespan.exercise.steps[0].image_path;
    }
  };

  const instructorImageSrc = getInstructorImageSrc(timespan);

  const rotateCamera = (degrees: number) => {
    if (view) {
      const camera = view.camera;
      const quaternion = new THREE.Quaternion();
      const radians = degrees * (Math.PI / 180);
      quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), radians);
      camera.position.applyQuaternion(quaternion);

      view.eye3d = camera.position.clone().toArray();
      setView(view);

      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (mount && scene && renderer && view) {
        pose3dService.renderCourseThree(mount, scene, renderer, view);
      }
    }
  };

  const getTimespanBarName = (index: number): string => {
    return `${index}TimespanBar`;
  };

  const getTimespanRewardName = (index: number): string => {
    return `${index}TimespanReward`;
  };

  const updateAccuracyLabelValue = (value: number) => {
    const font = poseNameFontRef.current;
    const accuracyLabel = accuracyLabelMeshRef.current;
    const accuracyLabelBackground = accuracyBackgroundMeshRef.current;
    if (font && accuracyLabel && accuracyLabelBackground) {
      const shapes = font.generateShapes(`${value}`, 0.11);
      const geometry = getTextGeometry(shapes);
      accuracyLabel.geometry.dispose();
      accuracyLabel.geometry = geometry;

      const material = accuracyLabel.material as THREE.Material;
      material.visible = true;

      (accuracyLabelBackground.material as THREE.Material).visible = true;
    }
  };

  const hideAccuracyLabel = () => {
    const accuracyLabel = accuracyLabelMeshRef.current;
    const accuracyLabelBackground = accuracyBackgroundMeshRef.current;
    if (accuracyLabel && accuracyLabelBackground) {
      const material = accuracyLabel.material as THREE.Material;
      material.visible = false;
      (accuracyLabelBackground.material as THREE.Material).visible = false;
    }
  };

  useEffect(() => {
    const initScene = async () => {
      const backgroundColor = new THREE.Color(0xd3d3d3);

      const getVideoTexture = (video: HTMLVideoElement): THREE.VideoTexture => {
        const texture = new THREE.VideoTexture(video);
        return texture;
      };

      const getCanvasTexture = (
        canvas: HTMLCanvasElement
      ): THREE.CanvasTexture => {
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
      };

      instructorVideoTextureRef.current = getVideoTexture(instructorVideo);
      studentVideoTextureRef.current = getCanvasTexture(studentCanvas);

      const getScene = (
        instructorTexture: THREE.VideoTexture,
        instructorVideo: HTMLVideoElement,
        studentTexture: THREE.CanvasTexture,
        studentVideo: HTMLCanvasElement
      ): THREE.Scene => {
        const scene = pose3dService.sceneSetup();
        scene.add(getStudentBackground(backgroundColor, wallHeight, halfWidth));
        const instructorVideoMesh = getInstructorVideoMesh(
          instructorTexture,
          instructorVideo,
          wallHeight,
          halfWidth
        );

        const setupInstructorVideoProgressBarBackground = (
          timespans: CourseAnalysisTimespan[],
          duration: number
        ) => {
          const instructorVideoProgressBarMaterial =
            new THREE.MeshBasicMaterial({
              color: new THREE.Color(0xfec27a),
              opacity: 0.7,
              transparent: true,
            });
          timespans.forEach((timespan) => {
            const instructorVideoPorgressBarBackground =
              getInstructorVideoProgressBarNodeBackground(
                duration,
                progressBarFullWidth,
                progressBarHeight,
                halfWidth,
                timespan,
                instructorVideoProgressBarMaterial
              );
            scene.add(instructorVideoPorgressBarBackground);
          });
        };
        setupInstructorVideoProgressBarBackground(
          timespans,
          instructorVideo.duration
        );
        scene.add(instructorVideoMesh);

        const studentVideoMesh = getStudentVideoMesh(
          studentTexture,
          studentVideo,
          wallHeight,
          halfWidth
        );
        scene.add(studentVideoMesh.mesh);
        scene.add(studentVideoMesh.backgroundMesh);

        const instructorBackground = getInstructorBackground(
          backgroundColor,
          wallHeight,
          halfWidth
        );
        instructorImageBackgroundRef.current = instructorBackground;
        scene.add(instructorBackground);
        return scene;
      };

      sceneRef.current = getScene(
        instructorVideoTextureRef.current,
        instructorVideo,
        studentVideoTextureRef.current,
        studentCanvas
      );
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }
      const renderer = pose3dService.getCourseRenderer(mount);
      setRenderer(renderer);

      const fontLoader = new THREE.FontLoader();
      const font = await fontLoader.loadAsync("/fonts/Roboto_Regular.json");

      poseNameFontRef.current = font;
      if (instructorPoseName) {
        const text = getCurrentPoseLabel(instructorPoseName, font, halfWidth);
        const box = text.geometry.boundingBox;
        if (box) {
          const mesh = getTextBackground(box, text.position.z);
          poseNameBackgroundRef.current = mesh;
          scene.add(mesh);
        }

        poseNameRef.current = text;
        scene.add(text);
      }

      const view = pose3dService.getCourseView(mount);
      setView(view);

      const addStudentModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentRootName;

        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });

        scene.add(model);
        avatarService.init(model);
      };

      const addStudentDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.shininess = 10;
            object.material.color = new THREE.Color(0x0072fa);
            object.material.visible = false;
            object.castShadow = true;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.transparent = true;
            object.material.opacity = 0.5;
            object.material.color = new THREE.Color(0x00ff00);
            object.material.visible = false;
          }
        });
        model.position.z = halfWidth / 2;
        scene.add(model);
        avatarService.init(model);
      };

      const addAccuracyLabel = () => {
        if (font) {
          const shapes = font.generateShapes("", 0.11);
          const geometry = getTextGeometry(shapes);
          const mesh = getAccuracyLabel(geometry, wallHeight, halfWidth);

          const getAccuracyLabelBackground = (position: THREE.Vector3) => {
            const geometry = new THREE.CircleGeometry(0.12, 50);
            const material = new THREE.MeshBasicMaterial({
              color: 0x000000,
              transparent: true,
              opacity: 0.1,
              visible: false,
            });
            const mesh = new THREE.Mesh(geometry, material);

            mesh.position.set(
              position.x - 0.01,
              position.y + 0.05,
              position.z + 0.0005
            );
            mesh.rotation.y = Math.PI;
            mesh.rotation.z = Math.PI / 2;
            return mesh;
          };
          const background = getAccuracyLabelBackground(mesh.position);
          scene.add(background);
          accuracyBackgroundMeshRef.current = background;
          scene.add(mesh);
          accuracyLabelMeshRef.current = mesh;
        }
      };

      await rewardService.loadStarModel();
      await addStudentModel();
      await addStudentDisplayModel();
      await addInstructorModel();
      if(isTest){
        await addInstructorDisplayModel();
      }
      addAccuracyLabel();
    };

    initScene();

    const videoUpdateInterval = setInterval(() => {
      const instructorVideoTexture = instructorVideoTextureRef.current;
      if (instructorVideoTexture) {
        instructorVideoTexture.needsUpdate = true;
      }

      const studentVideoTexture = studentVideoTextureRef.current;
      if (studentVideoTexture) {
        studentVideoTexture.needsUpdate = true;
      }

      setRenderTrigger(new Date().getTime());
    }, 120);

    //HACK: trieggers processing of last timespan(that ends on video end)
    const onInstructorVideoEnded = () => {
      setIsInstructorVideoEnded(true);
    };
    instructorVideo.addEventListener("ended", onInstructorVideoEnded);

    const onWindowResize = () => {
      //TODO: update, replace hardcoded value
      const headerHeight = 64;
      if (view && renderer) {
        view.camera.aspect = window.innerWidth / window.innerHeight;
        view.camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight - headerHeight);
      }
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      instructorVideo.removeEventListener("ended", onInstructorVideoEnded);

      const cleanupAvatar = (scene: THREE.Scene, name: string) => {
        const avatar = scene.getObjectByName(name);
        if (avatar) {
          avatar.traverse((child: any) => {
            if (child.isMesh) {
              pose3dService.cleanupObject(child);
            }
          });
          scene.remove(avatar);
        }
      };
      const scene = sceneRef.current;
      if (scene) {
        cleanupAvatar(scene, studentRootName);
        cleanupAvatar(scene, studentDisplayRootName);
        cleanupAvatar(scene, instructorRootName);
      }
      //TODO: DISPOSE EVERYTHING!!
      //https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      //something to think about https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
      clearInterval(videoUpdateInterval);
    };
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    if (mount && scene && renderer && view) {
      pose3dService.renderCourseThree(mount, scene, renderer, view);
    }
  }, [renderTrigger]);

  const getTextureFromPath = async (
    imagePath: string
  ): Promise<THREE.Texture> => {
    const textureLoader = new THREE.TextureLoader();
    const texture = await textureLoader.loadAsync(imagePath);
    //NOTE: https://discourse.threejs.org/t/warning-from-threejs-image-is-not-power-of-two/7085
    texture.minFilter = THREE.LinearFilter;
    texture.generateMipmaps = false;
    return texture;
  };

  const getMaterial = (texture: THREE.Texture): THREE.MeshBasicMaterial => {
    const material = new THREE.MeshBasicMaterial({ map: texture });
    return material;
  };

  useEffect(() => {
    const onChange = (
      scene: THREE.Scene,
      prevTimespanId: string | undefined,
      progressPercentage: number
    ) => {
      const prevIndex = timespans.findIndex((t) => t.id === prevTimespanId);
      if (prevTimespanId && prevIndex >= 0) {
        const prevTimespanName = getTimespanBarName(prevIndex);
        const prevTimespanBar = scene.getObjectByName(prevTimespanName);
        if (prevTimespanBar) {
          const prevPosition = prevTimespanBar.position;
          const prevRotation = prevTimespanBar.rotation;
          const star = rewardService.getStarModel(progressPercentage);

          const reward = rewardService.getReward(progressPercentage);
          onSaveReward(reward, prevTimespanId);
          if (star) {
            const starClone = star.clone();
            starClone.name = getTimespanRewardName(prevIndex);
            starClone.scale.set(0.0007, 0.0007, 0.0007);
            starClone.position.set(
              prevPosition.x + 0.15,
              prevPosition.y + wallHeight - 0.15,
              prevPosition.z
            );
            starClone.rotation.setFromQuaternion(
              new THREE.Quaternion().setFromEuler(prevRotation)
            );

            scene.add(starClone);
          }
        }
      }

      setTimespanAccuracy([]);
      setTimespanProgressPercentage(0);
    };

    const scene = sceneRef.current;
    if (scene) {
      onChange(scene, prevTimespanId, timespanProgressPercentage);
    }

    const setExerciseVariables = (timespan?: CourseAnalysisTimespan) => {
      if (timespan && timespan.exercise) {
        setNextExerciseStepIndex(0);
        setPrevExerciseAccuracy({});
        setRepsCounter(0);
      }
    };
    setExerciseVariables(timespan);

    if (!timespanId) {
      const hideInstructorAvatar = (scene: THREE.Scene) => {
        const model = scene.getObjectByName(instructorDisplayRootName);
        if (model) {
          model.traverse((object: any) => {
            if (object.isMesh) {
              object.material.visible = false;
            }
          });
        }
      };
      const hideAccuracyLabelBackground = () => {
        const background = accuracyBackgroundMeshRef.current;
        if (background) {
          const material = background.material as THREE.Material;
          material.visible = false;
        }
      };

      hideAccuracyLabel();
      hideAccuracyLabelBackground();
      if (scene) {
        avatarFeedback.removeFeedback(scene);
        hideInstructorAvatar(scene);
      }
    }
  }, [timespanId]);

  //HACK: handles timespan that ends with the video end
  useEffect(() => {
    const onChange = (
      scene: THREE.Scene,
      prevTimespanId: string | undefined,
      progressPercentage: number
    ) => {
      const prevIndex = timespans.findIndex((t) => t.id === prevTimespanId);
      if (prevTimespanId && prevIndex >= 0) {
        const prevTimespanName = getTimespanBarName(prevIndex);
        const prevTimespanBar = scene.getObjectByName(prevTimespanName);
        if (prevTimespanBar) {
          const prevPosition = prevTimespanBar.position;
          const prevRotation = prevTimespanBar.rotation;
          const star = rewardService.getStarModel(progressPercentage);

          const reward = rewardService.getReward(progressPercentage);
          onSaveReward(reward, prevTimespanId);
          if (star) {
            const starClone = star.clone();
            starClone.name = getTimespanRewardName(prevIndex);
            starClone.scale.set(0.0007, 0.0007, 0.0007);
            starClone.position.set(
              prevPosition.x + 0.15,
              prevPosition.y + wallHeight - 0.15,
              prevPosition.z
            );
            starClone.rotation.setFromQuaternion(
              new THREE.Quaternion().setFromEuler(prevRotation)
            );

            scene.add(starClone);
          }
        }
      }

      setTimespanAccuracy([]);
      setTimespanProgressPercentage(0);
    };

    if (isInstructorVideoEnded) {
      const hideInstructorAvatar = (scene: THREE.Scene) => {
        const model = scene.getObjectByName(instructorDisplayRootName);
        if (model) {
          model.traverse((object: any) => {
            if (object.isMesh) {
              object.material.visible = false;
            }
          });
        }
      };

      hideAccuracyLabel();
      const scene = sceneRef.current;
      if (scene) {
        if (timespanId !== undefined) {
          onChange(scene, timespanId, timespanProgressPercentage);
        }
        avatarFeedback.removeFeedback(scene);
        hideInstructorAvatar(scene);
      }
    }
  }, [isInstructorVideoEnded]);

  useEffect(() => {
    const onChange = async (imagePath: string | undefined) => {
      const instructorMaterial = instructorImageMaterialRef.current;
      const instructorTexture = instructorImageTextureRef.current;
      const scene = sceneRef.current;
      if (scene) {
        if (imagePath) {
          const newTexture = await getTextureFromPath(imagePath);
          const mesh = instructorImageMeshRef.current;
          if (instructorMaterial && mesh) {
            instructorMaterial.visible = true;
            instructorMaterial.map = newTexture;
            if (instructorTexture) {
              instructorTexture.dispose();
            }
            const aspectRatio =
              newTexture.image.width / newTexture.image.height;
            const width = wallHeight * aspectRatio;
            setSize(mesh, width, wallHeight);
          } else {
            const material = getMaterial(newTexture);
            const mesh = getCurrentInstructorPoseMesh(
              material,
              newTexture.image,
              wallHeight,
              halfWidth
            );
            scene.add(mesh);
            instructorImageMaterialRef.current = material;
            instructorImageMeshRef.current = mesh;
          }
          instructorImageTextureRef.current = newTexture;
        } else {
          if (instructorMaterial) {
            instructorMaterial.visible = false;
            if (instructorTexture) {
              instructorTexture.dispose();
            }
          }
        }
      }
    };

    onChange(instructorImageSrc);
  }, [instructorImageSrc]);

  useEffect(() => {
    const getCoordinates = (
      scene: THREE.Scene,
      keypoints: Keypoint3D[],
      name: string
    ) => {
      if (keypoints.length === 0) {
        return;
      }
      const root = scene.getObjectByName(name);
      if (!root) {
        return;
      }

      avatarService.poseUpdate(
        root,
        keypoints.map((kp) => {
          return {
            x: kp.x,
            y: kp.y,
            z: kp.z,
            part: kp.part,
            visibility: kp.visibility,
          };
        })
      );
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
      const coordinates = avatarFeedback.getCoordinates(root);
      return coordinates;
    };

    const processPoseTimespan = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      displayInstructorKeypoints: Keypoint3D[],
      live: LiveClassModel,
      regions: { [key: string]: boolean }
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorThresholdKeypoints = getCoordinates(
        scene,
        displayInstructorKeypoints,
        instructorRootName
      );

      if (instructorThresholdKeypoints && studentThresholdKeypoints) {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );
        if (alignedLowerInstructorKeypoints) {
          const filtered = alignedLowerInstructorKeypoints.filter(
            (kp) => avatarFeedback.lowerCoordinateJoints.indexOf(kp.part) !== -1
          );

          avatarFeedback.applyFeedback(
            scene,
            filtered,
            studentThresholdKeypoints,
            "mixamorigHips",
            avatarFeedback.lowerCoordinateJoints
          );
        }

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );
        if (alignedUpperInstructorKeypoints) {
          const filtered = alignedUpperInstructorKeypoints.filter(
            (kp) => avatarFeedback.upperCoordinateJoints.indexOf(kp.part) !== -1
          );

          avatarFeedback.applyFeedback(
            scene,
            filtered,
            studentThresholdKeypoints,
            "mixamorigNeck",
            avatarFeedback.feedbackJointsUpper
          );
        }

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          getJointsFromRegions(regions)
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        onPoseAccuracyCalculated(accuracyPercentage, live);

        if (timespanId && currentTime) {
          const newTimespanAccuracy = timespanAccuracy.map((i) => i);
          newTimespanAccuracy.push({
            percentage: accuracyPercentage,
            currentTime: currentTime,
          });
          setTimespanAccuracy(newTimespanAccuracy);

          const calculateProgressValue = (timespanAccuracy: any[]) => {
            const thresholdPercentage = 85;
            const fullProgressBarSeconds = 15;
            const percentageValue = fullProgressBarSeconds / 100;
            const durationSeconds = timespanAccuracy.reduce(
              (acc, curr, index) => {
                if (index > 0 && curr.percentage >= thresholdPercentage) {
                  const prevRecord = timespanAccuracy[index - 1];
                  const recordDurationSeconds =
                    curr.currentTime - prevRecord.currentTime;
                  acc = acc + recordDurationSeconds;
                }
                return acc;
              },
              0
            );
            return durationSeconds / percentageValue;
          };

          const progressValue = calculateProgressValue(newTimespanAccuracy);

          setTimespanProgressPercentage(progressValue);
        }

        updateAccuracyLabelValue(Math.round(accuracyPercentage));
      }

      if (
        !instructorThresholdKeypoints ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processExerciseTimespan = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      stepsKeypoints: Keypoint3D[][],
      nextExerciseStepIndex: number,
      prevExerciseAccuracy: { [key: number]: number },
      live: LiveClassModel,
      regions: { [key: string]: boolean }[]
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorStepThresholdKeypoints = stepsKeypoints.map((kp) => {
        return getCoordinates(scene, kp, instructorRootName);
      });

      const calculateAccuracy = (
        studentThresholdKeypoints: Keypoint3D[],
        instructorThresholdKeypoints: Keypoint3D[],
        accuracyJoints: string[]
      ) => {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          accuracyJoints
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        return {
          alignedLowerInstructorKeypoints,
          alignedUpperInstructorKeypoints,
          accuracyPercentage,
        };
      };

      if (
        studentThresholdKeypoints &&
        !instructorStepThresholdKeypoints.some((kp) => kp === undefined)
      ) {
        const accuracyData = instructorStepThresholdKeypoints.map(
          (kp, index) => {
            if (kp) {
              return calculateAccuracy(
                studentThresholdKeypoints,
                kp,
                getJointsFromRegions(regions[index])
              );
            }
          }
        );
        const accuracyPercentages = accuracyData.map((d) => {
          if (d === undefined) {
            return undefined;
          }
          return d.accuracyPercentage;
        });

        const accuracyObject = accuracyPercentages.reduce(
          (acc: any, curr: number | undefined, index: number) => {
            acc[index] = curr;
            return acc;
          },
          {}
        );

        const nextPoseAccuracyData = accuracyData[nextExerciseStepIndex];

        let currentRep = repsCounter;
        if (nextPoseAccuracyData) {
          const {
            alignedLowerInstructorKeypoints,
            alignedUpperInstructorKeypoints,
            accuracyPercentage,
          } = nextPoseAccuracyData;

          const isThresholdReached =
            prevExerciseAccuracy[nextExerciseStepIndex] >=
            exerciseSuccessThresholdPercentage;
          const isAccuracyDecreasing =
            prevExerciseAccuracy[nextExerciseStepIndex] > accuracyPercentage;
          const isAccuracyReached =
            accuracyPercentage === 100 ||
            (isThresholdReached && isAccuracyDecreasing);

          const isLastStepReached =
            nextExerciseStepIndex ===
              instructorStepThresholdKeypoints.length - 1 && isAccuracyReached;

          //TODO: remove after test
          updateAccuracyLabelValue(Math.round(accuracyPercentage));

          if (isLastStepReached) {
            currentRep = repsCounter + 1;
            setRepsCounter(currentRep);
            // updateAccuracyLabelValue(currentRep);
            onRepetition(currentRep.toString());
            if (timespan && timespan.exercise) {
              const percentage =
                (currentRep / timespan.exercise.repetitions) * 100;
              const percentageValue = percentage > 100 ? 100 : percentage;
              setTimespanProgressPercentage(percentageValue);
            }
            setNextExerciseStepIndex(0);
            onPlayBeep();
            setPrevExerciseAccuracy(accuracyObject);
          } else if (isAccuracyReached) {
            setNextExerciseStepIndex(nextExerciseStepIndex + 1);
            onPlayBeep();
            setPrevExerciseAccuracy(accuracyObject);
          } else {
            setPrevExerciseAccuracy(accuracyObject);
            if (alignedLowerInstructorKeypoints) {
              const filtered = alignedLowerInstructorKeypoints.filter(
                (kp) =>
                  avatarFeedback.lowerCoordinateJoints.indexOf(kp.part) !== -1
              );

              avatarFeedback.applyFeedback(
                scene,
                filtered,
                studentThresholdKeypoints,
                "mixamorigHips",
                avatarFeedback.lowerCoordinateJoints
              );
            }

            if (alignedUpperInstructorKeypoints) {
              const filtered = alignedUpperInstructorKeypoints.filter(
                (kp) =>
                  avatarFeedback.upperCoordinateJoints.indexOf(kp.part) !== -1
              );

              avatarFeedback.applyFeedback(
                scene,
                filtered,
                studentThresholdKeypoints,
                "mixamorigNeck",
                avatarFeedback.feedbackJointsUpper
              );
            }
          }
          //if (isAccuracyReached) {
          onExercisePeakReached(prevExerciseAccuracy, live, currentRep);
          //}
        }
      }

      if (
        instructorStepThresholdKeypoints.some((kp) => kp === undefined) ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processKeypoints = (
      live?: LiveClassModel,
      timespan?: CourseAnalysisTimespan
    ) => {
      const studentKeypoints = live ? live.studentKeypoints : [];
      let displayStudentKeypoints = !selfieMode
        ? mapKeypoints(studentKeypoints)
        : getFlippedDisplayKeypoints(mapKeypoints(studentKeypoints));
      displayStudentKeypoints = rotateAroundAxis(
        displayStudentKeypoints,
        new THREE.Vector3(0, 1, 0),
        -45
      ) as any[];

      const mount = mountRef.current;
      const scene = sceneRef.current;

      if (mount && scene && renderer && view) {
        //TODO: move to service
        const applyDisplayKeypoints = (
          scene: THREE.Scene,
          keypoints: Keypoint3D[],
          name: string
        ) => {
          const root = scene.getObjectByName(name);
          if (keypoints.length !== 0) {
            let displayKeypoints = keypoints.map(({ x, y, z, part }) => {
              return { x, y, z, part };
            });

            if (root) {
              avatarService.poseUpdate(root, displayKeypoints);
              const verticalShift = avatarService.getVerticalShift(root);
              avatarService.placeOnTheFloor(root, verticalShift);
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = true;
                }
              });
            }
          } else {
            if (root) {
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = false;
                }
              });
            }
          }
        };

        applyDisplayKeypoints(
          scene,
          displayStudentKeypoints,
          studentDisplayRootName
        );

        if (
          live &&
          live.isInstructorVideoEnded !== undefined &&
          live.isInstructorVideoEnded !== true &&
          live.isInstructorVideoPaused !== undefined &&
          live.isInstructorVideoPaused !== true
        ) {
          if (timespan && timespan.pose) {
            const displayInstructorKeypoints = mapKeypoints(
              timespan.pose.keypoints
            );
            applyDisplayKeypoints(
              scene,
              displayInstructorKeypoints,
              instructorDisplayRootName
            );
            processPoseTimespan(
              scene,
              displayStudentKeypoints,
              displayInstructorKeypoints,
              live,
              //HACK: bacuse of empty db records
              //TODO: replace with full regions selection?
              !timespan.pose.regions || Object.keys(timespan.pose.regions).length === 0 ?  defaultRegions : timespan.pose.regions,
            );
          } else if (timespan && timespan.exercise) {
            const sortedSteps = timespan.exercise.steps.sort((x) => x.order);
            const stepsDisplayKeypoints = sortedSteps.map((x) =>
              mapKeypoints(x.keypoints)
            );

            const stepsRegions = sortedSteps.map((x) => {
              if (x.regions) {
                return x.regions;
              }
              //HACK: bacuse of empty db records
              //TODO: replace with full regions selection?
              return {};
            });

            applyDisplayKeypoints(
              scene,
              stepsDisplayKeypoints[nextExerciseStepIndex],
              instructorDisplayRootName
            );

            processExerciseTimespan(
              scene,
              displayStudentKeypoints,
              stepsDisplayKeypoints,
              nextExerciseStepIndex,
              prevExerciseAccuracy,
              live,
              stepsRegions
            );
          }
        } else {
          avatarFeedback.removeFeedback(scene);
        }
      }
    };

    processKeypoints(live, timespan);
  }, [live]);

  useEffect(() => {
    const onChange = async (poseName: string | undefined) => {
      const scene = sceneRef.current;
      if (scene) {
        const textBackgroundMesh = poseNameBackgroundRef.current;
        if (textBackgroundMesh) {
          scene.remove(textBackgroundMesh);
          pose3dService.cleanupObject(textBackgroundMesh);
        }
        const textMesh = poseNameRef.current;
        if (textMesh) {
          scene.remove(textMesh);
          //TODO: check if text changes if not - skip to improve performance
          pose3dService.cleanupObject(textMesh);
        }
        const font = poseNameFontRef.current;
        if (poseName && font) {
          const text = getCurrentPoseLabel(poseName, font, halfWidth);

          const box = text.geometry.boundingBox;
          if (box) {
            const mesh = getTextBackground(box, text.position.z);
            poseNameBackgroundRef.current = mesh;
            scene.add(mesh);
          }
          scene.add(text);
          poseNameRef.current = text;
        }
      }
    };

    onChange(instructorPoseName);
  }, [instructorPoseName]);

  useEffect(() => {
    const setupInstructorVideoGeneralProgress = (
      currentTime: number,
      duration: number,
      scene: THREE.Scene
    ) => {
      const name = "ProgressBar";
      const element = scene.getObjectByName(name) as THREE.Mesh;
      if (element) {
        pose3dService.cleanupObject(element);
        scene.remove(element);
      }
      const instructorVideoProgressBar = getInstructorVideoGeneralProgressBar(
        duration,
        currentTime,
        progressBarFullWidth,
        progressBarHeight,
        halfWidth,
        name
      );
      scene.add(instructorVideoProgressBar);
    };

    const setupInstructorVideoProgressBar = (
      timespans: CourseAnalysisTimespan[],
      duration: number,
      currentTime: number,
      scene: THREE.Scene
    ) => {
      const pastTimespans = timespans.filter(
        (x) => x.start_seconds <= currentTime
      );

      const cleanupTimespanBars = (
        timespans: CourseAnalysisTimespan[],
        pastTimespans: CourseAnalysisTimespan[],
        scene: THREE.Scene
      ) => {
        const lastIndexValue = pastTimespans.length - 1;
        const lastIndex = lastIndexValue < 0 ? 0 : lastIndexValue;
        for (let index = lastIndex; index < timespans.length; index++) {
          const elementName = getTimespanBarName(index);
          const element = scene.getObjectByName(elementName) as THREE.Mesh;
          if (element) {
            pose3dService.cleanupObject(element);
            scene.remove(element);
          }
        }
      };
      cleanupTimespanBars(timespans, pastTimespans, scene);
      if (pastTimespans.length > 0) {
        const lastIndex = pastTimespans.length - 1;
        const timespan = pastTimespans[lastIndex];
        const name = getTimespanBarName(lastIndex);

        const instructorVideoPorgressBarNode =
          getInstructorVideoProgressBarNode(
            duration,
            currentTime,
            progressBarFullWidth,
            progressBarHeight,
            halfWidth,
            timespan,
            name
          );
        scene.add(instructorVideoPorgressBarNode);
      }
    };

    const scene = sceneRef.current;
    if (scene) {
      setupInstructorVideoProgressBar(
        timespans,
        instructorVideo.duration,
        currentTime,
        scene
      );
      setupInstructorVideoGeneralProgress(
        currentTime,
        instructorVideo.duration,
        scene
      );
    }
  }, [currentTime]);

  return (
    <div className={classes.root}>
      <div className={classes.canvas} ref={mountRef}></div>
      <IconButton
        className={classes.rotateLeft}
        onClick={() => rotateCamera(-15)}
        aria-label="rotate left"
      >
        <RotateRightIcon className={classes.iconButton} />
      </IconButton>
      <IconButton
        className={classes.rotateRight}
        onClick={() => rotateCamera(15)}
        aria-label="rotate right"
      >
        <RotateLeftIcon className={classes.iconButton} />
      </IconButton>
      {instructorImageBackgroundRef.current && poseNameFontRef.current && (
        <NextTimespanCountdown
          currentTime={currentTime}
          timespanId={timespanId}
          backgroundObject={instructorImageBackgroundRef.current}
          timespans={timespans}
          font={poseNameFontRef.current}
        />
      )}
      {instructorImageBackgroundRef.current && poseNameFontRef.current && (
        <TimespanCircularProgressBar
          backgroundObject={instructorImageBackgroundRef.current}
          timespanProgressPercentage={timespanProgressPercentage}
          timespan={timespan}
          repsCounter={repsCounter}
          isInstructorVideoEnded={isInstructorVideoEnded}
        />
      )}
    </div>
  );
};

export default LiveSkeletons;
