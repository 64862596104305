import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Book } from "@material-ui/icons";
import { secondsToTimeString } from "../../../../../utils/conversion.functions";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "black",
    borderRadius: 0,
    height: 200,
    width: "100%",
  },
  media: {
    height: 200,
    width: "100%",
    objectFit: "contain",
  },
}));

const VideoCard = (props) => {
  const { video, ...rest } = props;

  const onVideoClick = () => {
    history.push(`/sports-student/channel/video/${video.id}`);
  };
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box>
      <Card className={classes.card} onClick={onVideoClick} {...rest}>
        <CardActionArea>
          <img
            className={classes.media}
            alt="thumbnail"
            src={video.thumbnail_path}
            title="thumbnail"
          />
          <CardContent></CardContent>
        </CardActionArea>
      </Card>
      <Box style={{ marginTop: "15px" }}>
        <Typography
          gutterBottom
          style={{ marginLeft: "10px" }}
          variant="h3"
          component="h2"
        >
          {video.name}
        </Typography>
        <Typography
          gutterBottom
          color="textSecondary"
          style={{ marginLeft: "10px" }}
          variant="h6"
          component="h2"
        >
          <b>Added</b> {new Date(video.creation_date).toLocaleString()}
        </Typography>
        <Typography
          variant="h6"
          componenet="h2"
          color="textSecondary"
          style={{ marginLeft: "10px" }}
        >
          <b>Duration</b> {secondsToTimeString(video.duration)}{" "}
        </Typography>
      </Box>
    </Box>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoCard;
