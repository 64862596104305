import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { ChannelClassCard } from "./components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as channelClassPracticeProvider from "../../../../../providers/student/channel-class-practice.provider";
import { ChannelClassPracticeListItem } from "../../../../../providers/student/channel-class-practice.provider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

type Props = {
  channelId: string;
};

export default function ChannelClassPracticeList(props: Props) {
  const { channelId } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<Array<ChannelClassPracticeListItem>>(
    []
  );

  useEffect(() => {
    const getClasses = async () => {
      setLoading(true);
      try {
        const records = await channelClassPracticeProvider.getAllByChannel(
          channelId
        );
        setCourses(records);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses();
  }, []);

  return (
    <div className={classes.root}>
      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid style={{ marginTop: "30px" }} container spacing={3}>
            {courses &&
              courses.map((course) => (
                <Grid item key={course.id} lg={3} md={4} sm={6} xs={12}>
                  <ChannelClassCard course={course} />
                </Grid>
              ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
