import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as pictureProvider from "../../../providers/student/picture.provider";
import { PictureDetails } from "../../../providers/student/picture.provider";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    thumbnail: {
      maxWidth: "100%",
      objectFit: "contain",
    },
  });

type State = {
  picture?: PictureDetails;
};

type Props = RouteComponentProps<any> & {
  classes: any;
};

class VideoDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    if (id) {
      const picture = await pictureProvider.get(id);
      this.setState({
        picture: picture,
      });
    }
  };

  render = () => {
    const { picture } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {picture && <img src={picture.file_path} />}
      </div>
    );
  };
}

export default withStyles(styles)(VideoDetailsView);
