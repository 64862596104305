import React, { Component }  from 'react';
import './Editor.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Keypoint3D } from '../../../../../types/analyze/keypoint3d.type';
import NumberInput from '../../../../../components/editor/number.input';
import Pose3dEditor from '../../../../../components/editor/pose3d-editor';
import { AvatarType } from '../../../../../services/avatar/avatar-type.enum';

type State = { 
    disabled: boolean;
    keypoints: Array<Keypoint3D>;
};

type Props = RouteComponentProps<any> & {
    id: string,
    keypoints: Array<Keypoint3D>;
    name: string;
    thumbnail_path: string;
    onSave: (item: any) => Promise<any>
};

class Editor extends Component<Props, State> {
    constructor(props:Props){
        super(props);       
        this.state = {
            disabled: false,
            keypoints: props.keypoints
        }; 
    }

    handleXChange = (value: number, index: number): void => {
        //TODO: move to function for reusage
        this.setState((prevState: State) => {
            if(prevState.keypoints) {
                const keypoints = [...prevState.keypoints];
                keypoints[index].x = value;
                return { keypoints: keypoints };
            }
            else{
                return {
                    ...prevState
                }
            }
        });
    }

    handleYChange = (value: number, index: number): void => {
        this.setState((prevState: State) => {
            if(prevState.keypoints) {
                const keypoints = [...prevState.keypoints];
                keypoints[index].y = value;
                return { keypoints: keypoints };
            }
            else{
                return {
                    ...prevState
                }
            }
        });
    }

    handleZChange = (value: number, index: number): void => {
        this.setState((prevState: State) => {
            if(prevState.keypoints) {
                const keypoints = [...prevState.keypoints];
                keypoints[index].z = value;
                return { keypoints: keypoints };
            }
            else{
                return {
                    ...prevState
                }
            }
        });
    }

    handleSaveChanges = async (): Promise<void> => {
        this.setState({
            disabled: true
        });
        
        try{
            const keypoints = this.state.keypoints.map(kp => [kp.x, kp.y, kp.z])
            await this.props.onSave({ id: this.props.id, keypoints: keypoints });
        }
        catch{
            this.setState({
                disabled: false
            });
        }
    }

    render = () => {
        const { 
            keypoints,
            disabled
        } = this.state;
        const { 
            name,           
            thumbnail_path
        } = this.props;

        return (
            <div className='editor-view' >        
                { 
                    <div className='editor-container'>
                        <img  className='editor-image' src={ thumbnail_path } />
                        <Pose3dEditor height={700} width={700} keypoints={keypoints} avatarType={AvatarType.Female} />  
                        <div className='editor-sidebar'>         
                            <span>Name: {name}</span>        
                            <div className='editor-keypoints-container'>      
                                <div className='editor-keypoint'>
                                    <span className='editor-keypoint-part-header'>Part</span>           
                                    <span className='editor-keypoint-coordinate-header'>X</span>  
                                    <span className='editor-keypoint-coordinate-header'>Y</span>
                                    <span className='editor-keypoint-coordinate-header'>Z</span>  
                                </div>                    
                                {
                                    keypoints.map(({ part, x, y, z }, index)=> 
                                        (<div className='editor-keypoint' key={index}>
                                            <span className='editor-keypoint-part-label'>{part}</span>           
                                            <NumberInput value={x} onChangeValue={this.handleXChange} index={index} />
                                            <NumberInput value={y} onChangeValue={this.handleYChange} index={index} />
                                            <NumberInput value={z} onChangeValue={this.handleZChange} index={index} />
                                        </div>)
                                    )
                                }
                            </div>
                            <Button  disabled={disabled} onClick={this.handleSaveChanges} variant="contained" color="primary">
                                Save
                            </Button>
                        </div>                       
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(Editor);