import React, { useState, useEffect } from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@material-ui/core";

import BreadcrumbsContainer from "../../../../components/BreadcrumbsContainer";
import { ChannelVideoItem } from "../../../../providers/instructor/channel-video.provider";
import {
  AlternativeTextPreview,
  ExerciseSelection,
  PoseSelection,
  VideoSelection,
} from "./components";
import * as channelClassBlockProvider from "../../../../providers/instructor/channel-class-block.provider";
import * as channelVideoProvider from "../../../../providers/instructor/channel-video.provider";
import validate from "validate.js";
import { ChannelExerciseListItem } from "../../../../providers/instructor/channel-exercise.provider";
import { ChannelPoseOrSkillListItem } from "../../../../providers/instructor/channel-pose.provider";
import * as channelPoseOrSkillProvider from "../../../../providers/instructor/channel-pose.provider";
import * as channelExerciseProvider from "../../../../providers/instructor/channel-exercise.provider";
const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
}));

type Props = RouteComponentProps<any> & {
  breadcrumbs: any[];
  title: string;
};

type Params = {
  blockId: string;
  channelId: string;
};

export default function CreateItemView(props: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [alternativeText, setAlternativeText] = useState<string>("");
  const [videos, setVideos] = useState<ChannelVideoItem[]>([]);
  const [explanationVideo, setExplanationVideo] = useState<ChannelVideoItem>();
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});
  const history = useHistory();

  const { blockId, channelId } = useParams<Params>();

  const [poses, setPoses] = useState<ChannelPoseOrSkillListItem[]>();
  const [pose, setPose] = useState<ChannelPoseOrSkillListItem>();
  const [exercises, setExercises] = useState<ChannelExerciseListItem[]>();
  const [exercise, setExercise] = useState<ChannelExerciseListItem>();
  const [practiceVideo, setPracticeVideo] = useState<ChannelVideoItem>();

  useEffect(() => {
    const loadData = async (channelId: string) => {
      try {
        const results = await Promise.all([
          channelClassBlockProvider.get(blockId),
          channelVideoProvider.getByChannel(channelId),
        ]);
        const {
          name,
          explanation_video_id,
          alternative_text,
          exercise_id,
          pose_id,
          practice_video_id,
        } = results[0];

        const videos = results[1];
        setVideos(videos);

        setPracticeVideo(videos.find((x) => x.id === practice_video_id));

        if (pose_id) {
          const poses = await channelPoseOrSkillProvider.getAll(channelId);
          setPoses(poses);
          setPose(poses.find((x) => x.id === pose_id));
        }
        if (exercise_id) {
          const exercises = await channelExerciseProvider.getAll(channelId);
          setExercises(exercises);
          setExercise(exercises.find((x) => x.id === exercise_id));
        }

        setName(name);
        setExplanationVideo(videos.find((x) => x.id === explanation_video_id));
        setAlternativeText(
          alternative_text === null || alternative_text === undefined
            ? ""
            : alternative_text
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    loadData(channelId);
  }, []);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
  };

  const handleAlternativeTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //TODO: cut off last row
    //TODO: add preview of text on the wall
    setAlternativeText(event.target.value as string);
  };

  const handleExplanationVideoChange = (id: string) => {
    const video = videos.find((x) => x.id === id);
    if (video) {
      setExplanationVideo(video);
    }
    setSubmitted({
      ...submitted,
      explanationVideo: true,
    });
  };

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      explanationVideo: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const formData = {
      name: name,
      explanationVideo: explanationVideo ? explanationVideo.id : undefined,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({});
      return;
    }

    setLoading(true);
    if (explanationVideo) {
      const item = {
        id: blockId,
        name: formData.name,
        explanation_video_id: formData.explanationVideo,
        alternative_text: alternativeText,
      };
      try {
        await channelClassBlockProvider.edit(item);
        history.goBack();
      } catch (err) {
        alert("An error occurred while processing your request");
      }
    }
    setLoading(false);
  };

  const hasError = (field: string) =>
    submitted[field] && errors[field] ? true : false;

  const data = channelId ? { channelId: channelId } : undefined;
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      {loading && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
            />
          </Grid>
          <VideoSelection
            videos={videos}
            video={explanationVideo}
            inputLabel={"Explanation Video"}
            onVideoIdChange={handleExplanationVideoChange}
            errorMessage={
              hasError("explanationVideo")
                ? errors["explanationVideo"][0]
                : null
            }
            disabled={false}
          />
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Alternative Text"
              value={alternativeText}
              onChange={handleAlternativeTextChange}
              multiline
              rows="4"
            />
          </Grid>
          {alternativeText !== undefined && (
            <Grid item xs={12}>
              <AlternativeTextPreview text={alternativeText} />
            </Grid>
          )}
          <VideoSelection
            videos={videos}
            video={practiceVideo}
            inputLabel={"Practice Video"}
            onVideoIdChange={() => {}}
            errorMessage={
              hasError("practiceVideo") ? errors["practiceVideo"][0] : null
            }
            disabled={true}
          />
          {poses && pose && (
            <PoseSelection
              poses={poses}
              pose={pose}
              onPoseSelected={() => {}}
              errorMessage={hasError("pose") ? errors["pose"][0] : null}
              disabled={true}
            />
          )}
          {exercises && exercise && (
            <ExerciseSelection
              exercises={exercises}
              exercise={exercise}
              onExerciseSelected={() => {}}
              errorMessage={hasError("exercise") ? errors["exercise"][0] : null}
              disabled={true}
            />
          )}
          <Grid className={classes.buttonsContainer} item xs={12}>
            <Button
              disabled={loading}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={loading}
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}
