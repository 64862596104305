import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Activity } from '../../../../../providers/common/activity.provider';

const useStyles = makeStyles(theme => ({
  container: {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'visible',
  },
  slide: {
    backgroundColor: 'white',
    display: 'inline-block',
    //NOTE: 16x9
    width: '196px',
    height: '110px',  
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    border: '2px solid lightgray',
    marginLeft: '1px',
    marginRight: '1px',
    cursor: 'pointer',
    textAlign: 'center',
    position: 'relative'
  },
  textContainer: { 
    textAlign: 'center',
    position: 'absolute',
    bottom: 0
  },
  text: {
    color: 'white !important',
    fontSize: 18,
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
  }
}));

type Props = {
  activities: Array<Activity>
}

const ChannelCreateSlider = (props: Props) => {
  const { activities } = props;
  const history = useHistory();
  const classes = useStyles();
  const goToCreate = (id: string) => {
      history.push(`/sports-instructor/channel/create/${id}`);
  }
  return (
    <div className={classes.container}>
        {
          activities && activities.map(({ id, name, thumbnail_path }) => (
            <div className={classes.slide} onClick={() => goToCreate(id)} style={{ backgroundImage: `url(${ thumbnail_path })`}} key={id}>
                <div className={classes.textContainer}>
                  <p className={classes.text}>Create</p>
                  <p className={classes.text}>{name}</p>
                  <p className={classes.text}>channel</p>
                </div>
            </div>
          ))
        } 
    </div>
);
};

ChannelCreateSlider.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ChannelCreateSlider;