export function secondsToTimeString(durationSeconds: number) {
  const hourDuration = 60 * 60;
  const hours = Math.floor(durationSeconds / hourDuration);
  const minutes = Math.floor((durationSeconds % hourDuration) / 60);
  const seconds = durationSeconds - hours * hourDuration - minutes * 60;

  const getString = (number: number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  const hoursStr = getString(hours);
  const minutesStr = getString(minutes);
  const secondsRounded = seconds.toFixed(2);
  const secondsStr = seconds < 10 ? `0${secondsRounded}` : `${secondsRounded}`;
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function secondsToTimeRoundedString(durationSeconds: number) {
  const hourDuration = 60 * 60;
  const hours = Math.floor(durationSeconds / hourDuration);
  const minutes = Math.floor((durationSeconds % hourDuration) / 60);
  const seconds = durationSeconds - hours * hourDuration - minutes * 60;

  const getString = (number: number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  const hoursStr = getString(hours);
  const minutesStr = getString(minutes);
  const secondsRounded = seconds.toFixed(0);
  const secondsStr =
    parseInt(secondsRounded) < 10 ? `0${secondsRounded}` : `${secondsRounded}`;
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function secondsToShortTimeRoundedString(durationSeconds: number) {
  const hourDuration = 60 * 60;
  const hours = Math.floor(durationSeconds / hourDuration);
  const minutes = Math.floor((durationSeconds % hourDuration) / 60);
  const seconds = durationSeconds - hours * hourDuration - minutes * 60;

  const getString = (number: number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };
  const minutesStr = getString(minutes);
  const secondsRounded = seconds.toFixed(0);
  const secondsStr =
    parseInt(secondsRounded) < 10 ? `0${secondsRounded}` : `${secondsRounded}`;
  return `${minutesStr}:${secondsStr}`;
}

export const round = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const mapJointsAccuracy = (jointsAccuracy: any) => {
  const mapped: any = {};
  const keys = Object.keys(jointsAccuracy);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const mappedKey = key.replace("mixamorig", "");
    mapped[mappedKey] = round(jointsAccuracy[key]);
  }

  return mapped;
};
