import React, { FC, useEffect, useRef, useState } from "react";
import { CourseAnalysisTimespan } from "../../../../../types/class/class-analysis-timespan";
import * as THREE from "three";

type Props = {
  currentTime: number;
  timespanId?: string;
  backgroundObject: THREE.Mesh;
  timespans: CourseAnalysisTimespan[];
  font: THREE.Font;
};

const countdownObjectName = "TimespanCountdown";
const nameObjectName = "TimespanName";
const defaultTextObjectName = "DefaultText";

const NextTimespanCountdown: FC<Props> = (props) => {
  const { currentTime, timespanId, timespans, backgroundObject, font } = props;

  const getTextGeometry = (shapes: THREE.Shape[]): THREE.ShapeGeometry => {
    const geometry = new THREE.ShapeGeometry(shapes);
    geometry.computeBoundingBox();
    if (geometry.boundingBox) {
      const xMid =
        -(geometry.boundingBox.max.x - geometry.boundingBox.min.x) / 2;

      geometry.translate(xMid, 0, 0);
    }
    return geometry;
  };

  const [isTimespanCountdownEnabled, setIsTimespanCountdownEnabled] =
    useState<boolean>(false);
    
  const defaultTextMeshRef = useRef<THREE.Mesh>();

  const cleanupNameObject = () => {
    const nameObject = backgroundObject.getObjectByName(nameObjectName);
    if (nameObject) {
      backgroundObject.remove(nameObject);
    }
  };

  const showDefaultText = () => {
    const mesh = defaultTextMeshRef.current;
    if (mesh) {
      const material = mesh.material as THREE.Material;
      material.visible = true;
    }
  };

  const hideDefaultText = () => {
    const mesh = defaultTextMeshRef.current;
    if (mesh) {
      const material = mesh.material as THREE.Material;
      material.visible = false;
    }
  };

  useEffect(() => {
    const addDefaultTextMesh = (font: THREE.Font, parentObject: THREE.Mesh) => {
      const text = "is next, starts in";
      const shapes = font.generateShapes(text, 0.15);
      const geometry = getTextGeometry(shapes);
      const matDark = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
        visible: false
      });

      const mesh = new THREE.Mesh(geometry, matDark);
      mesh.position.set(0, 0.15, 0.5);
      mesh.name = defaultTextObjectName;
      parentObject.add(mesh);
      defaultTextMeshRef.current = mesh;
    };
    addDefaultTextMesh(font, backgroundObject);
  }, []);

  useEffect(() => {
    const initiateCountdown = (
      font: THREE.Font,
      timespans: CourseAnalysisTimespan[],
      currentTime: number
    ) => {
      const nextTimespan = timespans.find((x) => x.start_seconds > currentTime);
      if (nextTimespan && font) {
        const checkIfDisplayConditionMet = (
          timespanStartSeconds: number,
          currentTime: number
        ) => {
          const diff = timespanStartSeconds - currentTime;
          const roundedDiff = Math.round(diff);
          const minSecondsForCountdown = 2;
          const shouldDisplay = roundedDiff > minSecondsForCountdown;
          return shouldDisplay;
        };

        if (
          checkIfDisplayConditionMet(nextTimespan.start_seconds, currentTime)
        ) {
          const getNameMesh = (text: string, font: THREE.Font) => {
            const shapes = font.generateShapes(text, 0.25);
            const geometry = getTextGeometry(shapes);
            const matDark = new THREE.MeshBasicMaterial({
              color: 0xffffff,
              side: THREE.DoubleSide,
            });

            const mesh = new THREE.Mesh(geometry, matDark);
            mesh.position.set(0, 0.35, 0.5);
            mesh.name = nameObjectName;
            return mesh;
          };

          const getTimespanName = (
            timespan: CourseAnalysisTimespan | undefined
          ) => {
            if (!timespan) {
              return undefined;
            }
            if (timespan.pose) {
              return timespan.pose.name;
            }
            if (timespan.exercise) {
              return `${timespan.exercise.name}(${timespan.exercise.repetitions})`;
            }
          };

          const name = getTimespanName(nextTimespan);
          cleanupNameObject();
          if (name) {
            const newNameMesh = getNameMesh(name, font);
            backgroundObject.add(newNameMesh);
          }
          showDefaultText();
          setIsTimespanCountdownEnabled(true);
        }
      }
    };

    if (!timespanId) {
      initiateCountdown(font, timespans, currentTime);
    }
  }, [timespanId]);

  useEffect(() => {
    const cleanupCountdownObject = () => {
      const countdownObject =
        backgroundObject.getObjectByName(countdownObjectName);
      if (countdownObject) {
        backgroundObject.remove(countdownObject);
      }
    };

    cleanupCountdownObject();
    if (isTimespanCountdownEnabled) {
      const getCountdownMesh = (text: string, font: THREE.Font) => {
        const shapes = font.generateShapes(text, 0.5);
        const geometry = getTextGeometry(shapes);
        const matDark = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
        });

        const mesh = new THREE.Mesh(geometry, matDark);
        mesh.position.set(0, -0.45, 0.5);
        mesh.name = countdownObjectName;
        return mesh;
      };

      const nextTimespan = timespans.find((x) => x.start_seconds > currentTime);
      if (nextTimespan) {
        const diff = Math.round(nextTimespan.start_seconds - currentTime);
        if (diff > 0) {
          const countdownMesh = getCountdownMesh(diff.toString(), font);
          backgroundObject.add(countdownMesh);
        }

        if (diff === 0) {
          setIsTimespanCountdownEnabled(false);
          cleanupNameObject();
          hideDefaultText();
        }
      }
    }
  }, [currentTime]);

  return <div></div>;
};

export default NextTimespanCountdown;
