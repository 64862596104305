import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
}));

type Props = {
  clickHandler: () => void;
};

export default function SoundControl(props: Props) {
  const { clickHandler } = props;
  const classes = useStyles();
  return (
    <IconButton
      aria-label={"Skip Next"}
      onClick={clickHandler}
      className={classes.button}
    >
      <NavigateNextIcon fontSize="inherit" />
    </IconButton>
  );
}
