import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import AwardStars from "../../assets/award.fbx";
import { RewardType } from "../../enums/reward-type.enum";

import GoldBadgeImage from "../../assets/images/gold.png";
import SilverBadgeImage from "../../assets/images/silver.png";
import BronzeBadgeImage from "../../assets/images/bronze.png";

let rewardFBX: THREE.Group | undefined = undefined;

const goldName = "STAR";
const silverName = "STAR001";
const bronzeName = "STAR002";

export const loadStarModel = async () => {
  if (!rewardFBX) {
    const loader = new FBXLoader();
    const fbx = await loader.loadAsync(AwardStars);
    rewardFBX = fbx;
  }
};

export const getStarModel = (percentage: number) => {
  if (rewardFBX) {
    if (percentage > 66.6) {
      return rewardFBX.getObjectByName(goldName);
    }
    if (percentage > 33.3) {
      return rewardFBX.getObjectByName(silverName);
    }

    return rewardFBX.getObjectByName(bronzeName);
  }
};

export const getReward = (percentage: number) => {
  if (percentage > 66.6) {
    return RewardType.Gold;
  }
  if (percentage > 33.3) {
    return RewardType.Silver;
  }

  return RewardType.Bronze;
};

export const getRewardColor = (percentage: number) => {
  if (percentage > 66.6) {
    return "#d4af37";
  }
  if (percentage > 33.3) {
    return "#c0c0c0";
  }

  return "#cd7f32";
};

export const getRewardName = (percentage: number) => {
  if (percentage > 66.6) {
    return "Gold Medal";
  }
  if (percentage > 33.3) {
    return "Silver Medal";
  }

  return "Bronze Medal";
};

export const getRewardImage = (reward: RewardType) => {
  if (RewardType.Gold === reward) {
    return GoldBadgeImage;
  }
  if (RewardType.Silver === reward) {
    return SilverBadgeImage;
  }
  if (RewardType.Bronze === reward) {
    return BronzeBadgeImage;
  }
};

export const getBadgeImage = (reward: RewardType) => {
  if (RewardType.Gold === reward) {
    return GoldBadgeImage;
  }
  if (RewardType.Silver === reward) {
    return SilverBadgeImage;
  }
  if (RewardType.Bronze === reward) {
    return BronzeBadgeImage;
  }
};
