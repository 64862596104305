import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import defaultThumbnail from "../../../../../assets/images/channel-thumbnail.png";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
    position: "relative",
  },
  card: {
    position: "relative",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  publishIcon: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  publishedIcon: {
    color: "green",
  },
  unpublishedIcon: {
    color: "red",
  },
}));

const ChannelCard = (props) => {
  const { className, channel, onRemove, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const onOverview = (id) => {
    history.push(`/sports-instructor/channel/${id}`);
  };

  const onEdit = (id) => {
    history.push(`/sports-instructor/channel/${id}/edit`);
  };

  return (
    <Card {...rest} className={classes.card}>
      <CardActionArea onClick={() => onOverview(channel.id)}>
        <CardMedia
          style={{
            backgroundSize: "contain",
          }}
          className={classes.media}
          image={channel.thumbnail_path || defaultThumbnail}
          title={channel.name}
        >
          {channel.is_published ? (
            <CheckBoxIcon
              title="Published"
              className={`${classes.publishIcon} ${classes.publishedIcon}`}
            />
          ) : (
            <IndeterminateCheckBoxIcon
              title="Not published"
              className={`${classes.publishIcon} ${classes.unpublishedIcon}`}
            />
          )}
        </CardMedia>

        <CardContent>
          <Typography
            className={classes.name}
            title={channel.name}
            gutterBottom
            variant="h3"
            component="h2"
          >
            {channel.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Videos ({channel.video_count}) Poses ({channel.pose_count}) Classes
            ({channel.class_count}) Exercises ({channel.exercise_count})
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => onOverview(channel.id)}
        >
          Overview
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onEdit(channel.id)}
        >
          Edit
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onRemove(channel.id)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

ChannelCard.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ChannelCard;
