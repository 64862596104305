import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CardMedia,
  CardContent,
  CardActionArea,
  Card,
  CardActions,
  TextField,
  Button,
  Select, MenuItem, Box
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  media: {
    height: 200,
  },
  autocomplete: {
    minWidth: 200
  },
  analyzeButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  }
}));

const VideoCard = props => {
  const { channels, video, selectedChannelId, setSelectedChannelId, ...rest } = props;  
  const channelValue = selectedChannelId && channels.length > 0 ? channels.find(x=> x.id === selectedChannelId) : undefined;
  const classes = useStyles();
  const disableAnalysis = channelValue === undefined;
  const history = useHistory();
  const [channelSelected, setChannelSelected] = useState(0)

  const onAnalyze = () => {
    // history.push(`/sports-student/analyze-video?id=${selectedChannelId}&videoId=${video.id}`);
    history.push(`/sports-student/analyze-video/${selectedChannelId}/${video.id}`);
  }

  const onChannelSelected = (pos, channelId) => {
    setChannelSelected(pos)
    setSelectedChannelId(channelId)
  }

  return (
    <Card
      {...rest}
      className={classes.card}>
      <CardActionArea
        onClick={()=>{
          history.push(`/sports-student/video/details?id=${video.id}`)
        }}
        >
        <CardMedia
          className={classes.media}
          image={video.thumbnail_path}
          title="thumbnail"
        />     
        <CardContent>
          <Typography color="textSecondary" variant="h6" component="h2">
            <b>Uploaded: </b>{(new Date(video.creation_date)).toLocaleString()}
          </Typography>          
        </CardContent>
      </CardActionArea>
      <Typography variant="h6" style={{paddingLeft: "16px"}}><b>Select Channel</b></Typography>
      <CardActions style={{position: "relative"}}>
        <Select className={classes.autocomplete} style={{height: '35px'}} variant='outlined'  labelId="label" id="select" value={channelSelected}>
          {
            channels.map( (channel, pos) =>
              <MenuItem value={pos} onClick={() => onChannelSelected(pos, channel.id)}>{channel.name}</MenuItem>
            )
          }
        </Select>
        {/*<Autocomplete*/}
        {/*  options={channels}*/}
        {/*  getOptionLabel={(option) => option.name}*/}
        {/*  getOptionSelected={(option, value) => value && option.id === value.Id}*/}
        {/*  className={classes.autocomplete}*/}
        {/*  value={channelValue}*/}
        {/*  disabled={disableAnalysis}*/}
        {/*  disableClearable={true}*/}
        {/*  onChange={(event, newValue) => {*/}
        {/*    setSelectedChannelId(newValue.id);*/}
        {/*  }}*/}
        {/*  renderInput={params => (*/}
        {/*    <TextField {...params} label="Select Channel" margin="normal" fullWidth />*/}
        {/*  )}*/}
        {/*/>*/}
        <Button size="small" color="primary" className={classes.analyzeButton} disabled={disableAnalysis} onClick={() => onAnalyze()}>
          Analyze Video
        </Button>
      </CardActions>
    </Card>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  channels: PropTypes.array.isRequired,
  selectedChannelId: PropTypes.string,
  setSelectedChannelId: PropTypes.func.isRequired
};

export default VideoCard;