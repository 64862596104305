import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import * as pose3dService from "../../services/pose3d.service";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as unityService from "../../services/avatar/avatar.service";
import { AvatarType } from "../../services/avatar/avatar-type.enum";
import { mapKeypoints } from "../../services/keypoint.helper";

type Props = {
  keypoints: Array<Keypoint3D>;
  width: number;
  height: number;
  avatarType: AvatarType;
};

export default function Pose3dEditor(props: Props) {
  const { keypoints, avatarType, height, width } = props;

  const mountRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer>();
  const sceneRef = useRef<THREE.Scene>();
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const controlsRef = useRef<OrbitControls>();

  useEffect(() => {
    const init = async () => {
      const mount = mountRef.current;
      if (!mount) {
        return;
      }
      const renderer = pose3dService.getRenderer(mount);
      rendererRef.current = renderer;
      renderer.setClearColor(new THREE.Color(0.5, 0.5, 0.7));

      const scene = pose3dService.sceneSetup();
      sceneRef.current = scene;
      const model = await unityService.getModel(avatarType);

      scene.add(model);
      unityService.init(model);

      const displayStudentKeypoints = mapKeypoints(keypoints);
      unityService.poseUpdate(scene, displayStudentKeypoints);
      const root = unityService.getModelRoot(scene);
      if(root){
        const verticalShift = unityService.getVerticalShift(root);
        unityService.placeOnTheFloor(root, verticalShift);
      }

      const camera = new THREE.PerspectiveCamera(
        50,
        mount.clientHeight / mount.clientHeight,
        1,
        10
      );
      cameraRef.current = camera;
      camera.aspect = mount.clientWidth / mount.clientHeight;
      camera.position.fromArray([0, 3.6, -3.2]);
      camera.updateProjectionMatrix();

      renderThree(scene, renderer, camera);
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.maxPolarAngle = Math.PI * 0.4;
      controls.minPolarAngle = Math.PI * 0.4;
      controls.enableZoom = false;
      controls.enablePan = false;

      controlsRef.current = controls;
      animate();
    };

    init();
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    const renderer = rendererRef.current;
    const camera = cameraRef.current;
    if (mount && scene && renderer && camera) {
      const displayStudentKeypoints = mapKeypoints(keypoints);
      unityService.poseUpdate(scene, displayStudentKeypoints);
      const root = unityService.getModelRoot(scene);
      if (root) {
        const verticalShift = unityService.getVerticalShift(root);
        unityService.placeOnTheFloor(root, verticalShift);
      }
      renderThree(scene, renderer, camera);
    }
  }, [keypoints]);  

  const animate = () => {
    requestAnimationFrame(animate);

    const controls = controlsRef.current;
    if (controls) {
      controls.update();
    }

    const scene = sceneRef.current;
    const renderer = rendererRef.current;
    const camera = cameraRef.current;
    if (scene && renderer && camera) {
      renderThree(scene, renderer, camera);
    }
  };

  const renderThree = (
    scene: THREE.Scene,
    renderer: THREE.WebGLRenderer,
    camera: THREE.PerspectiveCamera
  ): void => {
    renderer.render(scene, camera);
  };

  return <div style={{ width: width, height: height }} ref={mountRef} />;
}