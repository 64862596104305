import React from "react";

import { Route, Redirect, useLocation } from "react-router-dom";
import * as authService from "../../services/auth.service";

const ProtectedRoute = (props) => {
  const { component: Component, layout: Layout, ...rest } = props;
  const isAuthenticated = authService.isAuthenticated();
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { redirectUrl: location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
