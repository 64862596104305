import beepSound from "../assets/audio/drum.mp3";

const audio = new Audio(beepSound);
audio.volume = 1;

export const beep = async (): Promise<void> => {
  audio.pause();
  audio.currentTime = 0;
  await audio.play();
};
