import React, { Component } from "react";
import * as channelProvider from "../../../providers/instructor/channel.provider";
import * as activityProvider from "../../../providers/common/activity.provider";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Select,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import { Activity } from "../../../providers/common/activity.provider";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import NotImplementedComponent from "../../../components/NotImplemented";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import validate from "validate.js";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    thumbnail: {
      maxWidth: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      color: "#e53935",
      marginLeft: 14,
      marginRight: 14,
      fontSize: 11,
      marginTop: 3,
      textAlign: "left",
      
      fontWeight: 400,
      lineheight: 13,
      letterSpacing: 0.33,
    },
  });

type State = {
  disabled: boolean;
  activityId?: string;
  name?: string;
  description: string;
  activities: Array<Activity>;
  isContainerInitialized: boolean;
  thumbnail?: File;

  crop: Crop;
  imageUrl: string | null;
  croppedImageUrl?: string;
  isNotImplemented: boolean;
  selectedActivityName?: string;

  errors: any;
  submitted: any;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

const implementedActivities = ["Yoga", "Workout"];

class ChannelCreate extends Component<Props, State> {
  private imageRef?: HTMLImageElement;
  private fileUrl?: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      activities: [],
      isContainerInitialized: false,
      description: "",
      crop: {
        unit: "%",
        width: 30,
        aspect: 16 / 9,
      },
      imageUrl: null,
      isNotImplemented: true,
      errors: {},
      submitted: {},
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  componentDidMount = async () => {
    try {
      const activities = await activityProvider.getAll();
      const sortedActivities = activities.reverse().sort((a, b) => {
        //HACK: temporary hack for yoga, should be removed
        if (
          implementedActivities.indexOf(a.name) > -1 &&
          implementedActivities.indexOf(b.name) === -1
        ) {
          return -1;
        } else if (
          implementedActivities.indexOf(b.name) > -1 &&
          implementedActivities.indexOf(a.name) === -1
        ) {
          return 1;
        } else if (
          implementedActivities.indexOf(b.name) > -1 &&
          implementedActivities.indexOf(a.name) > -1
        ) {
          return 0;
        }
        return 0;
      });
      const { activityId } = this.props.match.params;
      const getActivityId = (
        id: string | null,
        activities: Array<Activity>
      ): string | undefined => {
        if (id) {
          return id;
        }
        return activities && activities.length > 0
          ? activities[0].id
          : undefined;
      };
      const selectedActivityId = getActivityId(activityId, sortedActivities);
      const selectedActivity = activities.find(
        (a) => a.id === selectedActivityId
      );
      const selectedActivityName = selectedActivity
        ? selectedActivity.name
        : "";
      const isNotImplemented =
        implementedActivities.indexOf(selectedActivityName) === -1;
      this.setState({
        activities: sortedActivities,
        activityId: selectedActivityId,
        isContainerInitialized: true,
        isNotImplemented: isNotImplemented,
        selectedActivityName: selectedActivityName,
      });
    } catch (err) {
      //TODO: handle initialization error
      console.log("componentDidMount:err");
      console.log(err);
    }
  };

  onSave = async () => {
    const { activityId, name, description, thumbnail } =
      this.state;

    const formData = {
      name: name,
      thumbnail: thumbnail,
    };

    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      thumbnail: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const errors = validate(formData, schema);
    if (errors) {
      this.setState({
        errors: errors,
        submitted: {
          name: true,
          thumbnail: true,
        },
      });
      return;
    }

    this.setState({
      disabled: true,
    });

    try {
      const model = {
        activityId,
        name,
        description,
        thumbnail: thumbnail,
      };
      await channelProvider.create(model);
      this.goToChannels();
    } catch (err) {
      //TODO: handle error
      this.setState({
        disabled: false,
      });
    }
  };

  goToChannels = () => {
    this.props.history.goBack();
  };

  handleActivityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const activityId = event.target.value as string;
    const selectedActivity = this.state.activities.find(
      (a) => a.id === activityId
    );
    const selectedActivityName = selectedActivity ? selectedActivity.name : "";
    const isNotImplemented =
      implementedActivities.indexOf(selectedActivityName) === -1;

    this.setState({
      activityId: activityId,
      isNotImplemented: isNotImplemented,
      selectedActivityName: selectedActivityName,
    });
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      name: event.target.value as string,
      submitted: { ...prevState.submitted, name: false },
    }));
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      description: event.target.value as string,
    });
  };

  handleImageChange = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ imageUrl: reader.result as string })
      );
      reader.readAsDataURL(file);
    }
  };

  onImageLoaded = (image: HTMLImageElement) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: Crop) => {
    this.makeClientCrop(crop);
  };

  async makeClientCrop(crop: Crop) {
    if (this.imageRef && crop.width && crop.height) {
      const blob = await this.getCroppedImg(this.imageRef, crop);

      if (this.fileUrl) {
        window.URL.revokeObjectURL(this.fileUrl);
      }
      this.fileUrl = window.URL.createObjectURL(blob);
      const thumbnail = new File([blob], "thumbnail.jpg", {
        type: "image/jpeg",
      });

      this.setState((prevState) => ({
        croppedImageUrl: this.fileUrl,
        thumbnail: thumbnail,
        submitted: { ...prevState.submitted, thumbnail: false },
      }));
    }
  }

  getCroppedImg(image: HTMLImageElement, crop: Crop): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      if (
        crop.x !== undefined &&
        crop.y !== undefined &&
        crop.width &&
        crop.height
      ) {
        canvas.width = 1280;
        canvas.height = 756;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
          );
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
            } else {
              resolve(blob);
            }
          }, "image/jpeg");
        } else {
          reject(new Error("Ctx is empty"));
        }
      } else {
        reject(new Error());
      }
    });
  }

  onCropChange = (crop: Crop) => {
    this.setState({ crop });
  };

  hasError = (field: string) => {
    const { submitted, errors } = this.state;
    return submitted[field] && errors[field] ? true : false;
  };

  render = () => {
    const {
      isContainerInitialized,
      name,
      description,
      disabled,
      activities,
      activityId,
      crop,
      imageUrl,
      croppedImageUrl,
      isNotImplemented,
      selectedActivityName,
      errors,
    } = this.state;

    const { classes } = this.props;

    const accept = "image/jpeg";

    return !isContainerInitialized ? (
      <CircularProgress />
    ) : (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
        />
        <Divider />
        {isNotImplemented && selectedActivityName && (
          <NotImplementedComponent featureName={selectedActivityName} />
        )}

        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} disabled={disabled}>
                <InputLabel shrink id="category-select-placeholder-label-label">
                  Activity
                </InputLabel>
                <Select
                  labelId="category-select-placeholder-label-label"
                  id="category-select-placeholder-label"
                  value={activityId}
                  onChange={this.handleActivityChange}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  {activities &&
                    activities.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Name"
                value={name}
                onChange={this.handleNameChange}
                helperText={this.hasError("name") ? errors["name"][0] : null}
                error={this.hasError("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Description"
                multiline
                rows="4"
                value={description}
                onChange={this.handleDescriptionChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropzone onDrop={this.handleImageChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: disabled
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: accept,
                          disabled: disabled,
                        })}
                      />
                      <p>
                        Drag 'n' drop thumbnail image here, or click to select
                        one.
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <p className={classes.error}>
                {this.hasError("thumbnail") ? errors["thumbnail"][0] : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              {imageUrl && (
                <ReactCrop
                  imageStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  src={imageUrl}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
            </Grid>
            {croppedImageUrl && (
              <Grid item xs={12}>
                <img className={classes.thumbnail} src={croppedImageUrl} />
              </Grid>
            )}
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button
              disabled={disabled}
              onClick={this.props.history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={disabled || isNotImplemented}
              onClick={this.onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelCreate);
export default withRouter(styledComponent as any);
