import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { RouteComponentProps, useParams } from "react-router-dom";
import { ChannelsToolbar } from "../ChannelList/components";
import ActivityBlockCard from "./components/ActivityBlockCard/ActivityBlockCard";
import * as studentActivityBlockProvider from "../../../providers/student/activity-practice-block.provider";
import { StudentPracticeActivityBlockListItem } from "../../../types/practice-activities/student-practice-activity-block-list-item";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  activityId: string;
};

export default function ActivitiesList(props: Props) {
  const { title } = props;

  const classes = useStyles();
  const [blocks, setBlocks] = useState<StudentPracticeActivityBlockListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { activityId } = useParams<Params>();

  useEffect(() => {
    const loadActivities = async () => {
      try {
        const blocks = await studentActivityBlockProvider.getAll(
          activityId
        );
        setBlocks(blocks);
        setLoading(false);
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };

    loadActivities();
  }, []);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h1" gutterBottom>
        {title}
      </Typography>
      {/*<BreadcrumbsContainer breadcrumbs={this.props.breadcrumbs} title={this.props.title} />*/}
      {/*<Divider/>*/}
      <ChannelsToolbar />
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.content}>
            <Grid container spacing={3}>
              {blocks &&
                blocks.map((block) => (
                  <Grid item key={block.id} lg={4} md={4} sm={6} xs={12}>
                    <ActivityBlockCard block={block} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
