import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { StudentActivity } from "../../types/activities/student-activity";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";

type ClassActivityTimespanPose = {
  keypoints: Keypoint3D[];
  name: string;
};

type ClassActivityTimespanExerciseStep = {
  keypoints: Keypoint3D[];
  order: number;
};

type ClassActivityTimespanExercise = {
  steps: ClassActivityTimespanExerciseStep[];
  repetitions: number;
  name: string;
};

type ClassActivityTimespan = {
  id: string;
  start_seconds: number;
  end_seconds: number;
  pose?: ClassActivityTimespanPose;
  exercise?: ClassActivityTimespanExercise;
};

export type ClassActivityCreate = {
  class_id: string;
  threshold_accuracy: number;
  timespans: ClassActivityTimespan[];
};

export const getAll = async (pager: any): Promise<{
  records: Array<StudentActivity>;
  total: number;
}> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
    },
  };
  const response = await axios(config);
  return response.data;
};

type StudentActivityCreateResponse = {
  student_activity_id: string;
  timespan_id_mapping: any;
};

export const create = async (
  model: ClassActivityCreate
): Promise<StudentActivityCreateResponse> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/create",
    baseURL: apiUrlBase,
    data: {
      class_id: model.class_id,
      threshold_accuracy: model.threshold_accuracy,
      user_id: authService.getAuthState().id,
      timespans: model.timespans,
    },
  };
  const response = await axios(config);
  return response.data;
};
