import * as THREE from "three";
import { Keypoint3D } from "../custom-kalman-filter.service";
import { Keypoint3D as Joint3D } from "../../types/analyze/keypoint3d.type";
import { defaultZAxisMultiplier } from "./constants";
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { POSE_CONNECTIONS } from "@mediapipe/pose";
import { Landmark } from "@mediapipe/holistic";

export const parts = [
  "nose", //0
  "left_eye_inner", //1
  "left_eye", //2
  "left_eye_outer", //3
  "right_eye_inner", //4
  "right-eye", //5
  "right-eye_outer", //6
  "left—ear", //7
  "right_ear", //8
  "mouth_left", //9
  "mouth_right", //10
  "left_shoulder", //11
  "right_shoulder", //12
  "left_elbow", //13
  "right-elbow", //14
  "left_wrist", //15
  "right-wrist", //16
  "left_pinky", //17
  "right_pinky", //18
  "left_index", //19
  "right_index", //20
  "left_thumb", //21
  "right-thumb", //22
  "left-hip", //23
  "right—hip", //24
  "left-knee", //25
  "right_knee", //26
  "left_ankle", //27
  "right_ankle", //28
  "left_heel", //29
  "right_heel", //30
  "left-foot-index", //31
  "right-foot_index", //32
];

export const linkedParts: any = {
  nose: ["right_eye_inner", "left_eye_inner"], //0
  left_eye_inner: ["left_eye"], //1
  left_eye: ["left_eye_outer"],
  left_eye_outer: ["left—ear"], //2
  right_eye_inner: ["right-eye"], //3
  "right-eye": ["right-eye_outer"], //4
  "right-eye_outer": ["right_ear"], //5
  "left—ear": [], //6
  right_ear: [], //7
  mouth_left: ["mouth_right"], //8
  mouth_right: [], //9
  left_shoulder: ["right_shoulder", "left_elbow", "left-hip"], //10
  right_shoulder: ["right-elbow", "right—hip"], //11
  left_elbow: ["left_wrist"], //12
  "right-elbow": ["right-wrist"], //13
  left_wrist: ["left_pinky", "left_index", "left_thumb"], //14
  "right-wrist": ["right_pinky", "right_index", "right_thumb"], //15
  left_pinky: ["left_index"], //17
  right_pinky: ["right_index"], //18
  left_index: [], //19
  right_index: [], //20
  left_thumb: [], //21
  "right-thumb": [], //22
  "left-hip": ["right—hip", "left-knee"], //23
  "right—hip": ["right_knee"], //24
  "left-knee": ["left_ankle"], //25
  right_knee: ["right_ankle"], //26
  left_ankle: ["left_heel", "left-foot-index"], //27
  right_ankle: ["right_heel", "right-foot_index"], //28
  left_heel: ["left-foot-index"], //29
  right_heel: ["right-foot_index"], //30
  "left-foot-index": [], //31
  "right-foot_index": [], //32                              //16
};

export const getIndex = (key: string) => {
  return parts.indexOf(key);
};

export const getMidPoint = (a: Keypoint3D, b: Keypoint3D) => {
  const x = (a.x + b.x) / 2;
  const y = (a.y + b.y) / 2;
  const z = (a.z + b.z) / 2;
  return {
    x,
    y,
    z,
  };
};

export const calculateMidhip = (landmarks: Keypoint3D[]) => {
  return calculateMiPoint(landmarks, "left-hip", "right—hip");
};

export const calculateMidFeet = (landmarks: Keypoint3D[]) => {
  return calculateMiPoint(landmarks, "left_ankle", "right_ankle");
};

export const calculateThorax = (landmarks: Keypoint3D[]) => {
  return calculateMiPoint(landmarks, "left_shoulder", "right_shoulder");
};

const calculateMiPoint = (
  landmarks: Keypoint3D[],
  name1: string,
  name2: string
) => {
  const keypoint1Index = getIndex(name1);
  const keypoint1 = landmarks[keypoint1Index];

  const keypoint2Index = getIndex(name2);
  const keypoint2 = landmarks[keypoint2Index];

  const midpoint = getMidPoint(keypoint1, keypoint2);
  return midpoint;
};

const mapPartsLandmark = (landmarks: Landmark[]): any[] => {
  return landmarks.map((kp, index) => {
    const part = parts[index];
    return {
      ...kp,
      part: part,
    };
  });
};

export const mapForAnalysis = (denormalized: Landmark[]) => {
  //TODO: merge to optimize
  const mapped = mapPartsLandmark(denormalized);
  const studentKeypoints = updateCoordinateSystem(mapped);
  const studentKeypointsScaledByZ = applyZAxisMultiplier(
    studentKeypoints,
    defaultZAxisMultiplier
  );
  return studentKeypointsScaledByZ;
};

export const mapParts = (landmarks: Keypoint3D[]): any[] => {
  return landmarks.map((kp, index) => {
    const part = parts[index];
    return {
      ...kp,
      part: part,
    };
  });
};

export const scaleDefault = (keypoints: Joint3D[]): Joint3D[] => {
  const midhip = calculateMidhip(keypoints);

  const leftShoulderIndex = getIndex("left_shoulder");
  const leftShoulder = keypoints[leftShoulderIndex];

  const rightShoulderIndex = getIndex("right_shoulder");
  const rightShoulder = keypoints[rightShoulderIndex];
  // TODO: if mediapipe model will be used, this can be removed from unity part to not calculate twiced

  const neck = getMidPoint(leftShoulder, rightShoulder);
  // NOTE: if changed - whole scene needs to be rescaled
  const defaultDistance = 0.6;
  if (midhip && neck) {
    const distance = new THREE.Vector3(midhip.x, midhip.y, midhip.z).distanceTo(
      new THREE.Vector3(neck.x, neck.y, neck.z)
    );
    const scaleMultiplier = defaultDistance / distance;
    const scaled = keypoints.map(({ x, y, z, part, visibility }) => {
      return {
        part: part,
        x: x * scaleMultiplier,
        y: y * scaleMultiplier,
        z: z * scaleMultiplier,
        visibility: visibility,
      };
    });
    return scaled;
  }

  return keypoints;
};

export const calculateAngle = (p0: Keypoint3D, p1: Keypoint3D): number => {
  const dotProduct = p0.x * p1.x + p0.z * p1.z;
  const determinant = p0.x * p1.z - p0.z * p1.x;
  const radians = Math.atan2(determinant, dotProduct);
  return radians;
};

export const applyZAxisMultiplier = (
  keypoints: Joint3D[],
  zAxisMultiplier: number
): Joint3D[] => {
  return keypoints.map((k) => {
    return {
      x: k.x,
      y: k.y,
      z: k.z * zAxisMultiplier,
      part: k.part,
      visibility: k.visibility,
    };
  });
};

//TODO: rework, duplicate of keypoints3d service,
export const rotateAroundAxis = (
  keypoints: Joint3D[],
  axis: THREE.Vector3,
  degrees: number
): Joint3D[] => {
  const radians = (degrees * Math.PI) / 180;
  const quaternion = new THREE.Quaternion();

  quaternion.setFromAxisAngle(axis, radians);

  const rotatedKeypoints = keypoints.map(({ x, y, z, part, visibility }) => {
    const vector = new THREE.Vector3(x, y, z);
    vector.applyQuaternion(quaternion);
    return {
      x: vector.x,
      y: vector.y,
      z: vector.z,
      part: part,
      visibility: visibility,
    };
  });
  return rotatedKeypoints;
};

export const updateCoordinateSystem = (keypoints: Joint3D[]): Joint3D[] => {
  const midhip = calculateMidhip(keypoints);
  if (midhip) {
    const xShift = 0 - midhip.x;
    const yShift = 0 - midhip.y;
    const zShift = 0 - midhip.z;
    const centered = keypoints.map(({ x, y, z, part, visibility }) => {
      return {
        part: part,
        x: x + xShift,
        y: y + yShift,
        z: z + zShift,
        visibility: visibility,
      };
    });

    const rotated = rotateAroundAxis(
      rotateAroundAxis(centered, new THREE.Vector3(0, 1, 0), 180),
      new THREE.Vector3(1, 0, 0),
      180
    );
    return rotated;
  }
  return [];
};

export const denormalize = (
  landmarks: Landmark[],
  width: number,
  height: number
): Landmark[] => {
  return landmarks.map((j) => {
    return {
      x: j.x * width,
      y: j.y * height,
      z: j.z * width,
      visibility: j.visibility,
    };
  });
};

export const checkIfLandmarkssWithinImage = (
  filteredDenormalized: Landmark[],
  width: number,
  height: number
) => {
  //TODO: rework to use regions of interest
  const keypointsMapping = [
    true, //0 //"nose"
    true, //1 //"left_eye_inner"
    true, //2 // "left_eye"
    true, //3 //"left_eye_outer"
    true, //4 //"right_eye_inner"
    true, //5 //"right-eye"
    true, //6 //"right-eye_outer"
    true, //7 //"left—ear"
    true, //8 //"right_ear"
    true, //9 //"mouth_left"
    true, //10 //"mouth_right"
    true, //11 //"left_shoulder"
    true, //12 //"right_shoulder"
    true, //13 //"left_elbow"
    true, //14 //"right-elbow"
    false, //15 //"left_wrist"
    false, //16 //"right-wrist"
    false, //17 //"left_pinky"
    false, //18 //"right_pinky"
    false, //19 //"left_index"
    false, //20 //"right_index"
    false, //21 //"left_thumb"
    false, //22 // "right-thumb"
    true, //23 //"left-hip"
    true, //24 //"right—hip"
    true, //25 //"left-knee"
    true, //26 // "right_knee"
    true, //27 //"left_ankle"
    true, //28 // "right_ankle"
    false, //29 //"left_heel"
    false, //30 //"right_heel"
    false, //31 //"left-foot-index"
    false, //32 //"right-foot_index"
  ];

  for (let index = 0; index < filteredDenormalized.length; index++) {
    if (keypointsMapping[index]) {
      const element = filteredDenormalized[index];
      const xNotInImage = element.x > width || element.x < 0;
      const yNotInImage = element.y > height || element.y < 0;
      if (xNotInImage || yNotInImage) {
        return false;
      }
    }
  }
  return true;
};

export const drawOriginal = (
  landmarks: any,
  image: any,
  canvasElement: HTMLCanvasElement | null
) => {
  if (canvasElement) {
    const canvasCtx = canvasElement.getContext("2d");
    if (canvasCtx) {
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasCtx.drawImage(
        image,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      drawConnectors(canvasCtx, landmarks, POSE_CONNECTIONS.filter(c => {
        if(c[0] < 11 || c[1] < 11){
          return false;
        }
        return true;
      }), {
        color: "#0000FF",
        lineWidth: 4,
      });
      drawLandmarks(canvasCtx, landmarks.filter((l: any, i: number) => i > 10), {
        color: "#40e0d0",
        radius: 3
      });
      canvasCtx.restore();
    }
  }
};

export const drawOverlay = (
  landmarks: any,
  canvasElement: HTMLCanvasElement | null
) => {
  if (canvasElement) {
    const canvasCtx = canvasElement.getContext("2d");
    if (canvasCtx) {
      canvasCtx.save();
      canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);    
      drawConnectors(canvasCtx, landmarks, POSE_CONNECTIONS, {
        color: "#00FF00",
        lineWidth: 4,
      });
      drawLandmarks(canvasCtx, landmarks, {
        color: "#00FF00",
        lineWidth: 4,
      });
      canvasCtx.restore();
    }
  }
};

export const defaultTPoseSkeleton = [
  {
    x: 283.4078189134598,
    y: 48.33855843544006,
    z: -209.6661740541458,
    visibility: 1.0,
  },
  {
    x: 286.5340465307236,
    y: 43.80293798446655,
    z: -200.88852244615555,
    visibility: 1.0,
  },
  {
    x: 288.52894258499146,
    y: 43.85410666465759,
    z: -200.88852244615555,
    visibility: 1.0,
  },
  {
    x: 290.728106379509,
    y: 43.815760374069214,
    z: -200.88852244615555,
    visibility: 1.0,
  },
  {
    x: 280.44220530986786,
    y: 43.793779134750366,
    z: -201.9857268333435,
    visibility: 1.0,
  },
  {
    x: 278.24555110931396,
    y: 43.782323598861694,
    z: -201.8859869837761,
    visibility: 1.0,
  },
  {
    x: 276.14852118492126,
    y: 43.77590775489807,
    z: -201.8859869837761,
    visibility: 1.0,
  },
  {
    x: 293.3507971763611,
    y: 47.244526863098145,
    z: -137.25055241584778,
    visibility: 1.0,
  },
  {
    x: 273.39181476831436,
    y: 46.93202877044678,
    z: -141.34013390541077,
    visibility: 1.0,
  },
  {
    x: 286.9899671077728,
    y: 54.512571573257446,
    z: -185.3281483054161,
    visibility: 1.0,
  },
  {
    x: 279.50599467754364,
    y: 54.382925033569336,
    z: -186.52509254217148,
    visibility: 1.0,
  },
  {
    x: 308.52624583244324,
    y: 82.81982374191284,
    z: -81.34289953112602,
    visibility: 1.0,
  },
  {
    x: 256.7197895646095,
    y: 80.29388737678528,
    z: -97.50175404548645,
    visibility: 1.0,
  },
  {
    x: 317.71938383579254,
    y: 122.13678503036499,
    z: -48.35189285874367,
    visibility: 1.0,
  },
  {
    x: 250.52120804786682,
    y: 119.82947087287903,
    z: -74.36067253351212,
    visibility: 1.0,
  },
  {
    x: 323.0741969347,
    y: 158.36285305023193,
    z: -97.50175404548645,
    visibility: 1.0,
  },
  {
    x: 247.39564085006714,
    y: 159.82042121887207,
    z: -115.30642339587212,
    visibility: 1.0,
  },
  {
    x: 326.1908814907074,
    y: 167.8091139793396,
    z: -109.22191283106804,
    visibility: 1.0,
  },
  {
    x: 245.22616291046143,
    y: 171.09610891342163,
    z: -126.37823188304901,
    visibility: 1.0,
  },
  {
    x: 323.92861461639404,
    y: 169.09269189834595,
    z: -130.16857731342316,
    visibility: 1.0,
  },
  {
    x: 247.44669127464294,
    y: 172.60690069198608,
    z: -147.7238805294037,
    visibility: 1.0,
  },
  {
    x: 320.49383902549744,
    y: 165.922372341156,
    z: -106.52877178788185,
    visibility: 1.0,
  },
  {
    x: 251.04577922821045,
    y: 168.59459924697876,
    z: -124.38331106305122,
    visibility: 1.0,
  },
  {
    x: 297.29970932006836,
    y: 154.55963373184204,
    z: 8.515817476436496,
    visibility: 1.0,
  },
  {
    x: 267.42804539203644,
    y: 154.74345231056213,
    z: -8.478413484990597,
    visibility: 1.0,
  },
  {
    x: 293.9424339532852,
    y: 212.91567707061768,
    z: 22.355580665171146,
    visibility: 1.0,
  },
  {
    x: 269.3037357330322,
    y: 216.26106548309326,
    z: -6.645580239593983,
    visibility: 1.0,
  },
  {
    x: 292.31308007240295,
    y: 264.7676167488098,
    z: 99.79591092467308,
    visibility: 1.0,
  },
  {
    x: 266.83837336301804,
    y: 266.8721251487732,
    z: 70.91943308711052,
    visibility: 1.0,
  },
  {
    x: 289.75795018672943,
    y: 271.8290433883667,
    z: 103.13741052150726,
    visibility: 1.0,
  },
  {
    x: 267.04364824295044,
    y: 272.6752281188965,
    z: 74.2110545039177,
    visibility: 1.0,
  },
  {
    x: 296.1758074760437,
    y: 283.1405110359192,
    z: 31.120764791965485,
    visibility: 1.0,
  },
  {
    x: 270.0125474333763,
    y: 287.007851600647,
    z: -1.9840111290104687,
    visibility: 1.0,
  },
];

export const findLowestPoint = (landmarks: any[]) => {
  let minY = undefined;
  for (let index = 0; index < landmarks.length; index++) {
    const { y } = landmarks[index];
    if (minY === undefined) {
      minY = y;
    } else if (minY >= y) {
      minY = y;
    }
  }
  return minY;
};
