import { Keypoint3D } from "../types/analyze/keypoint3d.type";
import { mediapipeKeypoint } from "./mediapipe";

export const getFlippedDisplayKeypoints = (keypoints: Keypoint3D[]) => {
  const flippedIndexes = [
    0,
    4,
    5,
    6,
    1,
    2,
    3,
    8,
    7,
    10,
    9,
    12,
    11,
    14,
    13,
    16,
    15,
    18,
    17,
    20,
    19,
    22,
    21,
    24,
    23,
    26,
    25,
    28,
    27,
    30,
    29,
    32,
    31,
  ];
  const flippedKeypoints = [];
  for (let index = 0; index < keypoints.length; index++) {
    const keypoint = keypoints[index];
    const flippedIndex = flippedIndexes[index];
    const { x, y, z, visibility } = keypoints[flippedIndex] as Keypoint3D;
    flippedKeypoints.push({ x: -x, y: y, z: z, part: keypoint.part, visibility: visibility });
  }
  return flippedKeypoints;
};

export const mapKeypoints = (keypoints: Keypoint3D[]) => {
  const scaled =
    keypoints && keypoints.length !== 0
      ? mediapipeKeypoint.scaleDefault(keypoints)
      : keypoints;

  const decenter = (keypoints: Keypoint3D[]) => {
    if (keypoints.length === 0) {
      return [];
    }
    const midhip = mediapipeKeypoint.calculateMidhip(keypoints);
    //TODO: check if logic is correct
    const decenteredYShift = 1 - midhip.y;
    const decentered = keypoints.map(({ x, y, z, part, visibility }) => {
      return {
        part: part,
        x: x,
        y: y + decenteredYShift,
        z: z,
        visibility: visibility
      };
    });

    return decentered;
  };

  const decentered = decenter(scaled);
  return decentered;
};
