import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ChannelClassSequenceCheckpoint } from "../../types/class-sequence/class-sequence-checkpoint";

export type ChannelClassOption = {
  id: string;
  name: string;
  thumbnail_path: string;
  is_published: boolean;
};

export type ChannelClassSequence = {
  name: string;
  checkpoints: ChannelClassSequenceCheckpoint[];
  file_path: string;
  description: string;
};

export const getAll = async (): Promise<Array<ChannelClassOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-sequence/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getAllSearch = async (
  pager: any,
  filter: any
): Promise<{
  records: Array<ChannelClassOption>;
  total: number;
}> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-sequence/getAllSearch",
    baseURL: apiUrlBase,
    data: {
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        name: filter["name"],
      },
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-sequence/update",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClassSequence> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-sequence/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const toggleUnpublish = async (
  id: string,
  isPublished: boolean
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class-sequence/toggle_unpublish",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
      is_published: isPublished,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
