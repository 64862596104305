import React, { useState, useEffect } from "react";
import { useParams, RouteComponentProps, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@material-ui/core";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import validate from "validate.js";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
  listContainer: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  videoId: string;
};


export default function ChannelVideoEdit(props: Props) {
  const [name, setName] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>();
  const [thumbnail, setThumbnail] = useState<string>();
  const classes = useStyles();
  const { videoId } = useParams<Params>();
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getVideo = async (id: string) => {
      try {
        const video = await channelVideoProvider.get(id);
        setName(video.name);
        setChannelId(video.channel_id);
        setThumbnail(video.thumbnail_path);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        alert("An error occurred, please try again.");
        history.goBack();
      }
    };

    getVideo(videoId);
  }, []);

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const formData = {
      name: name,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true,
      });
      return;
    }

    setDisabled(true);

    const model = {
      id: videoId,
      name: name
    };
    try {
      await channelVideoProvider.edit(model);
      history.goBack();
    } catch (ex) {
      alert("An error occurred, please try again.");
    }
    setDisabled(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value as string;
    setName(name);
    setSubmitted({
      ...submitted,
      name: false
    })
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  }

  const data = channelId ? { channelId: channelId } : undefined;
  return loading ? (
    <LinearProgress />
  ) : (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              
              label="Name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            {thumbnail && (
              <img alt='thumbnail' className={classes.thumbnail} src={thumbnail} />
            )}
          </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
          <Button
            disabled={disabled}
            onClick={history.goBack}
            variant="contained"
          >
            Go Back
          </Button>
          <Button
            disabled={disabled}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Paper>
    </main>
  );
}
