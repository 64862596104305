import React, { FC } from "react";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    width: "100%",
    border: "1px dashed gray",
    padding: "2px 12px",
    margin: "5px 12px",
    backgroundColor: "rgb(250, 250, 250)",
    height: 80,
    display: "flex",
    alignItems: "center" /* Vertical center alignment */,
    justifyContent: "center" /* Horizontal center alignment */,
  },
}));

export interface CardProps {
  onRemove: (id: number) => void;
}

interface DragItem {
  index: number;
  id: number;
  type: string;
}

export const DeletionCard: FC<CardProps> = ({ onRemove }) => {
  const classes = useStyles();
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      onRemove(item.id);
    },
  });

  return (
    <div ref={drop} className={classes.delete} data-handler-id={handlerId}>
      Drag and drop block here to delete
    </div>
  );
};
