import React, { Component, createRef } from "react";
import "./number.input.scss";

type State = {
  disabled: boolean;
};

type Props = {
  value: any;
  onChangeValue: (value: number, index: number) => void;
  index: number;
};

export default class NumberInput extends Component<Props, State> {
  private readonly refference = createRef<HTMLInputElement>();
  private min: number = Infinity;
  private max: number = Infinity;
  private precision: number = 3;
  private step: number = 1.015;
  private value: number = 0;

  private distance: number = 0;
  private onMouseDownValue: number = 0;
  private pointer = [0, 0];
  private prevPointer = [0, 0];

  constructor(props: Props) {
    super(props);
    this.max = this.props.value + 11.15;
    this.min = this.props.value - 11.15;

    this.state = {
      disabled: this.props.index === 0,
    };
  }

  componentDidMount = async () => {
    this.setValue(this.props.value);
  };

  setValue = (value: any): void => {
    if (value !== undefined) {
      let parsed = parseFloat(value);
      if (parsed < this.min) parsed = this.min;
      if (parsed > this.max) parsed = this.max;
      this.value = parsed;
      if (this.refference.current) {
        this.refference.current.value = parsed.toFixed(this.precision);
      }
      this.props.onChangeValue(this.value, this.props.index);
    }
  };

  onMouseDown = (event: any) => {
    if (this.state.disabled) {
      return;
    }
    event.preventDefault();
    this.distance = 0;
    this.onMouseDownValue = this.value;
    this.prevPointer = [event.clientX, event.clientY];

    document.addEventListener("mousemove", this.onMouseMove, false);
    document.addEventListener("mouseup", this.onMouseUp, false);

    if (this.refference.current) {
      this.refference.current.style.backgroundColor = "yellow";
    }
  };

  onMouseMove = (event: any) => {
    this.pointer = [event.clientX, event.clientY];
    this.distance +=
      this.pointer[0] -
      this.prevPointer[0] -
      (this.pointer[1] - this.prevPointer[1]);
    let value =
      this.onMouseDownValue +
      (this.distance / (event.shiftKey ? 5 : 50)) * this.step;
    value = Math.min(this.max, Math.max(this.min, value));
    if (this.value !== value) {
      this.setValue(value);
      if (this.refference.current) {
        const changeEvent = document.createEvent("HTMLEvents");
        changeEvent.initEvent("change", true, true);
        this.refference.current.dispatchEvent(changeEvent);
      }
    }
    this.prevPointer = [event.clientX, event.clientY];
  };
  onMouseUp = () => {
    document.removeEventListener("mousemove", this.onMouseMove, false);
    document.removeEventListener("mouseup", this.onMouseUp, false);
    if (Math.abs(this.distance) < 2) {
      if (this.refference.current) {
        this.refference.current.focus();
        this.refference.current.select();
      }
    }
    if (this.refference.current) {
      this.refference.current.style.backgroundColor = "";
    }
  };

  onTouchStart = (event: any) => {
    if (this.state.disabled) {
      return;
    }
    if (event.touches.length === 1) {
      this.distance = 0;
      this.onMouseDownValue = this.value;
      this.prevPointer = [event.touches[0].pageX, event.touches[0].pageY];
      document.addEventListener("touchmove", this.onTouchMove, false);
      document.addEventListener("touchend", this.onTouchEnd, false);
    }
    if (this.refference.current) {
      this.refference.current.style.backgroundColor = "yellow";
    }
  };

  onTouchMove = (event: any) => {
    this.pointer = [event.touches[0].pageX, event.touches[0].pageY];
    this.distance +=
      this.pointer[0] -
      this.prevPointer[0] -
      (this.pointer[1] - this.prevPointer[1]);
    let value =
      this.onMouseDownValue +
      (this.distance / (event.shiftKey ? 5 : 50)) * this.step;
    value = Math.min(this.max, Math.max(this.min, value));
    if (this.value !== value) {
      this.setValue(value);
      if (this.refference.current) {
        const changeEvent = document.createEvent("HTMLEvents");
        changeEvent.initEvent("change", true, true);
        this.refference.current.dispatchEvent(changeEvent);
      }
    }

    this.prevPointer = [event.touches[0].pageX, event.touches[0].pageY];
  };

  onTouchEnd = (event: any) => {
    if (event.touches.length === 0) {
      document.removeEventListener("touchmove", this.onTouchMove, false);
      document.removeEventListener("touchend", this.onTouchEnd, false);
    }
    if (this.refference.current) {
      this.refference.current.style.backgroundColor = "";
    }
  };

  onChange = () => {
    if (this.refference.current) {
      this.setValue(this.refference.current.value);
    }
  };

  render = () => {
    return (
      <input
        className="number-input"
        disabled={this.state.disabled}
        readOnly={true}
        ref={this.refference}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
        onChange={this.onChange}
      />
    );
  };
}
