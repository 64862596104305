import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ChannelType } from "../../types/channel/channel.type";

export type ChannelOption = {
  id: string;
  name: string;
  description: string;
  thumbnail_path: string;
  first_name: string;
  last_name: string;
  subscription_id: string;
  subscription_name: string;
  type: string;
  video_count: number;
  class_count: number;
  is_published?: boolean;
};

export const getAll = async (): Promise<Array<ChannelType>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelDetails = {
  id: string;
  name: string;
  description: string;
  type: string;
  thumbnail_path: string;
  subscribed: boolean;
  subscription_name: string;
  instr_first_name: string;
  instr_last_name: string;
};

export const get = async (channelId: string): Promise<ChannelDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/get",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const subscribe = async (channelId: string): Promise<ChannelDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/subscribe",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const unsubscribe = async (
  channelId: string
): Promise<ChannelDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/unsubscribe",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelAnalysisOption = {
  id: string;
  name: string;
};

export const getAnalysisOptions = async (
  channelId: string
): Promise<Array<ChannelAnalysisOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/getAnalysisOptions",
    baseURL: apiUrlBase,
    data: {
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelDetailsPose = {
  id: string;
  name: string;
};

export type ChannelAnalysisDetails = {
  poses: Array<ChannelDetailsPose>;
};

export const getAnalysisDetails = async (
  channelId: string
): Promise<ChannelAnalysisDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/getAnalysisDetails",
    baseURL: apiUrlBase,
    data: {
      channel_id: channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
