import React, { Fragment, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  Button,
  TextField,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { ChannelExerciseListItem } from "../../../../../providers/instructor/channel-exercise.provider";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  thumbnail: {
    maxWidth: "100%",
  },
}));

type Props = {
  exercises: ChannelExerciseListItem[];
  exercise: ChannelExerciseListItem;
  onExerciseSelected: (pose: ChannelExerciseListItem) => void;
  onCreateTimespan: (repetitions: number) => void;
};

export default function ExerciseSelection(props: Props) {
  const { exercises, exercise, onExerciseSelected, onCreateTimespan } = props;
  const classes = useStyles();

  const [repetitions, setRepetitions] = useState<number>(1);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    if (value < 1) {
      value = 1;
    }
    setRepetitions(value);
  };

  const handlePoseOrSkillChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const id = event.target.value as string;
    const exercise = exercises.find((x) => x.id === id);
    if (exercise) {
      onExerciseSelected(exercise);
    }
  };

  const handleTimespanCreation = async () => {
    onCreateTimespan(repetitions);
  };

  const getName = (name: string, message: string) => {
    if(!message){
      return name;
    }
    return `${name} (${message})`;
  }

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={6}>
        <img  className={classes.thumbnail} src={exercise.thumbnail_path} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Repetitions"
          value={repetitions}
          onChange={handleChange}
          name="repetitions"
          type={"number"}
        />
      </Grid>
      <Grid item xs={8}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="category-select-placeholder-label-label">
            Exercise
          </InputLabel>
          <Select
            labelId="category-select-placeholder-label-label"
            value={exercise.id}
            onChange={handlePoseOrSkillChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {exercises.map(({ id, name, message, thumbnail_path }) => (
              <MenuItem value={id} key={id}>
                <Avatar alt={name} src={thumbnail_path} style={{ margin: "0px 10px 0px 0px"}} />
                <ListItemText primary={getName(name,message)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <Button
          onClick={handleTimespanCreation}
          variant="contained"
          color="primary"
        >
          Add Exercise Set
        </Button>
      </Grid>
    </Grid>
  );
}
