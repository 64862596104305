import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import * as adminUserProvider from "../../../providers/admin/user.provider";
import { User } from "../../../providers/admin/user.provider";
import { Typography, Divider, Button } from "@material-ui/core";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

declare module "@material-ui/core/styles/withStyles" {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      },
    },
    tableRow: {
      cursor: "pointer",
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    noClick: {
      cursor: "initial",
    },
  });

interface ColumnData {
  dataKey: string;
  label: string;
  width: number;
  type: string;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => User;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  update = async (id: string, rowIndex: number, roles: Array<string>) => {
    const { rowGetter } = this.props;
    const row = rowGetter({
      index: rowIndex,
    }) as any;
    const role = "Instructor";
    const isInstructor = roles.indexOf(role) === -1;
    const data = {
      id: id,
      isInstructor: isInstructor,
    };

    try {
      row["loading"] = true;
      this.forceUpdate();
      await adminUserProvider.update(data);
      if (isInstructor) {
        row["roles"] = [...roles, role];
      } else {
        row["roles"] = roles.filter((x) => x !== role);
      }
    } catch (ex) {
      //TODO: display error
    }
    row["loading"] = false;
    this.forceUpdate();
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick !== null,
    });
  };

  cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowIndex,
    rowData,
  }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;

    const { type } = columns[columnIndex];
    const processColumn = (type: string, cellData: any) => {
      if (type === "datatime") {
        return new Date(cellData).toLocaleString();
      }
      if (type === "boolean") {
        return !cellData ? "No" : "Yes";
      }
      return cellData;
    };

    if (type === "actions") {
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          variant="body"
          style={{ height: rowHeight }}
          align={"left"}
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={rowData["loading"]}
            onClick={() => this.update(cellData, rowIndex, rowData["roles"])}
          >
            {rowData["roles"].indexOf("Instructor") === -1
              ? "Enable instructor"
              : "Disable instructor"}
          </Button>
        </TableCell>
      );
    }
    if (type === "admin") {
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          variant="body"
          style={{ height: rowHeight }}
          align={"left"}
        >
          {cellData.indexOf("Admin") === -1 ? "No" : "Yes"}
        </TableCell>
      );
    }
    if (type === "instructor") {
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          variant="body"
          style={{ height: rowHeight }}
          align={"left"}
        >
          {cellData.indexOf("Instructor") === -1 ? "No" : "Yes"}
        </TableCell>
      );
    }
    const renderedValue = processColumn(type, cellData);
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        {renderedValue}
      </TableCell>
    );
  };

  headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, columns, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].type === "number" ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

const width = 1000;
const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    padding: theme.spacing(3),
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(width + theme.spacing(2) * 2)]: {
      width: width,
      marginLeft: "auto",
      marginRight: "auto",
    },
    height: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(width + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    height: "100%",
  },
}));

type Props = {
  title: string;
  breadcrumbs: any[];
};

export default function ReactVirtualizedTable(props: Props) {
  const classes = useStyles();
  const [users, setUsers] = useState<User[]>();
  useEffect(() => {
    const getUsers = async () => {
      const users = await adminUserProvider.getAll();
      setUsers(users);
    };
    getUsers();
  }, []);

  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
      />
      <Divider />
      <Paper className={classes.paper}>
        {users && (
          <VirtualizedTable
            rowCount={users.length}
            rowGetter={({ index }) => users[index]}
            columns={[
              {
                width: 150,
                label: "First Name",
                dataKey: "firstName",
                type: "string",
              },
              {
                width: 150,
                label: "Last Name",
                dataKey: "lastName",
                type: "string",
              },
              {
                width: 250,
                label: "Username",
                dataKey: "email",
                type: "string",
              },
              {
                width: 100,
                label: "Is Admin",
                dataKey: "roles",
                type: "admin",
              },
              {
                width: 100,
                label: "Is Instructor",
                dataKey: "roles",
                type: "instructor",
              },
              {
                width: 300,
                label: "Actions",
                dataKey: "id",
                type: "actions",
              },
            ]}
          />
        )}
      </Paper>
    </main>
  );
}
