import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  is_default_instructor: boolean;
  is_admin: boolean;
  creation_date: Date;
  is_instructor: boolean;
};

export const getAll = async (): Promise<Array<User>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/user/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};


export const update = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/user/update",
    data: {
      id: item.id,
      user_id: authService.getAuthState().id,
      is_instructor: item.isInstructor
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};