import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  liveSkeletonPlaceholder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

type Props = {};

export default function SnapshotSkeletonsPlaceholder(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.liveSkeletonPlaceholder}>
      <Typography variant="body1">
        Here you should see your position in 3D. Make sure that your head,
        wrists and ankles are all visible. Then our AI can process your position
        and display it in 3D
      </Typography>
    </div>
  );
}
