import React, { useState, useEffect } from "react";
import { useParams, RouteComponentProps, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@material-ui/core";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import { ChannelPoseOrSkill } from "../../../providers/instructor/channel-pose.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import HumanRegions from "../../../components/HumanRegions";
import validate from "validate.js";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
  listContainer: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  info: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px !important",
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  poseOrSkillId: string;
};

export default function CreateItemView(props: Props) {
  const [name, setName] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>();
  const [pose, setPose] = useState<ChannelPoseOrSkill>();
  const classes = useStyles();
  const { poseOrSkillId } = useParams<Params>();
  const history = useHistory();
  const [regions, setRegions] = useState<{ [key: string]: boolean }>();
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  useEffect(() => {
    const getPoseOrSkill = async (id: string) => {
      try {
        const pose = await channelPoseOrSkillProvider.get(id);
        setName(pose.name);
        setChannelId(pose.channel_id);
        setPose(pose);

        setRegions(pose.regions || {});
      } catch (err) {
        alert("An error occurred, please try again.");
        history.goBack();
      }
    };

    getPoseOrSkill(poseOrSkillId);
  }, []);

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const formData = {
      name: name,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true,
      });
      return;
    }

    setDisabled(true);

    const model = {
      id: poseOrSkillId,
      name: name,
      regions: regions,
    };
    try {
      await channelPoseOrSkillProvider.edit(model);
      history.goBack();
    } catch (ex) {
      alert("An error occurred, please try again.");
    }
    setDisabled(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const onToggleRegion = (key: string) => {
    if (regions) {
      if (regions[key]) {
        regions[key] = false;
      } else {
        regions[key] = true;
      }

      setRegions({ ...regions });
    }
  };

  const hasError = (field: string) =>
    submitted[field] && errors[field] ? true : false;

  const data = channelId ? { channelId: channelId } : undefined;
  return !pose ? (
    <LinearProgress />
  ) : (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              
              label="Name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            {pose && (
              <img className={classes.thumbnail} src={pose.thumbnail_path} />
            )}
          </Grid>
          {regions && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 16 }}>
                  Regions of interest
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.info}>
                <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
                <Typography>
                  If no regions will be selected, all regions will be used for
                  calculations.
                </Typography>
              </Grid>
              <HumanRegions
                selectedRegions={regions}
                onRegionSelection={onToggleRegion}
              />
            </Grid>
          )}
        </Grid>
        <div className={classes.buttonsContainer}>
          <Button
            disabled={disabled}
            onClick={history.goBack}
            variant="contained"
          >
            Go Back
          </Button>
          <Button
            disabled={disabled}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Paper>
    </main>
  );
}
