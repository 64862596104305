import { LiveHolisticModel } from "../types/analyze/live.holistic.type";
import { findLowestPoint } from "./mediapipe/mediapipe.keypoint";

export const timeCrossingCheck = (
  startSeconds: number,
  endSeconds: number,
  newStartSeconds: number,
  newEndSeconds: number
): boolean => {
  //NOTE: crossing start
  const isTimeCrossing =
    (startSeconds <= newStartSeconds && endSeconds >= newStartSeconds) ||
    //NOTE: crossing end
    (startSeconds <= newEndSeconds && endSeconds >= newEndSeconds) ||
    //NOTE: inside
    (startSeconds > newStartSeconds && endSeconds < newEndSeconds) ||
    //NOTE: wraps
    (startSeconds <= newStartSeconds && endSeconds >= newEndSeconds);
  return isTimeCrossing;
};


export const placeOnFloor = (live: LiveHolisticModel) => {
  const yArray = [];
  const minYPose = findLowestPoint(live.poseLandmarks);
  yArray.push(minYPose);
  if (live.rightHandLandmarks.length !== 0) {
    const minYRightHand = findLowestPoint(live.rightHandLandmarks);
    yArray.push(minYRightHand);
  }
  if (live.leftHandLandmarks.length !== 0) {
    const minYLeftHand = findLowestPoint(live.leftHandLandmarks);
    yArray.push(minYLeftHand);
  }
  yArray.sort((a,b) => a-b)

  const lowestY = yArray[0];

  const yShift = 0 - lowestY;

  const placeLandmarksOnFloor = (landmarks: any[]) => {
    return landmarks.map(kp => {
      return {
        ...kp,
        y: kp.y + yShift,
      }
    });
  }

  live.poseLandmarks = placeLandmarksOnFloor(live.poseLandmarks);
  live.faceLandmarks = placeLandmarksOnFloor(live.faceLandmarks);
  live.leftHandLandmarks = placeLandmarksOnFloor(live.leftHandLandmarks);
  live.rightHandLandmarks = placeLandmarksOnFloor(live.rightHandLandmarks);
};