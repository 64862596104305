import React, { useState, ChangeEvent, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import {
  TabPanel,
  ChannelHeader,
  ClassPracticeList,
  ClassSequenceList,
} from "./components";
import { useHistory, useParams } from "react-router-dom";
import ChannelVideoList from "../ChannelVideoList";
import ChannelClassList from "./components/ChannelClassList";
import * as channelProvider from "../../../providers/student/channel.provider";
import { ChannelDetails } from "../../../providers/student/channel.provider";

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowY: "hidden",
  },
  content: {},
  breadcrumbs: {
    position: "absolute",
    left: "2.31%",
    right: "86.15%",
    top: "5%",
    bottom: "55.25%",
  },
}));

type Params = {
  channelId: string;
};

export default function ChannelDetailsView() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [channel, setChannel] = useState<ChannelDetails>();
  const { channelId } = useParams<Params>();
  const history = useHistory();

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getChannel = async () => {
      if (channelId) {
        const channel = await channelProvider.get(channelId);
        setChannel(channel);
      }
    };
    getChannel();
  }, []);

  const onAnalyzeLive = () => {
    if (channel) {
      history.push(`/sports-student/live-feedback/${channel.id}`);
    }
  };

  const onSubscribe = async () => {
    if (channel && channel.subscribed) {
      await channelProvider.unsubscribe(channel.id);
    }

    if (channel && !channel.subscribed) {
      await channelProvider.subscribe(channel.id);
    }

    if (channel) {
      channel.subscribed = !channel.subscribed;
    }
    const newChannel = JSON.parse(JSON.stringify(channel));
    setChannel(newChannel);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.content}>
        {channel && (
          <ChannelHeader
            channel={channel}
            onSubscribe={onSubscribe}
            onAnalyzeLive={onAnalyzeLive}
          />
        )}
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            style={{ background: "white" }}
          >
            <Tab label="Videos" {...a11yProps(0)} />
            <Tab label="Classes" {...a11yProps(1)} />
            <Tab label="Block Classes" {...a11yProps(2)} />
            <Tab label="Checkpoint Classes" {...a11yProps(3)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {channelId && <ChannelVideoList channelId={channelId} />}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {channelId && <ChannelClassList channelId={channelId} />}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {channelId && <ClassPracticeList channelId={channelId} />}
        </TabPanel>

        <TabPanel value={value} index={3}>
          {channelId && <ClassSequenceList channelId={channelId} />}
        </TabPanel>
      </Box>
    </div>
  );
}
