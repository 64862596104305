import React, { Component } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { ChannelClassToolbar, ChannelClassCard } from "./components";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelClassProvider from "../../../providers/instructor/channel-class.provider";
import { ChannelClassOption } from "../../../providers/instructor/channel-class.provider";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  courses?: Array<ChannelClassOption>;
  loading: boolean;
  confirmationData: any;
};

type Props = {
  classes: any;
  channelId: string;
};

class ChannelCourseList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      confirmationData: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      const courses = await channelClassProvider.getAll(this.props.channelId);
      this.setState({
        courses: courses,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  onRemove = async (id: string) => {
    const { channelId } = this.props;
    this.setState({
      confirmationData: {
        channelId: channelId,
        id: id,
      },
    });
  };

  onRemoveClickHandler = async (data: any) => {
    await channelClassProvider.remove(data);
    const { courses } = this.state;
    this.setState({
      courses: courses && courses.filter((i) => i.id !== data.id),
    });
  };

  onPublish = async (id: string) => {
    const { courses } = this.state;
    if (courses) {
      const course = courses.find((i) => i.id === id);
      if (course) {
        const newPublishStatus = !course.is_published;
        try {
          await channelClassProvider.setPublished(course.id, newPublishStatus);
          const newCourses = courses.map((c) => {
            if (c.id === id) {
              c.is_published = newPublishStatus;
            }
            return c;
          });
          this.setState({
            courses: newCourses,
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  render = () => {
    const { courses, loading, confirmationData } = this.state;

    const { classes, channelId } = this.props;

    return (
      <div className={classes.root}>
        <ChannelClassToolbar channelId={channelId} />
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {courses &&
                courses.map((course) => (
                  <Grid item key={course.id} lg={4} md={6} xs={12}>
                    <ChannelClassCard
                      course={course}
                      channelId={channelId}
                      onRemove={this.onRemove}
                      onPublish={this.onPublish}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
        <ConfirmationDialog
          handleConfirm={this.onRemoveClickHandler}
          dialogTitle={"Confirm class removal"}
          data={confirmationData}
        />
      </div>
    );
  };
}

export default withStyles(styles)(ChannelCourseList);
