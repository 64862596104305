import { Pose } from "@mediapipe/pose";
import { storageBase } from "./constants";

const baseUrl = `${storageBase}/pose/`;
let dynamicModel: any;
let staticModel: any;
const loadModel = () => {
  const pose = new Pose({
    locateFile: (file: string) => {
      return `${baseUrl}${file}`;
    },
  });
  return pose;
};

export const clear = async () => {
  await clearStatic();
  await clearDynamic();
};

const clearStatic = async () => {
  if (staticModel) {
    await staticModel.close();
    staticModel = undefined;
  }
};

const clearDynamic = async () => {
  if (dynamicModel) {
    await dynamicModel.close();
    dynamicModel = undefined;
  }
};

export const getModel = async () => {
  await clear();

  const model = loadModel();
  model.setOptions({
    modelComplexity: 2,
    smoothLandmarks: true,
    minDetectionConfidence: 0.6, //0.5,
    minTrackingConfidence: 0.6, //0.5
  });
  await model.initialize();
  dynamicModel = model;
  return dynamicModel;
};

export const getModel1 = async () => {
  await clear();
  const model = loadModel();
  model.setOptions({
    modelComplexity: 1,
    smoothLandmarks: true,
    minDetectionConfidence: 0.6, //0.5,
    minTrackingConfidence: 0.6, //0.5
  });
  await model.initialize();
  dynamicModel = model;
  return dynamicModel;
};

export const getStaticModel = async () => {
  await clear();
  const model = loadModel();
  model.setOptions({
    modelComplexity: 2,
    smoothLandmarks: false,
    minDetectionConfidence: 0.6, //0.5,
    minTrackingConfidence: 0.6, //0.5
  });
  await model.initialize();
  staticModel = model;

  return staticModel;
};

export const getStaticModel1 = async () => {
  await clear();
  const model = loadModel();
  model.setOptions({
    modelComplexity: 1,
    smoothLandmarks: false,
    minDetectionConfidence: 0.6, //0.5,
    minTrackingConfidence: 0.6, //0.5
  });
  await model.initialize();
  staticModel = model;

  return staticModel;
};