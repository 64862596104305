import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Avatar, Button, Grid } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import GradientSVG from "./GradientSVG";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: 15,
    width: 60,
    height: 60,
  },
}));

type Props = {
  instructorAvatar: string;
  instructorName: string;
  effectiveness: number;
  classThumbnail: string;
  channelType: string;

  rewards: any;
  timespansCount: number;
  classDuration: number;

  onReturnClickHandler: () => void;
};

const exerciseGradientId = "exercise";
const exerciseStartColor = "red";
const exerciseEndColor = "blue";

const timeGradientId = "time";
const timeStartColor = "yellow";
const timeEndColor = "green";

const caloriesGradientId = "calories";
const caloriesStartColor = "orange";
const caloriesEndColor = "red";

export default function ClassSummary(props: Props) {
  const {
    instructorAvatar,
    instructorName,
    effectiveness,
    classThumbnail,
    channelType,
    rewards,
    timespansCount,
    classDuration,
    onReturnClickHandler,
  } = props;
  const classes = useStyles();
  const [calories, setCalories] = useState<number>();

  useEffect(() => {
    setCalories(getRandomInt(25, 100));
  }, []);

  //TOOD: move to helper
  const getColorForPercentage = (percentage: number) => {
    const pct = percentage / 100;

    const percentColors = [
      { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
      { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
      { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
    ];

    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  };

  const getLang = () => {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  };

  const getGradientStyles = (id: string): any => {
    const base = buildStyles({
      trailColor: "transparent",
    });

    return {
      ...base,
      path: { stroke: `url(#${id})` },
    };
  };

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const calculateClassScore = (rewards: any) => {
    const keys = Object.keys(rewards);
    return (
      keys.reduce((acc, curr) => {
        const value = rewards[curr];
        return acc + value.rewardProgressPercentage;
      }, 0) / keys.length
    );
  };

  const [goldCount] = useState<number>(
    Object.keys(rewards).filter((x) => rewards[x].rewardProgressPercentage > 66)
      .length
  );
  const [silverCount] = useState<number>(
    Object.keys(rewards).filter(
      (x) =>
        rewards[x].rewardProgressPercentage > 33 &&
        rewards[x].rewardProgressPercentage <= 66
    ).length
  );
  const [bronzeCount] = useState<number>(
    Object.keys(rewards).filter(
      (x) => rewards[x].rewardProgressPercentage <= 33
    ).length
  );


  return (
    <Grid
      container
      xs={12}
      style={{ backgroundColor: "#373737", height: "100vh", width: "100hw" }}
    >
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          padding: 15,
          height: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "#191919",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            height: "75%",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              className={classes.avatar}
              alt="instructor avatar"
              src={instructorAvatar}
            />
            <span style={{ fontSize: 25, color: "white" }}>
              {instructorName}
            </span>
          </div>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              height: 200,
              width: 300,
            }}
          >
            <CircularProgressbar
              value={effectiveness / 2}
              strokeWidth={4}
              styles={buildStyles({
                rotation: -0.25,
                pathColor: getColorForPercentage(effectiveness),
                trailColor: "transparent",
              })}
            />
            <div
              style={{
                position: "absolute",
                bottom: 5,
                right: 0,
                left: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>
                <span style={{ fontSize: 60, color: "white" }}>
                  {Math.round(effectiveness)}
                </span>
                <span style={{ fontSize: 30, color: "white" }}>%</span>
              </p>
              <span style={{ fontSize: 25, color: "white" }}>
                Effectiveness
              </span>
            </div>
          </div>
          <Button
            onClick={onReturnClickHandler}
            variant="contained"
            size="large"
            color="primary"
            startIcon={<ExitToAppIcon />}
          >
            Exit
          </Button>
        </div>

        <div style={{ position: "relative" }}>
          <img
            src={classThumbnail}
            alt={"Class thumbnail"}
            style={{ width: "100%", borderRadius: 10 }}
          ></img>
          <span
            style={{
              position: "absolute",
              left: "5%",
              bottom: "15%",
              fontSize: 40,
              color: "white",
              fontWeight: 900,
            }}
          >
            {channelType}
          </span>
        </div>
      </Grid>
      <Grid
        item
        xs={8}
        style={{
          paddingRight: 15,
          paddingTop: 15,
          paddingBottom: 15,
          height: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            backgroundColor: "#191919",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: 50,
                fontWeight: 900,
                textTransform: "uppercase",
              }}
            >
              Your scores
            </span>
            <span>
              {new Date().toLocaleString(getLang(), {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
          <div>
            <CircularProgressbarWithChildren
              value={calculateClassScore(rewards)}
              strokeWidth={4}
              styles={buildStyles({
                pathColor: getColorForPercentage(calculateClassScore(rewards)),
                trailColor: "transparent",
              })}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: 80,
                  }}
                >
                  <strong>{Math.round(calculateClassScore(rewards))}</strong>
                </span>
                <span
                  style={{
                    fontSize: 30,
                  }}
                >
                  Average Score
                </span>
                {goldCount ? (
                  <span
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {goldCount}xGold Medal(s)
                  </span>
                ) : (
                  <span></span>
                )}
                {silverCount ? (
                  <span
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {silverCount}xSilver Medal(s)
                  </span>
                ) : (
                  <span></span>
                )}
                {bronzeCount ? (
                  <span
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {bronzeCount}xBronze Medal(s)
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: 300,
              }}
            >
              <div
                style={{
                  height: 200,
                  width: 200,
                }}
              >
                <GradientSVG
                  startColor={caloriesStartColor}
                  endColor={caloriesEndColor}
                  idCSS={caloriesGradientId}
                  rotation={90}
                />
                <CircularProgressbarWithChildren
                  value={calories || 0}
                  strokeWidth={4}
                  styles={getGradientStyles(caloriesGradientId)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <WhatshotIcon
                      fontSize="large"
                      style={{ color: "orange", margin: 10 }}
                    />
                    <span
                      style={{
                        fontSize: 30,
                        color: "white",
                      }}
                    >
                      {calories}
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <span
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: 25,
                }}
              >
                Calories
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: 300,
              }}
            >
              <div
                style={{
                  height: 200,
                  width: 200,
                }}
              >
                <GradientSVG
                  startColor={exerciseStartColor}
                  endColor={exerciseEndColor}
                  idCSS={exerciseGradientId}
                  rotation={90}
                />
                <CircularProgressbarWithChildren
                  value={100}
                  strokeWidth={4}
                  styles={getGradientStyles(exerciseGradientId)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FitnessCenterIcon
                      fontSize="large"
                      style={{ color: "purple", margin: 10 }}
                    />

                    <span
                      style={{
                        fontSize: 30,
                        color: "white",
                      }}
                    >
                      {timespansCount}/{timespansCount}
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <span
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: 25,
                }}
              >
                Done
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                height: 300,
              }}
            >
              <div
                style={{
                  height: 200,
                  width: 200,
                }}
              >
                <GradientSVG
                  startColor={timeStartColor}
                  endColor={timeEndColor}
                  idCSS={timeGradientId}
                  rotation={90}
                />
                <CircularProgressbarWithChildren
                  value={100}
                  strokeWidth={4}
                  styles={getGradientStyles(timeGradientId)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon
                      fontSize="large"
                      style={{ color: "lightgreen", margin: 10 }}
                    />
                    <span
                      style={{
                        fontSize: 30,
                        color: "white",
                      }}
                    >
                      {Math.floor(classDuration / 60)}
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <span
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: 25,
                }}
              >
                Minutes
              </span>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
