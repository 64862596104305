import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { SearchInput } from '../../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const VideoToolbar = props => {
  const { className, channelId, ...rest } = props;

  const classes = useStyles();
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
        />
      </div> */}
    </div>
  );
};

VideoToolbar.propTypes = {
  className: PropTypes.string,
  channelId: PropTypes.string.isRequired
};


export default VideoToolbar;
