import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import * as channelVideoProvider from "../../../providers/admin/channel-video.provider";
import { VideoCard, Filter } from "./components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    // marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const pageSize = 8;

export default function ClassSequenceList() {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<
    Array<channelVideoProvider.ChannelVideoItem>
  >([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = useState<{ [key: string]: string | undefined }>(
    {}
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };


  const [instructors, setInstructors] = useState<Array<channelVideoProvider.InstructorListItem>>();
  useEffect(() => {
    const load = async () => {
      const instructors = await channelVideoProvider.getInstructors();
      setInstructors(instructors);
    };

    load();
  }, []);


  useEffect(() => {
    const getClasses = async (
      page: number,
      filter: { [key: string]: string | undefined }
    ) => {
      setLoading(true);
      try {
        const pager = {
          pageSize: pageSize,
          pageNumber: page,
        };

        const { records, total } = await channelVideoProvider.getAll(
          pager,
          filter
        );
        setVideos(records);
        setTotalCount(total);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses(page, filter);
  }, [page, filter]);

  const onFilterChange = (filter: { [key: string]: string | undefined }) => {
    setFilter(filter);
  };

  const onToggleApprove = async (videoId: string, isApproved: boolean) => {
    try {
      await channelVideoProvider.update({
        id: videoId,
        isApproved: isApproved,
      });
      setVideos([
        ...videos.map((v) => {
          if (v.id === videoId) {
            v.is_approved = isApproved;
          }
          return v;
        }),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography component="h1" variant="h4" gutterBottom>
          Channel Videos
        </Typography>
        {instructors ? (
          <Filter instructors={instructors} onFilterChange={onFilterChange} />
        ) : (
          <CircularProgress />
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid style={{ marginTop: "30px" }} container spacing={3}>
            {videos &&
              videos.map((video) => (
                <Grid item key={video.id} lg={3} md={4} sm={6} xs={12}>
                  <VideoCard onToggleApprove={onToggleApprove} video={video} />
                </Grid>
              ))}
          </Grid>
        )}
        {totalCount !== undefined && totalCount !== 0 && (
          <Pagination
            disabled={loading}
            count={Math.ceil(totalCount / pageSize)}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}
