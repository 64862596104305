import { Holistic } from "@mediapipe/holistic";
import { storageBase } from "./constants";

const baseUrl = `${storageBase}/holistic/`;
let model: Holistic | undefined;

const loadModel = () => {
  const model = new Holistic({
    locateFile: (file: string) => {
      return `${baseUrl}${file}`;
    },
  });
  return model;
};
export const clear = async () => {
  if (model) {
    await model.close();
    model = undefined;
  }
};


export const getModel = async () => {
  await clear();
  const holistic = loadModel();
  holistic.setOptions({
    modelComplexity: 2,
    smoothLandmarks: true,
    minDetectionConfidence: 0.6, //0.5,
    minTrackingConfidence: 0.6, //0.5
  });
  await holistic.initialize();
  model = holistic;
  return model;
};