import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { StudentPracticeActivityBlockListItem } from "../../types/practice-activities/student-practice-activity-block-list-item";
import { StudentPracticeActivityBlockDetails } from "../../types/practice-activities/student-practice-activity-block-details";

export const getAll = async (activityId: string): Promise<Array<StudentPracticeActivityBlockListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/block/getAll",
    baseURL: apiUrlBase,
    data: {
      activity_id: activityId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (blockId: string): Promise<StudentPracticeActivityBlockDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/block/details",
    baseURL: apiUrlBase,
    data: {
      block_id: blockId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const saveReward = async (model: any) => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/block/reward/save",
    baseURL: apiUrlBase,
    data: {
      block_id: model.blockId,
      reward: model.reward,
      score: model.score,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
}

export const saveEffectiveness = async (model: any) => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/block/effectiveness/save",
    baseURL: apiUrlBase,
    data: {
      block_id: model.blockId,
      effectiveness: model.effectiveness,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
}