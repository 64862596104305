import * as THREE from "three";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { getLineElements } from "../pose3d.service";
import { regionsToMixamorigMap } from "./regions.service";

const threshold = 0.11;

//TODO: check if all joints needed for calculation
export const coordinateJoints = [
  // "mixamorigHeadTop_End",
  "mixamorigLeftEye",
  "mixamorigRightEye",
  // "mixamorigHead",
  "mixamorigHips",
  "mixamorigSpine1",
  "mixamorigSpine2",
  "mixamorigRightFoot",
  "mixamorigLeftFoot",
  "mixamorigRightUpLeg",
  "mixamorigLeftUpLeg",
  "mixamorigRightLeg",
  "mixamorigLeftLeg",
  "mixamorigLeftArm",
  "mixamorigRightArm",
  "mixamorigLeftForeArm",
  "mixamorigRightForeArm",
  "mixamorigRightHand",
  "mixamorigLeftHand",
  "mixamorigNeck",
  "mixamorigRightToe_End",
  "mixamorigLeftToe_End",
  "mixamorigRightToeBase",
  "mixamorigLeftToeBase",
];

export const lowerAccuracyPoints = [
  "mixamorigRightFoot",
  "mixamorigLeftFoot",
  "mixamorigRightUpLeg",
  "mixamorigLeftUpLeg",
  "mixamorigRightLeg",
  "mixamorigLeftLeg",
  // "mixamorigRightToe_End",
  // "mixamorigLeftToe_End",
  // "mixamorigRightToeBase",
  // "mixamorigLeftToeBase",
];

export const upperAccuracyPoints = [
  "mixamorigSpine1",
  //"mixamorigSpine2",
  "mixamorigLeftLeg",
  "mixamorigLeftArm",
  "mixamorigRightArm",
  "mixamorigLeftForeArm",
  "mixamorigRightForeArm",
  "mixamorigRightHand",
  "mixamorigLeftHand",
  "mixamorigNeck",
];

export const getCoordinates = (model: THREE.Object3D): Keypoint3D[] => {
  const keypoints: Keypoint3D[] = [];
  for (let index = 0; index < coordinateJoints.length; index++) {
    const name = coordinateJoints[index];
    const object = model.getObjectByName(name);
    if (object) {
      const position = new THREE.Vector3();
      object.getWorldPosition(position);
      const keypoint = {
        x: position.x,
        y: position.y,
        z: position.z,
        part: name,
      };
      keypoints.push(keypoint);
    }
  }

  return keypoints;
};

export const removeFeedback = (scene: THREE.Scene) => {
  coordinateJoints.forEach((part) => {
    const keys = getFeedbackArrowKeys(part);
    keys.forEach((key) => removeJoint(scene, key));
  });
};

const removeJoint = (scene: THREE.Scene, name: string) => {
  const prevLine = scene.getObjectByName(name);
  if (prevLine) {
    const prevLineMesh = prevLine as THREE.Mesh;
    prevLineMesh.geometry.dispose();
    scene.remove(prevLine);
  }
};

const getFeedbackArrowKeys = (part: string): string[] => {
  const keys = [];

  for (let index = 0; index < 4; index++) {
    const key = `${part}${index}-arrow`;
    keys.push(key);
  }

  return keys;
};

export const feedbackJointsUpper = [
  "mixamorigHips",
  "mixamorigLeftArm",
  "mixamorigRightArm",
  "mixamorigLeftForeArm",
  "mixamorigRightForeArm",
  "mixamorigRightHand",
  "mixamorigLeftHand",
];

export const applyFeedback = (
  scene: THREE.Scene,
  instructorKeypoints: Keypoint3D[],
  studentKeypoints: Keypoint3D[],
  centerPart: string,
  parts: string[]
) => {
  const center = studentKeypoints.find((kp) => kp.part === centerPart);
  if (center) {
    for (let index = 0; index < instructorKeypoints.length; index++) {
      const kp = instructorKeypoints[index];
      if (parts.indexOf(kp.part) !== -1) {
        const keys = getFeedbackArrowKeys(kp.part);
        keys.forEach((key) => removeJoint(scene, key));

        const from = studentKeypoints.find((skp) => skp.part === kp.part);
        if (from && kp) {
          const arrows = getLineElements(center, from, kp);
          if (arrows) {
            for (let index = 0; index < arrows.length; index++) {
              const arrow = arrows[index];
              const key = keys[index];
              arrow.name = key;
              scene.add(arrow);
            }
          }
        }
      }
    }
  }
};

const getMidPoint = (a: Keypoint3D, b: Keypoint3D) => {
  const x = (a.x + b.x) / 2;
  const y = (a.y + b.y) / 2;
  const z = (a.z + b.z) / 2;
  return {
    x,
    y,
    z,
  };
};

const applyRotation = (
  keypoints: Keypoint3D[],
  quaternion: THREE.Quaternion
): Keypoint3D[] => {
  const rotated = keypoints.map(({ x, y, z, part }) => {
    const vector = new THREE.Vector3(x, y, z);
    vector.applyQuaternion(quaternion);
    return { x: vector.x, y: vector.y, z: vector.z, part: part };
  });
  //TODO: center by
  return rotated;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const upperCoordinateJoints = [
  "mixamorigHips",
  "mixamorigSpine1",
  //"mixamorigRightFoot",
  //"mixamorigLeftFoot",
  "mixamorigRightUpLeg",
  "mixamorigLeftUpLeg",
  //"mixamorigRightLeg",
  //"mixamorigLeftLeg",
  "mixamorigLeftArm",
  "mixamorigRightArm",
  "mixamorigLeftForeArm",
  "mixamorigRightForeArm",
  "mixamorigRightHand",
  "mixamorigLeftHand",
  "mixamorigNeck",
  "mixamorigSpine2",
];

export const lowerCoordinateJoints = [
  "mixamorigHips",
  //"mixamorigSpine1",
  "mixamorigRightFoot",
  "mixamorigLeftFoot",
  "mixamorigRightUpLeg",
  "mixamorigLeftUpLeg",
  "mixamorigRightLeg",
  "mixamorigLeftLeg",
  // "mixamorigLeftArm",
  // "mixamorigRightArm",
  // "mixamorigLeftForeArm",
  // "mixamorigRightForeArm",
  // "mixamorigRightHand",
  // "mixamorigLeftHand",
  "mixamorigNeck",
  // "mixamorigRightToe_End",
  // "mixamorigLeftToe_End",
  // "mixamorigRightToeBase",
  // "mixamorigLeftToeBase",
];

export const alignUpper = (
  studentKeypoints: Keypoint3D[],
  instructorKeypoints: Keypoint3D[]
) => {
  const center = (keypoints: Keypoint3D[]) => {
    const rHip = keypoints.find((kp) => kp.part === "mixamorigRightUpLeg");

    const lHip = keypoints.find((kp) => kp.part === "mixamorigLeftUpLeg");

    if (!rHip || !lHip) {
      return;
    }
    const hips = getMidPoint(rHip, lHip);

    const xShift = 0 - hips.x;
    const zShift = 0 - hips.z;
    const yShift = 0 - hips.y;

    return keypoints.map((kp) => {
      return {
        x: kp.x + xShift,
        y: kp.y + yShift,
        z: kp.z + zShift,
        part: kp.part,
      };
    });
  };

  const centeredStudentKeypoints = center(studentKeypoints);
  const centeredInstructorKeypoints = center(instructorKeypoints);
  if (centeredStudentKeypoints && centeredInstructorKeypoints) {
    const neckStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === "mixamorigSpine2" // "mixamorigSpine2"
    ); //mixamorigNeck"
    const lShoulderStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === "mixamorigLeftUpLeg"
    );
    const rShoulderStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === "mixamorigRightUpLeg"
    );

    const neckInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === "mixamorigSpine2" // "mixamorigSpine2"
    ); //mixamorigNeck"
    const lShoulderInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === "mixamorigLeftUpLeg"
    );
    const rShoulderInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === "mixamorigRightUpLeg"
    );

    if (
      neckStudent &&
      lShoulderStudent &&
      rShoulderStudent &&
      neckInstructor &&
      lShoulderInstructor &&
      rShoulderInstructor
    ) {
      const getVector = (keypoint: Keypoint3D) => {
        const { x, y, z } = keypoint;
        return new THREE.Vector3(x, y, z);
      };

      const hipsPosS = getVector(neckStudent);
      const lShoulderPosS = getVector(lShoulderStudent);
      const rShoulderPosS = getVector(rShoulderStudent);
      const studentPlane = new THREE.Plane().setFromCoplanarPoints(
        hipsPosS,
        rShoulderPosS,
        lShoulderPosS
      );

      const hipsPosI = getVector(neckInstructor);
      const lShoulderPosI = getVector(lShoulderInstructor);
      const rShoulderPosI = getVector(rShoulderInstructor);
      const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
        hipsPosI,
        rShoulderPosI,
        lShoulderPosI
      );

      const quaternion = new THREE.Quaternion().setFromUnitVectors(
        instructorPlane.normal,
        studentPlane.normal
      );
      if (centeredInstructorKeypoints) {
        const rotated = applyRotation(centeredInstructorKeypoints, quaternion);

        const neckInstructor = rotated.find(
          (kp) => kp.part === "mixamorigSpine2" // "mixamorigSpine2"
        ); //mixamorigNeck"
        const lShoulderInstructor = rotated.find(
          (kp) => kp.part === "mixamorigLeftUpLeg"
        );
        const rShoulderInstructor = rotated.find(
          (kp) => kp.part === "mixamorigRightUpLeg"
        );

        if (neckInstructor && lShoulderInstructor && rShoulderInstructor) {
          const hipsStud = getMidPoint(lShoulderStudent, rShoulderStudent);

          const hipsPosS2 = new THREE.Vector3(
            hipsStud.x,
            hipsStud.y,
            hipsStud.z
          );
          // spine2, normal, midhips
          const plane = new THREE.Plane().setFromCoplanarPoints(
            hipsPosS,
            studentPlane.normal,
            hipsPosS2
          );

          const neckPosI = getVector(neckInstructor);
          const hipsPos = getMidPoint(lShoulderInstructor, rShoulderInstructor);

          const hipsPosI = new THREE.Vector3(hipsPos.x, hipsPos.y, hipsPos.z);
          // spine2, normal, midhips
          const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
            neckPosI,
            studentPlane.normal,
            hipsPosI
          );

          const quaternion = new THREE.Quaternion().setFromUnitVectors(
            instructorPlane.normal,
            plane.normal
          );

          const rotated2 = applyRotation(rotated, quaternion);
          /////////////////////////////////////////////////////

          const lShoulderInstructor2 = rotated2.find(
            (kp) => kp.part === "mixamorigLeftUpLeg"
          );
          const rShoulderInstructor2 = rotated2.find(
            (kp) => kp.part === "mixamorigRightUpLeg"
          );
          if (lShoulderInstructor2 && rShoulderInstructor2) {
            const lShoulderPos = getVector(lShoulderInstructor2);
            const rShoulderPos = getVector(rShoulderInstructor2);
            // normal, rhip, lhip
            const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
              studentPlane.normal,
              rShoulderPos,
              lShoulderPos
            );
            // normal, rhip, lhip
            const studentPlane3 = new THREE.Plane().setFromCoplanarPoints(
              studentPlane.normal,
              rShoulderPosS,
              lShoulderPosS
            );

            const quaternion = new THREE.Quaternion().setFromUnitVectors(
              instructorPlane.normal,
              studentPlane3.normal
            );

            const rotated3 = applyRotation(rotated2, quaternion);

            const centerByHips = (
              studentKeypoints: Keypoint3D[],
              instructorKeypoints: Keypoint3D[]
            ) => {
              const rHipStudent = studentKeypoints.find(
                (kp) => kp.part === "mixamorigRightUpLeg"
              );

              const lHipStudent = studentKeypoints.find(
                (kp) => kp.part === "mixamorigLeftUpLeg"
              );

              if (!rHipStudent || !lHipStudent) {
                return;
              }
              const studentHips = getMidPoint(rHipStudent, lHipStudent);

              const rHipInstructor = instructorKeypoints.find(
                (kp) => kp.part === "mixamorigRightUpLeg"
              );

              const lHipInstructor = instructorKeypoints.find(
                (kp) => kp.part === "mixamorigLeftUpLeg"
              );

              if (!rHipInstructor || !lHipInstructor) {
                return;
              }
              const instructorHips = getMidPoint(
                rHipInstructor,
                lHipInstructor
              );

              if (studentHips && instructorHips) {
                const xShift = studentHips.x - instructorHips.x;
                const zShift = studentHips.z - instructorHips.z;
                const yShift = studentHips.y - instructorHips.y;
                return instructorKeypoints.map((kp) => {
                  return {
                    x: kp.x + xShift,
                    y: kp.y + yShift,
                    z: kp.z + zShift,
                    part: kp.part,
                  };
                });
              }
              return instructorKeypoints;
            };
            return centerByHips(studentKeypoints, rotated3);
          }
        }
      }
    }
  }
};

export const alignLower = (
  studentKeypoints: Keypoint3D[],
  instructorKeypoints: Keypoint3D[]
) => {
  const topKey = "mixamorigHips";
  const leftKey = "mixamorigLeftLeg";
  const rightKey = "mixamorigRightLeg";
  // const leftKey = 'mixamorigLeftFoot'
  // const rightKey = 'mixamorigRightFoot'

  //TODO: refactor
  const center = (keypoints: Keypoint3D[]) => {
    const hips = keypoints.find((kp) => kp.part === topKey);
    if (!hips) {
      return;
    }

    const xShift = 0 - hips.x;
    const zShift = 0 - hips.z;
    const yShift = 0 - hips.y;

    return keypoints.map((kp) => {
      return {
        x: kp.x + xShift,
        y: kp.y + yShift,
        z: kp.z + zShift,
        part: kp.part,
      };
    });
  };

  const instructorInitialHips = instructorKeypoints.find(
    (kp) => kp.part === topKey
  );

  const centeredStudentKeypoints = center(studentKeypoints);
  const centeredInstructorKeypoints = center(instructorKeypoints);

  if (
    centeredStudentKeypoints &&
    centeredInstructorKeypoints &&
    instructorInitialHips
  ) {
    const neckStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === topKey
    );
    const lShoulderStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === leftKey
    );
    const rShoulderStudent = centeredStudentKeypoints.find(
      (kp) => kp.part === rightKey //"mixamorigRightUpLeg"
    );

    const neckInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === topKey
    ); //mixamorigNeck"
    const lShoulderInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === leftKey
    );
    const rShoulderInstructor = centeredInstructorKeypoints.find(
      (kp) => kp.part === rightKey
    );

    if (
      neckStudent &&
      lShoulderStudent &&
      rShoulderStudent &&
      neckInstructor &&
      lShoulderInstructor &&
      rShoulderInstructor
    ) {
      const getVector = (keypoint: Keypoint3D) => {
        const { x, y, z } = keypoint;
        return new THREE.Vector3(x, y, z);
      };

      const hipsPosS = getVector(neckStudent);
      const lShoulderPosS = getVector(lShoulderStudent);
      const rShoulderPosS = getVector(rShoulderStudent);
      //hips, rfeet, lfeet
      const studentPlane = new THREE.Plane().setFromCoplanarPoints(
        hipsPosS,
        rShoulderPosS,
        lShoulderPosS
      );

      const hipsPosI = getVector(neckInstructor);
      const lShoulderPosI = getVector(lShoulderInstructor);
      const rShoulderPosI = getVector(rShoulderInstructor);
      //hips, rfeet, lfeet
      const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
        hipsPosI,
        rShoulderPosI,
        lShoulderPosI
      );

      const quaternion = new THREE.Quaternion().setFromUnitVectors(
        instructorPlane.normal,
        studentPlane.normal
      );
      if (centeredInstructorKeypoints) {
        const rotated = applyRotation(centeredInstructorKeypoints, quaternion);

        const neckInstructor = rotated.find((kp) => kp.part === topKey);
        const lShoulderInstructor = rotated.find((kp) => kp.part === leftKey);
        const rShoulderInstructor = rotated.find((kp) => kp.part === rightKey);

        if (neckInstructor && lShoulderInstructor && rShoulderInstructor) {
          const hipsStud = getMidPoint(lShoulderStudent, rShoulderStudent);

          const hipsPosS2 = new THREE.Vector3(
            hipsStud.x,
            hipsStud.y,
            hipsStud.z
          );
          //hips, normal, midfeet
          const plane = new THREE.Plane().setFromCoplanarPoints(
            hipsPosS,
            studentPlane.normal,
            hipsPosS2
          );

          const neckPosI = getVector(neckInstructor);
          const hipsPos = getMidPoint(lShoulderInstructor, rShoulderInstructor);

          const hipsPosI = new THREE.Vector3(hipsPos.x, hipsPos.y, hipsPos.z);
          //hips, normal, midfeet
          const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
            neckPosI,
            studentPlane.normal,
            hipsPosI
          );

          const quaternion = new THREE.Quaternion().setFromUnitVectors(
            instructorPlane.normal,
            plane.normal
          );

          const rotated2 = applyRotation(rotated, quaternion);
          /////////////////////////////////////////////////////

          const lShoulderInstructor2 = rotated2.find(
            (kp) => kp.part === leftKey
          );
          const rShoulderInstructor2 = rotated2.find(
            (kp) => kp.part === rightKey
          );
          if (lShoulderInstructor2 && rShoulderInstructor2) {
            const lShoulderPos = getVector(lShoulderInstructor2);
            const rShoulderPos = getVector(rShoulderInstructor2);
            //normal, rfeet, lfeet
            const instructorPlane = new THREE.Plane().setFromCoplanarPoints(
              studentPlane.normal,
              rShoulderPos,
              lShoulderPos
            );

            const studentPlane3 = new THREE.Plane().setFromCoplanarPoints(
              studentPlane.normal,
              rShoulderPosS,
              lShoulderPosS
            );
            //normal, rfeet, lfeet
            const quaternion = new THREE.Quaternion().setFromUnitVectors(
              instructorPlane.normal,
              studentPlane3.normal
            );

            const rotated3 = applyRotation(rotated2, quaternion);

            const centerByHips = (
              studentKeypoints: Keypoint3D[],
              instructorKeypoints: Keypoint3D[]
            ) => {
              const studentHips = studentKeypoints.find(
                (kp) => kp.part === "mixamorigHips"
              );
              const instructorHips = instructorKeypoints.find(
                (kp) => kp.part === "mixamorigHips"
              );
              if (studentHips && instructorHips) {
                const xShift = studentHips.x - instructorHips.x;
                const zShift = studentHips.z - instructorHips.z;
                const yShift = studentHips.y - instructorHips.y;
                return instructorKeypoints.map((kp) => {
                  return {
                    x: kp.x + xShift,
                    y: kp.y + yShift,
                    z: kp.z + zShift,
                    part: kp.part,
                  };
                });
              }
              return instructorKeypoints;
            };

            const centered = centerByHips(studentKeypoints, rotated3);
            return centered;
          }
        }
      }
    }
  }
};

export const getVerticalShift = (keypoints: Keypoint3D[]) => {
  let lowestPosition: Keypoint3D | undefined;

  for (let index = 0; index < keypoints.length; index++) {
    const keypoint = keypoints[index];
    if (
      !lowestPosition ||
      (lowestPosition && lowestPosition.y && keypoint.y < lowestPosition.y)
    ) {
      lowestPosition = keypoint;
    }
  }

  if (lowestPosition) {
    const floorY = 0;
    const shiftY = floorY - lowestPosition.y;
    return shiftY;
  }
};

export const calculateAccuracyPercentage = (
  studentKeypoints: Keypoint3D[],
  instructorKeypoints: Keypoint3D[] | undefined,
  parts: string[]
) => {
  const checkThreshold = (distance: number) => {
    const min = threshold;
    const max = 4 * threshold;
    if (distance <= min) {
      return 100;
    }
    if (distance >= max) {
      return 0;
    }

    const percentageValue = (max - min) / 100;

    const percentage = (distance - min) / percentageValue;
    return 100 - percentage;
  };

  const dict: any = {};

  for (let index = 0; index < parts.length; index++) {
    const part = parts[index];
    const studentKeypoint = studentKeypoints.find((kp) => kp.part === part);
    const instructorKeypoint = instructorKeypoints
      ? instructorKeypoints.find((kp) => kp.part === part)
      : undefined;

    if (studentKeypoint && instructorKeypoint) {
      const distance = new THREE.Vector3(
        studentKeypoint.x,
        studentKeypoint.y,
        studentKeypoint.z
      ).distanceTo(
        new THREE.Vector3(
          instructorKeypoint.x,
          instructorKeypoint.y,
          instructorKeypoint.z
        )
      );
      const percentage = checkThreshold(distance);

      dict[part] = percentage;
    } else {
      //TODO: check
      dict[part] = 0; //100;
    }
  }

  return dict;
};

export const getMergedAccuracy = (
  upper: any,
  lower: any,
  accuracyJoints: string[]
) => {
  const merged: { [key: string]: number } = {};

  for (let index = 0; index < accuracyJoints.length; index++) {
    const key = accuracyJoints[index];
    const upperJoint = upper[key];
    const lowerJoint = lower[key];
    if (upperJoint !== undefined && lowerJoint !== undefined) {
      merged[key] = (lowerJoint + upperJoint) / 2;
    }
  }
  return merged;
};

export const getAverageAccuracyPercentage = (merged: {
  [key: string]: number;
}) => {
  const keys = Object.keys(merged);
  const percentageSumm = keys
    .map((key) => merged[key])
    .reduce((acc, curr) => acc + curr, 0);

  const averagePercent = percentageSumm / keys.length;
  return averagePercent;
};

export const mapVisibility = (
  avatarKeypoints: Keypoint3D[],
  studentKeypoints: Keypoint3D[]
) => {
  const partsDict: { [key: string]: string } = {
    mixamorigRightFoot: "right_ankle",
    mixamorigLeftFoot: "left_ankle",
    mixamorigRightUpLeg: "right—hip",
    mixamorigLeftUpLeg: "left-hip",
    mixamorigRightLeg: "right_knee",
    mixamorigLeftLeg: "left-knee",
    mixamorigLeftArm: "left_shoulder",
    mixamorigRightArm: "right_shoulder",
    mixamorigLeftForeArm: "left_elbow",
    mixamorigRightForeArm: "right-elbow",
    mixamorigRightHand: "right-wrist",
    mixamorigLeftHand: "left_wrist",
  };

  const getVisibility = (keypoints: Keypoint3D[], part: string) => {
    const studentKeypointPart = partsDict[part];
    if (!studentKeypointPart) {
      return 1.0;
    }
    const studentKeypoint = keypoints.find(
      (kp) => kp.part === studentKeypointPart
    );
    return studentKeypoint ? studentKeypoint.visibility : 1.0;
  };
  return avatarKeypoints.map(({ x, y, z, part }) => {
    return {
      x,
      y,
      z,
      part,
      visibility: getVisibility(studentKeypoints, part),
    };
  });
};

export const getFeedback = (accuracy: number, mergedAccuracy: any) => {
  const feedbackAccuracy = 60;
  if (accuracy > feedbackAccuracy || !mergedAccuracy) {
    return undefined;
  }

  let lowestAccuracyRegion = "";
  let lowestAccuracyValue = 100;

  const getRegionAccuracy = (mixamorigParts: string[], mergedAccuracy: any) => {
    let accuracySum = undefined;
    for (let i = 0; i < mixamorigParts.length; i++) {
      const partKey = mixamorigParts[i];
      const partAccuracy = mergedAccuracy[partKey];
      if (partAccuracy) {
        accuracySum =
          accuracySum === undefined ? partAccuracy : accuracySum + partAccuracy;
      }
    }
    if (accuracySum === undefined) {
      return undefined;
    }
    return accuracySum / mixamorigParts.length;
  };

  const keys = Object.keys(regionsToMixamorigMap);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const mixamorigParts = regionsToMixamorigMap[key];
    for (let y = 0; y < mixamorigParts.length; y++) {
      const regionAccuracy = getRegionAccuracy(mixamorigParts, mergedAccuracy);
      if (regionAccuracy !== undefined) {
        if (regionAccuracy < lowestAccuracyValue) {
          lowestAccuracyValue = regionAccuracy;
          lowestAccuracyRegion = key;
        }
      }
    }
  }

  const keyToName: { [key: string]: string } = {
    head: "Head",
    leftShoulder: "Left Shoulder",
    rightShoulder: "Right Shoulder",
    leftArm: "Left Arm",
    rightArm: "Right Arm",
    leftHand: "Left Hand",
    rightHand: "Right Hand",
    chest: "Chest",
    stomach: "Stomach",
    leftLeg: "Left Leg",
    rightLeg: "Right Leg",
    leftFoot: "Left Foot",
    rightFoot: "Right Foot",
  };

  return keyToName[lowestAccuracyRegion];
};
