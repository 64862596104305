import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelVideoItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  duration: number;
};

export type ChannelVideoDetails = {
  name: string;
  file_path: string;
  thumbnail_path: string;
  creation_date: string;
  channel_name: string;
  channel_id: string;
  subscribed: boolean;
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelVideoItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/video/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelVideoDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/video/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
