import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import SelectedPoint from "../SelectedPoint";
import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import { getFlippedDisplayKeypoints } from "../../../../../services/keypoint.helper";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import { StudentActivityRecord } from "../../../../../providers/student/activity-sequence.provider";
import { ChannelClassSequenceCheckpoint } from "../../../../../types/class-sequence/class-sequence-checkpoint";
import HumanRegionsAccuracy from "../../../../../components/HumanRegionsAccuracy";
Chart.register(...registerables);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
  },
  chart: {
    height: 300,
  },
  video: {
    width: "50%",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
}));

type Props = {
  records: StudentActivityRecord[];
  filePath: string;

  checkpoint: ChannelClassSequenceCheckpoint;
};

export default function AccuracyChart(props: Props) {
  const { records, filePath, checkpoint } = props;
  const classes = useStyles();
  const chartRef = useRef<any>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [selectedRecord, setSelectedRecord] = useState<StudentActivityRecord>();
  const [selectedRecordIndex, setSelectedRecordIndex] = useState<number>(-1);
  const [trigger, setStrigger] = useState<number>(0);

  useEffect(() => {}, []);

  useEffect(() => {
    const setupData = (records: StudentActivityRecord[]) => {
      const canvasElement = canvasRef.current;

      if (canvasElement) {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
        const chart = new Chart(canvasElement, {
          type: "line",
          data: {
            labels: records.map((d) => d.current_time.toFixed(2)),
            datasets: [
              {
                label: "accuracy (%)",
                data: records.map((d) => Math.round(d.accuracy)),
                backgroundColor: "rgb(255, 99, 132)",
                fill: false,
                tension: 0.4,
                pointStyle: "circle",
                pointRadius: 5,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        });
        chartRef.current = chart;

        const getMaxAccuracyKeypoints = (records: StudentActivityRecord[]) => {
          let max = records[0].accuracy;
          let maxIndex = 0;
          for (let index = 1; index < records.length; index++) {
            const record = records[index];
            const accuracy = record.accuracy;
            if (max < accuracy) {
              max = accuracy;
              maxIndex = index;
            }
          }

          return maxIndex;
        };

        if (records && records.length > 0) {
          const index = getMaxAccuracyKeypoints(records);
          setSelectedRecordIndex(index);
          setStrigger(new Date().getTime());
        }

        canvasElement.onclick = (event: Event) => {
          const activePoints = chart.getElementsAtEventForMode(
            event,
            "point",
            { axis: "x", intersect: true },
            true
          );
          if (activePoints.length !== 0) {
            const activePoint = activePoints[0];
            setSelectedRecordIndex(activePoint.index);
          }
        };
      }
    };
    setupData(records);
  }, [records]);

  useEffect(() => {
    const onUpdate = (timestamp: number) => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.currentTime = timestamp;
      }
    };
    onUpdate(checkpoint.startTime);
  }, [checkpoint]);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      if (selectedRecordIndex !== undefined && selectedRecordIndex >= 0) {
        const selectedRecord = records[selectedRecordIndex];
        setSelectedRecord(selectedRecord);

        const videoElement = videoRef.current;
        if (videoElement && videoElement.paused) {
          videoElement.currentTime = selectedRecord.current_time;
        }

        chart.config.data.datasets[0]["pointBackgroundColor"] = Array.from(
          Array(records.length)
        ).map((e, i) =>
          i === selectedRecordIndex ? "rgba(0, 255, 0)" : "rgb(255, 99, 132)"
        );

        chart.config.data.datasets[0]["pointRadius"] = Array.from(
          Array(records.length)
        ).map((e, i) => (i === selectedRecordIndex ? 10 : 5));
      } else {
        chart.config.data.datasets[0]["pointBackgroundColor"] = Array.from(
          Array(records.length)
        ).map((e, i) => "rgb(255, 99, 132)");
        chart.config.data.datasets[0]["pointRadius"] = Array.from(
          Array(records.length)
        ).map((e, i) => 5);
      }
      chart.update();
    }
  }, [selectedRecordIndex]);

  const onTimeUpdate = () => {
    const video = videoRef.current;

    if (video && !video.paused) {
      const { currentTime } = video;
      if (
        currentTime >= checkpoint.startTime &&
        currentTime <= checkpoint.endTime
      ) {
        if (records.length > 0) {
          const closest = [...records].sort(
            (a, b) =>
              Math.abs(currentTime - a.current_time) -
              Math.abs(currentTime - b.current_time)
          )[0];

          const index = records.findIndex(
            (x) => closest.timestamp === x.timestamp
          );
          setSelectedRecordIndex(index);
        } else {
          setSelectedRecordIndex(-1);
        }
      } else {
        const closest = [...records].sort(
          (a, b) =>
            Math.abs(checkpoint.startTime - a.current_time) -
            Math.abs(checkpoint.startTime - b.current_time)
        )[0];
        const index = records.findIndex(
          (x) => closest.timestamp === x.timestamp
        );
        setSelectedRecordIndex(index);
        video.currentTime = checkpoint.startTime;
        video.pause();
      }
    }
  };

  const onTogglePlay = async () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        await videoElement.play();
      } else {
        videoElement.pause();
        videoElement.currentTime = checkpoint.startTime;
      }
      setStrigger(new Date().getTime());
    }
  };

  return (
    <Grid container xs={12}>
      <Grid className={classes.chart} xs={12}>
        <canvas ref={canvasRef} />
      </Grid>
      <Grid className={classes.chart} item sm={4} xs={12}>
        {selectedRecord && (
          <SelectedPoint
            studentKeypoints={getFlippedDisplayKeypoints(
              selectedRecord.keypoints
            )}
            instructorKeypoints={getFlippedDisplayKeypoints(
              checkpoint.keypoints
            )}
          />
        )}
      </Grid>

      <Grid
        item
        sm={2}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {selectedRecord && (
          <div style={{ height: 48, display: "flex", alignItems: "center" }}>
            <span>Regions used in calculations</span>
          </div>
        )}
        {selectedRecord && (
          <HumanRegionsAccuracy accuracyData={selectedRecord.joints_accuracy} />
        )}
        {selectedRecord && (
          <div
            style={{
              height: 48,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#57c9d5",
                  margin: 5,
                }}
              ></div>
              <span>not used</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#ff0000",
                  margin: 5,
                }}
              ></div>{" "}
              <span>to</span>{" "}
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#00ff00",
                  margin: 5,
                }}
              ></div>
              <span>scale represents accuracy </span>
            </div>
          </div>
        )}
      </Grid>
      <Grid item className={classes.chart} sm={6} xs={12}>
        <video
          src={filePath}
          ref={videoRef}
          muted={true}
          style={{ width: "100%" }}
          onTimeUpdate={onTimeUpdate}
          crossOrigin="anonymous"
        />
      </Grid>
      {videoRef.current && (
        <Grid item xs={12}>
          <IconButton
            aria-label={videoRef.current.paused ? "Play" : "Stop"}
            onClick={onTogglePlay}
            className={classes.button}
          >
            {videoRef.current.paused ? (
              <PlayArrowIcon fontSize="inherit" />
            ) : (
              <StopIcon fontSize="inherit" />
            )}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
