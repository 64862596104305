import React, { useState, ChangeEvent, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Typography,
  Tabs,
  Tab,
  Button,
  Divider,
} from "@material-ui/core";
import { TabPanel, ChannelHeader } from "./components";
import { useParams, useHistory } from "react-router-dom";
import ChannelVideoList from "../ChannelVideoList";
import * as channelProvider from "../../../providers/instructor/channel.provider";
import { Channel } from "../../../providers/instructor/channel.provider";
import ChannelPoseOrSkillList from "../ChannelPoseOrSkillList";
import ChannelClassList from "../ChannelClassList";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import ChannelExerciselList from "../ChannelExerciselList";
import { ChannelClassPracticeBlockList } from "../ClassPractice/ChannelBlockList";
import ChannelClassPracticeClassList from "../ClassPractice/ChannelClassPracticeClassList/ChannelClassPracticeClassList";
import { ChannelClassSequenceClassList } from "../ClassSequence/ChannelClassSequenceClassList";

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  publishButton: {
    zIndex: 1,
    marginBottom: theme.spacing(1),
    position: "absolute",
    top: 5,
    right: 5,
  },
}));

const getTabIndex = (tab: string | undefined): number => {
  if (!tab) {
    return 0;
  }

  const parsedTab = parseInt(tab);
  if (isNaN(parsedTab) || parsedTab < 0 || parsedTab > 6) {
    return 0;
  }
  return parsedTab;
};

type Params = {
  channelId: string;
  tab: string;
};

export default function ChannelDetails(props: any) {
  const classes = useStyles();
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [isPublishInProgress, setIsPublishStatusInProgress] =
    useState<boolean>(false);
  const { channelId, tab } = useParams<Params>();
  const value = getTabIndex(tab);
  const history = useHistory();

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    history.push(`/sports-instructor/channel/${channelId}/${newValue}`);
  };

  useEffect(() => {
    const getChannel = async () => {
      if (channelId) {
        const channel = await channelProvider.get(channelId);
        setChannel(channel);
      }
    };
    getChannel();
  }, []);

  const onPublishStatusChange = async () => {
    if (channel) {
      const newPublishStatus = !channel.is_published;
      setIsPublishStatusInProgress(true);
      try {
        await channelProvider.setPublished(channel.id, newPublishStatus);
        channel.is_published = newPublishStatus;
        setChannel(channel);
      } catch (err) {
        console.log(err);
      }
      setIsPublishStatusInProgress(false);
    }
  };

  const getPublishButtonText = (
    isPublishInProgress: boolean,
    channel: Channel
  ): string => {
    if (isPublishInProgress) {
      return "Loading";
    }
    return channel.is_published ? "Unpublish" : "Publish";
  };

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
      />
      <Divider />
      {channel && (
        <ChannelHeader channel={channel}>
          <Button
            disabled={isPublishInProgress}
            className={classes.publishButton}
            size="small"
            color="primary"
            variant="contained"
            onClick={onPublishStatusChange}
          >
            {getPublishButtonText(isPublishInProgress, channel)}
          </Button>
        </ChannelHeader>
      )}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab
            label={`Dynamic classes (${
              channel ? channel.class_sequence_count : 0
            })`}
            {...a11yProps(0)}
          />
          <Tab
            label={`Classes (${channel ? channel.class_count : 0})`}
            {...a11yProps(1)}
          />
          <Tab
            label={`Poses (${channel ? channel.pose_count : 0})`}
            {...a11yProps(2)}
          />
          <Tab
            label={`Exercises (${channel ? channel.exercise_count : 0})`}
            {...a11yProps(3)}
          />
          <Tab
            label={`Videos (${channel ? channel.video_count : 0})`}
            {...a11yProps(4)}
          />
          <Tab
            label={`Blocks (${channel ? channel.block_count : 0})`}
            {...a11yProps(5)}
          />
          <Tab
            label={`Explaining Classes (${
              channel ? channel.class_practice_count : 0
            })`}
            {...a11yProps(6)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {channelId && <ChannelClassSequenceClassList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {channelId && <ChannelClassList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {channelId && <ChannelPoseOrSkillList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {channelId && <ChannelExerciselList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {channelId && <ChannelVideoList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {channelId && <ChannelClassPracticeBlockList channelId={channelId} />}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {channelId && <ChannelClassPracticeClassList channelId={channelId} />}
      </TabPanel>
    </div>
  );
}
