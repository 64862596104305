import React from "react";
import NotImplementedComponent from "../../../components/NotImplemented";

type Props = {};

export default function AnalyzeCourse(props: Props) {
  return (
    <div>
      <NotImplementedComponent featureName={"Marketing Tools"} />
    </div>
  );
}
