import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ClassSequenceAnalysisDetails } from "../../types/class-sequence/class-sequence-analysis-details";

export type InstructorListItem = {
  id: string;
  name: string;
};

export type ChannelClassSequenceItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
};

export type ChannelClassSequenceListItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  channel_id: string;
  subscription_id: string;
  subscription_name: string;
  is_published?: boolean;
  first_name: string;
  last_name: string;
  class_type: string;
  duration: number;
};

export type ChannelClassSequenceResponse = {
  records: Array<ChannelClassSequenceListItem>;
  total: number;
};

export const getAll = async (
  pager: any,
  filter: any
): Promise<ChannelClassSequenceResponse> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-sequence/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        activity_id: filter["activityId"],
        instructor_id: filter["instructorId"],
      },
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getInstructors = async (): Promise<Array<InstructorListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-sequence/getInstructors",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getAllByChannel = async (
  channelId: string
): Promise<Array<ChannelClassSequenceListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-sequence/getAllByChannel",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getForAnalysis = async (
  id: string
): Promise<ClassSequenceAnalysisDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-sequence/getForAnalysis",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getForAnalysisTest = async (
  id: string
): Promise<ClassSequenceAnalysisDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-sequence/getForAnalysisTest",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};