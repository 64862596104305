import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const ChannelToolbar = (props) => {
  const { channelId, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          onClick={() => {
            history.push(
              `/sports-instructor/channel/${channelId}/class-practice/create`
            );
          }}
          color="primary"
          variant="contained"
        >
          Add Explaining Class
        </Button>
      </div>
    </div>
  );
};

ChannelToolbar.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ChannelToolbar;
