import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelVideoResponse = {
  records: Array<ChannelVideoItem>;
  total: number;
};

export type ChannelVideoItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  file_path: string;
};

export type ChannelVideoDetails = {
  name: string;
  file_path: string;
  creation_date: string;
  thumbnail_path: string;
  channel_id: string;
};

export const getAll = async (
  channelId: string,
  pager: any
): Promise<ChannelVideoResponse> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
      pager: pager
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getByChannel = async (
  channelId: string
): Promise<Array<ChannelVideoItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/getByChannel",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};


export const create = async (
  item: any,
  onUploadProgress: (progressEvent: any) => void
): Promise<ChannelVideoItem> => {
  const formData = new FormData();
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("channel_id", item.channelId);
  formData.append("video", item.video);
  formData.append("name", item.name);
  formData.append("duration", item.duration);
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelVideoDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/remove",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      channel_id: model.channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/video/edit",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      name: model.name,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
