import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelOption = {
  id: string;
  name: string;
  subscription_name: string;
  thumbnail_path: string;
  is_published: boolean;
};

export const getAll = async (): Promise<Array<ChannelOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (id: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/remove",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const toggleUnpublish = async (id: string, isPublished: boolean): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/toggle_unpublish",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
      is_published: isPublished
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

