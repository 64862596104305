import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as THREE from "three";
import { getLights } from "../../../../../../services/pose3d.service";
import { ClassView } from "../../../../../../types/threejs/class-view.type";
import {
  getTextMesh,
  halfWidth,
  wallHeight,
} from "../../../../../../services/analysis-scene.helper";

const useStyles = makeStyles((theme: Theme) => ({
  canvas: {
    width: "100%",
    height: 500,
  },
}));

type Props = {
  text?: string;
};

const alternateTextMeshName = "AlternateTex";

const LiveSkeletons: FC<Props> = (props) => {
  const classes = useStyles();
  const { text } = props;

  const mountRef = useRef<HTMLDivElement>(null);
  const fontRef = useRef<THREE.Font>();
  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();
  const sceneRef = useRef<THREE.Scene>();

  useEffect(() => {
    const initScene = async () => {
      const backgroundColor = new THREE.Color(0xd3d3d3);
      const getScene = (): THREE.Scene => {
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);
        const lights = getLights();
        lights.forEach((light) => scene.add(light));

        const material = new THREE.MeshBasicMaterial({
          color: backgroundColor,
        });
        const width = halfWidth * 2;
        const geometry = new THREE.PlaneGeometry(width, wallHeight);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(-halfWidth - 0.15, wallHeight / 2, 0);
        mesh.rotation.y = Math.PI /2;
        scene.add(mesh);
        return scene;
      };

      sceneRef.current = getScene();
      const mount = mountRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }

      const width = mount.clientWidth;
      const height = mount.clientHeight;
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(width, height);
      mount.appendChild(renderer.domElement);
      setRenderer(renderer);

      const fontLoader = new THREE.FontLoader();
      const font = await fontLoader.loadAsync("/fonts/Roboto_Regular.json");

      fontRef.current = font;
      const fov = 35;
      const eye3d = [5.0, 1.4, 0];
      const camera = new THREE.PerspectiveCamera(fov, width / height, 1, 50);
      camera.position.fromArray(eye3d);
      const view = {
        eye3d: eye3d,
        fov: fov,
        camera: camera,
      };
      setView(view);
    };

    initScene();
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    const font = fontRef.current;

    //TODO: generate text mesha and skale to fit wall

    if (mount && scene && renderer && view && font) {
      const prevText = scene.getObjectByName(alternateTextMeshName);
      if (prevText) {
        scene.remove(prevText);
      }
      if (text) {
        const newText = getTextMesh(text, font, halfWidth, wallHeight, alternateTextMeshName);
        scene.add(newText);
      }
      const camera = view.camera;

      if (camera) {
        camera.aspect = mount.clientWidth / mount.clientHeight;
        camera.lookAt(0, 1, 0);
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
      }
    }
  }, [text]);

  return <div className={classes.canvas} ref={mountRef}></div>;
};

export default LiveSkeletons;
