import React from "react";
import { Grid } from "@material-ui/core";
import { ClassList, ClassPracticeList, ClassSequenceList } from "./components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type Props = {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

export default function ChannelCourseList(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ClassList />
        </Grid>
        <Grid item xs={12}>
          <ClassSequenceList />
        </Grid>
        <Grid item xs={12}>
          <ClassPracticeList />
        </Grid>
      </Grid>
    </div>
  );
}
