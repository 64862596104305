import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const ExerciseToolbar = (props) => {
  const { className, channelId, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const onAdd = (channelId) => {
    history.push(`/sports-instructor/channel/${channelId}/exercise/create`);
  };

  const onAddFromVideo = (channelId) => {
    history.push(
      `/sports-instructor/channel/${channelId}/exercise/create-video`
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        {/* <Button
          onClick={() => {
            onAdd(channelId);
          }}
          color="primary"
          variant="contained"
        >
          Add Exercise from Images
        </Button>
        <span className={classes.spacer} /> */}
        <span className={classes.spacer} />
        <Button
          onClick={() => {
            onAddFromVideo(channelId);
          }}
          color="primary"
          variant="contained"
        >
          Add Exercise from Video
        </Button>
      </div>
      {/* <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
        />
      </div> */}
    </div>
  );
};

ExerciseToolbar.propTypes = {
  className: PropTypes.string,
  channelId: PropTypes.string.isRequired,
};

export default ExerciseToolbar;
