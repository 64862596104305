import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css'
type Props = {
  duration: number;
  currentTime: number;
};

export default function CornerCountdown(props: Props) {
  const { duration, currentTime } = props;
  const percentage = 100 - currentTime / (duration / 100); 
  return (
    <CircularProgressbarWithChildren
      value={100}
      strokeWidth={50}
      styles={buildStyles({
        pathColor: "black",
        trailColor: "black",
      })}
    >
      <div
        style={{
          width: "85%",
        }}
      >
        <CircularProgressbar
          value={percentage}
          text={`${Math.round(duration - currentTime)}`}
          strokeWidth={50}
          styles={buildStyles({
            textSize: "34px",
            textColor: "white",
            pathColor: "rgba(169,169,169, 0.5)",
            trailColor: "black",
            strokeLinecap: "butt",
          })}
        />
      </div>
    </CircularProgressbarWithChildren>
  );
}
