import React, { Component } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Divider,
} from "@material-ui/core";

import {
  ChannelsToolbar,
  ChannelCreateSlider,
  ChannelCard,
} from "./components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelProvider from "../../../providers/instructor/channel.provider";
import { ChannelOption } from "../../../providers/instructor/channel.provider";
import * as activityProvider from "../../../providers/common/activity.provider";
import { Activity } from "../../../providers/common/activity.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  channels?: Array<ChannelOption>;
  activities?: Array<Activity>;
  loading: boolean;
  confirmationData: any;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class ChannelList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      confirmationData: undefined
    };
  }

  componentDidMount = async () => {
    try {
      const channels = await channelProvider.getAll();
      const activities = await activityProvider.getAll();

      const workingChannelNames = ["Yoga", "Workout"];
      const sortedActivities = activities.reverse().sort((a, b) => {
        //HACK: temporary hack for yoga, should be removed
        if (
          workingChannelNames.indexOf(a.name) > -1 &&
          workingChannelNames.indexOf(b.name) === -1
        ) {
          return -1;
        } else if (
          workingChannelNames.indexOf(b.name) > -1 &&
          workingChannelNames.indexOf(a.name) === -1
        ) {
          return 1;
        } else if (
          workingChannelNames.indexOf(b.name) > -1 &&
          workingChannelNames.indexOf(a.name) > -1
        ) {
          return 0;
        }
        return 0;
      });
      this.setState({
        channels: channels,
        activities: sortedActivities,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  onRemove = async (id: string) => {
    this.setState({
      confirmationData: {
        id: id,
      },
    });
  };

  onRemoveClickHandler = async (data: any) => {
    await channelProvider.remove(data.id);
    this.setState({
      channels:
        this.state.channels && this.state.channels.filter((i) => i.id !== data.id),
    });
  };

  render = () => {
    const { channels, activities, loading, confirmationData } = this.state;

    const { classes, title, breadcrumbs } = this.props;

    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h4" gutterBottom>
          {title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={breadcrumbs}
          title={title}
        />
        <Divider />
        {activities && <ChannelCreateSlider activities={activities} />}
        <ChannelsToolbar />
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {channels &&
                channels.map((channel) => (
                  <Grid item key={channel.id} lg={3} md={6} xs={12}>
                    <ChannelCard channel={channel} onRemove={this.onRemove} />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
        <ConfirmationDialog
          handleConfirm={this.onRemoveClickHandler}
          dialogTitle={"Confirm channel removal"}
          data={confirmationData}
        />
      </div>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelList);
export default withRouter(styledComponent as any);
