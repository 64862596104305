import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import yogaInstructor from "../../../../../../../assets/images/yoga_instructor.jpg";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  Card,
  CardActionArea,
  Typography,
  Box,
  Avatar,
  Menu,
  Grid,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import defaultThumbnail from "../../../../../../../assets/images/channel-thumbnail.png";
import { useHistory } from "react-router-dom";
import * as authService from "../../../../../../../services/auth.service";
import clsx from "clsx";
import { AvatarType } from "../../../../../../../services/avatar/avatar-type.enum";

import { secondsToTimeRoundedString } from "../../../../../../../utils/conversion.functions";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  card: {
    background: "black",
    position: "relative",
    height: 200,
    borderRadius: 0,
    width: "100%",
  },
  cover: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  badge: {
    width: "fit-content",
    height: "fit-content",
    background: "#FFFFFF",
    boxShadow: "0 4px 4px rgba(0,0,0,0.25)",
    borderRadius: "4px",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 10,
    paddingLeft: 10,
  },
  sportType: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 1,
  },
  durationContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    fontWeight: 500,
    paddingLeft: 4,
    paddingRight: 4,
  },
  title: {
    color: "black",
    marginTop: 5,
    marginLeft: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 16,
    
  },
  description: {
    fontSize: 14,
    marginLeft: 10,
    
    whiteSpace: "nowrap",
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    color: "#797878",
  },
  instructorProfile: {
    marginTop: 5,
  },
  instructorName: {
    
    fontSize: 14,
    marginLeft: 10,
    marginRight: 15,
    color: "#797878",
  },
  moreOptions: {
    color: "#797878",
    marginTop: 5,
    cursor: "pointer",
  },
}));

const CourseCard = (props) => {
  const { course, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  /* Anchor Elements variables for dropdown menu*/
  const optionsAnchorEl = useRef(null);
  const [isOptionsAnchor, setIsOptionsAnchor] = useState(false);

  const onAnalysis = () => {
    //Note: channelId needed for membership check, remove this later
    history.push(
      `/sports-student/take-class-sequence/${course.id}/${course.channel_id}/${AvatarType.Male}`
    );
  };

  const onAnalysisTest = () => {
    history.push(
      `/sports-student/take-class-sequence-test/${course.id}/${course.channel_id}/${AvatarType.Male}`
    );
  };

  const onAnalysisTestXbot = () => {
    history.push(
      `/sports-student/take-class-sequence/${course.id}/${course.channel_id}/${AvatarType.Female}`
    );
  };

  const isAdmin = authService.isAdmin();

  return (
    <Box {...rest}>
      <Card className={classes.card} onClick={() => onAnalysis()}>
        <Typography
          className={clsx(classes.sportType, classes.badge)}
          variant="h5"
        >
          {course.class_type}
        </Typography>
        <div className={classes.durationContainer}>
          <span>{secondsToTimeRoundedString(course.duration)}</span>
        </div>
        <CardActionArea>
          <img
            alt="card thumbnail"
            className={classes.cover}
            src={course.thumbnail_path || defaultThumbnail}
            title={course.name}
          />
        </CardActionArea>
      </Card>
      <Box
        display="flex"
        style={{ marginTop: "10px", justifyContent: "space-between" }}
      >
        <Grid xs={10} style={{ display: "flex", flexDirection: "row" }}>
          <Avatar
            className={classes.instructorProfile}
            alt="instructor avatar"
            src={yogaInstructor}
          />
          <Box display="block" className={classes.container}>
            <Typography className={classes.title}>{course.name}</Typography>
            <Box display="block" style={{ marginTop: "5px" }}>
              <Typography className={classes.instructorName}>
                {course.first_name} {course.last_name}
              </Typography>
              <Typography className={classes.description}>
                {course.description}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={2}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <MoreVertIcon
            ref={optionsAnchorEl}
            className={classes.moreOptions}
            onClick={() => setIsOptionsAnchor(!isOptionsAnchor)}
          />
          <Menu
            id="simple-menu"
            anchorEl={optionsAnchorEl.current}
            keepMounted
            open={isOptionsAnchor}
            onClose={() => setIsOptionsAnchor(!isOptionsAnchor)}
          >
             <MenuItem key="course test xbot" onClick={onAnalysisTestXbot}>
              Female avatar
            </MenuItem>
            {isAdmin && (
              <MenuItem key="course test" onClick={onAnalysisTest}>
                Test
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Box>
    </Box>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseCard;
