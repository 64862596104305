import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: 10,
    position: "fixed",
    bottom: "15%",
    zIndex: 10,
    backgroundColor: "black",
    width: 350,
    height: 80,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  icon: {
    color: "white",
  },
}));

type Props = {
  isFullScreenEnabled: boolean;
  toggleFullScreen: () => void;
  isInstructorVideoMuted: boolean;
  toggleMute: () => void;
  isInstructorVideoPlaying: boolean;
  togglePlay: () => void;
  onExit: () => void;
};

export default function CenterCountdown(props: Props) {
  const {
    isInstructorVideoMuted,
    toggleMute,
    isInstructorVideoPlaying,
    togglePlay,
    isFullScreenEnabled,
    toggleFullScreen,
    onExit,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IconButton aria-label="Mute" onClick={toggleMute}>
        {!isInstructorVideoMuted ? (
          <VolumeUpIcon fontSize="large" className={classes.icon} />
        ) : (
          <VolumeOffIcon fontSize="large" className={classes.icon} />
        )}
      </IconButton>
      <IconButton aria-label="Play" onClick={togglePlay}>
        {isInstructorVideoPlaying ? (
          <PauseIcon fontSize="large" className={classes.icon} />
        ) : (
          <PlayArrowIcon fontSize="large" className={classes.icon} />
        )}
      </IconButton>
      <IconButton aria-label="Full screen" onClick={toggleFullScreen}>
        {!isFullScreenEnabled ? (
          <FullscreenIcon fontSize="large" className={classes.icon} />
        ) : (
          <FullscreenExitIcon fontSize="large" className={classes.icon} />
        )}
      </IconButton>
      <IconButton aria-label="Exit" onClick={onExit}>
        <ExitToAppIcon fontSize="large" className={classes.icon} />
      </IconButton>
    </div>
  );
}
