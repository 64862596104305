import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Pose3dEditor from "../../../../../components/editor/pose3d-editor";

import { Keypoint3D } from "../../../../../types/analyze/keypoint3d.type";
import { mediapipeKeypoint } from "../../../../../services/mediapipe";
import { AvatarType } from "../../../../../services/avatar/avatar-type.enum";
import HumanRegions from "../../../../../components/HumanRegions";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  thumbnail: {
    maxWidth: "100%",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
  formControl: {
    width: "100%",
  },
  info: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px !important",
  },
}));

type Props = {
  imagePath: string;
  keypoints: Keypoint3D[];
  stepIndex: number;
  onToggleRegion: (key: string, stepIndex: number) => void;
  regions: any;
};

const dimension = 400;

export default function PoseCreate(props: Props) {
  const { keypoints, imagePath, stepIndex, onToggleRegion, regions } = props;

  const classes = useStyles();

  const onRegionSelection = (key: string) => {
    onToggleRegion(key, stepIndex);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>Step #{stepIndex + 1}</Typography>
      </Grid>
      <Grid item xs={12}>
        <img className={classes.formControl} src={imagePath} />
      </Grid>
      <Grid item xs={6}>
        {keypoints && keypoints.length > 0 && (
          <Pose3dEditor
            height={dimension}
            width={dimension}
            keypoints={mediapipeKeypoint.mapParts(keypoints)}
            avatarType={AvatarType.Female}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: 16 }}>Regions of interest</Typography>
        </Grid>
        <Grid item xs={12} className={classes.info}>
          <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
          <Typography>If no regions will be selected, all regions will be used for calculations.</Typography>
        </Grid>
        <HumanRegions
          selectedRegions={regions}
          onRegionSelection={onRegionSelection}
        />
      </Grid>
    </Grid>
  );
}

