import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as axiosService from "./services/axios.service";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { Routes } from "./Routes";
import ReactGA from "react-ga";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

type Props = {};
const googleAnalyticsKey = "TODO"; //UA-25547510-3;
const options: ReactGA.InitializeOptions = {
  gaOptions: {
    siteSpeedSampleRate: 100, //NOTE: percentage of tracked users. Default value is 1
  },
};
ReactGA.initialize(googleAnalyticsKey, options);
const history = createBrowserHistory();
history.listen(() => {
  const { pathname, search } = window.location;
  const fullPath = `${pathname}${search}`;
  ReactGA.pageview(fullPath);
});
axiosService.configureInterceptors(history);

export default function App(props: Props) {
  useEffect(() => {
    const { pathname, search } = window.location;
    const fullPath = `${pathname}${search}`;
    ReactGA.pageview(fullPath);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
