import LiveTvIcon from "@material-ui/icons/LiveTv";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import DashboardIcon from "@material-ui/icons/Dashboard";

import { AppRoute } from "./app-route.type";
import { AppRouteLayout } from "./app-route-layout.enum";
import { RouteAccessRequirement } from "./route-access-requirement.enum";
import ChannelList from "../views/user/ChannelList";
import VideoList from "../views/user/VideoList";
import VideoDetails from "../views/user/VideoDetails";
import VideoCreate from "../views/user/VideoCreate";
import ChannelDetails from "../views/user/ChannelDetails";
import ChannelVideo from "../views/user/ChannelVideo";
import Activities from "../views/user/Activities";
import PictureCreate from "../views/user/PictureCreate";
import PictureDetails from "../views/user/PictureDetails";
import { AnalyzeClass, AnalyzeClassTest } from "../views/user/AnalyzeClass";
import ClassList from "../views/user/ClassList";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import ActivityDetail from "../views/user/ActivityDetail";
import ActivityTimespanDetails from "../views/user/ActivityTimespanDetails";
import TrainingPlansList from "../views/user/TrainingPlansList";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";

import {
  AnalyzeClassPractice,
  AnalyzeClassPracticeTest,
} from "../views/user/AnalyzeClassPractice";
import PracticeActivityDetail from "../views/user/PracticeActivityDetail";
import PracticeActivityBlockDetails from "../views/user/PracticeActivityBlockDetails";
import AnalyzeClassSequenceTest from "../views/user/AnalyzeClassSequence/AnalyzeClassSequenceTest";
import { AnalyzeClassSequence } from "../views/user/AnalyzeClassSequence";
import SequenceActivityDetails from "../views/user/SequenceActivityDetails";

const routePrefix = "/sports-student";

const getPath = (route: string): string => {
  return `${routePrefix}${route}`;
};

const takeClassRoute: AppRoute = {
  path: getPath("/take-class/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClass,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.None,
  title: "Take Class",
};

const takeClassTestRoute: AppRoute = {
  path: getPath("/take-class-test/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClassTest,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.None,
  title: "Take Class Test",
};

const takeClassPracticeRoute: AppRoute = {
  path: getPath("/take-class-practice/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClassPractice,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.None,
  title: "Take Class",
};

const takeClassPracticeTestRoute: AppRoute = {
  path: getPath(
    "/take-class-practice-test/:classId/:channelId/:modelTypeString"
  ),
  component: AnalyzeClassPracticeTest,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.None,
  title: "Take Class Test",
};

const takeClassSequenceRoute: AppRoute = {
  path: getPath("/take-class-sequence/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClassSequence,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.None,
  title: "Take Class",
};

const takeClassSequenceTestRoute: AppRoute = {
  path: getPath(
    "/take-class-sequence-test/:classId/:channelId/:modelTypeString"
  ),
  component: AnalyzeClassSequenceTest,
  accessRequirement: RouteAccessRequirement.Login,
  layout:  AppRouteLayout.None,//AppRouteLayout.Minimal
  title: "Take Class Test",
};

export const analyzeRoutes: Array<AppRoute> = [
  takeClassRoute,
  takeClassTestRoute,
  takeClassPracticeRoute,
  takeClassPracticeTestRoute,
  takeClassSequenceRoute,
  takeClassSequenceTestRoute,
];

const pictureCreateRoute: AppRoute = {
  path: getPath("/picture/create"),
  component: PictureCreate,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Upload Picture",
};

const pictureDetailsRoute: AppRoute = {
  path: getPath("/picture/details"),
  component: PictureDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Picture Details",
};

export const pictureRoutes: Array<AppRoute> = [
  pictureCreateRoute,
  pictureDetailsRoute,
];

const channelsRoute: AppRoute = {
  path: getPath("/channels"),
  title: "Channels",
  component: ChannelList,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: LiveTvIcon,
};

const channelDetailsRoute: AppRoute = {
  path: getPath("/channel/:channelId"),
  component: ChannelDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Channel Details",
  breadcrumbs: [channelsRoute],
};

const channelVideoRoute: AppRoute = {
  path: getPath("/channel/video/:videoId"),
  component: ChannelVideo,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Channel Video",
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

export const channelRoutes: Array<AppRoute> = [
  channelDetailsRoute,
  channelVideoRoute,
];

const classesRoute: AppRoute = {
  path: getPath("/skills-video-classes"),
  title: "Classes",
  component: ClassList,
  accessRequirement: RouteAccessRequirement.None,
  layout: AppRouteLayout.Main,
  icon: PhotoSizeSelectLargeIcon,
};

const activitiesRoute: AppRoute = {
  path: getPath("/activities"),
  title: "My Activities",
  component: Activities,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

const activitiyDetailsRoute: AppRoute = {
  path: getPath("/activity/:activityId"),
  title: "Activity Details",
  component: ActivityDetail,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

const practiceActivitiyDetailsRoute: AppRoute = {
  path: getPath("/practice-activity/:activityId"),
  title: "Practice Activity Details",
  component: PracticeActivityDetail,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

const activityTimespanDetails: AppRoute = {
  path: getPath("/activity-timespan/:timespanId"),
  title: "Activity Timespan Details",
  component: ActivityTimespanDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

const practiceActivityBlockDetails: AppRoute = {
  path: getPath("/practice-activity-block/:blockId"),
  title: "Activity Block Details",
  component: PracticeActivityBlockDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

const sequenceActivitiyDetailsRoute: AppRoute = {
  path: getPath("/sequence-activity/:activityId"),
  title: "Activity Details",
  component: SequenceActivityDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: DashboardIcon,
};

export const activitiesRoutes: Array<AppRoute> = [
  activitiyDetailsRoute,
  activityTimespanDetails,
  practiceActivitiyDetailsRoute,
  practiceActivityBlockDetails,
  sequenceActivitiyDetailsRoute,
];

const videosRoute: AppRoute = {
  path: getPath("/videos"),
  title: "My Videos",
  component: VideoList,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: VideoLabelIcon,
};

const trainingPlansRoute: AppRoute = {
  path: getPath("/training-plans"),
  title: "My Training Plans",
  component: TrainingPlansList,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: AccessibilityNewIcon,
};

export const sidebarRoutes: Array<AppRoute> = [
  classesRoute,
  activitiesRoute,
  trainingPlansRoute,
];

const videoCreateRoute: AppRoute = {
  path: getPath("/video/create"),
  component: VideoCreate,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Upload Video",
  breadcrumbs: [videosRoute],
};

const videoDetailsRoute: AppRoute = {
  path: getPath("/video/details"),
  component: VideoDetails,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  title: "Video Details",
  breadcrumbs: [videosRoute],
};

export const videoRoutes: Array<AppRoute> = [
  videoCreateRoute,
  videoDetailsRoute,
];

export const routes = sidebarRoutes
  .concat(activitiesRoutes)
  .concat(analyzeRoutes)
  .concat(videoRoutes)
  .concat(channelRoutes)
  .concat(pictureRoutes);
