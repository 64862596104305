import React, { Fragment, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import * as classProvider from "../../../providers/admin/channel-class.provider";
import * as classPracticeProvider from "../../../providers/admin/channel-class-practice.provider";
import * as classSequenceProvider from "../../../providers/admin/channel-class-sequence.provider";
import { AnalyzeClassSequenceTestFile } from "../../user/AnalyzeClassSequence";
import { AnalyzeClassPracticeTestFile } from "../../user/AnalyzeClassPractice";
import AnalyzeClassTestMediapipeWasmKalmanCombinedFile from "../../user/AnalyzeClass/File";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  video: {
    width: "100%",
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  info: {
    display: "flex",
    alignItems: "center",
  },
  error: {
    color: "#e53935",
    marginLeft: 14,
    marginRight: 14,
    fontSize: 11,
    marginTop: 3,
    textAlign: "left",

    fontWeight: 400,
    lineheight: 13,
    letterSpacing: 0.33,
  },
}));

export default function AdminVideoTest() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const classes = useStyles();
  const [videoObjectUrl, setVideoObjectUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [video, setVideo] = useState<File>();

  const [radioValue, setRadioValue] = useState<string>("Class");
  const [courses, setCourses] =
    useState<Array<classProvider.ChannelClassOption>>();
  const [practiceCourses, setPracticeCourses] =
    useState<Array<classPracticeProvider.ChannelClassOption>>();
  const [sequenceCourses, setSequenceCourses] =
    useState<Array<classSequenceProvider.ChannelClassOption>>();

  const [selectedCource, setSelectedCource] =
    useState<classProvider.ChannelClassOption>();
  const [selectedPracticeCource, setSelectedPracticeCource] =
    useState<classPracticeProvider.ChannelClassOption>();
  const [selectedSequenceCource, setSelectedSequenceCource] =
    useState<classSequenceProvider.ChannelClassOption>();

  const [testInProgress, setTestInProgress] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const result = await Promise.all([
        await classProvider.getAll(),
        await classPracticeProvider.getAll(),
        await classSequenceProvider.getAll(),
      ]);
      setCourses(result[0]);
      setPracticeCourses(result[1]);
      setSequenceCourses(result[2]);
    };
    load();
    setLoading(false);
  }, []);

  const onChange = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 1) {
      const video = acceptedFiles[0];
      setVideoObjectUrl(URL.createObjectURL(video));
      setVideo(video);
    }
  };

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setRadioValue(value);
  };

  const onTest = () => {
    setTestInProgress(true);
  };

  const getForm = () => {
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            {loading && <LinearProgress />}
            <Grid item xs={12}>
              <Dropzone onDrop={onChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: loading
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: "video/*",
                          disabled: loading,
                        })}
                      />
                      <p>
                        Drag 'n' drop video(*.mp4) here, or click to select
                        video.
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
            {videoObjectUrl && (
              <Grid item xs={12}>
                <video
                  className={classes.video}
                  ref={videoRef}
                  playsInline
                  crossOrigin="anonymous"
                  src={videoObjectUrl}
                  // onLoadedMetadata={onLoadedMetadata}
                ></video>
              </Grid>
            )}
            <Grid item xs={12}>
              {video && (
                <Typography color="textSecondary" variant="h6" component="h2">
                  {video.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                aria-label="timespan-radio"
                name="timespan-radio"
                value={radioValue}
                onChange={onRadioChange}
              >
                <FormControlLabel
                  value="Class"
                  control={<Radio />}
                  label="Class"
                />
                <FormControlLabel
                  value="Dynamic"
                  control={<Radio />}
                  label="Dynamic"
                />
                <FormControlLabel
                  value="Explaining"
                  control={<Radio />}
                  label="Explaining"
                />
              </RadioGroup>
            </Grid>
            {radioValue === "Class" && courses && (
              <Autocomplete
                value={selectedCource}
                onChange={(event: any, newValue: any | null) => {
                  setSelectedCource(newValue);
                }}
                options={courses}
                getOptionLabel={(option) => option.name}
                renderOption={(option: any) => (
                  <React.Fragment>
                    <Avatar
                      alt={option.name}
                      src={option.thumbnail_path}
                      style={{ margin: "0px 10px 0px 0px" }}
                    />
                    <ListItemText primary={option.name} />
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Combo box" variant="outlined" />
                )}
              />
            )}
            {radioValue === "Dynamic" && sequenceCourses && (
              <Autocomplete
                value={selectedSequenceCource}
                onChange={(event: any, newValue: any | null) => {
                  setSelectedSequenceCource(newValue);
                }}
                options={sequenceCourses}
                getOptionLabel={(option) => option.name}
                renderOption={(option: any) => (
                  <React.Fragment>
                    <Avatar
                      alt={option.name}
                      src={option.thumbnail_path}
                      style={{ margin: "0px 10px 0px 0px" }}
                    />
                    <ListItemText primary={option.name} />
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Combo box" variant="outlined" />
                )}
              />
            )}
            {radioValue === "Explaining" && practiceCourses && (
              <Autocomplete
                value={selectedPracticeCource}
                onChange={(event: any, newValue: any | null) => {
                  setSelectedPracticeCource(newValue);
                }}
                options={practiceCourses}
                getOptionLabel={(option) => option.name}
                renderOption={(option: any) => (
                  <React.Fragment>
                    <Avatar
                      alt={option.name}
                      src={option.thumbnail_path}
                      style={{ margin: "0px 10px 0px 0px" }}
                    />
                    <ListItemText primary={option.name} />
                  </React.Fragment>
                )}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Combo box" variant="outlined" />
                )}
              />
            )}

            <Grid item xs={12} className={classes.buttonsContainer}>
              <Button
                disabled={loading || !videoObjectUrl}
                onClick={onTest}
                variant="contained"
                color="primary"
              >
                Process
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    );
  };

  const getProcessingView = () => {
    if (videoObjectUrl) {
      if (radioValue === "Class" && selectedCource) {
        return (
          <AnalyzeClassTestMediapipeWasmKalmanCombinedFile
            videoSrc={videoObjectUrl}
            classId={selectedCource.id}
          />
        );
      }
      if (radioValue === "Explaining" && selectedPracticeCource) {
        return (
          <AnalyzeClassPracticeTestFile
            videoSrc={videoObjectUrl}
            classId={selectedPracticeCource.id}
          />
        );
      }
      if (radioValue === "Dynamic" && selectedSequenceCource) {
        return (
          <AnalyzeClassSequenceTestFile
            videoSrc={videoObjectUrl}
            classId={selectedSequenceCource.id}
          />
        );
      }
    }
  };

  return (
    <Fragment>{!testInProgress ? getForm() : getProcessingView()}</Fragment>
  );
}
