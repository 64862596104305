import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ClassCreateTimespan } from "../../types/class/class-create-timespan";

export type ChannelClassOption = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  is_published: boolean;
  pose_count: number;
  exercise_count: number;
  description: string;
  avatar: string;
};

export type ChannelClass = {
  name: string;
  description: string;
  file_path: string;
  thumbnail_path: string;
  timespans: ClassCreateTimespan[];
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelClassOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const createNew = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/createNew",
    data: {
      channel_id: item.channelId,
      name: item.name,
      timespans: item.timespans,
      video_id: item.videoId,
      description: item.description,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/edit",
    data: {
      id: item.classId,
      name: item.name,
      timespans: item.timespans,
      description: item.description,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClass> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/remove",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      channel_id: model.channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelClassEditDetails = {
  name: string;
  file_path: string;
  thumbnail_path: string;
};

export const setPublished = async (
  classId: string,
  isPublished: boolean
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class/setPublished",
    data: {
      id: classId,
      is_published: isPublished,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
