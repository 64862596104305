import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../services/pose3d.service";
import { ClassView } from "../../../types/threejs/class-view.type";
import { IconButton } from "@material-ui/core";
import * as THREE from "three";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../services/avatar/avatar.service";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../services/keypoint.helper";
import {
  getAccuracyLabel,
  getCurrentPoseLabel,
  getCurrentInstructorPoseMesh,
  getInstructorBackground,
  getInstructorVideoMesh,
  getStudentBackground,
  getStudentVideoMesh,
  getTextBackground,
  getTextGeometry,
  setSize,
  removeBlockLabel,
  getBlockLabel,
} from "./ui.helper";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import * as rewardService from "../../../services/reward/reward.service";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import { RewardType } from "../../../enums/reward-type.enum";
import { rotateAroundAxis } from "../../../services/mediapipe/mediapipe.keypoint";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import { LiveClassPracticeModel } from "../../../types/analyze/live-class-practice.type";
import {
  defaultRegions,
  getJointsFromRegions,
} from "../../../services/avatar/regions.service";
import { BlockVideoType } from "../../../types/analyze/block-video-type.enum";
import BlockCircularProgressBar from "./components/BlockCircularProgressBar";
import {
  getTextMesh,
  halfWidth,
  wallHeight,
} from "../../../services/analysis-scene.helper";
import VideoDurationTime from "./components/VideoDurationTime";
import { BlocksProgressBar } from "./components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  canvas: {
    width: "100%",
    height: "100%",
  },
  canvasBorder: {
    "& canvas": {
      border: "4px solid red",
    },
  },
  iconButton: {
    fontSize: "3rem !important",
  },
  rotateLeft: {
    left: 5,
    bottom: "30%",
    position: "absolute",
  },
  rotateRight: {
    right: 5,
    bottom: "30%",
    position: "absolute",
  },
}));

type Props = {
  currentBlockVideoType: BlockVideoType;
  currentBlock?: ClassPracticeAnalysisBlock;
  instructorVideo: HTMLVideoElement;
  studentCanvas: HTMLCanvasElement;
  avatarType: AvatarType;
  selfieMode: boolean;
  //NOTE: used only as assiciation with video time
  currentTime: number;
  //NOTE: current time of explanation video or practice part(video can be replayed to fit duration)
  videoPartCurrentTime: number;

  onPoseAccuracyCalculated: (
    accuracy: number,
    live: LiveClassPracticeModel
  ) => void;
  live?: LiveClassPracticeModel;
  blocks: ClassPracticeAnalysisBlock[];
  onExercisePeakReached: (
    accuracyPercentages: { [key: number]: number },
    live: LiveClassPracticeModel,
    repetition: number
  ) => void;
  onSaveReward: (
    reward: RewardType,
    blockId: string,
    blockOrder: number
  ) => void;
  onRepetition: (text: string) => void;
  onPlayBeep: () => void;
  font: THREE.Font;
  displayOutOfFrameNotificationBorder: boolean;
  skipExplanations: boolean;
  isTest?: boolean;
};

const studentRootName = "root-student";
const studentDisplayRootName = "root-student-display";
const instructorRootName = "root-instructor";
const instructorDisplayRootName = "root-instructor-display";
const alternateTextMeshName = "AlternateText";

const usePrevious = <T extends {}>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const LiveSkeletons: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    instructorVideo,
    studentCanvas,
    avatarType,
    selfieMode,
    currentTime,
    videoPartCurrentTime,
    onPoseAccuracyCalculated,
    live,
    blocks,
    currentBlock,
    onExercisePeakReached,
    onSaveReward,
    onRepetition,
    onPlayBeep,
    currentBlockVideoType,
    font,
    displayOutOfFrameNotificationBorder,
    skipExplanations,
    isTest,
  } = props;

  const blockId = currentBlock ? currentBlock.id : undefined;

  const getInstructorPoseName = (
    currentBlockVideoType: BlockVideoType,
    block?: ClassPracticeAnalysisBlock
  ) => {
    //TODO: test
    if (!block || currentBlockVideoType !== BlockVideoType.Practice) {
      return undefined;
    }

    if (block.pose) {
      return block.pose.name;
    }
    if (block.exercise) {
      return `${block.exercise.name}(${block.exercise.repetitions})`;
    }
  };

  const getInstructorImageSrc = (
    currentBlockVideoType: BlockVideoType,
    block?: ClassPracticeAnalysisBlock
  ) => {
    //TODO: test
    if (!block || currentBlockVideoType !== BlockVideoType.Practice) {
      return undefined;
    }

    if (block.pose) {
      return block.pose.image_path;
    }
    if (block.exercise) {
      if (nextExerciseStepIndex !== undefined) {
        return block.exercise.steps[nextExerciseStepIndex].image_path;
      }
      //TODO: rework
      return block.exercise.steps[0].image_path;
    }
  };

  const prevBlockId = usePrevious<any>(blockId);

  const mountRef = useRef<HTMLDivElement>(null);

  const poseNameRef = useRef<THREE.Mesh>();
  const poseNameBackgroundRef = useRef<THREE.Mesh>();
  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();

  const instructorVideoTextureRef = useRef<THREE.VideoTexture>();

  const studentVideoTextureRef = useRef<THREE.CanvasTexture>();

  const sceneRef = useRef<THREE.Scene>();

  const instructorImageMeshRef = useRef<THREE.Mesh>();
  const instructorImageTextureRef = useRef<THREE.Texture>();
  const instructorImageMaterialRef = useRef<THREE.MeshBasicMaterial>();

  const instructorImageBackgroundRef = useRef<THREE.Mesh>();

  const accuracyLabelMeshRef = useRef<THREE.Mesh>();
  const accuracyBackgroundMeshRef = useRef<THREE.Mesh>();

  const [blockAccuracy, setBlockAccuracy] = useState<
    { percentage: number; currentTime: number; videoPartCurrentTime: number }[]
  >([]);

  const [blockProgressPercentage, setBlockProgressPercentage] =
    useState<number>(0);

  const [nextExerciseStepIndex, setNextExerciseStepIndex] = useState<number>(0);

  const [repsCounter, setRepsCounter] = useState<number>(0);

  const instructorPoseName = getInstructorPoseName(
    currentBlockVideoType,
    currentBlock
  );

  const [prevExerciseAccuracy, setPrevExerciseAccuracy] = useState<{
    [key: number]: number;
  }>({});

  const [renderTrigger, setRenderTrigger] = useState<number>();

  const instructorImageSrc = getInstructorImageSrc(
    currentBlockVideoType,
    currentBlock
  );

  const rotateCamera = (degrees: number) => {
    if (view) {
      const camera = view.camera;
      const quaternion = new THREE.Quaternion();
      const radians = degrees * (Math.PI / 180);
      quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), radians);
      camera.position.applyQuaternion(quaternion);

      view.eye3d = camera.position.clone().toArray();
      setView(view);

      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (mount && scene && renderer && view) {
        pose3dService.renderCourseThree(mount, scene, renderer, view);
      }
    }
  };

  const getBlockRewardName = (index: number): string => {
    return `${index}BlockReward`;
  };

  const updateAccuracyLabelValue = (value: number) => {
    const accuracyLabel = accuracyLabelMeshRef.current;
    const accuracyLabelBackground = accuracyBackgroundMeshRef.current;
    if (accuracyLabel && accuracyLabelBackground) {
      const shapes = font.generateShapes(`${value}`, 0.11);
      const geometry = getTextGeometry(shapes);
      accuracyLabel.geometry.dispose();
      accuracyLabel.geometry = geometry;

      const material = accuracyLabel.material as THREE.Material;
      material.visible = true;

      (accuracyLabelBackground.material as THREE.Material).visible = true;
    }
  };

  const hideAccuracyLabel = () => {
    const accuracyLabel = accuracyLabelMeshRef.current;
    const accuracyLabelBackground = accuracyBackgroundMeshRef.current;
    if (accuracyLabel && accuracyLabelBackground) {
      const material = accuracyLabel.material as THREE.Material;
      material.visible = false;
      (accuracyLabelBackground.material as THREE.Material).visible = false;
    }
  };

  useEffect(() => {
    const initScene = async () => {
      const backgroundColor = new THREE.Color(0xd3d3d3);

      const getVideoTexture = (video: HTMLVideoElement): THREE.VideoTexture => {
        const texture = new THREE.VideoTexture(video);
        return texture;
      };

      const getCanvasTexture = (
        canvas: HTMLCanvasElement
      ): THREE.CanvasTexture => {
        const texture = new THREE.CanvasTexture(canvas);
        return texture;
      };

      instructorVideoTextureRef.current = getVideoTexture(instructorVideo);
      studentVideoTextureRef.current = getCanvasTexture(studentCanvas);

      const getScene = (
        instructorTexture: THREE.VideoTexture,
        instructorVideo: HTMLVideoElement,
        studentTexture: THREE.CanvasTexture,
        studentVideo: HTMLCanvasElement
      ): THREE.Scene => {
        const scene = pose3dService.sceneSetup();
        scene.add(getStudentBackground(backgroundColor, wallHeight, halfWidth));
        const instructorVideoMesh = getInstructorVideoMesh(
          instructorTexture,
          instructorVideo,
          wallHeight,
          halfWidth
        );

        scene.add(instructorVideoMesh);

        const studentVideoMesh = getStudentVideoMesh(
          studentTexture,
          studentVideo,
          wallHeight,
          halfWidth
        );
        scene.add(studentVideoMesh.mesh);
        scene.add(studentVideoMesh.backgroundMesh);

        const instructorBackground = getInstructorBackground(
          backgroundColor,
          wallHeight,
          halfWidth
        );
        instructorImageBackgroundRef.current = instructorBackground;
        scene.add(instructorBackground);
        return scene;
      };

      sceneRef.current = getScene(
        instructorVideoTextureRef.current,
        instructorVideo,
        studentVideoTextureRef.current,
        studentCanvas
      );
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }
      const renderer = pose3dService.getCourseRenderer(mount);
      setRenderer(renderer);

      if (instructorPoseName) {
        const text = getCurrentPoseLabel(instructorPoseName, font, halfWidth);
        const box = text.geometry.boundingBox;
        if (box) {
          const mesh = getTextBackground(box, text.position.z);
          poseNameBackgroundRef.current = mesh;
          scene.add(mesh);
        }

        poseNameRef.current = text;
        scene.add(text);
      }

      const view = pose3dService.getCourseView(mount);
      setView(view);

      const addStudentModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentRootName;

        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });

        scene.add(model);
        avatarService.init(model);
      };

      const addStudentDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.shininess = 10;
            object.material.color = new THREE.Color(0x0072fa);
            object.material.visible = false;
            object.castShadow = true;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.transparent = true;
            object.material.opacity = 0.5;
            object.material.color = new THREE.Color(0x00ff00);
            object.material.visible = false;
          }
        });
        model.position.z = halfWidth / 2;
        scene.add(model);
        avatarService.init(model);
      };

      const addAccuracyLabel = () => {
        const shapes = font.generateShapes("", 0.11);
        const geometry = getTextGeometry(shapes);
        const mesh = getAccuracyLabel(geometry, wallHeight, halfWidth);

        const getAccuracyLabelBackground = (position: THREE.Vector3) => {
          const geometry = new THREE.CircleGeometry(0.12, 50);
          const material = new THREE.MeshBasicMaterial({
            color: 0x000000,
            transparent: true,
            opacity: 0.1,
            visible: false,
          });
          const mesh = new THREE.Mesh(geometry, material);

          mesh.position.set(
            position.x - 0.01,
            position.y + 0.05,
            position.z + 0.0005
          );
          mesh.rotation.y = Math.PI;
          mesh.rotation.z = Math.PI / 2;
          return mesh;
        };
        const background = getAccuracyLabelBackground(mesh.position);
        scene.add(background);
        accuracyBackgroundMeshRef.current = background;
        scene.add(mesh);
        accuracyLabelMeshRef.current = mesh;
      };

      await rewardService.loadStarModel();
      await addStudentModel();
      await addStudentDisplayModel();
      await addInstructorModel();
      if (isTest) {
        await addInstructorDisplayModel();
      }
      addAccuracyLabel();
    };

    initScene();

    const videoUpdateInterval = setInterval(() => {
      const instructorVideoTexture = instructorVideoTextureRef.current;
      if (instructorVideoTexture) {
        instructorVideoTexture.needsUpdate = true;
      }

      const studentVideoTexture = studentVideoTextureRef.current;
      if (studentVideoTexture) {
        studentVideoTexture.needsUpdate = true;
      }

      setRenderTrigger(new Date().getTime());
    }, 120);

    const onWindowResize = () => {
      //TODO: update, replace hardcoded value
      const headerHeight = 64;
      if (view && renderer) {
        view.camera.aspect = window.innerWidth / window.innerHeight;
        view.camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight - headerHeight);
      }
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      const cleanupAvatar = (scene: THREE.Scene, name: string) => {
        const avatar = scene.getObjectByName(name);
        if (avatar) {
          avatar.traverse((child: any) => {
            if (child.isMesh) {
              pose3dService.cleanupObject(child);
            }
          });
          scene.remove(avatar);
        }
      };
      const scene = sceneRef.current;
      if (scene) {
        cleanupAvatar(scene, studentRootName);
        cleanupAvatar(scene, studentDisplayRootName);
        cleanupAvatar(scene, instructorRootName);
      }
      //TODO: DISPOSE EVERYTHING!!
      //https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      //something to think about https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
      clearInterval(videoUpdateInterval);
    };
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    if (mount && scene && renderer && view) {
      const avatar = scene.getObjectByName(studentDisplayRootName);
      if (avatar) {
        if (currentBlockVideoType === BlockVideoType.Practice) {
          avatar.traverse((object: any) => {
            if (object.isMesh) {
              object.material.visible = true;
            }
          });
        } else {
          avatar.traverse((object: any) => {
            if (object.isMesh) {
              object.material.visible = false;
            }
          });
        }
      }

      pose3dService.renderCourseThree2(
        mount,
        scene,
        renderer,
        view,
        currentBlockVideoType
      );
    }
  }, [renderTrigger]);

  const getTextureFromPath = async (
    imagePath: string
  ): Promise<THREE.Texture> => {
    const textureLoader = new THREE.TextureLoader();
    const texture = await textureLoader.loadAsync(imagePath);
    //NOTE: https://discourse.threejs.org/t/warning-from-threejs-image-is-not-power-of-two/7085
    texture.minFilter = THREE.LinearFilter;
    texture.generateMipmaps = false;
    return texture;
  };

  const getMaterial = (texture: THREE.Texture): THREE.MeshBasicMaterial => {
    const material = new THREE.MeshBasicMaterial({ map: texture });
    return material;
  };

  useEffect(() => {
    const onChange = (
      scene: THREE.Scene,
      prevBlockId: string | undefined,
      progressPercentage: number
      //blockId: string | undefined
    ) => {
      //TODO: reword, position connected to elements set in external component

      const prevIndex = blocks.findIndex((t) => t.id === prevBlockId);
      if (prevBlockId && prevIndex >= 0) {
        const prevBar = scene.getObjectByName(prevBlockId) as THREE.Mesh;
        if (prevBar) {
          const prevPosition = prevBar.position;
          const prevRotation = prevBar.rotation;

          prevBar.material = new THREE.MeshBasicMaterial({
            color: new THREE.Color(0xff0000),
          });

          const star = rewardService.getStarModel(progressPercentage);
          const reward = rewardService.getReward(progressPercentage);

          const prevBlock = blocks[prevIndex];
          if (prevBlock) {           
            onSaveReward(reward, prevBlock.id, prevBlock.order);
          }
          if (star) {
            const starClone = star.clone();
            starClone.name = getBlockRewardName(prevIndex);
            starClone.scale.set(0.0007, 0.0007, 0.0007);
            starClone.position.set(
              prevPosition.x + 0.15,
              prevPosition.y + wallHeight - 0.15,
              prevPosition.z
            );
            starClone.rotation.setFromQuaternion(
              new THREE.Quaternion().setFromEuler(prevRotation)
            );

            scene.add(starClone);
          }
        }
      }
      setBlockAccuracy([]);
      setBlockProgressPercentage(0);
    };

    const setExerciseVariables = (block?: ClassPracticeAnalysisBlock) => {
      if (block && block.exercise) {
        setNextExerciseStepIndex(0);
        setPrevExerciseAccuracy({});
        setRepsCounter(0);
      }
    };

    const scene = sceneRef.current;
    if (scene) {
      onChange(scene, prevBlockId, blockProgressPercentage);
    }
    setExerciseVariables(currentBlock);
    hideAccuracyLabel();
  }, [currentBlock]);

  useEffect(() => {
    const onChange = async (imagePath: string | undefined) => {
      const instructorMaterial = instructorImageMaterialRef.current;
      const instructorTexture = instructorImageTextureRef.current;
      const scene = sceneRef.current;
      if (scene) {
        if (imagePath) {
          const newTexture = await getTextureFromPath(imagePath);
          const mesh = instructorImageMeshRef.current;
          if (instructorMaterial && mesh) {
            instructorMaterial.visible = true;
            instructorMaterial.map = newTexture;
            if (instructorTexture) {
              instructorTexture.dispose();
            }
            const aspectRatio =
              newTexture.image.width / newTexture.image.height;
            const width = wallHeight * aspectRatio;
            setSize(mesh, width, wallHeight);
          } else {
            const material = getMaterial(newTexture);
            const mesh = getCurrentInstructorPoseMesh(
              material,
              newTexture.image,
              wallHeight,
              halfWidth
            );
            scene.add(mesh);
            instructorImageMaterialRef.current = material;
            instructorImageMeshRef.current = mesh;
          }
          instructorImageTextureRef.current = newTexture;
        } else {
          if (instructorMaterial) {
            instructorMaterial.visible = false;
            if (instructorTexture) {
              instructorTexture.dispose();
            }
          }
        }
      }
    };

    onChange(instructorImageSrc);
  }, [instructorImageSrc]);

  useEffect(() => {
    const getCoordinates = (
      scene: THREE.Scene,
      keypoints: Keypoint3D[],
      name: string
    ) => {
      if (keypoints.length === 0) {
        return;
      }
      const root = scene.getObjectByName(name);
      if (!root) {
        return;
      }

      avatarService.poseUpdate(
        root,
        keypoints.map((kp) => {
          return {
            x: kp.x,
            y: kp.y,
            z: kp.z,
            part: kp.part,
            visibility: kp.visibility,
          };
        })
      );
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
      const coordinates = avatarFeedback.getCoordinates(root);
      return coordinates;
    };

    const processPoseBlock = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      displayInstructorKeypoints: Keypoint3D[],
      live: LiveClassPracticeModel,
      regions: { [key: string]: boolean },
      currentBlock: ClassPracticeAnalysisBlock
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorThresholdKeypoints = getCoordinates(
        scene,
        displayInstructorKeypoints,
        instructorRootName
      );

      if (instructorThresholdKeypoints && studentThresholdKeypoints) {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );
        if (alignedLowerInstructorKeypoints) {
          const filtered = alignedLowerInstructorKeypoints.filter(
            (kp) => avatarFeedback.lowerCoordinateJoints.indexOf(kp.part) !== -1
          );

          avatarFeedback.applyFeedback(
            scene,
            filtered,
            studentThresholdKeypoints,
            "mixamorigHips",
            avatarFeedback.lowerCoordinateJoints
          );
        }

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );
        if (alignedUpperInstructorKeypoints) {
          const filtered = alignedUpperInstructorKeypoints.filter(
            (kp) => avatarFeedback.upperCoordinateJoints.indexOf(kp.part) !== -1
          );

          avatarFeedback.applyFeedback(
            scene,
            filtered,
            studentThresholdKeypoints,
            "mixamorigNeck",
            avatarFeedback.feedbackJointsUpper
          );
        }

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          getJointsFromRegions(regions)
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        onPoseAccuracyCalculated(accuracyPercentage, live);

        if (blockId && currentTime && videoPartCurrentTime) {
          const newTimespanAccuracy = blockAccuracy.map((i) => i);
          newTimespanAccuracy.push({
            percentage: accuracyPercentage,
            currentTime: currentTime,
            videoPartCurrentTime: videoPartCurrentTime,
          });
          setBlockAccuracy(newTimespanAccuracy);

          const calculateProgressValue = (
            blockAccuracy: any[],
            currentBlock: ClassPracticeAnalysisBlock
          ) => {
            const thresholdPercentage = 85;
            const fullProgressBarSeconds = currentBlock.pose
              ? currentBlock.pose.duration
              : 15;
            const percentageValue = fullProgressBarSeconds / 100;
            const durationSeconds = blockAccuracy.reduce((acc, curr, index) => {
              if (index > 0 && curr.percentage >= thresholdPercentage) {
                const prevRecord = blockAccuracy[index - 1];
                const recordDurationSeconds =
                  curr.videoPartCurrentTime - prevRecord.videoPartCurrentTime;
                acc = acc + recordDurationSeconds;
              }
              return acc;
            }, 0);
            return durationSeconds / percentageValue;
          };

          const progressValue = calculateProgressValue(
            newTimespanAccuracy,
            currentBlock
          );

          setBlockProgressPercentage(progressValue);
        }

        updateAccuracyLabelValue(Math.round(accuracyPercentage));
      }

      if (
        !instructorThresholdKeypoints ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processExerciseBlock = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      stepsKeypoints: Keypoint3D[][],
      nextExerciseStepIndex: number,
      prevExerciseAccuracy: { [key: number]: number },
      live: LiveClassPracticeModel,
      regions: { [key: string]: boolean }[]
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorStepThresholdKeypoints = stepsKeypoints.map((kp) => {
        return getCoordinates(scene, kp, instructorRootName);
      });

      const calculateAccuracy = (
        studentThresholdKeypoints: Keypoint3D[],
        instructorThresholdKeypoints: Keypoint3D[],
        accuracyJoints: string[]
      ) => {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          accuracyJoints
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        return {
          alignedLowerInstructorKeypoints,
          alignedUpperInstructorKeypoints,
          accuracyPercentage,
        };
      };

      if (
        studentThresholdKeypoints &&
        !instructorStepThresholdKeypoints.some((kp) => kp === undefined)
      ) {
        const accuracyData = instructorStepThresholdKeypoints.map(
          (kp, index) => {
            if (kp) {
              return calculateAccuracy(
                studentThresholdKeypoints,
                kp,
                getJointsFromRegions(regions[index])
              );
            }
          }
        );
        const accuracyPercentages = accuracyData.map((d) => {
          if (d === undefined) {
            return undefined;
          }
          return d.accuracyPercentage;
        });

        const accuracyObject = accuracyPercentages.reduce(
          (acc: any, curr: number | undefined, index: number) => {
            acc[index] = curr;
            return acc;
          },
          {}
        );

        const nextPoseAccuracyData = accuracyData[nextExerciseStepIndex];

        let currentRep = repsCounter;
        if (currentBlock && nextPoseAccuracyData) {
          const {
            alignedLowerInstructorKeypoints,
            alignedUpperInstructorKeypoints,
            accuracyPercentage,
          } = nextPoseAccuracyData;

          const isThresholdReached =
            prevExerciseAccuracy[nextExerciseStepIndex] >=
            exerciseSuccessThresholdPercentage;
          const isAccuracyDecreasing =
            prevExerciseAccuracy[nextExerciseStepIndex] > accuracyPercentage;
          const isAccuracyReached =
            accuracyPercentage === 100 ||
            (isThresholdReached && isAccuracyDecreasing);

          const isLastStepReached =
            nextExerciseStepIndex ===
              instructorStepThresholdKeypoints.length - 1 && isAccuracyReached;

          //TODO: remove after test
          updateAccuracyLabelValue(Math.round(accuracyPercentage));

          if (isLastStepReached) {
            currentRep = repsCounter + 1;
            setRepsCounter(currentRep);
            onRepetition(currentRep.toString());
            if (currentBlock.exercise) {
              const percentage =
                (currentRep / currentBlock.exercise.repetitions) * 100;
              const percentageValue = percentage > 100 ? 100 : percentage;
              setBlockProgressPercentage(percentageValue);
            }
            setNextExerciseStepIndex(0);
            onPlayBeep();
            setPrevExerciseAccuracy(accuracyObject);
          } else if (isAccuracyReached) {
            setNextExerciseStepIndex(nextExerciseStepIndex + 1);
            onPlayBeep();
            setPrevExerciseAccuracy(accuracyObject);
          } else {
            setPrevExerciseAccuracy(accuracyObject);
            if (alignedLowerInstructorKeypoints) {
              const filtered = alignedLowerInstructorKeypoints.filter(
                (kp) =>
                  avatarFeedback.lowerCoordinateJoints.indexOf(kp.part) !== -1
              );

              avatarFeedback.applyFeedback(
                scene,
                filtered,
                studentThresholdKeypoints,
                "mixamorigHips",
                avatarFeedback.lowerCoordinateJoints
              );
            }

            if (alignedUpperInstructorKeypoints) {
              const filtered = alignedUpperInstructorKeypoints.filter(
                (kp) =>
                  avatarFeedback.upperCoordinateJoints.indexOf(kp.part) !== -1
              );

              avatarFeedback.applyFeedback(
                scene,
                filtered,
                studentThresholdKeypoints,
                "mixamorigNeck",
                avatarFeedback.feedbackJointsUpper
              );
            }
          }
          onExercisePeakReached(prevExerciseAccuracy, live, currentRep);
        }
      }

      if (
        instructorStepThresholdKeypoints.some((kp) => kp === undefined) ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processKeypoints = (
      block?: ClassPracticeAnalysisBlock,
      live?: LiveClassPracticeModel
    ) => {
      const studentKeypoints = live ? live.studentKeypoints : [];
      let displayStudentKeypoints = !selfieMode
        ? mapKeypoints(studentKeypoints)
        : getFlippedDisplayKeypoints(mapKeypoints(studentKeypoints));
      displayStudentKeypoints = rotateAroundAxis(
        displayStudentKeypoints,
        new THREE.Vector3(0, 1, 0),
        -45
      ) as any[];

      const mount = mountRef.current;
      const scene = sceneRef.current;

      if (mount && scene && renderer && view) {
        //TODO: move to service
        const applyDisplayKeypoints = (
          scene: THREE.Scene,
          keypoints: Keypoint3D[],
          name: string
        ) => {
          const root = scene.getObjectByName(name);
          if (keypoints.length !== 0) {
            let displayKeypoints = keypoints.map(({ x, y, z, part }) => {
              return { x, y, z, part };
            });

            if (root) {
              avatarService.poseUpdate(root, displayKeypoints);
              const verticalShift = avatarService.getVerticalShift(root);
              avatarService.placeOnTheFloor(root, verticalShift);
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = true;
                }
              });
            }
          } else {
            if (root) {
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = false;
                }
              });
            }
          }
        };

        applyDisplayKeypoints(
          scene,
          displayStudentKeypoints,
          studentDisplayRootName
        );

        if (
          live &&
          live.isInstructorVideoPaused !== undefined &&
          live.isInstructorVideoPaused !== true &&
          currentBlockVideoType === BlockVideoType.Practice &&
          block
        ) {
          if (block.pose) {
            const displayInstructorKeypoints = mapKeypoints(
              block.pose.keypoints
            );
            applyDisplayKeypoints(
              scene,
              displayInstructorKeypoints,
              instructorDisplayRootName
            );
            processPoseBlock(
              scene,
              displayStudentKeypoints,
              displayInstructorKeypoints,
              live,
              //HACK: bacuse of empty db records
              block.pose.regions || defaultRegions,
              block
            );
          } else if (block && block.exercise) {
            const sortedSteps = block.exercise.steps.sort((x) => x.order);
            const stepsDisplayKeypoints = sortedSteps.map((x) =>
              mapKeypoints(x.keypoints)
            );

            const stepsRegions = sortedSteps.map((x) => {
              if (x.regions) {
                return x.regions;
              }
              //HACK: bacuse of empty db records
              return defaultRegions;
            });

            applyDisplayKeypoints(
              scene,
              stepsDisplayKeypoints[nextExerciseStepIndex],
              instructorDisplayRootName
            );

            processExerciseBlock(
              scene,
              displayStudentKeypoints,
              stepsDisplayKeypoints,
              nextExerciseStepIndex,
              prevExerciseAccuracy,
              live,
              stepsRegions
            );
          }
        } else {
          avatarFeedback.removeFeedback(scene);
        }
      }
    };

    processKeypoints(currentBlock, live);
  }, [live]);

  useEffect(() => {
    const onChange = async (poseName: string | undefined) => {
      const scene = sceneRef.current;
      if (scene) {
        const textBackgroundMesh = poseNameBackgroundRef.current;
        if (textBackgroundMesh) {
          scene.remove(textBackgroundMesh);
          pose3dService.cleanupObject(textBackgroundMesh);
        }
        const textMesh = poseNameRef.current;
        if (textMesh) {
          scene.remove(textMesh);
          //TODO: check if text changes if not - skip to improve performance
          pose3dService.cleanupObject(textMesh);
        }
        if (poseName) {
          const text = getCurrentPoseLabel(poseName, font, halfWidth);

          const box = text.geometry.boundingBox;
          if (box) {
            const mesh = getTextBackground(box, text.position.z);
            poseNameBackgroundRef.current = mesh;
            scene.add(mesh);
          }
          scene.add(text);
          poseNameRef.current = text;
        }
      }
    };

    onChange(instructorPoseName);
  }, [instructorPoseName]);

  useEffect(() => {
    if (
      currentBlockVideoType === BlockVideoType.AlternativeExplanation ||
      currentBlockVideoType === BlockVideoType.Explanation
    ) {
      //TODO: hide ewards
      //and instructor skeleton
      const instructorImageBackground = instructorImageBackgroundRef.current;
      if (instructorImageBackground) {
        instructorImageBackground.visible = false;
      }
    } else {
      //TODO: rewards
      //and instructor skeleton
      const instructorImageBackground = instructorImageBackgroundRef.current;
      if (instructorImageBackground) {
        instructorImageBackground.visible = true;
      }
    }

    const scene = sceneRef.current;
    if (scene) {
      removeBlockLabel(scene);
      if (currentBlock) {
        const getBlockLabelText = (
          currentBLock: ClassPracticeAnalysisBlock,
          type: BlockVideoType
        ) => {
          const getBlockTypeLabel = (type: BlockVideoType) => {
            if (type === BlockVideoType.Explanation) {
              return "Explanation";
            }
            if (type === BlockVideoType.Practice) {
              return "Practice";
            }
          };

          const blockTypeLabel = getBlockTypeLabel(type);
          if (blockTypeLabel) {
            return `${currentBLock.name} - ${blockTypeLabel}`;
          }
          return "";
        };

        const blockLabel = getBlockLabel(
          getBlockLabelText(currentBlock, currentBlockVideoType),
          font,
          halfWidth
        );
        scene.add(blockLabel);

        const prevText = scene.getObjectByName(alternateTextMeshName);
        const instructorVideoBackground = scene.getObjectByName(
          "InstructorVideoBackground"
        );
        if (prevText) {
          if (instructorVideoBackground) {
            instructorVideoBackground.position.setX(-halfWidth - 0.01);
          }
          scene.remove(prevText);
        }
        if (currentBlockVideoType === BlockVideoType.AlternativeExplanation) {
          const newText = getTextMesh(
            currentBlock.alternative_text,
            font,
            halfWidth,
            wallHeight,
            alternateTextMeshName
          );
          if (instructorVideoBackground) {
            instructorVideoBackground.position.setX(-halfWidth + 0.05);
          }
          scene.add(newText);
        }
      }
    }
  }, [currentBlockVideoType]);

  const [classDuration, setClassDuration] = useState<number>();
  useEffect(() => {
    const init = () => {
      const classDuration = blocks.reduce((acc, curr) => {
        if (skipExplanations) {
          acc = acc + curr.practice_duration_seconds;
        } else {
          acc =
            acc +
            curr.explanation_duration_seconds +
            curr.practice_duration_seconds;
        }
        return acc;
      }, 0);
      setClassDuration(classDuration);
    };

    init();
  }, [blocks]);

  const [classCurrentTime, setClassCurrentTime] = useState<number>();

  useEffect(() => {
    const onChange = (
      videoPartCurrentTime: number,
      currentBlock: ClassPracticeAnalysisBlock
    ) => {
      const classCurrentTime = blocks.reduce((acc, curr) => {
        if (curr.order < currentBlock.order) {
          if (!skipExplanations) {
            acc =
              acc +
              curr.explanation_duration_seconds +
              curr.practice_duration_seconds;
          } else {
            acc = acc + curr.practice_duration_seconds;
          }
        } else if (curr.order === currentBlock.order) {
          if (currentBlockVideoType === BlockVideoType.Explanation) {
            acc = acc + videoPartCurrentTime;
          } else {
            if (skipExplanations) {
              acc = acc + videoPartCurrentTime;
            } else {
              acc =
                acc +
                currentBlock.explanation_duration_seconds +
                videoPartCurrentTime;
            }
          }
        }
        return acc;
      }, 0); //TODO: calculate class current time based on current block and skipExplanation
      setClassCurrentTime(classCurrentTime);
    };
    if (currentBlock) {
      onChange(videoPartCurrentTime, currentBlock);
    }
  }, [videoPartCurrentTime]);
  return (
    <div className={classes.root}>
      <div
        className={`${classes.canvas} ${
          displayOutOfFrameNotificationBorder ? classes.canvasBorder : ""
        }`}
        ref={mountRef}
      ></div>
      <IconButton
        disabled={currentBlockVideoType !== BlockVideoType.Practice}
        className={classes.rotateLeft}
        onClick={() => rotateCamera(-15)}
        aria-label="rotate left"
      >
        <RotateRightIcon className={classes.iconButton} />
      </IconButton>
      <IconButton
        disabled={currentBlockVideoType !== BlockVideoType.Practice}
        className={classes.rotateRight}
        onClick={() => rotateCamera(15)}
        aria-label="rotate right"
      >
        <RotateLeftIcon className={classes.iconButton} />
      </IconButton>
      {instructorImageBackgroundRef.current && (
        <BlockCircularProgressBar
          backgroundObject={instructorImageBackgroundRef.current}
          blockProgressPercentage={blockProgressPercentage}
          block={currentBlock}
          repsCounter={repsCounter}
          currentBlockVideoType={currentBlockVideoType}
        />
      )}
      {sceneRef.current && classDuration && classCurrentTime && (
        <VideoDurationTime
          duration={classDuration}
          classCurrentTime={classCurrentTime}
          font={font}
          scene={sceneRef.current}
        />
      )}
      {sceneRef.current &&
        currentBlock &&
        classDuration &&
        classCurrentTime && (
          <BlocksProgressBar
            classDuration={classDuration}
            classCurrentTime={classCurrentTime}
            scene={sceneRef.current}
            blocks={blocks}
            skipExplanations={skipExplanations}
          />
        )}
    </div>
  );
};

export default LiveSkeletons;
