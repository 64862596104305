import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ChannelClassSequenceCheckpoint } from "../../types/class-sequence/class-sequence-checkpoint";

export type ChannelClassSequenceOption = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  is_published: boolean;
  channel_id: string;
  description: string;
};

export type ChannelClassSequence = {
  name: string;
  checkpoints: ChannelClassSequenceCheckpoint[];
  file_path: string;
  description: string;
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelClassSequenceOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/create",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/edit",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClassSequence> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/remove",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      channel_id: model.channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ChannelClassEditDetails = {
  name: string;
  file_path: string;
  thumbnail_path: string;
};

export const setPublished = async (
  classId: string,
  isPublished: boolean
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/class-sequence/setPublished",
    data: {
      id: classId,
      is_published: isPublished,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
