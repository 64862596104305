import { AppRoute } from "./app-route.type";
import { RouteAccessRequirement } from "./route-access-requirement.enum";
import ChannelPoseOrSkillCreate from "../views/instructor/ChannelPoseOrSkillCreate";
import ChannelPoseOrSkillCreateVideo from "../views/instructor/ChannelPoseOrSkillCreateVideo";
import ChannelCreate from "../views/instructor/ChannelCreate";
import ChannelEdit from "../views/instructor/ChannelEdit";
import ChannelVideoCreate from "../views/instructor/ChannelVideoCreate";
import ChannelDetails from "../views/instructor/ChannelDetails";
import ChannelList from "../views/instructor/ChannelList";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import SurroundSoundIcon from "@material-ui/icons/SurroundSound";

import { AppRouteLayout } from "./app-route-layout.enum";
import ChannelPoseOrSkillSkeleton from "../views/instructor/ChannelPoseOrSkillSkeleton";
import ChannelPoseOrSkillEdit from "../views/instructor/ChannelPoseOrSkillEdit";
import {
  ChannelClassEdit,
  ChannelClassCreate,
} from "../views/instructor/ChannelClassCreate";
import MarketingTools from "../views/instructor/MarketingTools";
import ChannelVideo from "../views/instructor/ChannelVideo";

import ChannelExerciseCreateVideo from "../views/instructor/ChannelExerciseCreateVideo";
import {
  AnalyzeClass,
  AnalyzeClassTest,
} from "../views/instructor/AnalyzeClass";
import ChannelExerciseEdit from "../views/instructor/ChannelExerciseEdit";
import ChannelVideoEdit from "../views/instructor/ChannelVideoEdit";
import { ChannelBlockCreate, ChannelBlockEdit } from "../views/instructor/ClassPractice/ChannelBlockCreate";
import { ChannelClassPracticeCreate, ChannelClassPracticeEdit } from "../views/instructor/ClassPractice/ChannelClassPracticeCreate";
import { AnalyzeClassPractice, AnalyzeClassPracticeTest } from "../views/instructor/AnalyzeClassPractice";
import { ChannelClassSequenceCreate, ChannelClassSequenceEdit } from "../views/instructor/ClassSequence/ChannelClassSequenceCreate";

const routePrefix = "/sports-instructor";

const getPath = (route: string): string => {
  return `${routePrefix}${route}`;
};

const channelsRoute: AppRoute = {
  path: getPath("/skills-video-classes"),
  component: ChannelList,
  title: "Instructor Channels",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: FeaturedVideoIcon,
};

const marketingToolsRoute: AppRoute = {
  path: getPath("/marketing-tools"),
  component: MarketingTools,
  title: "Marketing Tools",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  icon: SurroundSoundIcon,
};

export const sidebarRoutes: Array<AppRoute> = [
  channelsRoute,
  marketingToolsRoute,
];

const channelDetailsRoute: AppRoute = {
  path: getPath("/channel/:channelId/:tab?"),
  component: ChannelDetails,
  title: "Channel Details",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute],
};

const channelPoseCreateRoute: AppRoute = {
  path: getPath("/channel/create-pose/:channelId"),
  component: ChannelPoseOrSkillCreate,
  title: "Add Pose",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelExerciseEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/exercise/:exerciseId/edit"),
  component: ChannelExerciseEdit,
  title: "Edit Exercise",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelExerciseVideoCreateRoute: AppRoute = {
  path: getPath("/channel/:channelId/exercise/create-video"),
  component: ChannelExerciseCreateVideo,
  title: "Add Exercise",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelPoseVideoCreateRoute: AppRoute = {
  path: getPath("/channel/create-pose-video/:channelId"),
  component: ChannelPoseOrSkillCreateVideo,
  title: "Add Pose",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelPoseSkeletonRoute: AppRoute = {
  path: getPath("/channel/pose/:poseOrSkillId/skeleton"),
  component: ChannelPoseOrSkillSkeleton,
  title: "Pose Skeleton",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelPoseEditRoute: AppRoute = {
  path: getPath("/channel/pose/:poseOrSkillId/edit"),
  component: ChannelPoseOrSkillEdit,
  title: "Pose Edit",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelCreateRoute: AppRoute = {
  path: getPath("/channel/create/:activityId?"),
  component: ChannelCreate,
  title: "Create Channel",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute],
};

const channelEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/edit"),
  component: ChannelEdit,
  title: "Edit Channel",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute],
};

const channelVideoCreateRoute: AppRoute = {
  path: getPath("/channel/create-video/:channelId"),
  component: ChannelVideoCreate,
  title: "Upload Video",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelVideoEditRoute: AppRoute = {
  path: getPath("/channel/video/:videoId/edit"),
  component: ChannelVideoEdit,
  title: "Edit Video",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelVideoDetailsRoute: AppRoute = {
  path: getPath("/channel/video/:videoId"),
  component: ChannelVideo,
  title: "Video Details",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelClassCreateRoute: AppRoute = {
  path: getPath("/channel/:channelId/class/create"),
  component: ChannelClassCreate,
  title: "Create Class",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelClassEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/class/:classId/edit"),
  component: ChannelClassEdit,
  title: "Edit Class",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const takeClassRoute: AppRoute = {
  path: getPath("/take-class/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClass,
  //TODO: add access requirement instructor
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Class,
  title: "Take Class",
};

const takeClassTestRoute: AppRoute = {
  path: getPath("/take-class-test/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClassTest,
  //TODO: add access requirement instructor
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Class,
  title: "Take Class Test",
};

const channelBlockCreateRoute: AppRoute = {
  path: getPath("/channel/:channelId/block/create"),
  component: ChannelBlockCreate,
  title: "Create Block",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelBlockEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/block/:blockId/edit"),
  component: ChannelBlockEdit,
  title: "Edit Block",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelClassPracticeCreateRoute: AppRoute = {
  path: getPath("/channel/:channelId/class-practice/create"),
  component: ChannelClassPracticeCreate,
  title: "Create Class Practice",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelClassPracticeEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/class-practice/:classId/edit"),
  component: ChannelClassPracticeEdit,
  title: "Edit Class Practice",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const takeClassPracticeRoute: AppRoute = {
  path: getPath("/take-class-practice/:classId/:channelId/:modelTypeString"),
  component: AnalyzeClassPractice,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Class,
  title: "Take Class",
};

const takeClassPracticeTestRoute: AppRoute = {
  path: getPath(
    "/take-class-practice-test/:classId/:channelId/:modelTypeString"
  ),
  component: AnalyzeClassPracticeTest,
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Class,
  title: "Take Class Test",
};

const channelClassSequenceCreateRoute: AppRoute = {
  path: getPath("/channel/:channelId/class-sequence/create"),
  component: ChannelClassSequenceCreate,
  title: "Create Class with Checkpoints",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

const channelClassSequenceEditRoute: AppRoute = {
  path: getPath("/channel/:channelId/class-sequence/:classId/edit"),
  component: ChannelClassSequenceEdit,
  title: "Class Sequence Edit",
  accessRequirement: RouteAccessRequirement.Login,
  layout: AppRouteLayout.Main,
  breadcrumbs: [channelsRoute, channelDetailsRoute],
};

export const otherRoutes: Array<AppRoute> = [
  channelPoseCreateRoute,
  channelPoseVideoCreateRoute,
  channelPoseSkeletonRoute,
  channelPoseEditRoute,
  channelCreateRoute,
  channelEditRoute,
  channelVideoCreateRoute,
  channelVideoEditRoute,
  channelVideoDetailsRoute,
  channelClassCreateRoute,
  channelClassEditRoute,
  channelDetailsRoute,
  channelExerciseEditRoute,
  channelExerciseVideoCreateRoute,
  takeClassRoute,
  takeClassTestRoute,
  takeClassPracticeRoute,
  takeClassPracticeTestRoute,
  channelBlockCreateRoute,
  channelBlockEditRoute,
  channelClassPracticeCreateRoute,
  channelClassPracticeEditRoute,
  channelClassSequenceCreateRoute,
  channelClassSequenceEditRoute
];

export const routes: Array<AppRoute> = sidebarRoutes.concat(otherRoutes);
