import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import * as authService from '../../../../../../services/auth.service'
import avatarPlaceholder from '../../../../../../assets/images/avatar_placeholder.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginRight: theme.spacing(1),
    color: '#ffffff'
  }
}));

const Profile = props => {
  const { className, staticContext, ...rest } = props;

  const classes = useStyles();

  const userState = authService.getAuthState();

  const user = {
    name: `${userState.name}`,
    avatar: avatarPlaceholder
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
        <Typography
        className={classes.name}
        variant="h6"
      >
        {user.name}
      </Typography>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}   
        to={"/account"}     
      />    
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default withRouter(Profile);