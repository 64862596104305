import React from "react";
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
}));

export default function ActivityCard(props) {
  const { studentActivity } = props;
  const { id, name, creation_date, thumbnail_path } = studentActivity;
  const history = useHistory();
  const classes = useStyles();
  const onActivityClicked = () => {
    history.push(`/sports-student/sequence-activity/${id}`);
  };

  return (
    <Card onClick={onActivityClicked}>
      <CardActionArea>
      <CardMedia
          className={classes.media}
          image={thumbnail_path}
          title="thumbnail"
        />
        <CardContent>
          <Typography gutterBottom variant="h3" component="h2">
            <strong>Class:</strong> {name}
          </Typography>
          <Typography color="textSecondary" variant="h5" component="h2">
            <strong>Recorded:</strong> {new Date(creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
