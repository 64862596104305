import React, { FC, useEffect } from "react";
import * as THREE from "three";
import {
  halfWidth,
  wallHeight,
} from "../../../../../services/analysis-scene.helper";
import { ClassPracticeAnalysisBlock } from "../../../../../types/class-practice/class-practice-analysis-block";
import * as pose3dService from "../../../../../services/pose3d.service";

type Props = {
  blocks: ClassPracticeAnalysisBlock[];
  scene: THREE.Scene;
  skipExplanations: boolean;
  classDuration: number;
  classCurrentTime: number;
};

const progressBarHeight = wallHeight / 60;
const progressBarFullWidth = halfWidth * 2;

const BlocksProgressBar: FC<Props> = (props) => {
  const {
    scene,
    blocks,
    skipExplanations,
    classCurrentTime,
    classDuration
  } = props;

  useEffect(() => {
    const getInstructorVideoProgressBarNodeBackground = (
      classDuration: number,
      blockDuration: number,
      block: ClassPracticeAnalysisBlock,
      instructorVideoProgressBarMaterial: THREE.MeshBasicMaterial
    ) => {
      const space = 0.015 * 2;
      const timePercentage = blockDuration / classDuration;
      const width = progressBarFullWidth * timePercentage;

      const startPercentage =
        blocks.reduce((acc, curr) => {
          if (curr.order < block.order) {
            if (skipExplanations) {
              acc = acc + curr.practice_duration_seconds;
            } else {
              acc =
                acc +
                curr.practice_duration_seconds +
                curr.explanation_duration_seconds;
            }
          }
          return acc;
        }, 0) / classDuration;
      const startPosition = progressBarFullWidth * startPercentage;
      const positionZ = startPosition + width / 2 - halfWidth;
      const instructorVideoProgressBarGeometry = new THREE.PlaneGeometry(
        width - space,
        progressBarHeight
      );

      const instructorVideoPorgressBar = new THREE.Mesh(
        instructorVideoProgressBarGeometry,
        instructorVideoProgressBarMaterial
      );
      instructorVideoPorgressBar.name = block.id;
      const { x, y, z } = new THREE.Vector3(
        -halfWidth + 0.005,
        progressBarHeight / 2,
        -positionZ
      );

      instructorVideoPorgressBar.position.set(x, y, z);
      instructorVideoPorgressBar.rotation.y = Math.PI / 2;
      return instructorVideoPorgressBar;
    };

    const init = () => {
      const setupInstructorVideoProgressBarBackground = (
        blocks: ClassPracticeAnalysisBlock[]
      ) => {
        const instructorVideoProgressBarMaterial = new THREE.MeshBasicMaterial({
          color: new THREE.Color(0xd3d3d3),
          opacity: 0.8,
          transparent: true,
        });

        blocks.forEach((block) => {
          const blockDuration = skipExplanations
            ? block.practice_duration_seconds
            : block.explanation_duration_seconds +
              block.practice_duration_seconds;
          const instructorVideoPorgressBarBackground =
            getInstructorVideoProgressBarNodeBackground(
              classDuration,
              blockDuration,
              block,
              instructorVideoProgressBarMaterial
            );
          scene.add(instructorVideoPorgressBarBackground);
        });
      };
      setupInstructorVideoProgressBarBackground(blocks);
    };

    init();
  }, []);
  useEffect(() => {
    const getInstructorVideoGeneralProgressBar = (
      duration: number,
      currentTime: number,
      progressBarFullWidth: number,
      progressBarHeight: number,
      halfWidth: number,
      name: string
    ) => {
      const space = 0.01 * 2;
      const instructorVideoProgressBarMaterial = new THREE.MeshBasicMaterial({
        color: new THREE.Color(0xff0000),
      });
    
      const timePercentage = currentTime / duration;
      const width = progressBarFullWidth * timePercentage;
    
      const positionZ = -halfWidth + width / 2;
      const instructorVideoProgressBarGeometry = new THREE.PlaneGeometry(
        width - space,
        progressBarHeight
      );
    
      const instructorVideoPorgressBar = new THREE.Mesh(
        instructorVideoProgressBarGeometry,
        instructorVideoProgressBarMaterial
      );
    
      const { x, y, z } = new THREE.Vector3(
        -halfWidth + 0.007,
        progressBarHeight / 2,
        -positionZ
      );
    
      instructorVideoPorgressBar.position.set(x, y, z);
      instructorVideoPorgressBar.rotation.y = Math.PI / 2;
      instructorVideoPorgressBar.name = name;
      return instructorVideoPorgressBar;
    };

    const setupInstructorVideoGeneralProgress = (
      currentTime: number,
      duration: number,
      scene: THREE.Scene
    ) => {
      const name = "ProgressBar";
      const element = scene.getObjectByName(name) as THREE.Mesh;
      if (element) {
        pose3dService.cleanupObject(element);
        scene.remove(element);
      }
      const instructorVideoProgressBar = getInstructorVideoGeneralProgressBar(
        duration,
        currentTime,
        progressBarFullWidth,
        progressBarHeight,
        halfWidth,
        name
      );
      scene.add(instructorVideoProgressBar);
    };

    if (classDuration) {

      setupInstructorVideoGeneralProgress(
        classCurrentTime,
        classDuration,
        scene
      );
    }
  }, [classCurrentTime]);

  return <div></div>;
};

export default BlocksProgressBar;
