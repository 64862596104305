import React, { useEffect, useState } from "react";

type Props = {
  duration: number;
  currentTime: number;
};

export default function ClassProgressBar(props: Props) {
  const { duration, currentTime } = props;


  const [message, setMessage] = useState<string>();
  const [percentage, setPercentage] = useState<number>();

  useEffect(()=> {
    const percentage = currentTime / (duration / 100);
    const getMessage = (secondsLeft: number) => {
      if (secondsLeft < 60) {
        return `${secondsLeft} more sec.`;
      }
      return `${Math.floor(secondsLeft / 60)} more min.`;
    };
    setPercentage(percentage);
    const timeLeft = Math.round(duration - currentTime);
    setMessage(getMessage(timeLeft))
  },[])

  return (
    <div
      style={{
        width: "100%",
        height: 50,
        backgroundColor: "rgba(236, 236, 236, 0.5)",
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          height: "100%",
          background: "linear-gradient(-0.25turn, lightgreen, yellow)",
          position: "absolute",
          left: 0,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <span
          style={{
            height: "100%",
            color: "black",
            fontSize: 45,
            fontWeight: 900,
          }}
        >
          {message}
        </span>
      </div>
    </div>
  );
}
