import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, TextField, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as userFeedbackProvider from '../../../providers/common/user-feedback.provider';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%'
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
}));

type Props = {
    
}

const AskForFeature = (props: Props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(false);
    const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string | undefined>(undefined);
    const history = useHistory();

    const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const feedback = (event.target.value as string);
        setFeedback(feedback);
    };

    const onSubmit = async () => {
        //TODO: send to database
        const entity = {
            feedback: feedback
        }
        try{
            setLoading(true);
            await userFeedbackProvider.create(entity);
            setFeedbackSent(true);
        }
        catch{
            alert('An error occurred, please try again.');
            setLoading(false);
        }
    }

    const onBack = () => {
        history.goBack();
    }

    return (
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                {
                    feedbackSent 
                    ? <Typography component="h1" variant="h4" gutterBottom>
                            Thanks for your feedback!
                        </Typography>
                    : <Fragment>
                        <Typography component="h1" variant="h4" gutterBottom>
                            Ask for a feature or suggest improvements to an existing one
                        </Typography>
                        <Grid container spacing={3}> 
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.formControl}
                                    label="Feedback"
                                    multiline
                                    rows="6"
                                    value={feedback}
                                    onChange={handleFeedbackChange}
                                    variant="outlined"
                                />
                            </Grid>                        
                        </Grid> 
                        <div className={classes.buttonsContainer}>
                            <Button  disabled={loading} onClick={onBack} variant="contained">
                                Go Back
                            </Button>
                            <Button  disabled={loading} onClick={onSubmit} variant="contained" color="primary">
                                Submit
                            </Button>    
                        </div>    
                    </Fragment>
                }     
            </Paper>
        </main>
    );
}

export default AskForFeature