import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Button } from "@material-ui/core";

import { SidebarNav } from "./components";
// import { sidebarRoutes as studentSidebarRoutes } from "../../../../Routes/user.routes";
// import { sidebarRoutes as instructorSidebarRoutes } from "../../../../Routes/instructor.routes";
// import { sidebarRoutes as commonSidebarPages } from "../../../../Routes/common.routes";
// import { sidebarRoutes as adminSidebarPages } from "../../../../Routes/admin.routes";
// import * as authService from "../../../../services/auth.service";
// import { useHistory } from "react-router-dom";
// import { UserType } from "../../../../enums/user-type.enum";
import logo from "../../../../assets/images/wizhero_logo_low res_v2.png";
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 80//240,
    // [theme.breakpoints.up("lg")]: {
    //   marginTop: 64,
    //   height: "calc(100% - 64px)",
    // },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",
    padding: theme.spacing(2),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  switch: {
    marginTop: "auto",
  },
  logo: {
    height: 32,
    borderRadius: 20,
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  //const authState = authService.getAuthState();
  // const userType = authState.userType;
  // const history = useHistory();
  // const isAdmin = authService.isAdmin();
  // const isInstructor = authService.isInstructor();
  const classes = useStyles();

  // const onSwitchUserType = () => {
  //   const newUserType =
  //     userType === UserType.Instructor ? UserType.Student : UserType.Instructor;
  //   authService.switchUserType(newUserType);
  //   history.push("/default");
  // };

  // const studentPages = studentSidebarRoutes.map(({ path, title, icon }) => {
  //   return {
  //     path,
  //     title,
  //     icon,
  //   };
  // });

  // const instructorPages = instructorSidebarRoutes.map(
  //   ({ path, title, icon }) => {
  //     return {
  //       path,
  //       title,
  //       icon,
  //     };
  //   }
  // );

  // const commonPages = commonSidebarPages
  //   .filter((p) => {
  //     return isAdmin || !p.userType || p.userType === userType;
  //   })
  //   .map(({ path, title, icon }) => {
  //     return {
  //       path,
  //       title,
  //       icon,
  //     };
  //   });

  // const adminPages = adminSidebarPages.map(({ path, title, icon }) => {
  //   return {
  //     path,
  //     title,
  //     icon,
  //   };
  // });

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <RouterLink style={{ textAlign: "center", padding: 10 }} to="/">
        <img className={clsx(classes.logo)} alt="Logo" src={logo} />
      </RouterLink>

      <div {...rest} className={clsx(classes.root, className)}>
      <SidebarNav className={classes.nav} pages={[{ path: '/default', icon: ArrowBackIcon }]} />
        {/* {(isAdmin || userType !== UserType.Instructor) && (
          <SidebarNav className={classes.nav} pages={studentPages} />
        )}
        {isAdmin && <Divider />}
        {(isAdmin || userType === UserType.Instructor) && (
          <SidebarNav className={classes.nav} pages={instructorPages} />
        )}
        <Divider />
        <SidebarNav className={classes.nav} pages={commonPages} />
        {isAdmin && (
          <Fragment>
            <Divider />
            <SidebarNav className={classes.nav} pages={adminPages} />
          </Fragment>
        )}
        {!isAdmin && isInstructor && (
          <Button
            className={classes.switch}
            size="small"
            variant="contained"
            color="primary"
            onClick={onSwitchUserType}
          >
            Switch to {userType === UserType.Student ? "Instructor" : "Student"}
          </Button>
        )} */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
