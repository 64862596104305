import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ChannelClassBlockOption = {
  id: string;
  name: string;
  thumbnail_path: string;
  explanation_duration_seconds: number;
  practice_duration_seconds: number;
  pose_id: string;
  exercise_id: string;
};

export type ChannelClassBlock = {
  id: string;
  name: string;
  explanation_video_id: string;
  practice_video_id: string;
  alternative_text: string;
  pose_id: string;
  exercise_id: string;
};

export const getAll = async (
  channelId: string
): Promise<Array<ChannelClassBlockOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/block/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/block/create",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/block/edit",
    data: {
      ...item,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClassBlock> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/block/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/block/remove",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      channel_id: model.channelId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
