import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  makeStyles,
  Theme,
  Button,
  Checkbox,
} from "@material-ui/core";
import { ChannelPoseOrSkillListItem } from "../../../../../providers/instructor/channel-pose.provider";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  thumbnail: {
    maxWidth: "100%",
  },
}));

type Props = {
  poses: ChannelPoseOrSkillListItem[];
  pose: ChannelPoseOrSkillListItem;
  onPoseSelected: (pose: ChannelPoseOrSkillListItem) => void;
  onCreateTimespan: (isPoseOrSkillFlipped: boolean) => void;
};

export default function PoseSelection(props: Props) {
  const { poses, pose, onPoseSelected, onCreateTimespan } = props;
  const classes = useStyles();
  const [isPoseOrSkillFlipped, setIsPoseOrSkillFlipped] =
    useState<boolean>(false);

  const handlePoseOrSkillFlipped = async () => {
    setIsPoseOrSkillFlipped((prevState) => !prevState);
  };

  const handlePoseOrSkillChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const id = event.target.value as string;
    const pose = poses.find((x) => x.id === id);
    if (pose) {
      onPoseSelected(pose);
    }
    //TODO: store locally or to parent
  };

  const handleTimespanCreation = async () => {
    onCreateTimespan(isPoseOrSkillFlipped);
  };

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={6}>
        <img
          className={classes.thumbnail}
          src={isPoseOrSkillFlipped ? pose.flipped_path : pose.thumbnail_path}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPoseOrSkillFlipped}
              onChange={() => handlePoseOrSkillFlipped()}
            />
          }
          label="Flip Pose"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="category-select-placeholder-label-label">
            Pose
          </InputLabel>
          <Select
            labelId="category-select-placeholder-label-label"
            value={pose.id}
            onChange={handlePoseOrSkillChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {poses.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Button
          onClick={handleTimespanCreation}
          variant="contained"
          color="primary"
        >
          Add Pose
        </Button>
      </Grid>
    </Grid>
  );
}
