import React from "react";

import { Route, Redirect } from "react-router-dom";
import * as authService from "../../services/auth.service";

const ProtectedAdminRoute = (props) => {
  const { component: Component, layout: Layout, ...rest } = props;
  const isAdmin = authService.isAdmin();
  return (
    <Route
      {...rest}
      render={() =>
        isAdmin ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/not-found",
            }}
          />
        )
      }
    />
  );
};

export default ProtectedAdminRoute;
