import * as authProvider from "../providers/common/auth.provider";
import { UserType } from "../enums/user-type.enum";
import jwt_decode from "jwt-decode";
type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  username: string | null;
  id: string | null;
  name: string | null;
  isAdmin: boolean | null;
  isInstructor: boolean | null;
  userType: UserType;
};
//TODO: check if there is need in session storage, try to store data in object
export const getAuthState = (): AuthState => {
  const storageIsAdmin = sessionStorage.getItem("isAdmin");
  const isAdmin = storageIsAdmin === null ? null : JSON.parse(storageIsAdmin);
  const storageIsInstructor = sessionStorage.getItem("isInstructor");
  const isInstructor =
    storageIsInstructor === null ? null : JSON.parse(storageIsInstructor);

  const userTypeString = sessionStorage.getItem("userType");
  const userType: UserType =
    userTypeString === null
      ? UserType.Undefined
      : UserType[userTypeString as keyof typeof UserType];
  return {
    accessToken: sessionStorage.getItem("accessToken"),
    refreshToken: sessionStorage.getItem("refreshToken"),
    username: sessionStorage.getItem("username"),
    id: sessionStorage.getItem("id"),
    name: sessionStorage.getItem("name"),
    userType: userType,
    isAdmin: isAdmin,
    isInstructor: isInstructor,
  };
};

export const login = async (
  username: string,
  password: string
): Promise<any> => {
  const data = await authProvider.login(username, password);
  if (data.accessToken) {
    const decoded: any = jwt_decode(data.accessToken);
    logout();
    sessionStorage.setItem("accessToken", data.accessToken);
    sessionStorage.setItem("refreshToken", data.refreshToken);
    sessionStorage.setItem("username", decoded.username);
    sessionStorage.setItem("id", decoded.id);
    sessionStorage.setItem("name", decoded.name);
    sessionStorage.setItem("userType", UserType[UserType.Student]);
    // TODO: rework to work with multiple roles from token
    if (Array.isArray(decoded["role"])) {
      if (decoded["role"].indexOf("Admin") !== -1) {
        sessionStorage.setItem("isAdmin", "true");
      }
      if (decoded["role"].indexOf("Instructor") !== -1) {
        sessionStorage.setItem("isInstructor", "true");
      }
    }
  }
  return data;
};

export const logout = (): void => {
  const keys = [
    "accessToken",
    "refreshToken",
    "username",
    "id",
    "name",
    "userType",
    "isAdmin",
    "isInstructor",
  ];
  keys.forEach((key) => {
    sessionStorage.removeItem(key);
  });
};

export const switchUserType = (type: UserType): void => {
  sessionStorage.setItem("userType", UserType[type]);
};

export const isAuthenticated = (): boolean => {
  const token = sessionStorage.getItem("accessToken");
  return !!token;
};

export const isAdmin = (): boolean => {
  const storageValue = sessionStorage.getItem("isAdmin");
  const isAdmin = storageValue === null ? null : JSON.parse(storageValue);
  return !!isAdmin;
};

export const isInstructor = (): boolean => {
  const storageValue = sessionStorage.getItem("isInstructor");
  const isInstructor = storageValue === null ? null : JSON.parse(storageValue);
  return !!isInstructor;
};

export type RegisterModel = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export const refreshToken = async () => {
  const model = {
    accessToken: sessionStorage.getItem("accessToken"),
    refreshToken: sessionStorage.getItem("refreshToken"),
  };
  const result = await authProvider.refreshToken(model);
  const { accessToken, refreshToken } = result;
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
  return { accessToken };
};
