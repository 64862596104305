export default function passwordComplexity(
  value: any,
  options: any,
  key: any,
  attributes: any
) {
  if (key === "password") {
    let oneUpperCase = false;
    let oneLowerCase = false;
    let oneNumber = false;

    for (let index = 0; index < value.length; index++) {
      if (oneUpperCase && oneLowerCase && oneNumber) {
        break;
      }
      const character = value.charAt(index);

      if (!Number.isNaN(character * 1)) {
        oneNumber = true;
      } else {
        if (character === character.toUpperCase()) {
          oneUpperCase = true;
        }
        if (character === character.toLowerCase()) {
          oneLowerCase = true;
        }
      }
    }

    if (!oneLowerCase || !oneLowerCase || !oneNumber) {
      return "does not match requirements";
    }
  }
  return undefined;
}
