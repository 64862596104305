import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type VideoOption = {
  id: string;
  creation_date: string;
  thumbnail_path: string;
};

export type VideoDetails = {
  file_path: string;
  thumbnail_path: string;
};

export const getAll = async (): Promise<Array<VideoOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/video/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("video", item.video);
  formData.append("thumbnail", item.thumbnail);

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/video/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<VideoDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/video/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
