import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";

import { getRewardImage } from "../../../../../services/reward/reward.service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': { cursor: 'pointer' },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }),
);

export default function MediaControlCard(props) {
  const classes = useStyles();

  const { block } = props;
  const { id, name, reward } = block;

  const history = useHistory();
  const onActivityClicked = () => {
    history.push(
        `/sports-student/practice-activity-block/${id}`
    )
  };
  return (
    <Card className={classes.root} onClick={onActivityClicked}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {name}
          </Typography>
        </CardContent>       
      </div>
      <CardMedia
        className={classes.cover}
        image={getRewardImage(reward)}
        title="Reward"
      />
    </Card>
  );
}
