import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import NotImplementedComponent from "../../../components/NotImplemented";
import { Grid, List, ListItem, ListItemText, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type Props = {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

export default function ChannelCourseList(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NotImplementedComponent featureName={"Training Plans"} />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            After analyzing your activity history, we will automatically produce
            a personalized training plan to help you improve in the fastest way.
            These training plans will take into account:
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemText primary="1) the results of the analysis of your activity history" />
              </ListItem>
              <ListItem>
                <ListItemText primary="2) the mistakes you might have made in performing the exercises, if any" />
              </ListItem>
              <ListItem>
                <ListItemText primary="3) the root causes for these mistakes" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            The training plans displayed will take into account all these
            factors, as well as your bio-metrics and the instructor coaching
            recommendations
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
