import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export const create = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/application/interest/create",
    data: {
      feature_name: item.featureName,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};
