import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
} from "@material-ui/core";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import * as channelExerciseProvider from "../../../providers/instructor/channel-exercise.provider";
import { ChannelExerciseStep } from "../../../providers/instructor/channel-exercise.provider";
import Step from "./components/Step";
import validate from "validate.js";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

type Params = {
  exerciseId: string;
  channelId: string;
};

type Props = {
  breadcrumbs: any[];
  title: string;
};

export default function ChannelExerciseEdit(props: Props) {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const history = useHistory();
  const { exerciseId, channelId } = useParams<Params>();
  const [isUiDisabled, setIsUiDisabled] = useState<boolean>(false);
  const [steps, setSteps] = useState<ChannelExerciseStep[]>();
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  useEffect(() => {
    const loadExercise = async (exerciseId: string) => {
      const { message, name, steps } = await channelExerciseProvider.get(
        exerciseId
      );
      setMessage(message);
      setName(name);
      setSteps(steps);
    };

    loadExercise(exerciseId);
  }, []);

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const formData = {
      name: name,
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true
      });
      return;
    }

    if (name && steps) {
      setIsUiDisabled(true);
      const model = {
        id: exerciseId,
        message: message || "",
        name: name,
        steps: steps.map((step) => {
          return { regions: step.regions, id: step.id };
        }),
      };
      try {
        await channelExerciseProvider.edit(model);
        history.goBack();
      } catch (ex) {
        console.log(ex);
        setIsUiDisabled(false);
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
    setSubmitted({
      ...submitted,
      name: false,
    });
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value as string);
  };

  const onToggleRegion = (key: string, stepIndex: number) => {
    if (steps) {
      let { regions } = steps[stepIndex];
      if (!regions) {
        regions = {};
      }
      if (regions[key]) {
        regions[key] = false;
      } else {
        regions[key] = true;
      }

      const newSteps = [...steps];
      newSteps[stepIndex].regions = regions;

      setSteps(newSteps);
    }
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const data = channelId ? { channelId: channelId } : undefined;
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      {isUiDisabled && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              id="name"
              label="Exercise name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              id="message"
              label="Instructor message"
              value={message}
              onChange={handleMessageChange}
            />
          </Grid>
          {steps &&
            steps.map((step) => (
              <Grid item key={step.id} xs={12}>
                <Step
                  keypoints={step.keypoints}
                  imagePath={step.image_path}
                  stepIndex={step.step_order}
                  onToggleRegion={onToggleRegion}
                  regions={step.regions || {}}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container direction="row" justifyContent={"space-between"}>
          <Button
            disabled={isUiDisabled}
            onClick={history.goBack}
            variant="contained"
          >
            Go Back
          </Button>
          <Button
            disabled={isUiDisabled}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </main>
  );
}
