import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  Theme,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as authService from "../../../services/auth.service";
import login from "../../../assets/images/login.jpg";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    // backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${login})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    // color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    // color: theme.palette.white,
  },
  bio: {
    // color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  error: {
    color: "red",
  },
}));

export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();

  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  const handleBack = () => {
    history.push("/");
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setEmail(value);
    setSubmitted({
      ...submitted,
      email: false,
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setPassword(value);
    setSubmitted({
      ...submitted,
      password: false,
    });
  };

  const handleSignIn = async (event: any) => {
    event.preventDefault();
    const values = {
      email: email,
      password,
    };
    const errors = validate(values, schema);
    if (errors) {
      //TODO: set erors
      setErrors(errors);
      setSubmitted({
        email: true,
        password: true,
      });
      return;
    }
    setLoading(true);
    try {
      const result = await authService.login(email, password);

      const errorKeys = Object.keys(result.errors);
      if (errorKeys.length === 0) {
        if (
          location &&
          location.state &&
          //HACK: check if works
          (location.state as any).redirectUrl
        ) {
          history.push((location.state as any).redirectUrl);
        } else {
          history.push(`/`);
        }
      } else {
        if (errorKeys.indexOf("Form") === -1) {
          const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toLowerCase() + string.slice(1);
          };
          const pageErrors = { ...errors };
          const pageSubmitted = { ...submitted };
          for (let index = 0; index < errorKeys.length; index++) {
            const key = errorKeys[index];
            const error = result.errors[key];
            const keyLower = capitalizeFirstLetter(key);

            pageErrors[keyLower] = [error];
            pageSubmitted[keyLower] = true;
          }

          setErrors(pageErrors);
          setSubmitted(pageSubmitted);

          setPassword("");

          setErrorMessage("");
        } else {
          setErrors({});
          setSubmitted({});

          setPassword("");

          setErrorMessage(result.errors["Form"]);
        }

        setLoading(false);
      }
    } catch (ex) {
      console.log(ex);
      setErrorMessage("An error occurred while processing your request");
      setLoading(false);
    }
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const goRegister = () => {
    history.push("/register");
  };

  const goForgotPassword = () => {
    history.push("/forgot-password");
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              {/* <div className={classes.person}></div> */}
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  with email address
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={hasError("email") ? errors.email[0] : null}
                  label="Email address"
                  name="email"
                  onChange={handleEmailChange}
                  type="text"
                  value={email || ""}
                  variant="outlined"
                  autoComplete="email"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password")}
                  fullWidth
                  helperText={hasError("password") ? errors.password[0] : null}
                  label="Password"
                  name="password"
                  onChange={handlePasswordChange}
                  type="password"
                  value={password || ""}
                  variant="outlined"
                  autoComplete="current-password"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={loading}
                  onClick={handleSignIn}
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                >
                  Sign in
                </Button>
                <Typography
                  className={classes.error}
                  component="h1"
                  variant="h5"
                >
                  {errorMessage}
                </Typography>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Button
                      component="span"
                      color="secondary"
                      onClick={goForgotPassword}
                    >
                      Forgot password?
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component="span"
                      color="primary"
                      onClick={goRegister}
                    >
                      Don't have an account? Sign Up
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
