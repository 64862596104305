import React, { Component } from "react";
import {
  IconButton,
  Grid,
  Typography,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { VideoToolbar, VideoCard } from "./components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as videoProvider from "../../../providers/student/video.provider";
import { VideoOption } from "../../../providers/student/video.provider";
import * as channelProvider from "../../../providers/student/channel.provider";
import { ChannelAnalysisOption } from "../../../providers/student/channel.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  loading: boolean;
  disabled: boolean;
  videos: Array<VideoOption>;
  channelOptions: Array<ChannelAnalysisOption>;
  selectedChannelId: string | undefined;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class VideoList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      videos: [],
      channelOptions: [],
      selectedChannelId: undefined,
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      const videos = await videoProvider.getAll();
      const { location } = this.props;
      //HACK: Property 'channelId' does not exist on type '{}'.  TS2339
      const locationState = location.state as any;
      const channelId = locationState ? locationState.channelId : undefined;
      const channelOptions = await channelProvider.getAnalysisOptions(
        channelId
      );
      const selectedChannelId =
        channelId ||
        (channelOptions && channelOptions.length > 0
          ? channelOptions[0].id
          : undefined);
      this.setState({
        videos: videos,
        channelOptions: channelOptions,
        selectedChannelId: selectedChannelId,
        loading: false,
      });
    } catch (err) {
      //TODO: handle initialization error
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      console.log(err);
    }
  };

  setSelectedChannelId = (id: string) => {
    this.setState({
      selectedChannelId: id,
    });
  };

  render = () => {
    const { videos, channelOptions, selectedChannelId, loading } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h2" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
        />
        <Divider />
        <VideoToolbar />
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {videos.map((video) => (
                <Grid item key={video.id} lg={3} md={4} sm={6} xs={12}>
                  <VideoCard
                    video={video}
                    channels={channelOptions}
                    selectedChannelId={selectedChannelId}
                    setSelectedChannelId={this.setSelectedChannelId}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {/* <div className={classes.pagination}>
          <Typography variant="caption">1-6 of 20</Typography>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton>
            <ChevronRightIcon />
          </IconButton>
        </div> */}
      </div>
    );
  };
}

const styledComponent = withStyles(styles)(VideoList);
export default withRouter(styledComponent as any);
