import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type PictureOption = {
  id: string;
  creation_date: string;
  thumbnail_path: string;
};

export type PictureDetails = {
  file_path: string;
};

export const getAll = async (): Promise<Array<PictureOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/picture/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("image", item.image);

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/picture/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<PictureDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/picture/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
