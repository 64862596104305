import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import * as channelExerciseProvider from "../../../providers/instructor/channel-exercise.provider";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";
import { PoseCreate } from "./components";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import {
  getBlobFromCanvas,
  getImageFromCanvas,
} from "../../../services/canvas.service";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { ChannelVideoItem } from "../../../providers/instructor/channel-video.provider";
import validate from "validate.js";
import _ from "lodash";

const width = 900;
const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(width + theme.spacing(2) * 2)]: {
      width: width,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(width + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  timespanSlectionControls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
  timeLabel: {
    fontSize: 17,
    fontWeight: 700,
  },
}));

type Params = {
  channelId: string;
};

type Props = {
  breadcrumbs: any[];
  title: string;
};

const defaultNumberOfSteps = 2;

export default function ChannelExerciseCreateVideo(props: Props) {
  const classes = useStyles();
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const history = useHistory();
  const { channelId } = useParams<Params>();
  const [isUiDisabled, setIsUiDisabled] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const poseRef = useRef<any>();

  const [videos, setVideos] = useState<ChannelVideoItem[]>();
  const [result, setResult] = useState<any>();
  const [numberOfSteps, setNumberOfSteps] =
    useState<number>(defaultNumberOfSteps);

  const [selectedVideos, setSelectedVideos] = useState<{
    [key: number]: ChannelVideoItem;
  }>({});
  const [imageBlobs, setImageBlobs] = useState<{
    [key: number]: Blob;
  }>({});
  const [imageElements, setImageElements] = useState<{
    [key: number]: HTMLImageElement;
  }>({});
  const [keypoints, setKeypoints] = useState<{
    [key: number]: Keypoint3D[];
  }>({});

  const [landmarks, setLandmarks] = useState<{
    [key: number]: any;
  }>({});
  const [stepsRegions, setStepsRegions] = useState<{
    [key: number]: any;
  }>({});
  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  const [stepErrors, setStepErrors] = useState<any>({});
  const [stepSubmitted, setStepSubmitted] = useState<any>({});

  const onVideoIdChange = (id: string, stepIndex: number) => {
    if (videos) {
      const video = videos.find((x) => x.id === id);
      if (video) {
        const updatedSelectedVideos = {
          ...selectedVideos,
        };
        updatedSelectedVideos[stepIndex] = video;
        setSelectedVideos(updatedSelectedVideos);

        const newSubmitted = { ...stepSubmitted[stepIndex], video: false };
        stepSubmitted[stepIndex] = newSubmitted;
        setStepSubmitted({ ...stepSubmitted });
      }
    }
  };

  const onVideoUpdate = async (
    canvas: HTMLCanvasElement,
    stepIndex: number
  ) => {
    setCurrentStepIndex(stepIndex);

    setIsUiDisabled(true);
    const pose = poseRef.current;
    if (pose) {
      try {
        const blob = await getBlobFromCanvas(canvas);
        const image = await getImageFromCanvas(canvas);

        const updatedImageBlobs = {
          ...imageBlobs,
        };
        updatedImageBlobs[stepIndex] = blob;
        setImageBlobs(updatedImageBlobs);

        await pose.send({ image: image });
      } catch (ex) {
        console.log(ex);
        setIsUiDisabled(false);
      }
    } else {
      setIsUiDisabled(false);
    }
  };

  const onToggleRegion = (key: string, stepIndex: number) => {
    let regions = stepsRegions[stepIndex];
    if (!regions) {
      regions = {};
    }
    if (regions[key]) {
      regions[key] = false;
    } else {
      regions[key] = true;
    }

    stepsRegions[stepIndex] = regions;

    setStepsRegions({ ...stepsRegions });
  };

  useEffect(() => {
    const onLandmarks = async (results: any) => {
      setResult(results);
    };
    const init = async () => {
      const videos = await channelVideoProvider.getByChannel(channelId);
      setVideos(videos);
      const pose = await mediapipeService.getStaticModel();
      pose.onResults(onLandmarks);
      poseRef.current = pose;
    };
    init();
  }, []);

  useEffect(() => {
    const onResults = async (results: any, stepIndex: number) => {
      const { poseLandmarks, image } = results;
      const imageWidth = image.width;
      const imageHeight = image.height;
      if (poseLandmarks && imageWidth && imageHeight) {
        const mapped = mediapipeKeypoint.mapForAnalysis(
          mediapipeKeypoint.denormalize(poseLandmarks, imageWidth, imageHeight)
        );

        const updatedImageElements = {
          ...imageElements,
        };
        updatedImageElements[stepIndex] = image;
        setImageElements(updatedImageElements);

        const updatedLandmarks = {
          ...landmarks,
        };
        updatedLandmarks[stepIndex] = poseLandmarks;
        setLandmarks(updatedLandmarks);

        const updatedKeypoints = {
          ...keypoints,
        };
        updatedKeypoints[stepIndex] = mapped;
        setKeypoints(updatedKeypoints);

        const newSubmitted = { ...stepSubmitted[stepIndex], avatarData: false };
        stepSubmitted[stepIndex] = newSubmitted;
        setStepSubmitted({ ...stepSubmitted });
      }
      setIsUiDisabled(false);
    };

    if (result) {
      onResults(result, currentStepIndex);
    } else {
      setIsUiDisabled(false);
    }
  }, [result]);

  const onSave = async () => {
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const stepSchema = {
      video: {
        presence: { allowEmpty: false, message: "is required" },
      },
      avatarData: {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const formData = {
      name: name,
    };

    const errors = validate(formData, schema);
    const stepErrors = new Array(numberOfSteps)
      .fill(undefined)
      .reduce((acc, curr, index) => {
        const stepKeypoints = keypoints[index];
        const stepVideo = selectedVideos[index];
        const formData = {
          video: stepVideo,
          avatarData: stepKeypoints,
        };
        const stepError = validate(formData, stepSchema);
        acc[index] = stepError;
        return acc;
      }, {});

    const checkifAnyStepErrors = (stepErrors: any) => {
      for (let key in stepErrors) {
        const stepError = stepErrors[key];
        if (stepError) {
          return true;
        }
      }
      return false;
    };

    if (errors || checkifAnyStepErrors(stepErrors)) {
      setStepErrors(stepErrors);
      setStepSubmitted(
        new Array(numberOfSteps).fill(undefined).reduce((acc, curr, index) => {
          acc[index] = { video: true, avatarData: true };
          return acc;
        }, {})
      );
      setErrors(errors || {});
      setSubmitted({
        name: true,
      });
      return;
    }

    const getStepsData = () => {
      const stepsImages = [];
      const stepsKeypoints = [];
      const stepsRegionsData = [];
      for (let index = 0; index < numberOfSteps; index++) {
        const stepKeypoints = keypoints[index];
        const stepImage = imageBlobs[index];
        stepsImages.push(stepImage);
        stepsKeypoints.push(stepKeypoints);

        const regions = stepsRegions[index];
        if (regions) {
          const selectedRegions = Object.keys(regions).reduce(
            (acc: any, curr: string) => {
              const regionValue = regions[curr];
              if (regionValue === true) {
                acc[curr] = true;
              }
              return acc;
            },
            {}
          );

          stepsRegionsData.push(selectedRegions);
        } else {
          stepsRegionsData.push(undefined);
        }
      }
      return { stepsImages, stepsKeypoints, stepsRegionsData };
    };

    const { stepsImages, stepsKeypoints, stepsRegionsData } = getStepsData();
    if (name) {
      setIsUiDisabled(true);
      const model = {
        channelId: channelId,
        name: name,
        imageBlobs: stepsImages,
        keypoints: stepsKeypoints,
        regions: stepsRegionsData,
        message: message,
      };
      try {
        await channelExerciseProvider.create(model);
        history.goBack();
      } catch (ex) {
        console.log(ex);
        setIsUiDisabled(false);
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
    setSubmitted({
      ...submitted,
      name: false,
    });
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value as string);
  };

  const decrementNumberOfSteps = () => {
    setNumberOfSteps((prevValue) =>
      prevValue === 2 ? prevValue : prevValue - 1
    );

    if (numberOfSteps > 2) {
      const index = numberOfSteps - 1;

      setImageElements(_.omit(imageElements, index));

      setLandmarks(_.omit(landmarks, index));

      setKeypoints(_.omit(keypoints, index));

      setSelectedVideos(_.omit(selectedVideos, index));

      setImageBlobs(_.omit(imageBlobs, index));

      setStepSubmitted(_.omit({ ...stepSubmitted }, index));

      setStepErrors(_.omit({ ...stepErrors }, index));
    }
  };

  const incrementNumberOfSteps = () => {
    setNumberOfSteps((prevValue) =>
      prevValue === 5 ? prevValue : prevValue + 1
    );

    if (numberOfSteps < 5) {
      const index = numberOfSteps + 1;
      stepSubmitted[index] = { video: false, avatarData: false };
      setStepSubmitted({ ...stepSubmitted });

      stepErrors[index] = {};
      setStepErrors({ ...stepErrors });
    }
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const data = channelId ? { channelId: channelId } : undefined;

  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {props.title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={props.breadcrumbs}
        title={props.title}
        data={data}
      />
      <Divider />
      {isUiDisabled && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              id="name"
              label="Exercise name"
              value={name}
              onChange={handleNameChange}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              id="message"
              label="Instructor message"
              value={message}
              onChange={handleMessageChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.timespanSlectionControls}>
            <IconButton
              aria-label={"Move back"}
              onClick={decrementNumberOfSteps}
              className={classes.button}
              disabled={isUiDisabled}
            >
              <RemoveIcon />
            </IconButton>
            <Typography>Number of Steps: {numberOfSteps}</Typography>
            <IconButton
              aria-label={"Move forward"}
              onClick={incrementNumberOfSteps}
              className={classes.button}
              disabled={isUiDisabled}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          {!!videos &&
            new Array(numberOfSteps).fill(undefined).map((value, index) => (
              <Grid item key={index} xs={12}>
                <PoseCreate
                  onUpdate={onVideoUpdate}
                  landmarks={landmarks[index]}
                  keypoints={keypoints[index]}
                  image={imageElements[index]}
                  isUiDisabled={isUiDisabled}
                  stepIndex={index}
                  videos={videos}
                  onToggleRegion={onToggleRegion}
                  regions={stepsRegions[index] || {}}
                  onVideoIdChange={onVideoIdChange}
                  selectedChannelVideo={selectedVideos[index]}
                  videoError={
                    stepSubmitted[index] &&
                    stepSubmitted[index].video &&
                    stepErrors[index]
                      ? stepErrors[index].video
                      : undefined
                  }
                  avatarDataError={
                    stepSubmitted[index] &&
                    stepSubmitted[index].avatarData &&
                    stepErrors[index] &&
                    !stepErrors[index].video
                      ? stepErrors[index].avatarData
                      : undefined
                  }
                />
              </Grid>
            ))}
        </Grid>
        <Grid container direction="row" justifyContent={"space-between"}>
          <Button
            disabled={isUiDisabled}
            onClick={history.goBack}
            variant="contained"
          >
            Go Back
          </Button>
          <Button
            disabled={isUiDisabled}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </main>
  );
}
