import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

const UnprotectedRoute = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={() =>
        Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

UnprotectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default UnprotectedRoute;
