import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Paper, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    }
}));

type Props = {

}

const NotImplementedView = (props: Props) => {
    const location = useLocation();
    const { featureName }  = location.state as any;
    const history = useHistory();
    const classes = useStyles();
    //TODO: handle empty feature name, 
    const handleCheck = () => {
        //TODO: store feature name to database
        //TODO: redirect back
    }
    return (
        <div className={classes.layout}>
             <Paper className={classes.paper}>
                <h1>Requested feature "{featureName}" not implemented yet.</h1>
                <FormGroup row>    
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={false}
                            onChange={() => handleCheck()}
                            color="primary"
                        />
                        }
                        label="Notify Me when it is will be available!"
                    />
                </FormGroup>            
                <Button  onClick={history.goBack} variant="contained">
                    Go Back
                </Button>
            </Paper>
        </div>
    );
}

export default NotImplementedView