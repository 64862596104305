import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type Activity = {
  id: string;
  name: string;
  thumbnail_path: string;
};

export const getAll = async (): Promise<Array<Activity>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "api/application/activity/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<Activity> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "api/application/activity/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
