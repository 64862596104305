import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import videoProcessing from "../../../../../assets/images/video-processing.jpg";
import { ChannelVideoItem } from "../../../../../providers/instructor/channel-video.provider";
const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
    objectFit: "contain",
    width: '100%'
  },
}));

type Props = {
  video: ChannelVideoItem,
  onRemove: (id: string) => void
}

const VideoCard = (props: Props) => {
  const { video, onRemove } = props;
  const history = useHistory();
  const classes = useStyles();
  const onOverview = (id: string) => {
    history.push(`/sports-instructor/channel/video/${id}`);
  };
  const onEdit = (id: string) => {
    history.push(`/sports-instructor/channel/video/${id}/edit`);
  };

  const imageRef = useRef<HTMLImageElement>(null);
  const onError = () => {
    if (imageRef.current) {
      imageRef.current.src = videoProcessing;
    }
  };

  return (
    <Card>
      <CardActionArea>
        <img
          alt="card thumbnail"
          className={classes.media}
          src={video.thumbnail_path}
          title={video.name}
          onError={onError}
          ref={imageRef}
        />
        <CardContent>
          <Typography color="textSecondary" variant="h4" component="h2">
            {video.name}
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {new Date(video.creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => onOverview(video.id)}
        >
          Overview
        </Button>
        <Button size="small" color="secondary" onClick={() => onEdit(video.id)}>
          Edit
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onRemove(video.id)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};
export default VideoCard;
