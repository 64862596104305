import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: "center",
  },
}));

export default function RegistrationSuccess() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h2">Registration success</Typography>
            <Typography variant="h4">
              To complete the registration, check your email and follow the link provided in the letter
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
