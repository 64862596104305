import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as studentActivityBlockProvider from "../../../providers/student/activity-practice-block.provider";
import * as studentActivityBlockRecordProvider from "../../../providers/student/activity-practice-block-record.provider";
import { ExerciseChart, PoseChart } from "./components";
import { StudentPracticeActivityBlockPoseRecord } from "../../../types/practice-activities/student-practice-activity-block-pose-record";
import { StudentPracticeActivityBlockExerciseRecord } from "../../../types/practice-activities/student-practice-activity-block-exercise-record";
import { StudentPracticeActivityBlockDetails } from "../../../types/practice-activities/student-practice-activity-block-details";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  breadcrumbs: any[];
  title: string;
};

type Params = {
  blockId: string;
};

//TODO: split vide into two
export default function ActivityTimespanDetails(props: Props) {
  const classes = useStyles();
  const [poseGroups, setPoseGroups] = useState<
    StudentPracticeActivityBlockPoseRecord[][]
  >([]);

  const [block, setTimespan] = useState<
    StudentPracticeActivityBlockDetails | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { blockId } = useParams<Params>();

  const [exerciseGroups, setExerciseGroups] = useState<
    StudentPracticeActivityBlockExerciseRecord[][]
  >([]);

  useEffect(() => {
    const loadActivities = async (blockId: string) => {
      try {
        const block = await studentActivityBlockProvider.get(blockId);
        setTimespan(block);

        if (block.poseDetails) {
          const records = await studentActivityBlockRecordProvider.getPoses(
            blockId
          );
          setLoading(false);
          const mapGroups = (
            records: StudentPracticeActivityBlockPoseRecord[]
          ) => {
            const groups: any[][] = [];
            const group: any[] = records;
            groups.push(group);
            setPoseGroups(groups);
          };
          mapGroups(records);
        }
        if (block.exerciseDetails) {
          const records = await studentActivityBlockRecordProvider.getExercises(
            blockId
          );
          setLoading(false);
          const mapGroups = (
            records: StudentPracticeActivityBlockExerciseRecord[]
          ) => {
            const groups: any[][] = [];
            const group: any[] = records;
            groups.push(group);

            setExerciseGroups(groups);
          };
          mapGroups(records);
        }
      } catch (err) {
        alert(err);
        setLoading(false);
      }
    };

    loadActivities(blockId);
  }, []);

  return (
    <div className={classes.root}>
      {loading && <CircularProgress />}
      {!loading &&
        block &&
        block.poseDetails &&
        poseGroups.length === 0 &&
        exerciseGroups.length === 0 && (
          <Typography>No records found</Typography>
        )}
      {!loading &&
        block &&
        block.poseDetails &&
        poseGroups.length !== 0 &&
        poseGroups.map(
          (group, index) =>
            block &&
            block.poseDetails && (
              <PoseChart
                key={index}
                records={group}
                pose={block.poseDetails}
                name={block.name}
                score={block.score}
                duration={block.duration}
                effectiveness={block.effectiveness}
              />
            )
        )}
      {!loading &&
        block &&
        block.exerciseDetails &&
        exerciseGroups.length !== 0 &&
        exerciseGroups.map(
          (group, index) =>
            group.length !== 0 &&
            block &&
            block.exerciseDetails && (
              <ExerciseChart
                records={group}
                name={block.name}
                score={block.score}
                duration={block.duration}
                exercise={block.exerciseDetails}
                effectiveness={block.effectiveness}
              />
            )
        )}
    </div>
  );
}
