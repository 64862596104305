import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import SelectedPoint from "../SelectedPoint";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { getFlippedDisplayKeypoints } from "../../../../../services/keypoint.helper";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import HumanRegionsAccuracy from "../../../../../components/HumanRegionsAccuracy";
import {
  StudentActivityTimespanDetails,
  StudentActivityTimespanExerciseDetails,
} from "../../../../../types/activities/student-activity-timespan-details";
import { StudentActivityTimespanExerciseRecord } from "../../../../../types/activities/student-activity-timespan-exercise-record";
import TimespanSummary from "../Summary";
Chart.register(...registerables);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
  },
  chart: {
    height: 300,
  },
  video: {
    width: "50%",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
}));

type Props = {
  records: StudentActivityTimespanExerciseRecord[];
  timespan: StudentActivityTimespanDetails;
  filePath?: string;
};

const green = "rgb(0, 255, 0)";

export default function AccuracyChart(props: Props) {
  const { records, filePath, timespan } = props;

  const { steps, thresholdAccuracy, repetitions } =
    timespan.exerciseDetails as StudentActivityTimespanExerciseDetails;

  const classes = useStyles();
  const chartRef = useRef<any>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [selectedRecord, setSelectedRecord] =
    useState<StudentActivityTimespanExerciseRecord>();
  const [selectedRecordIndex, setSelectedRecordIndex] = useState<number>(-1);
  const [trigger, setStrigger] = useState<number>(0);

  const [studentReps, setStudentReps] = useState<number>(0);

  const selectedRecords = useRef<any>({});

  useEffect(() => {
    //TODO: generate ranges
    const ranges: any = {};
    for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
      const stepRecords: any = {};
      for (let index = 0; index < records.length; index++) {
        const record = records[index];

        if (stepIndex === record.nextStepIndex) {
          stepRecords[index] = true;
        }
      }
      ranges[stepIndex] = stepRecords;
    }
    selectedRecords.current = ranges;

    const setVideo = () => {
      const videoElement = videoRef.current;
      if (filePath && videoElement) {
        videoElement.currentTime = timespan.startTime;
        setStrigger(new Date().getTime());
      }
    };

    const setupData = () => {
      const canvasElement = canvasRef.current;
      if (canvasElement) {
        //TODO: use records instead of steps?
        const datasets = steps.map(({ order }) => {
          const data = records.map((r) => {
            const value = r.accuracy.find((a) => a.order === order);
            if (value) {
              return value.accuracy;
            }
          });

          const randomNumber = (min: number, max: number) => {
            return Math.random() * (max - min) + min;
          };
          //TODO: generate color
          const r = randomNumber(0, 255);
          const g = randomNumber(0, 255);
          const b = randomNumber(0, 255);

          const solidColor = `rgb(${r}, ${g}, ${b})`;
          const borderColor = `rgba(${r}, ${g}, ${b}, 0.9)`;
          const fillColor = `rgba(${r}, ${g}, ${b}, 0.2)`;

          const dataset = {
            label: `Step #${order + 1} accuracy (%)`,
            data: data,
            borderColor: borderColor,
            backgroundColor: solidColor,
            tension: 0.4,
            pointStyle: "circle",
            pointRadius: 5,
            pointBorderColor: solidColor,
            fill: {
              above: fillColor,
              below: "rgba(255, 255, 255, 0)",
              target: { value: thresholdAccuracy },
            },
          };
          return dataset;
        });

        const chart = new Chart(canvasElement, {
          type: "line",
          data: {
            labels: records.map((d) => d.classTime.toFixed(2)),
            datasets: datasets,
          },
          options: {
            maintainAspectRatio: false,
          },
        });
        chartRef.current = chart;

        const getFirstRepKeypoints = (
          records: StudentActivityTimespanExerciseRecord[]
        ) => {
          if (records.length > 1) {
            return records[1];
          }
          if (records.length === 1) {
            return records[0];
          }
        };

        const record = getFirstRepKeypoints(records);
        if (record) {
          setSelectedRecord(record);
        }

        canvasElement.onclick = (event: Event) => {
          const activePoints = chart.getElementsAtEventForMode(
            event,
            "point",
            { axis: "x", intersect: true },
            true
          );
          if (activePoints.length !== 0) {
            const activePoint = activePoints[0];
            const selectedRecordIndex = activePoint.index;
            const record = records[selectedRecordIndex];
            setSelectedRecord(record);
          }
        };
      }
    };

    setVideo();
    setupData();

    const setMaxRep = (records: StudentActivityTimespanExerciseRecord[]) => {
      let max = 0;
      for (let index = 0; index < records.length; index++) {
        const element = records[index];
        if (element.repetition > max) {
          max = element.repetition;
        }
      }
      setStudentReps(max);
    };
    setMaxRep(records);
  }, []);

  const onTimeUpdate = () => {
    const videoElement = videoRef.current;
    if (videoElement && !videoElement.paused) {
      const { currentTime } = videoElement;
      if (currentTime >= timespan.endTime) {
        videoElement.pause();
        videoElement.currentTime = timespan.startTime;
        setStrigger(new Date().getTime());
        return;
      }

      if (records.length > 0) {
        const closest = [...records].sort(
          (a, b) =>
            Math.abs(currentTime - a.classTime) -
            Math.abs(currentTime - b.classTime)
        )[0];

        const index = records.findIndex(
          (x) => closest.timestamp === x.timestamp
        );
        setSelectedRecordIndex(index);
      } else {
        setSelectedRecordIndex(-1);
      }
    }
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      if (selectedRecordIndex !== undefined && selectedRecordIndex >= 0) {
        const selectedRecord = records[selectedRecordIndex];
        setSelectedRecord(selectedRecord);

        const videoElement = videoRef.current;
        if (videoElement && videoElement.paused) {
          videoElement.currentTime = selectedRecord.classTime;
        }

        for (
          let index = 0;
          index < chart.config.data.datasets.length;
          index++
        ) {
          const stepRange = selectedRecords.current[index];

          if (index === selectedRecord.nextStepIndex) {
            const dataset = chart.config.data.datasets[index];
            const newPointColors = Array.from(Array(records.length)).map(
              (e, i) => {
                if (i < selectedRecordIndex && stepRange[i]) {
                  return Array.isArray(dataset["pointBackgroundColor"])
                    ? dataset["pointBackgroundColor"][i]
                    : dataset["backgroundColor"];
                }

                if (i === selectedRecordIndex) {
                  return green;
                }
                if (i > selectedRecordIndex) {
                  return Array.isArray(dataset["pointBackgroundColor"])
                    ? dataset["pointBackgroundColor"][i]
                    : dataset["backgroundColor"];
                }
                return "rgba(255, 99, 132, 0)"; //0.1
              }
            );

            dataset["pointBackgroundColor"] = newPointColors;

            dataset["pointBorderColor"] = Array.from(Array(records.length)).map(
              (e, i) => {
                if (i <= selectedRecordIndex && stepRange[i]) {
                  return Array.isArray(dataset["pointBorderColor"])
                    ? dataset["pointBorderColor"][i]
                    : dataset["pointBorderColor"];
                }

                if (i < selectedRecordIndex) {
                  return "rgba(255, 99, 132, 0)";
                }
                return Array.isArray(dataset["pointBorderColor"])
                  ? dataset["pointBorderColor"][i]
                  : dataset["pointBorderColor"];
              }
            );

            dataset["pointRadius"] = Array.from(Array(records.length)).map(
              (e, i) => (i === selectedRecordIndex ? 10 : 5)
            );
          } else {
            const dataset = chart.config.data.datasets[index];
            const newPointColors = Array.from(Array(records.length)).map(
              (e, i) => {
                if (i <= selectedRecordIndex && stepRange[i]) {
                  return Array.isArray(dataset["pointBackgroundColor"])
                    ? dataset["pointBackgroundColor"][i]
                    : dataset["backgroundColor"];
                }
                if (i > selectedRecordIndex) {
                  const dataset = chart.config.data.datasets[index];
                  return Array.isArray(dataset["pointBackgroundColor"])
                    ? dataset["pointBackgroundColor"][i]
                    : dataset["backgroundColor"];
                }
                return "rgba(255, 99, 132, 0)";
              }
            );

            dataset["pointBackgroundColor"] = newPointColors;

            dataset["pointBorderColor"] = Array.from(Array(records.length)).map(
              (e, i) => {
                if (i <= selectedRecordIndex && stepRange[i]) {
                  return Array.isArray(dataset["pointBorderColor"])
                    ? dataset["pointBorderColor"][i]
                    : dataset["pointBorderColor"];
                }

                if (i > selectedRecordIndex) {
                  return Array.isArray(dataset["pointBorderColor"])
                    ? dataset["pointBorderColor"][i]
                    : dataset["pointBorderColor"];
                }

                return "rgba(255, 99, 132, 0)";
              }
            );

            dataset["pointRadius"] = Array.from(Array(records.length)).map(
              (e, i) => 5
            );
          }
        }
      }
      chart.update();
    }
  }, [selectedRecordIndex]);

  const onTogglePlay = async () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.currentTime = timespan.startTime;
        await videoElement.play();
      } else {
        videoElement.pause();
      }
      setStrigger(new Date().getTime());
    }
  };

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h3">
          {timespan.name}. Repetitions {studentReps} out of required{" "}
          {repetitions}
        </Typography>
      </Grid>
      <Grid className={classes.chart} xs={12}>
        <canvas ref={canvasRef} />
      </Grid>
      <Grid className={classes.chart} item sm={4} xs={12}>
        {selectedRecord && (
          <SelectedPoint
            studentKeypoints={getFlippedDisplayKeypoints(
              selectedRecord.keypoints
            )}
            instructorKeypoints={getFlippedDisplayKeypoints(
              timespan.exerciseDetails
                ? timespan.exerciseDetails.steps[selectedRecord.nextStepIndex]
                    .keypoints
                : []
            )}
          />
        )}
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {selectedRecord && (
          <div style={{ height: 48, display: "flex", alignItems: "center" }}>
            <span>Regions used in calculations</span>
          </div>
        )}
        {selectedRecord && selectedRecord.jointsAccuracy && (
          <HumanRegionsAccuracy accuracyData={selectedRecord.jointsAccuracy} />
        )}
        {selectedRecord && (
          <div
            style={{
              height: 48,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#57c9d5",
                  margin: 5,
                }}
              ></div>
              <span>not used</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#ff0000",
                  margin: 5,
                }}
              ></div>{" "}
              <span>to</span>{" "}
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#00ff00",
                  margin: 5,
                }}
              ></div>
              <span>scale represents accuracy </span>
            </div>
          </div>
        )}
      </Grid>
      <Grid item className={classes.chart} sm={6} xs={12}>
        <video
          src={filePath}
          ref={videoRef}
          muted={true}
          style={{ width: "100%" }}
          onTimeUpdate={onTimeUpdate}
          crossOrigin="anonymous"
        />
      </Grid>
      {videoRef.current && (
        <Grid item xs={12}>
          <IconButton
            aria-label={videoRef.current.paused ? "Play" : "Stop"}
            onClick={onTogglePlay}
            className={classes.button}
          >
            {videoRef.current.paused ? (
              <PlayArrowIcon fontSize="inherit" />
            ) : (
              <StopIcon fontSize="inherit" />
            )}
          </IconButton>
        </Grid>
      )}
      <TimespanSummary
        timespan={{
          start_seconds: timespan.startTime,
          end_seconds: timespan.endTime,
          pose: timespan.poseDetails,
          exercise: timespan.exerciseDetails,
        }}
        studentRepsCounter={studentReps}
        score={timespan.score}
        effectiveness={timespan.effectiveness}
      />
    </Grid>
  );
}
