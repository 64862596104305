import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { AnalysisOverlay } from "../../../components/Analysis";
import { Button, Grid, LinearProgress } from "@material-ui/core";

import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import * as channelClassProvider from "../../../providers/student/channel-class-practice.provider";
import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";

import {
  checkIfLandmarkssWithinImage,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import { RewardType } from "../../../enums/reward-type.enum";
import * as audioService from "../../../services/audio.service";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Landmark } from "@mediapipe/pose";
import * as speechHelper from "../../../services/speech.helper";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import { BlockVideoType } from "../../../types/analyze/block-video-type.enum";
import { LiveClassPracticeModel } from "../../../types/analyze/live-class-practice.type";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import LiveModelCombinedPractice2D from "../../../components/Analysis/LiveModelCombined/LiveModelCombinedPractice2D";
import AccuracyProgressBar from "./components/AccuracyProgressBar";
import TimeCountdown from "./components/TimeCountdown";
import RepsProgressBar from "./components/RepsProgressBar";
import AccuracyChart from "./components/AccuracyChart";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ClassSummary from "./components/ClassSummary";
import logo from "../../../assets/images/logo_only2.png";
import { mapJointsAccuracy, round } from "../../../utils/conversion.functions";

const selfieMode = false;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: window.innerHeight,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: window.innerHeight,
    width: "100%",
  },
  blockContainer: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
  },
  studentCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s ease-in",
  },
  fullScreenButton: {
    position: "absolute",
    top: "60%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  startClassButton: {
    position: "absolute",
    top: "70%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  exitClassButton: {
    position: "absolute",
    top: "80%",
    right: "20%",
    pointerEvents: "all",
    zIndex: 10,
    width: 200,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid red",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  bottomContainer: {
    height: "18%",
    width: "100%",
    position: "fixed",
    bottom: 0,
    "&::before": {
      position: "fixed",
      bottom: 0,
      content: '""',
      height: "35vh",
      width: "100%",
      backgroundImage: "linear-gradient(180deg, transparent 25%, #1b1b1b 50%)",
      zIndex: -1,
    },
  },
}));

type Props = {
  classId: string;
  videoSrc: string;
};

export default function AnalyzeCourse(props: Props) {
  const { classId, videoSrc } = props;
  const classes = useStyles();
  const history = useHistory();

  const [accuracy, setAccuracy] = useState<number>(0);
  const [accuracyTimestamp, setAccuracyTimestamp] = useState<number>();
  const [repsCounter, setRepsCounter] = useState<number>(0);
  const [effectiveness, setEffectiveness] = useState<number>(0);
  const effectivenesRef = useRef<any>({});

  const [feedback, setFeedback] = useState<string>();

  //used for effectivenes calculation
  const accuracyRef = useRef<any>({});

  const [classData, setClassData] = useState<any>();

  const [live, setLive] = useState<LiveClassPracticeModel>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [blocks, setBlocks] = useState<ClassPracticeAnalysisBlock[]>();
  const [currentBlock, setCurrentBlock] =
    useState<ClassPracticeAnalysisBlock>();

  const [currentBlockPracticeIteration, setCurrentBlockPracticeIteration] =
    useState<number>(0);

  const [currentBlockVideoType, setCurrentBlockVideoType] =
    useState<BlockVideoType>(BlockVideoType.None);
  const [isBellEnabled, setIsBellEnabled] = useState<boolean>(true);

  //TODO: change with isClassStarted flag to enum
  const [isClassEnded, setIsClassEnded] = useState<boolean>(false);
  const blockRewardsRef = useRef<any>({});

  const onTimeUpdate = async (currentTime: number) => {
    const instructorVideoElement = instructorVideoRef.current;
    if (
      currentBlock &&
      instructorVideoElement &&
      currentBlockVideoType === BlockVideoType.Practice
    ) {
      const practiceVideoDuration = instructorVideoElement.duration;
      const practiceTime =
        currentBlockPracticeIteration === 0
          ? currentTime
          : currentBlockPracticeIteration * practiceVideoDuration + currentTime;

      if (practiceTime >= currentBlock.practice_duration_seconds) {
        if (blocks) {
          const nextBlockIndex = currentBlock.order + 1;
          const nextBlock =
            nextBlockIndex <= blocks.length - 1
              ? blocks[nextBlockIndex]
              : undefined;

          const calculateEffectiveness = (repsAccuracy: any) => {
            const keys = Object.keys(repsAccuracy);
            if (keys.length === 0) {
              return 0;
            }
            const sum = keys.reduce((acc, curr) => {
              return acc + repsAccuracy[curr];
            }, 0);
            console.log(keys.length);
            return Math.round(sum / keys.length);
          };

          const effectiveness = calculateEffectiveness(accuracyRef.current);
          setEffectiveness(effectiveness);
          effectivenesRef.current[currentBlock.id] = effectiveness;
          accuracyRef.current = {};

          //TODO: store effectiveness

          instructorVideoElement.pause();
          setCurrentBlockVideoType(BlockVideoType.Summary);
          const summaryDelay = 7000;
          if (nextBlock) {
            setTimeout(() => {
              setCurrentBlock(nextBlock);
              setCurrentBlockVideoType(BlockVideoType.Explanation);
              instructorVideoElement.src = nextBlock.explanation_file_path;
              instructorVideoElement.loop = false;
              instructorVideoElement.play();
            }, summaryDelay);
          } else {
            setTimeout(() => {
              setCurrentBlockVideoType(BlockVideoType.None);
              setCurrentBlock(undefined);
              setIsClassEnded(true);
            }, summaryDelay);
          }
        }
        setCurrentBlockPracticeIteration(0);
      }
    }
  };

  const onInstructorVideoEnded = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (!instructorVideoElement) {
      return;
    }

    if (currentBlockVideoType === BlockVideoType.Explanation && currentBlock) {
      setAccuracy(0);
      setAccuracyTimestamp(0);
      setRepsCounter(0);
      setCurrentBlockVideoType(BlockVideoType.Practice);
      instructorVideoElement.src = currentBlock.practice_file_path;
      instructorVideoElement.play();
    }
    if (currentBlockVideoType === BlockVideoType.Practice) {
      instructorVideoElement.play();
      setCurrentBlockPracticeIteration(currentBlockPracticeIteration + 1);
    }
  };

  const onPlayBeep = () => {
    if (isBellEnabled) {
      audioService.beep();
    }
  };

  const instructorVideoRef = useRef<HTMLVideoElement>(null);

  // const onToggleBell = () => {
  //   setIsBellEnabled(!isBellEnabled);
  // };

  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };

  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastBlockId, setLastBlockId] = useState<string>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [lastInstructorVideoPaused, setLastInstructorVideoPaused] = useState<
    boolean | undefined
  >(false);
  const [canStartClass, setCanStartClass] = useState<boolean>(false);
  const [isClassStarted, setIsClassStarted] = useState<boolean>(false);

  const repetitionAccuracyRef = useRef<any>({});

  const onRepetition = (repetition: number, repetitionAccuracy: number) => {
    if (isBellEnabled) {
      speechHelper.speak(repetition.toString());
    }
    setRepsCounter(repetition);
    repetitionAccuracyRef.current[repetition] = repetitionAccuracy;
    //TODO: store rep accuracy for progressbar
  };

  const onSaveReward = (
    reward: RewardType,
    rewardProgressPercentage: number,
    blockId: string,
    blockOrder: number
  ) => {
    blockRewardsRef.current[blockId] = {
      blockId,
      reward,
      rewardProgressPercentage,
    };
  };

  const feedbaclClearingTimeoutRef = useRef<NodeJS.Timeout>();
  const [allowFeedbackUpdate, setAllowFeedbackUpdate] = useState<boolean>(true);

  const updateFeedback = (
    feedback: string | undefined,
    newFeedback: string | undefined
  ) => {
    if (feedback === undefined && newFeedback !== undefined) {
      setAllowFeedbackUpdate(false);
      setFeedback(newFeedback);
      feedbaclClearingTimeoutRef.current = setTimeout(() => {
        setFeedback(undefined);
        setTimeout(() => {
          //TODO: wait for 3 seconds from prev feedback
          setAllowFeedbackUpdate(true);
        }, 3000);
      }, 5000);
    }
  };

  const onExerciseAccuracyCalculated = (
    accuracyData: { [key: number]: number },
    live: LiveClassPracticeModel,
    repetition: number,
    nextExerciseStepIndex: number,
    mergedAccuracyData: { [key: number]: any }
  ) => {
    const accuracy = accuracyData[nextExerciseStepIndex];
    if (accuracy !== undefined) {
      setAccuracy(accuracy);

      accuracyRef.current[live.timestamp] = accuracy;
      //TODO: wait for 5 seconds from timespan start
      const canShowFeedback = live.blockTime >= 5;
      if (canShowFeedback && allowFeedbackUpdate) {
        const newFeedback = avatarFeedback.getFeedback(
          accuracy,
          mergedAccuracyData[nextExerciseStepIndex]
        );
        updateFeedback(feedback, newFeedback);
      }
    }
  };

  const onPoseAccuracyCalculated = (
    accuracy: number,
    live: LiveClassPracticeModel,
    mergedAccuracyData: any
  ) => {
    const canShowFeedback = live.blockTime >= 5;
    if (canShowFeedback && allowFeedbackUpdate) {
      const newFeedback = avatarFeedback.getFeedback(
        accuracy,
        mergedAccuracyData
      );
      updateFeedback(feedback, newFeedback);
    }

    if (Number.isNaN(accuracy)) {
      return;
    }

    accuracyRef.current[`${live.blockTime}`] = accuracy;

    setAccuracy(accuracy);
    setAccuracyTimestamp(live.blockTime);
  };

  useEffect(() => {
    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();
      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const startVideo = () => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.loop = true;
      videoElement.muted = true;
      videoElement.crossOrigin = "anonymous";
      const onCanPlayHandler = async () => {
        videoElement.play();
        processFrame();
        videoElement.removeEventListener("canplay", onCanPlayHandler);
      };

      videoElement.addEventListener("canplay", onCanPlayHandler);
      videoElement.src = videoSrc;
      studentVideoElementRef.current = videoElement;
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00035; //0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const instructorVideoElement = instructorVideoRef.current;
      if (!instructorVideoElement) {
        return;
      }

      const { blocks, instructor, avatar, type } =
        await channelClassProvider.getForAnalysis(classId);

      setClassData({
        instructor,
        avatar,
        type,
        classDuration: blocks.reduce((acc, curre) => {
          acc =
            acc +
            curre.explanation_duration_seconds +
            curre.practice_duration_seconds;
          return acc;
        }, 0),
      });
      blocks.sort((a, b) => a.order - b.order);
      setBlocks(blocks);
    };

    const initializeModel = async (classId: string) => {
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      startVideo();
      speechHelper.initSpeech();
      setCanStartClass(true);
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
          }
        }
      }
    };

    if (classId) {
      initializeModel(classId);
    }
  }, []);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;

    if (videoElement) {
      const model = mediapipeModelRef.current;
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        //TODO: check if needed?
        setLastCurrentTime(
          getInstructorVideoCurrentTime(instructorVideoElement)
        );

        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        //TODO: check if needed?
        if (currentBlock) {
          setLastBlockId(currentBlock.id);
        } else {
          setLastBlockId(undefined);
        }

        const instructorVideo = instructorVideoRef.current;
        if (instructorVideo) {
          setLastInstructorVideoPaused(instructorVideo.paused);
        } else {
          setLastInstructorVideoPaused(undefined);
        }
        await model.send({ image: videoElement });
      }
    }
  };

  useEffect(() => {
    const onResults = async (
      results: any,
      blocks: ClassPracticeAnalysisBlock[]
    ) => {
      const { poseLandmarks, image } = results;

      if (poseLandmarks && blocks) {
        const processLandmarks = (
          poseLandmarks: Landmark[],
          blocks: ClassPracticeAnalysisBlock[]
        ) => {
          const filterKalmanUnity = (
            measurements: Measurement3D[],
            joints: Landmark[],
            customKalmanFilter: CustomKalmanFilter
          ) => {
            const filteredUnity = applyKalmanFilterUnity(
              measurements,
              joints,
              customKalmanFilter
            );
            if (filteredUnity) {
              setMeasurements(filteredUnity);
              const mappedUnity = filteredUnity.map((f, index: number) => {
                return {
                  x: f.pos3d.x,
                  y: f.pos3d.y,
                  z: f.pos3d.z,
                  visibility: joints[index].visibility,
                };
              });
              return mappedUnity;
            }
          };

          const canvasElement = canvasRef.current;
          if (!canvasElement) {
            return;
          }

          const block = blocks.find((x) => x.id === lastBlockId);

          const getFilter = (block: ClassPracticeAnalysisBlock | undefined) => {
            if (block && block.exercise) {
              return customKalmanFilterFastRef.current;
            }
            return customKalmanFilterSlowRef.current;
          };

          const filter = getFilter(block);
          if (!filter) {
            return;
          }

          const filtered = filterKalmanUnity(
            measurements,
            poseLandmarks,
            filter
          );
          if (!filtered) {
            return;
          }

          const { width, height } = canvasElement;
          const denormalized = mediapipeKeypoint.denormalize(
            filtered,
            width,
            height
          );
          const studentKeypointsScaledByZ = mapForAnalysis(denormalized);
          drawOriginal(filtered, image, canvasElement);
          const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
            denormalized,
            width,
            height
          );

          if (lastCurrentTime !== undefined && lastTimestamp) {
            const live: LiveClassPracticeModel = {
              studentKeypoints: studentKeypointsScaledByZ,
              blockTime: lastCurrentTime,
              timestamp: lastTimestamp,
              isLandmarksWithinImage: isLandmarksWithinImage,
              studentActivityBlockId: undefined,
              isInstructorVideoPaused: lastInstructorVideoPaused,
            };

            setLive(live);
          } else {
            setLive(undefined);
            const canvasElement = canvasRef.current;
            if (canvasElement) {
              drawOriginal([], image, canvasElement);
            }
          }
        };
        processLandmarks(poseLandmarks, blocks);
      } else {
        setLive(undefined);
        const canvasElement = canvasRef.current;
        if (canvasElement) {
          drawOriginal([], image, canvasElement);
        }
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results && blocks) {
      onResults(results, blocks);
    }
  }, [results]);

  const [isInFrameCheckPassed, setIsInFrameCheckPassed] =
    useState<boolean>(false);

  const onStartVideo = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && instructorVideoElement.paused) {
      await instructorVideoElement.play();
    }
    setIsInFrameCheckPassed(true);
  };

  const onTogglePlay = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      if (instructorVideoElement.paused) {
        await instructorVideoElement.play();
      } else {
        instructorVideoElement.pause();
      }
    }
  };

  const onStartClass = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (blocks && blocks.length > 0 && instructorVideoElement) {
      const currentBlock = blocks[0];
      setCurrentBlock(currentBlock);
      setCurrentBlockVideoType(BlockVideoType.Explanation);
      instructorVideoElement.src = currentBlock.explanation_file_path;
      instructorVideoElement.muted = true;
      await instructorVideoElement.play();
      instructorVideoElement.pause();
      instructorVideoElement.muted = false;
    }
    setIsClassStarted(true);
  };

  // const onGoActivityClickHandler = () => {
  //   //TODO: redirect to activity
  //   history.push(`/sports-student/practice-activity/${studentActivityId}`);
  // };

  const getInstructorVideoCurrentTime = (
    instructorVideoElement: HTMLVideoElement
  ) => {
    if (currentBlockVideoType === BlockVideoType.Practice) {
      const { duration, currentTime } = instructorVideoElement;
      return currentBlockPracticeIteration === 0
        ? currentTime
        : currentBlockPracticeIteration * duration + currentTime;
    } else {
      return instructorVideoElement.currentTime;
    }
  };

  const onReturnClickHandler = () => {
    history.push("/");
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const calculateClassEfeectiveness = () => {
    const keys = Object.keys(effectivenesRef.current);
    if (keys.length === 0) {
      return 0;
    }
    const sum = keys.reduce((acc, curr) => {
      const effectiveness = effectivenesRef.current[curr];
      return acc + effectiveness;
    }, 0);
    return sum / keys.length;
  };

  const pauseTimeoutRef = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    const onChange = (live: any) => {
      const videoElement = instructorVideoRef.current;
      if (isInFrameCheckPassed && videoElement) {
        if (!pauseTimeoutRef.current && !live) {
          pauseTimeoutRef.current = setTimeout(() => {
            videoElement.pause();
            pauseTimeoutRef.current = undefined;
          }, 7000);
        }
        if (pauseTimeoutRef.current && live) {
          clearTimeout(pauseTimeoutRef.current);
          pauseTimeoutRef.current = undefined;
        }
      }
    };

    onChange(live);
  }, [live]);

  return (
    <div className={classes.root}>
      {!isProcessingStarted && (
        <LinearProgress
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            height: 7,
            zIndex: 10,
          }}
        />
      )}

      {!isClassEnded && (
        <Grid
          className={classes.container}
          style={{
            visibility: !isProcessingStarted ? "hidden" : "visible",
            width: "100%",
          }}
          container
        >
          <div
            style={{
              position: "absolute",
              top: window.innerHeight / 4,
              zIndex: 1,
              height: window.innerHeight / 3,
              transition: "opacity 0.6s",
              opacity:
                isClassStarted &&
                currentBlockVideoType === BlockVideoType.Practice &&
                instructorVideoRef.current &&
                !instructorVideoRef.current.paused
                  ? 1
                  : 0,
            }}
          >
            <AccuracyProgressBar accuracy={accuracy} />
          </div>
          {isClassStarted &&
            instructorVideoRef.current &&
            !instructorVideoRef.current.paused &&
            currentBlock &&
            currentBlockVideoType === BlockVideoType.Practice && (
              <div
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  zIndex: 1,
                }}
              >
                <TimeCountdown
                  duration={currentBlock.practice_duration_seconds}
                  currentTime={getInstructorVideoCurrentTime(
                    instructorVideoRef.current
                  )}
                />
              </div>
            )}
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isClassStarted ? "height 0.6s" : "",
              height:
                currentBlockVideoType === BlockVideoType.Practice
                  ? "83vh"
                  : "100vh",
              borderRight: "4px solid grey",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                // transform: "translateX(-50%)",
                // marginLeft: "50%",
                //marginLeft: "40%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <canvas
                ref={canvasRef}
                width={640}
                height={480}
                style={{ height: "100%" }}
              />
            </div>
          </Grid>
          <Grid
            xs={6}
            item
            className={classes.blockContainer}
            style={{
              transition: isClassStarted ? "height 0.6s" : "",
              opacity: isClassStarted ? 1 : 0,
              height:
                currentBlockVideoType === BlockVideoType.Practice
                  ? "83vh"
                  : "100vh",
              borderLeft: "4px solid grey",
            }}
          >
            <video
              style={{
                height: "100%",
                transform: "translateX(-50%)",
                marginLeft: "50%",
              }}
              crossOrigin="anonymous"
              onEnded={onInstructorVideoEnded}
              onTimeUpdate={() =>
                onTimeUpdate(
                  instructorVideoRef.current
                    ? instructorVideoRef.current.currentTime
                    : 0
                )
              }
              ref={instructorVideoRef}
            />
          </Grid>
          <Grid
            xs={12}
            item
            container
            className={classes.bottomContainer}
            style={{
              transition: "opacity 0.6s",
              opacity:
                isClassStarted &&
                currentBlock &&
                currentBlockVideoType === BlockVideoType.Practice
                  ? 1
                  : 0,
            }}
          >
            {currentBlock && currentBlock.exercise && (
              <Grid
                xs={2}
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <span style={{ fontSize: 40 }}>Repetitions</span>
                <div>
                  <span style={{ fontSize: 80, fontWeight: 500 }}>
                    {repsCounter}
                  </span>
                  <span style={{ fontSize: 60 }}>/</span>
                  <span style={{ fontSize: 60 }}>
                    {currentBlock.exercise.repetitions}
                  </span>
                </div>
              </Grid>
            )}
            {currentBlock &&
              currentBlock.exercise &&
              currentBlockVideoType === BlockVideoType.Practice && (
                <Grid xs={10}>
                  <RepsProgressBar
                    requiredReps={currentBlock.exercise.repetitions}
                    studentReps={repsCounter}
                    repsAccuracy={repetitionAccuracyRef.current}
                  />
                </Grid>
              )}
            {currentBlock &&
              currentBlock.pose &&
              currentBlockVideoType === BlockVideoType.Practice && (
                <AccuracyChart
                  duration={currentBlock.practice_duration_seconds}
                  accuracy={
                    accuracyTimestamp !== undefined
                      ? {
                          value: accuracy,
                          seconds: accuracyTimestamp,
                        }
                      : undefined
                  }
                />
              )}
          </Grid>
          {studentVideoElementRef.current &&
            canvasRef.current &&
            instructorVideoRef.current &&
            isClassStarted &&
            blocks && (
              <LiveModelCombinedPractice2D
                live={live}
                selfieMode={selfieMode}
                currentTime={instructorVideoRef.current.currentTime}
                onPoseAccuracy={onPoseAccuracyCalculated}
                onExerciseAccuracy={onExerciseAccuracyCalculated}
                onSaveReward={onSaveReward}
                onRepetition={onRepetition}
                onPlayBeep={onPlayBeep}
                currentBlock={currentBlock}
                currentBlockVideoType={currentBlockVideoType}
                blocks={blocks}
                videoPartCurrentTime={getInstructorVideoCurrentTime(
                  instructorVideoRef.current
                )}
              />
            )}
          <Grid className={classes.studentCanvas}>
            {instructorVideoRef.current && (
              <AnalysisOverlay
                isLandmarksWithinImage={!!(live && live.isLandmarksWithinImage)}
                isClassStarted={isClassStarted}
                onCanStartPlayback={onStartVideo}
                blockVideoType={currentBlockVideoType}
                isInstructorVideoPlaying={!instructorVideoRef.current.paused}
                blockVideoCurrentTime={getInstructorVideoCurrentTime(
                  instructorVideoRef.current
                )}
                block={currentBlock}
                blocks={blocks}
                studentRepsCounter={repsCounter}
                reward={
                  currentBlock && blockRewardsRef.current[currentBlock.id]
                }
                classData={classData}
                onExit={onReturnClickHandler}
                isFullScreenEnabled={!!document.fullscreenElement}
                toggleFullScreen={toggleFullScreen}
                isInstructorVideoMuted={instructorVideoRef.current.muted}
                toggleMute={onToggleInstructorVideoSound}
                togglePlay={onTogglePlay}
                effectiveness={effectiveness}
                feedback={feedback}
              />
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.fullScreenButton}
                onClick={toggleFullScreen}
                variant="contained"
                size="large"
                color="default"
                startIcon={
                  !document.fullscreenEnabled ? (
                    <FullscreenIcon />
                  ) : (
                    <FullscreenExitIcon />
                  )
                }
              >
                Fullscreen
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButton}
                onClick={onStartClass}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start Class
              </Button>
            )}
            {!isClassStarted && isProcessingStarted && (
              <Button
                onClick={onReturnClickHandler}
                className={classes.exitClassButton}
                variant="contained"
                size="large"
                color="primary"
                startIcon={<ExitToAppIcon />}
              >
                Exit
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {isClassEnded && blocks && (
        <ClassSummary
          instructorAvatar={classData.avatar}
          instructorName={classData.instructor}
          effectiveness={calculateClassEfeectiveness()}
          classThumbnail={blocks[0] ? blocks[0].thumbnail_path : ""}
          channelType={classData.type}
          rewards={blockRewardsRef.current}
          blocksCount={blocks.length}
          classDuration={blocks.reduce((acc, curr) => {
            return (
              acc +
              curr.explanation_duration_seconds +
              curr.practice_duration_seconds
            );
          }, 0)}
          onReturnClickHandler={onReturnClickHandler}
        />
      )}
    </div>
  );
}
