import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export const get = async (): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/summary/get",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,     
    },
  };
  const response = await axios(config);
  return response.data;
};


export const getByMonth = async (date: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/summary/getByMonth",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      date: date    
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getRank = async (): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/summary/getRank",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};