import React, { Fragment } from 'react';
import { makeStyles, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { secondsToTimeString } from "../../../../../../../utils/conversion.functions"
import {
  Typography,
  CircularProgress,
  Dialog,
  Button,
  List,
  ListItem,
  IconButton, Box, Avatar,
} from '@material-ui/core';
import {ChannelCourseListItem, ClassModalDetails} from '../../../../../../../providers/student/channel-class.provider';
import defaultThumbnail from "../../../../../../../assets/images/channel-thumbnail.png";
import yogaInstructor from "../../../../../../../assets/images/yoga_instructor.jpg";
import {Rating} from "@material-ui/lab";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      overflow: "hidden"
      // paddingRight: theme.spacing(2),
      // paddingLeft: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const useStyles = makeStyles((theme: Theme) => ({
    poseList: {
      maxHeight: '400px',
      overflowY: 'auto'
    },
    cover: {
      height: 200,
      width: "100%"
    },
    loadingCircle: {
      position: "relative",
      marginLeft: "50%",
      left: "-20px",
      marginTop: "50%",
      top: "-100px"
    }
  }));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    overflow: "hidden",
    padding: theme.spacing(0),
    "&:first-child": {
      paddingTop: 0
    }
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    // padding: theme.spacing(1),
  },
}))(MuiDialogActions);

type Props = {
  classModalDetails: ClassModalDetails | undefined,
  selectedCourse: ChannelCourseListItem,
  modalLoading: boolean,
  open: boolean,
  handleClose: () => void
};

export default function CustomizedDialogs(props: Props) {
  const { modalLoading, classModalDetails, open, handleClose, selectedCourse } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog onClose={handleClose} maxWidth={'xs'} fullWidth={true} aria-labelledby="customized-dialog-title" open={open}>
        {/*<DialogTitle id="customized-dialog-title" onClose={handleClose}>*/}
          {/*<Typography variant="h2">Class Details</Typography>*/}
        {/*</DialogTitle>*/}
        <DialogContent >
          {
            classModalDetails
            && !modalLoading
            && <Box>
                <img  className={classes.cover} src={selectedCourse.thumbnail_path} alt="class cover"/>
                <Box style={{padding: "10px"}}>
                  <Typography variant="h3" gutterBottom>{classModalDetails.name}</Typography>
                  {/* TODO: convert to readable string */}
                  <Typography variant="h6" gutterBottom><b>Duration:</b> {secondsToTimeString(classModalDetails.duration)}</Typography>
                  <Typography variant="h6" gutterBottom><b>Description:</b> This basic indoor class is suitable for all levels beginner to pro. It’s easily accessible and can be done in 15 minutes in the morning.</Typography>
                  <Typography variant="h6" gutterBottom>
                    <b>{`${classModalDetails.poses.length} Pose(s): `}</b>{classModalDetails.poses.map(p => {return `${p.name} `})}
                  </Typography>
                  <Box display="flex" style={{marginTop: "15px"}}>
                    <Avatar alt='instructor avatar' src={yogaInstructor}/>
                    <Box display="block" style={{marginLeft: "10px"}}>
                      <Typography variant="h6">{selectedCourse.first_name} {selectedCourse.last_name}</Typography>
                      <Rating name="read-only" value={4.2} size='small' readOnly />
                    </Box>
                  </Box>
                </Box>
            </Box>
          }
          {
            modalLoading 
            && <CircularProgress className={classes.loadingCircle}/>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
