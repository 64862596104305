import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 400,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

type Props = {
  handleConfirm: (data: any) => void;
  dialogTitle: string;
  data: any;
};

export default function AlertDialog(props: Props) {
  const classes = useStyles();
  const { dialogTitle, handleConfirm, data } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickConfirm = () => {
    setOpen(false);
    //console.log(handleConfirm);
    handleConfirm(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      setOpen(true);
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography className={classes.title}>{dialogTitle}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleClickConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
