let voice: SpeechSynthesisVoice | undefined = undefined;

const getVoice = (voices: SpeechSynthesisVoice[]) => {
  if (voices && voices.length > 0) {
    const microsoftWomenVoiceName = "Microsoft Zira - English (United States)";
    const microsoftWomenVoice = voices.find(
      (x) => x.name === microsoftWomenVoiceName
    );
    if (microsoftWomenVoice) {
      return microsoftWomenVoice;
    } else {
      return voices[0];
    }
  }
};

export const initSpeech = () => {
  if (speechSynthesis) {
    const voices = speechSynthesis.getVoices();
    if (!voices || voices.length === 0) {
      const onVoicesChanged = () => {
        const voices = speechSynthesis
          .getVoices()
          .filter((x) => x.lang === "en-US" && x.localService === true);
        voice = getVoice(voices);
        speechSynthesis.removeEventListener("voiceschanged", onVoicesChanged);
      };

      speechSynthesis.addEventListener("voiceschanged", onVoicesChanged);
    } else {
      voice = getVoice(voices);
    }
  }
};

export const speak = (text: string) => {
  if (speechSynthesis && voice) {
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.voice = voice;
    speechSynthesis.speak(utterThis);
  }
};
