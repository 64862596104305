import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AvatarType } from "../../../../../../services/avatar/avatar-type.enum";
import * as authService from "../../../../../../services/auth.service";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { ChannelClassSequenceOption } from "../../../../../../providers/instructor/channel-class-sequence.provider";

const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "contain",
  },
  card: {
    position: "relative",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  publishIcon: {
    position: "absolute",
    bottom: 10,
    left: 10,
  },
  publishedIcon: {
    color: "green",
  },
  unpublishedIcon: {
    color: "red",
  },
  durationContainer: {
    position: "absolute",
    bottom: 10,
    right: 5,
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 4,
    paddingRight: 4,
  },
  description: {
    fontSize: 14,
    marginLeft: 10,
    
    whiteSpace: "nowrap",
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    color: "#797878",
  },
  image: {
    width: "100%",
    height: 250,
    objectFit: "contain",
  },
  cover: {
    position: "relative",
  },
}));

type Props = {
  classSequence: ChannelClassSequenceOption;
  channelId: string;
  onRemove: (id: string) => void;
  onPublish: (id: string) => void;
};

export default function CourseCard(props: Props) {
  const { classSequence, onRemove, onPublish, channelId } = props;
  const history = useHistory();
  const classes = useStyles();

  const isAdmin = authService.isAdmin();

  const onEdit = (id: string) => {
    history.push(
      `/sports-instructor/channel/${channelId}/class-sequence/${id}/edit`
    );
  };

  const onAnalysis = () => {
    history.push(
      `/sports-instructor/take-class-sequence/${classSequence.id}/${classSequence.channel_id}/${AvatarType.Male}`
    );
  };

  const onAnalysisTest = () => {
    history.push(
      `/sports-instructor/take-class-sequence-test/${classSequence.id}/${classSequence.channel_id}/${AvatarType.Male}`
    );
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <div className={classes.cover}>
          <img
            alt="card thumbnail"
            className={classes.image}
            src={classSequence.thumbnail_path}
            title={classSequence.name}
          />
          {classSequence.is_published ? (
            <CheckBoxIcon
              className={`${classes.publishIcon} ${classes.publishedIcon}`}
            />
          ) : (
            <IndeterminateCheckBoxIcon
              className={`${classes.publishIcon} ${classes.unpublishedIcon}`}
            />
          )}
        </div>
        <CardContent>
          <Typography
            gutterBottom
            className={classes.name}
            title={classSequence.name}
            variant="h3"
            component="h2"
          >
            {classSequence.name}
          </Typography>
          <Typography className={classes.description} variant="h6">
            {classSequence.description}
          </Typography>
          <Typography className={classes.description} variant="h6">
            {new Date(classSequence.creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => onEdit(classSequence.id)}
        >
          Edit
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onPublish(classSequence.id)}
        >
          {classSequence.is_published ? "Unpublish" : "Publish"}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onRemove(classSequence.id)}
        >
          Remove
        </Button>
        <Button size="small" color="primary" onClick={() => onAnalysis()}>
          Live
        </Button>
        {isAdmin && (
          <Button size="small" color="primary" onClick={() => onAnalysisTest()}>
            Test
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
