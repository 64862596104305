import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  StudentCanvas,
  SnapshotSkeletonsPlaceholder,
  VideoControl,
  SoundControl,
  InstructorVideo,
  LiveModelCombined,
  InstructorSoundControl,
} from "../../../components/Analysis";
import { Button, CircularProgress, Grid, Modal } from "@material-ui/core";

import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";

import * as channelClassProvider from "../../../providers/student/channel-class.provider";

import { LiveClassModel } from "../../../types/analyze/live.class.type";

import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";

import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import {
  checkIfLandmarkssWithinImage,
  defaultTPoseSkeleton,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import { CourseAnalysisDetails } from "../../../types/class/class-analysis-details";
import { RewardType } from "../../../enums/reward-type.enum";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";
import * as audioService from "../../../services/audio.service";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { Landmark } from "@mediapipe/pose";
import * as speechHelper from "../../../services/speech.helper";

const selfieMode = true;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  container: {
    position: "relative",
    height: window.innerHeight - 64,
    width: "100%",
  },
  controls: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    bottom: 5,
    right: 5,
  },
  testCanvas: {
    marginTop: 200,
  },
  studentCanvas: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s ease-in",
    pointerEvents: "none",
  },
  startClassButton: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    top: "50%",
    left: 0,
    right: 0,
    textAlign: "center",
    pointerEvents: "all",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid red",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

type Params = {
  classId?: string;
  modelTypeString: string;
};

export default function AnalyzeCourse() {

  const pauseTimeoutRef = useRef<NodeJS.Timer>();
  const classes = useStyles();
  const [live, setLive] = useState<LiveClassModel>();
  const { classId, modelTypeString } = useParams<Params>();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [course, setCourse] = useState<CourseAnalysisDetails>();
  const [avatarType, setAvatarType] = useState<AvatarType>(AvatarType.None);

  const [isBellEnabled, setIsBellEnabled] = useState<boolean>(true);

  const onPlayBeep = () => {
    if (isBellEnabled) {
      audioService.beep();
    }
  };

  const onToggleBell = () => {
    setIsBellEnabled(!isBellEnabled);
  };
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const onDataLoaded = () => {
    setIsVideoLoaded(true);
  };

  const instructorVideoRef = useRef<HTMLVideoElement>(null);

  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };

  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastTimeSpanId, setLastTimeSpanId] = useState<string>();
  const [lastTimestamp, setLastTimestamp] = useState<number>(); 

  const [canStartClass, setCanStartClass] = useState<boolean>(false);

  const [isClassStarted, setIsClassStarted] = useState<boolean>(false);
  
  const [stream, setStream] = useState<MediaStream>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [streamErrorMessage, setStreamErrorMessage] = useState<string>();

  const onRepetition = (text: string) => {
    if (isBellEnabled) {
      speechHelper.speak(text);
    }
  };

  const onSaveReward = (reward: RewardType, timespanId: string) => {};

  useEffect(() => {
    const initVideoStream = async () => {
      const video = {
        width: 640,
        height: 480,
        //TODO: try to limit processing by fps
      };
      const constraints = {
        audio: false,
        video: video,
      };

      const getMedia = async (constraints: MediaStreamConstraints) => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          return stream;
        } catch (err) {
          const getErrorMessage = (errorName: string) => {
            if (errorName === "NotAllowedError") {
              return "In order to proceed - give permission to access camera";
            }

            if (errorName === "NotFoundError") {
              return "Camera device not found";
            }
            return "An error occurred while accessing camera";
          };

          const errorMessage = getErrorMessage(err.name);
          setStreamErrorMessage(errorMessage);
          handleOpen();
          //If the user denies permission, or matching media is not available, then the promise is rejected with NotAllowedError or NotFoundError respectively
        }
      };

      const stream = await getMedia(constraints);
      if (stream) {
        setStream(stream);
      }
    };

    initVideoStream();
  }, []);

  useEffect(() => {
    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();
      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const initVideoElement = (stream: MediaStream) => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.crossOrigin = "anonymous";
      const onCanPlayHandler = async () => {
        videoElement.play();
        const model = mediapipeModelRef.current;
        await model.send({ image: videoElement });
        videoElement.removeEventListener("canplay", onCanPlayHandler);
      };

      videoElement.addEventListener("canplay", onCanPlayHandler);
      videoElement.srcObject = stream;
      studentVideoElementRef.current = videoElement;
    };
    
    const parseAvatarType = (typeString: string) => {
      if (typeString === "1") {
        setAvatarType(AvatarType.Male);
      }
      if (typeString === "2") {
        setAvatarType(AvatarType.Female);
      }
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00035; //0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const course = await channelClassProvider.getForAnalysis(classId);
      setCourse(course);
    };


    const initialize = async (
      classId: string,
      modelTypeString: string,
      stream: MediaStream
    ) => {
      parseAvatarType(modelTypeString);
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      initVideoElement(stream);
      speechHelper.initSpeech();

      setCanStartClass(true);
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
          }
        }
      }
    };

    if (classId && stream) {
      initialize(classId, modelTypeString, stream);
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  useEffect(() => {
    const onResults = async (
      results: any,
      cource: CourseAnalysisDetails | undefined
    ) => {
      const { poseLandmarks, image } = results;
      const landmarks = poseLandmarks as Landmark[];     

      if (landmarks && cource) {
        const { timespans } = cource;
        const processLandmarks = (
          landmarks: Landmark[],
          timespans: CourseAnalysisTimespan[]
        ) => {
          const filterKalmanUnity = (
            measurements: Measurement3D[],
            joints: Landmark[],
            customKalmanFilter: CustomKalmanFilter
          ) => {
            const filteredUnity = applyKalmanFilterUnity(
              measurements,
              joints,
              customKalmanFilter
            );
            if (filteredUnity) {
              setMeasurements(filteredUnity);
              const mappedUnity = filteredUnity.map((f, index: number) => {
                return {
                  x: f.pos3d.x,
                  y: f.pos3d.y,
                  z: f.pos3d.z,
                  visibility: joints[index].visibility,
                };
              });
              return mappedUnity;
            }
          };

          const denormalize = (landmarks: Landmark[]) => {
            const canvasElement = canvasRef.current;
            if (canvasElement) {
              const { width, height } = canvasElement;
              return mediapipeKeypoint.denormalize(landmarks, width, height);
            }
            return landmarks;
          };

          const canvasElement = canvasRef.current;
          if (!canvasElement) {
            return;
          }

          const timespan = timespans.find((x) => x.id === lastTimeSpanId);

          const getFilter = (timespan: CourseAnalysisTimespan | undefined) => {
            if (timespan && timespan.exercise) {
              return customKalmanFilterFastRef.current;
            }
            return customKalmanFilterSlowRef.current;
          };

          const filter = getFilter(timespan);
          if (!filter) {
            return;
          }

          const filtered = filterKalmanUnity(measurements, landmarks, filter);
          if (!filtered) {
            return;
          }
          const filteredDenormalized = denormalize(filtered);
          const { width, height } = canvasElement;
          const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
            filteredDenormalized,
            width,
            height
          );
          const denormalized = isLandmarksWithinImage
            ? filteredDenormalized
            : defaultTPoseSkeleton;
          drawOriginal(filtered, image, canvasElement);
          const studentKeypointsScaledByZ = mapForAnalysis(denormalized);

          if (lastCurrentTime !== undefined && lastTimestamp) {
            const live: LiveClassModel = {
              studentKeypoints: studentKeypointsScaledByZ,
              classTime: lastCurrentTime,
              timestamp: lastTimestamp,
              isLandmarksWithinImage: isLandmarksWithinImage,
              studentActivityTimespanId: undefined,
            };

            setLive(live);
          }
          else {
            setLive(undefined);
            const canvasElement = canvasRef.current;
            if (canvasElement) {
              drawOriginal([], image, canvasElement);
            }
          }
        };
        processLandmarks(landmarks, timespans);
      } else {
        setLive(undefined);
        const canvasElement = canvasRef.current;
        if (canvasElement) {
          drawOriginal([], image, canvasElement);
        }
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results) {
      onResults(results, course);
    }
  }, [results]);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;
    if (videoElement) {
      const model = mediapipeModelRef.current;
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        const currentTime = instructorVideoElement.currentTime;
        setLastCurrentTime(currentTime);
        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        if (course) {
          const timeSpan = course.timespans.find(
            (x) =>
              x.start_seconds <= currentTime && x.end_seconds >= currentTime
          );
          if (timeSpan) {
            setLastTimeSpanId(timeSpan.id);
          } else {
            setLastTimeSpanId(undefined);
          }
        }
        await model.send({ image: videoElement });
      }
    }
  };

  const onExercisePeakReached = (
    accuracyData: { [key: number]: number },
    live: LiveClassModel,
    repetition: number
  ) => {};

  const onPoseAccuracyCalculated = (
    accuracy: number,
    live: LiveClassModel
  ) => {};

  const onCountdownEnded = async () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement && instructorVideoElement.paused) {
      try {
        await instructorVideoElement.play();
      } catch (err) {
        console.log("video error");
        console.log(err);
      }
    }
  };

  const onStartClass = async () => {
    setIsClassStarted(true);
  };

  useEffect(() => {
    const onChange = async (
      isClassStarted: boolean,
      live: LiveClassModel | undefined
    ) => {
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement && isClassStarted) {
        const isLandmarksWithinImage = live && live.isLandmarksWithinImage;
        if (
          !isLandmarksWithinImage &&
          !instructorVideoElement.paused &&
          !pauseTimeoutRef.current
        ) {
          //TODO: add timeout
          pauseTimeoutRef.current = setTimeout(() => {
            instructorVideoElement.pause();
          }, 2000);
        }
        if (isLandmarksWithinImage && pauseTimeoutRef.current) {
          clearTimeout(pauseTimeoutRef.current);
          pauseTimeoutRef.current = undefined;
        }
      }
    };

    onChange(isClassStarted, live);
  }, [isClassStarted, live]);

  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);

  // const onToggleInstructorVideo = async (): Promise<void> => {
  //   const video = instructorVideoRef.current;
  //   if (video) {
  //     if (isVideoEnabled) {
  //       video.pause();
  //     } else {
  //       await video.play();
  //     }
  //   }
  //   setIsVideoEnabled(!isVideoEnabled);
  // };

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2>Error</h2>
          <p>{streamErrorMessage}</p>
        </div>
      </Modal>
      {!isProcessingStarted && <CircularProgress />}
      <Grid
        container
        spacing={1}
        style={{ visibility: !isProcessingStarted ? "hidden" : "visible" }}
      >
        <Grid className={classes.container} item xs={12}>
          {studentVideoElementRef.current &&
            !studentVideoElementRef.current.paused &&
            canvasRef.current &&
            instructorVideoRef.current &&
            instructorVideoRef.current.currentTime &&
            canvasRef.current &&
            course &&
            isVideoLoaded && (
              <LiveModelCombined
                live={live}
                instructorVideo={instructorVideoRef.current}
                studentCanvas={canvasRef.current}
                avatarType={avatarType}
                selfieMode={selfieMode}
                currentTime={instructorVideoRef.current.currentTime}
                onPoseAccuracyCalculated={onPoseAccuracyCalculated}
                timespans={course.timespans}
                onExercisePeakReached={onExercisePeakReached}
                onSaveReward={onSaveReward}
                onRepetition={onRepetition}
                onPlayBeep={onPlayBeep}
              />
            )}
          {(!canvasRef.current ||
            !instructorVideoRef.current ||
            !isVideoLoaded) && <SnapshotSkeletonsPlaceholder />}
          <Grid className={classes.controls} item>
            {instructorVideoRef.current && (
              <InstructorSoundControl
                onToggleSound={onToggleInstructorVideoSound}
                isAudioEnabled={!instructorVideoRef.current.muted}
              />
            )}
            <SoundControl
              isAudioEnabled={isBellEnabled}
              onToggleSound={onToggleBell}
            />
            {/* <VideoControl
              isVideoPlaying={isVideoEnabled}
              onToggleVideo={onToggleInstructorVideo}
            /> */}
          </Grid>
          {course && (
            <InstructorVideo
              filePath={course.file_path}
              videoRef={instructorVideoRef}
              onDataLoaded={onDataLoaded}
            />
          )}
          <Grid
            className={classes.studentCanvas}
            style={{
              opacity:
                instructorVideoRef.current &&
                ((instructorVideoRef.current.currentTime === 0 &&
                  !instructorVideoRef.current.ended) ||
                  (instructorVideoRef.current.paused &&
                    !instructorVideoRef.current.ended))
                  ? 1
                  : 0,
            }}
          >
            <StudentCanvas
              canvasRef={canvasRef}
              isLandmarksWithinImage={!!(live && live.isLandmarksWithinImage)}
              onCountdownEnded={onCountdownEnded}
              isClassStarted={isClassStarted}
              isProcessingStarted={isProcessingStarted}
            />

            {!isClassStarted && isProcessingStarted && (
              <Button
                className={classes.startClassButton}
                onClick={onStartClass}
                disabled={!canStartClass}
                variant="contained"
                size="large"
                color="default"
                startIcon={<PlayCircleOutlineIcon />}
              >
                Start class
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
