import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type ActivityOption = {
  id: string;
  name: string;
  thumbnail_path: string;
};

export const getAll = async (): Promise<Array<ActivityOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/activity/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ActivityOption> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/activity/get",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      id: id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("name", item.name);
  formData.append("id", item.id);
  if (item.thumbnail) {
    formData.append("thumbnail", item.thumbnail);
  }

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/activity/edit",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (id: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/activity/remove",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
