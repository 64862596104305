import React, { RefObject, memo } from "react";

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  height: number;
  width: number;
};

const StudentCanvasLive = (props: Props) => {
  const { canvasRef, height, width } = props;

  return (
    <canvas
      ref={canvasRef}
      // style={{ transform: "rotateY(180deg)" }}
      height={height}
      width={width}
    ></canvas>
  );
};

export default memo(StudentCanvasLive);
