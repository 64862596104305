import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../services/pose3d.service";
import { ClassView } from "../../../types/threejs/class-view.type";
import * as THREE from "three";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../services/avatar/avatar.service";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../services/keypoint.helper";
import * as rewardService from "../../../services/reward/reward.service";
import { exerciseSuccessThresholdPercentage } from "../../../const/const";
import { RewardType } from "../../../enums/reward-type.enum";
import { rotateAroundAxis } from "../../../services/mediapipe/mediapipe.keypoint";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import { LiveClassPracticeModel } from "../../../types/analyze/live-class-practice.type";
import {
  defaultRegions,
  getJointsFromRegions,
} from "../../../services/avatar/regions.service";
import { BlockVideoType } from "../../../types/analyze/block-video-type.enum";

const useStyles = makeStyles((theme: Theme) => ({
  canvas: {
    width: 1,
    height: 1,
    zIndex: -100,
    position: "absolute",
  },
  canvasTest: {
    width: "100%",
    height: "100%",
  },
}));

type Props = {
  currentBlockVideoType: BlockVideoType;
  currentBlock?: ClassPracticeAnalysisBlock;
  selfieMode: boolean;
  //NOTE: used only as assiciation with video time
  currentTime: number;
  //NOTE: current time of explanation video or practice part(video can be replayed to fit duration)
  videoPartCurrentTime: number;
  onPoseAccuracy: (accuracy: number, live: LiveClassPracticeModel, jointsAccuracy: any) => void;
  live?: LiveClassPracticeModel;
  blocks: ClassPracticeAnalysisBlock[];
  onExerciseAccuracy: (
    accuracyPercentages: { [key: number]: number },
    live: LiveClassPracticeModel,
    repetition: number,
    nextExerciseStepIndex: number,
    prevExerciseMergedAccuracy: { [key: number]: any },
  ) => void;
  onSaveReward: (
    reward: RewardType,
    rewardProgressPercentage: number,
    blockId: string,
    blockOrder: number
  ) => void;
  onRepetition: (repetition: number, accuracy: number) => void;
  onPlayBeep: () => void;
  isTest?: boolean;
};

const studentRootName = "root-student";
const instructorRootName = "root-instructor";

const LiveModelCombinedPractice2D: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    selfieMode,
    currentTime,
    videoPartCurrentTime,
    onPoseAccuracy,
    live,
    blocks,
    currentBlock,
    onExerciseAccuracy,
    onSaveReward,
    onRepetition,
    onPlayBeep,
    currentBlockVideoType,
    isTest,
  } = props;

  const blockId = currentBlock ? currentBlock.id : undefined;
  const mountRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();
  const sceneRef = useRef<THREE.Scene>();
  const [blockAccuracy, setBlockAccuracy] = useState<
    { percentage: number; currentTime: number; videoPartCurrentTime: number }[]
  >([]);

  const [blockProgressPercentage, setBlockProgressPercentage] =
    useState<number>(0);

  const [nextExerciseStepIndex, setNextExerciseStepIndex] = useState<number>(0);

  const [repsCounter, setRepsCounter] = useState<number>(0);

  const [prevExerciseAccuracy, setPrevExerciseAccuracy] = useState<{
    [key: number]: number;
  }>({});

  const [renderTrigger, setRenderTrigger] = useState<number>();

  const exerciseAccuracyPeaksRef = useRef<any>({});

  useEffect(() => {
    const initScene = async () => {
      sceneRef.current = pose3dService.sceneSetup();
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }
      const renderer = pose3dService.getCourseRenderer(mount);
      setRenderer(renderer);

      const view = pose3dService.getCourseView(mount);
      setView(view);

      const addStudentModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = studentRootName;
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(AvatarType.Male);
        model.name = instructorRootName;
        scene.add(model);
        avatarService.init(model);
      };

      await addStudentModel();
      await addInstructorModel();
    };

    initScene();

    const videoUpdateInterval = setInterval(() => {
      setRenderTrigger(new Date().getTime());
    }, 120);

    return () => {
      const cleanupAvatar = (scene: THREE.Scene, name: string) => {
        const avatar = scene.getObjectByName(name);
        if (avatar) {
          avatar.traverse((child: any) => {
            if (child.isMesh) {
              pose3dService.cleanupObject(child);
            }
          });
          scene.remove(avatar);
        }
      };
      const scene = sceneRef.current;
      if (scene) {
        cleanupAvatar(scene, studentRootName);
        cleanupAvatar(scene, instructorRootName);
      }
      //TODO: DISPOSE EVERYTHING!!
      //https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      //something to think about https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
      clearInterval(videoUpdateInterval);
    };
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    if (mount && scene && renderer && view) {
      pose3dService.renderCourseThree2(
        mount,
        scene,
        renderer,
        view,
        currentBlockVideoType
      );
    }
  }, [renderTrigger]);

  useEffect(() => {
    const onChange = (
      prevBlockId: string | undefined,
      progressPercentage: number
    ) => {
      //TODO: reword, position connected to elements set in external component
      const prevIndex = blocks.findIndex((t) => t.id === prevBlockId);
      if (prevBlockId && prevIndex >= 0) {
        const reward = rewardService.getReward(progressPercentage);
        const prevBlock = blocks[prevIndex];
        if (prevBlock) {
          const progress = progressPercentage > 100 ? 100 : progressPercentage;
          onSaveReward(reward, progress, prevBlock.id, prevBlock.order);
        }
      }
      setBlockAccuracy([]);
      setBlockProgressPercentage(0);
    };

    const setExerciseVariables = (block?: ClassPracticeAnalysisBlock) => {
      if (block && block.exercise) {
        setNextExerciseStepIndex(0);
        setPrevExerciseAccuracy({});
        setRepsCounter(0);
      }
    };

    if (currentBlockVideoType === BlockVideoType.Summary) {
      onChange(blockId, blockProgressPercentage);
      setExerciseVariables(currentBlock);
    }
  }, [currentBlockVideoType]);

  useEffect(() => {
    const getCoordinates = (
      scene: THREE.Scene,
      keypoints: Keypoint3D[],
      name: string
    ) => {
      if (keypoints.length === 0) {
        return;
      }
      const root = scene.getObjectByName(name);
      if (!root) {
        return;
      }

      avatarService.poseUpdate(
        root,
        keypoints.map((kp) => {
          return {
            x: kp.x,
            y: kp.y,
            z: kp.z,
            part: kp.part,
            visibility: kp.visibility,
          };
        })
      );
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
      const coordinates = avatarFeedback.getCoordinates(root);
      return coordinates;
    };

    const processPoseBlock = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      displayInstructorKeypoints: Keypoint3D[],
      live: LiveClassPracticeModel,
      regions: { [key: string]: boolean },
      currentBlock: ClassPracticeAnalysisBlock
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorThresholdKeypoints = getCoordinates(
        scene,
        displayInstructorKeypoints,
        instructorRootName
      );

      if (instructorThresholdKeypoints && studentThresholdKeypoints) {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          getJointsFromRegions(regions)
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        onPoseAccuracy(accuracyPercentage, live, mergedAccuracy);

        if (blockId && currentTime && videoPartCurrentTime) {
          const newTimespanAccuracy = blockAccuracy.map((i) => i);
          newTimespanAccuracy.push({
            percentage: accuracyPercentage,
            currentTime: currentTime,
            videoPartCurrentTime: videoPartCurrentTime,
          });
          setBlockAccuracy(newTimespanAccuracy);

          const calculateProgressValue = (
            blockAccuracy: any[],
            currentBlock: ClassPracticeAnalysisBlock
          ) => {
            const thresholdPercentage = 85;
            const fullProgressBarSeconds = currentBlock.pose
              ? currentBlock.pose.duration
              : 15;
            const percentageValue = fullProgressBarSeconds / 100;
            const durationSeconds = blockAccuracy.reduce((acc, curr, index) => {
              if (index > 0 && curr.percentage >= thresholdPercentage) {
                const prevRecord = blockAccuracy[index - 1];
                const recordDurationSeconds =
                  curr.videoPartCurrentTime - prevRecord.videoPartCurrentTime;
                acc = acc + recordDurationSeconds;
              }
              return acc;
            }, 0);
            return durationSeconds / percentageValue;
          };

          const progressValue = calculateProgressValue(
            newTimespanAccuracy,
            currentBlock
          );

          setBlockProgressPercentage(progressValue);
        }
      }

      if (
        !instructorThresholdKeypoints ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processExerciseBlock = (
      scene: THREE.Scene,
      displayStudentKeypoints: Keypoint3D[],
      stepsKeypoints: Keypoint3D[][],
      nextExerciseStepIndex: number,
      prevExerciseAccuracy: { [key: number]: number },
      live: LiveClassPracticeModel,
      regions: { [key: string]: boolean }[]
    ) => {
      const studentThresholdKeypoints = getCoordinates(
        scene,
        displayStudentKeypoints,
        studentRootName
      );

      const instructorStepThresholdKeypoints = stepsKeypoints.map((kp) => {
        return getCoordinates(scene, kp, instructorRootName);
      });

      const calculateAccuracy = (
        studentThresholdKeypoints: Keypoint3D[],
        instructorThresholdKeypoints: Keypoint3D[],
        accuracyJoints: string[]
      ) => {
        const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );
        const lowerAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedLowerInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
          studentThresholdKeypoints,
          instructorThresholdKeypoints
        );

        const upperAccuracyPercentage =
          avatarFeedback.calculateAccuracyPercentage(
            avatarFeedback.mapVisibility(
              studentThresholdKeypoints,
              displayStudentKeypoints
            ),
            alignedUpperInstructorKeypoints,
            avatarFeedback.coordinateJoints
          );

        const mergedAccuracy = avatarFeedback.getMergedAccuracy(
          upperAccuracyPercentage,
          lowerAccuracyPercentage,
          accuracyJoints
        );
        const accuracyPercentage =
          avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

        return {
          accuracyPercentage,
          mergedAccuracy
        };
      };

      if (
        studentThresholdKeypoints &&
        !instructorStepThresholdKeypoints.some((kp) => kp === undefined)
      ) {
        //TODO: store data values
        const accuracyData = instructorStepThresholdKeypoints.map(
          (kp, index) => {
            if (kp) {
              return calculateAccuracy(
                studentThresholdKeypoints,
                kp,
                getJointsFromRegions(regions[index])
              );
            }
          }
        );

        const accuracyObject = accuracyData.map((d) => {
          if (d === undefined) {
            return undefined;
          }
          return d.accuracyPercentage;
        }).reduce(
          (acc: any, curr: number | undefined, index: number) => {
            acc[index] = curr;
            return acc;
          },
          {}
        );

        const accuracyMergedObject = accuracyData.reduce(
          (acc: any, curr: any, index: number) => {
            acc[index] = curr.mergedAccuracy;
            return acc;
          },
          {}
        );

        const nextPoseAccuracyData = accuracyData[nextExerciseStepIndex];

        let currentRep = repsCounter;
        if (currentBlock && nextPoseAccuracyData) {
          const {
            accuracyPercentage,
          } = nextPoseAccuracyData;

          const isThresholdReached =
            prevExerciseAccuracy[nextExerciseStepIndex] >=
            exerciseSuccessThresholdPercentage;
          const isAccuracyDecreasing =
            prevExerciseAccuracy[nextExerciseStepIndex] > accuracyPercentage;
          const isAccuracyReached =
            accuracyPercentage === 100 ||
            (isThresholdReached && isAccuracyDecreasing);

          const isLastStepReached =
            nextExerciseStepIndex ===
              instructorStepThresholdKeypoints.length - 1 && isAccuracyReached;

          if (isAccuracyReached) {
            //TODO: record peak accuracy
            if (!exerciseAccuracyPeaksRef.current[currentBlock.id]) {
              exerciseAccuracyPeaksRef.current[currentBlock.id] = [];
            }
            const stepAccuracy = accuracyPercentage > prevExerciseAccuracy[nextExerciseStepIndex] ? accuracyPercentage : prevExerciseAccuracy[nextExerciseStepIndex];
            if(stepAccuracy){
              exerciseAccuracyPeaksRef.current[currentBlock.id].push(
                stepAccuracy
              );
            }
          }

          if (isLastStepReached) {
            const blockAccuracyRecords =
              exerciseAccuracyPeaksRef.current[currentBlock.id]; 
            const averageAccuracy =
              blockAccuracyRecords.reduce((acc: number, curr: number) => {
                if (!Number.isNaN(curr)) {
                  acc = acc + curr;
                }
                return acc;
              }, 0) / blockAccuracyRecords.length;
            currentRep = repsCounter + 1;
            setRepsCounter(currentRep);
            onRepetition(currentRep, averageAccuracy);
            if (currentBlock.exercise) {
              const percentage =
                (currentRep / currentBlock.exercise.repetitions) * 100;
              const percentageValue = percentage > 100 ? 100 : percentage;
              setBlockProgressPercentage(percentageValue);
            }
            setNextExerciseStepIndex(0);
            onPlayBeep();
          } else if (isAccuracyReached) {
            setNextExerciseStepIndex(nextExerciseStepIndex + 1);
            onPlayBeep();
          } 
          setPrevExerciseAccuracy(accuracyObject);
          onExerciseAccuracy(
            accuracyObject,
            live,
            currentRep,
            nextExerciseStepIndex,
            accuracyMergedObject
          );
        }
      }

      if (
        instructorStepThresholdKeypoints.some((kp) => kp === undefined) ||
        !studentThresholdKeypoints ||
        live.isLandmarksWithinImage === false
      ) {
        avatarFeedback.removeFeedback(scene);
      }
    };

    const processKeypoints = (
      block?: ClassPracticeAnalysisBlock,
      live?: LiveClassPracticeModel
    ) => {
      const studentKeypoints = live ? live.studentKeypoints : [];
      let displayStudentKeypoints = !selfieMode
        ? mapKeypoints(studentKeypoints)
        : getFlippedDisplayKeypoints(mapKeypoints(studentKeypoints));
      displayStudentKeypoints = rotateAroundAxis(
        displayStudentKeypoints,
        new THREE.Vector3(0, 1, 0),
        -45
      ) as any[];

      const mount = mountRef.current;
      const scene = sceneRef.current;

      if (mount && scene && renderer && view) {
        if (
          live &&
          live.isInstructorVideoPaused !== undefined &&
          live.isInstructorVideoPaused !== true &&
          currentBlockVideoType === BlockVideoType.Practice &&
          block
        ) {
          if (block.pose) {
            const displayInstructorKeypoints = mapKeypoints(
              block.pose.keypoints
            );

            processPoseBlock(
              scene,
              displayStudentKeypoints,
              displayInstructorKeypoints,
              live,
              //HACK: bacuse of empty db records
              block.pose.regions || defaultRegions,
              block
            );
          } else if (block.exercise) {
            const sortedSteps = block.exercise.steps.sort((x) => x.order);
            const stepsDisplayKeypoints = sortedSteps.map((x) =>
              mapKeypoints(x.keypoints)
            );

            const stepsRegions = sortedSteps.map((x) => {
              if (x.regions) {
                return x.regions;
              }
              //HACK: bacuse of empty db records
              return defaultRegions;
            });

            processExerciseBlock(
              scene,
              displayStudentKeypoints,
              stepsDisplayKeypoints,
              nextExerciseStepIndex,
              prevExerciseAccuracy,
              live,
              stepsRegions
            );
          }
        } else {
          avatarFeedback.removeFeedback(scene);
        }
      }
    };

    processKeypoints(currentBlock, live);
  }, [live]);

  return (
    <div
      className={isTest ? classes.canvasTest : classes.canvas}
      ref={mountRef}
    ></div>
  );
};

export default LiveModelCombinedPractice2D;
