import React, { Component }  from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    TextField, 
    Paper, 
    Grid, 
    Typography, 
    Button, 
    CircularProgress} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import * as activityProvider from '../../../providers/admin/activity.provider';
import { fileToJpg } from '../../../services/canvas.service';

const styles = (theme: Theme) => createStyles({
    formControl: {
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
          width: 800,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    thumbnail: {
        width: '100%'
    },
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
});

type State = {
    disabled: boolean,
    name?: string,
    isContainerInitialized: boolean,
    thumbnail_path?: string,
    thumbnail?: string,
    isThumbnailUpdated: boolean
};

type Props = RouteComponentProps<any> & {
    classes: any,
    title: string
};

class ChannelCreate extends Component<Props,State> {
    constructor(props:Props){
        super(props);       
        this.state = {
            disabled: false,
            isContainerInitialized: false,
            isThumbnailUpdated: false
        }; 

        this.handleImageChange = this.handleImageChange.bind(this)
    }

    componentDidMount = async () => {
        try{      
            const activityId = this.props.match.params.activityId;
            const activity = await activityProvider.get(activityId);
            if(activity){
                this.setState({
                    name: activity.name,
                    thumbnail_path: activity.thumbnail_path,
                    isContainerInitialized: true
                });
            }
        }
        catch(err){
            //TODO: handle initialization error
            console.log('componentDidMount:err');
            console.log(err);
        }
    }

    onSave = async () => { 
        this.setState({
            disabled: true
        });      
        const {
            name,
            thumbnail,
            isThumbnailUpdated
        } = this.state;

        try{
            const model: any = {
                id: this.props.match.params.activityId,
                channelId: this.props.match.params.channelId,
                name,       
            }
            if(isThumbnailUpdated){
                model.thumbnail = thumbnail && thumbnail.split(',').pop();
            }

            await activityProvider.edit(model);
            this.goToActivities();
        }
        catch(err){
            //TODO: handle error
            this.setState({
                disabled: false
            }); 
        }  
    }

    goToActivities = () => {
        this.props.history.push("/admin/activities")
    };   

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: (event.target.value as string)
        });
    };

    handleImageChange = async (acceptedFiles: Array<File>) => {
        if(acceptedFiles.length === 1) {            
            const file = acceptedFiles[0];
            //TODO: draw image on canvas
            //TODO: save canvas as blob / base64
            const base64 = await fileToJpg(file);
            this.setState({
                thumbnail: base64,
                isThumbnailUpdated: true
            });
        }
    };

    onResetThumbnail = () => {
        this.setState({
            thumbnail: undefined,
            isThumbnailUpdated: false
        });
    }

    render = () => {
        const { 
            isContainerInitialized,
            name,
            disabled,
            thumbnail,
            thumbnail_path,
            isThumbnailUpdated
        } = this.state;

        const {
            classes,
            title
        } = this.props;
        
        //TODO: move url generation to file update?
        const currentThumbnail = isThumbnailUpdated? thumbnail : thumbnail_path;

        const accept = 'image/jpeg, image/png, image/webp';

        return (
            !isContainerInitialized ? 
            (<CircularProgress />) :
            (<main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" gutterBottom>
                        {title}
                    </Typography>
                    <Grid container spacing={3}>                       
                        <Grid item xs={12}>
                            <TextField  
                                className={classes.formControl}
                                label="Name"
                                value={name}
                                disabled={true}
                                onChange={this.handleNameChange}
                                variant="outlined"
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            <Dropzone onDrop={this.handleImageChange}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps({ className: disabled? `${classes.dropzone} disabled` : classes.dropzone })}>
                                        <input {...getInputProps({accept: accept , disabled: disabled})} />
                                        <p>Drag 'n' drop thumbnail image here, or click to select one.</p>
                                    </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>                        
                        {   
                            thumbnail
                            && (<Grid item xs={12}> 
                                    <Button  onClick={this.onResetThumbnail} variant="contained">Reset thumbnail</Button>                                    
                                </Grid>)
                        }
                        <Grid item xs={12}>
                            <img  className={classes.thumbnail} src={currentThumbnail} />
                        </Grid>
                    </Grid> 
                    <div className={classes.buttonsContainer}>
                        <Button  disabled={disabled} onClick={this.props.history.goBack} variant="contained">
                            Go Back
                        </Button>
                        <Button  disabled={disabled} onClick={this.onSave} variant="contained" color="primary">
                            Save
                        </Button>    
                    </div>               
                </Paper>
            </main>)
        );
    }    
}

const styledComponent = withStyles(styles)(ChannelCreate);
export default withRouter(styledComponent as any)