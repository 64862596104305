import { RouteAccessRequirement } from "./route-access-requirement.enum";
import { AppRoute } from "./app-route.type";
import SignIn from "../views/common/SignIn";
import SignUp from "../views/common/SignUp";
import NotFound from "../views/common/NotFound";
import { AppRouteLayout } from "./app-route-layout.enum";

import NotImplemented from "../views/common/NotImplemented";
import HelpAndFAQ from "../views/common/HelpAndFAQ";
import AskForFeature from "../views/common/AskForFeature";

import HelpIcon from "@material-ui/icons/Help";
import FeedbackIcon from "@material-ui/icons/Feedback";
import DefaultRoute from "../components/DefaultRoute";
import EmailConfirmation from "../views/common/EmailConfirmation";
import ForgotPassword from "../views/common/ForgotPassword";
import ResetPassword from "../views/common/ResetPassword";
import ResistrationSuccess from "../views/common/RegistrationSuccess/ResistrationSuccess";
import ForgotPasswordSuccess from "../views/common/ForgotPasswordSuccess";
import ResetPasswordSuccess from "../views/common/ResetPasswordSuccess";
import Account from "../views/common/Account";

export const sidebarRoutes: Array<AppRoute> = [
 
  {
    path: "/help",
    component: HelpAndFAQ,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Main,
    title: "Help & F.A.Q.",
    icon: HelpIcon,
  },
  {
    path: "/feedback",
    component: AskForFeature,
    accessRequirement: RouteAccessRequirement.Login,
    layout: AppRouteLayout.Main,
    title: "Feedback",
    icon: FeedbackIcon,
  },
];


export const common: Array<AppRoute> = [
  {
    path: "/not-found",
    component: NotFound,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
    title: "Not found",
  },
  {
    path: "/not-implemented",
    component: NotImplemented,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
    title: "Not found",
  },
  {
    path: "/login",
    component: SignIn,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
    title: "Login",
  },
  {
    path: "/register",
    component: SignUp,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.None,
    title: "Register",
  },
  {
    path: "/email-confirmation",
    component: EmailConfirmation,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
    title: "Email confirmation",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot password",
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    title: "Reset password",
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
  },
  {
    path: "/registration-success",
    component: ResistrationSuccess,
    title: "Registration success",
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
  },
  {
    path: "/forgot-password-success",
    component: ForgotPasswordSuccess,
    title: "Forgot password success",
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
  },
  {
    path: "/reset-password-success",
    component: ResetPasswordSuccess,
    title: "Reset password success",
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.Minimal,
  },
  {
    path: "/account",
    component: Account,
    title: "Account",
    accessRequirement: RouteAccessRequirement.Login,
    layout: AppRouteLayout.Main,
  },
];

const defaultRoute = [
  {
    path: "/default",
    component: DefaultRoute,
    accessRequirement: RouteAccessRequirement.None,
    layout: AppRouteLayout.None,
    title: "Default",
  },
];

export const routes: Array<AppRoute> = common
  .concat(sidebarRoutes)
  .concat(defaultRoute);
