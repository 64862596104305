import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { RewardType } from "../../../enums/reward-type.enum";
import { secondsToShortTimeRoundedString } from "../../../utils/conversion.functions";
import { getRewardImage } from "../../../services/reward/reward.service";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  canvas: {
    width: "100%",
    height: "100%",
  },
  title: {
    margin: 15,
  },
  iconButton: {
    fontSize: "3rem !important",
  },
  rotateLeft: {
    left: 5,
    bottom: "30%",
    position: "absolute",
  },
  rotateRight: {
    right: 5,
    bottom: "30%",
    position: "absolute",
  },
  reward: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  rewardsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  blockRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  paper: {
    width: 600,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  avatar: { margin: "0px 10px 0px 0px" },
  buttonContainer: { display: "flex", justifyContent: "space-between" },
}));

type Props = {
  rewards: {
    reward: RewardType;
    name: string;
    timestamp: number;
  }[];
  onGoActivityClickHandler: () => void;
  onReturnClickHandler: () => void;
};

export default function SequenceClassSummary(props: Props) {
  const { rewards, onGoActivityClickHandler, onReturnClickHandler } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid xs={12} container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h4" className={classes.title}>
            Class Summary:
          </Typography>
        </Grid>
        <Grid container className={classes.blockRow} item xs={12}>
          <Grid item xs={1}>
            #
          </Grid>
          <Grid item xs={2}>
            Timestamp
          </Grid>
          <Grid item xs={5}>
            Block Name
          </Grid>
          <Grid item container xs={4} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              Reward Achieved
            </Grid>
            <Grid item xs={4}>
              Gold
            </Grid>
            <Grid item xs={4}>
              Silver
            </Grid>
            <Grid item xs={4}>
              Bronze
            </Grid>
          </Grid>
        </Grid>
        {rewards.map(({ name, reward, timestamp }, index) => (
          <Grid container className={classes.blockRow} item xs={12}>
            <Grid item xs={1}>
              {index + 1}.
            </Grid>
            <Grid item xs={2}>
              {secondsToShortTimeRoundedString(timestamp)}
            </Grid>
            <Grid item xs={5}>
              {name}
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={4}>
                {reward === RewardType.Gold && (
                  <img
                    title={"Gold"}
                    alt={"Gold"}
                    className={classes.reward}
                    src={getRewardImage(RewardType.Gold)}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {reward === RewardType.Silver && (
                  <img
                    title={"Silver"}
                    alt={"Silver"}
                    className={classes.reward}
                    src={getRewardImage(RewardType.Silver)}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {reward === RewardType.Bronze && (
                  <img
                    title={"Bronze"}
                    alt={"Bronze"}
                    className={classes.reward}
                    src={getRewardImage(RewardType.Bronze)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            onClick={onReturnClickHandler}
          >
            Return to Classes
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onGoActivityClickHandler}
          >
            Go to Activity Details
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
