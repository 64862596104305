import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import * as analysisProvider from "../../../providers/student/analysis.provider";
import {
  StudentCanvasTest,
  SnapshotSkeletonsPlaceholder,
  VideoControl,
  SoundControl,
  InstructorVideo,
  LiveModelCombined,
  InstructorSoundControl,
} from "../../../components/Analysis";
import { CircularProgress, Grid } from "@material-ui/core";

import {
  mediapipeKeypoint,
  mediapipeService,
} from "../../../services/mediapipe";

import * as channelClassProvider from "../../../providers/student/channel-class.provider";

import { LiveClassModel } from "../../../types/analyze/live.class.type";
import videoSrc from "../../../assets/videos/testleaveframe.mp4"; //teststeps.mp4 //warrior245.mp4"; //alignment_error//rotation4 //WIN_20210330_10_41_41_Pro

import {
  applyKalmanFilterUnity,
  CustomKalmanFilter,
  Measurement3D,
} from "../../../services/custom-kalman-filter.service";

import { AvatarType } from "../../../services/avatar/avatar-type.enum";

import {
  checkIfLandmarkssWithinImage,
  defaultTPoseSkeleton,
  drawOriginal,
  mapForAnalysis,
} from "../../../services/mediapipe/mediapipe.keypoint";
import { CourseAnalysisDetails } from "../../../types/class/class-analysis-details";
import { RewardType } from "../../../enums/reward-type.enum";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";
import * as audioService from "../../../services/audio.service";
import { Landmark } from "@mediapipe/pose";
import * as speechHelper from "../../../services/speech.helper";

const selfieMode = false;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  container: {
    position: "relative",
    // TODO: switch to custom breakpoints that match phone/laptop/pc
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 960px
    // lg, large: 1280px
    // xl, extra-large: 1920px
    [theme.breakpoints.up("lg")]: {
      height: 850,
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 425,
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: 350,
    },
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
  },
  controls: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    bottom: 5,
    right: 5,
  },
}));

type Params = {
  classId?: string;
  modelTypeString: string;
};

export default function AnalyzeCourse() {
  const classes = useStyles();
  const [live, setLive] = useState<LiveClassModel>();

  const { classId, modelTypeString } = useParams<Params>();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [course, setCourse] = useState<CourseAnalysisDetails>();
  const [avatarType, setAvatarType] = useState<AvatarType>(AvatarType.None);

  const [isBellEnabled, setIsBellEnabled] = useState<boolean>(true);

  const onPlayBeep = () => {
    if (isBellEnabled) {
      audioService.beep();
    }
  };

  const onToggleBell = () => {
    setIsBellEnabled(!isBellEnabled);
  };

  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const onDataLoaded = () => {
    setIsVideoLoaded(true);
  };

  const instructorVideoRef = useRef<HTMLVideoElement>(null);
  const onToggleInstructorVideoSound = () => {
    const instructorVideoElement = instructorVideoRef.current;
    if (instructorVideoElement) {
      instructorVideoElement.muted = !instructorVideoElement.muted;
    }
  };
  const [isProcessingStarted, setIsProcessingStarted] =
    useState<boolean>(false);

  const studentVideoElementRef = useRef<HTMLVideoElement>();
  const mediapipeModelRef = useRef<any>(null);

  const [results, setResults] = useState<any>();

  const onLandmarks = (results: any) => {
    setResults(results);
  };

  const [measurements, setMeasurements] = useState<Measurement3D[]>([]);
  const customKalmanFilterSlowRef = useRef<CustomKalmanFilter>();
  const customKalmanFilterFastRef = useRef<CustomKalmanFilter>();

  const resetKalmanCorrections = (): void => {
    const customKalmanFilter = customKalmanFilterSlowRef.current;
    if (customKalmanFilter) {
      const initalMeasurements = customKalmanFilter.getInitialMeasurements();
      setMeasurements(initalMeasurements);
    }
  };

  const [lastCurrentTime, setLastCurrentTime] = useState<number>();
  const [lastTimeSpanId, setLastTimeSpanId] = useState<string>();
  const [lastTimestamp, setLastTimestamp] = useState<number>();

  const [isLandmarksWithinImage, setisLandmarksWithinImage] = useState<boolean>();

  const onRepetition = (text: string) => {
    if (isBellEnabled) {
      speechHelper.speak(text);
    }
  };

  const onSaveReward = (reward: RewardType, timespanId: string) => {};

  useEffect(() => {
    const parseAvatarType = (typeString: string) => {
      if (typeString === "1") {
        setAvatarType(AvatarType.Male);
      }
      if (typeString === "2") {
        setAvatarType(AvatarType.Female);
      }
    };

    const startVideo = () => {
      const videoElement = document.createElement("video");
      videoElement.height = 480;
      videoElement.width = 640;
      videoElement.muted = true;
      videoElement.loop = true;
      videoElement.crossOrigin = "anonymous";
      if (videoElement) {
        videoElement.oncanplay = async () => {
          videoElement.play();
          const model = mediapipeModelRef.current;
          await model.send({ image: videoElement });
        };
        videoElement.src = videoSrc;
      }
      studentVideoElementRef.current = videoElement;
    };

    const initKalmanFilter = () => {
      customKalmanFilterSlowRef.current = new CustomKalmanFilter();
      const q = 0.001;
      const r = 0.00075;
      customKalmanFilterFastRef.current = new CustomKalmanFilter(q, r);
    };

    const getClassData = async (classId: string) => {
      const course = await channelClassProvider.getForAnalysis(classId);
      setCourse(course);
    };

    const startMediapipe = async () => {
      const pose = await mediapipeService.getModel();

      pose.onResults(onLandmarks);
      mediapipeModelRef.current = pose;
    };

    const initializeModel = async (
      classId: string,
      modelTypeString: string
    ) => {
      speechHelper.initSpeech();
      parseAvatarType(modelTypeString);
      await getClassData(classId);
      initKalmanFilter();
      resetKalmanCorrections();
      await startMediapipe();
      startVideo();
      if (selfieMode) {
        //HACK: that flips student video horizontally
        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");
          if (context) {
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
          }
        }
      }
    };

    if (classId) {
      initializeModel(classId, modelTypeString);
    } else {
      alert("Channel id is missing");
    }

    return () => {
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        instructorVideoElement.removeEventListener("error", onVideoError);
        instructorVideoElement.removeEventListener("ended", onVideoEnded);
      }
    };
  }, []);
  useEffect(() => {
    const processLandmarks = async (
      results: any,
      timespans: CourseAnalysisTimespan[]
    ) => {
      const { poseLandmarks, image } = results;
      const landmarks = poseLandmarks as Landmark[];
      if (landmarks) {
        const filterKalmanUnity = (
          measurements: Measurement3D[],
          joints: Landmark[],
          customKalmanFilter: CustomKalmanFilter
        ) => {
          const filteredUnity = applyKalmanFilterUnity(
            measurements,
            joints,
            customKalmanFilter
          );
          if (filteredUnity) {
            setMeasurements(filteredUnity);
            const mappedUnity = filteredUnity.map((f, index) => {
              return {
                x: f.pos3d.x,
                y: f.pos3d.y,
                z: f.pos3d.z,
                visibility: joints[index].visibility,
              };
            });
            return mappedUnity;
          }
        };

        const denormalize = (landmarks: any[]) => {
          const canvasElement = canvasRef.current;
          if (canvasElement) {
            const { width, height } = canvasElement;
            return mediapipeKeypoint.denormalize(landmarks, width, height);
          }
          return landmarks;
        };

        const canvasElement = canvasRef.current;
        if (!canvasElement) {
          return;
        }

        const timespan = timespans.find((x) => x.id === lastTimeSpanId);

        const getFilter = (timespan: CourseAnalysisTimespan | undefined) => {
          if (timespan && timespan.exercise) {
            return customKalmanFilterFastRef.current;
          }
          return customKalmanFilterSlowRef.current;
        };

        const filter = getFilter(timespan);
        if (!filter) {
          return;
        }

        const filtered = filterKalmanUnity(measurements, landmarks, filter);
        if (!filtered) {
          return;
        }
        const filteredDenormalized = denormalize(filtered);
        const { width, height } = canvasElement;
        const isLandmarksWithinImage = checkIfLandmarkssWithinImage(
          filteredDenormalized,
          width,
          height
        );
        setisLandmarksWithinImage(isLandmarksWithinImage);
        const denormalized = isLandmarksWithinImage
          ? filteredDenormalized
          : defaultTPoseSkeleton;

        drawOriginal(filtered, image, canvasElement);

        const studentKeypointsScaledByZ = mapForAnalysis(denormalized);

        if (lastCurrentTime && lastTimestamp) {
          const live: LiveClassModel = {
            studentKeypoints: studentKeypointsScaledByZ,
            classTime: lastCurrentTime,
            timestamp: lastTimestamp,
            isLandmarksWithinImage: isLandmarksWithinImage,
            studentActivityTimespanId: undefined,
          };
          setLive(live);
        }
      }

      //NOTE: Hack to reduce amount of frames processed
      setTimeout(async () => {
        await processFrame();
        //TODO: change timeout value dynamically or set to some maximum value per second
      }, 50);

      if (!isProcessingStarted) {
        setIsProcessingStarted(true);
      }
    };

    if (results && course) {
      processLandmarks(results, course.timespans);
    }
  }, [results]);

  const processFrame = async (): Promise<void> => {
    const videoElement = studentVideoElementRef.current;
    if (videoElement) {
      const model = mediapipeModelRef.current;

      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        const currentTime = instructorVideoElement.currentTime;
        setLastCurrentTime(currentTime);
        const timestamp = new Date().getTime();
        setLastTimestamp(timestamp);

        if (course) {
          const timeSpan = course.timespans.find(
            (x) =>
              x.start_seconds <= currentTime && x.end_seconds >= currentTime
          );
          if (timeSpan) {
            setLastTimeSpanId(timeSpan.id);
          } else {
            setLastTimeSpanId(undefined);
          }
        }

        await model.send({ image: videoElement });
      }
    }
  };

  const onExercisePeakReached = (
    accuracyData: { [key: number]: number },
    live: LiveClassModel,
    repetition: number
  ) => {};

  const onPoseAccuracyCalculated = (
    accuracy: number,
    live: LiveClassModel
  ) => {};

  const onVideoError = (event: any): void => {
    //TODO: handle
    alert("Error during playback");
  };

  const onVideoEnded = (event: any): void => {
    //TODO: handle
    //alert("Course eneded.");
    console.log("Class eneded.");
  };

  useEffect(() => {
    const onPlayInstructorVideo = async (): Promise<void> => {
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        instructorVideoElement.addEventListener("error", onVideoError);
        instructorVideoElement.addEventListener("ended", onVideoEnded);
        await instructorVideoElement.play();
      }
    };
    if (isProcessingStarted) {
      onPlayInstructorVideo();
    }
  }, [isProcessingStarted]);

  useEffect(() => {
    const onChange = async (isLandmarksWithinImage: boolean) => {
      const instructorVideoElement = instructorVideoRef.current;
      if (instructorVideoElement) {
        if (isLandmarksWithinImage && instructorVideoElement.paused) {
          await instructorVideoElement.play();
        } else if (!isLandmarksWithinImage && !instructorVideoElement.paused) {
          instructorVideoElement.pause();
        }
      }
    };
    if (isLandmarksWithinImage !== undefined) {
      onChange(isLandmarksWithinImage);
    }
  }, [isLandmarksWithinImage]);

  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);

  const onToggleInstructorVideo = async (): Promise<void> => {
    const video = instructorVideoRef.current;
    if (video) {
      if (isVideoEnabled) {
        video.pause();
      } else {
        await video.play();
      }
    }
    setIsVideoEnabled(!isVideoEnabled);
  };

  return (
    <div className={classes.root}>
      {!isProcessingStarted && <CircularProgress />}
      <Grid
        container
        spacing={1}
        style={{ visibility: !isProcessingStarted ? "hidden" : "visible" }}
      >
        <Grid className={classes.container} item xs={12}>
          {canvasRef.current &&
            instructorVideoRef.current &&
            instructorVideoRef.current.currentTime &&
            canvasRef.current &&
            course &&
            isVideoLoaded && (
              <LiveModelCombined
                live={live}
                instructorVideo={instructorVideoRef.current}
                studentCanvas={canvasRef.current}
                avatarType={avatarType}
                selfieMode={selfieMode}
                currentTime={instructorVideoRef.current.currentTime}
                onPoseAccuracyCalculated={onPoseAccuracyCalculated}
                timespans={course.timespans}
                onExercisePeakReached={onExercisePeakReached}
                onSaveReward={onSaveReward}
                onRepetition={onRepetition}
                onPlayBeep={onPlayBeep}
              />
            )}
          {(!canvasRef.current ||
            !instructorVideoRef.current ||
            !isVideoLoaded) && <SnapshotSkeletonsPlaceholder />}
          <Grid className={classes.controls} item>
            {instructorVideoRef.current && (
              <InstructorSoundControl
                onToggleSound={onToggleInstructorVideoSound}
                isAudioEnabled={!instructorVideoRef.current.muted}
              />
            )}
            <SoundControl
              isAudioEnabled={isBellEnabled}
              onToggleSound={onToggleBell}
            />
            <VideoControl
              isVideoPlaying={isVideoEnabled}
              onToggleVideo={onToggleInstructorVideo}
            />
          </Grid>
          {course && (
            <InstructorVideo
              filePath={course.file_path}
              videoRef={instructorVideoRef}
              onDataLoaded={onDataLoaded}
            />
          )}
        </Grid>
        <Grid item>
          <StudentCanvasTest canvasRef={canvasRef} height={480} width={640} />
        </Grid>
      </Grid>
    </div>
  );
}
