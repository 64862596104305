import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important"
  },
}));

type Props = {
  isAudioEnabled: boolean;
  onToggleSound: () => void;
};

export default function SoundControl(props: Props) {
  let { isAudioEnabled, onToggleSound } = props;
  const classes = useStyles();
  return (
    <IconButton
      aria-label={isAudioEnabled ? "Disable speech" : "Enable speech"}
      onClick={onToggleSound}
      className={classes.button}
    >
      {!isAudioEnabled ? (
        <NotificationsOffIcon fontSize="inherit" />
      ) : (
        <NotificationsActiveIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}
