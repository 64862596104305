import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardActionArea, CardContent, CardMedia, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import defaultThumbnail from '../../../../../assets/images/channel-thumbnail.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  media: {
    height: 200,
  },
  card: {
    position: 'relative',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const ActivityCard = props => {
  const { activity, onRemove } = props;
  const history = useHistory();
  const classes = useStyles();
  const onEdit = (id) => {
    history.push(`/admin/activity/${id}/edit`);
  }
  return (
    <Card
      className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={activity.thumbnail_path || defaultThumbnail}
          title={activity.name}
        />
        <CardContent>        
          <Typography className={classes.name} title={activity.name} gutterBottom variant="h3" component="h2">
            {activity.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>       
        <Button size="small" color="secondary" onClick={() => onEdit(activity.id)}>
            Edit
        </Button>
        <Button size="small" color="secondary" onClick={() => onRemove(activity.id)}>
            Remove
        </Button>
      </CardActions>
    </Card>
  );
};

ActivityCard.propTypes = {
  className: PropTypes.string,
  activity: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default ActivityCard;