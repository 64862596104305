import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { ChannelClassCard, ChannelDetailsModal } from "./components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as channelClassProvider from "../../../../../providers/student/channel-class.provider";
import {
  ChannelCourseListItem,
  ClassModalDetails,
} from "../../../../../providers/student/channel-class.provider";
import { Pagination } from "@material-ui/lab";
import * as activityProvider from "../../../../../providers/common/activity.provider";
import FilterClasses from "./components/FilterClasses";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    // marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const pageSize = 8;

type Props = {
  instructors?: Array<any>;
  activities?: Array<activityProvider.Activity>;
};

export default function ChannelCourseList(props: Props) {
  const { instructors, activities } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [classModalDetails, setClassModalDetails] =
    useState<ClassModalDetails>();
  const [courses, setCourses] = useState<Array<ChannelCourseListItem>>([]);
  const [course, setCourse] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>();
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [filter, setFilter] = useState<{ [key: string]: string | undefined }>(
    {}
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    const getClasses = async (
      page: number,
      filter: { [key: string]: string | undefined }
    ) => {
      setLoading(true);
      try {
        const pager = {
          pageSize: pageSize,
          pageNumber: page,
        };

        const { records, total } = await channelClassProvider.getAll(
          pager,
          filter
        );
        setCourses(records);
        setTotalCount(total);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses(page, filter);
  }, [page, filter]);

  const handleOpen = async (classId: string) => {
    setOpen(true);
    setModalLoading(true);
    setClassModalDetails(undefined);
    const classModalDetails = await channelClassProvider.getModalDetails(
      classId
    );
    const selectedCourse = courses.find((course) => {
      return course.id === classId;
    });
    setCourse(selectedCourse);
    setClassModalDetails(classModalDetails);
    setModalLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onFilterChange = (filter: { [key: string]: string | undefined }) => {
    setFilter(filter);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography component="h1" variant="h4" gutterBottom>
          Classes
        </Typography>
        {instructors && activities ? (
          <FilterClasses
            instructors={instructors}
            activities={activities}
            onFilterChange={onFilterChange}
          />
        ) : (
          <CircularProgress />
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid style={{ marginTop: "30px" }} container spacing={3}>
            {courses &&
              courses.map((course) => (
                <Grid item key={course.id} lg={3} md={4} sm={6} xs={12}>
                  <ChannelClassCard course={course} onDetails={handleOpen} />
                </Grid>
              ))}
          </Grid>
        )}
        {totalCount !== undefined && totalCount !== 0 && (
          <Pagination
            disabled={loading}
            count={Math.ceil(totalCount / pageSize)}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
      <ChannelDetailsModal
        handleClose={handleClose}
        classModalDetails={classModalDetails}
        selectedCourse={course}
        modalLoading={modalLoading}
        open={open}
      />
    </div>
  );
}
