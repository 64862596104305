export const addBase64Prefix = (image: string): string => {
  return `data:image/jpeg;base64, ${image}`;
};

export const getBase64Image = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      resolve(event.target.result);
    };
    reader.onerror = (error: any) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const getImage = (base64: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = (err) => {
      reject(err);
    };

    img.src = base64; // is the data URL because called with readAsDataURL
  });
};