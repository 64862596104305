import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { StudentActivityTimespanListItem } from "../../types/activities/student-activity-timespan-list-item";
import { StudentActivityTimespanDetails } from "../../types/activities/student-activity-timespan-details";

export const getAll = async (activityId: string): Promise<Array<StudentActivityTimespanListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/timespan/getAll",
    baseURL: apiUrlBase,
    data: {
      activity_id: activityId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (timespanId: string): Promise<StudentActivityTimespanDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/timespan/details",
    baseURL: apiUrlBase,
    data: {
      timespan_id: timespanId,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const saveReward = async (model: any) => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/timespan/reward/save",
    baseURL: apiUrlBase,
    data: {
      timespan_id: model.timespanId,
      reward: model.reward,
      score: model.score,
      effectiveness: model.effectiveness,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
}