import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ClassList, ClassPracticeList, ClassSequenceList } from "./components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as channelClassProvider from "../../../providers/student/channel-class.provider";
import * as activityProvider from "../../../providers/common/activity.provider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type Props = {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

export default function ChannelCourseList(props: Props) {
  const classes = useStyles();
  const [instructors, setInstructors] = useState<Array<any>>();
  const [activities, setActivities] =
    useState<Array<activityProvider.Activity>>();
  useEffect(() => {
    const load = async () => {
      const results = await Promise.all([
        activityProvider.getAll(),
        channelClassProvider.getInstructors(),
      ]);
      const activities = results[0];
      setActivities(activities);
      const instructors = results[1];
      setInstructors(instructors);
    };

    load();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ClassList instructors={instructors} activities={activities} />
        </Grid>
        <Grid item xs={12}>
          <ClassSequenceList
            instructors={instructors}
            activities={activities}
          />
        </Grid>
        <Grid item xs={12}>
          <ClassPracticeList
            instructors={instructors}
            activities={activities}
          />
        </Grid>
      </Grid>
    </div>
  );
}
