import React from "react";

type Props = {
  feedback?: string;
};

export default function CornerCountdown(props: Props) {
  const { feedback } = props;

  return (
    <div
      style={{
        position: "fixed",
        maxWidth: "50%",
        display: feedback !== undefined ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        bottom: "25%",
        right: "5%",
        border: "5px solid rgba(139, 0, 0, 0.7)",
        borderRadius: 20,
        backgroundColor: "rgba(27, 27, 27, 0.7)",
        fontSize: 80,
        color: "white",
        height: "15%",
        padding: 20,
      }}
    >
      <span>Adjust {feedback}</span>
    </div>
  );
}
