import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import defaultThumbnail from '../../../../../assets/images/channel-thumbnail.png'
import {Rating} from "@material-ui/lab";
import {Avatar, Box, Button} from "@material-ui/core";
import yogaInstructor from "../../../../../assets/images/yoga_instructor.jpg";

const useStyles = makeStyles(theme => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },  
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
    marginLeft: 70
  },
  subscribeButton: {
    background: "#DA1E37",
    color: "white",
    
    fontSize: "16px",
    width: 200,
    height: 50,
  },
  topBoxContainer: {
    position: 'absolute',
    left: '2.31%',
    right: '10.15%',
    top: '5%',
    bottom: '96.25%',
  },
  boxStyle: {
    width: 'fit-content',
    height: 'fit-content',
    background: '#FFFFFF',
    boxShadow: '0 4px 4px rgba(0,0,0,0.25)',
    borderRadius: '4px',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 15,
    paddingLeft: 15
  },
}));

const ChannelHeader = (props) => {
  const classes = useStyles();
  const { channel, onSubscribe, onAnalyzeLive, ...rest } = props;

  return (
    <Paper className={classes.mainFeaturedPost} style={{ background:  `linear-gradient(78.5deg, #DA1E37 -0.51%, rgba(255, 255, 255, 0) 100%), url(${channel.thumbnail_path || defaultThumbnail}) no-repeat`, backgroundSize: "cover" }}>
      <Grid container style={{height: "500px"}} xs={12} sm={12} md={8} lg={9} justifyContent="flex-start">
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <div className={classes.mainFeaturedPostContent}>
            <Box display='flex' style={{marginTop: "15px", marginBottom: "15px"}}>
              <Typography className={classes.boxStyle} variant='h5'>{channel.type}</Typography>
            </Box>
            <Typography component="h1" variant="h1" color="inherit" gutterBottom style={{fontSize: "52px", lineHeight: "1.2em"}}>
              {channel.name}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph style={{lineHeight: "1.6em"}}>
              {channel.description}
            </Typography>
            <Box display="flex" flexDirection='row'  flexGrow={1} style={{alignContent: "center", verticalAlign: 'middle'}}>
              {/* <Rating name="read-only" value={4.2} size='large' readOnly /> */}
              <Box display="flex" flexDirection='column' justifyContent='center'>
                <Typography variant="h6" style={{marginLeft: "15px"}} color="inherit">(59) Reviews</Typography>
              </Box>
            </Box>
            <Box display='flex' style={{marginTop: '15px', alignItems: 'center'}}>
              <Avatar alt='instructor avatar' src={yogaInstructor} style={{border: "2px solid white"}}/>
              <Box style={{paddingLeft: '10px'}} >
                <Typography style={{paddingLeft: '3px'}} variant='h6' color='inherit'>{channel.instr_first_name} {channel.instr_last_name}</Typography>
                {/* <Rating name="read-only" value={4.5} size='small' readOnly /> */}
              </Box>
            </Box>
            <Box display='flex' style={{marginTop: "15px"}}>
              <Button variant="contained"  size='large' className={classes.subscribeButton} onClick={onSubscribe}  color={channel.subscribed ? 'secondary' : 'primary' }>
                {channel.subscribed ? 'Unsubscribe':'Subscribe'}
              </Button>
              {/* <Button variant="contained"  size='large' style={{marginLeft: "15px"}} className={classes.subscribeButton} onClick={onAnalyzeLive}>
                Live Feedback
              </Button> */}
            </Box>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

ChannelHeader.propTypes = {
  channel: PropTypes.object,
  onSubscribe: PropTypes.func.isRequired,
  onAnalyzeLive: PropTypes.func.isRequired
};

export default ChannelHeader;