import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import InfoIcon from "@material-ui/icons/Info";
import validate from "validate.js";

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    video: {
      width: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    info: {
      display: "flex",
      alignItems: "center",
    },
    error: {
      color: "#e53935",
      marginLeft: 14,
      marginRight: 14,
      fontSize: 11,
      marginTop: 3,
      textAlign: "left",
      
      fontWeight: 400,
      lineheight: 13,
      letterSpacing: 0.33,
    },
  });

type State = {
  disabled: boolean;
  channelId?: string;
  name: string;
  video?: File;
  videoObjectUrl?: string;
  duration?: number;
  uploadProgress: number;
  errors: any;
  submitted: any;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class ChannelVideoCreate extends Component<Props, State> {
  private videoRef = React.createRef<HTMLVideoElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: false,
      uploadProgress: 0,
      errors: {},
      submitted: {},
      name: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
  }

  componentDidMount = async () => {
    const channelId = this.props.match.params.channelId;
    if (channelId) {
      this.setState({
        channelId: channelId,
      });
    }
  };

  async onChange(acceptedFiles: Array<File>) {
    if (acceptedFiles.length === 1) {
      const video = acceptedFiles[0];
      this.setState(prevState => ({
        video: video,
        videoObjectUrl: URL.createObjectURL(video),
        submitted: { ...prevState.submitted, file: false},
      }));
    }
  }

  onUploadProgress(progressEvent: any): void {
    const uploadProgress = progressEvent.lengthComputable
      ? Math.round(progressEvent.loaded / (progressEvent.total / 100))
      : 0;
    this.setState({
      uploadProgress: uploadProgress,
    });
  }

  onSave = async () => {
    const { video, channelId, name, duration } = this.state;

    const formData = {
      name: name,
      file: video
    };
   
    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      file:  {
        presence: { allowEmpty: false, message: "is required" },
      },
    };

    const errors = validate(formData, schema);
    if (errors) {
      this.setState({
        errors: errors,
        submitted: {
          name: true,
          file: true
        },
      });
      return;
    }

    this.setState({
      disabled: true,
    });

    if (video) {
      const model = {
        video: video,
        channelId: channelId,
        name: name,
        duration: duration,
      };
      try {
        await channelVideoProvider.create(model, this.onUploadProgress);
        this.props.history.goBack();
      } catch (err) {
        console.log(err);
        this.setState({
          disabled: false,
        });
        alert("An error occurred. Please try again.");
      }
    } else {
      //TODO: display error message if no poseOrSkillId
      alert("Upload failed, please try again.");
      this.setState({
        disabled: false,
      });
    }
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      name: event.target.value as string,
      submitted: { ...prevState.submitted, name: false},
    }));
  };

  onLoadedMetadata = () => {
    const video = this.videoRef.current;
    if (video) {
      const duration = video.duration;
      this.setState({
        duration,
      });
    }
  };

  formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  hasError = (field: string) => {
    const { submitted, errors } = this.state;
    return submitted[field] && errors[field] ? true : false;
  };

  render = () => {
    const {
      video,
      errors,
      disabled,
      name,
      videoObjectUrl,
      channelId,
      uploadProgress,
    } = this.state;
    const { classes } = this.props;
    const data = channelId ? { channelId: channelId } : undefined;
    return (
      <main className={classes.layout}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
          data={data}
        />
        <Divider />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                
                label="Name"
                value={name}
                onChange={this.handleNameChange}
                helperText={this.hasError("name") ? errors["name"][0] : null}
                error={this.hasError("name")}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropzone onDrop={this.onChange}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: disabled
                          ? `${classes.dropzone} disabled`
                          : classes.dropzone,
                      })}
                    >
                      <input
                        {...getInputProps({
                          accept: "video/*",
                          disabled: disabled,
                        })}
                      />
                      <p>
                        Drag 'n' drop video(*.mp4) here, or click to select
                        video.
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <p className={classes.error}>{this.hasError("file") ? errors["file"][0] : null}</p>
            </Grid>
            {videoObjectUrl && (
              <Grid item xs={12}>
                <video
                  className={classes.video}
                  ref={this.videoRef}
                  playsInline
                  crossOrigin="anonymous"
                  src={videoObjectUrl}
                  onLoadedMetadata={this.onLoadedMetadata}
                ></video>
              </Grid>
            )}
            <Grid item xs={12}>
              {video && (
                <Typography color="textSecondary" variant="h6" component="h2">
                  {video.name} {this.formatBytes(video.size)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {disabled && (
                <LinearProgress variant="determinate" value={uploadProgress} />
              )}
            </Grid>
            <Grid item xs={12} className={classes.buttonsContainer}>
              <Button
                disabled={disabled}
                onClick={this.props.history.goBack}
                variant="contained"
              >
                Go Back
              </Button>
              <Button
                disabled={disabled}
                onClick={this.onSave}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.info}>
              <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
              <Typography>
                Large videos will process more slowly and might take a few
                minutes to be available.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </main>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelVideoCreate);
export default withRouter(styledComponent as any);
