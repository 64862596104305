import React from "react";
import { Slider, Theme, Tooltip, withStyles } from "@material-ui/core";
import theme from "../../../../../theme";
import { secondsToShortTimeRoundedString } from "../../../../../utils/conversion.functions";

type Props = {
  duration?: number;
  step?: number;
  checkpoints: any[];
  activeIndex: number;
};

export default function Checkpoints(props: Props) {
  const { checkpoints, duration, step, activeIndex } = props;

  const getSlider = (cp: any, index: number) => {
    const color =
      index === activeIndex ? theme.palette.primary : theme.palette.secondary;

    function ValueLabelComponent(props: any) {
      const { children, open, value } = props;
      const CustomTooltip = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: color.main,
          color: "rgb(255, 255, 255)",
          boxShadow: theme.shadows[1],
          fontSize: 14,
        },
      }))(Tooltip);
      return (
        <CustomTooltip
          open={open}
          enterTouchDelay={0}
          placement={index % 2 === 0 ? "top" : "bottom"}
          title={value}
        >
          {children}
        </CustomTooltip>
      );
    }

    const rootColor =
      index === 0 ? theme.palette.primary.main : "rgba(0, 0, 0, 0);";
    const CheckpointSlider = withStyles({
      root: {
        color: theme.palette.common.white,
        pointerEvents: "none",
      },
      thumb: {
        color: color.main,
        // pointerEvents: "auto",
        // height: 24,
        // width: 24,
        // backgroundColor: "#fff",
        // border: "2px solid currentColor",
        // marginTop: -8,
        // marginLeft: -12,
        // "&:focus, &:hover, &$active": {
        //   boxShadow: "inherit",
        // },
      },
      // active: {},
      // valueLabel: {
      //   left: "calc(-50% + 4px)",
      // },
      track: {
        color: color.main,
        //backgroundColor: color.main,
        // height: 8,
        // borderRadius: 4,
      },
      rail: {
        color: rootColor,
        //backgroundColor: color.light,
        //color: theme.palette.common.black,
        //backgroundColor:  theme.palette.common.black,
        // height: 8,
        // borderRadius: 4,
      },
    })(Slider);
    return (
      <CheckpointSlider
        color={index % 2 === 0 ? "primary" : "secondary"}
        style={{ position: "absolute", left: 0, top: 0 }}
        value={[cp.startTime, cp.endTime]}
        aria-labelledby="time-slider"
        valueLabelDisplay="on"
        step={step}
        min={0}
        max={duration}
        ValueLabelComponent={ValueLabelComponent}
        valueLabelFormat={(x) => secondsToShortTimeRoundedString(x)}
      />
    );
  };

  return (
    <div style={{ position: "relative", marginTop: 40, marginBottom: 100 }}>
      {checkpoints.map((cp, index) => getSlider(cp, index))}
    </div>
  );
}
