import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { VideoToolbar, VideoCard } from "./components";
import * as channelVideoProvider from "../../../providers/instructor/channel-video.provider";
import { ChannelVideoItem } from "../../../providers/instructor/channel-video.provider";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

type Props = {
  channelId: string;
};

const pageSize = 12;

export default function ChannelCourseList(props: Props) {
  const { channelId } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<Array<ChannelVideoItem>>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = React.useState(1);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    const getClasses = async (page: number) => {
      setLoading(true);
      try {
        const pager = {
          page_size: pageSize,
          page_number: page,
        };

        const { records, total } = await channelVideoProvider.getAll(
          channelId,
          pager
        );
        setVideos(records);
        setTotalCount(total);
      } catch (err) {
        console.log("An error occurred while processing your request.");
      }
      setLoading(false);
    };
    getClasses(page);
  }, [page]);

  const [confirmationData, setConfirmationData] = useState<any>();

  const onRemoveClickHandler = async (data: any) => {
    await channelVideoProvider.remove(data);
    setVideos(videos.filter((i) => i.id !== data.id));
  };

  const onRemoveVideo = async (id: string) => {
    setConfirmationData({
      id: id,
      channelId: channelId,
    });
  };

  return (
    <div className={classes.root}>
      <VideoToolbar channelId={channelId} />
      <div className={classes.content}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid style={{ marginTop: "30px" }} container spacing={3}>
            {videos &&
              videos.map((video) => (
                <Grid item key={video.id} lg={3} md={6} xs={12}>
                  <VideoCard onRemove={onRemoveVideo} video={video} />
                </Grid>
              ))}
          </Grid>
        )}
        {totalCount !== undefined && totalCount !== 0 && (
          <Pagination
            disabled={loading}
            count={Math.ceil(totalCount / pageSize)}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </div>
      <ConfirmationDialog
        handleConfirm={onRemoveClickHandler}
        dialogTitle={"Confirm video removal"}
        data={confirmationData}
      />
    </div>
  );
}
