import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
}));

const VideoCard = (props) => {
  const { className, video, onToggleApprove, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const onDetails = () => {
    history.push(`/admin/video/${video.id}/details`);
  };

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <CardActionArea>
        <CardMedia
          onClick={onDetails}
          className={clsx(classes.media, className)}
          image={video.thumbnail_path}
          title="thumbnail"
        />
        <CardContent>
          <Typography color="textSecondary" variant="h4" component="h2">
            {video.name}
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {video.instructor}
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {new Date(video.creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          onClick={() => onToggleApprove(video.id, !video.is_approved)}
        >
          {video.is_approved ? "Decline" : "Approve"}
        </Button>
      </CardActions>
    </Card>
  );
};

VideoCard.propTypes = {
  className: PropTypes.string,
  video: PropTypes.object.isRequired,
  onToggleApprove: PropTypes.func.isRequired,
};

export default VideoCard;
