import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ClassPracticeAnalysisDetails } from "../../types/class-practice/class-practice-analysis-details";

export type InstructorListItem = {
  id: string;
  name: string;
};

export type ChannelClassPracticeItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
};

export type ChannelClassPracticeListItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  channel_id: string;
  subscription_id: string;
  subscription_name: string;
  is_published?: boolean;
  first_name: string;
  last_name: string;
  class_type: string;
  duration: number;
  block_count: number;
  practice_duration: number;
};

export type ClassPracticesResponse = {
  records: Array<ChannelClassPracticeListItem>;
  total: number;
};

export type ChannelCourseDetails = {
  name: string;
  file_path: string;
  thumbnail_path: string;
  creation_date: string;
  channel_name: string;
  channel_id: string;
  timespans: any[];
};

export const getAll = async (
  pager: any,
  filter: any
): Promise<ClassPracticesResponse> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-practice/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        activity_id: filter["activityId"],
        instructor_id: filter["instructorId"],
      },
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getInstructors = async (): Promise<Array<InstructorListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-practice/getInstructors",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export type ClassModalDetails = {
  name: string;
  duration: number;
  poses: Array<{
    name: string;
  }>;
};

export const getAllByChannel = async (
  channelId: string
): Promise<Array<ChannelClassPracticeListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-practice/getAllByChannel",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      channel_id: channelId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getForAnalysis = async (
  id: string
): Promise<ClassPracticeAnalysisDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/channel/class-practice/getForAnalysis",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};