import React, { Component } from "react";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";

import { PoseOrSkillToolbar, PoseOrSkillCard } from "./components";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ChannelPoseOrSkillListItem } from "../../../providers/instructor/channel-pose.provider";
import * as channelPoseOrSkillProvider from "../../../providers/instructor/channel-pose.provider";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  posesOrSkills: Array<ChannelPoseOrSkillListItem>;
  loading: boolean;
  confirmationData: any;
};

type Props = {
  classes: any;
  channelId: string;
};

class ChannelPoseOrSkillList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      posesOrSkills: [],
      loading: true,
      confirmationData: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      const posesOrSkills = await channelPoseOrSkillProvider.getAll(
        this.props.channelId
      );
      this.setState({
        posesOrSkills: posesOrSkills,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  onRemove = async (id: string) => {
    const { channelId } = this.props;
    this.setState({
      confirmationData: {
        channelId: channelId,
        id: id,
      },
    });
  };

  onRemoveClickHandler = async (data: any) => {
    await channelPoseOrSkillProvider.remove(data.id, data.channelId);
    this.setState({
      posesOrSkills:
        this.state.posesOrSkills &&
        this.state.posesOrSkills.filter((i) => i.id !== data.id),
    });
  };

  render = () => {
    const { posesOrSkills, loading, confirmationData } = this.state;

    const { classes, channelId } = this.props;

    return (
      <div className={classes.root}>
        {channelId && <PoseOrSkillToolbar channelId={channelId} />}
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              {posesOrSkills.map((poseOrSkill) => (
                <Grid item key={poseOrSkill.id} lg={3} md={6} xs={12}>
                  <PoseOrSkillCard
                    onRemove={this.onRemove}
                    poseOrSkill={poseOrSkill}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        <ConfirmationDialog
          handleConfirm={this.onRemoveClickHandler}
          dialogTitle={"Confirm pose removal"}
          data={confirmationData}
        />
      </div>
    );
  };
}

export default withStyles(styles)(ChannelPoseOrSkillList);
