import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type Feedback = {
  id: string;
  creation_date: Date;
  feedback: string;
  first_name: string;
  last_name: string;
  username: string;
};

export const getAll = async (): Promise<Array<Feedback>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/feedback/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
