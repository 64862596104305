import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  Avatar,
  ListItemText,
  FormHelperText,
} from "@material-ui/core";
import { ChannelExerciseListItem } from "../../../../../../providers/instructor/channel-exercise.provider";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  thumbnail: {
    maxWidth: "100%",
  },
}));

type Props = {
  exercises: ChannelExerciseListItem[];
  exercise?: ChannelExerciseListItem;
  onExerciseSelected: (exercise: ChannelExerciseListItem) => void;
  errorMessage?: string;
  disabled: boolean;
};

export default function ExerciseSelection(props: Props) {
  const { exercises, exercise, onExerciseSelected, errorMessage, disabled } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    const exercise = exercises.find((x) => x.id === id);
    if (exercise) {
      onExerciseSelected(exercise);
    }
  };

  const getName = (name: string, message: string) => {
    if (!message) {
      return name;
    }
    return `${name} (${message})`;
  };

  return (
    <Grid container spacing={3} item xs={12}>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} error={!!errorMessage}>
          <InputLabel shrink id="category-select-placeholder-label-label">
            Exercise
          </InputLabel>
          <Select
            labelId="category-select-placeholder-label-label"
            value={exercise ? exercise.id : ""}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            disabled={disabled}
          >
            {exercises.map(({ id, name, message, thumbnail_path }) => (
              <MenuItem value={id} key={id}>
                <Avatar
                  alt={name}
                  src={thumbnail_path}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <ListItemText primary={getName(name, message)} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
      </Grid>
      {exercise && (
        <Grid item xs={12}>
          <img
            alt="thumbnail"
            className={classes.thumbnail}
            src={exercise.thumbnail_path}
          />
        </Grid>
      )}
    </Grid>
  );
}
