import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ChannelClassBlockOption } from "../../../../../../providers/instructor/channel-class-block.provider";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
    position: "relative",
  },
  card: {
    position: "relative",
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

type Props = {
  block: ChannelClassBlockOption;
  channelId: string;
  onRemove: (id: string) => void;
};

export default function CourseCard(props: Props) {
  const { block, onRemove, channelId } = props;
  const history = useHistory();
  const classes = useStyles();

  const onEdit = (id: string) => {
    history.push(`/sports-instructor/channel/${channelId}/block/${id}/edit`);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          style={{
            backgroundSize: "contain",
          }}
          className={classes.media}
          image={block.thumbnail_path}
          title={block.name}
        ></CardMedia>
        <CardContent>
          <Typography
            gutterBottom
            className={classes.name}
            title={block.name}
            variant="h3"
            component="h2"
          >
            {block.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => onEdit(block.id)}>
          Edit
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => onRemove(block.id)}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
