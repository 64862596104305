import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { StudentPracticeActivityBlockPoseRecord } from "../../types/practice-activities/student-practice-activity-block-pose-record";
import { StudentPracticeActivityBlockExerciseRecord } from "../../types/practice-activities/student-practice-activity-block-exercise-record";

export type CreateStudentPracticeActivityBlockPoseRecord = {
  blockTime: number;
  keypoints: Keypoint3D[];
  timestamp: number;
  studentActivityBlockId: string;
  accuracy: number;
  jointsAccuracy: any;
};

export type CreateStudentPracticeActivityBlockExerciseRecord = {
  blockTime: number;
  keypoints: Keypoint3D[];
  timestamp: number;
  studentActivityBlockId: string;
  accuracyPercentages: (number | undefined)[];
  repetition: number;
  jointsAccuracy: any;
  nextExerciseStepIndex: number;
};

export const createPose = async (
  model: CreateStudentPracticeActivityBlockPoseRecord
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/record/createPose",
    baseURL: apiUrlBase,
    data: {
      block_time: model.blockTime,
      timestamp: model.timestamp,
      keypoints: model.keypoints,
      student_activity_block_id: model.studentActivityBlockId,
      accuracy: model.accuracy,
      user_id: authService.getAuthState().id,
      joints_accuracy: model.jointsAccuracy
    },
  };
  const response = await axios(config);
  return response.data;
};

export const createExercise = async (
  model: CreateStudentPracticeActivityBlockExerciseRecord
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/record/createExercise",
    baseURL: apiUrlBase,
    data: {
      block_time: model.blockTime,
      timestamp: model.timestamp,
      keypoints: model.keypoints,
      student_activity_block_id: model.studentActivityBlockId,
      accuracy_percentages: model.accuracyPercentages,
      repetition: model.repetition,
      user_id: authService.getAuthState().id,
      joints_accuracy: model.jointsAccuracy,
      next_step_index: model.nextExerciseStepIndex
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getPoses = async (
  blockId: string
): Promise<Array<StudentPracticeActivityBlockPoseRecord>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/record/getPoses",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      block_id: blockId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getExercises = async (
  blockId: string
): Promise<Array<StudentPracticeActivityBlockExerciseRecord>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/practice-activity/record/getExercises",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      block_id: blockId,
    },
  };
  const response = await axios(config);
  return response.data;
};
