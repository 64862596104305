import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
  },
}));

const ExerciseCard = (props) => {
  const { className, poseOrSkill, onRemove, channelId, ...rest } = props;
  const { id, thumbnail_path, name, message, creation_date, step_count } = poseOrSkill;
  const history = useHistory();
  const classes = useStyles();

  const urlBase =`/sports-instructor/channel/${channelId}/exercise/`;
  const onEdit = () => {
    history.push(`${urlBase}${id}/edit`);
  };
  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <CardActionArea>
        <CardMedia
          style={{
            backgroundSize: "contain"
          }}
          className={clsx(classes.media, className)}
          image={thumbnail_path}
          title="thumbnail"
        />
        <CardContent>
          <Typography color="textSecondary" variant="h4" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {message}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Steps ({step_count})
          </Typography>
          <Typography color="textSecondary" variant="h6" component="h2">
            {new Date(creation_date).toLocaleString()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => onEdit(id)}>
          Edit
        </Button>
        <Button size="small" color="secondary" onClick={() => onRemove(id)}>
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

ExerciseCard.propTypes = {
  className: PropTypes.string,
  poseOrSkill: PropTypes.object.isRequired,
  channelId: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ExerciseCard;
