import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "white",
    fontSize: 65,
    padding: 6,
    backgroundColor: "black !important",
    marginLeft: 5,
    marginRight: 5
  },
}));

type Props = {
  isAudioEnabled: boolean;
  onToggleSound: () => void;
};

export default function InstructorSoundControl(props: Props) {
  let { isAudioEnabled, onToggleSound } = props;
  const classes = useStyles();
  return (
    <IconButton
      aria-label={isAudioEnabled ? "Mute" : "Unmute"}
      onClick={onToggleSound}
      className={classes.button}
    >
      {!isAudioEnabled ? (
        <SignalCellular0BarIcon fontSize="inherit" />
      ) : (
        <SignalCellular4BarIcon fontSize="inherit" />
      )}
    </IconButton>
  );
}
