import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { StudentActivityTimespanPoseRecord } from "../../types/activities/student-activity-timespan-pose-record";
import { StudentActivityTimespanExerciseRecord } from "../../types/activities/student-activity-timespan-exercise-record";

export type CreateStudentActivityTimespanPoseRecord = {
  classTime: number;
  keypoints: Keypoint3D[];
  timestamp: number;
  studentActivityTimespanId: string;
  accuracy: number;
  jointsAccuracy: any
};

export type CreateStudentActivityTimespanExerciseRecord = {
  classTime: number;
  keypoints: Keypoint3D[];
  timestamp: number;
  studentActivityTimespanId: string;
  accuracyPercentages: (number | undefined)[];
  repetition: number;
  jointsAccuracy: any,
  nextExerciseStepIndex: number
};

export const createPose = async (
  model: CreateStudentActivityTimespanPoseRecord
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/record/createPose",
    baseURL: apiUrlBase,
    data: {
      class_time: model.classTime,
      timestamp: model.timestamp,
      keypoints: model.keypoints,
      student_activity_timespan_id: model.studentActivityTimespanId,
      accuracy: model.accuracy,
      user_id: authService.getAuthState().id,
      joints_accuracy: model.jointsAccuracy,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const createExercise = async (
  model: CreateStudentActivityTimespanExerciseRecord
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/record/createExercise",
    baseURL: apiUrlBase,
    data: {
      class_time: model.classTime,
      timestamp: model.timestamp,
      keypoints: model.keypoints,
      student_activity_timespan_id: model.studentActivityTimespanId,
      accuracy_percentages: model.accuracyPercentages,
      repetition: model.repetition,
      user_id: authService.getAuthState().id,
      joints_accuracy: model.jointsAccuracy,      
      next_step_index: model.nextExerciseStepIndex
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getPoses = async (
  timespanId: string
): Promise<Array<StudentActivityTimespanPoseRecord>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/record/getPoses",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      timespan_id: timespanId,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getExercises = async (
  timespanId: string
): Promise<Array<StudentActivityTimespanExerciseRecord>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/user/activity/record/getExercises",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
      timespan_id: timespanId,
    },
  };
  const response = await axios(config);
  return response.data;
};
