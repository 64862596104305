import React, { FC, useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { XYCoord } from "dnd-core";
import {
  secondsToTimeRoundedString,
} from "../../../../../utils/conversion.functions";
import { ChannelClassPracticeBlockCreateListItem } from "../../../../../providers/instructor/channel-class-practice.provider";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Grid } from "@material-ui/core";

const style = {
  width: "100%",
  border: "1px dashed gray",
  padding: "2px 12px",
  margin: "2px 12px",
  backgroundColor: "white",
  cursor: "move",
};

const useStyles = makeStyles((theme: Theme) => ({
  blockListItem: {
    display: "flex",
    alignItems: "center",
  },
  timeRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

export interface CardProps {
  id: number;
  block: ChannelClassPracticeBlockCreateListItem;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: number;
  type: string;
}

export const Card: FC<CardProps> = ({ id, block, index, moveCard }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Grid
        className={classes.blockListItem}
        container
        item
        xs={12}
        spacing={3}
      >
        <Grid item xs={1}>
          {index + 1}.
        </Grid>
        <Grid container item xs={5}>
          <Grid item xs={12} className={classes.timeRow}>
            <span>Total block duration:</span>{" "}
            <span>
              {secondsToTimeRoundedString(
                block.practice_duration_seconds +
                  block.explanation_duration_seconds
              )}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.timeRow}>
            <span>Explanation video duration:</span>{" "}
            <span>
              {secondsToTimeRoundedString(block.explanation_duration_seconds)}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.timeRow}>
            <span>Practice duration:</span>{" "}
            <span>
              {secondsToTimeRoundedString(block.practice_duration_seconds)}
            </span>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Avatar alt={block.name} src={block.thumbnail_path} />
        </Grid>
        <Grid item xs={2} className={classes.name}>
          {block.name}
        </Grid>
        <Grid item xs={3}>
          {block.exercise_repetitions
            ? `Exercise repetitions: ${block.exercise_repetitions}`
            : `Pose duration: ${block.pose_duration_seconds}(s)`}
        </Grid>
      </Grid>
    </div>
  );
};
