import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import { makeStyles, Theme } from "@material-ui/core";
Chart.register(...registerables);

const useStyles = makeStyles((theme: Theme) => ({
  chart: {
    width: "100% !important",
  },
}));

type Props = {
  duration: number;
  accuracy?: { value: number; seconds: number };
};

export default function AccuracyChart(props: Props) {
  const classes = useStyles();
  const { duration, accuracy } = props;
  const chartRef = useRef<any>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const plugin = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart: Chart) => {
      const ctx = chart.canvas.getContext("2d");
      if (ctx) {
        ctx.save();
        ctx.clearRect(0, 0, chart.width, chart.height);
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    const canvasElement = canvasRef.current;

    if (canvasElement) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const numberOfRecords = Math.floor(duration) * 2;
      const records = new Array(numberOfRecords).fill(NaN);
      const chart = new Chart(canvasElement, {
        type: "line",
        plugins: [plugin],
        data: {
          labels: records.map((d, i) => i),
          datasets: [
            {
              data: records,
              label: "accuracy (%)",
              backgroundColor: "#90ee90",
              borderColor: "#90ee90",
              borderWidth: 5,
              fill: false,
              cubicInterpolationMode: "monotone",
              tension: 0.5,
            },
          ],
        },
        options: {
          elements: {
            point: {
              radius: 0,
            },
          },
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              min: 0,
              max: 100,
              ticks: {
                display: false,
              },
              grid: {
                display: false,
              },
            },
            x: {
              display: false,
            },
          },
        },
      });
      chartRef.current = chart;
    }
  }, [duration]);

  useEffect(() => {
    const updateData = (accuracy: any) => {
      const chart = chartRef.current;
      const dataset = chart.data.datasets[0];

      chart.data.datasets[0].data = dataset.data.map(
        (record: any, index: number) => {
          if (Number.isNaN(record) && (index + 1) / 2 < accuracy.seconds) {
            return accuracy.value;
          }
          return record;
        }
      );
      chart.update();
    };
    if (accuracy) {
      updateData(accuracy);
    }
  }, [accuracy]);

  return <canvas className={classes.chart} ref={canvasRef} />;
}
