// const regions = [
//   "head",
//   "leftShoulder",
//   "rightShoulder",
//   "leftArm",
//   "rightArm",
//   "leftHand",
//   "rightHand",
//   "chest",
//   "stomach",
//   "leftLeg",
//   "rightLeg",
//   "leftFoot",
//   "rightFoot",
// ];

export const defaultRegions = {
    "head": true,
    "leftShoulder": true,
    "rightShoulder": true,
    "leftArm": true,
    "rightArm": true,
    "leftHand": true,
    "rightHand": true,
    "chest": true,
    "stomach": true,
    "leftLeg": true,
    "rightLeg": true,
    "leftFoot": true,
    "rightFoot": true,
}

export const regionsToMixamorigMap: { [key: string]: string[] } = {
  head: ["mixamorigLeftEye", "mixamorigRightEye", "mixamorigNeck"],
  leftShoulder: ["mixamorigLeftArm"],
  rightShoulder: ["mixamorigRightArm"],
  leftArm: ["mixamorigLeftForeArm"],
  rightArm: ["mixamorigRightForeArm"],
  leftHand: ["mixamorigLeftHand"], //TODO: try adding fingers?
  rightHand: ["mixamorigRightHand"], //TODO: try adding fingers?
  chest: [],
  stomach: ["mixamorigHips", "mixamorigSpine1", "mixamorigSpine2"],
  leftLeg: ["mixamorigLeftUpLeg", "mixamorigLeftLeg"],
  rightLeg: ["mixamorigRightUpLeg", "mixamorigRightLeg"],
  leftFoot: [
    "mixamorigLeftFoot",
    "mixamorigLeftToe_End",
    "mixamorigLeftToeBase",
  ],
  rightFoot: [
    "mixamorigRightFoot",
    "mixamorigRightToe_End",
    "mixamorigRightToeBase",
  ],
};

export const getJointsFromRegions = (regions: {
  [key: string]: boolean;
}): string[] => {
  const keys = Object.keys(regions).filter((k) => regions[k] === true);
  let joints: string[] = [];
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const mixamorigJoints = regionsToMixamorigMap[key];
    if (mixamorigJoints) {
      joints = joints.concat(mixamorigJoints);
    }
  }
  return joints;
};