import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import SelectedPoint from "../SelectedPoint";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { StudentPracticeActivityBlockExerciseRecord } from "../../../../../types/practice-activities/student-practice-activity-block-exercise-record";
import { StudentPracticeActivityBlockExerciseDetails } from "../../../../../types/practice-activities/student-practice-activity-block-details";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import HumanRegionsAccuracy from "../../../../../components/HumanRegionsAccuracy";
import Summary from "../Summary";

Chart.register(...registerables);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
  },
  chart: {
    height: 300,
  },
  video: {
    width: "50%",
  },
  reward: {
    height: 30,
    width: 30,
    objectFit: "contain",
  },
  button: {
    color: "black",
    fontSize: "50px",
    backgroundColor: "white !important",
  },
}));

type Props = {
  records: StudentPracticeActivityBlockExerciseRecord[];
  name: string;
  score: number;
  duration: number;
  exercise: StudentPracticeActivityBlockExerciseDetails;
  effectiveness: number;
};

export default function AccuracyChart(props: Props) {
  const { records, name, score, duration, exercise, effectiveness } = props;
  const classes = useStyles();
  const chartRef = useRef<any>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [selectedRecord, setSelectedRecord] =
    useState<StudentPracticeActivityBlockExerciseRecord>();
  const [studentReps, setStudentReps] = useState<number>(0);

  const [recordIndex, setRecordIndex] = useState<number>();
  const playbackTimeoutRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    const setupData = () => {
      const canvasElement = canvasRef.current;
      if (canvasElement) {
        const setCurrentTime = (time: number) => {
          const videoElement = videoRef.current;
          if (videoElement) {
            videoElement.currentTime = time;
          }
        };

        //TODO: use records instead of steps?
        const datasets = exercise.steps.map(({ order }) => {
          const data = records.map((r) => {
            const value = r.accuracy.find((a) => a.order === order);
            if (value) {
              return value.accuracy;
            }
          });

          const randomNumber = (min: number, max: number) => {
            return Math.random() * (max - min) + min;
          };
          //TODO: generate color
          const r = randomNumber(0, 255);
          const g = randomNumber(0, 255);
          const b = randomNumber(0, 255);

          const solidColor = `rgb(${r}, ${g}, ${b})`;
          const borderColor = `rgba(${r}, ${g}, ${b}, 0.6)`;
          const fillColor = `rgba(${r}, ${g}, ${b}, 0.2)`;

          const dataset = {
            label: `Step #${order + 1} accuracy (%)`,
            data: data,
            borderColor: borderColor,
            backgroundColor: solidColor,
            tension: 0.4,
            pointStyle: "circle",
            pointRadius: 5,
            pointBorderColor: solidColor,
            fill: {
              above: fillColor,
              below: "rgba(255, 255, 255, 0)",
              target: { value: exercise.thresholdAccuracy },
            },
          };
          return dataset;
        });

        const chart = new Chart(canvasElement, {
          type: "line",
          data: {
            labels: records.map((d) => d.blockTime.toFixed(2)),
            datasets: datasets,
          },
          options: {
            maintainAspectRatio: false,
          },
        });
        chartRef.current = chart;

        const getFirstRepKeypoints = (
          records: StudentPracticeActivityBlockExerciseRecord[]
        ) => {
          if (records.length > 1) {
            return records[1];
          }
          if (records.length === 1) {
            return records[0];
          }
        };

        const record = getFirstRepKeypoints(records);
        if (record) {
          setSelectedRecord(record);
          setCurrentTime(record.blockTime);
        }

        canvasElement.onclick = (event: Event) => {
          const activePoints = chart.getElementsAtEventForMode(
            event,
            "point",
            { axis: "x", intersect: true },
            true
          );
          if (activePoints.length !== 0) {
            const activePoint = activePoints[0];
            const selectedRecordIndex = activePoint.index;
            const record = records[selectedRecordIndex];
            setSelectedRecord(record);
            setCurrentTime(record.blockTime);
          }
        };
      }
    };

    setupData();

    const setMaxRep = (
      records: StudentPracticeActivityBlockExerciseRecord[]
    ) => {
      let max = 0;
      for (let index = 0; index < records.length; index++) {
        const element = records[index];
        if (element.repetition > max) {
          max = element.repetition;
        }
      }
      setStudentReps(max);
    };
    setMaxRep(records);
  }, []);

  const [isPLaying, setIsPlaying] = useState<boolean>();

  const onTogglePlay = () => {
    setIsPlaying(!isPLaying);
    if (!isPLaying) {
      const playbackTimeout = playbackTimeoutRef.current;
      if (playbackTimeout) {
        clearTimeout(playbackTimeout);
      }
      const recordIndex = 0;
      setRecordIndex(recordIndex);
    } else {
      const playbackTimeout = playbackTimeoutRef.current;
      if (playbackTimeout) {
        clearTimeout(playbackTimeout);
      }
    }
  };

  useEffect(() => {
    if (recordIndex !== undefined) {
      const record = records[recordIndex];
      setSelectedRecord(record);

      const nextRecordIndex = recordIndex + 1;
      if (records.length - 1 >= nextRecordIndex) {
        const nextRecord = records[nextRecordIndex];
        const timeoutDuration = nextRecord.blockTime - record.blockTime;
        playbackTimeoutRef.current = setTimeout(() => {
          setRecordIndex(nextRecordIndex);
        }, timeoutDuration * 1000);
      } else {
        setIsPlaying(false);
      }
    }
  }, [recordIndex]);

  const recalculateScore = () => {
    const percentage = (studentReps / exercise.repetitions) * 100;
    const percentageValue = percentage > 100 ? 100 : percentage;
    return percentageValue;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          Exercise: {name}. Repetitions {studentReps} out of required{" "}
          {exercise.repetitions}
        </Typography>
      </Grid>
      <Grid className={classes.chart} item xs={12}>
        <canvas ref={canvasRef} />
      </Grid>
      <Grid className={classes.chart} item sm={6} xs={12}>
        {selectedRecord && (
          <SelectedPoint
            studentKeypoints={selectedRecord.keypoints}
            instructorKeypoints={
              exercise.steps[selectedRecord.nextStepIndex].keypoints
            }
          />
        )}
      </Grid>
      <Grid
        item
        sm={6}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {selectedRecord && (
          <div style={{ height: 48, display: "flex", alignItems: "center" }}>
            <span>Regions used in calculations</span>
          </div>
        )}
        {selectedRecord && (
          <HumanRegionsAccuracy accuracyData={selectedRecord.jointsAccuracy} />
        )}
        {selectedRecord && (
          <div
            style={{
              height: 48,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#57c9d5",
                  margin: 5,
                }}
              ></div>
              <span>not used</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#ff0000",
                  margin: 5,
                }}
              ></div>{" "}
              <span>to</span>{" "}
              <div
                style={{
                  height: 28,
                  width: 28,
                  borderRadius: 10,
                  backgroundColor: "#00ff00",
                  margin: 5,
                }}
              ></div>
              <span>scale represents accuracy </span>
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <IconButton
          aria-label={isPLaying ? "Stop" : "Play"}
          onClick={onTogglePlay}
          className={classes.button}
        >
          {!isPLaying ? (
            <PlayArrowIcon fontSize="inherit" />
          ) : (
            <StopIcon fontSize="inherit" />
          )}
        </IconButton>
      </Grid>
      <Summary
        duration={duration}
        exercise={exercise}
        score={
          score === null || score === undefined ? recalculateScore() : score
        }
        effectiveness={effectiveness}
        studentRepsCounter={studentReps}
      />
    </Grid>
  );
}
