import React, { Component } from "react";
import { VideoPlayer } from "./components";
import { RouteComponentProps } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as videoProvider from "../../../providers/student/video.provider";
import { VideoDetails } from "../../../providers/student/video.provider";
import BreadcrumbsContainer from "../../../components/BreadcrumbsContainer";
import { Typography, Divider } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
  });

type State = {
  video?: VideoDetails;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class VideoDetailsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    if (id) {
      const video = await videoProvider.get(id);
      this.setState({
        video: video,
      });
    }
  };

  render = () => {
    const { video } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h4" gutterBottom>
          {this.props.title}
        </Typography>
        <BreadcrumbsContainer
          breadcrumbs={this.props.breadcrumbs}
          title={this.props.title}
        />
        <Divider />
        {video && <VideoPlayer video={video} />}
      </div>
    );
  };
}

export default withStyles(styles)(VideoDetailsView);
