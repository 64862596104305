import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  Slider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ClassCreateTimespan } from "../../../../../types/class/class-create-timespan";
import { secondsToTimeString } from "../../../../../utils/conversion.functions";

const useStyles = makeStyles((theme: Theme) => ({
  timespanLabel: {
    display: "flex",
    justifyContent: "space-between",
  },
  timespanDelete: {
    cursor: "pointer",
  },
}));

type Props = {
  timespan: ClassCreateTimespan;
  index: number;
  duration: number;
  onRemove: (index: number) => void;
};

export default function PoseSelection(props: Props) {
  const { timespan, onRemove, index, duration } = props;
  const { name, startSeconds, endSeconds } = timespan;
  const classes = useStyles();

  const getLabel = () => {
    const namePart = timespan.exercise
      ? `${name}(${timespan.exercise.repetitions})`
      : name;

    return `${namePart}, ${secondsToTimeString(
      startSeconds
    )} - ${secondsToTimeString(endSeconds)}`;
  };

  return (
    <Grid item xs={12} key={startSeconds}>
      <Typography className={classes.timespanLabel} gutterBottom>
        {getLabel()}
        <Delete
          className={classes.timespanDelete}
          onClick={() => onRemove(index)}
        />
      </Typography>
      <Slider
        disabled
        min={0}
        max={duration}
        value={[startSeconds, endSeconds]}
      />
    </Grid>
  );
}
