import React, { FC, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../services/pose3d.service";
import { ClassView } from "../../../types/threejs/class-view.type";
import * as THREE from "three";
import { Keypoint3D } from "../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../services/avatar/avatar.service";
import { AvatarType } from "../../../services/avatar/avatar-type.enum";
import * as avatarFeedback from "../../../services/avatar/avatar.feedback";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../services/keypoint.helper";
import * as rewardService from "../../../services/reward/reward.service";
import { rotateAroundAxis } from "../../../services/mediapipe/mediapipe.keypoint";
import {
  defaultRegions,
  getJointsFromRegions,
} from "../../../services/avatar/regions.service";
import { halfWidth } from "../../../services/analysis-scene.helper";
import { LiveClassSequenceModel } from "../../../types/analyze/live-class-sequence.type";
import { ChannelClassSequenceCheckpoint } from "../../../types/class-sequence/class-sequence-checkpoint";

const useStyles = makeStyles((theme: Theme) => ({
  canvas: {
    width: 1,
    height: 1,
  },
  canvasTest: {
    width: "100%",
    height: "100%",
  },
}));

type Props = {
  avatarType: AvatarType;
  selfieMode: boolean;
  checkpoint?: ChannelClassSequenceCheckpoint;
  onPoseAccuracyCalculated: (
    accuracy: number,
    live: LiveClassSequenceModel,
    checkpoint: ChannelClassSequenceCheckpoint,
    mergedAccuracy: any
  ) => void;
  live?: LiveClassSequenceModel;
  isTest?: boolean;
};

const studentRootName = "root-student";
const studentDisplayRootName = "root-student-display";
const instructorRootName = "root-instructor";
const instructorDisplayRootName = "root-instructor-display";

const LiveModelCombinedSequence: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    avatarType,
    selfieMode,
    checkpoint,
    onPoseAccuracyCalculated,
    live,
    isTest,
  } = props;

  const mountRef = useRef<HTMLDivElement>(null);

  const [view, setView] = useState<ClassView>();
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer>();
  const sceneRef = useRef<THREE.Scene>();
  const [renderTrigger, setRenderTrigger] = useState<number>();

  useEffect(() => {
    const initScene = async () => {
      sceneRef.current = pose3dService.sceneSetup2();
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }
      const renderer = pose3dService.getCourseRenderer(mount);
      setRenderer(renderer);

      const view = pose3dService.getCourseView(mount);
      setView(view);

      const addStudentModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentRootName;

        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });

        scene.add(model);
        avatarService.init(model);
      };

      const addStudentDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.shininess = 10;
            object.material.color = new THREE.Color(0x0072fa);
            object.material.visible = false;
            object.castShadow = true;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.visible = false;
          }
        });
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorDisplayModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorDisplayRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.transparent = true;
            object.material.opacity = 0.5;
            object.material.color = new THREE.Color(0x00ff00);
            object.material.visible = false;
          }
        });
        model.position.z = halfWidth / 2;
        scene.add(model);
        avatarService.init(model);
      };

      await rewardService.loadStarModel();
      await addStudentModel();
      await addStudentDisplayModel();
      await addInstructorModel();
      if (isTest) {
        await addInstructorDisplayModel();
      }
    };

    initScene();
    //TODO: enable only in test
    const videoUpdateInterval = setInterval(() => {
      setRenderTrigger(new Date().getTime());
    }, 120);

    const onWindowResize = () => {
      //TODO: remove in prod
      if (view && renderer) {
        view.camera.aspect = window.innerWidth / window.innerHeight;
        view.camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      const cleanupAvatar = (scene: THREE.Scene, name: string) => {
        const avatar = scene.getObjectByName(name);
        if (avatar) {
          avatar.traverse((child: any) => {
            if (child.isMesh) {
              pose3dService.cleanupObject(child);
            }
          });
          scene.remove(avatar);
        }
      };
      const scene = sceneRef.current;
      if (scene) {
        cleanupAvatar(scene, studentRootName);
        cleanupAvatar(scene, studentDisplayRootName);
        cleanupAvatar(scene, instructorRootName);
      }
      //TODO: DISPOSE EVERYTHING!!
      //https://threejs.org/docs/#manual/en/introduction/How-to-dispose-of-objects
      //something to think about https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag
      clearInterval(videoUpdateInterval);
    };
  }, []);

  useEffect(() => {
    const mount = mountRef.current;
    const scene = sceneRef.current;
    if (mount && scene && renderer && view) {
      pose3dService.renderCourseThree(mount, scene, renderer, view);
    }
  }, [renderTrigger]);

  useEffect(() => {
    const getCoordinates = (
      scene: THREE.Scene,
      keypoints: Keypoint3D[],
      name: string
    ) => {
      if (keypoints.length === 0) {
        return;
      }
      const root = scene.getObjectByName(name);
      if (!root) {
        return;
      }

      avatarService.poseUpdate(
        root,
        keypoints.map((kp) => {
          return {
            x: kp.x,
            y: kp.y,
            z: kp.z,
            part: kp.part,
            visibility: kp.visibility,
          };
        })
      );
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
      const coordinates = avatarFeedback.getCoordinates(root);
      return coordinates;
    };

    const processKeypoints = (
      checkpoint?: ChannelClassSequenceCheckpoint,
      live?: LiveClassSequenceModel
    ) => {
      const studentKeypoints = live ? live.studentKeypoints : [];
      let displayStudentKeypoints = !selfieMode
        ? mapKeypoints(studentKeypoints)
        : getFlippedDisplayKeypoints(mapKeypoints(studentKeypoints));
      displayStudentKeypoints = rotateAroundAxis(
        displayStudentKeypoints,
        new THREE.Vector3(0, 1, 0),
        -45
      ) as any[];

      const mount = mountRef.current;
      const scene = sceneRef.current;

      if (mount && scene && renderer && view) {
        //TODO: move to service
        const applyDisplayKeypoints = (
          scene: THREE.Scene,
          keypoints: Keypoint3D[],
          name: string
        ) => {
          const root = scene.getObjectByName(name);
          if (keypoints.length !== 0) {
            let displayKeypoints = keypoints.map(({ x, y, z, part }) => {
              return { x, y, z, part };
            });

            if (root) {
              avatarService.poseUpdate(root, displayKeypoints);
              const verticalShift = avatarService.getVerticalShift(root);
              avatarService.placeOnTheFloor(root, verticalShift);
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = true;
                }
              });
            }
          } else {
            if (root) {
              root.traverse((object: any) => {
                if (object.isMesh) {
                  object.material.visible = false;
                }
              });
            }
          }
        };

        applyDisplayKeypoints(
          scene,
          displayStudentKeypoints,
          studentDisplayRootName
        );

        if (
          live &&
          live.isInstructorVideoPaused !== undefined &&
          live.isInstructorVideoPaused !== true &&
          checkpoint
        ) {
          const processPoseBlock = (
            scene: THREE.Scene,
            displayStudentKeypoints: Keypoint3D[],
            displayInstructorKeypoints: Keypoint3D[],
            live: LiveClassSequenceModel,
            regions: { [key: string]: boolean }
          ) => {
            const studentThresholdKeypoints = getCoordinates(
              scene,
              displayStudentKeypoints,
              studentRootName
            );

            const instructorThresholdKeypoints = getCoordinates(
              scene,
              displayInstructorKeypoints,
              instructorRootName
            );

            if (instructorThresholdKeypoints && studentThresholdKeypoints) {
              const alignedLowerInstructorKeypoints = avatarFeedback.alignLower(
                studentThresholdKeypoints,
                instructorThresholdKeypoints
              );
              const lowerAccuracyPercentage =
                avatarFeedback.calculateAccuracyPercentage(
                  avatarFeedback.mapVisibility(
                    studentThresholdKeypoints,
                    displayStudentKeypoints
                  ),
                  alignedLowerInstructorKeypoints,
                  avatarFeedback.coordinateJoints
                );

              const alignedUpperInstructorKeypoints = avatarFeedback.alignUpper(
                studentThresholdKeypoints,
                instructorThresholdKeypoints
              );

              const upperAccuracyPercentage =
                avatarFeedback.calculateAccuracyPercentage(
                  avatarFeedback.mapVisibility(
                    studentThresholdKeypoints,
                    displayStudentKeypoints
                  ),
                  alignedUpperInstructorKeypoints,
                  avatarFeedback.coordinateJoints
                );

              const mergedAccuracy = avatarFeedback.getMergedAccuracy(
                upperAccuracyPercentage,
                lowerAccuracyPercentage,
                getJointsFromRegions(regions)
              );
              const accuracyPercentage =
                avatarFeedback.getAverageAccuracyPercentage(mergedAccuracy);

              onPoseAccuracyCalculated(
                accuracyPercentage,
                live,
                checkpoint,
                mergedAccuracy
              );
            }
          };

          const displayInstructorKeypoints = mapKeypoints(checkpoint.keypoints);
          applyDisplayKeypoints(
            scene,
            displayInstructorKeypoints,
            instructorDisplayRootName
          );
          processPoseBlock(
            scene,
            displayStudentKeypoints,
            displayInstructorKeypoints,
            live,
            //HACK: bacuse of empty db records
            checkpoint.regions && Object.keys(checkpoint.regions).length !== 0
              ? checkpoint.regions
              : defaultRegions
          );
          //TODO: processs checkpoint
        }
      }
    };

    processKeypoints(checkpoint, live);
  }, [live]);

  return (
    <div
      className={isTest ? classes.canvasTest : classes.canvas}
      ref={mountRef}
    ></div>
  );
};

export default LiveModelCombinedSequence;
