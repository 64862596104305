import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type Channel = {
  id: string;
  name: string;
  description: string;
  thumbnail_path: string;
  activity_id: string;
  class_count: number;
  video_count: number;
  pose_count: number;
  exercise_count: number;
  block_count: number;
  class_practice_count: number;
  is_published: boolean;
  class_sequence_count: number;
};

export type ChannelOption = {
  id: string;
  name: string;
  description: string;
  subscription_name: string;
  thumbnail_path: string;
  class_count: number;
  video_count: number;
  pose_count: number;
  exercise_count: number;
  is_published: boolean;
};

export const getAll = async (): Promise<Array<ChannelOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (channelId: string): Promise<Channel> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/get",
    data: {
      id: channelId,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const create = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("activity_id", item.activityId);
  formData.append("name", item.name);
  formData.append("description", item.description);
  formData.append("user_id", authService.getAuthState().id || "");
  formData.append("thumbnail", item.thumbnail);

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/create",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const edit = async (item: any): Promise<any> => {
  const formData = new FormData();
  formData.append("id", item.channelId);
  formData.append("activity_id", item.activityId);
  formData.append("name", item.name);
  formData.append("description", item.description);
  formData.append("user_id", authService.getAuthState().id || "");

  if (item.thumbnail) {
    formData.append("thumbnail", item.thumbnail);
  }

  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/edit",
    data: formData,
    baseURL: apiUrlBase,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios(config);
  return response.data;
};

export const remove = async (id: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/remove",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};

export const setPublished = async (
  channelId: string,
  isPublished: boolean
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/instructor/channel/setPublished",
    data: {
      id: channelId,
      is_published: isPublished,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};
