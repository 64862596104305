import React, { Component } from "react";
import {
  IconButton,
  Grid,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { ChannelsToolbar, ChannelCard } from "./components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as channelProvider from "../../../providers/student/channel.provider";
import { ChannelOption } from "../../../providers/student/channel.provider";
import { ChannelType } from "../../../types/channel/channel.type";
import { ChannelCourseListItem } from "../../../providers/student/channel-class.provider";
import FilterChannels from "./components/FilterChannels/FilterChannels";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    content: {
      flexGrow: 1,
      marginTop: theme.spacing(2),
    },
    pagination: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

type State = {
  channels?: Array<ChannelType>;
  filteredChannels?: Array<ChannelType>;
  loading: boolean;
};

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

class ChannelList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = async () => {
    try {
      const channels = await channelProvider.getAll();
      this.setState({
        channels: channels,
        filteredChannels: channels,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      alert("An error occurred while processing your request.");
      //TODO: handle initialization error
    }
  };

  clearFilters = (filtered: Array<ChannelType>) => {
    this.setState({ filteredChannels: filtered });
  };

  filter = (filtered: Array<ChannelType>) => {
    this.setState({ filteredChannels: filtered });
  };

  render = () => {
    const { channels, filteredChannels, loading } = this.state;

    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h1" gutterBottom>
          {this.props.title}
        </Typography>
        <ChannelsToolbar />
        <div className={classes.content}>
          {loading ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <FilterChannels
                filter={this.filter}
                clearFilters={this.clearFilters}
                channels={channels}
                filteredChannels={filteredChannels}
              />
              <Grid
                direction={"row"}
                container
                spacing={3}
                alignItems="stretch"
                alignContent="stretch"
                style={{ marginTop: "25px" }}
              >
                {filteredChannels &&
                  filteredChannels.map((channel) => (
                    <Grid item key={channel.id} lg={3} md={4} sm={6} xs={12}>
                      <ChannelCard channel={channel} />
                    </Grid>
                  ))}
              </Grid>
            </React.Fragment>
          )}
        </div>
        {/* <div className={classes.pagination}>
        <Typography variant="caption">1-6 of 20</Typography>
        <IconButton>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </div> */}
      </div>
    );
  };
}

const styledComponent = withStyles(styles)(ChannelList);
export default withRouter(styledComponent as any);
