import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  Button,
  Divider,
  Slider,
  IconButton,
} from "@material-ui/core";
import * as channelClassSequenceProvider from "../../../../providers/instructor/channel-class-sequence.provider";
import BreadcrumbsContainer from "../../../../components/BreadcrumbsContainer";
import {
  mediapipeService,
  mediapipeKeypoint,
} from "../../../../services/mediapipe";
import Pose3dEditor from "../../../../components/editor/pose3d-editor";

import { Keypoint3D } from "../../../../types/analyze/keypoint3d.type";
import { AvatarType } from "../../../../services/avatar/avatar-type.enum";
import {
  drawVideoOnCanvas,
  getImageFromCanvas,
} from "../../../../services/canvas.service";
import {
  round,
  secondsToTimeString,
} from "../../../../utils/conversion.functions";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FastForwardIcon from "@material-ui/icons/FastForward";
import { drawOriginal } from "../../../../services/mediapipe/mediapipe.keypoint";
import HumanRegions from "../../../../components/HumanRegions";
import validate from "validate.js";
import InfoIcon from "@material-ui/icons/Info";
import { Checkpoints, CombinedSlider } from "./components";
import { ChannelClassSequenceCheckpoint } from "../../../../types/class-sequence/class-sequence-checkpoint";
import { defaultCheckpointThreshouldSecondsStart } from "../../../../const/const";
import { timeStamp } from "console";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  thumbnail: {
    maxWidth: "100%",
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  listContainer: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  video: {
    height: "100%",
    width: "100%",
  },
  timespanSlectionControls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  flippedButton: {
    transform: "rotateY(180deg)",
  },
  error: {
    color: "#e53935",
    marginLeft: 14,
    marginRight: 14,
    fontSize: 14,
    marginTop: 3,
    textAlign: "left",

    fontWeight: 400,
    lineheight: 13,
    letterSpacing: 0.33,
  },
  info: {
    display: "flex",
    alignItems: "center",
    padding: "2px 12px !important",
  },
}));

type Props = RouteComponentProps<any> & {
  classes: any;
  breadcrumbs: any[];
  title: string;
};

type Params = {
  channelId: string;
  classId: string;
};

export default function CreateItemView(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const poseRef = useRef<any>();

  const [isUiDisabled, setIsUiDisabled] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [keypoints3d, setKeypoints3d] = useState<Keypoint3D[]>();
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [duration, setDuration] = useState<number>();
  const [step, setStep] = useState<number>();
  const [regions, setRegions] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const [checkpointName, setCheckpointName] = useState<string>();
  const [checkpoints, setCheckpoints] = useState<
    ChannelClassSequenceCheckpoint[]
  >([]);
  const [filePath, setFilePath] = useState<string>();
  const { channelId, classId } = useParams<Params>();

  useEffect(() => {
    const startMediapipe = async () => {
      const onLandmarks = async (results: any) => {
        const { poseLandmarks, image } = results;

        if (poseLandmarks) {
          const downscale = (height: number, width: number) => {
            const getScaleMultiplier = (
              height: number,
              width: number
            ): number => {
              const maxValue = 550;
              if (height >= width) {
                return maxValue / height;
              }
              return maxValue / width;
            };

            const scaleMultiplier = getScaleMultiplier(height, width);
            return {
              scaledWidth: width * scaleMultiplier,
              scaledHeight: height * scaleMultiplier,
            };
          };

          const imageHeight = image.height;
          const imageWidth = image.width;

          const canvasElement = canvasRef.current;
          if (canvasElement) {
            const { scaledHeight, scaledWidth } = downscale(
              imageHeight,
              imageWidth
            );
            canvasElement.width = scaledWidth;
            canvasElement.height = scaledHeight;
            drawOriginal(poseLandmarks, image, canvasElement);
          }

          const mapped = mediapipeKeypoint.mapForAnalysis(
            mediapipeKeypoint.denormalize(
              poseLandmarks,
              imageWidth,
              imageHeight
            )
          );
          setKeypoints3d(mapped);
        }
        setIsUiDisabled(false);
      };
      const pose = await mediapipeService.getStaticModel();
      pose.onResults(onLandmarks);
      poseRef.current = pose;
    };

    const loadData = async () => {
      const { name, checkpoints, file_path, description } =
        await channelClassSequenceProvider.get(classId);
      setName(name);
      //TODO: generate missing start and end time

      const sortedCheckpoints = checkpoints
        .sort((a, b) => {
          return a.timestamp - b.timestamp;
        })
        //HACK: replace with calculation if needed
        .map((cp) => {
          if (cp.startTime === undefined) {
            cp.startTime = cp.timestamp - 1;
          }
          if (cp.endTime === undefined) {
            cp.endTime = cp.timestamp + 1;
          }
          return cp;
        });
      setCheckpoints(sortedCheckpoints);
      setDescription(description);
      setFilePath(file_path);
    };

    const init = async () => {
      try {
        await loadData();
        await startMediapipe();
        setIsUiDisabled(false);
      } catch (err) {
        //TODO: handle initialization error
        console.log("componentDidMount:err");
        console.log(err);
        setIsUiDisabled(false);
      }
    };
    init();
  }, []);
 
  const findPrevCheckpoint = (
    timestamp: number,
    checkpoints: ChannelClassSequenceCheckpoint[]
  ) => {
    const reversedCheckpoints = [...checkpoints]
      .sort((a, b) => {
        return a.timestamp - b.timestamp;
      })
      .reverse();
    for (let index = 0; index < reversedCheckpoints.length; index++) {
      const checkpoint = reversedCheckpoints[index];
      //TODO: if checkpoint.endTime === timestamp
      //THROW: error
      if (checkpoint.timestamp < timestamp) {
        return checkpoint;
      }
    }
    return undefined;
  };

  const findNextCheckpoint = (
    timestamp: number,
    checkpoints: ChannelClassSequenceCheckpoint[]
  ) => {
    const reversedCheckpoints = [...checkpoints].sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
    for (let index = 0; index < reversedCheckpoints.length; index++) {
      const checkpoint = reversedCheckpoints[index];
      if (checkpoint.timestamp > timestamp) {
        return checkpoint;
      }
    }
    return undefined;
  };


  const onAddCheckpoint = async () => {
    //TODO: validate time crossing
    if (keypoints3d && keypoints3d.length !== 0 && checkpointName) {
      //TODO: rearrange borders
      const prevCheckpoint = findPrevCheckpoint(sliderValue, checkpoints);
      const nextCheckpoint = findNextCheckpoint(sliderValue, checkpoints);

      if (prevCheckpoint && sliderValue - prevCheckpoint.timestamp < 3) {
        //TODO: display error, checkpoint to close
        alert(
          "The selected checkpoint time is to close to existing checkpoint. Please select different time."
        );
        return;
      }
      if (nextCheckpoint && nextCheckpoint.timestamp - sliderValue < 3) {
        alert(
          "The selected checkpoint time is to close to existing checkpoint. Please select different time."
        );
        return;
      }

      const getEnd = (timestamp: number) => {
        if (nextCheckpoint) {
          return nextCheckpoint.startTime - 1;
        }
        if (videoRef.current) {
          return videoRef.current.duration;
        } else {
          return timestamp;
        }
      };

      const getStart = (timestamp: number) => {
        const startTime = timestamp - defaultCheckpointThreshouldSecondsStart;
        return startTime < 0 ? 0 : startTime;
      };

      const startTime = getStart(sliderValue);
      if (prevCheckpoint) {
        const prevIndex = checkpoints.findIndex(
          (x) => x.timestamp === prevCheckpoint.timestamp
        );

        checkpoints[prevIndex].endTime = startTime - 1;
      }

      const checkpoint = {
        keypoints: keypoints3d.map((kp) => {
          return {
            x: round(kp.x),
            y: round(kp.y),
            z: round(kp.z),
            part: kp.part,
          };
        }),
        timestamp: sliderValue,
        regions: regions,
        name: checkpointName,
        startTime: startTime, // if previous exists check 1 if within defaultCheckpointThreshouldSecondsStart 2 check if withing start      ///TODO: calculatw,
        endTime: getEnd(sliderValue), //TODO: calculate
      };

      const newCheckpoints = [...checkpoints, checkpoint];
      setCheckpoints(
        newCheckpoints.sort((a, b) => {
          return a.timestamp - b.timestamp;
        })
      );
      setCheckpointName("");
      setKeypoints3d([]);
      setRegions({});
      setSubmitted((prevValue: any) => ({
        ...prevValue,
        checkpoints: false,
      }));
    }
  };

  const onSave = async () => {
    const formData = {
      name: name,
      checkpoints: checkpoints.length !== 0 ? checkpoints : undefined,
    };

    const schema = {
      name: {
        presence: { allowEmpty: false, message: "is required" },
      },
      checkpoints: {
        presence: { allowEmpty: false, message: "are required" },
      },
    };

    const errors = validate(formData, schema);
    if (errors) {
      setErrors(errors);
      setSubmitted({
        name: true,
        checkpoints: true,
      });
      return;
    }

    setIsUiDisabled(true);

    const model = {
      id: classId,
      name: name,
      checkpoints: checkpoints,
      description: description,
    };
    try {
      await channelClassSequenceProvider.edit(model);
      history.goBack();
    } catch (ex) {
      //TODO: switch to toaster
      alert("Unable to process image, please select different one.");
    }
    setIsUiDisabled(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value as string);
    setSubmitted((prevState: any) => ({
      ...prevState,
      name: false,
    }));
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value as string);
  };

  const handleCheckpointNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckpointName(event.target.value as string);
  };

  const onLoadedMetadata = () => {
    const video = videoRef.current;
    if (video) {
      const duration = video.duration;
      const step = duration / 100;
      setDuration(duration);
      setStep(step);
      setIsUiDisabled(false);
    }
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const sliderValue = newValue as number;
    setSliderValue(sliderValue);
    const video = videoRef.current;
    if (video) {
      video.currentTime = sliderValue;
    }
  };

  const onGenerate = async () => {
    setIsUiDisabled(true);
    const video = videoRef.current;
    const pose = poseRef.current;
    if (video && pose) {
      try {
        const canvas = drawVideoOnCanvas(video);
        const image = await getImageFromCanvas(canvas);
        await pose.send({ image: image });
      } catch (err) {
        setIsUiDisabled(false);
        console.log(err);
      }
    } else {
      setIsUiDisabled(false);
    }
  };

  const onMoveBackFast = () => {
    let currentTime = sliderValue - 0.4;
    if (currentTime < 0) {
      currentTime = 0;
    }
    setSliderValue(currentTime);
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveBack = () => {
    let currentTime = sliderValue - 0.02;
    if (currentTime < 0) {
      currentTime = 0;
    }
    setSliderValue(currentTime);
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveForward = () => {
    let currentTime = sliderValue + 0.02;
    if (videoRef && videoRef.current) {
      if (currentTime > videoRef.current.duration) {
        currentTime = videoRef.current.duration;
      }
      setSliderValue(currentTime);
      videoRef.current.currentTime = currentTime;
    }
  };

  const onMoveForwardFast = () => {
    let currentTime = sliderValue + 0.4;
    if (videoRef && videoRef.current) {
      if (currentTime > videoRef.current.duration) {
        currentTime = videoRef.current.duration;
      }
      setSliderValue(currentTime);
      videoRef.current.currentTime = currentTime;
    }
  };

  const onToggleRegion = (key: string) => {
    if (regions[key]) {
      regions[key] = false;
    } else {
      regions[key] = true;
    }
    setRegions({ ...regions });
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  const removeCheckpoint = (data: any) => {
    setCheckpoints(checkpoints.filter((cp, index) => index !== data.index));
  };

  const onMoveStartTimeBack = (index: number) => {
    const newCheckpoints = [...checkpoints];
    const newStart = newCheckpoints[index].startTime - 1;
    if (index === 0) {
      newCheckpoints[index].startTime = newStart < 0 ? 0 : newStart;
    } else {
      const prevCheckpoint = newCheckpoints[index - 1];
      if (newStart > prevCheckpoint.endTime) {
        newCheckpoints[index].startTime = newStart;
      }
    }
    setCheckpoints(newCheckpoints);
  };

  const onMoveStartTimeForward = (index: number) => {
    const newCheckpoints = [...checkpoints];
    const newStart = newCheckpoints[index].startTime + 1;
    const videoElement = videoRef.current;
    if (videoElement) {
      const currentCheckpoint = checkpoints[index];
      if (newStart < currentCheckpoint.timestamp) {
        newCheckpoints[index].startTime = newStart;
      }
      setCheckpoints(newCheckpoints);
    }
  };

  const onMoveEndTimeBack = (index: number) => {
    const newCheckpoints = [...checkpoints];
    const newEndTime = newCheckpoints[index].endTime - 1;
    const currentCheckpoint = checkpoints[index];
    if (newEndTime > currentCheckpoint.timestamp) {
      newCheckpoints[index].endTime = newEndTime;
      setCheckpoints(newCheckpoints);
    }
  };

  const onMoveEndTimeForward = (index: number) => {
    const newCheckpoints = [...checkpoints];
    const newEndTime = newCheckpoints[index].endTime + 1;
    const videoElement = videoRef.current;
    if (videoElement) {
      if (index === checkpoints.length - 1) {
        newCheckpoints[index].endTime =
          newEndTime > videoElement.duration
            ? videoElement.duration
            : newEndTime;
      } else {
        const nextCheckpoint = checkpoints[index + 1];
        if (newEndTime < nextCheckpoint.startTime) {
          newCheckpoints[index].endTime = newEndTime;
        }
      }
      setCheckpoints(newCheckpoints);
    }
  };

  const { title, breadcrumbs } = props;
  const data = channelId ? { channelId: channelId } : undefined;
  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h4" gutterBottom>
        {title}
      </Typography>
      <BreadcrumbsContainer
        breadcrumbs={breadcrumbs}
        title={title}
        data={data}
      />
      <Divider />
      {isUiDisabled && <LinearProgress />}
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Name"
              value={name}
              onChange={handleNameChange}
              disabled={isUiDisabled}
              helperText={hasError("name") ? errors["name"][0] : null}
              error={hasError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Description"
              multiline
              rows="4"
              value={description}
              disabled={isUiDisabled}
              onChange={handleDescriptionChange}
            />
          </Grid>
          {filePath && (
            <Grid item xs={12}>
              <video
                className={classes.video}
                ref={videoRef}
                playsInline
                src={filePath}
                crossOrigin="anonymous"
                onLoadedMetadata={onLoadedMetadata}
              ></video>
            </Grid>
          )}
          {duration && sliderValue !== undefined && (
            <Grid item xs={12}>
              <Grid item xs={12} className={classes.timespanSlectionControls}>
                <IconButton
                  aria-label={"Move fast forward"}
                  onClick={onMoveBackFast}
                  className={`${classes.flippedButton}`}
                  disabled={isUiDisabled}
                >
                  <FastForwardIcon />
                </IconButton>
                <IconButton aria-label={"Move back"} onClick={onMoveBack}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography>{secondsToTimeString(sliderValue)}</Typography>
                <IconButton aria-label={"Move forward"} onClick={onMoveForward}>
                  <KeyboardArrowRightIcon />
                </IconButton>
                <IconButton
                  aria-label={"Move fast forward"}
                  onClick={onMoveForwardFast}
                  disabled={isUiDisabled}
                >
                  <FastForwardIcon />
                </IconButton>
              </Grid>
              <Slider
                track={false}
                value={sliderValue}
                aria-labelledby="time-slider"
                valueLabelDisplay="off"
                step={step}
                min={0}
                max={duration}
                onChange={handleSliderChange}
                disabled={isUiDisabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Slider
              track={false}
              value={checkpoints.map((x) => x.timestamp)}
              aria-labelledby="time-slider"
              valueLabelDisplay="off"
              step={step}
              disabled={true}
              min={0}
              max={duration}
              marks={checkpoints.map((x, index) => {
                return { value: x.timestamp, label: `#${index + 1}` };
              })}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <CombinedSlider
              duration={duration}
              step={step}
              checkpoints={checkpoints}
            />
          </Grid> */}
          {filePath && checkpoints && checkpoints.length > 0 && (
            <Checkpoints
              checkpoints={checkpoints}
              onRemoveClickHandler={removeCheckpoint}
              onMoveStartTimeBack={onMoveStartTimeBack}
              onMoveStartTimeForward={onMoveStartTimeForward}
              onMoveEndTimeBack={onMoveEndTimeBack}
              onMoveEndTimeForward={onMoveEndTimeForward}
              filePath={filePath}
              duration={duration}
              step={step}
            />
          )}
          <Grid item xs={12}>
            <p className={classes.error}>
              {hasError("checkpoints") ? errors["checkpoints"][0] : ""}
            </p>
          </Grid>
          {duration && sliderValue !== undefined && (
            <Grid item xs={12}>
              <Button
                disabled={isUiDisabled}
                onClick={onGenerate}
                variant="contained"
                color="primary"
              >
                Generate avatar data
              </Button>
            </Grid>
          )}
          {keypoints3d && keypoints3d.length > 0 && (
            <Grid item xs={12}>
              <Pose3dEditor
                height={550}
                width={550}
                keypoints={keypoints3d}
                avatarType={AvatarType.Female}
              />
            </Grid>
          )}

          <Grid
            style={{
              visibility:
                keypoints3d && keypoints3d.length > 0 ? "visible" : "hidden",
            }}
            item
            xs={12}
          >
            {/* TODO: hide when no data or on image update*/}
            <canvas ref={canvasRef}></canvas>
          </Grid>
          {keypoints3d && keypoints3d.length > 0 && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 16 }}>
                  Regions of interest
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.info}>
                <InfoIcon style={{ color: "#00bcd4", margin: "5px" }} />
                <Typography>
                  If no regions will be selected, all regions will be used for
                  calculations.
                </Typography>
              </Grid>
              <HumanRegions
                selectedRegions={regions}
                onRegionSelection={onToggleRegion}
              />
            </Grid>
          )}

          {keypoints3d && keypoints3d.length > 0 && (
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Checkpoint Name"
                value={checkpointName}
                onChange={handleCheckpointNameChange}
              />
            </Grid>
          )}
          {keypoints3d && keypoints3d.length > 0 && (
            <Grid item xs={12}>
              <Button
                onClick={onAddCheckpoint}
                variant="contained"
                color="primary"
                disabled={
                  !checkpointName || !checkpointName.trim() ? true : false
                }
              >
                Add checkpoint
              </Button>
            </Grid>
          )}
          <Grid
            style={{ display: "flex", justifyContent: "space-between" }}
            item
            xs={12}
          >
            <Button
              disabled={isUiDisabled}
              onClick={history.goBack}
              variant="contained"
            >
              Go Back
            </Button>
            <Button
              disabled={isUiDisabled}
              onClick={onSave}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}
