import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import * as authProvider from "../../../providers/common/auth.provider";
import validate from "validate.js";
import { useHistory, useLocation } from "react-router-dom";
import { confirmPassword, passwordComplexity } from "../../../validators";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={`${window.location.origin}`}>
        Wizhero
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "red",
  },
}));

validate.validators.confirmPassword = confirmPassword;

validate.validators.passwordComplexity = passwordComplexity;

const schema = {
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
      maximum: 128,
    },
    passwordComplexity: {},
  },
  confirmPassword: {
    confirmPassword: {},
  },
};

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [errors, setErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>({});

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setPassword(value);
    setSubmitted({
      ...submitted,
      password: false,
    });
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as string;
    setConfirmPassword(value);
    setSubmitted({
      ...submitted,
      confirmPassword: false,
    });
  };

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const values = {
      password,
      confirmPassword,
    };
    const errors = validate(values, schema);
    if (errors) {
      //TODO: set erors
      setErrors(errors);
      setSubmitted({
        password: true,
        confirmPassword: true,
      });
      return;
    }
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get("code");
      const userId = searchParams.get("userId");
      if (code && userId) {
        const result = await authProvider.resetPassword({
          code: code,
          userId: userId,
          password: password,
          confirmPassword: confirmPassword,
        });
        if (Object.keys(result.errors).length !== 0) {
          const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toLowerCase() + string.slice(1);
          };
          //TODO: display error messages
          const pageErrors = { ...errors };
          const pageSubmitted = { ...submitted };

          const errorKeys = Object.keys(result.errors);
          if (errorKeys.indexOf("Form") === -1) {
            for (let index = 0; index < errorKeys.length; index++) {
              const key = errorKeys[index];
              const error = result.errors[key];
              const keyLower = capitalizeFirstLetter(key);

              pageErrors[keyLower] = [error];
              pageSubmitted[keyLower] = true;
            }

            setErrors(pageErrors);
            setSubmitted(pageSubmitted);

            setPassword("");
            setConfirmPassword("");

            setErrorMessage("");
          } else {
            setErrors({});
            setSubmitted({});

            setPassword("");
            setConfirmPassword("");

            setErrorMessage(result.errors["Form"]);
          }
        } else {
          //TODO: show congratulation message
          history.push(`/reset-password-success`);
        }
      } else {
        setLoading(false);
      }
    } catch (ex) {
      console.log(ex);
      setErrorMessage("An error occurred while processing your request");

      setPassword("");
      setConfirmPassword("");
      setLoading(false);
    }
  };

  const hasError = (field: string) => {
    return submitted[field] && errors[field] ? true : false;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Forgot password?
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={hasError("password")}
                fullWidth
                helperText={
                  hasError("password")
                    ? errors.password[0]
                    : "8 or more characters, 1 uppercase, 1 lowercase, 1 number"
                }
                label="Password"
                name="password"
                onChange={handlePasswordChange}
                type="password"
                value={password || ""}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasError("confirmPassword")}
                fullWidth
                helperText={
                  hasError("confirmPassword") ? errors.confirmPassword[0] : null
                }
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleConfirmPasswordChange}
                type="password"
                value={confirmPassword || ""}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <p className={classes.error}>{errorMessage}</p>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
            onClick={handleResetPassword}
          >
            Reset password
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
