import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";
import { ClassCreateTimespan } from "../../types/class/class-create-timespan";

export type ChannelClassOption = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  is_approved: boolean;
  is_published: boolean;
};


export type ChannelClass = {
  name: string;
  description: string;
  file_path: string;
  thumbnail_path: string;
  timespans: ClassCreateTimespan[];
};

export const getAll = async (): Promise<Array<ChannelClassOption>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class/getAll",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getAllSearch = async (pager: any, filter: any): Promise<{
  records: Array<ChannelClassOption>;
  total: number;
}> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class/getAllSearch",
    baseURL: apiUrlBase,
    data: {
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        name: filter["name"],
      },
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

// export const approve = async (model: any): Promise<any> => {
//   const config: AxiosRequestConfig = {
//     method: "post",
//     url: "api/admin/channel/class/approve",
//     baseURL: apiUrlBase,
//     data: {
//       id: model.id,
//       user_id: authService.getAuthState().id,
//     },
//   };
//   const response = await axios(config);
//   return response.data;
// };


export const edit = async (item: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class/update",
    data: {
      id: item.classId,
      name: item.name,
      timespans: item.timespans,
      description: item.description,
      user_id: authService.getAuthState().id,
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<ChannelClass> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};


export const toggleUnpublish = async (id: string, isPublished: boolean): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/class/toggle_unpublish",
    data: {
      id: id,
      user_id: authService.getAuthState().id,
      is_published: isPublished
    },
    baseURL: apiUrlBase,
  };
  const response = await axios(config);
  return response;
};