import React, { useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import * as pose3dService from "../../../../../services/pose3d.service";
import * as THREE from "three";
import { Keypoint3D } from "../../../../../types/analyze/keypoint3d.type";
import * as avatarService from "../../../../../services/avatar/avatar.service";
import { AvatarType } from "../../../../../services/avatar/avatar-type.enum";
import {
  getFlippedDisplayKeypoints,
  mapKeypoints,
} from "../../../../../services/keypoint.helper";
import { ClassView } from "../../../../../types/threejs/class-view.type";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 600,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  canvas: {
    height: "100%",
    width: "100%",
  },
}));
type Props = {
  studentKeypoints: Keypoint3D[];
  instructorKeypoints: Keypoint3D[];
};

const studentRootName = "root-student";
const instructorRootName = "root-instructor";

const avatarType = AvatarType.Male;

export default function SelectedPoint(props: Props) {
  const controlsRef = useRef<OrbitControls>();

  const classes = useStyles();
  const { studentKeypoints, instructorKeypoints } = props;

  const displayStudentKeypoints = getFlippedDisplayKeypoints(
    mapKeypoints(studentKeypoints)
  );
  const displayInstructorKeypoints = mapKeypoints(instructorKeypoints);

  const viewRef = useRef<ClassView>();
  const rendererRef = useRef<THREE.WebGLRenderer>();

  const mountRef = useRef<HTMLDivElement>(null);

  const sceneRef = useRef<THREE.Scene>();

  const applyDisplayKeypoints = (
    keypoints: Keypoint3D[],
    name: string,
    scene: THREE.Scene
  ) => {
    const root = scene.getObjectByName(name);
    if (root && keypoints.length !== 0) {
      const displayKeypoints = getFlippedDisplayKeypoints(keypoints);
      avatarService.poseUpdate(root, displayKeypoints);
      const verticalShift = avatarService.getVerticalShift(root);
      avatarService.placeOnTheFloor(root, verticalShift);
    }
  };

  const animate = () => {
    requestAnimationFrame(animate);

    const controls = controlsRef.current;
    if (controls) {
      controls.update();
    }

    const scene = sceneRef.current;
    const mount = mountRef.current;
    const renderer = rendererRef.current;
    const view = viewRef.current;
    if (mount && scene && renderer && view && view.camera) {
      pose3dService.renderCourseThree(mount, scene, renderer, view);
    }
  };

  useEffect(() => {
    const init = async () => {
      const getScene = (): THREE.Scene => {
        const scene = pose3dService.sceneSetup();
        return scene;
      };

      sceneRef.current = getScene();
      const mount = mountRef.current;
      const scene = sceneRef.current;
      if (!mount) {
        throw new Error("Mount not available");
      }

      const renderer = pose3dService.getCourseRenderer(mount);
      rendererRef.current = renderer;

      const view = pose3dService.getCourseView(mount);
      viewRef.current = view;

      const addStudentModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = studentRootName;
        scene.add(model);
        avatarService.init(model);
      };

      const addInstructorModel = async () => {
        const model = await avatarService.getModel(avatarType);
        model.name = instructorRootName;
        model.traverse((object: any) => {
          if (object.isMesh) {
            object.material.transparent = true;
            object.material.opacity = 0.5;
            object.material.color = new THREE.Color(0x00ff00);
          }
        });
        model.position.z = 2;
        scene.add(model);
        avatarService.init(model);
      };

      await addStudentModel();
      await addInstructorModel();

      applyDisplayKeypoints(displayStudentKeypoints, studentRootName, scene);
      applyDisplayKeypoints(
        displayInstructorKeypoints,
        instructorRootName,
        scene
      );

      pose3dService.renderCourseThree(mount, scene, renderer, view);

      const controls = new OrbitControls(view.camera, renderer.domElement);
      controls.maxPolarAngle = Math.PI * 0.4;
      controls.minPolarAngle = Math.PI * 0.4;
      controls.enableZoom = false;
      controls.enablePan = false;

      controlsRef.current = controls;
      animate();
    };

    init();
    return () => {};
  }, []);

  useEffect(() => {
    const scene = sceneRef.current;
    const mount = mountRef.current;
    const renderer = rendererRef.current;
    const view = viewRef.current;
    if (scene && mount && renderer && view) {
      applyDisplayKeypoints(displayStudentKeypoints, studentRootName, scene);
      applyDisplayKeypoints(
        displayInstructorKeypoints,
        instructorRootName,
        scene
      );
      pose3dService.renderCourseThree(mount, scene, renderer, view);
    }
  }, [studentKeypoints]);
  return (
    <div className={classes.container}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: 28,
              width: 28,
              borderRadius: 10,
              backgroundColor: "#377588",
            }}
          ></div>
          <span style={{ padding: 15 }}>Student</span>
        </div>
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: 28,
              width: 28,
              borderRadius: 10,
              backgroundColor: "#94ff94",
            }}
          ></div>
          <span style={{ padding: 15 }}>Instructor</span>
        </div>
      </div>
      <div className={classes.canvas} ref={mountRef}></div>
    </div>
  );
}
