export default function confirmPassword(
  value: any,
  options: any,
  key: any,
  attributes: any
) {
  if (key === "confirmPassword" && attributes["password"] !== value) {
    return "does not match";
  }
  return undefined;
}