import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { withRouter } from "react-router-dom";

import logo from "../../../../assets/images/wizhero_logo_low res_v2.png";
import * as authService from "../../../../services/auth.service";
import Profile from "./components/Profile";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: "rgb(40, 40, 40)",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    transform: "rotateY(180deg)",
  },
  signInButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    height: 50,
    borderRadius: 20,
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, staticContext, ...rest } = props;

  const classes = useStyles();

  const isAuthenticated = authService.isAuthenticated();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            className={clsx(classes.logo, className)}
            alt="Logo"
            src={logo}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>{isAuthenticated && <Profile />}</Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        {isAuthenticated ? (
          <IconButton
            title="Sign Out"
            onClick={() => {
              authService.logout();
              props.history.push("/default");
            }}
            className={classes.signInButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              props.history.push("/login");
            }}
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
