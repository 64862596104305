import { makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TimespanClassType } from "../../../types/analyze/timespan-class-type.enum";
import {
  ButtonContainer,
  Feedback,
} from "./Components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  leftSide: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "50%",
    backgroundColor: "rgba(27, 27, 27, 0.7)",
  },
  rightSide: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "50%",
    backgroundColor: "rgb(27, 27, 27)",
  },
  countdown: {
    position: "absolute",
    margiLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    top: "50%",
  },
}));

type Props = {
  isLandmarksWithinImage: boolean;
  isClassStarted: boolean;
  onCanStartPlayback: () => void;
  onExit: () => void;
  isFullScreenEnabled: boolean;
  toggleFullScreen: () => void;
  isInstructorVideoMuted: boolean;
  toggleMute: () => void;
  isInstructorVideoPlaying: boolean;
  togglePlay: () => void;
  timespanClassType: TimespanClassType;
  feedback?: string;
};

const AnalysisOverlayDefault = (props: Props) => {
  const classes = useStyles();
  const {
    isLandmarksWithinImage,
    isClassStarted,
    onCanStartPlayback,
    onExit,
    isInstructorVideoMuted,
    toggleMute,
    isInstructorVideoPlaying,
    togglePlay,
    isFullScreenEnabled,
    toggleFullScreen,
    timespanClassType,
    feedback,
  } = props;

  const [displayIntroMessage, setDisplayIntroMessage] =
    useState<boolean>(false);
  const [displayInFrameMessage, setDisplayInFrameMessage] =
    useState<boolean>(false);

  useEffect(() => {
    const onChange = (
      isClassStarted: boolean,
      isLandmarksWithinImage: boolean
    ) => {
      if (isClassStarted === true) {
        if (isLandmarksWithinImage) {
          setDisplayInFrameMessage(true);
        } else {
          setDisplayIntroMessage(true);
        }
      }
    };
    onChange(isClassStarted, isLandmarksWithinImage);
  }, [isClassStarted]);

  useEffect(() => {
    const onChange = (
      isLandmarksWithinImage: boolean,
      displayIntroMessage: boolean
    ) => {
      if (displayIntroMessage === true && isLandmarksWithinImage === true) {
        setDisplayIntroMessage(false);
        setDisplayInFrameMessage(true);
      }
    };
    onChange(isLandmarksWithinImage, displayIntroMessage);
  }, [isLandmarksWithinImage]);

  useEffect(() => {
    const onChange = (displayInFrameMessage: boolean) => {
      if (displayInFrameMessage === true) {
        const delay = 3 * 1000;
        setTimeout(() => {
          onCanStartPlayback();
          setDisplayInFrameMessage(false);
        }, delay);
      }
    };
    onChange(displayInFrameMessage);
  }, [displayInFrameMessage]);

  const introMessage =
    "Take a few steps back until you see your full body in the screen";
  const inoFrameMessage = "Thats the spot! \nNow lets start!";

  return (
    <div className={classes.root}>
      {!displayInFrameMessage &&
        !displayIntroMessage &&
        !isLandmarksWithinImage &&
        isClassStarted && (
          <ButtonContainer
            isFullScreenEnabled={isFullScreenEnabled}
            toggleFullScreen={toggleFullScreen}
            isInstructorVideoMuted={isInstructorVideoMuted}
            toggleMute={toggleMute}
            isInstructorVideoPlaying={isInstructorVideoPlaying}
            togglePlay={togglePlay}
            onExit={onExit}
          />
        )}
      {displayInFrameMessage && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        >
          <div
            style={{
              color: "white",
              padding: 15,
              fontSize: 100,
              fontWeight: 700,
            }}
          >
            {inoFrameMessage}
          </div>
        </div>
      )}
      {displayIntroMessage && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        >
          <div
            style={{
              color: "white",
              padding: 15,
              fontSize: 100,
              fontWeight: 700,
            }}
          >
            {introMessage}
          </div>
        </div>
      )}    
      {!isClassStarted && (
        <div
          className={classes.rightSide}
          style={{ backgroundColor: "rgb(27, 27, 27)" }}
        ></div>
      )}   
      {timespanClassType === TimespanClassType.Timespan && (
        <Feedback feedback={feedback} />
      )}
    </div>
  );
};

export default AnalysisOverlayDefault;
