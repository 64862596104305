import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardActionArea, CardContent, CardMedia, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import defaultThumbnail from '../../../../../assets/images/channel-thumbnail.png';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const useStyles = makeStyles(theme => ({
  media: {
    height: 200,
    position: 'relative',
  },
  card: {
    position: 'relative',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  publishIcon: {
    position: 'absolute',
    bottom: 10,
    right: 10
  },
  publishedIcon: {
    color: 'green'
  },
  unpublishedIcon: {
    color: 'red'
  }
}));

const ChannelCard = props => {
  const { className, channel, onRemove, onUnpublish, ...rest } = props;
  const classes = useStyles();
  return (
    <Card 
      {...rest}
      className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={channel.thumbnail_path || defaultThumbnail}
          title={channel.name}
        >
        {
          channel.is_published 
          ? <CheckBoxIcon title='Published' className={`${classes.publishIcon} ${classes.publishedIcon}`}/>
          : <IndeterminateCheckBoxIcon title='Not published' className={`${classes.publishIcon} ${classes.unpublishedIcon}`}/>
        }
        </CardMedia>     
        <CardContent>        
          <Typography className={classes.name} title={channel.name} gutterBottom variant="h3" component="h2">
            {channel.name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>       
        <Button size="small" color="secondary" onClick={() => onRemove(channel.id)}>
            Remove
        </Button>
        <Button size="small" color="secondary" onClick={() => onUnpublish(channel.id)}>
            Unpublish
        </Button>
      </CardActions>
    </Card>
  );
};

ChannelCard.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUnpublish: PropTypes.func.isRequired
};

export default ChannelCard;