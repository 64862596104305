import axios, { AxiosRequestConfig } from "axios";
import * as authService from "../../services/auth.service";
import { apiUrlBase } from "../../const/const";

export type InstructorListItem = {
  id: string;
  name: string;
};

export type VideoDetails = {
  file_path: string;
  thumbnail_path: string;
};

export type ChannelVideoItem = {
  id: string;
  name: string;
  creation_date: string;
  thumbnail_path: string;
  is_approved: boolean;
  instructor: string;
};

export const getAll = async (pager: any, filter: any): Promise<{
  records: Array<ChannelVideoItem>;
  total: number;
}> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/video/getAll",
    baseURL: apiUrlBase,
    data: {
      pager: {
        page_number: pager.pageNumber,
        page_size: pager.pageSize,
      },
      filter: {
        instructor_id: filter["instructorId"],
      },
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const getInstructors = async (): Promise<Array<InstructorListItem>> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/video/getInstructors",
    baseURL: apiUrlBase,
    data: {
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const update = async (model: any): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/video/update",
    baseURL: apiUrlBase,
    data: {
      id: model.id,
      is_approved: model.isApproved,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};

export const get = async (id: string): Promise<VideoDetails> => {
  const config: AxiosRequestConfig = {
    method: "post",
    url: "api/admin/channel/video/get",
    baseURL: apiUrlBase,
    data: {
      id: id,
      user_id: authService.getAuthState().id,
    },
  };
  const response = await axios(config);
  return response.data;
};
