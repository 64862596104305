import axios from "axios";
import * as authenticationSerivce from "./auth.service";

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const configureInterceptors = (history: any): void => {
  axios.interceptors.request.use(
    (config) => {
      //TODO: check if set default header instead of storage retrieval
      const state = authenticationSerivce.getAuthState();
      if (state.accessToken) {
        config.headers["Authorization"] = `Bearer ${state.accessToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      console.log("axios error");
      console.log(error);

      if (error.response && error.response.status === 401) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              //TODO: check if needed
              //originalRequest._retry = false;
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          authenticationSerivce
            .refreshToken()
            .then(({ accessToken }) => {
              originalRequest.headers["Authorization"] =
                "Bearer " + accessToken;
              processQueue(null, accessToken);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              authenticationSerivce.logout();
              history.push("/login");
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );
};
