import * as THREE from "three";
import { cleanupObject } from "../../../services/pose3d.service";
import { ClassPracticeAnalysisBlock } from "../../../types/class-practice/class-practice-analysis-block";
import { CourseAnalysisTimespan } from "../../../types/class/class-analysis-timespan";

export const getStudentBackground = (
  backgroundColor: THREE.Color,
  height: number,
  halfWidth: number
) => {
  const material = new THREE.MeshBasicMaterial({
    color: backgroundColor,
  });
  const width = halfWidth * 2;
  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);
  mesh.position.set(-halfWidth - 0.01, height / 2, 0);
  mesh.rotation.y = Math.PI / 2;
  mesh.name = "InstructorVideoBackground";
  return mesh;
};

export const getInstructorBackground = (
  backgroundColor: THREE.Color,
  height: number,
  halfWidth: number
) => {
  const material = new THREE.MeshBasicMaterial({
    color: backgroundColor,
  });
  const width = halfWidth * 2;
  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);
  mesh.position.set(0, height / 2, halfWidth + 0.01);
  mesh.rotation.y = Math.PI;
  mesh.name = "InstructorPoseBackground";
  return mesh;
};

export const getTextBackground = (
  box: THREE.Box3,
  zTextPosition: number
): THREE.Mesh => {
  const material = new THREE.MeshBasicMaterial({
    color: 0x5a5a5a,
    transparent: true,
    opacity: 0.15,
  });
  const width = box.max.x - box.min.x;
  const height = box.max.y - box.min.y;
  const center = new THREE.Vector3();
  box.getCenter(center);
  const geometry = new THREE.PlaneGeometry(width + 0.15, height + 0.1);
  const mesh = new THREE.Mesh(geometry, material);
  mesh.position.set(center.z - 0.05, center.y + 0.02, zTextPosition + 0.001);
  mesh.rotation.y = Math.PI;
  return mesh;
};

export const getStudentVideoMesh = (
  texture: THREE.CanvasTexture,
  video: HTMLCanvasElement,
  height: number,
  halfWidth: number
) => {
  const material = new THREE.MeshBasicMaterial({
    map: texture,
  });
  const aspectRatio = video.width / video.height;
  const localHeight = height / 3;
  const width = localHeight * aspectRatio;
  const geometry = new THREE.PlaneGeometry(width, localHeight);
  const mesh = new THREE.Mesh(geometry, material);

  const xPosition = halfWidth - width / 2;
  const yPosition = localHeight / 2;

  const { x, y, z } = new THREE.Vector3(xPosition, yPosition, halfWidth - 0.01);
  mesh.position.set(x, y, z);
  mesh.rotation.y = Math.PI;
  mesh.name = "StudentVideo";

  const backgroundMaterial = new THREE.MeshBasicMaterial({
    color: "#ffffff",
  });

  const backgroundGeometry = new THREE.PlaneGeometry(
    width + 0.05,
    localHeight + 0.05
  );

  const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
  backgroundMesh.position.set(xPosition, yPosition + 0.005, halfWidth - 0.005);
  backgroundMesh.rotation.y = Math.PI;
  backgroundMesh.name = "StudentVideoBackground";

  return { mesh: mesh, backgroundMesh: backgroundMesh };
};

export const getInstructorVideoMesh = (
  instructorTexture: THREE.VideoTexture,
  instructorVideo: HTMLVideoElement,
  height: number,
  halfWidth: number
): THREE.Mesh => {
  const material = new THREE.MeshBasicMaterial({
    map: instructorTexture,
  });
  const aspectRatio = instructorVideo.videoWidth / instructorVideo.videoHeight;
  const width = height * aspectRatio;
  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);
  const { x, y, z } = new THREE.Vector3(-halfWidth, height / 2, 0);
  mesh.position.set(x, y, z);
  mesh.rotation.y = Math.PI / 2;
  mesh.name = "InstructorVideo";
  return mesh;
};

export const getSequenceInstructorVideoMesh = (
  instructorTexture: THREE.VideoTexture,
  instructorVideo: HTMLVideoElement,
  height: number,
  halfWidth: number
): THREE.Mesh => {
  const material = new THREE.MeshBasicMaterial({
    map: instructorTexture,
  });
  const aspectRatio = instructorVideo.videoWidth / instructorVideo.videoHeight;
  const width = height * aspectRatio;
  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);

  const xPosition = halfWidth - width / 2;
  const yPosition = height / 2;

  const { x, y, z } = new THREE.Vector3(xPosition, yPosition, halfWidth);

  mesh.position.set(x, y, z);
  mesh.rotation.y = Math.PI;
  mesh.name = "InstructorVideo";
  return mesh;
};

export const getAccuracyLabel = (
  geometry: THREE.ShapeGeometry,
  height: number,
  halfWidth: number
) => {
  const matDark = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    visible: false,
  });

  const mesh = new THREE.Mesh(geometry, matDark);
  mesh.position.set(halfWidth - 0.39, height - 0.35, halfWidth - 0.021);
  mesh.rotation.y = Math.PI;

  return mesh;
};

export const setSize = (
  mesh: THREE.Mesh,
  xSize: number,
  ySize: number
): void => {
  const geometry = mesh.geometry;
  geometry.computeBoundingBox();
  const boundingBox = geometry.boundingBox;

  if (boundingBox) {
    const parameters = new THREE.Vector3();
    boundingBox.getSize(parameters);
    const width = parameters.x;
    const height = parameters.y;
    const scaleFactorX = xSize / width;
    const scaleFactorY = ySize / height;
    mesh.scale.set(scaleFactorX, scaleFactorY, 1);
  }
};

export const getCurrentInstructorPoseMesh = (
  material: THREE.MeshBasicMaterial,
  textureImage: HTMLImageElement,
  height: number,
  halfWidth: number
): THREE.Mesh => {
  const aspectRatio = textureImage.width / textureImage.height;
  const width = height * aspectRatio;
  const geometry = new THREE.PlaneGeometry(width, height);
  const mesh = new THREE.Mesh(geometry, material);

  mesh.position.set(0, height / 2, halfWidth);
  mesh.rotation.y = Math.PI;

  return mesh;
};

export const getTextGeometry = (shapes: THREE.Shape[]): THREE.ShapeGeometry => {
  const geometry = new THREE.ShapeGeometry(shapes);
  geometry.computeBoundingBox();
  if (geometry.boundingBox) {
    const xMid =
      -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

    geometry.translate(xMid, 0, 0);
  }
  return geometry;
};

export const getCurrentPoseLabel = (
  text: string,
  font: THREE.Font,
  halfWidth: number
) => {
  const shapes = font.generateShapes(text, 0.27);
  const geometry = getTextGeometry(shapes);
  const matDark = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
  });

  const mesh = new THREE.Mesh(geometry, matDark);
  mesh.position.set(0, 0.05, halfWidth - 0.1);
  mesh.rotation.y = Math.PI;

  return mesh;
};

export const getBlockLabel = (
  text: string,
  font: THREE.Font,
  halfWidth: number
) => {
  const shapes = font.generateShapes(text, 0.14);
  const geometry = getTextGeometry(shapes);
  const matDark = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
  });
  const mesh = new THREE.Mesh(geometry, matDark);
  mesh.position.set(-halfWidth + 0.1, 0.15, 0);
  mesh.rotation.y = Math.PI / 2;
  mesh.name = "BlockLabel";
  // const box = geometry.boundingBox;
  // if (box) {
  //   console.log('box')
  //   const material = new THREE.MeshBasicMaterial({
  //     color: 0x5a5a5a,
  //     transparent: true,
  //     opacity: 0.15,
  //   });
  //   const width = box.max.x - box.min.x;
  //   const height = box.max.y - box.min.y;
  //   const center = new THREE.Vector3();
  //   box.getCenter(center);
  //   const bgGeometry = new THREE.PlaneGeometry(width + 0.15, height + 0.1);
  //   const bgMesh = new THREE.Mesh(bgGeometry, material);
  //   bgMesh.position.set(0.1, 0, 0);
  //   // bgMesh.rotation.y = Math.PI;
  //   mesh.add(bgMesh);
  // }
  return mesh;
};

export const removeBlockLabel = (scene: THREE.Scene) => {
  const label = scene.getObjectByName('BlockLabel') as THREE.Mesh;
  if(label){
    scene.remove(label);
    cleanupObject(label);
  }
}


export const getInstructorVideoProgressBarNode = (
  duration: number,
  currentTime: number,
  progressBarFullWidth: number,
  progressBarHeight: number,
  halfWidth: number,
  timespan: CourseAnalysisTimespan,
  name: string
) => {
  const space = 0.02 * 2;
  const instructorVideoProgressBarMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0xff0000),
  });
  const { end_seconds, start_seconds } = timespan;
  const isTimespanFullyVisible = currentTime >= end_seconds;
  const timespanDuration = isTimespanFullyVisible
    ? end_seconds - start_seconds
    : currentTime - start_seconds;
  const timePercentage = timespanDuration / duration;
  const width = progressBarFullWidth * timePercentage;

  const startPercentage = start_seconds / duration;
  const startPosition = progressBarFullWidth * startPercentage;
  const positionZ = startPosition + width / 2 - halfWidth;
  const instructorVideoProgressBarGeometry = new THREE.PlaneGeometry(
    width - (isTimespanFullyVisible ? space : space / 2),
    progressBarHeight
  );

  const instructorVideoPorgressBar = new THREE.Mesh(
    instructorVideoProgressBarGeometry,
    instructorVideoProgressBarMaterial
  );

  const { x, y, z } = new THREE.Vector3(
    -halfWidth + 0.009,
    progressBarHeight / 2,
    -positionZ
  );

  instructorVideoPorgressBar.position.set(x, y, z);
  instructorVideoPorgressBar.rotation.y = Math.PI / 2;
  instructorVideoPorgressBar.name = name;
  return instructorVideoPorgressBar;
};

export const getInstructorVideoProgressBarNodeBackground = (
  duration: number,
  progressBarFullWidth: number,
  progressBarHeight: number,
  halfWidth: number,
  timespan: CourseAnalysisTimespan,
  instructorVideoProgressBarMaterial: THREE.MeshBasicMaterial
) => {
  const space = 0.01 * 2;
  const { end_seconds, start_seconds } = timespan;
  const timespanDuration = end_seconds - start_seconds;
  const timePercentage = timespanDuration / duration;
  const width = progressBarFullWidth * timePercentage;

  const startPercentage = start_seconds / duration;
  const startPosition = progressBarFullWidth * startPercentage;
  const positionZ = startPosition + width / 2 - halfWidth;
  const instructorVideoProgressBarGeometry = new THREE.PlaneGeometry(
    width - space,
    progressBarHeight
  );

  const instructorVideoPorgressBar = new THREE.Mesh(
    instructorVideoProgressBarGeometry,
    instructorVideoProgressBarMaterial
  );

  const { x, y, z } = new THREE.Vector3(
    -halfWidth + 0.005,
    progressBarHeight / 2,
    -positionZ
  );

  instructorVideoPorgressBar.position.set(x, y, z);
  instructorVideoPorgressBar.rotation.y = Math.PI / 2;
  return instructorVideoPorgressBar;
};

export const getInstructorVideoGeneralProgressBar = (
  duration: number,
  currentTime: number,
  progressBarFullWidth: number,
  progressBarHeight: number,
  halfWidth: number,
  name: string
) => {
  const space = 0.01 * 2;
  const instructorVideoProgressBarMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0xffff00),
  });

  const timePercentage = currentTime / duration;
  const width = progressBarFullWidth * timePercentage;

  const positionZ = -halfWidth + width / 2;
  const instructorVideoProgressBarGeometry = new THREE.PlaneGeometry(
    width - space,
    progressBarHeight
  );

  const instructorVideoPorgressBar = new THREE.Mesh(
    instructorVideoProgressBarGeometry,
    instructorVideoProgressBarMaterial
  );

  const { x, y, z } = new THREE.Vector3(
    -halfWidth + 0.007,
    progressBarHeight / 2,
    -positionZ
  );

  instructorVideoPorgressBar.position.set(x, y, z);
  instructorVideoPorgressBar.rotation.y = Math.PI / 2;
  instructorVideoPorgressBar.name = name;
  return instructorVideoPorgressBar;
};
