import { Hands } from "@mediapipe/hands";
import { storageBase } from "./constants";

const baseUrl = `${storageBase}/hands/`;
let model: Hands | undefined;

const loadModel = () => {
  const pose = new Hands({
    locateFile: (file: string) => {
      return `${baseUrl}${file}`;
    },
  });
  return pose;
};

export const clear = async () => {
  if (model) {
    await model.close();
    model = undefined;
  }
};


export const getModel = async () => {
  await clear();
  const hands = loadModel();
  hands.setOptions({
    maxNumHands: 2,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5
  });
  await hands.initialize();
  model = hands;
  return model;
};