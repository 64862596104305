import * as THREE from "three";
import { Vector3 } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Keypoint3D } from "../../types/analyze/keypoint3d.type";
import { AvatarType } from "./avatar-type.enum";
import Ybot from "../../assets/Ybot.fbx";
import Xbot from "../../assets/Xbot.glb";

const mixamoByPart: any = {
  MidHip: "mixamorigHips", //0
  RHip: "mixamorigRightUpLeg", //1
  RKnee: "mixamorigRightLeg", //2
  RAnkle: "mixamorigRightFoot", //3
  LHip: "mixamorigLeftUpLeg", //4
  LKnee: "mixamorigLeftLeg", //5
  LAnkle: "mixamorigLeftFoot", //6
  Spine: "mixamorigSpine1", //7
  Thorax: "mixamorigNeck", //8
  Nose: "mixamorigHead", //9
  Head: "mixamorigHead", //10
  LShoulder: "mixamorigLeftArm", //   //11
  LElbow: "mixamorigLeftForeArm", //12
  LWrist: "mixamorigLeftHand", //13
  RShoulder: "mixamorigRightArm", //14
  RElbow: "mixamorigRightForeArm", //15
  RWrist: "mixamorigRightHand", //16
};

const toVector = (keypoint: Keypoint3D = { x: 0, y: 0, z: 0, part: "" }): THREE.Vector3 => {
  const { x, y, z } = keypoint;
  return new THREE.Vector3(x, y, z);
};

const triangleNormal = (
  a: THREE.Vector3,
  b: THREE.Vector3,
  c: THREE.Vector3
): THREE.Vector3 => {
  const aCopy1 = new Vector3(a.x, a.y, a.z);
  const aCopy2 = new Vector3(a.x, a.y, a.z);
  const bCopy = new Vector3(b.x, b.y, b.z);
  const cCopy = new Vector3(c.x, c.y, c.z);
  const d1 = aCopy1.sub(bCopy);
  const d2 = aCopy2.sub(cCopy);

  const dd = d1.cross(d2);

  dd.normalize();

  return dd;
};

function getAngle(
  axis: THREE.Vector3,
  dv1: THREE.Vector3,
  dv2: THREE.Vector3,
  normal: THREE.Vector3
): number {
  let angle = dv1.angleTo(dv2);
  let tNormal = triangleNormal(
    axis,
    new THREE.Vector3(axis.x + dv1.x, axis.y + dv1.y, axis.z + dv1.z),
    new THREE.Vector3(axis.x + dv2.x, axis.y + dv2.y, axis.z + dv2.z)
  );

  // if (angle > Math.PI / 2) angle = Math.PI - angle;
  if (tNormal.angleTo(normal) > Math.PI / 2) angle = -angle;
  return angle;
}

function getVerticalProjectionVector(
  a: THREE.Vector3,
  b: THREE.Vector3,
  c: THREE.Vector3
): THREE.Vector3 {
  let angle = new THREE.Vector3()
    .copy(a)
    .sub(b)
    .angleTo(new THREE.Vector3().copy(a).sub(c));
  let L = a.distanceTo(c);
  let l = a.distanceTo(b) / Math.cos(angle);
  let rate = l / L;
  return new THREE.Vector3(
    a.x + (c.x - a.x) * rate,
    a.y + (c.y - a.y) * rate,
    a.z + (c.z - a.z) * rate
  );
}

function parseKeypoints(keypoints: Keypoint3D[]): Keypoint3D[] {
  let replaceKeyList: any = {
    nose: "Nose",
    left_eye_inner: "LEInner",
    left_eye: "LE",
    left_eye_outer: "LEOuter",
    right_eye_inner: "REInner",
    "right-eye": "RE",
    "right-eye_outer": "REOuter",
    "left—ear": "REar",
    right_ear: "LEar",
    mouth_left: "LMouth",
    mouth_right: "RMouth",
    left_shoulder: "LShoulder",
    right_shoulder: "RShoulder",
    left_elbow: "LElbow",
    "right-elbow": "RElbow",
    left_wrist: "LWrist",
    "right-wrist": "RWrist",
    left_pinky: "LHPinky",
    right_pinky: "RHPinky",
    left_index: "LHIndex",
    right_index: "RHIndex",
    left_thumb: "LHThumb",
    "right-thumb": "RHThumb",
    "left-hip": "LHip",
    "right—hip": "RHip",
    "left-knee": "LKnee",
    right_knee: "RKnee",
    left_ankle: "LAnkle",
    right_ankle: "RAnkle",
    left_heel: "LHeel",
    right_heel: "RHeel",
    "left-foot-index": "LFIndex",
    "right-foot_index": "RFIndex",
  };
  keypoints.forEach((item) => (item.part = replaceKeyList[item.part]));

  let LShoulder = keypoints.find((item) => item.part === "LShoulder");
  let RShoulder = keypoints.find((item) => item.part === "RShoulder");
  let Thorax = {
    part: "Thorax",
    x: ((LShoulder ? LShoulder.x : 0) + (RShoulder ? RShoulder.x : 0)) / 2,
    y: ((LShoulder ? LShoulder.y : 0) + (RShoulder ? RShoulder.y : 0)) / 2,
    z: ((LShoulder ? LShoulder.z : 0) + (RShoulder ? RShoulder.z : 0)) / 2,
  };
  keypoints.push(Thorax);

  let LHip = keypoints.find((item) => item.part === "LHip");
  let RHip = keypoints.find((item) => item.part === "RHip");
  let MidCenter = {
    part: "MidHip",
    x: ((LHip ? LHip.x : 0) + (RHip ? RHip.x : 0)) / 2,
    y: ((LHip ? LHip.y : 0) + (RHip ? RHip.y : 0)) / 2,
    z: ((LHip ? LHip.z : 0) + (RHip ? RHip.z : 0)) / 2,
  };

  let Spine = {
    part: "Spine",
    x: (Thorax.x + MidCenter.x) / 2,
    y: (Thorax.y + MidCenter.y) / 2,
    z: (Thorax.z + MidCenter.z) / 2,
  };
  keypoints.push(Spine);

  let MidHip = {
    part: "MidHip",
    x: (Spine.x + MidCenter.x) / 2,
    y: (Spine.y + MidCenter.y) / 2,
    z: (Spine.z + MidCenter.z) / 2,
  };
  keypoints.push(MidHip);

  let LEar = keypoints.find((item) => item.part === "LEar");
  let REar = keypoints.find((item) => item.part === "REar");
  let Head = {
    part: "Head",
    x: ((LEar ? LEar.x : 0) + (REar ? REar.x : 0)) / 2,
    y: ((LEar ? LEar.y : 0) + (REar ? REar.y : 0)) / 2,
    z: ((LEar ? LEar.z : 0) + (REar ? REar.z : 0)) / 2,
  };
  keypoints.push(Head);

  return keypoints;
}

export const poseUpdate = (scene: THREE.Object3D, keypoints: Keypoint3D[]) => {
  if (keypoints.length === 0) return;
  keypoints = parseKeypoints(keypoints);

  const skeletonTree = {
    name: "MidHip",
    child: [
      {
        name: "RHip",
        child: [
          {
            name: "RKnee",
            child: [{ name: "RAnkle" }],
          },
        ],
      },
      {
        name: "LHip",
        child: [
          {
            name: "LKnee",
            child: [{ name: "LAnkle" }],
          },
        ],
      },
      {
        name: "Spine",
        child: [
          {
            name: "RShoulder",
            child: [
              {
                name: "RElbow",
                child: [{ name: "RWrist" }],
              },
            ],
          },
          {
            name: "LShoulder",
            child: [
              {
                name: "LElbow",
                child: [{ name: "LWrist" }],
              },
            ],
          },
          { name: "Thorax" },
        ],
      },
    ],
  };

  let rShinVectorZ: any, lShinVectorZ: any;

  function recursiveUpdate(skeleton: any) {
    let name = skeleton.name;
    let item = keypoints.find((item) => item.part == name);

    //TODO: replace scene with root object??
    let b = scene.getObjectByName(mixamoByPart[name]);

    if (item && b) {
      b.rotation.set(0, 0, 0);

      let pwPos = new THREE.Vector3();
      b.getWorldPosition(pwPos);

      let vetFront: any;
      let vetTop: any;
      let vetRight: any;
      b.traverse((i) => {
        if (i instanceof THREE.Mesh) {
          if (i.name.includes('-front')) vetFront = i;
          if (i.name.includes('-top')) vetTop = i;
          if (i.name.includes('-right')) vetRight = i;
        }
      });

      if (Array.isArray(skeleton.child) && skeleton.child.length === 1) {
        // rotate legs
        if (name === "RHip" || name === "LHip" || name === "RKnee" || name === "LKnee") {
          (() => {

            let keyHip = "";
            let keyKnee = "";
            let keyAnkle = "";
            let keyToe = "";
            let keyHeel = "";

            if (name === "RHip" || name === "RKnee") {
              keyHip = "RHip";
              keyKnee = "RKnee";
              keyAnkle = "RAnkle";
              keyToe = "RFIndex";
              keyHeel = "RHeel";
            } else {
              keyHip = "LHip";
              keyKnee = "LKnee";
              keyAnkle = "LAnkle";
              keyToe = "LFIndex";
              keyHeel = "LHeel";
            }
            const hipItem = keypoints.find((i) => i.part === keyHip);
            const kneeItem = keypoints.find((i) => i.part === keyKnee);
            const ankleItem = keypoints.find((i) => i.part === keyAnkle);
            const toeItem = keypoints.find((i) => i.part === keyToe);
            const heelItem = keypoints.find((i) => i.part === keyHeel);

            const hipVector = toVector(hipItem);
            const kneeVector = toVector(kneeItem);
            const ankleVector = toVector(ankleItem);
            const toeVector = toVector(toeItem);
            const heelVector = toVector(heelItem);

            let taY = new THREE.Vector3();
            let vpPos: any;
            if (name === "LHip" || name === "RHip") {
              taY = new THREE.Vector3().copy(hipVector).sub(kneeVector);
              vpPos = triangleNormal(hipVector, toeVector, heelVector);
            }
            else {
              taY = new THREE.Vector3().copy(kneeVector).sub(ankleVector);
              vpPos = triangleNormal(kneeVector, toeVector, heelVector);
            }
            const taX = vpPos;
            const taZ = new THREE.Vector3().copy(taX).cross(taY);

            b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

            // rotate Z
            if (vetTop instanceof THREE.Mesh) {
              let vetPos = new THREE.Vector3();
              vetTop.getWorldPosition(vetPos);
              const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
              const dir2 = taY;
              const angle = getAngle(pwPos, dir1, dir2, taZ);
              b.rotateZ(angle);
            }
          })();
        }

        // rotate arms
        else if (name === "RShoulder" || name === "LShoulder" || name === "RElbow" || name === "LElbow") {
          (() => {
            let keyShoulder = "";
            let keyElbow = "";
            let keyWrist = "";
            if (name === "RShoulder" || name === "RElbow") {
              keyShoulder = "RShoulder";
              keyElbow = "RElbow";
              keyWrist = "RWrist";
            } else {
              keyShoulder = "LShoulder";
              keyElbow = "LElbow";
              keyWrist = "LWrist";
            }
            const shoulderItem = keypoints.find((i) => i.part === keyShoulder);
            const elbowItem = keypoints.find((i) => i.part === keyElbow);
            const wristItem = keypoints.find((i) => i.part === keyWrist);

            const shoulderVector = toVector(shoulderItem);
            const elbowVector = toVector(elbowItem);
            const wristVector = toVector(wristItem);

            let taX = new THREE.Vector3();
            let vpPos = new THREE.Vector3();

            if (name === "RShoulder" || name === "LShoulder") {
              taX = new THREE.Vector3().copy(shoulderVector).sub(elbowVector);
              vpPos = getVerticalProjectionVector(shoulderVector, elbowVector, wristVector);
            }
            else {
              taX = new THREE.Vector3().copy(elbowVector).sub(wristVector);
              vpPos = getVerticalProjectionVector(wristVector, elbowVector, shoulderVector);
            }

            const taZ = new THREE.Vector3().copy(vpPos).sub(elbowVector);

            if (name === "LShoulder" || name === "LElbow") {
              taX = taX.multiplyScalar(-1);
            }

            b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

            // rotate Z
            if (vetRight instanceof THREE.Mesh) {
              let vetPos = new THREE.Vector3();
              vetRight.getWorldPosition(vetPos);
              const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
              const dir2 = taX;
              const angle = getAngle(pwPos, dir1, dir2, taZ);
              b.rotateZ(angle);
            }
          })();
        }
      } else {
        if (name === "MidHip") {
          const midHip = item;
          const rHip = keypoints.find((item) => item.part === "RHip");
          const lHip = keypoints.find((item) => item.part === "LHip");
          const spine = keypoints.find((item) => item.part === "Spine");

          const hipVector = new THREE.Vector3((midHip.x + (spine ? spine.x : 0)) / 2, (midHip.y + (spine ? spine.y : 0)) / 2, (midHip.z + (spine ? spine.z : 0)) / 2);

          const lHipVector = toVector(lHip);
          const rHipVector = toVector(rHip);

          const taZ = triangleNormal(hipVector, rHipVector, lHipVector);
          const taX = new THREE.Vector3().copy(lHipVector).sub(rHipVector);
          const taY = new THREE.Vector3().copy(taZ).cross(taX);
          const tmpY = new THREE.Vector3().copy(hipVector).sub(new THREE.Vector3().copy(lHipVector).add(rHipVector).multiplyScalar(0.5));

          b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

          // rotate Z
          if (vetTop instanceof THREE.Mesh) {
            let vetPos = new THREE.Vector3();
            vetTop.getWorldPosition(vetPos);
            const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
            const dir2 = tmpY;
            const angle = getAngle(pwPos, dir1, dir2, taZ);
            b.rotateZ(angle);
          }

          // rotate left/right bone
          // const spine1Bone = scene.getObjectByName("mixamorigSpine");
          // if (spine1Bone) {
          //   spine1Bone.rotation.set(0, 0, 0);
          //   const angle = getAngle(pwPos, taY, tmpY, taZ);
          //   spine1Bone.rotateZ(angle / 2);
          // }
        }
        else if (name === "Spine") {
          const rShoulder = keypoints.find((item) => item.part === "RShoulder");
          const lShoulder = keypoints.find((item) => item.part === "LShoulder");

          const spineVector = toVector(item);
          const rShoulderVector = toVector(rShoulder);
          const lShoulderVector = toVector(lShoulder);

          const taZ = triangleNormal(spineVector, lShoulderVector, rShoulderVector);
          const taX = new THREE.Vector3().copy(lShoulderVector).sub(rShoulderVector);
          const taY = new THREE.Vector3().copy(lShoulderVector).add(rShoulderVector).multiplyScalar(0.5).sub(spineVector);
          const tmpY = new THREE.Vector3().copy(taZ).cross(taX);

          b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

          // rotate Z
          if (vetTop) {
            let vetPos = new THREE.Vector3();
            vetTop.getWorldPosition(vetPos);
            const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
            const dir2 = taY;
            const angle = getAngle(pwPos, dir1, dir2, taZ);
            b.rotateZ(angle);
          }

          // rotate shoulders
          const offsetAngle = getAngle(pwPos, taY, tmpY, taZ);
          (() => {
            const rate = 3.2;
            const reductionRate = 0.6;

            const lEarItem = keypoints.find((i) => i.part === "LEar");
            const rEarItem = keypoints.find((i) => i.part === "REar");
            const lEarVector = toVector(lEarItem);
            const rEarVector = toVector(rEarItem);

            const midEarVector = new THREE.Vector3().copy(rEarVector).add(lEarVector).multiplyScalar(0.5);
            const midShoulderVector = new THREE.Vector3().copy(rShoulderVector).add(lShoulderVector).multiplyScalar(0.5);

            const distEar = rEarVector.distanceTo(lEarVector);
            const distEarShoulder = midEarVector.distanceTo(midShoulderVector);
            const distSpineShoulder = spineVector.distanceTo(midShoulderVector);
            const offsetDistShoulderSpine = distEar * rate - distEarShoulder;

            const realMidShoulderVector = new THREE.Vector3()
              .copy(spineVector)
              .multiplyScalar(offsetDistShoulderSpine / distSpineShoulder)
              .add(
                new THREE.Vector3()
                  .copy(midShoulderVector)
                  .multiplyScalar(1 - offsetDistShoulderSpine / distSpineShoulder)
              );

            const rShoulderBone = scene.getObjectByName("mixamorigRightShoulder");
            if (rShoulderBone instanceof THREE.Bone) {
              rShoulderBone.rotation.set(0, 0, 0);
              let vec1 = new THREE.Vector3().copy(rShoulderVector).sub(midShoulderVector);
              let vec2 = new THREE.Vector3().copy(rShoulderVector).sub(realMidShoulderVector);
              const angle = getAngle(pwPos, vec1, vec2, taZ);
              rShoulderBone.rotateZ(angle * reductionRate + offsetAngle);
            }
            const lShoulderBone = scene.getObjectByName("mixamorigLeftShoulder");
            if (lShoulderBone instanceof THREE.Bone) {
              lShoulderBone.rotation.set(0, 0, 0);
              let vec1 = new THREE.Vector3().copy(lShoulderVector).sub(midShoulderVector);
              let vec2 = new THREE.Vector3().copy(lShoulderVector).sub(realMidShoulderVector);
              const angle = getAngle(pwPos, vec1, vec2, taZ);
              lShoulderBone.rotateZ(angle * reductionRate + offsetAngle);
            }
            // console.log(rAngle, lAngle);
          })();
        }
        else if (name === "Thorax") {
          const thoraxVector = toVector(item);
          const lEyeItem = keypoints.find((i) => i.part === "LE");
          const rEyeItem = keypoints.find((i) => i.part === "RE");
          const lEarItem = keypoints.find((i) => i.part === "LEar");
          const rEarItem = keypoints.find((i) => i.part === "REar");

          const lEyeVector = toVector(lEyeItem);
          const rEyeVector = toVector(rEyeItem);
          const lEarVector = toVector(lEarItem);
          const rEarVector = toVector(rEarItem);

          const taX = new THREE.Vector3().copy(rEarVector).sub(lEarVector).multiplyScalar(0.5);
          const taY = new THREE.Vector3().copy(rEarVector).add(lEarVector).multiplyScalar(0.5).sub(thoraxVector);
          // const taY = triangleNormal(new THREE.Vector3().copy(lEyeVector).add(rEyeVector).multiplyScalar(0.5), rEarVector, lEarVector);
          const taZ = new THREE.Vector3().copy(taX).cross(taY);

          b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

          // rotate Z
          if (vetTop instanceof THREE.Mesh) {
            let vetPos = new THREE.Vector3();
            vetTop.getWorldPosition(vetPos);
            const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
            const dir2 = taY;
            const angle = getAngle(pwPos, dir1, dir2, taZ);
            b.rotateZ(angle);
          }
        }
        else if (name === "RAnkle" || name === "LAnkle") {
          let keyFIndex = "";
          let keyHeel = "";
          let keyKnee = "";
          if (name === "RAnkle") {
            keyFIndex = "RFIndex";
            keyHeel = "RHeel";
            keyKnee = "RKnee";
          } else {
            keyFIndex = "LFIndex";
            keyHeel = "LHeel";
            keyKnee = "LKnee";
          }
          const indexItem = keypoints.find((i) => i.part === keyFIndex);
          const heelItem = keypoints.find((i) => i.part === keyHeel);
          const kneeItem = keypoints.find((i) => i.part === keyKnee);

          const indexVector = toVector(indexItem);
          const heelVector = toVector(heelItem);
          const kneeVector = toVector(kneeItem);

          const ankleVector = toVector(item);

          const ankleKneeVector = new THREE.Vector3().copy(kneeVector).sub(ankleVector);

          let taZ = new THREE.Vector3().copy(indexVector).sub(heelVector);
          let taX = triangleNormal(ankleVector, indexVector, heelVector);

          let taY = new THREE.Vector3().copy(taZ).cross(taX);

          if (ankleKneeVector.y > 0 && (ankleKneeVector.angleTo(new THREE.Vector3(ankleKneeVector.x, 0, ankleKneeVector.z)) > Math.PI / 4)) {
            taZ.y = 0;
          }

          b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));
        }
        else if (name === "RWrist" || name === "LWrist") {
          (() => {
            let keyIndex = "";
            let keyPinky = "";
            let keyThumb = "";
            if (name === "RWrist") {
              keyIndex = "RHIndex";
              keyThumb = "RHThumb";
              keyPinky = "RHPinky";
            } else {
              keyIndex = "LHIndex";
              keyThumb = "LHThumb";
              keyPinky = "LHPinky";
            }

            const indexItem = keypoints.find((i) => i.part === keyIndex);
            const thumbItem = keypoints.find((i) => i.part === keyThumb);
            const pinkyItem = keypoints.find((i) => i.part === keyPinky);

            const indexVector = toVector(indexItem);
            const thumbVector = toVector(thumbItem);
            const pinkyVector = toVector(pinkyItem);

            let taX = new THREE.Vector3().copy(thumbVector).add(pinkyVector).multiplyScalar(0.5).sub(toVector(item));
            let taY = triangleNormal(toVector(item), thumbVector, pinkyVector);
            // let taZ = new THREE.Vector3().copy(thumbVector).sub(pinkyVector);
            if (name === 'RWrist') {
              taY = taY.multiplyScalar(-1);
              taX = taX.multiplyScalar(-1);
            }
            let taZ = new THREE.Vector3().copy(taX).cross(taY);


            b.lookAt(new THREE.Vector3().copy(pwPos).add(taZ));

            // rotate Z
            if (vetTop instanceof THREE.Mesh) {
              let vetPos = new THREE.Vector3();
              vetTop.getWorldPosition(vetPos);
              const dir1 = new THREE.Vector3().copy(vetPos).sub(pwPos);
              const dir2 = taY;
              const angle = getAngle(pwPos, dir1, dir2, taZ);
              b.rotateZ(angle);
            }
          })();
        }
      }
    }

    Array.isArray(skeleton.child) && skeleton.child.forEach(recursiveUpdate);
  }

  recursiveUpdate(skeletonTree);

  return;
};

export const init = (model: any) => {
  // const helper = new THREE.SkeletonHelper(model);
  // model.parent.add(helper);

  // model.traverse((obj: any) => {
  //   if (obj instanceof THREE.Bone) {
  //     console.log(obj.name)
  //   }
  // })
  const attachJoints = [
    "mixamorigHips",
    // "mixamorigSpine",
    "mixamorigSpine1",
    "mixamorigRightFoot",
    "mixamorigLeftFoot",
    "mixamorigRightUpLeg",
    "mixamorigLeftUpLeg",
    "mixamorigRightLeg",
    "mixamorigLeftLeg",
    "mixamorigLeftShoulder",
    "mixamorigRightShoulder",
    "mixamorigLeftArm",
    "mixamorigRightArm",
    "mixamorigLeftForeArm",
    "mixamorigRightForeArm",
    "mixamorigRightHand",
    "mixamorigLeftHand",
    "mixamorigNeck",
  ];

  const length = 20;
  const thickness = 0.4;
  for (let point of attachJoints) {
    const obj = model.getObjectByName(point);
    if (obj) {
      // front
      const meshFront = new THREE.Mesh();
      meshFront.name = `${point}-target-front`;
      meshFront.position.set(0, 0, length / 2);
      obj.add(meshFront);

      // front
      const meshTop = new THREE.Mesh();
      meshTop.name = `${point}-target-top`;
      meshTop.position.set(0, length / 2, 0);
      obj.add(meshTop);

      // right
      const meshRight = new THREE.Mesh();
      meshRight.name = `${point}-target-right`;
      meshRight.position.set(length / 2, 0, 0);
      obj.add(meshRight);
    }
  }

  model.rotation.y = Math.PI;
  // poseUpdate(model.parent, JSON.parse(testData));
};

export const getFbxModel = async (modelPath: string) => {
  const loader = new FBXLoader();
  const fbx = await loader.loadAsync(modelPath);
  fbx.scale.set(0.01, 0.01, 0.01);
  return fbx;
};

export const getGltfModel = async (modelPath: string) => {
  const loader = new GLTFLoader();
  const gltf = await loader.loadAsync(modelPath);
  const model = gltf.scene.children[0];
  return model;
};

export const getModel = (type: AvatarType) => {
  if (type === AvatarType.Female) {
    return getGltfModel(Xbot);
  }

  return getFbxModel(Ybot);
};

export const getVerticalShift = (root: THREE.Object3D) => {
  if (root) {
    const getLowestPosition = (root: THREE.Object3D) => {
      let lowestPosition: THREE.Vector3 | undefined;
      root.traverse((e) => {
        if (e.name.startsWith("mixamorig")) {
          const position = new THREE.Vector3();
          e.getWorldPosition(position);
          if (
            !lowestPosition ||
            (lowestPosition &&
              lowestPosition.y &&
              position.y < lowestPosition.y)
          ) {
            lowestPosition = position;
          }
        }
      });
      return lowestPosition;
    };

    const lowestPosition = getLowestPosition(root);

    if (lowestPosition) {
      const floorY = 0;
      const shiftY = floorY - lowestPosition.y;
      return shiftY;
    }
  }
};

export const placeOnTheFloor = (root: THREE.Object3D, verticalShift: number | undefined) => {
  if (root && verticalShift) {
    root.position.setY(root.position.y + verticalShift);
  }
};

export const getModelRoot = (scene: THREE.Scene) => {
  const hips = scene.getObjectByName("mixamorigHips");
  if (hips) {
    return hips.parent;
  }
};