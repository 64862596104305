import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { RewardType } from "../../../../../enums/reward-type.enum";
import { getRewardImage } from "../../../../../services/reward/reward.service";
import { CourseAnalysisTimespan } from "../../../../../types/class/class-analysis-timespan";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(211,211,211, 0.8)",
  },
  progress: {
    height: "100%",
    backgroundColor: "red",
  },
  reward: {
    width: 70,
    height: 70,
    objectFit: "contain",
  },
}));

type Props = {
  duration: number;
  currentTime: number;
  timespans: CourseAnalysisTimespan[];
  rewards?: RewardType[];
};

export default function ProgressBar(props: Props) {
  const classes = useStyles();
  const { duration, currentTime, timespans, rewards } = props;

  const getLeft = (timespan: CourseAnalysisTimespan) => {
    const positionPercentage = Math.round(
      (timespan.start_seconds / duration) * 100
    );
    return `${positionPercentage}%`;
  };

  const getWidth = (timespan: CourseAnalysisTimespan) => {
    const timespanDuration = timespan.end_seconds - timespan.start_seconds;
    const percentageValue = duration / 100;

    const widthPrecentage = Math.round(timespanDuration / percentageValue);
    return `${widthPrecentage}%`;
  };

  const getImageElement = (index: number) => {
    const timespan = timespans[index];
    if (rewards && rewards.length !== 0 && rewards.length > index) {
      const reward = rewards[index];
      const rewardImage = getRewardImage(reward);
      if (rewardImage) {
        return (
          <img
            title={RewardType[reward]}
            alt={RewardType[reward]}
            className={classes.reward}
            src={rewardImage}
            style={{
              position: "absolute",
              left: getLeft(timespan),
              zIndex: 10,
              top: -10,
            }}
          />
        );
      }
    }
  };

  const getTimespan = (index: number) => {
    const timespan = timespans[index];
    return (
      <div
        key={`cp-${index}`}
        style={{
          position: "absolute",
          width: getWidth(timespan),
          left: getLeft(timespan),
          backgroundColor: "rgba(0,255,0, 0.5)",
          zIndex: 10,
          top: 0,
          height: "100%",
        }}
      ></div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: 50,
      }}
    >
      <div className={classes.container}>
        <div
          className={classes.progress}
          style={{
            width: `${(currentTime / duration) * 100}%`,
            height: "100%",
            backgroundColor: "red",
          }}
        ></div>
        {timespans.map((timespan, index) => getTimespan(index))}
        {timespans.map((timespan, index) => getImageElement(index))}
      </div>
    </div>
  );
}
